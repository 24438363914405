import React from "react";
import { makeRequest } from '../helpers'
import Navbar from './Navbar_new';
import Footer from './Footer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import {Fullscreen,DashSquare,GraphUp,ArrowLeftRight,ArrowLeft } from 'react-bootstrap-icons';

class PlayGame extends React.Component {
  constructor(props) {
    super(props);
    this.deposit_amount = this.deposit_amount.bind(this)
	  this.makeRequest = makeRequest
  }
  state= {
    iframe_src:'',
    games_data:[],
  }
  async deposit_amount(){
    //let game_id=(window.location.hash).substr(1);
    let game_id=localStorage.getItem('game_id');
    let data = JSON.stringify({
      "gameId": game_id.toString(),
      "settings": {
          "back_url": "https: //www.google.com/",
          "language": "en",
          "cash_url": "https: //www.google.com/",
          "https": 1,
          "extra_bonuses": {
          "bonus_spins": {
              "spins_count": 10,
              "bet_in_money": 4,
              "freespins_on_start": {
              "freespins_count": 10,
              "bet_in_money": 1
              }
          }
          },
          "extra_bonuses_settings": {
          "expire": "2021-10-30",
          "registration_id": 7463,
          "bypass": {
              "promoCode": "promoCode value"
          }
          }
      }
    });
    let result1=await this.makeRequest('evoplay/game/iframe', 'post', data,localStorage.getItem('token'));
    if(result1.status===200){
      console.log(result1.data);
      this.setState({iframe_src: result1.data.data.link})      
    }
  }
  componentDidMount(){
    this.deposit_amount();
      (async () => {
        let result1=await this.makeRequest('game', 'get', '','');
        if(result1.status===200){
          this.setState({
            games_data:result1.data.data
          })
        }
    })();
  }
  render() {
    return (
      <div>
        <Navbar prop={this.props}/>
        <div style={{backgroundImage:'linear-gradient(#000407, #011c31)',color:'white'}}><br/><br/>
          <div className="container">
            <div className="row justify-content-center">
              <div style={{borderRadius: 0,width: '100%',height:"100vh",padding:"0"}}>
                <div className="row" style={{margin:0,height:'100%'}}>
                  <iframe width="100%" height="100%" allowFullScreen={true} style={{border: 0,padding: 0}} src={this.state.iframe_src}/>
                </div>
              </div>
              <div className="game_controls">
                <div className="row" style={{width:"100%",margin:"0"}}>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <ul className="game_control_left">
                            <li><span><Fullscreen color="white" size={14} /><br/></span>Full screen</li>
                            <li><span><DashSquare color="white" size={14} /><br/></span>Minimum</li>
                            <li><span><GraphUp color="white" size={14} /><br/></span>Live slots</li>
                        </ul>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <ul className="game_control_right">
                            <li>Fun play</li>
                            <li>
                            <label className="switch">
                                <input type="checkbox"/>
                                    <span className="slider round"></span>
                                </label>
                            </li>
                            <li>Real play</li>
                        </ul>
                    </div>
                </div>
              </div>
            </div></div><br/><br/><br/>
          <Footer/>
      </div>
    </div>
    );
  }
}
export default PlayGame;
