import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
import { CSVLink } from "react-csv";
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,member_id: "super168",member_group:"VIP1A",rebate_turnover_req:"0.00",last_rebate:"-",rebate:"0.75",last_dep_dt:"2021-02-03 02:57:56"},
  {id: 2,member_id: "super168",member_group:"VIP1A",rebate_turnover_req:"0.00",last_rebate:"-",rebate:"0.75",last_dep_dt:"2021-02-03 02:57:56"},
  {id: 3,member_id: "super168",member_group:"VIP1A",rebate_turnover_req:"0.00",last_rebate:"-",rebate:"0.75",last_dep_dt:"2021-02-03 02:57:56"},
  {id: 4,member_id: "super168",member_group:"VIP1A",rebate_turnover_req:"0.00",last_rebate:"-",rebate:"0.75",last_dep_dt:"2021-02-03 02:57:56"},
];
const data1 = [
  {id: 1,member_id: "super168",member_group:"VIP1A",start_time:"2021-02-03 02:57:56",end_time:"2021-02-03 02:57:56",roll_over:"131.00",rebate_turnover_req:"0.00",rebate_sgd:"891.00",rebate_user:"891.00",trans_id:"0.00",status:"Rejected",sub_date:"2021-02-03 02:57:56",sub_by:"cdemo02",remark:"-"},
  {id: 2,member_id: "super168",member_group:"VIP1A",start_time:"2021-02-03 02:57:56",end_time:"2021-02-03 02:57:56",roll_over:"131.00",rebate_turnover_req:"0.00",rebate_sgd:"891.00",rebate_user:"891.00",trans_id:"0.00",status:"Rejected",sub_date:"2021-02-03 02:57:56",sub_by:"cdemo02",remark:"-"},
  {id: 3,member_id: "super168",member_group:"VIP1A",start_time:"2021-02-03 02:57:56",end_time:"2021-02-03 02:57:56",roll_over:"131.00",rebate_turnover_req:"0.00",rebate_sgd:"891.00",rebate_user:"891.00",trans_id:"0.00",status:"Rejected",sub_date:"2021-02-03 02:57:56",sub_by:"cdemo02",remark:"-"},
  {id: 4,member_id: "super168",member_group:"VIP1A",start_time:"2021-02-03 02:57:56",end_time:"2021-02-03 02:57:56",roll_over:"131.00",rebate_turnover_req:"0.00",rebate_sgd:"891.00",rebate_user:"891.00",trans_id:"0.00",status:"Rejected",sub_date:"2021-02-03 02:57:56",sub_by:"cdemo02",remark:"-"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '13px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const headers = [
  { label: "Member ID", key: "member_id" },
  { label: "Member Group", key: "member_group" },
  { label: "Rollover", key: "rollover" },
  { label: "Rebate Turnover Requirement", key: "rebate_turnover_req" },
  { label: "Last Rebate", key: "last_rebate" },
  { label: "Rebate (SGD)", key: "rebate" },
  { label: "Last Deposit Date", key: "last_dep_dt" },
];
const excelData = [
  {
    "Member ID": "super168",
    "Member Group": "VIP1A",
    "Rollover":'131.00',
    "Rebate Turnover Requirement": 0.00,
    "Last Rebate": '-',
    "Rebate (SGD)": 0.75,
    "Rebate to be given (SGD)": 70.00,
    "Last Deposit Date": '2021-02-03 02:57:56',
    "Remark": 0.75,
  },
  {
    "Member ID": "super168",
    "Member Group": "VIP1A",
    "Rollover":'131.00',
    "Rebate Turnover Requirement": 0.00,
    "Last Rebate": '-',
    "Rebate (SGD)": 0.75,
    "Rebate to be given (SGD)": 70.00,
    "Last Deposit Date": '2021-02-03 02:57:56',
    "Remark": 0.75,
  },
  {
    "Member ID": "super168",
    "Member Group": "VIP1A",
    "Rollover":'131.00',
    "Rebate Turnover Requirement": 0.00,
    "Last Rebate": '-',
    "Rebate (SGD)": 0.75,
    "Rebate to be given (SGD)": 70.00,
    "Last Deposit Date": '2021-02-03 02:57:56',
    "Remark": 0.75,
  },
  {
    "Member ID": "super168",
    "Member Group": "VIP1A",
    "Rollover":'131.00',
    "Rebate Turnover Requirement": 0.00,
    "Last Rebate": '-',
    "Rebate (SGD)": 0.75,
    "Rebate to be given (SGD)": 70.00,
    "Last Deposit Date": '2021-02-03 02:57:56',
    "Remark": 0.75,
  }
]
class CalculateMemberRebate extends React.Component {
  constructor(props) {
    super(props);
    this.changeTab = this.changeTab.bind(this)
    this.printData = this.printData.bind(this)
    this.exportData = this.exportData.bind(this)
  }
  state= {
      currentTab:'rebate'
  }
  changeTab(val){
      this.setState(state => ({currentTab: val}));
  }
  printData(){
    window.print();
  }
  exportData(){
    exportFromJSON({ data: excelData, fileName: 'Calculate member rebate', exportType: exportFromJSON.types.xls})
  }
  render() {
    const columns = [
      {name: 'No.',selector: 'id',width:'60px',cell: row => <div data-tag="allowRowEvents"><input type="checkbox" style={{width:"auto"}}/> {row.id}</div>},
      {name: 'Member ID',selector: 'member_id',width:'94px'},
      {name: 'Member Group',selector: 'member_group',width:'100px'},
      {name: 'Rollover',selector: 'rollover',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>372.50</div>,width:'80px'},
      {name: 'Rebate Turnover Requirement',selector: 'rebate_turnover_req'},
      {name: 'Last Rebate',selector: 'last_rebate',width:'100px'},
      {name: 'Rebate (SGD)',selector: 'rebate',width:'111px'},
      {name: 'Rebate to be given (SGD)',selector: 'status',cell: row => <div data-tag="allowRowEvents"><input className="form-control"/></div>},
      {name: 'Last Deposit Date',selector: 'last_dep_dt'},
      {name: 'Remark',selector: 'status',cell: row => <div data-tag="allowRowEvents"><input className="form-control"/></div>},
    ];
    const columns1 = [
      {name: 'No.',selector: 'id',width:'60px',cell: row => <div data-tag="allowRowEvents"> {row.id}</div>},
      {name: 'Member ID',selector: 'member_id',width:'94px'},
      {name: 'Member Group',selector: 'member_group',width:'120px'},
      {name: 'Start Time',selector: 'start_time',width:'150px'},
      {name: 'End Time',selector: 'end_time',width:'150px'},
      {name: 'Rollover',selector: 'roll_over',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>372.50</div>,width:'80px'},
      {name: 'Rebate Turnover Requirement',selector: 'rebate_turnover_req',width:'150px'},
      {name: 'Rebate (SGD)',selector: 'rebate_sgd',width:'111px'},
      {name: 'Rebate (User Adjusted) (SGD)',width:'150px',selector: 'rebate_user'},
      {name: 'Transaction ID',selector: 'trans_id',width:'110px'},
      {name: 'Status',selector: 'status',width:'90px'},
      {name: 'Submitted Date',selector: 'sub_date',width:'150px'},
      {name: 'Submitted By',selector: 'sub_by',width:'120px'},
      {name: 'Remark',selector: 'remark',width:'90px'},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}} className="print_hide">
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Rebate</span> / 
                    <span>Calculate Member Rebate</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Calculate Member Rebate</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              <div className="subbdvv1 print_hide" style={{padding: '22px 24px 0px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <ul className="tabbss">
                    <li className={this.state.currentTab==='rebate' ? 'active' : ''} onClick={()=>this.changeTab('rebate')}>Calculate Rebate</li>
                    <li className={this.state.currentTab==='rebate_history' ? 'active' : ''} onClick={()=>this.changeTab('rebate_history')}>Member Rebate History</li>
                </ul>
              </div>
              <div className="row print_hide" style={{margin:0,padding:'20px 14px',background:'white'}}>
              {this.state.currentTab==='rebate' &&
                <div className="row credit_hist member_listing" style={{width:"100%",margin:"0"}}>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls"><span>Date Range:</span> 
                            <input type="date" className="form-control"/>
                        </label>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">    
                        <label className="labls"><span>Account ID:</span> <input type="text" className="form-control"/></label>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">    
                        <label className="labls"><span>Member Group:</span> 
                            <select className="form-control" style={{width:"100%"}}>
                                <option>All</option>
                            </select>
                        </label>
                    </div>
                    <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div1 cal_rebate_btns">
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Calculate Rebate</button>
                        <button className="btn btn-primary add_btn cancel_btn">Reset</button>
                        <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}}>Submit Member Rebate</button>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 top_div1">    
                        <span style={{verticalAlign: 'middle',fontSize: '14px'}}><input type="checkbox" style={{marginLeft:'12px',width:"auto"}}/> <span style={{verticalAlign:'text-bottom'}}>Auto Approve</span></span>
                    </div>
                </div>}
                {this.state.currentTab==='rebate_history' &&
                <div className="col-md-12" style={{paddingLeft:"0"}}>
                     <div className="row player_freq cal_rebate" style={{margin:0,padding:'12px 0px',background:'white'}}>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"82px"}}>Date Range:</span>
                          <input type="date" className="form-control" style={{height:"32px",fontSize:"14px"}} /></label>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"82px"}}>Record Type:</span>
                          <input type="text" className="form-control" style={{height:"32px",fontSize:"14px"}} /></label>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"104px"}}>Amount Criteria:</span>
                          <input type="text" className="form-control" style={{height:"32px",fontSize:"14px"}} /></label>
                      </div>
                      <br/>
                      <div style={{marginTop:"0px"}}>
                          <button className="btn btn-primary add_btn" style={{width:'auto'}}>Search</button>
                          <button className="btn btn-primary add_btn reset_black_btn">Reset</button>
                      </div>
                  </div><br/>
                </div>}

              </div>
              <br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                  <div className="row print_hide">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <span className="subdvv1_1_1 mem_stat_left">
                          <input type="text" className="form-control" style={{display: 'inline-block',height: '31px',fontSize: '14px'}} placeholder="Please enter"/>
                          <Search color="#00000040" style={{top:'4px'}} size={15}/>
                      </span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                      <div className="mem_stat_btns" style={{float:'right',marginBottom:'10px'}}>
                          <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                          <CSVLink data={data} headers={headers} className="btn btn-primary add_btn cancel_btn">CSV</CSVLink>;
                          <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                      </div>
                    </div>
                  </div>    
                {this.state.currentTab==='rebate' &&
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>}
                {this.state.currentTab==='rebate_history' &&
                  <DataTable title="" columns={columns1} pagination data={data1} customStyles={customStyles}/>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CalculateMemberRebate;