import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
// import User from "../assets/user_placeholder.png"
import AdminNav from "./AdminNav"
import { makeRequest,makeFileUploadRequest,handleKeyUp,gameList } from '../../helpers'
class CreateMember extends React.Component {
  constructor(props) {
    super(props);
    this.showDiv = this.showDiv.bind(this)
	  this.makeRequest = makeRequest
    this.gameList = gameList
    this.changeEmail = this.changeEmail.bind(this)
    this.changeUsername = this.changeUsername.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.add = this.add.bind(this)
    this.checkGamess = this.checkGamess.bind(this)
  }
  state= {
    showGameControl:true,
	  gameData:[],
    checkGames:[],
    username:'',
    email:'',
    password:'',
    submitDisabled:false,
    error_msg:'',
    success_msg:'',
  }
  componentDidMount() {
	this.gameList(this);
  }
  showDiv(e){
	  if(e.target.value==="senior"){
		this.setState(state => ({showGameControl: !this.state.showGameControl}));
	  }
  }
  changeEmail(e){
		this.setState(state => ({email: e.target.value}));
  }
  changeUsername(e){
		this.setState(state => ({username: e.target.value}));
  }
  changePassword(e){
		this.setState(state => ({password: e.target.value}));
  }
  checkGamess(event) {
    var isChecked = event.target.checked;  
    var item = event.target.value;
    let myarray;			
    if(isChecked===true){
      myarray = {id: item,status: isChecked};
      this.state.checkGames.push(myarray);
    }else{
      var index_value=this.state.checkGames.findIndex(x => x.id ===item);
      this.state.checkGames.splice(index_value, 1);
    }
  } 
  async add(){
    if(this.state.username===''){
      this.setState(state => ({error_msg:'Enter username.'}));
    }else if(this.state.email===''){
      this.setState(state => ({error_msg:'Enter email address.'}));
    }else if((this.state.email).indexOf("@") === -1 || (this.state.email).indexOf(".") === -1){
      this.setState(state => ({error_msg:'Invalid email address.'}));
    }else if(this.state.password===''){
      this.setState(state => ({error_msg:'Enter password.'}));
    }else if((this.state.password).length<8){
      this.setState(state => ({error_msg:'Password must be atleast 8 Characters.'}));
    }else if((this.state.checkGames).length===0){
      this.setState(state => ({error_msg:'Please select games'}));
    }else{
      this.setState(state => ({submitDisabled:true}));
      let data = JSON.stringify({username: this.state.username,password: this.state.password,email: this.state.email,gameControl: this.state.checkGames});
      console.log(data);
      var result1=await this.makeRequest('senior/register', 'post', data,localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState({
          success_msg:'Senior added successfully.',error_msg:'',submitDisabled:false
        })
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled:false,success_msg:''}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false,success_msg:''}));
    }
  }
  render() {
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Member</span> / 
                    <span>Create Member</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Create Member</h5>
                    <div style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}><Link to="/admin/senior_management" style={{textDecoration:"none",color:"white"}}>Back to Senior Management</Link></button>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 divbody">
                <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                    <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Member Details</h5>
                </div>
                <div className="subbdvv1" style={{padding: '0px 18px 18px'}}>
                    <div className="row" style={{margin:0,}}>
                        <div style={{width:"100px"}}>
                            <img src={'../assets/user_placeholder.png'}/>
                        </div>
                        <div className="details_right_div" style={{width:"200px"}}>
                            <button className="btn btn-primary add_btn" style={{width:'88px'}}>Upload</button><br/>
                            <button className="btn btn-primary add_btn cancel_btn" style={{marginLeft:"0px",width:'88px',marginTop:"8px"}}>Remove</button>
                        </div>
                    </div>
                </div>
                <div className="row" style={{margin:0,padding:'12px 14px 48px',background:'white'}}>
                  <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                    {this.state.success_msg!=='' ?
                      (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg}</p>):('')
                    }
                    {this.state.error_msg!=='' ?
                      (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg}</p>):('')
                    }
                  </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                        <label className="labls"><span style={{fontWeight:"700"}}>Username:</span>
                        <input type="text" className="form-control" placeholder="Username" onChange={this.changeUsername}/></label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                        <label className="labls"><span style={{fontWeight:"700"}}>Email:</span>
                        <input type="text" className="form-control" placeholder="Email" onChange={this.changeEmail}/></label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                        <label className="labls"><span style={{fontWeight:"700"}}>Password:</span>
                        <input type="password" className="form-control" placeholder="Password" onChange={this.changePassword}/></label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                        <label className="labls"><span style={{fontWeight:"700"}}>Full Name:</span>
                        <input type="text" className="form-control" placeholder="Full Name"/></label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                        <label className="labls"><span style={{fontWeight:"700"}}>Phone:</span>
                        <input type="text" className="form-control" placeholder="Phone"/></label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                        <label className="labls"><span style={{fontWeight:"700"}}>Date of birth:</span>
                        <input type="date" className="form-control"/></label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                        <label className="labls"><span style={{fontWeight:"700"}}>Gender:</span>
                        <select className="form-control" style={{width:"100%"}}>
                            <option defaultValue="">Gender</option>
                            <option defaultValue="male">Male</option>
                            <option defaultValue="female">Female</option>
                        </select></label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                        <label className="labls"><span style={{fontWeight:"700"}}>Role:</span>
                        <select className="form-control" style={{width:"100%"}} onChange={this.showDiv}>
                            <option value="senior">Senior</option>
                        </select></label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                        <label className="labls"><span style={{fontWeight:"700"}}>Commission for upline:</span>
                        <input type="text" className="form-control" placeholder="%"/></label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 top_div3">
                        <label className="labls"><span style={{fontWeight:"700"}}>Note:</span>
                        <textarea className="form-control" style={{height:"100px"}} placeholder="Note"/></label>
                    </div>
                    <br/>
                    <div className="create_mem_btn" style={{marginTop:"28px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}} onClick={this.add} disabled={this.state.submitDisabled}>Create Member</button>
                    </div>
                </div>
            </div>
              {this.state.showGameControl ?
              (<div className="col-md-12 divbody">
                  <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                      <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Game Control</h5>
                  </div>
                  <div className="row" style={{margin:0,padding:'12px 14px',background:'white'}}>
                  
                      <div className="col-md-6 top_div3">
                      <ul className="comm_div">
                            <li className="li_head">Game Name <span>No/Yes</span></li>
                            {this.state.gameData.map(data => (
                              <li className="game_provider_checkbox" key={data.id}>{data.name} 
                              <span>
                                <label className="switch">
                                  <input type="checkbox" defaultValue={data.id} onClick={this.checkGamess}/>
                                  <span className="slider round"></span>
                                </label>
                              </span>
                              </li>
                            ))}
                          </ul>
                      </div>
                      {/*<div className="col-md-6 top_div3">
                          <ul className="comm_div">
                            <li className="li_head">Game Name <span>No/Yes</span></li>
                            <li>Poker A <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                            <li>Poker B <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                            <li>Poker C <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                            <li>Poker D <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                            <li>Poker E <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
					  </ul>
                      </div>*/}
                  </div><br/>
              </div>):('')}
          </div>
        </div>
      </div>
    );
  }
}
export default CreateMember;