import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
import { CSVLink } from "react-csv";
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,misc: "TOPPAY33",total_records:"1",total_claims:"0.00"},
  {id: 2,misc: "TOPPAY33",total_records:"1",total_claims:"0.00"},
  {id: 3,misc: "TOPPAY33",total_records:"1",total_claims:"0.00"},
  {id: 4,misc: "TOPPAY33",total_records:"1",total_claims:"0.00"},
  {id: 5,misc: "TOPPAY33",total_records:"1",total_claims:"0.00"},
  {id: 6,misc: "TOPPAY33",total_records:"1",total_claims:"0.00"},
  {id: 7,misc: "TOPPAY33",total_records:"1",total_claims:"0.00"},
  {id: 8,misc: "TOPPAY33",total_records:"1",total_claims:"0.00"},
  {id: 9,misc: "TOPPAY33",total_records:"1",total_claims:"0.00"},
  {id: 10,misc: "TOPPAY33",total_records:"1",total_claims:"0.00"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '13px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'800'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'700!important'
    },
  },
};
const excelData = [
  {
    "Misc": "TOPPAY33",
    "Total Records": "4",
    "Total Claims": 0.09,
  },
  {
    "Misc": "TOPPAY33",
    "Total Records": "4",
    "Total Claims": 0.09,
  },
  {
    "Misc": "TOPPAY33",
    "Total Records": "4",
    "Total Claims": 0.09,
  },
  {
    "Misc": "TOPPAY33",
    "Total Records": "4",
    "Total Claims": 0.09,
  }
]
const headers = [
  { label: "Misc", key: "misc" },
  { label: "Total Records", key: "total_records" },
  { label: "Total Claims", key: "total_claims" },
];
class RebateReport extends React.Component {
  constructor(props) {
  super(props);
  this.tableRef = React.createRef();
  this.printData = this.printData.bind(this)
  this.exportData = this.exportData.bind(this)
  }
  printData(){
    window.print();
  }
  exportData(){
    exportFromJSON({ data: excelData, fileName: 'Rebate report', exportType: exportFromJSON.types.xls})
  }
  render() {
    const columns = [
      {name: 'No.',selector: 'id',width:"100px",cell: row => <div data-tag="allowRowEvents"> {row.id}</div>},
      {name: 'Misc',selector: 'misc',width:"300px",cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>TOPPAY33</div>},
      {name: 'Total Records',selector: 'total_records',width:"500px",cell: row => <div data-tag="allowRowEvents">1</div>},
      {name: 'Total Claims',selector: 'total_claims',cell: row => <div data-tag="allowRowEvents">0.09</div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9 plo_right" style={{padding: 0}}>
            <div style={{padding:'0px'}} className="print_hide">
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Rebate</span> / 
                    <span>Rebate Report</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Rebate Report</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="subbdvv1 print_hide" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                    <h6 style={{fontWeight:700,margin:0}}>Search Criteria</h6>
                  </div>
              <div className="row print_hide" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-12">
                    <div className="top_second_div">
                        <label className="labls"><span>Date Range:</span> 
                            <input type="date" className="form-control" placeholder="Start time - end of time"/>
                        </label>
                        <label className="labls"><span style={{minWidth:"48px"}}>Status:</span> 
                            <select className="form-control" style={{width:"194px"}}>
                                <option>All</option>
                            </select>
                        </label>
                        <label className="labls"><span style={{minWidth:"84px"}}>Agent Code:</span> <input type="text" className="form-control"/></label>
                    </div><br/>
                    <div className="top_second_div">
                        <label className="labls"><span>Account Id:</span> <input type="text" className="form-control"/></label>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Member</button>
                        <button className="btn btn-primary add_btn cancel_btn">Agent</button>
                        <button className="btn btn-primary add_btn cancel_btn last_btn">Reset</button>
                    </div>
                </div>
              </div><br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <span className="subdvv1_1_1 print_hide">
                    <input type="text" className="form-control search" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Please enter the"/>
                    <Search color="#00000040" style={{top:'4px'}} size={15}/>
                </span>
                <div className="top_btns print_hide" style={{float:'right',marginBottom:'10px'}}>
                    <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                    <CSVLink data={data} headers={headers} className="btn btn-primary add_btn cancel_btn">CSV</CSVLink>;
                    <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                </div>
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RebateReport;