const slots_data = [
  {
    id: 1,gname: "Pirates Party", img: "slot_game.png",category: 'slots'
  },
  {
    id: 2,gname: "Fairy Fantasy", img: "fairyparty.png",category: 'progressive'
  },
  {
    id: 3,gname: "Five of Swords", img: "fiveofswords.png",category: 'table_games'
  },
  {
    id: 4,gname: "Vampire Track", img: "vampiretrack.png",category: 'slots'
  },
  {
    id: 5,gname: "Ten of Swords", img: "tenofswords.png",category: 'table_games'
  },
  {
    id: 6,gname: "Moth Boy", img: "mothboy.png",category: 'progressive'
  },
  {
    id: 7,gname: "Four of Wands", img: "fourwands.png",category: 'video'
  },
  {
    id: 8,gname: "Wolfstone", img: "wolfstone.png",category: 'slots'
  },
  {
    id: 9,gname: "Goblin Tour", img: "goblintour.png",category: 'video'
  },
  {
    id: 10,gname: "The New Moon", img: "thenewmoon.png",category: 'video'
  }
];
export default slots_data;
