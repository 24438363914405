import React from "react";
//import Navbar from './Navbar';
import Navbar from './Navbar_new';
import Footer from './Footer';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { CaretDownFill,CaretUpFill} from 'react-bootstrap-icons';
import { makeRequest } from '../helpers'

class Home_new extends React.Component {
   constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.ppModal = this.ppModal.bind(this)
    this.games = this.games.bind(this)
    this.gameInfo = this.gameInfo.bind(this)
    this.gameInfoModal = this.gameInfoModal.bind(this)
    this.toggleCurrencyFilter = this.toggleCurrencyFilter.bind(this)
    this.switch_currency = this.switch_currency.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.deposit_amount = this.deposit_amount.bind(this)
  }
  state= {
    show_ppModal:false,
    show_gameinfoModal:false,
    showCurrencyFilter:false,
    submitDisabled:true,
    content:'',
    chosen_currency:'USD',
    games_data:[],
    game_info:{},
    fiat_currency:JSON.parse(localStorage.getItem('fiat_currency')),
    success_msg:'',
    error_msg:'',
    amount:0,
    game_title:'',
    availableWalletBalance:0,
    wallet_currency:''
  }
  ppModal(val,content){
    this.setState({show_ppModal: val,content:content})
  }
  async games(){
    let result1=await this.makeRequest('game?limit=12', 'get', '','');
    if(result1.status===200){
      this.setState({
        games_data:result1.data.data
      })
    }
  }
  async gameInfo(val){
    let result1=await this.makeRequest('evoplay/game/'+val, 'get', '','');
    localStorage.setItem('game_id',val);
    if(result1.status===200){
      this.setState({game_info:result1.data.data});
      this.setState({game_title:result1.data.data.title});
      this.gameInfoModal(true);
      if(localStorage.getItem('token')!=='' && localStorage.getItem('token')!==null && localStorage.getItem('token')!=='null' && localStorage.getItem('token')!=='undefined' && localStorage.getItem('token')!==undefined){
        (async () => {
          var result1=await this.makeRequest('wallet/balance', 'get', '',localStorage.getItem('token'));
          if(result1.status===200){
            this.setState({availableWalletBalance:(result1.data.value).toFixed(2),wallet_currency:result1.data.currency})
          }
        })();
      }
    }
  }
  gameInfoModal(val){
    this.setState({success_msg:'',error_msg:'',show_gameinfoModal: val})
  }
  toggleCurrencyFilter(val,val2){
    this.setState({showCurrencyFilter: val})
    if(val2!==''){
      let data = JSON.stringify({currency: val2});
      this.setState({chosen_currency: val2})
      this.switch_currency(data);
    }
  }
  async switch_currency(data){
    if(localStorage.getItem('token')==='' || localStorage.getItem('token')===null || localStorage.getItem('token')==='null' || localStorage.getItem('token')==='undefined' || localStorage.getItem('token')===undefined){
      this.setState({error_msg: 'You need to login first to access the following features.'})
    }else{
      let result1=await this.makeRequest('evoplay/user/switch-currency', 'post', data,localStorage.getItem('token'));
      if(result1.status===200){
        console.log(result1.data);
        let bal=(result1.data.data.balance);
        this.setState({success_msg: 'Balance: '+(bal)+(result1.data.data.currency)})
      }else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message}));
      }else if(result1.status===500){
        this.setState(state => ({error_msg:'Some problem occured. Please try again.'}));
      }
    }
  }
  handleChange(e) {
    //let fnameValid = e.target.value ? true : false;
    this.setState({amount: e.target.value})
    if(e.target.value!==''){
      this.setState({submitDisabled: false})
    }else{
      this.setState({submitDisabled: true})
    }
  }
  async deposit_amount(){
    if(localStorage.getItem('token')==='' || localStorage.getItem('token')===null || localStorage.getItem('token')==='null' || localStorage.getItem('token')==='undefined' || localStorage.getItem('token')===undefined){
      this.setState({error_msg: 'You need to login first to access the following features.'})
    }else{
      if(parseFloat(this.state.amount)>parseFloat(this.state.availableWalletBalance)){
        this.setState({error_msg: 'Insufficient balance in wallet.'})
      }else{
        this.setState({submitDisabled: true})
        let data = JSON.stringify({amount:parseFloat(this.state.amount),currency: this.state.chosen_currency});
        let result1=await this.makeRequest('evoplay/user/deposit', 'post', data,localStorage.getItem('token'));
        if(result1.status===200){
          //console.log(result1.data);
          this.setState({submitDisabled: false,success_msg: 'Amount deposited successfully.'})
          setTimeout(
            function() {
              if(result1.status===200){
                this.props.history.push('/play_game#'+this.state.game_title);
              }
            }.bind(this),3000
          );
        }else if(result1.status===400){
          this.setState(state => ({error_msg:result1.data.message}));
        }else if(result1.status===500){
          this.setState(state => ({error_msg:'Some problem occured. Please try again.'}));
        }
      }
    }
  }
  
  componentDidMount(){
    this.games();
  }
  render() {
    return (
        <div style={{position:'relative'}}>
          {/* <Navbar prop={this.props}/> */}
          <Navbar prop={this.props}/>
          <div style={{background:'#000C16',color:'white'}}>
            {/* banner */}
            <div className="banner_divv">
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-12 payoutss">
                    <h1>#1 E-GAMING PLATFORM</h1>
                    <h1 style={{color:'#EEB84E'}}>WITH DAILY PAYOUTS</h1><br/>
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <img alt="pay1" src={'assets/dicee.png'}/>
                        <div className="grids">
                          <span>3500 +</span><br/>
                          <span>Games</span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <img alt="pay2" src={'assets/coinss.png'}/>
                        <div className="grids">
                          <span>VIGOBET</span><br/>
                          <span>High Stakes</span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <img alt="pay3" src={'assets/bitss.png'}/>
                        <div className="grids">
                          <span>FREE BTC</span><br/>
                          <span>Every 20mins</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-12 payout_img" style={{margin:"auto"}}>
                    <img alt="pay4" src={'assets/lion_mascot.png'} className="w-100"/>
                  </div>
                </div><br/><br/><br/><br/>
                <div className="row justify-content-center assets_div">
                  <div className="col-lg-10 col-md-12">
                    <div className="row" style={{textAlign:"center"}}>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <img alt="pay5" src={'assets/asset1.png'}/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <img alt="pay6" src={'assets/asset2.png'}/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <img alt="pay7" src={'assets/asset3.png'}/>
                      </div>
                    </div>
                  </div>
                </div><br/><br/>
              </div>
            </div>
            {/* banner */}

            {/* <br/><br/><br/><br/><br/><br/> */}

            {/*  section */}
            <div className="games_here_div">
              <div style={{padding:'16px 0',background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.1) 100%)'}} className="thrddivnav">
                <nav className="navbar-expand-lg container thrd_nav text-white">
                  <ul className="navbar-nav mb-2 mb-lg-0 d-flex" style={{marginLeft:'auto',justifyContent: 'space-between'}}>
                    <li className="nav-item">
                      <Link to="/live_casino" className="nav-link">
                        <img alt="nav game1" src={'assets/games_icons/poker-full.png'}/><br/>
                        <span className="txtt11">live casino</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/slots" className="nav-link">
                        <img alt="nav game2" src={'assets/games_icons/slot-machine.png'}/><br/>
                        <span className="txtt11">slots</span>
                      </Link>
                    </li>
                    <li className="nav-item"  onClick={() => this.ppModal(true,'Sportsbook')}>
                      <a href="/" className="nav-link">
                        <img alt="nav game3" src={'assets/games_icons/soccer-ball-variant.png'} /><br/>
                        <span className="txtt11">sports</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/" className="nav-link">
                        <img alt="nav game4" src={'assets/games_icons/game-controller.png'}/><br/>
                        <span className="txtt11">e-sports</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => this.ppModal(true,'4D Lottery')}>
                      <a href="/" className="nav-link">
                        <img alt="nav game5" src={'assets/games_icons/dice.png'}/><br/>
                        <span className="txtt11">4d lottery</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => this.ppModal(true,'Prediction')}>
                      <a href="/" className="nav-link">
                        <img alt="nav game6" src={'assets/games_icons/roulette.png'}/><br/>
                        <span className="txtt11">prediction</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        <img alt="nav game8" src={'assets/games_icons/spade-token.png'}/><br/>
                        <span className="txtt11">mini games</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        <img alt="nav game9" src={'assets/games_icons/tournament.png'}/><br/>
                        <span className="txtt11">tournament</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div><br/><br/>
              <div className="container">
                <div className="row" style={{margin:0}}>
                  <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12" style={{margin:'auto'}}>
                    <div className="fst_game_desc_area">
                      <h1 style={{color:'#EEB84E',fontWeight:'700'}}>ALL GAMES HERE</h1><br/>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div><br/>
                    <div className="scnd_game_desc_area">
                      <img alt="jackpots" src={'assets/coin1.png'}/>
                      <div>
                        <span>Total Jackpots</span>
                        <h1>5.12345 BTC</h1>
                      </div>
                    </div><br/>
                    <button className="btn btn-primary all_games_btn">ALL GAMES</button>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 game_imgs_div">
                    <div className="row" style={{width:"100%",margin:"0"}}>
                       {this.state.games_data.map((games) => (
                        <div key={games.id} className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt={games.name} src={games.image} onClick={()=>this.props.history.push('/pick_new_currency#'+games.gameId)} style={{cursor:'pointer',borderRadius:"12px"}}/></div>
                      ))} 
                      {/* {this.state.games_data.map((games, index) => {
                        if (games.length < 2) {
                            return (<div key={index} className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt={games.name} src={'assets/gamess1.png'}/></div>
                          )}
                        }
                      )} */}
                      {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game1" src={'assets/gamess1.png'} onClick={()=>this.props.history.push('/pick_new_currency#4')} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game2" src={'assets/gamess2.png'} onClick={()=>this.gameInfo(7)} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game3" src={'assets/gamess3.png'} onClick={()=>this.gameInfo(10)} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game4" src={'assets/gamess4.png'} onClick={()=>this.gameInfo(13)} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game5" src={'assets/gamess5.png'} onClick={()=>this.gameInfo(16)} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game6" src={'assets/gamess6.png'} onClick={()=>this.gameInfo(19)} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game7" src={'assets/gamess7.png'} onClick={()=>this.gameInfo(22)} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game8" src={'assets/gamess8.png'} onClick={()=>this.gameInfo(25)} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game9" src={'assets/gamess9.png'} onClick={()=>this.gameInfo(28)} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game10" src={'assets/gamess10.png'} onClick={()=>this.gameInfo(34)} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game11" src={'assets/gamess3.png'} onClick={()=>this.gameInfo(37)} style={{cursor:'pointer'}}/></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6"><img alt="game12" src={'assets/gamess4.png'} onClick={()=>this.gameInfo(76)} style={{cursor:'pointer'}}/></div> */}
                    </div>	
                  </div>
                </div>
              </div>
            </div>
			      <Modal size="md" show={this.state.show_gameinfoModal} className="pwd_changed_modal bank_account_modal wallet_modal">
              <Modal.Header style={{background:'none'}} closeButton onClick={() => this.gameInfoModal(false)}></Modal.Header>
              <Modal.Body>
                <div className="">
                  <h2 style={{fontSize: '30px',fontWeight: 600,margin: 0}} className="text-white text-center">{this.state.content}</h2>
                  <div className="text-center">
                      <h3>{this.state.game_info.title}</h3><br/>
                      <div className="row justify-content-center" style={{margin:0,textAlign:'left'}}>
                        <div className="col-lg-12">
                          <div>
                            <span>Game type: </span>
                            <span style={{float:'right'}}>{this.state.game_info.game_sub_type}</span>
                          </div>
                          <div>
                            <span>Available free spins count: </span>
                            <span style={{float:'right'}}>{this.state.game_info.available_freespins_count}</span>
                          </div><br/>
                          {(this.state.availableWalletBalance!=='' && this.state.availableWalletBalance!==0) ?
                            (<p style={{fontSize: '16px',textAlign:'center'}}>Wallet Balance: {this.state.availableWalletBalance+' '+this.state.wallet_currency}</p>):('')
                          }
                          {this.state.success_msg!=='' ?
                            (<p style={{fontSize: '16px',color: 'green',textAlign:'center'}}>{this.state.success_msg}</p>):('')
                          }
                          {this.state.error_msg!=='' ?
                            (<p style={{fontSize: '16px',color: 'red',textAlign:'center'}}>{this.state.error_msg}</p>):('')
                          }
                          <div>
                            <span>Balance in</span>
                            <div className="wallet_deposit_filter" style={{display: 'inline-block',float: 'right'}}>
                              <p className="wallet_select" style={{marginBottom:"0",lineHeight:"32px",textTransform:"capitalize",cursor: 'pointer',paddingRight:'24px'}} onClick={() => this.toggleCurrencyFilter(!this.state.showCurrencyFilter,'')}>{this.state.chosen_currency}
                                {this.state.showCurrencyFilter ?
                                (<span><CaretDownFill color="white" size={14} /></span>):
                                (<span><CaretUpFill color="white" size={14} /></span>)}
                              </p>
                              {this.state.showCurrencyFilter ?
                              (<div className="under_wallet_deposit_filter">
                                <ul style={{padding: 0,listStyle: 'none'}}>
                                  {this.state.fiat_currency.map((currency,index) => (
                                    <li key={index} onClick={() => this.toggleCurrencyFilter(false,currency)}>{currency}</li>
                                  ))}
                                  {/* <li onClick={() => this.closeDepositCryptoModal(true)}>Crypto</li>
                                  <li onClick={() => this.fiatDiv(true)}>Fiat</li> */}
                                </ul>
                              </div>):('')}
                            </div>
                          </div>
                          <div>
                            <div><label>Amount</label></div>
                            <input type="number" onChange={this.handleChange} style={{width: '70%',display: 'inline-block'}} className="form-control"/>
                            <button style={{float:'right'}} className="btn btn-success" onClick={this.deposit_amount} disabled={this.state.submitDisabled}>Real play</button>
                          </div>
                        </div>
                      </div>
                  </div><br/>
                </div>
              </Modal.Body>
            </Modal>
			      <Modal size="lg" show={this.state.show_ppModal} className="pwd_changed_modal bank_account_modal wallet_modal">
              <Modal.Header style={{background:'none'}} closeButton onClick={() => this.ppModal(false,'')}></Modal.Header>
              <Modal.Body>
                <div className="">
                  <h2 style={{fontSize: '30px',fontWeight: 600,margin: 0}} className="text-white text-center">{this.state.content}</h2>
                  <div className="text-center"><img alt="line" src={'assets/aboutus.png'}/></div><br/>
                  <p>GboBet is today one of the leading suppliers of online gaming products worldwide, offering a wide range of first-class online gambling products and games in a safe and user-friendly gaming environment. GboBet has been able establish themselves as one of the most trusted and reliable gaming companies in the market nowadays.</p>
                  <p>GboBet offers its customers a wide selection of innovative products such as Live Casino, Sports Betting and slots games. In our aim to provide the best service online we also offer the unique and exclusive support guarantee, which applies 24 hours a day, 7 days a week. Our support team is available through e-mail, live chat and telephone. Please visit the support section for further details.</p>
                  <p>With a solid product range and unique customer support, GboBet becomes the obvious choice for gaming enthusiasts all over the world!</p>
                  <p>Thank you for choosing Gbobet.com as your preferred choice of online gaming. We hope you enjoy playing with us, and if there is anything we can help you with please do not hesitate to contact us.</p>
                </div>
              </Modal.Body>
            </Modal>
            {/* games section */}

            {/* slots games section */}
            <div className="slots_game_main_div" style={{backgroundImage: 'url(assets/bckground.png)'}}>
              <div className="container">
                <h1 style={{fontWeight: 'bold'}} className="text-center"><span style={{color:'#EEB84E'}}>HUGE VARIETY </span>OF SLOT GAMES</h1><br/>
                <div className="row justify-content-center" style={{textAlign:"center"}}>
                  <div className="col-xl-11">
                    <div className="row huge_slot_games">
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game1.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game2.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game3.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game4.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game5.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game6.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game7.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game8.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game9.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game10.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game11.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game12.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game13.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game14.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game15.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game16.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game17.png'}/>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <img alt="slot_img" className="w-100" src={'assets/slots_games/slot_game18.png'}/>
                      </div>
                    </div>
                  </div>
                </div><br/>
                <div className="text-center">
                  <button className="btn btn-primary all_games_btn">ALL GAMES</button>
                </div>
              </div>
            </div>
            {/* slots games section */}

            {/* betting */}
            <div className="betting_div" style={{backgroundImage:'url(assets/mining.png)'}}>
              <div className="container">
                <h1 style={{fontWeight: 'bold'}} className="text-center">BETTING IS MINING <span style={{color:'#EEB84E'}}>STAKING IS EARNING</span></h1><br/><br/><br/>
                <div className="row" style={{margin:0}}>
                  <div className="col-lg-5 col-md-12">
                    
                  </div>
                  <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    <div className="winners_div">
                      <div className="s_dv1">OUR WINNERS</div><br/>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="s_dv2">
                            <div className="headd1 text-center">Last Winners</div>
                            <div className="s_dv3">
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="s_dv2">
                            <div className="headd1 text-center" style={{background:'#2283F6'}}>Big Winners</div>
                            <div className="s_dv3">
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="s_dv2">
                            <div className="headd1 text-center" style={{background:'#F2BA2F'}}>Jackpots</div>
                            <div className="s_dv3">
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div><br/>
                              <div>
                                <img alt="game_img" src={'assets/game_name.png'}/>
                                <div className="s_dv2_1">
                                  <span>User12345</span><br/>
                                  <span style={{color:'#F2BA2F'}}>0.0000002 BNB</span><br/>
                                  <span>Dice</span><br/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* betting */}

            <Footer/>
          </div>
        </div>
    );
  }
}

export default Home_new;
