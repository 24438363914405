import './App.css';
import {Switch, Route,BrowserRouter as Router,Redirect } from 'react-router-dom';
import Home_new from './components/Home_new';
import LiveCasino from './components/LiveCasino';
import Slots from './components/Slots';
import CockFighting from './components/CockFighting';
import CreateAccount from './components/CreateAccount';
import VerifyEmail from './components/VerifyEmail';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import AboutUs from './components/AboutUs';
import VIP from './components/VIP';
import MyAccount1 from './components/MyAccount1';
import Transfer from './components/Transfer';
import History from './components/History';
import Deposit from './components/Deposit';
import ReferTo from './components/ReferTo';
import Referral from './components/Referral';
import Withdrawal from './components/Withdrawal';
import Mailbox from './components/Mailbox';
import ComingSoon from './components/ComingSoon';
import Promotion from './components/Promotion';
import AdminLogin from './components/admin/AdminLogin';
import OwnerLogin from './components/admin/OwnerLogin';
import PlatformOwners from './components/admin/PlatformOwners';
import AddPlatformOwner from './components/admin/AddPlatformOwner';
import PlatformOwnerDets from './components/admin/PlatformOwnerDets';
import EditPlatformOwner from './components/admin/EditPlatformOwner';
import Dashboard from './components/admin/Dashboard';
import PersonalSettings from './components/admin/PersonalSettings';
import ChangePassword from './components/admin/ChangePassword';
import SendMessage from './components/admin/SendMessage';
import SendHistory from './components/admin/SendHistory';
import TransactionRequest from './components/admin/TransactionRequest';
import TransactionHistory from './components/admin/TransactionHistory';
import MemberListing from './components/admin/MemberListing';
import OnlineMember from './components/admin/OnlineMember';
import MemberDetails from './components/admin/MemberDetails';
import MemberGroup from './components/admin/MemberGroup';
import BatchAdjustment from './components/admin/BatchAdjustment';
import AdjustmentHistory from './components/admin/AdjustmentHistory';
import AgentManagement from './components/admin/AgentManagement';
import CreateAgent from './components/admin/CreateAgent';
import AgentPerformance from './components/admin/AgentPerformance';
import AgentCreditSettlement from './components/admin/AgentCreditSettlement';
import CreditHistory from './components/admin/CreditHistory';
import Banks from './components/admin/Banks';
import DailyBankReport from './components/admin/DailyBankReport';
import BankStatement from './components/admin/BankStatement';
import DetailedBankReport from './components/admin/DetailedBankReport';
import ProductMaintenance from './components/admin/ProductMaintenance';
import UpdateProductDetails from './components/admin/UpdateProductDetails';
import RebateSetting from './components/admin/RebateSetting';
import UpdateRebateSetting from './components/admin/UpdateRebateSetting';
import CalculateMemberRebate from './components/admin/CalculateMemberRebate';


import RebateReport from './components/admin/RebateReport';
import CashBackSetting from './components/admin/CashBackSetting';
import EditCashBack from './components/admin/EditCashBack';
import MemberCashBack from './components/admin/MemberCashBack';
import MemberCashBackHistory from './components/admin/MemberCashBackHistory';
import WinLossReport from './components/admin/WinLossReport';
import DetailedWinLossReport from './components/admin/DetailedWinLossReport';
import TransferReport from './components/admin/TransferReport';
import GameStatistic from './components/admin/GameStatistic';
import WebsiteStatistic from './components/admin/WebsiteStatistic';
import MemberStatistic from './components/admin/MemberStatistic';
import MemberStatisticAdvanced from './components/admin/MemberStatisticAdvanced';
import TransactionStatistics from './components/admin/TransactionStatistics';
import PlayerFreqReport from './components/admin/PlayerFreqReport';
import InactivePlayer from './components/admin/InactivePlayer';
import AffiliateBO from './components/admin/AffiliateBO';
import SmsBlast from './components/admin/SmsBlast';
import EmailBlast from './components/admin/EmailBlast';
import AccountManagement from './components/admin/AccountManagement';
import UpdateOperatorProfile from './components/admin/UpdateOperatorProfile';
import RoleManagement from './components/admin/RoleManagement';
import AssignModule from './components/admin/AssignModule';
import Transaction from './components/admin/Transaction';
import BonusAdjustment from './components/admin/BonusAdjustment';
import OffRecord from './components/admin/OffRecord';
import BatchAdjust from './components/admin/BatchAdjust';
import BatchWallet from './components/admin/BatchWallet';
import TransactionReport from './components/admin/TransactionReport';
import TransactionHistoryy from './components/admin/TransactionHistoryy';
import TransactionDetail from './components/admin/TransactionDetail';
import BankAccounts from './components/admin/BankAccounts';
import SeniorManagement from './components/admin/SeniorManagement';
import CreateMember from './components/admin/CreateMember';
import CommSetting from './components/admin/CommSetting';
import SeniorDetails from './components/admin/SeniorDetails';
import MasterManagement from './components/admin/MasterManagement';
import CreateMaster from './components/admin/CreateMaster';
import AddDepositChannel from './components/admin/AddDepositChannel';
import DepositChannel from './components/admin/DepositChannel';
import DepositChannelDets from './components/admin/DepositChannelDets';
import EditDepositChannel from './components/admin/EditDepositChannel';
import GameList from './components/admin/GameList';
import TransactionDetails from './components/admin/TransactionDetails';
import Wallets from './components/Wallets';
import PlayGame from './components/PlayGame';
import PickNewCurrency from './components/PickNewCurrency';


import MemberGroups from './components/admin/MemberGroups';
import AddMemberGroup from './components/admin/AddMemberGroup';
import EditMemberGroup from './components/admin/EditMemberGroup';

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/main.css'
import './assets/main1.css'

function App() {
  return (
    <div className="app">
      <Router basename={"/"}>
        <Switch>
          <Route exact path="/"
            render={() => {
              return (
                <Redirect to="/home" />
              )
          }}/>
          <Route path="/home" exact component={Home_new}/>
          <Route path="/live_casino" exact component={LiveCasino}/>
          <Route path="/slots" exact component={Slots}/>
          <Route path="/cock_fighting" exact component={CockFighting}/>
          <Route path="/create_account" component={CreateAccount}/>
          <Route path="/verify_email" exact component={VerifyEmail}/>
          <Route path="/forgot_password" exact component={ForgotPassword}/>
          <Route path="/reset-password/:code" exact component={ResetPassword}/>
          <Route path="/about_us" exact component={AboutUs}/>
          <Route path="/vip" exact component={VIP}/>
          <Route path="/my_account1" exact component={MyAccount1}/>
          <Route path="/transfer" exact component={Transfer}/>
          <Route path="/history" exact component={History}/>
          <Route path="/deposit" exact component={Deposit}/>
          <Route path="/refer_to" exact component={ReferTo}/>
          <Route path="/referral" exact component={Referral}/>
          <Route path="/withdrawal" exact component={Withdrawal}/>
          <Route path="/mailbox" exact component={Mailbox}/>
          <Route path="/coming_soon" exact component={ComingSoon}/>
          <Route path="/promotion" exact component={Promotion}/>
          <Route path="/admin/admin_login" exact component={AdminLogin}/>
          <Route path="/admin/owner_login" exact component={OwnerLogin}/>
          <Route path="/admin/platform_owners" exact component={PlatformOwners}/>
          <Route path="/admin/add_platform_owner" exact component={AddPlatformOwner}/>
          <Route path="/admin/platform_owner_detail" exact component={PlatformOwnerDets}/>
          <Route path="/admin/edit_platform_owner" exact component={EditPlatformOwner}/>
          <Route path="/admin/dashboard" exact component={Dashboard}/>
          <Route path="/admin/personal_settings" exact component={PersonalSettings}/>
          <Route path="/admin/change_password" exact component={ChangePassword}/>
          <Route path="/admin/send_message" exact component={SendMessage}/>
          <Route path="/admin/send_history" exact component={SendHistory}/>
          <Route path="/admin/transaction_request" exact component={TransactionRequest}/>
          <Route path="/admin/transaction_history" exact component={TransactionHistory}/>
          <Route path="/admin/member_listing" exact component={MemberListing}/>
          <Route path="/admin/online_member" exact component={OnlineMember}/>
          <Route path="/admin/member_details" exact component={MemberDetails}/>
          <Route path="/admin/member_group" exact component={MemberGroup}/>
          <Route path="/admin/batch_adjustment" exact component={BatchAdjustment}/>
          <Route path="/admin/adjustment_history" exact component={AdjustmentHistory}/>
          <Route path="/admin/agent_mgmt" exact component={AgentManagement}/>
          <Route path="/admin/create_agent" exact component={CreateAgent}/>
          <Route path="/admin/agent_performance" exact component={AgentPerformance}/>
          <Route path="/admin/agent_credit_settlement" exact component={AgentCreditSettlement}/>
          <Route path="/admin/credit_history" exact component={CreditHistory}/>
          <Route path="/admin/banks" exact component={Banks}/>
          <Route path="/admin/daily_bank_report" exact component={DailyBankReport}/>
          <Route path="/admin/bank_stmt" exact component={BankStatement}/>
          <Route path="/admin/detailed_bank_report" exact component={DetailedBankReport}/>
          <Route path="/admin/product_maintenance" exact component={ProductMaintenance}/>
          <Route path="/admin/update_product_details" exact component={UpdateProductDetails}/>
          <Route path="/admin/rebate_setting" exact component={RebateSetting}/>
          <Route path="/admin/update_rebate_setting" exact component={UpdateRebateSetting}/>
          <Route path="/admin/calculate_member_rebate" exact component={CalculateMemberRebate}/>
		  
		  
          <Route path="/admin/rebate_report" exact component={RebateReport}/>
          <Route path="/admin/cash_back_setting" exact component={CashBackSetting}/>
          <Route path="/admin/edit_cash_back" exact component={EditCashBack}/>
          <Route path="/admin/member_cash_back" exact component={MemberCashBack}/>
          <Route path="/admin/member_cash_back_history" exact component={MemberCashBackHistory}/>
          <Route path="/admin/win_loss_report" exact component={WinLossReport}/>
          <Route path="/admin/detailed_win_loss_report" exact component={DetailedWinLossReport}/>
          <Route path="/admin/transfer_report" exact component={TransferReport}/>
          <Route path="/admin/game_statistic" exact component={GameStatistic}/>
          <Route path="/admin/website_statistic" exact component={WebsiteStatistic}/>
          <Route path="/admin/member_statistic" exact component={MemberStatistic}/>
          <Route path="/admin/member_statistic_advanced" exact component={MemberStatisticAdvanced}/>
          <Route path="/admin/transaction_statistic" exact component={TransactionStatistics}/>
          <Route path="/admin/player_freq_report" exact component={PlayerFreqReport}/>
          <Route path="/admin/inactive_player" exact component={InactivePlayer}/>
          <Route path="/admin/affiliate_bo" exact component={AffiliateBO}/>
          <Route path="/admin/sms_blast" exact component={SmsBlast}/>
          <Route path="/admin/email_blast" exact component={EmailBlast}/>
          <Route path="/admin/account_management" exact component={AccountManagement}/>
          <Route path="/admin/update_operator_profile" exact component={UpdateOperatorProfile}/>
          <Route path="/admin/role_management" exact component={RoleManagement}/>
          <Route path="/admin/assign_module" exact component={AssignModule}/>
          <Route path="/admin/transaction" exact component={Transaction}/>
          <Route path="/admin/bonus_adjustment" exact component={BonusAdjustment}/>
          <Route path="/admin/off_record" exact component={OffRecord}/>
          <Route path="/admin/batch_adjust" exact component={BatchAdjust}/>
          <Route path="/admin/batch_wallet" exact component={BatchWallet}/>
          <Route path="/admin/transaction_report" exact component={TransactionReport}/>
          <Route path="/admin/transaction_historyy" exact component={TransactionHistoryy}/>
          <Route path="/admin/transaction_detail" exact component={TransactionDetail}/>
          <Route path="/admin/bank_accounts" exact component={BankAccounts}/>
          <Route path="/admin/senior_management" exact component={SeniorManagement}/>
          <Route path="/admin/create_member" exact component={CreateMember}/>
          <Route path="/admin/comm_setting" exact component={CommSetting}/>
          <Route path="/admin/senior_details" exact component={SeniorDetails}/>
          <Route path="/admin/master_management" exact component={MasterManagement}/>
          <Route path="/admin/create_master" exact component={CreateMaster}/>
          <Route path="/admin/deposit_channel" exact component={DepositChannel}/>
          <Route path="/admin/add_deposit_channel" exact component={AddDepositChannel}/>
          <Route path="/admin/deposit_channel_details" exact component={DepositChannelDets}/>
          <Route path="/admin/edit_deposit_channel" exact component={EditDepositChannel}/>
          <Route path="/admin/game_list" exact component={GameList}/>
          <Route path="/wallets" exact component={Wallets}/>
          <Route path="/admin/transaction_details" exact component={TransactionDetails}/>

          
          <Route path="/admin/member_groups" exact component={MemberGroups}/>
          <Route path="/admin/add_member_group" exact component={AddMemberGroup}/>
          <Route path="/admin/edit_member_group" exact component={EditMemberGroup}/>
          <Route path="/play_game" exact component={PlayGame}/>
          <Route path="/pick_new_currency" exact component={PickNewCurrency}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
