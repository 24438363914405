import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
const data = [
  {id: 1,member_name: "Bronze member",status:"Not Setup",action:"Setup"},
  {id: 2,member_name: "Bronze member",status:"Not Setup",action:"Setup"},
  {id: 3,member_name: "Bronze member",status:"Not Setup",action:"Setup"},
  {id: 4,member_name: "Bronze member",status:"Not Setup",action:"Setup"},
  {id: 5,member_name: "Bronze member",status:"Not Setup",action:"Setup"},
  {id: 6,member_name: "Bronze member",status:"Not Setup",action:"Setup"},
  {id: 7,member_name: "Bronze member",status:"Not Setup",action:"Setup"},
  {id: 8,member_name: "Bronze member",status:"Not Setup",action:"Setup"},
  {id: 9,member_name: "Bronze member",status:"Not Setup",action:"Setup"},
  {id: 10,member_name: "Bronze member",status:"Not Setup",action:"Setup"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '13px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'800'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'700!important'
    },
  },
};
class CashBackSetting extends React.Component {
  render() {
    const columns = [
      {name: 'No.',selector: 'id',width:"200px",cell: row => <div data-tag="allowRowEvents"> {row.id}</div>},
      {name: 'Member Name',selector: 'member_name',width:"400px",cell: row => <div data-tag="allowRowEvents">Bronze member</div>},
      {name: 'Status',selector: 'status',width:"300px",cell: row => <div data-tag="allowRowEvents">Not Setup</div>},
      {name: 'Action',selector: 'actions',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}><Link to="/admin/edit_cash_back" style={{textDecoration:"none"}}>Setup</Link></div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white',marginBottom:"12px"}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Cash back</span> / 
                    <span>Cash Back Setting</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Cash Back Setting</h5>
                </div>
              </div>
            </div>
            <div className="divbody">
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <span className="subdvv1_1_1">
                    <input type="text" className="form-control search" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Please enter the"/>
                    <Search color="#00000040" style={{top:'4px'}} size={15}/>
                </span>
                <span className="subdvv1_1_1 second_right_div" >
                  <label className="labls"><span style={{minWidth:"48px",marginLeft:"36px"}}>Status:</span> 
                      <select className="form-control" style={{width:"194px"}}>
                          <option>All</option>
                      </select>
                  </label>
                </span>
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CashBackSetting;