import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Upload} from 'react-bootstrap-icons'

class BatchAdjustment extends React.Component {
    constructor(props) {
        super(props);
        this.move = this.move.bind(this)
    }
    move(){
        var current_progress = 0;
        let elem = document.getElementById("dynamic");
        var interval = setInterval(function() {
            current_progress += 10;
            elem.style.width = current_progress + "%";
            //$("#dynamic").css("width", current_progress + "%").attr("aria-valuenow", current_progress);
            if (current_progress >= 100)
                clearInterval(interval);
        }, 1000);
    }
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Member management</span> / 
                    <span>Batch Adjustment</span>
                  </div>
                  <h5 className="pg_head">Batch Adjustment</h5>
                </div>
              </div><br/>
              <div className="divbody">
                <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                    <h6 style={{fontWeight:600,margin:0}}>Batch Token Adjustment</h6>
                </div>
                <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <div className="col-md-12">
                        <span style={{position:'relative'}}><Upload color="#797979" size="15" style={{position: 'absolute',zIndex: 1,top: '10px',left: '9px'}}/><input type="file" className="custom_file upload_batch_file" style={{width:'124px'}} onChange={this.changeAvatar}/></span><br/>
                        <div className="progress">
                            <div id="dynamic" className="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                <span id="current-progress"></span>
                            </div>
                        </div>
                        <span>No files were selected</span><br/><br/>
                        <button className="btn btn-primary addbtn" onClick={this.move}>Submit</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BatchAdjustment;