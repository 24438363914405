const data2 = [
  {
    id: 1,
    username: "peter132",
    vip_level: "Diamond",
    user_role: "User",
    datetime: "13/05/2021 12:45",
  },
  {
    id: 2,
    username: "peter132",
    vip_level: "Diamond",
    user_role: "Agent",
    datetime: "13/05/2021 12:45",
  },
  {
    id: 3,
    username: "peter132",
    vip_level: "Diamond",
    user_role: "User",
    datetime: "13/05/2021 12:45",
  },
  {
    id: 4,
    username: "peter132",
    vip_level: "Diamond",
    user_role: "User",
    datetime: "13/05/2021 12:45",
  },
  {
    id: 5,
    username: "peter132",
    vip_level: "Diamond",
    user_role: "User",
    datetime: "13/05/2021 12:45",
  },
  {
    id: 6,
    username: "peter132",
    vip_level: "Diamond",
    user_role: "User",
    datetime: "13/05/2021 12:45",
  },
  {
    id: 7,
    username: "peter132",
    vip_level: "Diamond",
    user_role: "User",
    datetime: "13/05/2021 12:45",
  },
  {
    id: 8,
    username: "peter132",
    vip_level: "Diamond",
    user_role: "User",
    datetime: "13/05/2021 12:45",
  },
  {
    id: 9,
    username: "peter132",
    vip_level: "Diamond",
    user_role: "User",
    datetime: "13/05/2021 12:45",
  },
  {
    id: 10,
    username: "peter132",
    vip_level: "Diamond",
    user_role: "User",
    datetime: "13/05/2021 12:45",
  },
];
export default data2;
