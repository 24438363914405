import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import {Link} from 'react-router-dom';

class Referral extends React.Component {
  state={
    isLoggedIn:false
  }
  componentDidMount(){
    if(localStorage.getItem('token')!=='undefined' && localStorage.getItem('token')!==null && localStorage.getItem('token')!==''){
      this.setState(state => ({isLoggedIn:true}));
    }
  }  
  render() {
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{background:'#000C16',color:'white'}}>
            <div className="outer_container" style={{background:"#0C1824"}}>
              <div className="referrbanner_div" style={{position:'relative'}}>
                <img alt="poker_game" className="d-block w-100" src={'assets/new_ref.jpg'}/>
                  <div className="ref_btn">
                    {/*<h3>GBO PROFESSIONAL REFERRAL PROGRAM</h3>
                    <p>Get paid every week, forever! Partner with GBO and recieve lifetime rewards for inviting new players. Be the player and be the house, all at the same time.</p><br/>*/}
                    {this.state.isLoggedIn &&
                      <Link to="/wallets#agent" className="btn btn-primary login_btn">Become an agent</Link>
                    }
                    {!this.state.isLoggedIn &&
                      <Link to="/create_account#referral" className="btn btn-primary login_btn">Become an agent</Link>
                    }
                  </div>
                {/*<div className="txtt container">
                  <div className="row">
                    <div className="col-lg-4 col-md-5">
                      <h3>GBO PROFESSIONAL REFERRAL PROGRAM</h3>
                      <p>Get paid every week, forever! Partner with GBO and recieve lifetime rewards for inviting new players. Be the player and be the house, all at the same time.</p><br/>
                      {this.state.isLoggedIn &&
                        <Link to="/wallets#agent" className="btn btn-primary login_btn">Become an agent</Link>
                      }
                      {!this.state.isLoggedIn &&
                        <Link to="/create_account#referral" className="btn btn-primary login_btn">Become an agent</Link>
                      }
                    </div>
                  </div>
                </div>*/}
              </div>
            </div> 
            
            <div className="showtxt393 txtt container text-center">
              <br/>
              <div className="row">
                <div className="col-md-4">
                  <h2 style={{fontWeight:'bold'}}>GBO PROFESSIONAL REFERRAL PROGRAM</h2>
                  <p style={{fontSize: '14px',marginTop: '20px'}}>Get paid every week, forever! Partner with GBO and recieve lifetime rewards for inviting new players. Be the player and be the house, all at the same time.</p><br/>
                  <Link to="/refer_to" className="btn btn-primary login_btn" style={{padding:'8px 28px',fontSize: '14px',fontWeight: 300}}>Become an agent</Link>
                </div>
              </div>
            </div>
            
            <br/><br/><br/>

            <div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="text-center">
                      <img alt="all games here" style={{marginBottom:'10px'}} src={'assets/allgameshere.png'}/>
                    </div>
                    <h1 className="text-white text-center homeheadng">HOW TO EARN LIFETIME PROFITS</h1><br/><br/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center" style={{marginBottom:'20px'}}>
                    <div className="new_ref_div">
                      <img src={'assets/new_ref1.png'} alt="ref1"/>
                      <div className="ref_desc">
                        <h4>Get Link</h4>
                        <p>Become a registered user of the platform and you will have a unique referral link.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center" style={{marginBottom:'20px'}}>
                    <div className="new_ref_div">
                      <img src={'assets/new_ref2.png'} alt="ref2"/>
                      <div className="ref_desc">
                        <h4>Invite Players</h4>
                        <p>Invite others to join the platform and become a player or agent through your referral link. When they invite others, you also earn!</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center" style={{marginBottom:'20px'}}>
                    <div className="new_ref_div">
                      <img src={'assets/new_ref3.png'} alt="ref3"/>
                      <div className="ref_desc">
                        <h4>Get Paid</h4>
                        <p>Register in 1 minute and become a partner of the platform. Everytime the house earns, you earn! No renewal required, earn commissions on your account forever.</p>
                      </div>
                    </div>
                  </div>
                </div>              
              </div>
            </div>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
        </div>
    );
  }
}

export default Referral;
