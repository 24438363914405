import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import { SuitSpadeFill } from 'react-bootstrap-icons';
import { makeRequest } from '../helpers'
class VIP extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.changetab = this.changetab.bind(this)
  }
  state= {
    tab:'privileges',
    silver:{},
    gold:{},
    platinum:{},
    diamond:{},
    crown:{},
  }

  changetab(val){
    this.setState(state => ({
        tab: val
    }));
  }
componentDidMount() {
    (async () => {
        var result1=await this.makeRequest('member-group', 'get','','');
        if(result1.status===200){
            console.log(result1.data.data);
            this.setState({silver:result1.data.data[3]});
            this.setState({gold:result1.data.data[2]});
            this.setState({platinum:result1.data.data[1]});
            this.setState({diamond:result1.data.data[0]});
            this.setState({crown:result1.data.data[0]});
        }
    })();
} 
  render() {
    if (!this.state.silver.birthdayBonus || !this.state.gold.birthdayBonus || !this.state.platinum.birthdayBonus || !this.state.diamond.birthdayBonus || !this.state.crown.birthdayBonus) {
    return null;
    }
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{background:'#0F294C',color:'white'}}>
            <br/><br/>
            <div>
            {(localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!=='') ? (
                <div style={{textAlign:"center"}}>
                    <img alt="vipcrown" className="vipcrownimgg" style={{height:'150px'}} src={'assets/Crown3.png'}/><br/><br/><br/>
                    <div className="row justify-content-center" style={{margin:"0",width:"100%"}}>
                        <div className="col-xl-6 col-lg-7 col-md-8">
                            <div className="viptxtdiv">
                                <span>Current status </span>
                                <span><img alt="vipimg1" src={'assets/vip_silver_icon1.png'}/>Silver</span>
                            </div>
                            <div className="viptxtdiv">
                                <span>Current Deposit </span>
                                <span><img alt="vipimg2" src={'assets/vip_coin_icon2.png'}/>THB 0</span>
                            </div>
                            <div className="viptxtdiv">
                                <span>Deposit amount required for next tier upgrade </span>
                                <span><img alt="vipimg3" src={'assets/vip_coin_icon2.png'}/>THB 50,000</span>
                            </div>
                            <div className="viptxtdiv">
                                <span>Turnover amount required for next tier upgrade </span>
                                <span><img alt="vipimg4" src={'assets/vip_coin_icon2.png'}/>THB 50,000</span>
                            </div>
                        </div>
                    </div><br/><br/>
                </div>
            ) : (
                <div>
                    <br/><br/>
                    <h2 style={{fontWeight:700,textTransform:'uppercase',textAlign:'center'}}>Sign in to enjoy our privileges.</h2><br/><br/>
                </div>
            )}
            <ul className="nav nav-tabs viptabul" id="ex1" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className={ this.state.tab==='privileges' ? 'nav-link active' : 'nav-link' } id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true" onClick={() => this.changetab('privileges')} style={{fontSize:'20px'}}>VIP Privileges</a>
                    <span></span>
                </li>
                <li className="nav-item" role="presentation">
                    <a className={ this.state.tab==='howto' ? 'nav-link active' : 'nav-link' } id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false" onClick={() => this.changetab('howto')} style={{fontSize:'20px'}}>How to become vip?</a>
                </li>
            </ul><br/><br/>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-11 text-center">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <div className="tab-content viptabcontent" id="ex1-content">
                                    <div style={{padding:'52px 0 0 0'}} className={ this.state.tab==='privileges' ? 'active show tab-pane fade' : 'tab-pane fade' } id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                                        <div className="row showsmscrn">
                                            <ul className="nav nav-tabs" id="ex2" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link active" id="ex2-tab-1" data-mdb-toggle="tab" href="#ex2-tabs-1" role="tab" aria-controls="ex2-tabs-1" aria-selected="false">
                                                        <img alt="crown1" src={'assets/vip_silver.png'}/><br/><span className="heads">Silver</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" id="ex2-tab-2" data-mdb-toggle="tab" href="#ex2-tabs-2" role="tab" aria-controls="ex2-tabs-2" aria-selected="false">
                                                        <img alt="crown1" src={'assets/vip_gold.png'}/><br/><span className="heads">Gold</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" id="ex2-tab-3" data-mdb-toggle="tab" href="#ex2-tabs-3" role="tab" aria-controls="ex2-tabs-3" aria-selected="false">
                                                        <img alt="crown1" src={'assets/vip_platinum.png'}/><br/><span className="heads">Platinum</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" id="ex2-tab-4" data-mdb-toggle="tab" href="#ex2-tabs-4" role="tab" aria-controls="ex2-tabs-4" aria-selected="false">
                                                        <img alt="crown1" src={'assets/vip_diamond.png'}/><br/><span className="heads">Diamond</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" id="ex2-tab-5" data-mdb-toggle="tab" href="#ex2-tabs-5" role="tab" aria-controls="ex2-tabs-5" aria-selected="false">
                                                        <img alt="crown1" src={'assets/vip_crown.png'}/><br/><span className="heads">Crown</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            
                                            <div className="tab-content" id="ex2-content">
                                                <div style={{padding:'0'}} className="active show tab-pane fade" id="ex2-tabs-1" role="tabpanel" aria-labelledby="ex2-tab-1">
                                                    <h5>Requirements</h5>
                                                    <table className="table vipcrowntable">
                                                        <tbody>
                                                            <tr>
                                                                <td><div className="requrtd"><span className=""><img src={'assets/piggy_bank.png'} alt="deposit" /></span><span className="txttt">Deposit Bonus</span></div>
                                                                </td>
                                                                <td className="oddrow"><div>5%</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div className="requrtd"><span className=""><img src={'assets/turnover.png'} alt="deposit" /></span><span className="txttt">Turnover requirement</span></div>
                                                                </td>
                                                                <td className="oddrow"><div>0</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-white"><h5 className="privil" style={{margin: '12px 0'}}>Privileges</h5></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div className="requrtd"><span className=""><img src={'assets/cake.png'} alt="cake" /></span><span className="txttt">Birthday bonus</span></div>
                                                                </td>
                                                                <td className="oddrow"><div>100 THB</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div className="requrtd"><span className=""><img src={'assets/holiday_bonus.png'} alt="holiday_bonus" /></span><span className="txttt">holiday bonus</span></div>
                                                                </td>
                                                                <td className="oddrow"><div>100</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div className="requrtd"><span className=""><img src={'assets/loss_return.png'} alt="loss_return" /></span><span style={{padding:'4px'}} className="txttt">loss return</span></div>
                                                                </td>
                                                                <td className="oddrow"><div>5%</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div className="requrtd"><span className=""><img src={'assets/friend_invite.png'} alt="friend_invite" /></span><span className="txttt">Friend invite bonus</span></div>
                                                                </td>
                                                                <td className="oddrow"><div>100</div></td>
                                                            </tr>
                                                            <tr className="removbord">
                                                                <td><div className="requrtd"><span className=""><img src={'assets/coin.png'} alt="coin" /></span><span style={{padding:'14px 0'}} className="txttt">coin</span></div>
                                                                </td>
                                                                <td className="oddrow"><div>coming soon</div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row hidsmscrn">
                                            <div className="col-md-12">
                                                <table className="table vipcrowntable">
                                                    <tbody>
                                                        <tr className="fstrow">
                                                            <td style={{verticalAlign:'bottom'}}><span className="heads">Requirements</span></td>
                                                            <td><img alt="crown1" src={'assets/vip_silver.png'}/><br/><span className="heads">Silver</span></td>
                                                            <td><img alt="crown2" src={'assets/vip_gold.png'}/><br/><span className="heads">Gold</span></td>
                                                            <td><img alt="crown3" src={'assets/vip_platinum.png'}/><br/><span className="heads">Platinum</span></td>
                                                            <td><img alt="crown4" src={'assets/vip_diamond.png'}/><br/><span className="heads">Diamond</span></td>
                                                            <td><img alt="crown5" src={'assets/vip_crown.png'}/><br/><span className="heads">Crown</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="requrtd">
                                                                <span className=""><img src={'assets/piggy_bank.png'} alt="deposit" /></span>
                                                                <span className="txttt">Deposit Requirement</span></div>
                                                            </td>
                                                            <td className="oddrow"><div>1000</div></td>
                                                            <td className="evenrow"><div>5000</div></td>
                                                            <td className="oddrow"><div>20000</div></td>
                                                            <td className="evenrow"><div>100000</div></td>
                                                            <td className="oddrow"><div>500000</div></td>
                                                        </tr>
                                                        <tr className="removbord">
                                                            <td><div className="requrtd">
                                                                <span className=""><img src={'assets/turnover.png'} alt="turnover" /></span>
                                                                <span className="txttt">Turnover requirement</span></div>
                                                            </td>
                                                            
                                                            <td className="oddrow">
                                                                <div>{this.state.silver.turnoverRequirement.value} {this.state.silver.turnoverRequirement.unit !=='percent' ? this.state.silver.turnoverRequirement.unit : '%'}</div>
                                                            </td>
                                                            <td className="evenrow"><div>{this.state.gold.turnoverRequirement.value} {this.state.gold.turnoverRequirement.unit!=='percent' ? this.state.gold.turnoverRequirement.unit : '%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.platinum.turnoverRequirement.value} {this.state.platinum.turnoverRequirement.unit !== 'percent' ? this.state.platinum.turnoverRequirement.unit : '%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.diamond.turnoverRequirement.value} {this.state.diamond.turnoverRequirement.unit!=='percent' ? this.state.diamond.turnoverRequirement.unit :'%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.crown.turnoverRequirement.value} {this.state.crown.turnoverRequirement.unit!=='percent' ? this.state.crown.turnoverRequirement.unit :'%'}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-white"><span className="privil">Privileges</span></td>
                                                            <td></td><td></td><td></td><td></td><td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="requrtd">
                                                                <span className=""><img src={'assets/cake.png'} alt="cake" /></span>
                                                                <span className="txttt">Birthday bonus</span></div>
                                                            </td>
                                                            <td className="oddrow"><div>{this.state.silver.birthdayBonus.value} {this.state.silver.birthdayBonus.unit!=='percent' ? this.state.silver.birthdayBonus.unit : '%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.gold.birthdayBonus.value} {this.state.gold.birthdayBonus.unit!=='percent' ? this.state.gold.birthdayBonus.unit :'%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.platinum.birthdayBonus.value} {this.state.platinum.birthdayBonus.unit!=='percent' ? this.state.platinum.birthdayBonus.unit : '%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.diamond.birthdayBonus.value} {this.state.diamond.birthdayBonus.unit!=='percent' ? this.state.diamond.birthdayBonus.unit : '%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.crown.birthdayBonus.value} {this.state.crown.birthdayBonus.unit!=='percent' ? this.state.crown.birthdayBonus.unit : '%'}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="requrtd">
                                                                <span className=""><img src={'assets/star.png'} alt="deposit_bonus" /></span>
                                                                <span className="txttt">Deposit Bonus</span></div>
                                                            </td>
                                                            <td className="oddrow"><div>{this.state.silver.depositBonus.value} {this.state.silver.depositBonus.unit!=='percent' ? this.state.silver.depositBonus.unit :'%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.gold.depositBonus.value} {this.state.gold.depositBonus.unit!=='percent' ? this.state.gold.depositBonus.unit :'%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.platinum.depositBonus.value} {this.state.platinum.depositBonus.unit!=='percent' ? this.state.platinum.depositBonus.unit :'%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.diamond.depositBonus.value} {this.state.diamond.depositBonus.unit!=='percent' ? this.state.diamond.depositBonus.unit : '%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.crown.depositBonus.value} {this.state.crown.depositBonus.unit!=='percent' ? this.state.crown.depositBonus.unit : '%'}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="requrtd">
                                                                <span className=""><img src={'assets/holiday_bonus.png'} alt="holiday_bonus" /></span>
                                                                <span className="txttt">holiday bonus</span></div>
                                                            </td>
                                                            <td className="oddrow"><div>{this.state.silver.holidayBonus.value} {this.state.silver.holidayBonus.unit !== 'percent' ? this.state.silver.holidayBonus.unit : '%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.gold.holidayBonus.value} {this.state.gold.holidayBonus.unit!=='percent' ? this.state.gold.holidayBonus.unit : '%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.platinum.holidayBonus.value} {this.state.platinum.holidayBonus.unit!=='percent' ? this.state.platinum.holidayBonus.unit :'%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.diamond.holidayBonus.value} {this.state.diamond.holidayBonus.unit!=='percent' ? this.state.diamond.holidayBonus.unit :'%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.crown.holidayBonus.value} {this.state.crown.holidayBonus.unit!=='percent' ? this.state.crown.holidayBonus.unit :'%'}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="requrtd">
                                                                <span className=""><img src={'assets/loss_return.png'} alt="loss_return" /></span>
                                                                <span style={{padding:'4px'}} className="txttt">loss return</span></div>
                                                            </td>
                                                            <td className="oddrow"><div>{this.state.silver.lossReturn.value} {this.state.silver.lossReturn.unit!=='percent' ? this.state.silver.lossReturn.unit : '%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.gold.lossReturn.value} {this.state.gold.lossReturn.unit!=='percent' ? this.state.gold.lossReturn.unit :'%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.platinum.lossReturn.value} {this.state.platinum.lossReturn.unit!=='percent' ? this.state.platinum.lossReturn.unit : '%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.diamond.lossReturn.value} {this.state.diamond.lossReturn.unit!=='percent' ? this.state.diamond.lossReturn.unit : '%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.crown.lossReturn.value} {this.state.crown.lossReturn.unit!=='percent' ? this.state.crown.lossReturn.unit :'%'}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="requrtd">
                                                                <span className=""><img src={'assets/friend_invite.png'} alt="friend_invite" /></span>
                                                                <span className="txttt">Friend invite bonus</span></div>
                                                            </td>
                                                            <td className="oddrow"><div>{this.state.silver.friendInviteBonus.value} {this.state.silver.friendInviteBonus.unit!=='percent' ? this.state.silver.friendInviteBonus.unit : '%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.gold.friendInviteBonus.value} {this.state.gold.friendInviteBonus.unit!=='percent' ? this.state.gold.friendInviteBonus.unit : '%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.platinum.friendInviteBonus.value} {this.state.platinum.friendInviteBonus.unit!=='percent' ? this.state.platinum.friendInviteBonus.unit: '%'}</div></td>
                                                            <td className="evenrow"><div>{this.state.diamond.friendInviteBonus.value} {this.state.diamond.friendInviteBonus.unit!=='percent' ? this.state.diamond.friendInviteBonus.unit :'%'}</div></td>
                                                            <td className="oddrow"><div>{this.state.crown.friendInviteBonus.value} {this.state.crown.friendInviteBonus.unit!=='percent' ? this.state.crown.friendInviteBonus.unit :'%'}</div></td>
                                                        </tr>
                                                        <tr className="removbord">
                                                            <td><div className="requrtd">
                                                                <span className=""><img src={'assets/coin.png'} alt="coin" style={{verticalAlign:"middle"}}/></span>
                                                                <span style={{padding:'14px 0'}} className="txttt">coin</span></div>
                                                            </td>
                                                            <td className="oddrow"><div>coming soon</div></td>
                                                            <td className="evenrow"><div>-</div></td>
                                                            <td className="oddrow"><div>-</div></td>
                                                            <td className="evenrow"><div>-</div></td>
                                                            <td className="oddrow"><div>-</div></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={ this.state.tab==='howto' ? 'active show tab-pane fade tab2content' : 'tab-pane fade' } id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10 col-md-12" style={{textAlign:'left'}}>
                                                <h2 className="text-center" style={{fontSize: '30px',fontWeight: 'bold'}}>More information</h2><br/><br/>
                                                <p><span>1.</span> This promotion offers is open to all GBOBET members.</p>
                                                <p><span>2.</span> Members can enjoy benefits of our tier level once their deposit amount has meet our requirement, members are automatically qualified in our VIP program by the following month for lifetime.</p>
                                                <p style={{fontSize:'14px'}}>Example: Member total deposit accumulated MYR 4,000,000 on month May, and he is awarded as Platinum member starting from month June until lifetime.</p>
                                                <p><span>3.</span> Members will receive upgraded bonus once their tore level has been upgraded.</p>
                                                <p><span>4.</span> Cash Rebate percentage will be upgraded throughout the whole month base on the tier above.</p>
                                                <p><span>5.</span> To claim this birthday promotion, Members are required to claim this bonus at (My Promotion) tab inside My Account.</p>
                                                <p><span>6.</span> After member has entitled their VIP lavel, it will preserve for lifetime.</p>
                                                <p><span>7.</span> GBOBET reserves the right to cancel member's VIP privileges anytime without prior noticy if any member breached the General Terms & Conditions.</p>
                                                <p><span>8.</span> General Promotional Terms & Conditions Apply.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
        </div>
    );
  }
}

export default VIP;
