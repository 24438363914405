import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import WalletBalance from './WalletBalance';
import LevelDets from './LevelDets';
import LevelDetsSM from './LevelDetsSM';

class Transfer extends React.Component {
  render() {
    return (
      <div>
        <Navbar prop={this.props}/>
        <div style={{ background: '#000C16', color: 'white' }}>
          <br /><br /><br />
          <div>
            <div className="container account_container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-3 col-md-3 hide767scr">
                  <WalletBalance/>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9">
                  <div className="hide767scr"><LevelDets/></div>
                  <div className="col-xl-6 col-lg-7 col-md-8 show767scr" style={{background:'#0d171f',padding:'20px'}}>
                    <LevelDetsSM/>
                  </div>
                  <br />
                  <div>
                    <ul className="nav nav-tabs accounttabul transferul" style={{ padding: '8px 0px', justifyContent: 'left' }}>
                      <li className="nav-item">
                        <span>Transfer</span>
                      </li>
                    </ul>
                    <div className="tab-pane transferdiv" style={{background:'#0D171F'}}>
                      <br/><br/><br/>
                      <div className="row justify-content-center security_tab" style={{margin:0}}>
                        <div className="col-lg-8 col-md-10">
                          <div style={{textTransform:'capitalize'}}>
                            
                            <div className="row">
                              <div className="col-md-4"><label style={{fontSize:'14px'}}>Transfer From</label></div>
                              <div className="col-md-8"><input className="form-control" placeholder="Main Wallet"/></div>
                            </div><br/>
                            <div className="row">
                              <div className="col-md-4"><label style={{fontSize:'14px'}}>Transfer To</label></div>
                              <div className="col-md-8"><input className="form-control" placeholder="Main Wallet"/></div>
                            </div><br/>
                            <div className="row">
                              <div className="col-md-4"><label style={{fontSize:'14px'}}>Transfer Amount</label></div>
                              <div className="col-md-8"><input className="form-control" placeholder="Enter Transfer Amount"/></div>
                            </div><br/>
                            <div className="row">
                              <div className="col-md-4"></div>
                              <div className="col-md-8">
                                <button className="btn btn-primary login_btn" style={{fontSize: '12px',width: '125px',height: '32px'}}>TRANSFER NOW</button>
                              </div>
                            </div><br/><br/><br/><br/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br /><br /><br /><br /><br /><br />
          <Footer />
        </div>

      </div>
    );
  }
}

export default Transfer;