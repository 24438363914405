import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import WalletBalance from './WalletBalance';
import LevelDets from './LevelDets';
import LevelDetsSM from './LevelDetsSM';
import { Bank2,ExclamationCircleFill } from 'react-bootstrap-icons';
import { Modal } from "react-bootstrap";

class Deposit extends React.Component {
  constructor(props) {
    super(props);
    this.submitRequest = this.submitRequest.bind(this)
    this.uploadProof = this.uploadProof.bind(this)
  }
  state = {
    showDets:1,
    showUploadModal:false,
  }
  submitRequest(val){
    this.setState(state => ({
      showDets: val,
      showUploadModal: false
    }));
  }
  uploadProof(val){
    this.setState(state => ({
      showUploadModal: val,
    }));
  }

  render() {
    return (
      <div> 
        <Navbar prop={this.props}/>
        <div style={{ background: '#000C16', color: 'white' }}>
          <br /><br /><br />
          <div>
            <div className="container account_container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-3 col-md-3 hide767scr">
                  <WalletBalance />
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9">
                  <div className="hide767scr"><LevelDets/></div>
                  <div className="col-xl-6 col-lg-7 col-md-8 show767scr" style={{background:'#0d171f',padding:'20px'}}>
                    <LevelDetsSM/>
                  </div>
                  <br /><br/>
                  <div>
                    <ul className="nav nav-tabs accounttabul transferul historyul depositul">
                      <li className="nav-item w-100" style={{display:'block'}}>
                        <span>Deposit</span>
                      </li>
                    </ul>
                    <div className="tab-pane transferdiv depositdivv" style={{background:'#0D171F'}}>
                      <br/><br/><br/>
                      <div className="row justify-content-center security_tab">
                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6">
                          <div className="depositfrom_div active">
                            <div className="iconn"><Bank2 color="white" size={40} /></div>
                            <span>Bank Transfer</span>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6">
                          <div className="depositfrom_div">
                            <div className="iconn" style={{margin:0}}>
                              <img alt="crypto" src={'assets/crypto.png'}/>
                            </div>
                            <span>Crypto Currency</span>
                            <div className="cmngsoon">Coming soon</div>
                          </div>
                        </div>
                      </div><br/>
                      <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="row" style={{margin:0}}>
                                <div className="col-lg-2 col-md-3 col-sm-4 col-xs-4">
                                    <div className="bankname">
                                      <img alt="bank1" className="w-100" src={'assets/hsbc_logo.png'}/>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-4 col-xs-4">
                                    <div className="bankname">
                                      <img alt="bank2" className="w-100" src={'assets/chinese_logo.png'}/>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-4 col-xs-4">
                                    <div className="bankname" style={{color: '#323338',fontSize: '12px'}}>International Wire</div>
                                </div>
                            </div><br/>
                            {this.state.showDets===1 &&
                              <div>
                                <div className="row security_tab depositform_div" style={{borderTop:'2px solid #ffffff40',margin:0}}>
                                    <div style={{paddingTop:'10px'}}><h6 className="text-white">Deposit request</h6></div>
                                    <div className="col-md-6">
                                        <label>Sender's Name</label>
                                        <input type="text" className="form-control" placeholder="Enter sender's name"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Amount to deposit (THB)<span>*</span></label>
                                        <input type="text" className="form-control" placeholder="Enter deposit amount"/>
                                    </div>
                                </div><br/>
                                <div className="row" style={{margin:0}}>
                                    <div className="col-md-12">
                                        <div className="depo_prom_div">
                                            <h6>Promotion</h6>
                                            <div>
                                                <input type="radio" name="promotion_type"/> <span>120% Welcome Deposit Bonus (Rollover x22)</span>
                                            </div>
                                            <div>
                                                <input type="radio" name="promotion_type"/> <span>Proceed without any of the promotions above. ( Rollover x1 )</span>
                                            </div>
                                        </div>
                                    </div>
                                </div><br/><br/>
                                <div className="row" style={{borderTop:'2px solid #ffffff40',margin:0}}>
                                    <div className="col-md-12">
                                        <br/>
                                        <div className="depo_terms_div">
                                        <span><ExclamationCircleFill style={{marginRight: '4px',verticalAlign: 'text-bottom'}} color="white" size={15}/>Kindly read the terms & conditions before selecting. <span style={{color:'#D8A62C'}}>Terms & Conditions</span></span>
                                        <span style={{float:'right'}} className="uniqcode">
                                            <button className="btn btn-primary login_btn" onClick={() => this.submitRequest(2)}>Get Unique Code</button>
                                        </span>
                                        </div>
                                    </div>
                                </div><br/>
                              </div>
                            }
                            {this.state.showDets===4 &&
                              <div>
                                <div className="row security_tab depositform_div" style={{borderTop:'2px solid #ffffff40',color:'#A6AEB3',margin:0}}>
                                  <div style={{paddingTop:'10px'}}><h6 className="text-white">Deposit request</h6></div>
                                  <div className="col-md-12 depositdivvv">
                                    <span>Account Name: Legal Trust Company Limited</span><br/>
                                    <span>Account Address: XXXXYYYYZZZZ</span><br/>
                                    <span>Account Number/ IBAN: 123123123123</span><br/>
                                    <span>Bank Name: DBS Bank Hong kong Limited</span><br/>
                                    <span>Bank Address: asdasd 123123</span><br/>
                                    <span>SWIFT: 123124521</span><br/>
                                    <span>Minimum Deposit: THB 50.00</span><br/><br/>
                                    <span>Unique code:</span><br/><br/>
                                    <h4 style={{color:'#D8A62C'}}>QWERTY12345</h4><br/>
                                    <span>Please transfer the money and in "REFERENCE" of your internet banking, you must put the unique code provided by our platform. When the "Unique code". "Amount" and "Sender's name" matched the funds, we will be credited automatically to the players account almost instantaneously for them to use.</span><br/><br/><br/>
                                    <div style={{borderTop:'2px solid #ffffff40'}}></div><br/>
                                    <span>Sender's name: Apple Chan Ping Guo</span><br/>
                                    <span>Amount to deposit (THB): 10,000 THB</span><br/>
                                    <span>Promotion: Proceed without any of the promotions above.</span><br/><br/>
                                    <div style={{borderTop:'2px solid #ffffff40'}}></div><br/>
                                    <div style={{textAlign:'right'}} className="depo_terms_div">
                                      <button className="btn btn-primary cancelreq" style={{width:'auto'}} onClick={() => this.submitRequest(1)}>Cancel this deposit request</button>
                                      <button className="btn btn-primary uploadbtn" style={{width:'auto'}} onClick={() => this.uploadProof(true)}>Upload Transfer Slip</button>
                                      <Modal size="sm" show={this.state.showUploadModal} className="pwd_changed_modal bank_account_modal upload_modal" strictmode="false">
                                        <Modal.Header closeButton onClick={() => this.uploadProof(false)}></Modal.Header>
                                        <Modal.Body>
                                          <div className="row justify-content-center" style={{height:'100%'}}>
                                            <div className="col-md-11 text-center" style={{margin:'auto 0'}}>
                                              <h5 style={{fontSize: '24px',fontWeight: 'bold'}}>Upload Proof</h5>
                                              <div className="row justify-content-center">
                                                <input type="file" className="custom-file-input"/>
                                              </div>
                                              <span style={{fontSize:'12px'}}>jpg, png, pdf.   Max 5MB. 3images Max</span><br/>
                                              <button className="btn btn-primary complete_deposit" onClick={() => this.submitRequest(3)}>Complete deposit</button><br/>
                                            </div>                                                    
                                          </div><br/>
                                        </Modal.Body>
                                      </Modal>
                                    </div><br/>
                                  </div>
                                </div>
                              </div>
                            }
                            {this.state.showDets===2 &&
                            <div>
                              <div className="row security_tab depositform_div" style={{borderTop:'2px solid #ffffff40',color:'#A6AEB3',margin:0}}>
                                <div style={{paddingTop:'10px'}}><h6 className="text-white">Please transfer to the following:</h6></div>
                                <div className="col-md-12 depositdivvv">
                                  <span>Bank: Local bank A</span><br/>
                                  <span>Bank Account Name: Marry Lam</span><br/>
                                  <span>Bank Account Number: 123123123123</span><br/>
                                  <span>Minimum Deposit: THB 50.00</span><br/><br/>
                                  <span>Unique code:</span><br/>
                                  <h4 style={{color:'#D8A62C',fontWeight:'bold'}}>QWERTY12345</h4><br/>
                                  <span>Please transfers the money and in "REFERENCE" of your internet banking, you must put the unique code provided by our platform. When the "Unique code". "Amount" and "Sender's name" matched the funds, we will be credited automatically to the players account almost instantaneously for them to use.</span><br/><br/><br/>
                                  <div style={{borderTop:'2px solid #ffffff40'}}></div><br/>
                                  <span>Sender's name: Apple Chan Ping Guo</span><br/>
                                  <span>Amount to deposit (THB): 10,000 THB</span><br/>
                                  <span>Promotion: Proceed without any of the promotions above.</span><br/><br/>
                                  <div style={{borderTop:'2px solid #ffffff40'}}></div><br/>
                                  <div style={{textAlign:'right'}} className="depo_terms_div">
                                    <button className="btn btn-primary cancelreq" style={{width:'auto'}} onClick={() => this.submitRequest(1)}>Cancel this deposit request</button>
                                    <button className="btn btn-primary uploadbtn" style={{width:'auto'}} onClick={() => this.uploadProof(true)}>Upload Transfer Slip</button>
                                    <Modal size="sm" show={this.state.showUploadModal} className="pwd_changed_modal bank_account_modal upload_modal" strictmode="false">
                                      <Modal.Header closeButton onClick={() => this.uploadProof(false)}></Modal.Header>
                                      <Modal.Body>
                                        <div className="row justify-content-center" style={{height:'100%'}}>
                                          <div className="col-md-11 text-center" style={{margin:'auto 0'}}>
                                            <h5 style={{fontSize: '24px',fontWeight: 'bold'}}>Upload Proof</h5>
                                            <div className="row justify-content-center">
                                              <input type="file" className="custom-file-input"/>
                                            </div>
                                            <span style={{fontSize:'12px'}}>jpg, png, pdf.   Max 5MB. 3images Max</span><br/>
                                            <button className="btn btn-primary complete_deposit" onClick={() => this.submitRequest(3)}>Complete deposit</button><br/>
                                          </div>                                                    
                                        </div><br/>
                                      </Modal.Body>
                                    </Modal>
                                  </div><br/><br/>
                                </div>
                              </div>
                            </div>
                            }
                            {this.state.showDets===3 &&
                            <div>
                              <div className="row security_tab depositform_div text-center" style={{borderTop:'2px solid #ffffff40',color:'#DEE2E6',paddingTop: '32px',margin:0}}>
                                <div className="col-md-12 depositdivvv">
                                  <span className="fc">Thank you for your application.</span><br/>
                                  <span>We will credit you shortly when we receive your transfer!</span><br/><br/><br/>
                                  <span>Transaction ID: TS-123123</span><br/><br/>
                                  <span>Bank: Local bank A</span><br/>
                                  <span>Bank Account Name: Marry Lam</span><br/>
                                  <span>Bank Account Number: 123123123123</span><br/>
                                  <span>Minimum Deposit: THB 50.00</span><br/>
                                  <span>Bank Reference unique code: QWERTY12345</span><br/><br/>
                                  <span>Sender’s name: Apple Chan Ping Guo</span><br/><br/>
                                  <span>Promotion: Proceed without any of the promotions above. </span><br/><br/>
                                  <div style={{borderTop:'2px solid #ffffff40'}}></div><br/>
                                  
                                  <div className="depo_terms_div">
                                      <button style={{width:'153px'}} className="btn btn-primary login_btn" onClick={() => this.submitRequest(4)}>Make new request</button>
                                  </div><br/><br/>
                                </div>
                              </div>
                            </div>
                            }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br /><br /><br /><br /><br /><br />
          <Footer />
        </div>

      </div>
    );
  }
}

export default Deposit;