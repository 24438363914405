import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';

class Promotion extends React.Component {  
  render() {
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{background:'#000C16',color:'white'}}>
            <br/><br/>
            <div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <h1 className="text-white text-center homeheadng">PROMOTION</h1>
                    <div className="text-center">
                      <img alt="all games here" src={'assets/Group44.png'}/>
                    </div><br/><br/>
                  </div>
                </div>
                {/* filters */}
                <div className="row justify-content-center">
                    <div className="text-center promo_filter active">
                        <div className="">ALL</div>
                    </div>
                    <div className="text-center promo_filter">
                        <div className="">NEW MEMBER</div>
                    </div>
                    <div className="text-center promo_filter">
                        <div className="">LIVE CASINO</div>
                    </div>
                    <div className="text-center promo_filter">
                        <div className="">SPORTBOOK</div>
                    </div>
                    <div className="text-center promo_filter">
                        <div className="">SLOTS</div>
                    </div>
                    <div className="text-center promo_filter">
                        <div className="">BONUS</div>
                    </div>
                </div>            
                {/* filters */}
                <br/><br/>
                <div className="row casidv casidvv">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="promodiv">
                      <img alt="live casino image1" src={'assets/promotion1.png'}/>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="promodiv">
                      <img alt="live casino image1" src={'assets/promotion2.png'}/>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="promodiv">
                      <img alt="live casino image1" src={'assets/promotion3.png'}/>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="promodiv">
                      <img alt="live casino image1" src={'assets/promotion4.png'}/>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="promodiv">
                      <img alt="live casino image1" src={'assets/promotion5.png'}/>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="promodiv">
                      <img alt="live casino image1" src={'assets/promotion6.png'}/>
                    </div>
                  </div>
                </div>              
              </div>
            </div>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
        </div>
    );
  }
}

export default Promotion;
