import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
const data = [
  {id: 1,username: "User1234",full_name:"Username",status:"Active",role:"Credit Demo",last_login_time:"2020-11-16 01:02:23",last_login_ip:"186.90.123.45",action:""},
  {id: 2,username: "User1234",full_name:"Username",status:"Active",role:"Credit Demo",last_login_time:"2020-11-16 01:02:23",last_login_ip:"186.90.123.45",action:""},
  {id: 3,username: "User1234",full_name:"Username",status:"Active",role:"Credit Demo",last_login_time:"2020-11-16 01:02:23",last_login_ip:"186.90.123.45",action:""},
  {id: 4,username: "User1234",full_name:"Username",status:"Active",role:"Credit Demo",last_login_time:"2020-11-16 01:02:23",last_login_ip:"186.90.123.45",action:""},
  {id: 5,username: "User1234",full_name:"Username",status:"Active",role:"Credit Demo",last_login_time:"2020-11-16 01:02:23",last_login_ip:"186.90.123.45",action:""},
  {id: 6,username: "User1234",full_name:"Username",status:"Active",role:"Credit Demo",last_login_time:"2020-11-16 01:02:23",last_login_ip:"186.90.123.45",action:""},
  {id: 7,username: "User1234",full_name:"Username",status:"Active",role:"Credit Demo",last_login_time:"2020-11-16 01:02:23",last_login_ip:"186.90.123.45",action:""},
  {id: 8,username: "User1234",full_name:"Username",status:"Active",role:"Credit Demo",last_login_time:"2020-11-16 01:02:23",last_login_ip:"186.90.123.45",action:""},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class AccountManagement extends React.Component {
  render() {
    const columns = [
      {name: 'No.',width:"50px",selector: 'id',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Username',width:"100px",selector: 'username',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>User1234</div>},
      {name: 'Full Name',width:"120px",selector: 'full_name',cell: row => <div data-tag="allowRowEvents">Username</div>},
      {name: 'Status',width:"100px",selector: 'status',cell: row => <div data-tag="allowRowEvents"  style={{color:"green"}}>Active</div>},
      {name: 'Role',width:"110px",selector: 'role',cell: row => <div data-tag="allowRowEvents">Credit Demo</div>},
      {name: 'Last Login Time',width:"180px",selector: 'last_login_time',cell: row => <div data-tag="allowRowEvents">2020-11-16 01:02:23</div>},
      {name: 'Last Login IP',width:"180px",selector: 'last_login_ip',cell: row => <div data-tag="allowRowEvents">186.90.123.45</div>},
      {name: 'Action',width:"220px",selector: 'action',cell: row => <div data-tag="allowRowEvents"><button className="btn btn-primary add_btn admin_btns" style={{width:'auto'}}><Link to="/admin/update_operator_profile" style={{textDecoration:"none",color:"white"}}>Edit Profile</Link></button> <button className="btn btn-primary add_btn admin_btns" style={{width:'auto',marginLeft:"8px"}}>Reset Password</button></div>}
    ];
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Admin Management</span> / 
                    <span>Account Management</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Account Management</h5>
                    <div style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Add Operator</button>
                    </div>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
              <div className="row">
                <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12"> 
                  <span className="subdvv1_1_1 acc_man_left">
                      <label className="labls labls1"><span style={{minWidth:"48px"}}>Show:</span>
                          <select className="form-control" style={{width:"70px"}}>
                              <option>50</option>
                          </select></label>
                  </span>
                  <span className="subdvv1_1_1 acc_man_left">
                      <label className="labls labls1"><span style={{minWidth:"48px"}}>Status:</span>
                          <select className="form-control" style={{width:"70px"}}>
                              <option>All</option>
                          </select></label>
                  </span>
                  <span className="subdvv1_1_1 acc_man_left">
                      <label className="labls labls1"><span style={{minWidth:"48px"}}>Role:</span>
                          <select className="form-control" style={{width:"70px"}}>
                              <option>All</option>
                          </select></label>
                  </span>
                </div> 
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                  <div className="acc_man_btns" style={{float:'right',marginBottom:'10px',width:"100%"}}>
                  <label className="labls labls1"><span style={{minWidth:"56px"}}>Search:</span><input type="text" className="form-control" style={{display: 'inline-block',width: '100%',height: '31px',fontSize: '14px'}}/></label>
                  </div>
                </div>
              </div>
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>   
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AccountManagement;