import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
//import { Lock,Phone,CheckSquare,CaretDownFill,StopwatchFill } from 'react-bootstrap-icons';

class CreateAgent extends React.Component {
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 24px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Agent Management</span> / 
                    <span>Create Agent</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Create Agent</h5>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:600,margin:0}}>Informations</h6>
              </div>
              <div className="row justify-content-center" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-5">
                  <div className="add_form_div create_agent_div">
                    <span>Name </span>
                    <input type="text" placeholder="Enter name" className="form-control"/>
                  </div><br/>
                  <div className="add_form_div create_agent_div">
                    <span>Full Name</span>
                    <input type="text" placeholder="Enter full name" className="form-control"/>
                  </div><br/>
                  <div className="add_form_div create_agent_div">
                    <span>Agent Type</span>
                    <select className="form-control">
                      <option>Choose agent</option>
                    </select>
                  </div><br/>
                  <div className="add_form_div create_agent_div">
                    <span>Login Permission</span>
                    <select className="form-control">
                      <option>Choose permission</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="add_form_div">
                    <span>Phone Number </span>
                    <input type="text" placeholder="0000-0000-0000" className="form-control"/>
                  </div><br/>
                  <div className="add_form_div">
                    <span>Email Address</span>
                    <input type="text" placeholder="Enter email address" className="form-control"/>
                  </div><br/>
                  <div className="add_form_div">
                    <span>Upline : Master</span>
                    <select className="form-control">
                        <option>aaaa</option>
                    </select>
                    <span className="text-center">or Agent</span>
                    <select className="form-control">
                        <option>aaaa</option>
                    </select>
                  </div><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAgent;
