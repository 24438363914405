import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"

class BatchWallet extends React.Component {
  render() {
    
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Transactions</span> / 
                    <span>Batch Wallet Adjustment</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Batch Wallet Adjustment</h5>
                </div>
              </div><br/>
              <p style={{paddingLeft:"20px",color:"#7C7D7E",fontSize:"14px",fontWeight:"700"}}>Coming soon...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BatchWallet;