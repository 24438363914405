import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
const data = [
  {id: 1,member_id: "",member_group:"",bet:"",payout:"",win_lose:"",last_cash_back:"",calculated_cash_back:"",adjusted_cash_back:"",remark:""}
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class MemberCashBackHistory extends React.Component {
  render() {
    const columns = [
      {name: 'No.',selector: 'id',width:'50px',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Member ID',selector: 'member_id',width:'80px',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Member Group',selector: 'member_group',width:'110px',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Start Time',selector: 'start_time',width:'80px',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'End Time',selector: 'end_time',width:'70px',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Bet',selector: 'bet',width:'50px',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Payout',selector: 'payout',width:'70px',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Win/Lose',selector: 'win_lose',width:'70px',cell: row => <div data-tag="allowRowEvents">Remarks</div>},
      {name: 'Cash Back (SGD)',width:'120px',selector: 'cash_back',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Adjusted Cash Back (SGD)',width:'180px',selector: 'adjusted_cash_back',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Transaction Id',width:'100px',selector: 'transaction_id',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Status',selector: 'status',width:'80px',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Submitted Date',selector: 'submitted_date',width:'120px',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Submitted By',selector: 'submitted_by',cell: row => <div data-tag="allowRowEvents"></div>},
      {name: 'Remark',selector: 'remark',cell: row => <div data-tag="allowRowEvents"></div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Cash back</span> / 
                    <span>Member Cash Back History</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Member Cash Back History</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-12">
                    <div className="top_second_div mem_history">
                        <label className="labls labls1"><span style={{minWidth:"88px"}}>Date Range:</span> 
                            <input type="date" className="form-control"/>
                        </label>
                        <label className="labls labls1"><span style={{minWidth:"82px"}}>Account ID:</span> <input type="text" className="form-control"/></label>
                        <label className="labls labls1"><span style={{minWidth:"60px"}}>Filter By:</span> 
                            <select className="form-control" style={{width:"120px"}}>
                                <option>All</option>
                            </select>
                        </label>
                        <label className="labls labls1"><span style={{minWidth:"48px"}}>Status:</span> 
                            <select className="form-control" style={{width:"120px"}}>
                                <option>All</option>
                            </select>
                        </label>
                    </div><br/>
                    <div className="top_second_div mem_history">
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Search</button>
                        <button className="btn btn-primary add_btn cancel_btn">Reset</button>
                    </div>
                </div>
              </div><br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <span className="subdvv1_1_1">
                    <input type="text" className="form-control" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Please enter"/>
                    <Search color="#00000040" style={{top:'4px'}} size={15}/>
                </span>
                <DataTable title="" columns={columns} data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MemberCashBackHistory;