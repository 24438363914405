import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
const data = [
  {id: 1,username: "User1234",email:"user@user.com",contact:"1234567890",birthday:"1990-03-04",member_group:"VIP 1 A",status:"Active",pause_bet_status:"Bet Active",amount:"130.00","":""},
  {id: 2,username: "User1234",email:"user@user.com",contact:"1234567890",birthday:"1990-03-04",member_group:"VIP 1 A",status:"Active",pause_bet_status:"Bet Active",amount:"130.00","":""},
  {id: 3,username: "User1234",email:"user@user.com",contact:"1234567890",birthday:"1990-03-04",member_group:"VIP 1 A",status:"Active",pause_bet_status:"Bet Active",amount:"130.00","":""},
  {id: 4,username: "User1234",email:"user@user.com",contact:"1234567890",birthday:"1990-03-04",member_group:"VIP 1 A",status:"Active",pause_bet_status:"Bet Active",amount:"130.00","":""},
  {id: 5,username: "User1234",email:"user@user.com",contact:"1234567890",birthday:"1990-03-04",member_group:"VIP 1 A",status:"Active",pause_bet_status:"Bet Active",amount:"130.00","":""},
  {id: 6,username: "User1234",email:"user@user.com",contact:"1234567890",birthday:"1990-03-04",member_group:"VIP 1 A",status:"Active",pause_bet_status:"Bet Active",amount:"130.00","":""},
  {id: 7,username: "User1234",email:"user@user.com",contact:"1234567890",birthday:"1990-03-04",member_group:"VIP 1 A",status:"Active",pause_bet_status:"Bet Active",amount:"130.00","":""},
  {id: 8,username: "User1234",email:"user@user.com",contact:"1234567890",birthday:"1990-03-04",member_group:"VIP 1 A",status:"Active",pause_bet_status:"Bet Active",amount:"130.00","":""},
  {id: 9,username: "User1234",email:"user@user.com",contact:"1234567890",birthday:"1990-03-04",member_group:"VIP 1 A",status:"Active",pause_bet_status:"Bet Active",amount:"130.00","":""},
  {id: 10,username: "User1234",email:"user@user.com",contact:"1234567890",birthday:"1990-03-04",member_group:"VIP 1 A",status:"Active",pause_bet_status:"Bet Active",amount:"130.00","":""},
  
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class MemberStatisticAdvanced extends React.Component {
  render() {
    const columns = [
      {name: 'No.',selector: 'id',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Username',selector: 'username',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>User1234</div>},
      {name: 'Email',selector: 'email',cell: row => <div data-tag="allowRowEvents">user@user.com</div>},
      {name: 'Contact',selector: 'contact',cell: row => <div data-tag="allowRowEvents">1234567890</div>},
      {name: 'Birthday',selector: 'birthday',cell: row => <div data-tag="allowRowEvents">1990-03-04</div>},
      {name: 'Member Group',selector: 'member_group',cell: row => <div data-tag="allowRowEvents">VIP 1 A</div>},
      {name: 'Status',selector: 'status',cell: row => <div data-tag="allowRowEvents">Active</div>},
      {name: 'Pause Bet Status',width:'120px',selector: 'pause_bet_status',cell: row => <div data-tag="allowRowEvents">Bet Active</div>},
      {name: 'Amount',selector: 'amount',cell: row => <div data-tag="allowRowEvents">130.00</div>},
      {name: <input type="checkbox"/>,selector: '',cell: row => <div data-tag="allowRowEvents"><input type="checkbox"/></div>},
    ];
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Statistics</span> / 
                    <span>Member Statistic Advanced</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Member Statistic Advanced</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="row mem_adv_top_div" style={{margin:0,padding:'30px 14px',background:'white'}}>
                
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"82px"}}>Date Range:</span>
                        <input type="date" className="form-control"/></label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"92px"}}>Record Type:</span>
                        <select className="form-control">
                                <option>Select</option>
                        </select></label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"102px"}}>No. of Records:</span>
                        <select className="form-control">
                            <option>Select</option>
                        </select></label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"82px"}}>Status:</span>
                        <select className="form-control">
                            <option>Select</option>
                        </select></label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"92px"}}>Pause Bet:</span>
                        <select className="form-control">
                            <option>Select</option>
                        </select></label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"102px"}}>Total Deposit:</span>
                        <select className="form-control">
                            <option>Select</option>
                        </select></label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"120px"}}>Total Withdrawal:</span>
                        <select className="form-control">
                            <option>Select</option>
                        </select></label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"102px"}}>Total Turnover:</span>
                        <select className="form-control">
                            <option>Select</option>
                        </select></label>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-10 col-xs-12">
                        <div className="mem_statistic">
                            <span className="checkspan twos">
                                <input type="checkbox" checked/> <span>Select All</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Deselect All</span>
                            </span>
                        </div>
                        <div className="mem_statistic" style={{marginTop:"20px"}}>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>VIP 1A</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>High Rank</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>VIP Member</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>Silver Member</span>
                            </span>
                        </div>
                        <div className="mem_statistic" style={{marginTop:"10px"}}>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>Bronze Member</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>Diamond Member</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>New</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>VVIP Diamond Player</span>
                            </span>
                        </div>
                    </div>
                    <br/>
                    <div className="mem_statistic" style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Search</button>
                        <button className="btn btn-primary add_btn reset_black_btn">Reset</button>
                    </div>
                
                </div><br/>
                <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <span className="subdvv1_1_1 mem_stat_left">
                      <label className="labls labls1"><span style={{minWidth:"48px"}}>Show:</span>
                          <select className="form-control" style={{width:"70px"}}>
                                  <option>50</option>
                          </select></label>
                      </span>
                    </div>  
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                      <div className="mem_stat_btns" style={{float:'right',marginBottom:'10px'}}>
                          <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}}>Export to Excel</button>
                          <button className="btn btn-primary add_btn cancel_btn">CSV</button>
                          <button className="btn btn-primary add_btn cancel_btn">Print</button>
                      </div>
                    </div>
                  </div>    
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MemberStatisticAdvanced;