import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import WalletBalance from './WalletBalance';
import LevelDets from './LevelDets';
import LevelDetsSM from './LevelDetsSM';
import { Modal } from "react-bootstrap";
import { Trash,ArrowLeft,EnvelopeFill,TelephoneFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { makeRequest,user_mailbox,read_mail,addZero,timestampDate } from '../helpers'
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
      fontSize: '13px',
      background:'#0d171f',
      color:'white',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',
      paddingRight: '8px',
      fontSize: '14px',
      fontWeight: '500',
      width:'50px',
      background:'#363F47',
      color:'white',
      border:'0'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',
      paddingRight: '8px',
      width:'50px',
      color: '#ffffff80',
      fontSize: '14px',
      borderBottom:'1px solid #283138'
    },
  },
};

class Mailbox extends React.Component {
  constructor(props) {
    super(props);
    this.mailDetsModal = this.mailDetsModal.bind(this)
    // this.user_mailbox = this.user_mailbox.bind(this)
    this.delete_mail = this.delete_mail.bind(this)
    this.makeRequest = makeRequest
    this.user_mailbox = user_mailbox
    this.read_mail = read_mail
    this.addZero = addZero
    this.timestampDate = timestampDate
  }
  state = {
    showModal:false,
    mailData:[],
    mailDets:{}
  }

  componentDidMount() {
    this.user_mailbox(this);
  }

  mailDetsModal(val,row){
    this.setState(state => ({
      showModal: val,
      mailDets:row
    }));
    if(val===true){
      this.read_mail(row.id)
    }
  }

  // async user_mailbox(){
  //   let result1=await this.makeRequest('mailbox', 'get', '',localStorage.getItem('token'));
  //   if(result1.status===200){
  //     let data_arr = [];
  //     let jsonString=result1.data;
  //     for(let i = 0 ; i < (jsonString.length) ; i++) {
  //       if(jsonString[i].isDeleted===false){
  //         data_arr.push({
  //           'id': jsonString[i].id,
  //           'subject': jsonString[i].subject,
  //           'date_time': this.timestampDate(jsonString[i].updatedAt),
  //           'isRead': jsonString[i].isRead,
  //           'detail': jsonString[i].detail,
  //           'recipient': jsonString[i].recipient,
  //         });
  //       }
  //     }
  //     this.setState({mailData:data_arr});
  //     console.log(this.state.mailData)
  //   }
  // }
  async delete_mail(id){
    // let result1=await this.makeRequest('mailbox/'+id, 'delete', '',localStorage.getItem('token'));
    // if(result1.status===200){
      this.user_mailbox(this);
    // }
  }

  render() {
    const columns = [
      {
        name: '',
        selector: 'id',
        cell: row => <div data-tag="allowRowEvents"><input className="checkrow" style={{height:'18px',width:'18px',verticalAlign:'bottom'}} type="checkbox"/></div>,
        width:'40px'
      },
      {
        name: 'DATE & TIME',
        selector: 'date_time',
        cell: row => <div data-tag="allowRowEvents" style={row.isRead===false ? {fontWeight:800} : {}}>{row.date_time}</div>,
        width:'200px',
      },
      {
        name: 'SUBJECT',
        selector: 'subject',
        cell: row => <div data-tag="allowRowEvents" style={row.isRead===false ? {fontWeight:800,cursor:'pointer'} : {cursor:'pointer'}} onClick={() => this.mailDetsModal(true,row)}>{row.subject}</div>,
      },
      {
        name: 'DELETE',
        selector: 'id',
        cell: row => <div data-tag="allowRowEvents" className="deletediv" style={{cursor:'pointer',position:'relative'}}><Trash color="#425A70" onClick={() => this.delete_mail(row.id)} size={18} /><span className="spinner-border spinner-border-sm d-none" style={{position: 'absolute',right: '-20px',top: '3px'}}></span></div>,
          width:'90px'
      },
    ];
    return (
      <div>
        <Navbar prop={this.props}/>
        <div style={{ background: '#000C16', color: 'white' }}>
          <br /><br /><br />
          <div>
            <div className="container account_container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-3 col-md-3 hide767scr">
                  <WalletBalance />
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9">
                  <div className="hide767scr"><LevelDets/></div>
                  <div className="col-xl-6 col-lg-7 col-md-8 show767scr" style={{background:'#0d171f',padding:'20px'}}>
                    <LevelDetsSM/>
                  </div>
                  <br /><br/>
                  <div>
                    <ul className="nav nav-tabs accounttabul transferul historyul">
                      <li className="nav-item w-100" style={{display:'block'}}>
                        Mailbox
                      </li>
                    </ul>
                    <div className="tab-pane transferdiv" style={{background:'#0D171F'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <DataTable title="" className="mailstable" columns={columns} pagination data={this.state.mailData} customStyles={customStyles}/>
                                <Modal size="lg" show={this.state.showModal} className="pwd_changed_modal bank_account_modal mailbox_modal" strictmode="false">
                                    <Modal.Header closeButton onClick={() => this.mailDetsModal(false,this.state.mailDets)}>System Information</Modal.Header>
                                    <Modal.Body>
                                        <div className="row justify-content-center" style={{height:'100%'}}>
                                            <div className="col-md-12" style={{margin:'auto 0',textAlign:'left'}}>
                                                <div className="divvv1">
                                                    <ArrowLeft color="#425A70" size={26} />
                                                    <Trash color="#425A70" size={17} />
                                                    <span>{this.state.mailDets.date_time}</span>
                                                </div>
                                                <div className="divvvborder"></div>
                                                <div className="divvv2">
                                                    <h3>{this.state.mailDets.subject}</h3><br/>
                                                    <div>
                                                        <span className="dp">TG</span>
                                                        <span className="dpname">Team GBO Casino<br/><span>team@gbocasino.com</span></span>
                                                    </div><br/>
                                                    <span className="spnn1">{this.state.mailDets.detail}</span><br/>
                                                    <span className="spnn1">Please sensor or cover up other sensitive information.</span><br/><br/><br/>
                                                    <span className="spnn2">
                                                        <TelephoneFill color="#ffffff80" size={12} />
                                                        <span>+ 603 9212 7434 (12pm 10pm)</span>
                                                        <EnvelopeFill color="#ffffff80" size={12} /> 
                                                        <span> support@gbocasino.com</span>
                                                    </span>
                                                </div>
                                            </div>                                                    
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br /><br /><br /><br /><br /><br />
          <Footer />
        </div>

      </div>
    );
  }
}

export default Mailbox;