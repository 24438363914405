import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { makeRequest,makeProviderRequest } from '../../helpers'

class AddMemberGroup extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.add = this.add.bind(this)
    this.changeName = this.changeName.bind(this)
    this.changeBirthdayValue = this.changeBirthdayValue.bind(this)
    this.changeBirthdayUnit = this.changeBirthdayUnit.bind(this)
    this.changeDepositValue = this.changeDepositValue.bind(this)
    this.changeDepositUnit = this.changeDepositUnit.bind(this)
    this.changeHolidayValue = this.changeHolidayValue.bind(this)
    this.changeHolidayUnit = this.changeHolidayUnit.bind(this)
    this.changeLossValue = this.changeLossValue.bind(this)
    this.changeLossUnit = this.changeLossUnit.bind(this)
    this.changeTurnoverValue = this.changeTurnoverValue.bind(this)
    this.changeTurnoverUnit = this.changeTurnoverUnit.bind(this)
    this.changeFriendValue = this.changeFriendValue.bind(this)
    this.changeFriendUnit = this.changeFriendUnit.bind(this)
  }
  state= {
    name:'',
    birthdayValue:'',
    birthdayUnit:'',
    holidayValue:'',
    holidayUnit:'',
    depositValue:'',
    depositUnit:'',
    lossValue:'',
    lossUnit:'',
    turnoverValue:'',
    turnoverUnit:'',
    friendValue:'',
    friendUnit:'',
    submitDisabled:false,
    error_msg:'',
    success_msg:'',
  }
  changeName(e) {
    this.setState({name: (e.target.value).toLowerCase()})
  }
  changeBirthdayValue(e) {
    this.setState({birthdayValue: (e.target.value).toLowerCase()})
  }
  changeBirthdayUnit(e) {
    this.setState({birthdayUnit: (e.target.value).toUpperCase()})
  }
  changeDepositValue(e) {
    this.setState({depositValue: (e.target.value).toLowerCase()})
  }
  changeDepositUnit(e) {
    this.setState({depositUnit: (e.target.value).toUpperCase()})
  }
  changeHolidayValue(e) {
    this.setState({holidayValue: (e.target.value).toLowerCase()})
  }
  changeHolidayUnit(e) {
    this.setState({holidayUnit: (e.target.value).toUpperCase()})
  }
  changeLossValue(e) {
    this.setState({lossValue: (e.target.value).toLowerCase()})
  }
  changeLossUnit(e) {
    this.setState({lossUnit: (e.target.value).toUpperCase()})
  }
  changeTurnoverValue(e) {
    this.setState({turnoverValue: (e.target.value).toLowerCase()})
  }
  changeTurnoverUnit(e) {
    this.setState({turnoverUnit: (e.target.value).toUpperCase()})
  }
  changeFriendValue(e) {
    this.setState({friendValue: (e.target.value).toLowerCase()})
  }
  changeFriendUnit(e) {
    this.setState({friendUnit: (e.target.value).toUpperCase()})
  }
  async add(){
    if(this.state.name===''){
      this.setState(state => ({error_msg:'Enter name.'}));
    }else if(this.state.birthdayValue===''){
      this.setState(state => ({error_msg:'Enter birthday bonus value.'}));
    }else if(this.state.birthdayUnit===''){
      this.setState(state => ({error_msg:'Enter birthday bonus unit.'}));
    }else if(this.state.depositValue===''){
      this.setState(state => ({error_msg:'Enter deposit bonus value.'}));
    }else if(this.state.depositUnit===''){
        this.setState(state => ({error_msg:'Enter deposit bonus unit.'}));
    }else if(this.state.holidayValue===''){
        this.setState(state => ({error_msg:'Enter holiday bonus value.'}));
    }else if(this.state.holidayUnit===''){
          this.setState(state => ({error_msg:'Enter holiday bonus unit.'}));
    }else if(this.state.lossValue===''){
        this.setState(state => ({error_msg:'Enter loss return value.'}));
    }else if(this.state.lossUnit===''){
          this.setState(state => ({error_msg:'Enter loss return unit.'}));
    }else if(this.state.turnoverValue===''){
        this.setState(state => ({error_msg:'Enter turnover requirement value.'}));
    }else if(this.state.turnoverUnit===''){
          this.setState(state => ({error_msg:'Enter turnover requirement unit.'}));
    }else if(this.state.friendValue===''){
        this.setState(state => ({error_msg:'Enter friend invite bonus value.'}));
    }else if(this.state.friendUnit===''){
          this.setState(state => ({error_msg:'Enter friend invite bonus unit.'}));
    }else{
      this.setState(state => ({submitDisabled:true}));
      const birthdayBonus = {value:parseFloat(this.state.birthdayValue), unit:this.state.birthdayUnit};
      const depositBonus = {value:parseFloat(this.state.depositValue), unit:this.state.depositUnit};
      const holidayBonus = {value:parseFloat(this.state.holidayValue), unit:this.state.holidayUnit};
      const lossReturn = {value:parseFloat(this.state.lossValue), unit:this.state.lossUnit};
      const turnoverRequirement = {value:parseFloat(this.state.turnoverValue), unit:this.state.turnoverUnit};
      const friendInviteBonus = {value:parseFloat(this.state.friendValue), unit:this.state.friendUnit};
      let data = JSON.stringify({name: this.state.name,birthdayBonus,depositBonus,holidayBonus,lossReturn,turnoverRequirement,friendInviteBonus});
      var result1=await this.makeRequest('member-group', 'post', data,localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState({
          success_msg:result1.data.message,error_msg:'',submitDisabled:false
        })
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled:false,success_msg:''}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false,success_msg:''}));
    }
  }
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>CMS</span> / 
                    <span>&nbsp; Member Groups</span> / 
                    <span>Add Member Group</span>
                  </div>
                  <h5 className="pg_head">Add Member Group</h5>
                </div>
              </div><br/>
            </div>
            <div style={{padding:'0 20px'}}>
              {this.state.success_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>):('')
              }
              {this.state.error_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.error_msg}</p>):('')
              }
            </div>
            <div className="divbody">
              <div className="subbdvv1 add_owner_subdiv" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:600,margin:0}}>Informations
                  <div className="add_owner_btns" style={{float:'right',marginTop:'-6px'}}>
                    <button className="btn btn-primary add_btn" onClick={this.add} disabled={this.state.submitDisabled}>Add</button>
                    <button className="btn btn-primary add_btn cancel_btn" onClick={()=>this.props.history.push('/admin/member_groups')}>Cancel</button>
                  </div>
                </h6>
              </div>
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white',width:"100%"}}>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Name: </span>
                      <input type="text" className="form-control" onChange={this.changeName}/>
                    </div>
                </div><br/>
                <p className="member_group_p">Birthday Bonus</p>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Value: </span>
                      <input type="text" className="form-control" onChange={this.changeBirthdayValue}/>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Unit: </span>
                      <input type="text" className="form-control" onChange={this.changeBirthdayUnit}/>
                    </div>
                </div>
                <p className="member_group_p">Deposit Bonus</p>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Value: </span>
                      <input type="text" className="form-control" onChange={this.changeDepositValue}/>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Unit: </span>
                      <input type="text" className="form-control" onChange={this.changeDepositUnit}/>
                    </div>
                </div>
                <p className="member_group_p">Holiday Bonus</p>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Value: </span>
                      <input type="text" className="form-control" onChange={this.changeHolidayValue}/>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Unit: </span>
                      <input type="text" className="form-control" onChange={this.changeHolidayUnit}/>
                    </div>
                </div>
                <p className="member_group_p">Loss Return</p>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Value: </span>
                      <input type="text" className="form-control" onChange={this.changeLossValue}/>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Unit: </span>
                      <input type="text" className="form-control" onChange={this.changeLossUnit}/>
                    </div>
                </div>
                <p className="member_group_p">Turnover Requirement</p>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Value: </span>
                      <input type="text" className="form-control" onChange={this.changeTurnoverValue}/>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Unit: </span>
                      <input type="text" className="form-control" onChange={this.changeTurnoverUnit}/>
                    </div>
                </div>
                <p className="member_group_p">Friend Invite Bonus</p>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Value: </span>
                      <input type="text" className="form-control" onChange={this.changeFriendValue}/>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Unit: </span>
                      <input type="text" className="form-control" onChange={this.changeFriendUnit}/>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddMemberGroup;
