import React from "react";
import { makeRequest} from '../helpers'
import {Modal} from 'react-bootstrap';

class WalletBalance extends React.Component {
  constructor(props) {
    super(props);
    this.addWalletModal = this.addWalletModal.bind(this)
    this.walletData = this.walletData.bind(this)
    //this.walletDetail = this.walletDetail.bind(this)
    this.addWallet = this.addWallet.bind(this)
    this.changeCurrency = this.changeCurrency.bind(this)
    this.changeName = this.changeName.bind(this)
    this.makeRequest = makeRequest
  }
  state= {
    wallets:[],
    showAddWalletModal:false,
    submitDisabled:false,
    err_msg:'',
    success_msg:'',
    currency:'',
    wallet_name:'',
    balance:'',
    isWallet:'Wallet Not Found',
  }
  addWalletModal(val){
    this.setState({showAddWalletModal:val});
  }
  changeCurrency(e) {
    this.setState(state => ({currency: e.target.value}))
  }
  changeName(e) {
    this.setState(state => ({wallet_name: e.target.value}))
  }
  async walletData(){
    var result1=await this.makeRequest('wallet', 'get', '',localStorage.getItem('token'));
    if(result1.status===200){
        console.log('all wallet',result1.data);
        this.setState({wallets:result1.data[0]});
        this.setState({isWallet:''});
    }
  }
  async addWallet(){
    if(this.state.currency==='' || this.state.wallet_name===''){
      this.setState(state => ({err_msg: 'Please enter all fields'}));
    }else{
        this.setState(state => ({submitDisabled: true,err_msg:''}));   
        var data = JSON.stringify({currency: this.state.currency,name: this.state.wallet_name});
        var result1=await this.makeRequest('wallet', 'post', data,localStorage.getItem('token'));
        if(result1.status===200){
            this.setState({success_msg:result1.data.message,submitDisabled: false});
            this.walletData();
            setTimeout(
                function() {
                    this.addWalletModal(false);
                }.bind(this),2000
            );
        }else if(result1.status===400){
            this.setState(state => ({err_msg:result1.data.message,submitDisabled:false}));
        }else if(result1.status===500){
            this.setState(state => ({err_msg:'Some problem occured. Please try again.',submitDisabled:false}));
        }
    }
  }
  componentDidMount() {
	(async () => {
      const response = await fetch('https://geolocation-db.com/json/');
      const data = await response.json();
      //this.setState({ ip: data.IPv4 })
      this.setState(state => ({IP: data.IPv4}));
      localStorage.setItem('country',data.country_code)
    })();
	// if(localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!==''){
  //     let data=JSON.parse(localStorage.getItem('userInfo'))
  //     this.setState(state => ({userInfo: data}));
  //     this.walletData();
  //   }
	if(localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!==''){
      let data=JSON.parse(localStorage.getItem('userInfo'))
      this.setState(state => ({userInfo: data}));
    }
    //this.walletDetail();
    this.walletData();
  }
  render() {
    return (
        <div className="walletdiv">
            <h4 className="walletbal">
                <span onClick={()=>this.addWalletModal(true)}>+</span>
                {this.state.isWallet==='' ?
                (<div><p className="bal">Wallet Balance</p><br/>
                {this.state.wallets.currency} {this.state.wallets.balance}</div>):
                (
                    <h4 className="walletbal" style={{paddingTop:"17px"}}>
                        Create Wallet
                    </h4>
                )}
            </h4>
            <Modal size="sm" show={this.state.showAddWalletModal} className="pwd_changed_modal bank_account_modal wallet_modal login_modal">
                <Modal.Header style={{background:'none'}} closeButton onClick={() => this.addWalletModal(false)}></Modal.Header>
                <Modal.Body>
                    <div>
                        <h3 className="text-center" style={{fontWeight:'bold',fontSize: '22px'}}>Add Wallet</h3>
                        {this.state.err_msg!=='' &&
                            <p className="text-center" style={{fontSize: '14px',color: '#ff2424'}}>{this.state.err_msg}</p>
                        }
                        {this.state.success_msg!=='' &&
                            <p className="text-center" style={{fontSize: '14px',color: '#ff2424'}}>{this.state.success_msg}</p>
                        }
                        <div>
                            <input placeholder="Currency" onChange={this.changeCurrency} className="form-control"/>
                        </div><br/>
                        <div>
                            <input placeholder="Name" onChange={this.changeName} className="form-control"/>
                        </div><br/>
                        <button className="btn btn-primary login_btn w-100" disabled={this.state.submitDisabled} onClick={this.addWallet}>Submit</button><br/>
                    </div><br/>
                </Modal.Body>
            </Modal>
            <br/>
            {this.state.isWallet==='' ?
            (<div>
                <div style={{borderBottom:'1px solid #464646',marginBottom:"16px"}}></div>
                    <div key={this.state.wallets.id} className="walletdets" style={{marginBottom: '20px'}}>
                        <span>{this.state.wallets.name} <span className="spn1">Activated</span></span>
                        <span>{this.state.wallets.balance}</span>
                    </div>
                <br/>
                <div style={{borderBottom:'1px solid #464646',marginBottom:"16px"}}></div>
                <h6>
                    <span style={{float:'right'}}>
                    {this.state.wallets.currency} {this.state.wallets.balance}
                      </span>
                    TOTAL
                </h6><br/>
                <button className="btn btn-primary login_btn w-100 twblnc" style={{padding: '10px 0',fontSize: '14px',height: '40px'}}>TRANSFER WALLET BALANCE</button>
            </div>) :('')}  
        </div>
    );
  }
}

export default WalletBalance;
