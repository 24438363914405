import React from "react";
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { HourglassSplit,CaretDownFill,PeopleFill,Bell,ChatRightText,Power,XSquare,Justify,CheckLg,CaretUpFill,WalletFill,Toggle2On,Toggle2Off} from 'react-bootstrap-icons';
import { makeRequest,user_mailbox,addZero,timestampDate } from '../helpers'

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.forceUpdate = this.forceUpdate.bind(this)
    this.BalanceModal = this.BalanceModal.bind(this)
    this.login = this.login.bind(this)
    this.LoginModal = this.LoginModal.bind(this)
    this.changeUsername = this.changeUsername.bind(this)
    this.changePwd = this.changePwd.bind(this)
    this.makeRequest = makeRequest
    this.user_mailbox = user_mailbox
    this.addZero = addZero
    this.timestampDate = timestampDate
    this.loginRequest = this.loginRequest.bind(this)
    this.cmngsoon = this.cmngsoon.bind(this)
    this.notifDiv = this.notifDiv.bind(this)
    this.toMailbox = this.toMailbox.bind(this)
    this.walletData = this.walletData.bind(this)
    this.showSidebar = this.showSidebar.bind(this)
    this.ppModal = this.ppModal.bind(this)
    this.showTopWallets = this.showTopWallets.bind(this)
    this.closeEmailNotifyDiv = this.closeEmailNotifyDiv.bind(this)
    this.closePasswordNotifyDiv = this.closePasswordNotifyDiv.bind(this)
    this.currency_converter = this.currency_converter.bind(this)
    this.converter_modal = this.converter_modal.bind(this)
    this.convert_currency = this.convert_currency.bind(this)
  }
  state = {
    showBalanceModal:false,
    isLoggedIn:false,
    showLoginModal:false,
    navopen:false,
    showNotif:false,
    IP:'',
    username:'',
    pwd:'',
    token:'',
    userInfo:{},
    err_msg:'',
    countNotif:0,
    mailData:[],
	  wallets:[],
    frontSidebar:false,
    show_ppModal:false,
    show_email_notify_div:false,
    show_password_notify_div:false,
    showTopWalletList:false,
    showCurrencyConverter:false,
    showConverterModal:false,
    allCurrencies:[],
    fiatCurrencies:[],
    allWalletss:[],
  };

  componentDidMount() {
    if(window.location.hash.substr(1)==='email_verified'){
      this.setState(state => ({show_email_notify_div: true}));
      document.getElementById("usernamme").focus();
    }
    if(window.location.hash.substr(1)==='password_verified'){
      this.setState(state => ({show_password_notify_div: true}));
      document.getElementById("usernamme").focus();
    }
    //this.login(false);
    (async () => {
      const response = await fetch('https://geolocation-db.com/json/');
      const data = await response.json();
      //this.setState({ ip: data.IPv4 })
      this.setState(state => ({IP: data.IPv4}));
      localStorage.setItem('country',data.country_code)
    })();
    (async () => {
      var result1=await this.makeRequest('btsepay/currency', 'get');
      if(result1.status===200){
      this.setState(state => ({allCurrencies: result1.data.data}));
      }
    })();
    (async () => {
      var result1=await this.makeRequest('deposit-channel/currency', 'get', '',localStorage.getItem('token'));
      if(result1.status===200){
        this.setState({
          fiatCurrencies:result1.data.data
        })
        localStorage.setItem('fiat_currency',JSON.stringify(this.state.fiatCurrencies));
      }
    })();
    if(localStorage.getItem('token')!=='undefined' && localStorage.getItem('token')!==null && localStorage.getItem('token')!==''){
      this.setState(state => ({token: localStorage.getItem('token'),isLoggedIn:true}));
      this.user_mailbox(this);
    }
    if(localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!==''){
      let data=JSON.parse(localStorage.getItem('userInfo'))
      this.setState(state => ({userInfo: data}));
      this.count_unread_mail();
    }
    if(localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!==''){
        let data=JSON.parse(localStorage.getItem('userInfo'))
        this.setState(state => ({userInfo: data}));
        this.walletData();
    }
    //console.log(this.props.prop.location.pathname)
    // document.body.addEventListener("click", 
    //   function(){
    //     this.setState(state => ({
    //       showTopWalletList: false,
    //     }));
    //   }
    // )
  }
  closeEmailNotifyDiv(){
    window.location.hash='';
    this.setState(state => ({show_email_notify_div: false}));
  }
  closePasswordNotifyDiv(){
    window.location.hash='';
    this.setState(state => ({show_password_notify_div: false}));
  }
  ppModal(val,content){
    this.setState({show_ppModal: val,content:content})
  }
  showSidebar(val){
    this.setState(state => ({
      frontSidebar: val,
    }));
  }
  showTopWallets(val){
    this.setState(state => ({
      showTopWalletList: val,
    }));
  }
  currency_converter(val){
    this.setState(state => ({
      showCurrencyConverter: val,
      showConverterModal:val
    }));
    if(val===false){
      let text = document.querySelectorAll(".curr_symbol");
      text.forEach(element => element.innerHTML = '');
    }
  }
  converter_modal(val){
    this.setState(state => ({
      showConverterModal:val
    }));
  }
  convert_currency(val2){
    let text = document.querySelectorAll(".curr_symbol");
    text.forEach(element => element.innerHTML = val2);
    this.setState(state => ({
      showConverterModal:false
    }));
  }
  BalanceModal(val){
    this.setState(state => ({
      showBalanceModal: val,
    }));
  }
  login(val){
    this.setState(state => ({
      isLoggedIn: val,
      showLoginModal:false
    }));
    if(val===false){
      this.setState(state => ({
        token: '',
        userInfo: {},
      }));
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      this.props.prop.history.push('/');
    }
  }
  togmenu(val){
    this.setState(state => ({
      navopen: val,
    }));
  }
  LoginModal(val){
    this.setState(state => ({
      showLoginModal: val,
    }));
  }
  notifDiv(val){
    this.setState(state => ({showNotif: val}));
  }
  changeUsername(e) {
    let usernameValid = e.target.value ? true : false;
    this.setState({
      username: (e.target.value).toLowerCase(),
      usernameValid: usernameValid,
    })
  }
  changePwd(e) {
    let pwdValid = e.target.value ? true : false;
    this.setState({
      pwd: e.target.value,
      pwdValid: pwdValid,
    })
  }
  async walletData(){
    var result1=await this.makeRequest('wallet', 'get', '',localStorage.getItem('token'));
    if(result1.status===200){
      // console.log('all walletssssss',result1.data[0]);
      this.setState({wallets:result1.data[0]});
      this.setState({allWalletss:result1.data.data});
      this.setState({isWallet:''});
    }
  }
  async loginRequest(){
    if(this.state.username===''){
      this.setState(state => ({err_msg:'Enter username.'}));
    }else if(this.state.pwd===''){
      this.setState(state => ({err_msg:'Enter password to login.'}));
    }else if((this.state.pwd).length<8){
      this.setState(state => ({err_msg:'Password must be atleast 8 Characters.'}));
    }else{
      var data = JSON.stringify({username: this.state.username,password: this.state.pwd,ip:this.state.IP});
      let result1=await this.makeRequest('auth/login', 'post', data,'');
      if(result1.status===200){
        localStorage.setItem('token',result1.data.token)
        this.setState(state => ({
          token: result1.data.token,
          isLoggedIn:true,
          err_msg:''
        }));
        let result2=await this.makeRequest('user/me', 'get', '',localStorage.getItem('token'));
        if(result2.status===200){
          localStorage.setItem('userInfo',JSON.stringify(result2.data.user))
          this.setState(state => ({
            userInfo: result2.data.user,
          }));
          let that=this
          setTimeout(
            function() {
              that.props.prop.history.push('/wallets#account');
            },1000
          );
        }
        //this.props.history.push('/my_account1');
      }else if(result1.status===400){
      this.setState(state => ({err_msg:result1.data.message}));
      }
    }
  }
  async count_unread_mail(){
    let result1=await this.makeRequest('mailbox/unread/count', 'get', '',localStorage.getItem('token'));
    if(result1.status===200){
      this.setState(state => ({countNotif: result1.data.count}));
    }
  }
  cmngsoon(e){
    this.props.prop.history.push('/coming_soon');
    e.preventDefault();
    return false;
  }
  toMailbox(){
    this.setState(state => ({showNotif: false}));
    this.props.prop.history.push('/wallets#notifications');
  }
  render() {
    // if(localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!==''){
    //   const user_data = JSON.parse(localStorage.getItem('userInfo'))
    // }
    const hash=this.props.prop.location.pathname;
    return (
        <div style={{background:'#17212D'}}>
          <div style={{backgroundImage: 'linear-gradient(to bottom, #0d67b8, #033969)',padding: '3px 0'}}>
            <nav className="navbar-expand-lg container text-white toppnav">
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex" style={{marginLeft:'auto',justifyContent: 'flex-end'}}>
                <li className="nav-item">
                  <span className="nav-link" style={{position:"relative"}}><HourglassSplit style={{verticalAlign:'sub'}} color="white" size={15} /> Casino Time : 14:24 <span style={{position:"relative",top:"0",right:"-7px"}}>|</span></span>
                </li>
                <li className="nav-item">
                  <span className="nav-link"><img src={'assets/us.png'} alt="language"/>  English <CaretDownFill color="white" size={10} /></span>
                </li>
              </ul>
            </nav>
          </div>
          <nav className="navbar navbar-expand-lg navbar-dark scnd_nav main_top_navbar" style={{paddingBottom:"26px",paddingTop:"20px"}}>
            <div className="container top_navbar">
              <li className="left_toggle" style={{marginTop:"-4px",paddingRight: "12px"}}>
                  <Justify color="#ffffff" size={36} onClick={() => this.showSidebar(true)} style={{cursor:"pointer"}}/>
              </li>
              <Link to="/" className="navbar-brand" style={{paddingTop:"0px"}}>
                  <img alt="header_logo" src={'assets/vigobet-lion.png'}/>
              </Link>
              <li className="nav-item navli navli1" style={{marginRight:'10px !important'}}>
                  <Link to="/referral" className={hash==='/referral' ? 'activeclass' : 'nav-link'}>Referral</Link> 
              </li>
              <li className="nav-item navli navli1">
                  <Link to="/promotion" className={hash==='/promotion' ? 'activeclass' : 'nav-link'}>Promotion</Link>
              </li>
              <li className="nav-item navli navli1" style={{position:'relative'}}>
                  <Link to="/vip" className={hash==='/vip' ? 'activeclass vipanch' : 'nav-link vipanch'}>VIP</Link>
              </li>
              {this.state.isLoggedIn ?
                (
                <div className="small_screen_btns small_wallet">
                  <div className="top_wallet_list" onMouseLeave={() => this.showTopWallets(false)}>
                    <div style={{display:"inline-flex",cursor:"pointer"}} onClick={() => this.showTopWallets(!this.state.showTopWalletList)}>
                      <p style={{background:"#222D3B",border:"none",padding:"11px 4px 0px 4px",fontSize:"11px"}}>0.00000000 <img src={'assets/wallet1.png'} style={{height:"14px",verticalAlign:"sub"}} alt="wallet"/>
                      {this.state.showTopWalletList ?
                      (<CaretDownFill color="white" size={13} />):
                      (<CaretUpFill color="white" size={13} />)}
                      </p><span style={{background:"#007bff"}} className="top_wallet_btn"><WalletFill color="white" size={14} /></span>
                    </div>
                    {this.state.showTopWalletList ?
                    (<div className="top_wallet_list_ul">
                      <ul style={{paddingLeft:"0",width:"auto"}}>
                        {this.state.allWalletss.map(data => (
                          <li key={data.id} style={{fontSize:"10px"}}><span className="curr_symbol"></span>{data.balance} <img src={'assets/wallet1.png'} style={{height:"13px"}} alt="wallet"/> {data.currency}</li>
                        ))}
                        {/* {this.state.allWallets.map(data => (
                          <li key={data.id} style={{fontSize:"10px"}}><span className="curr_symbol"></span>{data.balance} <img src={'assets/wallet1.png'} style={{height:"13px"}} alt="wallet"/> {data.currency}</li>
                        ))} */}
                        {/* {this.state.allCurrencies.map(currency_data => (
                          <li key={currency_data} style={{fontSize:"10px"}}><span className="curr_symbol"></span>0.00000000 <img src={'assets/wallet1.png'} style={{height:"13px"}} alt="wallet"/> {currency_data}</li>
                        ))}
                        {this.state.fiatCurrencies.map(currency_data => (
                          <li key={currency_data} style={{fontSize:"10px"}}><span className="curr_symbol"></span>0.00000000 <img src={'assets/wallet1.png'} style={{height:"13px"}} alt="wallet"/> {currency_data}</li>
                        ))} */}
                        <li style={{fontWeight:"700",marginTop:"10px",fontSize:"11px"}}>View in $/€/¥ 
                          {!this.state.showCurrencyConverter ?
                            (<span style={{marginLeft:"16px",cursor:"pointer"}} onClick={() => this.currency_converter(true)}><Toggle2Off color="#92989E" size={24} /></span>)
                          :
                            (<span style={{marginLeft:"16px",cursor:"pointer"}} onClick={() => this.currency_converter(false)}><Toggle2On color="#92989E" size={24} /></span>)
                          }
                          <Modal size="md" show={this.state.showConverterModal} className="pwd_changed_modal bank_account_modal wallet_modal converter_modal">
                            <Modal.Header style={{background:'none'}} closeButton onClick={() => this.converter_modal(false)}>Information</Modal.Header>
                            <Modal.Body>
                              <div className="row" style={{margin:0}}>
                                <div className="col-md-12 crncs">
                                  <span className="spnnn"><input type="radio" name="currency_name" onClick={()=>this.convert_currency('$')}/> <span> USD</span></span>
                                  <span className="spnnn"><input type="radio" name="currency_name" onClick={()=>this.convert_currency('฿')}/> <span> THB</span></span>
                                  <span className="spnnn"><input type="radio" name="currency_name" onClick={()=>this.convert_currency('€')}/> <span> EUR</span></span>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </li>
                      </ul>
                    </div>):('')}
                  </div>
                </div>	
                ):
                (
                <div className="small_screen_btns">
                  <button className="nav-link btn btn-primary comn_btn loginsmbtn nav_btns new_login_btn" style={{marginRight:'16px',display: 'inline-block'}} onClick={() => this.LoginModal(true)}>Log in</button>
                  <Link to="/create_account#user" className="nav-link btn btn-primary signup_btn comn_btn new_signup_btn nav_btns">Sign up</Link>
                  <Link to="/forgot_password" className="fgpwd">Forgot Password?</Link>
                </div>
                )
              }
              {this.state.isLoggedIn &&
                <span className="nav-link togmenu smnotif" style={{marginTop:0,position:'relative'}}><Bell color="#DEE2E6" size={20} onClick={()=>this.notifDiv(!this.state.showNotif)} /><img alt="navicon2" className="d-none" src={'assets/icn2.png'}/>
                  <span className="counttt">{this.state.countNotif}</span>
                  {this.state.showNotif &&
                    <div className="mailDropdown">
                      <div className="sdiv1">
                        <h5>Messages <span onClick={this.toMailbox} style={{float:'right',fontSize: '12px',marginTop: '6px',color: '#f3b92b',textDecoration: 'underline',cursor:"pointer"}}>All messages</span></h5>
                      </div>
                      <div className="outrdv" style={{height: '242px',overflowY: 'scroll'}}>
                        {this.state.countNotif!==0}
                        {this.state.mailData.map(todo => (
                          <div key={todo.id} className={todo.isRead===false ? 'active sdiv2' : 'sdiv2'} onClick={this.toMailbox}>
                            <ChatRightText color="#ffffff" size={18} />
                            <div>
                              <h6>{todo.subject}</h6>
                              <p>{todo.detail}</p>
                              <span>{todo.date_time}</span>
                            </div>
                          </div>
                        ))}
                        {this.state.countNotif===0 &&
                          <h4 style={{paddingTop: '24px',margin: 0,fontSize:"16px",textAlign:"center"}}>No messages to show!</h4>
                        }
                      </div>
                    </div>
                  }
                </span>
              }
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
                <div className="collapse navbar-collapse nav_collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mb-2 mb-lg-0 d-flex navul1" style={{marginLeft:'auto'}}>
                    {/* small screen  */}
                    {this.state.isLoggedIn &&
                      <li className="nav-item togmenu lii" style={{padding: '16px 16px',background: '#222D3B'}}>
                        {localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!=='' &&
                        <span className="dropdown-item fstanch" onClick={() => this.togmenu(!this.state.navopen)}>
                          <div className="userimg" style={{width: '49px',height: '48px',paddingTop: '6px',marginRight: '3px'}}><img alt="crown_icon" src={(JSON.parse(localStorage.getItem('userInfo'))).avatar!==null ? (JSON.parse(localStorage.getItem('userInfo'))).avatar  : 'assets/goldcrown.png'} width="30px" height="30px" style={{verticalAlign:'text-top',objectFit: 'cover'}}/></div> 
                          {(JSON.parse(localStorage.getItem('userInfo'))).username}<br/><span style={{position: 'absolute',top: '30px',left: '72px',fontSize: '12px',color: 'black'}}>{(JSON.parse(localStorage.getItem('userInfo'))).email}</span>
                        </span>
                        }
                        {this.state.navopen &&
                          <div className="nav-item loggedin_nav_li togmenu" style={{padding: 0,marginTop: '10px'}}>
                            <Link to="/transfer" className="nav-link">Transfer</Link>
                            <Link to="/withdrawal" className="nav-link">Withdrawal</Link>
                            <Link to="/deposit" className="nav-link">Deposit</Link>
                            <Link to="/my_account1" className="nav-link">My Account</Link>
                            <span className="nav-link" onClick={() => this.BalanceModal(true)}>Balance</span>
                            <span className="nav-link">Help</span>
                            <Link to="/history" className="nav-link">History</Link>
                          </div>
                        }
                      </li>
                    }
                    {this.state.isLoggedIn &&
                      <li className="loggedin_nav_li togmenu" style={{marginBottom:'28px',padding:0}}><span className="dropdown-item scndanch" style={{marginTop:0}}><PeopleFill style={{verticalAlign:'sub'}} color="white" size={21} /> COMMUNITY <span onClick={this.cmngsoon}>Coming Soon</span></span></li>
                    }
                    <li className="nav-item togmenu">
                      <Link to="/live_casino" className="nav-link">
                        <img alt="nav game1" src={'assets/white_livecasino.png'}/><br/>
                        <span className="txtt11">live casino</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/slots" className="nav-link">
                        <img alt="nav game2" src={'assets/white_slots.png'}/><br/>
                        <span className="txtt11">slots</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/" className="nav-link">
                        <img alt="nav game3" src={'assets/white_sports.png'}/><br/>
                        <span className="txtt11">sports</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/" className="nav-link">
                        <img alt="nav game4" src={'assets/white_esports.png'}/><br/>
                        <span className="txtt11">e-sports</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/" className="nav-link">
                        <img alt="nav game5" src={'assets/white_4Dlottery.png'}/><br/>
                        <span className="txtt11">4d lottery</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/" className="nav-link">
                        <img alt="nav game6" src={'assets/white_predection.png'}/><br/>
                        <span className="txtt11">prediction</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/cock_fighting" className="nav-link">
                        <img alt="nav game7" src={'assets/white_cockfighting.png'}/><br/>
                        <span className="txtt11">cock fighting</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/" className="nav-link">
                        <img alt="nav game8" src={'assets/white_minigames.png'}/><br/>
                        <span className="txtt11">mini games</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/" className="nav-link">
                        <img alt="nav game9" src={'assets/white_tournament.png'}/><br/>
                        <span className="txtt11">tournament</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/" className="nav-link">
                        <img alt="nav game10" src={'assets/white_muaythai.png'}/><br/>
                        <span className="txtt11">muay thai</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/promotion" className="nav-link">
                        <img alt="nav game10" src={'assets/white_promotion.png'}/><br/>
                        <span className="txtt11">Promotion</span>
                      </Link>
                    </li>
                    <li className="nav-item togmenu">
                      <Link to="/vip" className="nav-link">
                        <img alt="nav game10" src={'assets/white_vip.png'}/><br/>
                        <span className="txtt11">VIP</span>
                      </Link>
                    </li>
                    {this.state.isLoggedIn &&
                      <li className="nav-item togmenu" style={{borderTop: '1px solid #585858',marginTop: '24px',paddingTop: '20px'}}>
                        <span className="nav-link" onClick={() => this.login(false)}>
                          <span className="txtt11" style={{fontSize:'16px'}}><Power style={{verticalAlign:'sub',marginRight:'22px'}} color="white" size={20} /> Log out</span>
                        </span>
                      </li>
                    }
                    {this.state.isLoggedIn ? (
                    <li className="nav-item loggedin_nav_li hidesmscreen" style={{marginRight:"10px",margin:"auto"}}>
                      <div className="top_wallet_list" onMouseLeave={() => this.showTopWallets(false)}>
                        <div style={{display:"inline-flex",cursor:"pointer"}}>
                          <p style={{background:"#222D3B",border:"none",padding:"8px 12px"}} onClick={() => this.showTopWallets(!this.state.showTopWalletList)}>0.00000000 <img src={'assets/wallet1.png'} style={{height:"18px",verticalAlign:"sub"}} alt="wallet"/>
                          {this.state.showTopWalletList ?
                          (<CaretDownFill color="white" size={16} />):
                          (<CaretUpFill color="white" size={16} />)}
                          </p>
                          <span style={{background:"#007bff"}} className="top_wallet_btn" onClick={()=>this.props.prop.history.push('/wallets#wallet')}><WalletFill color="white" size={16} /> Wallet</span>
                        </div>
                        {this.state.showTopWalletList ?
                          (<div className="top_wallet_list_ul">
                            <ul>
                            {/* {this.state.allCurrencies.map(currency_data => (
                              <li key={currency_data}><img src={'assets/wallet1.png'} style={{height:"18px"}} alt="wallet"/> {currency_data} - <span className="curr_symbol"></span>0.00000000</li>
                            ))}
                            {this.state.fiatCurrencies.map(currency_data => (
                              <li key={currency_data}><img src={'assets/wallet1.png'} style={{height:"18px"}} alt="wallet"/> {currency_data} - <span className="curr_symbol"></span>0.00000000</li>
                            ))} */}
                            {this.state.allWalletss.map(data => (
                              <li key={data.id}><img src={'assets/wallet1.png'} style={{height:"18px"}} alt="wallet"/> {data.currency} - <span className="curr_symbol"></span>{data.balance}</li>
                            ))}
                              <li style={{fontWeight:"700",marginTop:"10px",fontSize:"14px"}}>View in $/€/¥ 
                                {!this.state.showCurrencyConverter ?
                                  (<span style={{marginLeft:"16px",cursor:"pointer"}} onClick={() => this.currency_converter(true)}><Toggle2Off color="#92989E" size={26} /></span>)
                                :
                                  (<span style={{marginLeft:"16px",cursor:"pointer"}} onClick={() => this.currency_converter(false)}><Toggle2On color="#92989E" size={26} /></span>)
                                }
                              </li>
                            </ul>
                          </div>):('')}
                      </div>
                    </li>
                    ) : (
                      <li className="nav-item lism" style={this.state.isLoggedIn ? {display:'none'} : {position:'relative'}}>
                        {this.state.err_msg!=='' &&
                          <span className="text-center" style={{fontSize: '12px',color: '#ff2424',position: 'absolute',bottom: 0,left: '9px'}}>{this.state.err_msg}</span>
                        }
                        <input className="form-control navbar_fields" id="usernamme" onChange={this.changeUsername} style={{display:'inline-block'}} placeholder="Username"/>
                        <input className="form-control navbar_fields" type="password" onChange={this.changePwd} style={{display:'inline-block'}} placeholder="Password"/>
                        <button className="nav-link btn btn-primary comn_btn new_login_btn nav_btns" style={{border: '1px solid #ffffff40',borderRadius: '3px',fontSize: '13px',background: 'transparent',marginRight: '6px',display: 'inline-block'}} onClick={this.loginRequest}>Log in</button>
                        <button className="nav-link btn btn-primary login_btn comn_btn loginsmbtn nav_btns" style={{marginRight:'16px',display: 'inline-block'}} onClick={() => this.LoginModal(true)}>Log in</button>
                        <Modal size="md" show={this.state.showLoginModal} className="pwd_changed_modal bank_account_modal wallet_modal login_modal">
                          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.LoginModal(false)}></Modal.Header>
                          <Modal.Body>
                            <div>
                              <h1 className="text-center" style={{fontWeight:'bold'}}>LOG IN</h1>
                              <p className="loginwithtxt">Login with your data that you entered during your registration.</p><br/>
                              {this.state.err_msg!=='' &&
                                <p className="text-center" style={{fontSize: '16px',color: '#ff2424'}}>{this.state.err_msg}</p>
                              }
                              <div>
                                <input placeholder="Username" onChange={this.changeUsername} className="form-control"/>
                              </div><br/>
                              <div>
                                <input placeholder="Password" onChange={this.changePwd} type="password" className="form-control"/>
                              </div><br/>
                              <div className="keepp">
                                <input type="checkbox" style={{width:"auto"}}/> <span>Keep me logged in</span>
                                <Link to="/forgot_password">Forgot Password?</Link>
                              </div><br/><br/>
                              <button className="btn btn-primary login_btn w-100" onClick={this.loginRequest} style={{height:"auto"}}>LOGIN</button><br/><br/>
                              <div className="text-center">
                                <span style={{fontSize: '12px',opacity: 0.75,fontWeight: 300}}>Don't have an account? <Link to="/create_account#user" style={{color:'#ffc63a',opacity:1,textDecoration:'none'}}>Sign up</Link></span>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                        <Link to="/create_account#user" className="text-white signupp" style={{opacity: 0.75,textDecoration: 'none'}}>Sign up</Link>
                      </li>
                    )}
                    {this.state.isLoggedIn ? (
                      <li className="nav-item dropdown loggedin_nav_li">
                        <span className="nav-link hidesmscreen" style={{margin:0,position:'relative'}}><Bell color="#DEE2E6" size={20} onClick={()=>this.notifDiv(!this.state.showNotif)} /><img alt="navicon2" className="d-none" src={'assets/icn2.png'}/>
                        <span className="counttt">{this.state.countNotif}</span>
                          {this.state.showNotif &&
                            <div className="mailDropdown">
                              <div className="sdiv1">
                                <h5>Messages <span onClick={this.toMailbox} style={{float:'right',fontSize: '12px',marginTop: '6px',color: '#f3b92b',textDecoration: 'underline'}}>All messages</span></h5>
                              </div>
                              <div className="outrdv" style={{height: '242px',overflowY: 'scroll'}}>
                                {this.state.countNotif!==0 && this.state.mailData.map(todo => (
                                  <div key={todo.id} className={todo.isRead===false ? 'active sdiv2' : 'sdiv2'} onClick={this.toMailbox}>
                                    <ChatRightText color="#ffffff" size={18} />
                                    <div>
                                      <h6>{todo.subject}</h6>
                                      <p>{todo.detail}</p>
                                      <span>{todo.date_time}</span>
                                    </div>
                                  </div>
                                ))}
                                {this.state.countNotif===0 &&
                                  <h4 style={{paddingTop: '24px',margin: 0,fontSize:"16px",textAlign:"center"}}>No messages to show!</h4>
                                }
                              </div>
                            </div>
                          }
                        </span>
                        {localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!=='' &&
                          <span className="nav-link dropdown-toggle hidesmscreen" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{marginTop:0}}>
                            <div className="userimg"><img alt="crown_icon" style={{objectFit: 'cover'}} height="18px" width="18px" src={(JSON.parse(localStorage.getItem('userInfo'))).avatar!==null ? (JSON.parse(localStorage.getItem('userInfo'))).avatar  : 'assets/goldcrown.png'}/></div> {(JSON.parse(localStorage.getItem('userInfo'))).username}
                          </span>
                        }
                        <ul className="dropdown-menu hidesmscreen" aria-labelledby="navbarDropdown">
                          <li>
                          {localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!=='' &&
                            <span className="dropdown-item fstanch" onClick={()=>this.props.prop.history.push('/wallets#account')}>
                              <div className="userimg" style={{width: '49px',height: '48px',paddingTop: '6px',marginRight: '7px'}}><img alt="crown_icon" src={(JSON.parse(localStorage.getItem('userInfo'))).avatar!==null ? (JSON.parse(localStorage.getItem('userInfo'))).avatar  : 'assets/goldcrown.png'} height="30px" width="30px" style={{verticalAlign:'text-top',objectFit: 'cover'}}/></div> 
                              {(JSON.parse(localStorage.getItem('userInfo'))).username } <span className="user_role">{(JSON.parse(localStorage.getItem('userInfo'))).role}</span><br/><span style={{position: 'absolute',top: '30px',left: '72px',fontSize: '12px',color: 'black'}}>{(JSON.parse(localStorage.getItem('userInfo'))).email}</span>
                            </span>
                          }
                          </li>
                          <li><Link to="/wallets#wallet" className="dropdown-item thrdanch">Wallet</Link></li>
                          <li><Link to="/vip" className="dropdown-item thrdanch">Vip</Link></li>
                          <li><Link to="/wallets#promotions" className="dropdown-item thrdanch">Promotions</Link></li>
                          <li><span className="dropdown-item thrdanch">Referral bonus</span></li>
                          <li><Link to="/wallets#agent" className="dropdown-item thrdanch">Agent</Link></li>
                          <li><Link to="/wallets#account" className="dropdown-item thrdanch">Settings</Link></li>
                          <li><Link to="/wallets#notifications" className="dropdown-item thrdanch">Notification</Link></li>
                          <li><span className="dropdown-item thrdanch">Live Support</span></li>
                          <li><span className="dropdown-item thrdanch" onClick={() => this.login(false)}>Sign Out</span></li>
                        </ul>
                      </li>
                    ) : (
                      <li className="nav-item hidesmscreen">
                        <Link to="/create_account#user" className="nav-link btn btn-primary signup_btn comn_btn new_signup_btn nav_btns">Sign up</Link>
                        <Link to="/forgot_password" className="fgpwd">Forgot Password?</Link>
                      </li>
                    )}                    
                  </ul>
                </div>
            </div>
          </nav>
          
          {this.state.frontSidebar ?
          (<div className="front_sidebar">
          <img alt="header_logo" src={'assets/vigobet-lion.png'}/>
          <span onClick={() => this.showSidebar(false)}><XSquare size="30" color="#ffffff"/></span>
          <ul>
            {this.state.isLoggedIn &&
            <li className="nav-item dropdown loggedin_nav_li side_navbar_hide">
              {localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!=='' &&
                <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{marginTop:0,color:"white",paddingLeft:"0"}}>
                <div className="userimg"><img alt="crown_icon" style={{objectFit: 'cover',padding:"1px"}} height="24px" width="24px" src={(JSON.parse(localStorage.getItem('userInfo'))).avatar!==null ? (JSON.parse(localStorage.getItem('userInfo'))).avatar  : 'assets/goldcrown.png'}/></div>{(JSON.parse(localStorage.getItem('userInfo'))).username}  <b className="user_role" style={{paddingTop:"0",marginRight:"0px"}}>{(JSON.parse(localStorage.getItem('userInfo'))).role}</b>
                <span className="user_span" style={{position: 'absolute',top: '21px',left: '70px',fontSize: '10px',color: 'white'}}>{(JSON.parse(localStorage.getItem('userInfo'))).email}</span>
                </span>
              }
              <ul className="dropdown-menu front_sidebar_screen_ul" aria-labelledby="navbarDropdown">
                <li><Link to="/wallets#wallet" className="dropdown-item thrdanch">Wallet</Link></li>
                <li><Link to="/vip" className="dropdown-item thrdanch">Vip</Link></li>
                <li><Link to="/wallets#promotions" className="dropdown-item thrdanch">Promotions</Link></li>
                <li><Link to="/referral" className="dropdown-item thrdanch">Referral bonus</Link></li>
                <li><Link to="/wallets#agent" className="dropdown-item thrdanch">Agent</Link></li>
                <li><Link to="/wallets#account" className="dropdown-item thrdanch">Settings</Link></li>
                <li><Link to="/wallets#notifications" className="dropdown-item thrdanch">Notification</Link></li>
                <li><span className="dropdown-item thrdanch">Live Support</span></li>
                <li><span className="dropdown-item thrdanch" onClick={() => this.login(false)}>Sign Out</span></li>
              </ul>
            </li>}
            <li onClick={() => this.ppModal(true,'Casino')}>Casino</li>
            <li onClick={() => this.ppModal(true,'Sportsbook')}>Sports</li>
            <div className="sidebar_border_line"></div>
            <li><Link to="/promotion">Promotions</Link></li>
            <li onClick={() => this.ppModal(true,'Ranking')}>Ranking</li>
            <div className="sidebar_border_line"></div>
            <li><Link to="/referral">Referral</Link></li>
            <li><Link to="/vip">VIP</Link></li>
            <li><Link to="/live_casino">Live Casino</Link></li>
            <li><Link to="/slots">Slots</Link></li>
            <li onClick={() => this.ppModal(true,'4D Lottery')}>4D Lottery</li>
            <li onClick={() => this.ppModal(true,'Prediction')}>Prediction</li>
            <div className="sidebar_border_line"></div>
          </ul>
          <Modal size="lg" show={this.state.show_ppModal} className="pwd_changed_modal bank_account_modal wallet_modal">
            <Modal.Header style={{background:'none'}} closeButton onClick={() => this.ppModal(false,'')}></Modal.Header>
            <Modal.Body>
              <div className="">
                <h2 style={{fontSize: '30px',fontWeight: 600,margin: 0}} className="text-white text-center">{this.state.content}</h2>
                <div className="text-center"><img src={'assets/aboutus.png'} alt="about"/></div><br/>
                <p>GboBet is today one of the leading suppliers of online gaming products worldwide, offering a wide range of first-class online gambling products and games in a safe and user-friendly gaming environment. GboBet has been able establish themselves as one of the most trusted and reliable gaming companies in the market nowadays.</p>
                <p>GboBet offers its customers a wide selection of innovative products such as Live Casino, Sports Betting and slots games. In our aim to provide the best service online we also offer the unique and exclusive support guarantee, which applies 24 hours a day, 7 days a week. Our support team is available through e-mail, live chat and telephone. Please visit the support section for further details.</p>
                <p>With a solid product range and unique customer support, GboBet becomes the obvious choice for gaming enthusiasts all over the world!</p>
                <p>Thank you for choosing Gbobet.com as your preferred choice of online gaming. We hope you enjoy playing with us, and if there is anything we can help you with please do not hesitate to contact us.</p>
              </div>
            </Modal.Body>
          </Modal>      
          </div>):('')}

          <div className="nav_notify_div">
            <Modal show={this.state.show_email_notify_div} size="sm" className="pwd_changed_modal bank_account_modal notif_modal">
              <Modal.Header style={{background:'none'}} closeButton onClick={() => this.closeEmailNotifyDiv()}></Modal.Header>
              <Modal.Body>
                <div className=""><br/><br/>
                  <span className="check_icon"><CheckLg size="30" color="#ffffff"/></span>
                  <h2 style={{fontSize: '28px',fontWeight: 600,margin: 0,marginTop:"50px"}} className="text-white text-center">Welcome!</h2><br/>
                  <p style={{fontSize: '16px',color: 'rgb(165 166 167)',marginBottom:"0px",fontWeight:"700"}}>
                  Account Created!</p>
                  <p style={{fontSize: '16px',color: 'rgb(165 166 167)',marginBottom:"0px",fontWeight:"700"}}>
                  Login to start your journey now!</p><br/><br/>
                </div>
              </Modal.Body>
            </Modal>  
          </div>

          <div className="nav_notify_div">
            <Modal show={this.state.show_password_notify_div} size="md" className="pwd_changed_modal bank_account_modal notif_modal">
              <Modal.Header style={{background:'none'}} closeButton onClick={() => this.closePasswordNotifyDiv()}></Modal.Header>
              <Modal.Body>
                <div className=""><br/><br/>
                  <span className="check_icon"><CheckLg size="30" color="#ffffff"/></span>
                  <h2 style={{fontSize: '28px',fontWeight: 600,margin: 0,marginTop:"50px"}} className="text-white text-center">Reset!</h2><br/>
                  <p style={{fontSize: '16px',color: 'rgb(165 166 167)',marginBottom:"0px",fontWeight:"700"}}>
                  You have successfully reset your password!</p>
                  <p style={{fontSize: '16px',color: 'rgb(165 166 167)',marginBottom:"0px",fontWeight:"700"}}>
                  Login to start your journey now!</p><br/><br/>
                </div>
              </Modal.Body>
            </Modal>  
          </div>

        </div>
    );
  }
}

export default Navbar;
