import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Dot } from 'react-bootstrap-icons';
const data = [
  {id: 1,group_name: "dclient02"},{id: 2,group_name: "dclient02"},{id: 3,group_name: "dclient02"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class MemberGroup extends React.Component {
  render() {
    const columns = [
        {name: 'No.',selector: 'id',width:'50px'},
        {name: 'Group Name',selector: 'group_name'},
        {name: 'Privilege',cell: row => <div data-tag="allowRowEvents" className="incdec_div"><input type="number" defaultValue="1" className="form-control"/></div>,width:'100px'},
        {name: 'Min. Turnover',cell: row => <div data-tag="allowRowEvents" className="incdec_div"><input type="number" defaultValue="10.00" className="form-control"/></div>,width:'120px'},
        {name: 'Max. Turnover',cell: row => <div data-tag="allowRowEvents" className="incdec_div"><input type="number" defaultValue="0.00" className="form-control"/></div>,width:'120px'},
        {name: 'Min. Deposit',cell: row => <div data-tag="allowRowEvents" className="incdec_div"><input type="number" defaultValue="0.00" className="form-control"/></div>,width:'120px'},
        {name: 'Max. Deposit',cell: row => <div data-tag="allowRowEvents" className="incdec_div"><input type="number" defaultValue="0.00" className="form-control"/></div>,width:'120px'},
        {name: 'Disabled',cell: row => <div data-tag="allowRowEvents" style={{marginLeft:'-16px'}}><Dot color="#53D816" size={30}/>Enabled</div>,width:'100px'},
        {name: 'Actions',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}><span style={{cursor:'pointer'}}>Edit</span> | <span style={{cursor:'pointer'}}>Rebate</span> | <span style={{cursor:'pointer'}}>Cash Back</span></div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Member management</span> / 
                    <span>Member Group</span>
                  </div>
                  <h5 className="pg_head">Member Group</h5>
                  <button className="btn btn-primary addbtn">+ Add member group</button>
                  <button className="btn btn-primary addbtn cancel_btn">Update Tier</button>
                </div>
              </div><br/>
              <div className="divbody">
                <div className="subbdvv1">
                    <div className="subdvv1_2">
                        <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MemberGroup;