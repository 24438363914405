import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Dot,Search } from 'react-bootstrap-icons';
import { makeProviderRequest,timestampDate } from '../../helpers';
// const data = [
//   {
//     id: 1,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
//   {
//     id: 2,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
//   {
//     id: 3,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
// ];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '14px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class GameList extends React.Component {
  constructor(props) {
    super(props);
    this.makeProviderRequest = makeProviderRequest
    this.changeName = this.changeName.bind(this)
    this.changeType = this.changeType.bind(this)
    this.changeOrganization = this.changeOrganization.bind(this)
    this.addGame = this.addGame.bind(this)
    this.changeAddModal = this.changeAddModal.bind(this)
    this.changeDeleteModal = this.changeDeleteModal.bind(this)
    this.viewDeleteModal = this.viewDeleteModal.bind(this)
    this.deleteGame = this.deleteGame.bind(this)
    this.changeEditName = this.changeEditName.bind(this)
    this.changeEditType = this.changeEditType.bind(this)
    this.changeEditOrganization = this.changeEditOrganization.bind(this)
    this.viewEditModal = this.viewEditModal.bind(this)
    this.changeEditModal = this.changeEditModal.bind(this)
    this.updateGame = this.updateGame.bind(this)
  }
  state={
    games:[],
    showAddModal:false,
    name:'',
    type:'',
    organization:'',
    submitDisabled:false,
    error_msg:'',
    success_msg:'',
    submitDisabled1:false,
    error_msg1:'',
    success_msg1:'',
    submitDisabled2:false,
    error_msg2:'',
    success_msg2:'',
    showDeleteModal:false,
    delGameId:'',
    edit_name:'',
    edit_type:'',
    edit_organization:'',
    editGameId:'',
    showEditModal:false,
  }
  changeName(e) {
    this.setState({
      name: (e.target.value).toLowerCase()
    })
  }
  changeType(e) {
    this.setState({
      type: (e.target.value).toLowerCase()
    })
  }
  changeOrganization(e) {
    this.setState({
      organization: (e.target.value).toLowerCase()
    })
  }
  changeEditName(e) {
    this.setState({
      edit_name: (e.target.value).toLowerCase()
    })
  }
  changeEditType(e) {
    this.setState({
      edit_type: (e.target.value).toLowerCase()
    })
  }
  changeEditOrganization(e) {
    this.setState({
      edit_organization: (e.target.value).toLowerCase()
    })
  }
  changeAddModal(val){
    this.setState({showAddModal: val})
  }
  viewDeleteModal(val){
    this.setState({delGameId: val})
    this.setState({showDeleteModal: true})
  }
  changeDeleteModal(val){
    this.setState({showDeleteModal: val})
  }
  async addGame(){
    if(this.state.name===''){
      this.setState(state => ({error_msg:'Enter game name.'}));
    }else{
      this.setState(state => ({submitDisabled:true}));
      let data = JSON.stringify({name: this.state.name,type: this.state.type,organization: this.state.organization});
      let result1=await this.makeProviderRequest('game', 'post', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg:'Game created successfully.',error_msg:'',submitDisabled:false
        })
        this.componentDidMount();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false}));
    }
  }
  async deleteGame(){
    this.setState(state => ({submitDisabled1:true}));
    let result1=await this.makeProviderRequest('game/'+this.state.delGameId, 'delete', '',localStorage.getItem('role_token'));
    
    if(result1.status===200){
      this.setState({
        success_msg1:'Game deleted successfully.',error_msg1:'',submitDisabled1:false
      })
      this.setState({showDeleteModal: false})
      this.componentDidMount();
    }
    else if(result1.status===400){
      this.setState(state => ({error_msg1:result1.data.message,submitDisabled1:false}));
    }
    else if(result1.status===500)
      this.setState(state => ({error_msg1:'Some problem occured. Please try again.',submitDisabled1:false}));
   
  }
  async updateGame(){
    this.setState(state => ({submitDisabled2:true}));
    let data = JSON.stringify({name: this.state.edit_name,type: this.state.edit_type,organization: this.state.edit_organization});
    let result1=await this.makeProviderRequest('game/'+this.state.editGameId, 'put', data,localStorage.getItem('role_token'));
    if(result1.status===200){
      this.setState({
        success_msg2:'Game updated successfully.',error_msg2:'',submitDisabled2:false
      })
      this.componentDidMount();
    }
    else if(result1.status===400){
      this.setState(state => ({error_msg2:result1.data.message,submitDisabled2:false}));
    }
    else if(result1.status===500)
      this.setState(state => ({error_msg2:'Some problem occured. Please try again.',submitDisabled2:false}));
   
  }
  changeEditModal(val){
    this.setState({showEditModal: val})
  }
  async viewEditModal(val){
    this.setState({editGameId: val})
    this.setState({showEditModal: true})
    let result1=await this.makeProviderRequest('game/'+val, 'get', '',localStorage.getItem('role_token'));
    if(result1.status===200){
      this.setState({edit_name:result1.data.name})
      if(result1.data.type!==null){
        this.setState({edit_type:result1.data.type})
      }
      if(result1.data.organization!==null){
        this.setState({edit_organization:result1.data.organization})
      }
    }
  }
  componentDidMount() {
    if(localStorage.getItem('role')!=='provider'){
      this.props.history.push('/admin/admin_login');
    }
    (async () => {
      var result1=await this.makeProviderRequest('game', 'get','',localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState(state => ({games:result1.data.data}));
      }
    })();
	} 
  render() {
    const columns = [
    {
      name: 'Name',width: '180px',selector: 'name',cell: row => <div data-tag="allowRowEvents">{row.name}</div>,
    },
    {
      name: 'Type',width: '180px',selector: 'type',cell: row => <div data-tag="allowRowEvents">{row.type}</div>,
    },
    {
      name: 'Organization',width: '180px',selector: 'organization',cell: row => <div data-tag="allowRowEvents">{row.organization}</div>,
    },
    {
      name: 'Created date',width: '250px',selector: 'time_ip',cell: row => <div data-tag="allowRowEvents" style={{height: '40px',whiteSpace: 'initial'}}>{row.date!==null ?timestampDate(row.date) : ''}</div>,
    },
    {
      name: 'Actions',width: '236px',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}><span style={{cursor:'pointer'}} onClick={() => this.viewEditModal(row.key)}>Edit</span> | <span style={{cursor:'pointer'}} onClick={() => this.viewDeleteModal(row.key)}>Delete</span></div>,right:true
    },
  ];
  var data = this.state.games.map(function(item) {
    return {
      key: item.id,
      name:item.name,
      type: item.type,
      organization: item.organization,
      date: item.createdAt,
    };
  });
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>Games</span>
                  </div>
                  <h5 className="pg_head">Game List</h5>
                  <button className="btn btn-primary addbtn" onClick={() => this.changeAddModal(true)}>+ Add game</button>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              <div className="subbdvv1">
                {this.state.success_msg1!=='' ?
                  (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg1}</p>):('')
                }
                {this.state.error_msg1!=='' ?
                  (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg1}</p>):('')
                }
                <div className="subdvv1_2">
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal size="md" show={this.state.showAddModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.changeAddModal(false)}>Add game</Modal.Header>
          <Modal.Body>
              {this.state.success_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg}</p>):('')
              }
              {this.state.error_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg}</p>):('')
              }
            <div className="formdiv small_game_modal">
              <label>Name</label>
              <input className="form-control" onChange={this.changeName} />
            </div><br/>
            <div className="formdiv small_game_modal">
              <label>Type</label>
              <input className="form-control" onChange={this.changeType} />
            </div><br/>
            <div className="formdiv small_game_modal">
              <label>Organization</label>
              <input className="form-control" onChange={this.changeOrganization} />
            </div><br/>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <span onClick={() => this.changeAddModal(false)}>Cancel</span>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.addGame} disabled={this.state.submitDisabled}>Create</button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal size="md" show={this.state.showDeleteModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.changeDeleteModal(false)}>Delete game</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              <h4>Are you sure</h4>
              <p>You want to delete this game ?</p>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.changeDeleteModal(false)} className="del_no_btn">No</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.deleteGame} disabled={this.state.submitDisabled}>Yes</button>
            </div>
          </Modal.Footer><br/>
        </Modal>

        <Modal size="md" show={this.state.showEditModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.changeEditModal(false)}>Update game</Modal.Header>
          <Modal.Body>
              {this.state.success_msg2!=='' ?
                (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg2}</p>):('')
              }
              {this.state.error_msg2!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg2}</p>):('')
              }
            <div className="formdiv small_game_modal">
              <label>Name</label>
              <input className="form-control" onChange={this.changeEditName} value={this.state.edit_name}/>
            </div><br/>
            <div className="formdiv small_game_modal">
              <label>Type</label>
              <input className="form-control" onChange={this.changeEditType} value={this.state.edit_type} />
            </div><br/>
            <div className="formdiv small_game_modal">
              <label>Organization</label>
              <input className="form-control" onChange={this.changeEditOrganization} value={this.state.edit_organization} />
            </div><br/>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <span onClick={() => this.changeEditModal(false)}>Cancel</span>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateGame} disabled={this.state.submitDisabled2}>Update</button>
            </div>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

export default GameList;
