import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import { CaretDownFill } from 'react-bootstrap-icons';
import slots_data from './slots_data'

class Slots extends React.Component {  
  constructor(props) {
    super(props);
    this.filter = this.filter.bind(this)
  }
  state = {
    slots:slots_data,
    selected:''
  };
  
  filter(val){
    let arr = [];
    for (let i = 0; i < slots_data.length; i++) {
      if (slots_data[i].category === val) {
        arr.push(slots_data[i]);
      }
    }
    this.setState(state => ({slots: arr,selected:val}));
    if(val==='all' || val==='top'){
      this.setState(state => ({
        slots: slots_data,
        selected:val
      }));
    }
  }
  render() {
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{background:'#000C16',color:'white'}}>
            <br/><br/>
            <div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <h1 className="text-white text-center homeheadng">SLOTS</h1>
                    <div className="text-center">
                      <img alt="all games here" src={'assets/Group44.png'}/>
                    </div><br/><br/>
                  </div>
                </div>
                {/* games tab */}
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12">
                    <div className="show595scr">
                      <div style={{position:'relative',height:'100%'}}>
                        <span>Providers</span>
                        <img alt="slotimg" height="28px" src={'assets/slotimg.png'}/>
                        <CaretDownFill className="carett" fill="white" size="15"/>
                      </div>
                    </div>
                    <div className="row hide595scr">
                      <div className="col-sm text-center slotsouterdiv">
                        <div className="slotsdiv active">
                          <img alt="slotimgtab1" className="w-100" src={'assets/slotimg.png'}/>
                        </div>
                      </div>
                      <div className="col-sm text-center slotsouterdiv">
                        <div className="slotsdiv">
                          <img alt="slotimgtab2" className="w-100" src={'assets/slotimg.png'}/>
                        </div>
                      </div>
                      <div className="col-sm text-center slotsouterdiv">
                        <div className="slotsdiv">
                          <img alt="slotimgtab3" className="w-100" src={'assets/slotimg.png'}/>
                        </div>
                      </div>
                      <div className="col-sm text-center slotsouterdiv">
                        <div className="slotsdiv">
                          <img alt="slotimgtab4" className="w-100" src={'assets/slotimg.png'}/>
                        </div>
                      </div>
                      <div className="col-sm text-center slotsouterdiv">
                        <div className="slotsdiv">
                          <img alt="slotimgtab5" className="w-100" src={'assets/slotimg.png'}/>
                        </div>
                      </div>
                      <div className="col-sm text-center slotsouterdiv">
                        <div className="slotsdiv">
                          <img alt="slotimgtab1" className="w-100" src={'assets/slotimg.png'}/>
                        </div>
                      </div>
                      <div className="col-sm text-center slotsouterdiv">
                        <div className="slotsdiv">
                          <img alt="slotimgtab2" className="w-100" src={'assets/slotimg.png'}/>
                        </div>
                      </div>
                      <div className="col-sm text-center slotsouterdiv">
                        <div className="slotsdiv">
                          <img alt="slotimgtab3" className="w-100" src={'assets/slotimg.png'}/>
                        </div>
                      </div>
                      <div className="col-sm text-center slotsouterdiv">
                        <div className="slotsdiv">
                          <img alt="slotimgtab4" className="w-100" src={'assets/slotimg.png'}/>
                        </div>
                      </div>
                      <div className="col-sm text-center slotsouterdiv">
                        <div className="slotsdiv">
                          <img alt="slotimgtab5" className="w-100" src={'assets/slotimg.png'}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>           
                {/* games tab */}

                <br/>
                {/* filters */}
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-12">                        
                      <ul className="navbar-nav d-flex filters_ul">
                        <li className="nav-item" style={this.state.selected==='top' ? {color:'#d8a62c',opacity:1} : {} } onClick={()=>this.filter('top')}>Top</li>
                        <li className="nav-item" onClick={()=>this.filter('all')} style={this.state.selected==='all' ? {color:'#d8a62c',opacity:1} : {} }>All</li>
                        <li className="nav-item" onClick={()=>this.filter('slots')} style={this.state.selected==='slots' ? {color:'#d8a62c',opacity:1} : {} }>Slots</li>
                        <li className="nav-item" onClick={()=>this.filter('progressive')} style={this.state.selected==='progressive' ? {color:'#d8a62c',opacity:1} : {} }>Progressive</li>
                        <li className="nav-item" onClick={()=>this.filter('table_games')} style={this.state.selected==='table_games' ? {color:'#d8a62c',opacity:1} : {} }>Table Games</li>
                        <li className="nav-item" onClick={()=>this.filter('video')} style={this.state.selected==='video' ? {color:'#d8a62c',opacity:1} : {} }>Video</li>
                      </ul> 
                    </div>
                </div>             
                {/* filters */}
                <br/>
                {/* games */}
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12">
                    <div className="row">
                      {(this.state.slots).map(item=>(
                        <div className="col-sm text-center gamesouterdiv" key={item.id}>
                          <div key={item.id}>
                            <img alt="slotgame1" className="w-100" src={'assets/'+item.img}/>
                            <h5 className="gamename">{item.gname}</h5>
                          </div>
                        </div>
                      ))}
                      {/* <div className="col-sm text-center gamesouterdiv">
                        <div>
                          <img alt="slotgame1" className="w-100" src={'assets/slot_game.png'}/>
                          <h5 className="gamename">Pirates Party</h5>
                        </div>
                      </div>
                      <div className="col-sm text-center gamesouterdiv">
                        <div>
                          <img alt="slotgame1" className="w-100" src={'assets/fairyparty.png'}/>
                          <h5 className="gamename">Fairy Fantasy</h5>
                        </div>
                      </div>
                      <div className="col-sm text-center gamesouterdiv">
                        <div>
                          <img alt="slotgame1" className="w-100" src={'assets/fiveofswords.png'}/>
                          <h5 className="gamename">Five of Swords</h5>
                        </div>
                      </div>
                      <div className="col-sm text-center gamesouterdiv">
                        <div>
                          <img alt="slotgame1" className="w-100" src={'assets/vampiretrack.png'}/>
                          <h5 className="gamename">Vampire Track</h5>
                        </div>
                      </div>
                      <div className="col-sm text-center gamesouterdiv">
                        <div>
                          <img alt="slotgame1" className="w-100" src={'assets/tenofswords.png'}/>
                          <h5 className="gamename">Ten of Swords</h5>
                        </div>
                      </div>
                      <div className="col-sm text-center gamesouterdiv">
                        <div>
                          <img alt="slotgame1" className="w-100" src={'assets/mothboy.png'}/>
                          <h5 className="gamename">Moth Boy</h5>
                        </div>
                      </div>
                      <div className="col-sm text-center gamesouterdiv">
                        <div>
                          <img alt="slotgame1" className="w-100" src={'assets/fourwands.png'}/>
                          <h5 className="gamename">Four of Wands</h5>
                        </div>
                      </div>
                      <div className="col-sm text-center gamesouterdiv">
                        <div>
                          <img alt="slotgame1" className="w-100" src={'assets/wolfstone.png'}/>
                          <h5 className="gamename">Wolfstone</h5>
                        </div>
                      </div>
                      <div className="col-sm text-center gamesouterdiv">
                        <div>
                          <img alt="slotgame1" className="w-100" src={'assets/goblintour.png'}/>
                          <h5 className="gamename">Goblin Tour</h5>
                        </div>
                      </div>
                      <div className="col-sm text-center gamesouterdiv">
                        <div>
                          <img alt="slotgame1" className="w-100" src={'assets/thenewmoon.png'}/>
                          <h5 className="gamename">The New Moon</h5>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* games */}           
              </div>
            </div>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
        </div>
    );
  }
}

export default Slots;
