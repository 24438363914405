import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Search,Dot } from 'react-bootstrap-icons';
import { makeRequest,timestampDate } from '../../helpers';
  const data_history = [
    {id: 1,transaction_date: "2021-02-28 14:53:05",trans_id:"11655",trans_type:"Withdraw",account_name:"Demo Client 02",bank_account_no:"4661 1056 7680 5777",status:"Pending",account_thb:"5.000"},
  ];
  const data_withdraw = [
    {id: 1,transaction_date: "2021-02-28 14:53:05",trans_id:"11655",wallet:"Metamask",bank_name:"HSBC",account_name:"Demo Client 02",bank_account_no:"4661 1056 7680 5777",status:"Pending",account_thb:"5.000"},
    {id: 2,transaction_date: "2021-02-28 14:53:05",trans_id:"11655",wallet:"Metamask",bank_name:"HSBC",account_name:"Demo Client 02",bank_account_no:"4661 1056 7680 5777",status:"Pending",account_thb:"5.000"},
    {id: 3,transaction_date: "2021-02-28 14:53:05",trans_id:"11655",wallet:"Metamask",bank_name:"HSBC",account_name:"Demo Client 02",bank_account_no:"4661 1056 7680 5777",status:"Pending",account_thb:"5.000"},
    {id: 4,transaction_date: "2021-02-28 14:53:05",trans_id:"11655",wallet:"Metamask",bank_name:"HSBC",account_name:"Demo Client 02",bank_account_no:"4661 1056 7680 5777",status:"Pending",account_thb:"5.000"},
    {id: 5,transaction_date: "2021-02-28 14:53:05",trans_id:"11655",wallet:"Metamask",bank_name:"HSBC",account_name:"Demo Client 02",bank_account_no:"4661 1056 7680 5777",status:"Pending",account_thb:"5.000"},
    {id: 6,transaction_date: "2021-02-28 14:53:05",trans_id:"11655",wallet:"Metamask",bank_name:"HSBC",account_name:"Demo Client 02",bank_account_no:"4661 1056 7680 5777",status:"Pending",account_thb:"5.000"},
  ];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class Transaction extends React.Component {
    constructor(props) {
        super(props);
        this.makeRequest = makeRequest
        this.changeTab = this.changeTab.bind(this)
        this.showStatusModal = this.showStatusModal.bind(this)
        this.update = this.update.bind(this)
        this.changeFee = this.changeFee.bind(this)
        this.changeRemark = this.changeRemark.bind(this)
    }
    state= {
        currentTab:'all_transactions',
        depositTrans:[],
        withdrawTrans:[],
        allTrans:[],
        trans_id:'',
        showModal:false,
        submitDisabled:false,
        error_msg:'',
        success_msg:'',
        status:'approved_manually',
        fee:'',
        remark:'',
    }
    changeTab(val){
        this.setState(state => ({currentTab: val}));
    }
    changeFee(e){
        this.setState(state => ({fee: e.target.value}));
    }
    changeRemark(e){
        this.setState(state => ({remark: e.target.value}));
    }
    showStatusModal(val,showVal){
        this.setState(state => ({showModal: showVal}));
        this.setState(state => ({trans_id: val}));
    }
    async update(){
      console.log(this.state.remark);
      if(this.state.fee===''){
        this.setState(state => ({error_msg:'Enter Fee.'}));
      }else{
        this.setState(state => ({submitDisabled:true}));
        var data;
        data = JSON.stringify({status: this.state.status,fee: parseInt(this.state.fee),remark: this.state.remark});
        var result1=await this.makeRequest('transaction/'+this.state.trans_id+'/status', 'put', data,localStorage.getItem('role_token'));
        if(result1.status===200){
          this.setState({
            success_msg:result1.data.message,error_msg:'',submitDisabled:false
          })
          this.componentDidMount();
        }
        else if(result1.status===400){
          this.setState(state => ({error_msg:result1.data.message,submitDisabled:false,success_msg:''}));
        }
        else if(result1.status===500)
          this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false,success_msg:''}));
      }
    }
    componentDidMount() {
      // (async () => {
      //   var result1=await this.makeRequest('btsepay/transaction?type=deposit', 'get','',localStorage.getItem('role_token'));
      //   if(result1.status===200){
      //     this.setState(state => ({depositTrans: result1.data.data}));
      //   }
      // })();
      // (async () => {
      //   var result1=await this.makeRequest('btsepay/transaction?type=withdraw', 'get','',localStorage.getItem('role_token'));
      //   if(result1.status===200){
      //     this.setState(state => ({withdrawTrans: result1.data.data}));
      //   }
      // })();
      (async () => {
        var result1=await this.makeRequest('transaction', 'get','',localStorage.getItem('role_token'));
        if(result1.status===200){
          this.setState(state => ({allTrans: result1.data.data}));
        }
      })();
    } 
  render() {
    const data_transactions = this.state.allTrans.map(function(item,index) {
      return {
        no:parseInt(index+1),
        key: item.id,
        trans_dt:timestampDate(item.createdAt),
        account_id:  (JSON.parse(item.user)).id,
        type: item.type,
        email: (JSON.parse(item.user)).email,
        status: item.status,
        amount: item.amount,
        currency: item.currency,
        deposit_channel:item.depositChannel,
        memberGroup: (JSON.parse(item.user)).memberGroup,
        details:item.remark,
        fees:item.fee
      }
    });
    const columns_transactions = [
      {name: 'No.',selector: 'id',width:"40px",cell: row => <div data-tag="allowRowEvents">{row.no}</div>},
      {name: 'Transaction Date',width:"200px",selector: 'transaction_date',cell: row => <div data-tag="allowRowEvents">{row.trans_dt}</div>},
      {name: 'Trans ID',width:"170px",selector: 'trans_id',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff',fontWeight:"700",cursor:"pointer"}} onClick={()=>this.props.history.push('/admin/transaction_details#'+row.key)}>{row.key}</div>},
      {name: 'Account ID',width:"170px",selector: 'account_id',cell: row => <div data-tag="allowRowEvents">{row.account_id}</div>},
      {name: 'Group',selector: 'group',width:"100px",cell: row => <div data-tag="allowRowEvents">{row.memberGroup!==null ? (JSON.parse(row.memberGroup)).name :''}</div>},
      {name: 'Email',selector: 'email',width:"190px",cell: row => <div data-tag="allowRowEvents">{row.email}</div>},
      {name: 'Account Name',selector: 'account_name',width:"120px",cell: row => <div data-tag="allowRowEvents">{row.deposit_channel !== null ? (JSON.parse(row.deposit_channel)).accountName : ''}</div>},
      {name: 'Transaction Type',width:"120px",selector: 'transaction_type',cell: row => <div data-tag="allowRowEvents">{row.type}</div>},
      {name: 'Details',selector: 'details',cell: row => <div data-tag="allowRowEvents">{row.details}</div>},
      {name: 'Status',width:'180px',selector: 'status',cell: row => <div data-tag="allowRowEvents" style={{marginLeft:'-16px'}}><Dot color="rgb(255, 113, 29)" size={30}/> {row.status}</div>},
      {name: 'Debit',selector: 'debit',cell: row => <div data-tag="allowRowEvents">{row.type==='withdraw' ? row.currency +' '+row.amount : '-'}</div>},
      {name: 'Credit',selector: 'credit',cell: row => <div data-tag="allowRowEvents">{row.type==='deposit' ? row.currency +' '+row.amount : '-'}</div>},
      {name: 'Fees',width:"50px",selector: 'fees',cell: row => <div data-tag="allowRowEvents">{row.fees}</div>},
      {name: 'Action',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff',cursor:"pointer"}} onClick={()=>this.showStatusModal(row.key,true)}>{row.status==='approved_manually' ? '' : 'Change status'}</div>},
    ];
      const columns_deposit = [
        {name: 'No.',selector: 'id',width:"60px",cell: row => <div data-tag="allowRowEvents">{row.no}</div>},
        {name: 'Transaction Date',width:"200px",selector: 'transaction_date',cell: row => <div data-tag="allowRowEvents">{row.trans_dt}</div>},
        {name: 'Trans ID',width:"170px",selector: 'trans_id',cell: row => <div data-tag="allowRowEvents"  style={{color:'#1890ff',fontWeight:"700",cursor:"pointer"}} onClick={()=>this.props.history.push('/admin/transaction_details#'+row.key)}>{row.key}</div>},
        {name: 'Bank Name',selector: 'bank_name',cell: row => <div data-tag="allowRowEvents">{row.deposit_channel!==null ? (JSON.parse(row.deposit_channel)).bankName : ''}</div>},
        {name: 'Account Name',selector: 'account_name',cell: row => <div data-tag="allowRowEvents">{row.deposit_channel!==null ? (JSON.parse(row.deposit_channel)).accountName : ''}</div>},
        {name: 'Bank Account Number',width:"170px",selector: 'bank_account_no',cell: row => <div data-tag="allowRowEvents">{row.deposit_channel!==null ? (JSON.parse(row.deposit_channel)).accountNumber : ''}</div>},
        {name: 'Type',selector: 'deposit_type',cell: row => <div data-tag="allowRowEvents">{row.type}</div>},
        {name: 'Minimum Deposit',selector: 'minimum_deposit',cell: row => <div data-tag="allowRowEvents">{row.deposit_channel!==null ? (JSON.parse(row.deposit_channel)).minimumDeposite : ''}</div>},
        {name: 'Action',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff',cursor:"pointer"}} onClick={()=>this.showStatusModal(row.key,true)}>{row.status==='approved_manually' ? '' : 'Change status'}</div>},
      ];
      var data_deposit = this.state.allTrans.filter(itemm => itemm.type === 'deposit').map(function(item,index) {
        return {
          no:parseInt(index+1),
          key: item.id,
          trans_dt:timestampDate(item.createdAt),
          account_id:  (JSON.parse(item.user)).id,
          type: item.type,
          email: (JSON.parse(item.user)).email,
          status: item.status,
          amount: item.amount,
          currency: item.currency,
          deposit_channel:item.depositChannel,
          memberGroup: (JSON.parse(item.user)).memberGroup,
          details:item.remark,
          fees:item.fee
        };
      });
      const columns_history = [
        {name: 'No.',selector: 'id',width:"60px",cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
        {name: 'Transaction Date',width:"170px",selector: 'transaction_date',cell: row => <div data-tag="allowRowEvents">2021-02-28 14:53:05</div>},
        {name: 'Trans ID',selector: 'trans_id',cell: row => <div data-tag="allowRowEvents"  style={{color:'#1890ff',fontWeight:"700"}}>11655</div>},
        {name: 'Transaction Type',selector: 'trans_type',cell: row => <div data-tag="allowRowEvents">Withdraw</div>},
        {name: 'Account Name',selector: 'account_name',cell: row => <div data-tag="allowRowEvents">Demo Client 01</div>},
        {name: 'Bank Account Number',width:"170px",selector: 'bank_account_no',cell: row => <div data-tag="allowRowEvents">4661 1056 7680 5777</div>},
        {name: 'Status',selector: 'status',cell: row => <div data-tag="allowRowEvents" style={{marginLeft:'-16px'}}><Dot color="rgb(255, 113, 29)" size={30}/> Pending</div>},
        {name: 'Account (THB)',selector: 'account_thb',cell: row => <div data-tag="allowRowEvents">5.000</div>},
      ];
      var data_withdraw = this.state.allTrans.filter(itemm => itemm.type === 'withdraw').map(function(item,index) {
        return {
          no:parseInt(index+1),
          key: item.id,
          trans_dt:timestampDate(item.createdAt),
          trans_id: item.transactionId,
          account_id:  (JSON.parse(item.user)).id,
          type: item.type,
          email: (JSON.parse(item.user)).email,
          status: item.status,
          amount: item.amount,
          currency: item.currency,
          deposit_channel:item.depositChannel,
          memberGroup: (JSON.parse(item.user)).memberGroup,
          details:item.remark,
          fees:item.fee
        };
      });
      const columns_withdraw = [
        {name: 'No.',selector: 'id',width:"60px",cell: row => <div data-tag="allowRowEvents">{row.no}</div>},
        {name: 'Transaction Date',width:"200px",selector: 'transaction_date',cell: row => <div data-tag="allowRowEvents">{row.trans_dt}</div>},
        {name: 'Trans ID',width:"170px",selector: 'trans_id',cell: row => <div data-tag="allowRowEvents"  style={{color:'#1890ff',fontWeight:"700",cursor:"pointer"}} onClick={()=>this.props.history.push('/admin/transaction_details#'+row.key)}>{row.key}</div>},
        {name: 'Wallet',selector: 'wallet',cell: row => <div data-tag="allowRowEvents"></div>},
        {name: 'Bank Name',selector: 'bank_name',cell: row => <div data-tag="allowRowEvents">{row.deposit_channel!==null ? (JSON.parse(row.deposit_channel)).bankName : ''}</div>},
        {name: 'Account Name',selector: 'account_name',cell: row => <div data-tag="allowRowEvents">{row.deposit_channel!==null ? (JSON.parse(row.deposit_channel)).accountName : ''}</div>},
        {name: 'Bank Account Number',width:"170px",selector: 'bank_account_no',cell: row => <div data-tag="allowRowEvents">{row.deposit_channel!==null ? (JSON.parse(row.deposit_channel)).accountNumber : ''}</div>},
        {name: 'Status',width:"160px",selector: 'status',cell: row => <div data-tag="allowRowEvents" style={{marginLeft:'-16px'}}><Dot color="rgb(255, 113, 29)" size={30}/> {row.status}</div>},
        {name: 'Amount',selector: 'account_thb',cell: row => <div data-tag="allowRowEvents">{row.amount} {row.currency}</div>},
        {name: 'Action',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff',cursor:"pointer"}} onClick={()=>this.showStatusModal(row.key,true)}>{row.status==='approved_manually' ? '' : 'Change status'}</div>},
      ];
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px 0px'}}>
                    <div className="routeinfo">
                        <span>Home</span> / 
                        <span>&nbsp; Transactions</span> / 
                        <span>Transaction</span>
                    </div>
                    <h5 className="pg_head" style={{marginBottom:'0'}}>Transaction</h5>
                    <div style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Add Bank</button>
                    </div>
                    <br></br>
                    <div className="subbdvv1 sd1">
                        <ul className="tabbss transaction_tabs">
                            <li className={this.state.currentTab==='all_transactions' ? 'active' : ''} onClick={()=>this.changeTab('all_transactions')}>All Transactions</li>
                            <li className={this.state.currentTab==='transfer' ? 'active' : ''} onClick={()=>this.changeTab('transfer')}>Transfer</li>
                            <li className={this.state.currentTab==='deposit' ? 'active' : ''} onClick={()=>this.changeTab('deposit')}>Deposit</li>
                            <li className={this.state.currentTab==='withdraw' ? 'active' : ''} onClick={()=>this.changeTab('withdraw')}>Withdraw</li>
                            <li className={this.state.currentTab==='history' ? 'active' : ''} onClick={()=>this.changeTab('history')}>History</li>
                        </ul>
                    </div>
                </div>
              </div><br/>
            </div>
            {this.state.currentTab!='transfer' ?
            (<div className="divbody">
                <div className="trans_tabl" style={{margin:0,padding:'16px 14px',background:'white'}}>
                    <div className="subdvv1_1">
                        <div className="subdvv1_1_1">
                            <input type="text" className="form-control" placeholder="Search"/>
                            <Search color="#00000040" size={15}/>
                        </div>
                    </div> 
                    {this.state.currentTab==='all_transactions' &&
                    <DataTable title="" columns={columns_transactions} pagination data={data_transactions} customStyles={customStyles} style={{marginTop:"20px"}}/> }
                    {this.state.currentTab==='deposit' &&
                    <DataTable title="" columns={columns_deposit} pagination data={data_deposit} customStyles={customStyles} style={{marginTop:"20px"}}/> }
                    {this.state.currentTab==='history' &&
                    <DataTable title="" columns={columns_history} pagination data={data_history} customStyles={customStyles} style={{marginTop:"20px"}}/> }
                    {this.state.currentTab==='transfer' &&
                    ('') }
                    {this.state.currentTab==='withdraw' &&
                    <DataTable title="" columns={columns_withdraw} pagination data={data_withdraw} customStyles={customStyles} style={{marginTop:"20px"}}/> }
                </div>
            </div>):('')} 

          </div>
        </div>
        <Modal size="lg" show={this.state.showModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.showStatusModal('',false)}>Update transaction status</Modal.Header>
          <Modal.Body>
          {this.state.success_msg!=='' ?
            (<p style={{fontSize: '16px',color: 'green',textAlign:'center'}}>{this.state.success_msg}</p>):('')
          }
          {this.state.error_msg!=='' ?
            (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:'center'}}>{this.state.error_msg}</p>):('')
          }
              <div className="formdiv">
                  <label>Status</label>
                  <select className="form-control" onChange={this.currntOption}>
                      <option value="approved_manually">Approved Manually</option>
                  </select>
              </div><br/>   
              <div className="formdiv">
                  <label>Fee</label>
                  <input className="form-control" placeholder="Fee" onChange={this.changeFee}/>
              </div><br/>
              <div className="formdiv">
                  <label>Remark</label>
                  <textarea className="form-control" placeholder="Remark" onChange={this.changeRemark}/>
              </div><br/>
          </Modal.Body>
          <Modal.Footer>
              <div>
                  <span onClick={()=>this.showStatusModal('',false)}>Cancel</span>
                  <button className="btn btn-primary" style={{width:'auto'}} onClick={this.update} disabled={this.state.submitDisabled}>Update</button>
              </div>
          </Modal.Footer>
      </Modal>
      </div>
    );
  }
}
export default Transaction;