import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { makeRequest,handleKeyUp } from '../../helpers'
class CreateMaster extends React.Component {
	constructor(props) {
		super(props);
		this.makeRequest = makeRequest
		this.createMaster = this.createMaster.bind(this)
		this.change_username = this.change_username.bind(this)
		this.change_password = this.change_password.bind(this)
		this.change_email = this.change_email.bind(this)
	  }
	  state= {
		error_msg:'',
		success_msg:'',
		submitDisabled:false,
		username:'',
		password:'',
		email:'',
	  }
	  change_username(e){
		this.setState(state => ({username: e.target.value})); 
	  }
	  change_email(e){
		this.setState(state => ({email: e.target.value})); 
	  }
	  change_password(e){
		this.setState(state => ({password: e.target.value})); 
	  }
	  async createMaster(){
		if(this.state.username===''){
		  this.setState(state => ({error_msg: 'Please enter your username.'}));
		}else if(this.state.password===''){
		  this.setState(state => ({error_msg: 'Please enter your password.'}));
		}else if((this.state.password).length<8){
		  this.setState(state => ({error_msg:'Password must be atleast 8 Characters.'}));
		}else if(this.state.email===''){
		  this.setState(state => ({error_msg: 'Please enter your email address.'}));
		}else if((this.state.email).indexOf("@") === -1 || (this.state.email).indexOf(".") === -1){
		  this.setState(state => ({error_msg:'Invalid email address.'}));
		}else if(this.state.role===''){
		  this.setState(state => ({error_msg: 'Please enter your role.'}));
		}else{
		  this.setState(state => ({submitDisabled: true,error_msg:''}));
		  var data = JSON.stringify({username: this.state.username,password:this.state.password,email: this.state.email,role: "master"});
		  var result1=await this.makeRequest('manager/register', 'post', data,'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAxNzllZmY5LThmNzgtNDkzMC05OGUxLTc3ZTAwMWIzNTIyOCIsImlhdCI6MTYyMzIzNTAyMn0.1koUN05h7_DSEkTwdZcIHx8e6ldZrEiCiu86PMMpv94');
		  if(result1.status===200){
			this.setState({
			  success_msg:'Master created successfully.',submitDisabled:false,error_msg:''
			})
		  }else if(result1.status===400){
		   this.setState(state => ({error_msg:result1.data.message,submitDisabled:false,success_msg:''}));
		  }
		}
	  }
  render() {
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0,position:"relative"}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; xx</span> / 
                    <span>xx</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Create Master</h5>
                    <div style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}><Link to="/admin/master_management" style={{textDecoration:"none",color:"white"}}>Back to Master Management</Link></button>
                    </div>
                </div>
              </div><br/>
            </div>
            <div className="col-md-12 divbody">
                <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                    <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Informations</h5>
                  </div>
                <div className="row create_master" style={{margin:0,padding:'12px 14px 48px',background:'white'}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						{this.state.error_msg!=='' &&
						  <p style={{fontSize: '16px',color: 'red',textAlign:"center"}}>{this.state.error_msg}</p>
						}
						{this.state.success_msg!=='' &&
						  <p style={{fontSize: '16px',color: '#0bd60b',textAlign:"center"}}>{this.state.success_msg}</p>
						}
					</div>
					<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div1">
                        <label className="labls"><span style={{fontWeight:"700"}}>Username:</span>
                        <input type="text" className="form-control sms_blast" placeholder="Enter username" onChange={this.change_username}/></label>
                    </div>
					<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div1">
                        <label className="labls"><span style={{fontWeight:"700"}}>Password:</span>
                        <input type="password" className="form-control sms_blast" placeholder="Enter password" onChange={this.change_password}/></label>
                    </div>
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 top_div1">
                        <label className="labls"><span style={{fontWeight:"700"}}>Email Address:</span>
                        <input type="text" className="form-control sms_blast" placeholder="Enter email address" onChange={this.change_email}/></label>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div1">
                        <label className="labls"><span style={{fontWeight:"700"}}>Full Name:</span>
                        <input type="text" className="form-control sms_blast" placeholder="Enter master name"/></label>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div1">
                        <label className="labls"><span style={{fontWeight:"700"}}>Phone Number:</span>
                        <input type="text" className="form-control sms_blast" placeholder="0000-0000-0000"/></label>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div1">
                        <label className="labls"><span style={{fontWeight:"700"}}>Senior:</span>
                        <select className="form-control sms_blast" style={{width:"182px"}}>
                            <option>Choose senior</option>
                        </select></label>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div1">
                        <label className="labls"><span style={{fontWeight:"700"}}>Url:</span>
                        <input type="text" className="form-control sms_blast" placeholder="Enter url here"/></label>
                    </div>
                </div><br/>
            </div>

            <div style={{background: "white",position: "absolute",bottom: "0",width:"100%"}}>
                <div style={{padding: '10px 20px 20px'}}>
                    <div style={{marginTop:"10px",paddingLeft:"0"}}>
						<button className="btn btn-primary add_btn" style={{width:'auto',marginLeft:"20px"}} onClick={this.createMaster} >Create</button>
                    </div>
                </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
export default CreateMaster;