import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Dot,Trash,PersonFill,BookmarkFill,ClipboardCheck} from 'react-bootstrap-icons';
import { makeRequest,timestampDate } from '../../helpers';
import exportFromJSON from 'export-from-json'
// const data = [
  // {id: 1,vip_level: "VIP1A",walletbal: "75.00"},{id: 2,vip_level: "VIP1A",walletbal: "75.00"},{id: 3,vip_level: "VIP1A",walletbal: "75.00"},{id: 4,vip_level: "VIP1A",walletbal: "75.00"},{id: 5,vip_level: "VIP1A",walletbal: "75.00"},{id: 6,vip_level: "VIP1A",walletbal: "75.00"},{id: 7,vip_level: "VIP1A",walletbal: "75.00"},
// ];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const excelData = [
  {
    "Member": "2021-02-26 14:53:06",
    "Contact No.": "dclient02",
    "Email": 30.00,
    "VIP Level": 5.00,
    "Agent Code": 'Promotion',
    "Status": 2,
    "Pause Bet": 70.00,
    "Register Details": 1041.00,
    "Last Login Details": 1041.00,
    "Total Balance": 'cdemo005',
  },
  {
    "Member": "2021-02-26 14:53:06",
    "Contact No.": "dclient02",
    "Email": 30.00,
    "VIP Level": 5.00,
    "Agent Code": 'Promotion',
    "Status": 2,
    "Pause Bet": 70.00,
    "Register Details": 1041.00,
    "Last Login Details": 1041.00,
    "Total Balance": 'cdemo005',
  },
  {
    "Member": "2021-02-26 14:53:06",
    "Contact No.": "dclient02",
    "Email": 30.00,
    "VIP Level": 5.00,
    "Agent Code": 'Promotion',
    "Status": 2,
    "Pause Bet": 70.00,
    "Register Details": 1041.00,
    "Last Login Details": 1041.00,
    "Total Balance": 'cdemo005',
  },
  {
    "Member": "2021-02-26 14:53:06",
    "Contact No.": "dclient02",
    "Email": 30.00,
    "VIP Level": 5.00,
    "Agent Code": 'Promotion',
    "Status": 2,
    "Pause Bet": 70.00,
    "Register Details": 1041.00,
    "Last Login Details": 1041.00,
    "Total Balance": 'cdemo005',
  }
]
class MemberListing extends React.Component {
  constructor(props) {
    super(props);
	this.makeRequest = makeRequest
    this.currntOption = this.currntOption.bind(this)
    this.statusModal = this.statusModal.bind(this)
    this.search = this.search.bind(this)
    this.changeMemberName = this.changeMemberName.bind(this)
    this.changePhone = this.changePhone.bind(this)
    this.changeEmail = this.changeEmail.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
    this.changePauseBetStatus = this.changePauseBetStatus.bind(this)
    this.changeAccountCreated = this.changeAccountCreated.bind(this)
    this.changeLastLogin = this.changeLastLogin.bind(this)
    this.changeMemberGroup = this.changeMemberGroup.bind(this)
    this.printData = this.printData.bind(this)
    this.reset = this.reset.bind(this)
    this.exportData = this.exportData.bind(this)

    this.viewDeleteModal = this.viewDeleteModal.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.getUser = this.getUser.bind(this)
    this.viewUserStatusModal = this.viewUserStatusModal.bind(this)
    this.changeUserStatus = this.changeUserStatus.bind(this)
    this.updateUserStatus = this.updateUserStatus.bind(this)
    this.changeUserRole = this.changeUserRole.bind(this)
    this.changeAssignMemberGroup = this.changeAssignMemberGroup.bind(this)
    this.updateMemberGroup = this.updateMemberGroup.bind(this)
    this.updateUserRole = this.updateUserRole.bind(this)
  }
  state={
    status_type:'approve',
    showStatusModal:false,
	  member_details:[],
    search_member_name:'',
    search_phone:'',
    search_email:'',
    search_status:'',
    search_pause_bet:'',
    search_account_created:'',
    search_login_time:'',
    member_group_id:'',
    memberGroups:[],

    showDeleteModal:false,
    delGameId:'',
    submitDisabled1:false,
    error_msg1:'',
    success_msg1:'',
    error_msg2:'',
    success_msg2:'',
    error_msg3:'',
    success_msg3:'',
    error_msg4:'',
    success_msg4:'',
    userStatusId:'',
    roleId:'',
    assignId:'',
    showRoleModal:false,
    showMemberModal:false,
    showStatusModal:false,
    userStatus:'',
    userRole:'',
    assignMemberGroup:'',

  }
  statusModal(val){
    this.setState({showStatusModal: val})
  }
  currntOption(e){
    this.setState({status_type: e.target.value})
  }
  changeMemberName(e){
    this.setState({search_member_name: e.target.value})
  }
  changePhone(e){
    this.setState({search_phone: e.target.value})
  }
  changeEmail(e){
    this.setState({search_email: e.target.value})
  }
  changeStatus(e){
    this.setState({search_status: e.target.value})
  }
  changePauseBetStatus(e){
    this.setState({search_pause_bet: e.target.value})
  }
  changeAccountCreated(e){
    this.setState({search_account_created: e.target.value})
  }
  changeLastLogin(e){
    this.setState({search_login_time: e.target.value})
  }
  changeMemberGroup(e){
    this.setState({member_group_id: e.target.value})
  }
  changeUserStatus(e){
    this.setState({userStatus: e.target.value})
  }
  changeUserRole(e){
    this.setState({userRole: e.target.value})
  }
  changeAssignMemberGroup(e){
    this.setState({assignMemberGroup: e.target.value})
  }
  printData(){
    window.print();
  }
  exportData(){
    exportFromJSON({ data: excelData, fileName: 'Member listing', exportType: exportFromJSON.types.xls})
  }
  async search(){
      var search_data='user?role=user';
      if(this.state.search_member_name!==''){
        search_data=search_data+'&fullname='+this.state.search_member_name;
      }if(this.state.search_phone!==''){
        search_data=search_data+'&phone='+this.state.search_phone;
      }if(this.state.search_email!==''){
        search_data=search_data+'&email='+this.state.search_email;
      }if(this.state.search_status!==''){
        search_data=search_data+'&status='+this.state.search_status;
      }if(this.state.search_pause_bet!==''){
        search_data=search_data+'&betStatus='+this.state.search_pause_bet;
      }if(this.state.member_group_id!==''){
        search_data=search_data+'&memberGroupId='+this.state.member_group_id;
      }
      let result1=await this.makeRequest(search_data, 'get','',localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState(state => ({member_details:result1.data.data}));
      }
  }
  async reset(){
    this.setState(state => ({status_type: '',search_member_name: '',search_phone:'',search_email: '',search_status:'',search_pause_bet:'',member_group_id:''}));
    var result1=await this.makeRequest('user?role=user', 'get','',localStorage.getItem('role_token'));
    if(result1.status===200){
      this.setState({member_details: result1.data.data});
    }
  }
  viewDeleteModal(val,modal_value){
    this.setState({delGameId: val})
    this.setState({showDeleteModal: modal_value})
  }
  viewUserStatusModal(val,modal_value){
    this.setState({
      success_msg2:'',error_msg2:'',submitDisabled1:false
    })
    this.setState({userStatusId: val})
    this.setState({showStatusModal: modal_value})
  }
  viewAssignMemberModal(val,modal_value){
    this.setState({
      success_msg4:'',error_msg4:'',submitDisabled1:false
    })
    this.setState({assignId: val})
    this.setState({showMemberModal: modal_value})
  }
  viewUserRoleModal(val,modal_value){
    this.setState({
      success_msg3:'',error_msg3:'',submitDisabled1:false
    })
    this.setState({roleId: val})
    this.setState({showRoleModal: modal_value})
  }
  async deleteUser(){
    this.setState(state => ({submitDisabled1:true}));
    let result1=await this.makeRequest('user/'+this.state.delGameId, 'delete', '',localStorage.getItem('role_token'));
    
    if(result1.status===200){
      this.setState({
        success_msg1:'Member deleted successfully.',error_msg1:'',submitDisabled1:false
      })
      this.setState({showDeleteModal: false})
      this.getUser();
    }
    else if(result1.status===400){
      this.setState(state => ({error_msg1:result1.data.message,submitDisabled1:false}));
    }
    else if(result1.status===500)
      this.setState(state => ({error_msg1:'Some problem occured. Please try again.',submitDisabled1:false}));
   
  }
  async updateUserStatus(){
    if(this.state.userStatus===''){
      this.setState(state => ({error_msg2:'Please choose status.'}));
    }else{
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({status: this.state.userStatus});
      let result1=await this.makeRequest('user/'+this.state.userStatusId+'/status', 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg2:'Status updated successfully.',error_msg2:'',submitDisabled1:false
        })
        this.getUser();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg2:result1.data.message,submitDisabled1:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg2:'Some problem occured. Please try again.',submitDisabled1:false}));
    }
  }
  async updateMemberGroup(){
    if(this.state.assignMemberGroup===''){
      this.setState(state => ({error_msg4:'Please choose member group.'}));
    }else{
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({groupId: this.state.assignMemberGroup});
      let result1=await this.makeRequest('user/'+this.state.assignId+'/member-group', 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg4:'Member group assigned successfully.',error_msg4:'',submitDisabled1:false
        })
        this.getUser();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg4:result1.data.message,submitDisabled1:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg4:'Some problem occured. Please try again.',submitDisabled1:false}));
    }
  }
  async updateUserRole(){
    if(this.state.userRole===''){
      this.setState(state => ({error_msg3:'Please choose role.'}));
    }else{
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({role: this.state.userRole});
      let result1=await this.makeRequest('user/'+this.state.roleId+'/role', 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg3:'Status updated successfully.',error_msg3:'',submitDisabled1:false
        })
        this.getUser();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg3:result1.data.message,submitDisabled1:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg3:'Some problem occured. Please try again.',submitDisabled1:false}));
    }
  }
  async getUser(){
    var result1=await this.makeRequest('user?role=user', 'get','',localStorage.getItem('role_token'));
    if(result1.status===200){
      this.setState({member_details: result1.data.data});
    }
  }
  componentDidMount() {
	  this.getUser();
		(async () => {
		  var result1=await this.makeRequest('member-group', 'get','','');
		  if(result1.status===200){
			  this.setState({memberGroup: result1.data.data});
		  }
		})();
	} 
  render() {
	var data = this.state.member_details.map(function(item) {
		return {
			key: item.id,
			name:item.fullname,
			phone: item.phone,
			email: item.email,
			status: item.status,
			pause_bet: item.betStatus,
			amount: (JSON.parse(item.balance)).value,
			currency: (JSON.parse(item.balance)).currency,
			reg_details: timestampDate(item.createdAt),
			last_login: timestampDate(item.lastLogin),
			last_login_ip: item.lastLoginIp,
			agent_code: item.referralCode,
			memberGroup: item.memberGroup
		};
	});
    const columns = [
        {name: 'Member',cell: row => <div data-tag="allowRowEvents" style={{color: '#1890ff',cursor:'pointer'}} onClick={()=>this.props.history.push('/admin/member_details#'+row.key)}>{row.name}</div>,width:'84px'},
        {name: 'Contact No.',cell: row => <div data-tag="allowRowEvents" style={{color: '#1890ff'}}>{row.phone}</div>,},
        {name: 'Email',cell: row => <div data-tag="allowRowEvents" style={{color: '#1890ff'}}>{row.email}</div>,},
        {name: 'VIP Level',selector: 'vip_level',width:'84px',cell: row => <div data-tag="allowRowEvents">{row.memberGroup!==null ? (JSON.parse(row.memberGroup)).name:''}</div>},
        {name: 'Agent Code',selector: 'vip_level',cell: row => <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}}>{row.agent_code}</div>,width:'84px'},
        {name: 'Status',cell: row => <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}}><Dot color="#FFAC2A" size={28}/>{row.status}</div>,width:'84px'},
        {name: 'Pause Bet',cell: row => <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}}><Dot color="#FFAC2A" size={28}/>{row.pause_bet}</div>},
        {name: 'Register Details',cell: row => <div data-tag="allowRowEvents"><span>{row.reg_details!==null ? row.reg_details : ''}</span><br/><span>223.24.185.07</span><br/><span className="orngdiv">bo.jcmweb.com</span></div>,width:'148px'},
        {name: 'Last Login Details',cell: row => <div data-tag="allowRowEvents"><span>{row.last_login!==null ? row.last_login : ''}</span><br/><span>{row.last_login_ip}</span><br/><span className="prpldiv">WINDOW</span></div>,width:'148px'},
        {name: 'Total Balance',selector: 'total_balance',cell: row => <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}}>{row.currency+' '+(row.amount).toFixed(2)}</div>},
        {name: 'Action',selector: 'total_balance',cell: row => 
        <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}} className="table_action_icons">
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Update user's role" onClick={() => this.viewUserRoleModal(row.key,true)}><PersonFill color="#0a58ca" size={20}/></span>
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Assign member group for user" onClick={() => this.viewAssignMemberModal(row.key,true)}><ClipboardCheck color="#FFAC2A" size={18}/></span>
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Update user status" onClick={() => this.viewUserStatusModal(row.key,true)}><BookmarkFill color="purple" size={18}/></span>
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Delete user" onClick={() => this.viewDeleteModal(row.key,true)}><Trash color="red" size={18}/></span>
        </div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}} className="print_hide">
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Member management</span> / 
                    <span>Member Listing</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Member Listing</h5><br/>
                  <button className="btn btn-primary addbtn">+ Add Member</button>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              <div className="subbdvv1 print_hide" style={{padding: '22px 24px 0px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <ul className="tabbss">
                    <li className="active">Search Criteria</li>
                    <li>Search Product Member Name</li>
                </ul>
              </div>
              <div className="row print_hide member_listing" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">
                  <label className="labls"><span>Member Name:</span> <input type="text" className="form-control input_width_100" value={this.state.search_member_name} onChange={this.changeMemberName}/></label>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">  
                  <label className="labls"><span className="lswidth">Phone:</span> <input type="text" className="form-control input_width_100" value={this.state.search_phone} onChange={this.changePhone}/></label>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">  
                  <label className="labls"><span className="lswidth">Email:</span> <input type="text" className="form-control input_width_100" value={this.state.search_email} onChange={this.changeEmail}/></label>
                </div> 
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1"> 
                    <label className="labls"><span>Member Group:</span> 
                        <select className="form-control input_width_100" onChange={this.changeMemberGroup} value={this.state.member_group_id}>
                            <option value="">All</option>
                            {this.state.memberGroup && this.state.memberGroup.map(data => (
                              <option value={data.id} key={data.id}>{data.name}</option>
                            ))}
                        </select>
                    </label>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">   
                    <label className="labls"><span>Agent code:</span> <input type="text" className="form-control input_width_100"/></label>
                  </div> 
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">   
                    <label className="labls"><span className="lswidth">Status:</span> 
                        <select className="form-control input_width_100" value={this.state.search_status} onChange={this.changeStatus}>
                            <option value="">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="blocked">Blocked</option>
                        </select>
                    </label>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1"> 
                    <label className="labls"><span>Pause Bet:</span> 
                        <select className="form-control input_width_100"  value={this.state.search_pause_bet} onChange={this.changePauseBetStatus}>
                            <option value="">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="blocked">Blocked</option>
                        </select>
                    </label>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1"> 
                    <label className="labls"><span>Account created date:</span> 
                        <input type="date" className="form-control input_width_100" onChange={this.changeAccountCreated}/>
                    </label>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">   
                    <label className="labls"><span>Last Login Time:</span> 
                        <input type="date" className="form-control input_width_100" onChange={this.changeLastLogin}/>
                    </label>
                  </div>
                  <div>
                    <button className="btn btn-primary add_btn" onClick={this.search}>Search</button>
                    <button className="btn btn-primary add_btn cancel_btn" onClick={this.reset}>Reset</button>
                </div>
              </div><br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div style={{float:'right',marginBottom:'10px'}} className="print_hide">
                    <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                    <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                </div>
                {this.state.success_msg1!=='' ?
                  (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg1}</p>):('')
                }
                {this.state.error_msg1!=='' ?
                  (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg1}</p>):('')
                }
                <div id="divcontents">
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} id="print_area"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*delete modal*/}
        <Modal size="md" show={this.state.showDeleteModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewDeleteModal('',false)}>Delete member</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              <h4>Are you sure</h4>
              <p>You want to delete this member ?</p>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewDeleteModal('',false)} className="del_no_btn">No</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.deleteUser} disabled={this.state.submitDisabled}>Yes</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
        {/*status modal*/}
        <Modal size="md" show={this.state.showStatusModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewUserStatusModal('',false)}>Update status</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              {this.state.success_msg2!=='' ?
                (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg2}</p>):('')
              }
              {this.state.error_msg2!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg2}</p>):('')
              }
              <select className="form-control" onChange={this.changeUserStatus} value={this.state.userStatus}>
                  <option value="">Choose status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="blocked">Block</option>
              </select>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewUserStatusModal('',false)} className="del_no_btn">Cancel</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateUserStatus} disabled={this.state.submitDisabled}>Update</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
        {/*role modal*/}
        <Modal size="md" show={this.state.showRoleModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewUserRoleModal('',false)}>Update role</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              {this.state.success_msg3!=='' ?
                (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg3}</p>):('')
              }
              {this.state.error_msg3!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg3}</p>):('')
              }
              <select className="form-control" onChange={this.changeUserRole} value={this.state.userRole}>
                  <option value="">Choose role</option>
                  <option value="user">User</option>
                  <option value="senior">Senior</option>
                  <option value="master">Master</option>
                  <option value="agent">Agent</option>
              </select>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewUserRoleModal('',false)} className="del_no_btn">Cancel</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateUserRole} disabled={this.state.submitDisabled}>Update</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
        {/*member modal*/}
        <Modal size="md" show={this.state.showMemberModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewAssignMemberModal('',false)}>Assign member group</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
            {this.state.success_msg4!=='' ?
              (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg4}</p>):('')
            }
            {this.state.error_msg4!=='' ?
              (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg4}</p>):('')
            }
            <select className="form-control" onChange={this.changeAssignMemberGroup} value={this.state.assignMemberGroup}>
                <option value="">Choose member group</option>
                {this.state.memberGroup && this.state.memberGroup.map(data => (
                  <option value={data.id} key={data.id}>{data.name}</option>
                ))}
            </select>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewAssignMemberModal('',false)} className="del_no_btn">Cancel</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateMemberGroup} disabled={this.state.submitDisabled}>Assign</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
      </div>
    );
  }
}
export default MemberListing;