import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Trash,Dot } from 'react-bootstrap-icons';
import { makeProviderRequest } from '../../helpers';
// const data = [
//   {
//     id: 1,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
//   {
//     id: 2,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
//   {
//     id: 3,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
// ];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '14px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class DepositChannel extends React.Component {
  constructor(props) {
    super(props);
    this.makeProviderRequest = makeProviderRequest
    this.deleteChannel = this.deleteChannel.bind(this)
    this.updateStatus = this.updateStatus.bind(this)
    this.changeStatusFilter = this.changeStatusFilter.bind(this)
    this.changeTypeFilter = this.changeTypeFilter.bind(this)
    this.search = this.search.bind(this)
    this.clearSearchData = this.clearSearchData.bind(this)
  }
  state={
    channels:[],
    status:'',
    type:'',
  }
  changeStatusFilter(e) {
    this.setState({
      status: e.target.value,
    })
  }
  changeTypeFilter(e) {
    this.setState({
      type: e.target.value,
    })
  }
  clearSearchData(){
    this.setState({
      type: '',
    })
    this.setState({
      status: '',
    })
  }
  async search(){
    var result1;
      if((this.state.status!=='') && (this.state.type==='')){
        result1=await this.makeProviderRequest('deposit-channel?status='+this.state.status, 'get','',localStorage.getItem('role_token'));
      }else if((this.state.status==='') && (this.state.type!=='')){
        result1=await this.makeProviderRequest('deposit-channel?type='+this.state.type, 'get','',localStorage.getItem('role_token'));
      }else if((this.state.status!=='') && (this.state.type!=='')){
        result1=await this.makeProviderRequest('deposit-channel?status='+this.state.status+'&type='+this.state.type, 'get','',localStorage.getItem('role_token'));
      }
      if(result1.status===200){
        this.setState(state => ({channels:result1.data.data}));
      }
  }
  componentDidMount() {
    (async () => {
      var result1=await this.makeProviderRequest('deposit-channel', 'get','',localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState({channels: result1.data.data})
      }
    })();
	} 
    async updateStatus(e){
        var id=e.target.getAttribute("attr");
        let data = JSON.stringify({status: e.target.checked});
        var result1=await this.makeProviderRequest('deposit-channel/'+id+'/status', 'put', data,localStorage.getItem('role_token'));
        if(result1.status===200){
            this.setState({
            success_msg:result1.data.message,error_msg:''
            })
            this.componentDidMount();
        }
        else if(result1.status===400){
            this.setState(state => ({error_msg1:result1.data.message}));
        }
        else if(result1.status===500)
            this.setState(state => ({error_msg:'Some problem occured. Please try again.'}));
    }
    async deleteChannel(id){
        var result1=await this.makeProviderRequest('deposit-channel/'+id, 'delete','',localStorage.getItem('role_token'));
        if(result1.status===200){
            this.componentDidMount();
            this.setState({
            success_msg:result1.data.message,error_msg:''
            })
        }
        else if(result1.status===400){
            this.setState(state => ({error_msg2:result1.data.message}));
        }
        else if(result1.status===500){
            this.setState(state => ({error_msg2:'Some problem occured. Please try again.'}));
        }
    }
  render() {
    const columns = [
    {
      name: 'Bank deposit channel',width: '200px',selector: 'username',cell: row => <div data-tag="allowRowEvents">{row.channel_name}</div>,
    },
    {
      name: 'Currency',width: '180px',selector: 'fname',cell: row => <div data-tag="allowRowEvents">{row.currency}</div>,
    },
    {
      name: 'Type',width: '180px',selector: 'status',cell: row => <div data-tag="allowRowEvents"><Dot color="#53D816" size={30}/>{row.type}</div>,
    },
    {
      name: 'Name',width: '160px',selector: 'time_ip',cell: row => <div data-tag="allowRowEvents" style={{height: '40px',whiteSpace: 'initial'}}>{row.bank_name}</div>,
    },
    {
      name: 'Close/Open',width: '100px',selector: 'action',cell: row => <div data-tag="allowRowEvents"><span>
      <label className="switch" style={{marginRight:"16px"}}>
        <input type="checkbox" defaultChecked={row.status===true ? true : false} attr={row.key} onClick={this.updateStatus}/>
        <span className="slider round"></span>
      </label>
    </span></div>,right:true
    },
    {
        name: 'Action',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{cursor:"pointer"}}>
            <span onClick={() => this.deleteChannel(row.key)} style={{color:"rgb(24, 144, 255)"}} onClick={()=>this.props.history.push('/admin/deposit_channel_details#'+row.key)}>View | </span>
            <span onClick={()=>this.props.history.push('/admin/edit_deposit_channel#'+row.key+'#'+row.type)} style={{color:"rgb(24, 144, 255)"}}>Edit | </span>
            <span onClick={() => this.deleteChannel(row.key)} style={{color:"rgb(24, 144, 255)"}}>Delete</span>
        </div>    
    },
  ];
  var data = this.state.channels.map(function(item) {
    return {
      key: item.id,
      channel_name:item.channelName,
      bank_name: item.bankName,
      currency: item.currency,
      status: item.status,
      type: item.type,
      success_msg:'',
      error_msg:'',
    };
  });
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0,height:"100vh"}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>Deposit Channel</span>
                  </div>
                  <h5 className="pg_head">Deposit Channel</h5>
                  {/*<button className="btn btn-primary addbtn" onClick={()=>this.props.history.push('/admin/add_deposit_channel')}>+ Add deposit channel</button>*/}
                </div>
              </div><br/>
            </div>

            <div className="divbody">
              <div className="subbdvv1">
                <div className="subdvv1_1">
                    <span>Status: </span>
                    <select className="form-control" onChange={this.changeStatusFilter} value={this.state.status}>
                        <option value="">All</option>
                        <option value="on">On</option>
                        <option value="off">Off</option>
                    </select>
                    <span>Type: </span>
                    <select className="form-control" onChange={this.changeTypeFilter} value={this.state.type}>
                        <option value="">All</option>
                        <option value="bank">Bank</option>
                        <option value="wire">Wire</option>
                    </select>
                  <button className="btn btn-primary filter_btn text-white" style={{background: '#1890FF'}}onClick={this.search}>Search</button>
                  <button className="btn btn-primary filter_btn text-white" style={{background: '#BFBFBF'}} onClick={this.clearSearchData}>Clear</button>
                </div>
              </div>
            </div>
            
            <div className="divbody">
              <div className="subbdvv1">
                {this.state.success_msg!=='' ?
                    (<p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>):('')
                }
                {this.state.error_msg!=='' ?
                    (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.error_msg}</p>):('')
                }
                <div className="subdvv1_2">
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DepositChannel;
