const COUNTRIES = [{
  "c": "中国大陆",
  "e": "China",
  "k": "CN",
  "n": "86",
  "o": "中国大陆",
  "t": "CHN"
}, {
  "c": "香港 (中国)",
  "e": "Hong Kong (China)",
  "k": "HK",
  "n": "852",
  "o": "香港",
  "t": "HKG"
}, {
  "c": "澳门 (中国)",
  "e": "Macau (China)",
  "k": "MO",
  "n": "853",
  "o": "澳門",
  "t": "MAC"
}, {
  "c": "台湾 (中国)",
  "e": "Taiwan (China)",
  "k": "TW",
  "n": "886",
  "o": "台灣",
  "t": "TWN"
}, {
  "c": "美国",
  "e": "United States",
  "k": "US",
  "n": "1",
  "o": "United States",
  "t": "USA"
}, {
  "c": "加拿大",
  "e": "Canada",
  "k": "CA",
  "n": "1",
  "o": "Canada",
  "t": "CAN"
}, {
  "c": "英国",
  "e": "United Kingdom",
  "k": "GB",
  "n": "44",
  "o": "United Kingdom",
  "t": "GBR"
}, {
  "c": "俄罗斯",
  "e": "Russia",
  "k": "RU",
  "n": "7",
  "o": "Россия",
  "t": "RUS"
}, {
  "c": "哈萨克斯坦",
  "e": "Kazakhstan",
  "k": "KZ",
  "n": "7",
  "o": "Казахстан",
  "t": "KAZ"
}, {
  "c": "阿富汗",
  "e": "Afghanistan",
  "k": "AF",
  "n": "93",
  "o": "‫افغانستان‬‎",
  "t": "AFG"
}, {
  "c": "奥兰群岛",
  "e": "Åland Islands",
  "k": "AX",
  "n": "358",
  "o": "Åland",
  "u": 1,
  "t": "ALA"
}, {
  "c": "阿尔巴尼亚",
  "e": "Albania",
  "k": "AL",
  "n": "355",
  "o": "Shqipëri",
  "t": "ALB"
}, {
  "c": "阿尔及利亚",
  "e": "Algeria",
  "k": "DZ",
  "n": "213",
  "o": "Algeria",
  "t": "DZA"
}, {
  "c": "美属萨摩亚",
  "e": "American Samoa",
  "k": "AS",
  "n": "1684",
  "o": "American Samoa",
  "t": "ASM"
}, {
  "c": "安道尔",
  "e": "Andorra",
  "k": "AD",
  "n": "376",
  "o": "Andorra",
  "t": "AND"
}, {
  "c": "安哥拉",
  "e": "Angola",
  "k": "AO",
  "n": "244",
  "o": "Angola",
  "t": "AGO"
}, {
  "c": "安圭拉",
  "e": "Anguilla",
  "k": "AI",
  "n": "1264",
  "o": "Anguilla",
  "t": "AIA"
}, {
  "c": "安提瓜和巴布达",
  "e": "Antigua & Barbuda",
  "k": "AG",
  "n": "1268",
  "o": "Antigua & Barbuda",
  "t": "ATG"
}, {
  "c": "阿根廷",
  "e": "Argentina",
  "k": "AR",
  "n": "54",
  "o": "Argentina",
  "t": "ARG"
}, {
  "c": "亚美尼亚",
  "e": "Armenia",
  "k": "AM",
  "n": "374",
  "o": "Հայաստան",
  "t": "ARM"
}, {
  "c": "阿鲁巴",
  "e": "Aruba",
  "k": "AW",
  "n": "297",
  "o": "Aruba",
  "t": "ABW"
}, {
  "c": "澳大利亚",
  "e": "Australia",
  "k": "AU",
  "n": "61",
  "o": "Australia",
  "t": "AUS"
}, {
  "c": "奥地利",
  "e": "Austria",
  "k": "AT",
  "n": "43",
  "o": "Österreich",
  "t": "AUT"
}, {
  "c": "阿塞拜疆",
  "e": "Azerbaijan",
  "k": "AZ",
  "n": "994",
  "o": "Azərbaycan",
  "t": "AZE"
}, {
  "c": "巴哈马",
  "e": "Bahamas",
  "k": "BS",
  "n": "1242",
  "o": "Bahamas",
  "t": "BHS"
}, {
  "c": "巴林",
  "e": "Bahrain",
  "k": "BH",
  "n": "973",
  "o": "‫البحرين‬‎",
  "t": "BHR"
}, {
  "c": "孟加拉国",
  "e": "Bangladesh",
  "k": "BD",
  "n": "880",
  "o": "বাংলাদেশ",
  "t": "BGD"
}, {
  "c": "巴巴多斯",
  "e": "Barbados",
  "k": "BB",
  "n": "1246",
  "o": "Barbados",
  "t": "BRB"
}, {
  "c": "白俄罗斯",
  "e": "Belarus",
  "k": "BY",
  "n": "375",
  "o": "Беларусь",
  "t": "BLR"
}, {
  "c": "比利时",
  "e": "Belgium",
  "k": "BE",
  "n": "32",
  "o": "Belgium",
  "t": "BEL"
}, {
  "c": "伯利兹",
  "e": "Belize",
  "k": "BZ",
  "n": "501",
  "o": "Belize",
  "t": "BLZ"
}, {
  "c": "贝宁",
  "e": "Benin",
  "k": "BJ",
  "n": "229",
  "o": "Bénin",
  "t": "BEN"
}, {
  "c": "百慕大",
  "e": "Bermuda",
  "k": "BM",
  "n": "1441",
  "o": "Bermuda",
  "t": "BMU"
}, {
  "c": "不丹",
  "e": "Bhutan",
  "k": "BT",
  "n": "975",
  "o": "འབྲུག",
  "t": "BTN"
}, {
  "c": "玻利维亚",
  "e": "Bolivia",
  "k": "BO",
  "n": "591",
  "o": "Bolivia",
  "t": "BOL"
}, {
  "c": "波斯尼亚",
  "e": "Bosnia",
  "k": "BA",
  "n": "387",
  "o": "Bosna i Hercegovina",
  "t": "BIH"
}, {
  "c": "博茨瓦纳",
  "e": "Botswana",
  "k": "BW",
  "n": "267",
  "o": "Botswana",
  "t": "BWA"
}, {
  "c": "布韦岛",
  "e": "Bouvet Island",
  "k": "BV",
  "n": "55",
  "o": "Bouvet Island",
  "u": 1,
  "t": "BVT"
}, {
  "c": "巴西",
  "e": "Brazil",
  "k": "BR",
  "n": "55",
  "o": "Brasil",
  "t": "BRA"
}, {
  "c": "英属印度洋领地",
  "e": "British Indian Ocean",
  "k": "IO",
  "n": "246",
  "o": "British Indian Ocean",
  "t": "IOT"
}, {
  "c": "英属维尔京群岛",
  "e": "British Virgin Islands",
  "k": "VG",
  "n": "1284",
  "o": "British Virgin Islands",
  "t": "VGB"
}, {
  "c": "文莱",
  "e": "Brunei",
  "k": "BN",
  "n": "673",
  "o": "Brunei",
  "t": "BRN"
}, {
  "c": "保加利亚",
  "e": "Bulgaria",
  "k": "BG",
  "n": "359",
  "o": "България",
  "t": "BGR"
}, {
  "c": "布基纳法索",
  "e": "Burkina Faso",
  "k": "BF",
  "n": "226",
  "o": "Burkina Faso",
  "t": "BFA"
}, {
  "c": "布隆迪",
  "e": "Burundi",
  "k": "BI",
  "n": "257",
  "o": "Uburundi",
  "t": "BDI"
}, {
  "c": "柬埔寨",
  "e": "Cambodia",
  "k": "KH",
  "n": "855",
  "o": "កម្ពុជា",
  "t": "KHM"
}, {
  "c": "喀麦隆",
  "e": "Cameroon",
  "k": "CM",
  "n": "237",
  "o": "Cameroun",
  "t": "CMR"
}, {
  "c": "佛得角",
  "e": "Cape Verde",
  "k": "CV",
  "n": "238",
  "o": "Kabu Verdi",
  "t": "CPV"
}, {
  "c": "荷属加勒比区",
  "e": "Caribbean Netherlands",
  "k": "BQ",
  "n": "599",
  "o": "Caribbean Netherlands",
  "t": "BES"
}, {
  "c": "开曼群岛",
  "e": "Cayman Islands",
  "k": "KY",
  "n": "1345",
  "o": "Cayman Islands",
  "t": "CYM"
}, {
  "c": "中非共和国",
  "e": "Central African Republic",
  "k": "CF",
  "n": "236",
  "o": "République centrafricaine",
  "t": "CAF"
}, {
  "c": "乍得",
  "e": "Chad",
  "k": "TD",
  "n": "235",
  "o": "Tchad",
  "t": "TCD"
}, {
  "c": "智利",
  "e": "Chile",
  "k": "CL",
  "n": "56",
  "o": "Chile",
  "t": "CHL"
}, {
  "c": "圣诞岛",
  "e": "Christmas Island",
  "k": "CX",
  "n": "61",
  "o": "Christmas Island",
  "u": 1,
  "t": "CXR"
}, {
  "c": "科科斯（基林）群岛",
  "e": "Cocos (Keeling) Islands",
  "k": "CC",
  "n": "61",
  "o": "Kepulauan Cocos (Keeling)",
  "u": 1,
  "t": "CCK"
}, {
  "c": "哥伦比亚",
  "e": "Colombia",
  "k": "CO",
  "n": "57",
  "o": "Colombia",
  "t": "COL"
}, {
  "c": "科摩罗",
  "e": "Comoros",
  "k": "KM",
  "n": "269",
  "o": "‫جزر القمر‬‎",
  "t": "COM"
}, {
  "c": "刚果（布）",
  "e": "Congo - Brazzaville",
  "k": "CG",
  "n": "242",
  "o": "Congo-Brazzaville",
  "t": "COG"
}, {
  "c": "刚果（金）",
  "e": "Congo - Kinshasa",
  "k": "CD",
  "n": "243",
  "o": "Jamhuri ya Kidemokrasia ya Kongo",
  "t": "COD"
}, {
  "c": "库克群岛",
  "e": "Cook Islands",
  "k": "CK",
  "n": "682",
  "o": "Cook Islands",
  "t": "COK"
}, {
  "c": "哥斯达黎加",
  "e": "Costa Rica",
  "k": "CR",
  "n": "506",
  "o": "Costa Rica",
  "t": "CRI"
}, {
  "c": "克罗地亚",
  "e": "Croatia",
  "k": "HR",
  "n": "385",
  "o": "Hrvatska",
  "t": "HRV"
}, {
  "c": "古巴",
  "e": "Cuba",
  "k": "CU",
  "n": "53",
  "o": "Cuba",
  "t": "CUB"
}, {
  "c": "库拉索",
  "e": "Curaçao",
  "k": "CW",
  "n": "599",
  "o": "Curaçao",
  "t": "CUW"
}, {
  "c": "塞浦路斯",
  "e": "Cyprus",
  "k": "CY",
  "n": "357",
  "o": "Κύπρος",
  "t": "CYP"
}, {
  "c": "捷克",
  "e": "Czechia",
  "k": "CZ",
  "n": "420",
  "o": "Česko",
  "t": "CZE"
}, {
  "c": "科特迪瓦",
  "e": "Côte d’Ivoire",
  "k": "CI",
  "n": "225",
  "o": "Côte d’Ivoire",
  "t": "CIV"
}, {
  "c": "丹麦",
  "e": "Denmark",
  "k": "DK",
  "n": "45",
  "o": "Danmark",
  "t": "DNK"
}, {
  "c": "吉布提",
  "e": "Djibouti",
  "k": "DJ",
  "n": "253",
  "o": "Djibouti",
  "t": "DJI"
}, {
  "c": "多米尼克",
  "e": "Dominica",
  "k": "DM",
  "n": "1767",
  "o": "Dominica",
  "t": "DMA"
}, {
  "c": "多米尼加共和国",
  "e": "Dominican Republic",
  "k": "DO",
  "n": "1809",
  "o": "República Dominicana",
  "t": "DOM"
}, {
  "c": "厄瓜多尔",
  "e": "Ecuador",
  "k": "EC",
  "n": "593",
  "o": "Ecuador",
  "t": "ECU"
}, {
  "c": "埃及",
  "e": "Egypt",
  "k": "EG",
  "n": "20",
  "o": "‫مصر‬‎",
  "t": "EGY"
}, {
  "c": "萨尔瓦多",
  "e": "El Salvador",
  "k": "SV",
  "n": "503",
  "o": "El Salvador",
  "t": "SLV"
}, {
  "c": "赤道几内亚",
  "e": "Equatorial Guinea",
  "k": "GQ",
  "n": "240",
  "o": "Guinea Ecuatorial",
  "t": "GNQ"
}, {
  "c": "厄立特里亚",
  "e": "Eritrea",
  "k": "ER",
  "n": "291",
  "o": "ኤርትራ",
  "t": "ERI"
}, {
  "c": "爱沙尼亚",
  "e": "Estonia",
  "k": "EE",
  "n": "372",
  "o": "Eesti",
  "t": "EST"
}, {
  "c": "埃塞俄比亚",
  "e": "Ethiopia",
  "k": "ET",
  "n": "251",
  "o": "Ethiopia",
  "t": "ETH"
}, {
  "c": "福克兰群岛（马尔维纳斯群岛）",
  "e": "Falkland Islands",
  "k": "FK",
  "n": "500",
  "o": "Falkland Islands (Islas Malvinas)",
  "t": "FLK"
}, {
  "c": "法罗群岛",
  "e": "Faroe Islands",
  "k": "FO",
  "n": "298",
  "o": "Føroyar",
  "t": "FRO"
}, {
  "c": "斐济",
  "e": "Fiji",
  "k": "FJ",
  "n": "679",
  "o": "Fiji",
  "t": "FJI"
}, {
  "c": "芬兰",
  "e": "Finland",
  "k": "FI",
  "n": "358",
  "o": "Suomi",
  "t": "FIN"
}, {
  "c": "法国",
  "e": "France",
  "k": "FR",
  "n": "33",
  "o": "France",
  "t": "FRA"
}, {
  "c": "法属圭亚那",
  "e": "French Guiana",
  "k": "GF",
  "n": "594",
  "o": "Guyane française",
  "t": "GUF"
}, {
  "c": "法属波利尼西亚",
  "e": "French Polynesia",
  "k": "PF",
  "n": "689",
  "o": "Polynésie française",
  "t": "PYF"
}, {
  "c": "法属南部领地",
  "e": "French Southern Territories",
  "k": "TF",
  "n": "262",
  "o": "Terres australes françaises",
  "u": 1,
  "t": "ATF"
}, {
  "c": "加蓬",
  "e": "Gabon",
  "k": "GA",
  "n": "241",
  "o": "Gabon",
  "t": "GAB"
}, {
  "c": "冈比亚",
  "e": "Gambia",
  "k": "GM",
  "n": "220",
  "o": "Gambia",
  "t": "GMB"
}, {
  "c": "格鲁吉亚",
  "e": "Georgia",
  "k": "GE",
  "n": "995",
  "o": "საქართველო",
  "t": "GEO"
}, {
  "c": "德国",
  "e": "Germany",
  "k": "DE",
  "n": "49",
  "o": "Deutschland",
  "t": "DEU"
}, {
  "c": "加纳",
  "e": "Ghana",
  "k": "GH",
  "n": "233",
  "o": "Gaana",
  "t": "GHA"
}, {
  "c": "直布罗陀",
  "e": "Gibraltar",
  "k": "GI",
  "n": "350",
  "o": "Gibraltar",
  "t": "GIB"
}, {
  "c": "希腊",
  "e": "Greece",
  "k": "GR",
  "n": "30",
  "o": "Ελλάδα",
  "t": "GRC"
}, {
  "c": "格陵兰",
  "e": "Greenland",
  "k": "GL",
  "n": "299",
  "o": "Kalaallit Nunaat",
  "t": "GRL"
}, {
  "c": "格林纳达",
  "e": "Grenada",
  "k": "GD",
  "n": "1473",
  "o": "Grenada",
  "t": "GRD"
}, {
  "c": "瓜德罗普",
  "e": "Guadeloupe",
  "k": "GP",
  "n": "590",
  "o": "Guadeloupe",
  "t": "GLP"
}, {
  "c": "关岛",
  "e": "Guam",
  "k": "GU",
  "n": "1671",
  "o": "Guam",
  "t": "GUM"
}, {
  "c": "危地马拉",
  "e": "Guatemala",
  "k": "GT",
  "n": "502",
  "o": "Guatemala",
  "t": "GTM"
}, {
  "c": "根西岛",
  "e": "Guernsey",
  "k": "GG",
  "n": "44",
  "o": "Guernsey",
  "u": 1,
  "t": "GGY"
}, {
  "c": "几内亚",
  "e": "Guinea",
  "k": "GN",
  "n": "224",
  "o": "Guinée",
  "t": "GIN"
}, {
  "c": "几内亚比绍",
  "e": "Guinea-Bissau",
  "k": "GW",
  "n": "245",
  "o": "Guiné-Bissau",
  "t": "GNB"
}, {
  "c": "圭亚那",
  "e": "Guyana",
  "k": "GY",
  "n": "592",
  "o": "Guyana",
  "t": "GUY"
}, {
  "c": "海地",
  "e": "Haiti",
  "k": "HT",
  "n": "509",
  "o": "Haiti",
  "t": "HTI"
}, {
  "c": "洪都拉斯",
  "e": "Honduras",
  "k": "HN",
  "n": "504",
  "o": "Honduras",
  "t": "HND"
}, {
  "c": "匈牙利",
  "e": "Hungary",
  "k": "HU",
  "n": "36",
  "o": "Magyarország",
  "t": "HUN"
}, {
  "c": "冰岛",
  "e": "Iceland",
  "k": "IS",
  "n": "354",
  "o": "Ísland",
  "t": "ISL"
}, {
  "c": "印度",
  "e": "India",
  "k": "IN",
  "n": "91",
  "o": "भारत",
  "t": "IND"
}, {
  "c": "印度尼西亚",
  "e": "Indonesia",
  "k": "ID",
  "n": "62",
  "o": "Indonesia",
  "t": "IDN"
}, {
  "c": "伊朗",
  "e": "Iran",
  "k": "IR",
  "n": "98",
  "o": "‫ایران‬‎",
  "t": "IRN"
}, {
  "c": "伊拉克",
  "e": "Iraq",
  "k": "IQ",
  "n": "964",
  "o": "‫العراق‬‎",
  "t": "IRQ"
}, {
  "c": "爱尔兰",
  "e": "Ireland",
  "k": "IE",
  "n": "353",
  "o": "Ireland",
  "t": "IRL"
}, {
  "c": "马恩岛",
  "e": "Isle of Man",
  "k": "IM",
  "n": "44",
  "o": "Isle of Man",
  "u": 1,
  "t": "IMN"
}, {
  "c": "以色列",
  "e": "Israel",
  "k": "IL",
  "n": "972",
  "o": "‫ישראל‬‎",
  "t": "ISR"
}, {
  "c": "意大利",
  "e": "Italy",
  "k": "IT",
  "n": "39",
  "o": "Italia",
  "t": "ITA"
}, {
  "c": "牙买加",
  "e": "Jamaica",
  "k": "JM",
  "n": "1876",
  "o": "Jamaica",
  "t": "JAM"
}, {
  "c": "日本",
  "e": "Japan",
  "k": "JP",
  "n": "81",
  "o": "日本",
  "t": "JPN"
}, {
  "c": "泽西岛",
  "e": "Jersey",
  "k": "JE",
  "n": "44",
  "o": "Jersey",
  "u": 1,
  "t": "JEY"
}, {
  "c": "约旦",
  "e": "Jordan",
  "k": "JO",
  "n": "962",
  "o": "‫الأردن‬‎",
  "t": "JOR"
}, {
  "c": "肯尼亚",
  "e": "Kenya",
  "k": "KE",
  "n": "254",
  "o": "Kenya",
  "t": "KEN"
}, {
  "c": "基里巴斯",
  "e": "Kiribati",
  "k": "KI",
  "n": "686",
  "o": "Kiribati",
  "t": "KIR"
}, {
  "c": "科威特",
  "e": "Kuwait",
  "k": "KW",
  "n": "965",
  "o": "‫الكويت‬‎",
  "t": "KWT"
}, {
  "c": "吉尔吉斯斯坦",
  "e": "Kyrgyzstan",
  "k": "KG",
  "n": "996",
  "o": "Кыргызстан",
  "t": "KGZ"
}, {
  "c": "老挝",
  "e": "Laos",
  "k": "LA",
  "n": "856",
  "o": "ລາວ",
  "t": "LAO"
}, {
  "c": "拉脱维亚",
  "e": "Latvia",
  "k": "LV",
  "n": "371",
  "o": "Latvija",
  "t": "LVA"
}, {
  "c": "黎巴嫩",
  "e": "Lebanon",
  "k": "LB",
  "n": "961",
  "o": "‫لبنان‬‎",
  "t": "LBN"
}, {
  "c": "莱索托",
  "e": "Lesotho",
  "k": "LS",
  "n": "266",
  "o": "Lesotho",
  "t": "LSO"
}, {
  "c": "利比里亚",
  "e": "Liberia",
  "k": "LR",
  "n": "231",
  "o": "Liberia",
  "t": "LBR"
}, {
  "c": "利比亚",
  "e": "Libya",
  "k": "LY",
  "n": "218",
  "o": "‫ليبيا‬‎",
  "t": "LBY"
}, {
  "c": "列支敦士登",
  "e": "Liechtenstein",
  "k": "LI",
  "n": "423",
  "o": "Liechtenstein",
  "t": "LIE"
}, {
  "c": "立陶宛",
  "e": "Lithuania",
  "k": "LT",
  "n": "370",
  "o": "Lietuva",
  "t": "LTU"
}, {
  "c": "卢森堡",
  "e": "Luxembourg",
  "k": "LU",
  "n": "352",
  "o": "Luxembourg",
  "t": "LUX"
}, {
  "c": "马其顿",
  "e": "Macedonia (FYROM)",
  "k": "MK",
  "n": "389",
  "o": "Република Македонија",
  "t": "MKD"
}, {
  "c": "马达加斯加",
  "e": "Madagascar",
  "k": "MG",
  "n": "261",
  "o": "Madagasikara",
  "t": "MDG"
}, {
  "c": "马拉维",
  "e": "Malawi",
  "k": "MW",
  "n": "265",
  "o": "Malawi",
  "t": "MWI"
}, {
  "c": "马来西亚",
  "e": "Malaysia",
  "k": "MY",
  "n": "60",
  "o": "Malaysia",
  "t": "MYS"
}, {
  "c": "马尔代夫",
  "e": "Maldives",
  "k": "MV",
  "n": "960",
  "o": "Maldives",
  "t": "MDV"
}, {
  "c": "马里",
  "e": "Mali",
  "k": "ML",
  "n": "223",
  "o": "Mali",
  "t": "MLI"
}, {
  "c": "马耳他",
  "e": "Malta",
  "k": "MT",
  "n": "356",
  "o": "Malta",
  "t": "MLT"
}, {
  "c": "马绍尔群岛",
  "e": "Marshall Islands",
  "k": "MH",
  "n": "692",
  "o": "Marshall Islands",
  "t": "MHL"
}, {
  "c": "马提尼克",
  "e": "Martinique",
  "k": "MQ",
  "n": "596",
  "o": "Martinique",
  "t": "MTQ"
}, {
  "c": "毛里塔尼亚",
  "e": "Mauritania",
  "k": "MR",
  "n": "222",
  "o": "‫موريتانيا‬‎",
  "t": "MRT"
}, {
  "c": "毛里求斯",
  "e": "Mauritius",
  "k": "MU",
  "n": "230",
  "o": "Moris",
  "t": "MUS"
}, {
  "c": "马约特",
  "e": "Mayotte",
  "k": "YT",
  "n": "262",
  "o": "Mayotte",
  "u": 1,
  "t": "MYT"
}, {
  "c": "墨西哥",
  "e": "Mexico",
  "k": "MX",
  "n": "52",
  "o": "México",
  "t": "MEX"
}, {
  "c": "密克罗尼西亚",
  "e": "Micronesia",
  "k": "FM",
  "n": "691",
  "o": "Micronesia",
  "t": "FSM"
}, {
  "c": "摩尔多瓦",
  "e": "Moldova",
  "k": "MD",
  "n": "373",
  "o": "Republica Moldova",
  "t": "MDA"
}, {
  "c": "摩纳哥",
  "e": "Monaco",
  "k": "MC",
  "n": "377",
  "o": "Monaco",
  "t": "MCO"
}, {
  "c": "蒙古",
  "e": "Mongolia",
  "k": "MN",
  "n": "976",
  "o": "Монгол",
  "t": "MNG"
}, {
  "c": "黑山",
  "e": "Montenegro",
  "k": "ME",
  "n": "382",
  "o": "Crna Gora",
  "t": "MNE"
}, {
  "c": "蒙特塞拉特",
  "e": "Montserrat",
  "k": "MS",
  "n": "1664",
  "o": "Montserrat",
  "t": "MSR"
}, {
  "c": "摩洛哥",
  "e": "Morocco",
  "k": "MA",
  "n": "212",
  "o": "Morocco",
  "t": "MAR"
}, {
  "c": "莫桑比克",
  "e": "Mozambique",
  "k": "MZ",
  "n": "258",
  "o": "Moçambique",
  "t": "MOZ"
}, {
  "c": "缅甸",
  "e": "Myanmar (Burma)",
  "k": "MM",
  "n": "95",
  "o": "မြန်မာ",
  "t": "MMR"
}, {
  "c": "纳米比亚",
  "e": "Namibia",
  "k": "NA",
  "n": "264",
  "o": "Namibië",
  "t": "NAM"
}, {
  "c": "瑙鲁",
  "e": "Nauru",
  "k": "NR",
  "n": "674",
  "o": "Nauru",
  "t": "NRU"
}, {
  "c": "尼泊尔",
  "e": "Nepal",
  "k": "NP",
  "n": "977",
  "o": "नेपाल",
  "t": "NPL"
}, {
  "c": "荷兰",
  "e": "Netherlands",
  "k": "NL",
  "n": "31",
  "o": "Nederland",
  "t": "NLD"
}, {
  "c": "新喀里多尼亚",
  "e": "New Caledonia",
  "k": "NC",
  "n": "687",
  "o": "Nouvelle-Calédonie",
  "t": "NCL"
}, {
  "c": "新西兰",
  "e": "New Zealand",
  "k": "NZ",
  "n": "64",
  "o": "New Zealand",
  "t": "NZL"
}, {
  "c": "尼加拉瓜",
  "e": "Nicaragua",
  "k": "NI",
  "n": "505",
  "o": "Nicaragua",
  "t": "NIC"
}, {
  "c": "尼日尔",
  "e": "Niger",
  "k": "NE",
  "n": "227",
  "o": "Nijar",
  "t": "NER"
}, {
  "c": "尼日利亚",
  "e": "Nigeria",
  "k": "NG",
  "n": "234",
  "o": "Nigeria",
  "t": "NGA"
}, {
  "c": "纽埃",
  "e": "Niue",
  "k": "NU",
  "n": "683",
  "o": "Niue",
  "t": "NIU"
}, {
  "c": "诺福克岛",
  "e": "Norfolk Island",
  "k": "NF",
  "n": "672",
  "o": "Norfolk Island",
  "t": "NFK"
}, {
  "c": "朝鲜",
  "e": "North Korea",
  "k": "KP",
  "n": "850",
  "o": "북한",
  "t": "PRK"
}, {
  "c": "北马里亚纳群岛",
  "e": "Northern Mariana Islands",
  "k": "MP",
  "n": "1",
  "o": "Northern Mariana Islands",
  "t": "MNP"
}, {
  "c": "挪威",
  "e": "Norway",
  "k": "NO",
  "n": "47",
  "o": "Norge",
  "t": "NOR"
}, {
  "c": "阿曼",
  "e": "Oman",
  "k": "OM",
  "n": "968",
  "o": "‫عُمان‬‎",
  "t": "OMN"
}, {
  "c": "巴基斯坦",
  "e": "Pakistan",
  "k": "PK",
  "n": "92",
  "o": "‫پاکستان‬‎",
  "t": "PAK"
}, {
  "c": "帕劳",
  "e": "Palau",
  "k": "PW",
  "n": "680",
  "o": "Palau",
  "t": "PLW"
}, {
  "c": "巴勒斯坦",
  "e": "Palestine",
  "k": "PS",
  "n": "970",
  "o": "‫فلسطين‬‎",
  "t": "PSE"
}, {
  "c": "巴拿马",
  "e": "Panama",
  "k": "PA",
  "n": "507",
  "o": "Panamá",
  "t": "PAN"
}, {
  "c": "巴布亚新几内亚",
  "e": "Papua New Guinea",
  "k": "PG",
  "n": "675",
  "o": "Papua New Guinea",
  "t": "PNG"
}, {
  "c": "巴拉圭",
  "e": "Paraguay",
  "k": "PY",
  "n": "595",
  "o": "Paraguay",
  "t": "PRY"
}, {
  "c": "秘鲁",
  "e": "Peru",
  "k": "PE",
  "n": "51",
  "o": "Perú",
  "t": "PER"
}, {
  "c": "菲律宾",
  "e": "Philippines",
  "k": "PH",
  "n": "63",
  "o": "Philippines",
  "t": "PHL"
}, {
  "c": "皮特凯恩群岛",
  "e": "Pitcairn Islands",
  "k": "PN",
  "n": "64",
  "o": "Pitcairn Islands",
  "u": 1,
  "t": "PCN"
}, {
  "c": "波兰",
  "e": "Poland",
  "k": "PL",
  "n": "48",
  "o": "Polska",
  "t": "POL"
}, {
  "c": "葡萄牙",
  "e": "Portugal",
  "k": "PT",
  "n": "351",
  "o": "Portugal",
  "t": "PRT"
}, {
  "c": "波多黎各",
  "e": "Puerto Rico",
  "k": "PR",
  "n": "1787",
  "o": "Puerto Rico",
  "t": "PRI"
}, {
  "c": "卡塔尔",
  "e": "Qatar",
  "k": "QA",
  "n": "974",
  "o": "‫قطر‬‎",
  "t": "QAT"
}, {
  "c": "罗马尼亚",
  "e": "Romania",
  "k": "RO",
  "n": "40",
  "o": "România",
  "t": "ROU"
}, {
  "c": "卢旺达",
  "e": "Rwanda",
  "k": "RW",
  "n": "250",
  "o": "U Rwanda",
  "t": "RWA"
}, {
  "c": "留尼汪",
  "e": "Réunion",
  "k": "RE",
  "n": "262",
  "o": "La Réunion",
  "t": "REU"
}, {
  "c": "萨摩亚",
  "e": "Samoa",
  "k": "WS",
  "n": "685",
  "o": "Samoa",
  "t": "WSM"
}, {
  "c": "圣马力诺",
  "e": "San Marino",
  "k": "SM",
  "n": "378",
  "o": "San Marino",
  "t": "SMR"
}, {
  "c": "沙特阿拉伯",
  "e": "Saudi Arabia",
  "k": "SA",
  "n": "966",
  "o": "‫المملكة العربية السعودية‬‎",
  "t": "SAU"
}, {
  "c": "塞内加尔",
  "e": "Senegal",
  "k": "SN",
  "n": "221",
  "o": "Senegaal",
  "t": "SEN"
}, {
  "c": "塞尔维亚",
  "e": "Serbia",
  "k": "RS",
  "n": "381",
  "o": "Србија",
  "t": "SRB"
}, {
  "c": "塞舌尔",
  "e": "Seychelles",
  "k": "SC",
  "n": "248",
  "o": "Seychelles",
  "t": "SYC"
}, {
  "c": "塞拉利昂",
  "e": "Sierra Leone",
  "k": "SL",
  "n": "232",
  "o": "Sierra Leone",
  "t": "SLE"
}, {
  "c": "新加坡",
  "e": "Singapore",
  "k": "SG",
  "n": "65",
  "o": "Singapore",
  "t": "SGP"
}, {
  "c": "荷属圣马丁",
  "e": "Sint Maarten",
  "k": "SX",
  "n": "1721",
  "o": "Sint Maarten",
  "t": "SXM"
}, {
  "c": "斯洛伐克",
  "e": "Slovakia",
  "k": "SK",
  "n": "421",
  "o": "Slovensko",
  "t": "SVK"
}, {
  "c": "斯洛文尼亚",
  "e": "Slovenia",
  "k": "SI",
  "n": "386",
  "o": "Slovenija",
  "t": "SVN"
}, {
  "c": "所罗门群岛",
  "e": "Solomon Islands",
  "k": "SB",
  "n": "677",
  "o": "Solomon Islands",
  "t": "SLB"
}, {
  "c": "索马里",
  "e": "Somalia",
  "k": "SO",
  "n": "252",
  "o": "Soomaaliya",
  "t": "SOM"
}, {
  "c": "南非",
  "e": "South Africa",
  "k": "ZA",
  "n": "27",
  "o": "South Africa",
  "t": "ZAF"
}, /*{
  "c": "南乔治亚和南桑威奇群岛",
  "e": "South Georgia & South Sandwich Islands",
  "k": "GS",
  "n": "500",
  "o": "South Georgia & South Sandwich Islands",
  "u": 1,
  "t": "SGS"
},*/ {
  "c": "韩国",
  "e": "South Korea",
  "k": "KR",
  "n": "82",
  "o": "대한민국",
  "t": "KOR"
}, {
  "c": "南苏丹",
  "e": "South Sudan",
  "k": "SS",
  "n": "211",
  "o": "‫جنوب السودان‬‎",
  "t": "SSD"
}, {
  "c": "西班牙",
  "e": "Spain",
  "k": "ES",
  "n": "34",
  "o": "España",
  "t": "ESP"
}, {
  "c": "斯里兰卡",
  "e": "Sri Lanka",
  "k": "LK",
  "n": "94",
  "o": "ශ්‍රී ලංකාව",
  "t": "LKA"
}, {
  "c": "圣巴泰勒米",
  "e": "St Barthélemy",
  "k": "BL",
  "n": "590",
  "o": "Saint-Barthélemy",
  "t": "BLM"
}, {
  "c": "圣赫勒拿",
  "e": "St Helena",
  "k": "SH",
  "n": "290",
  "o": "St. Helena",
  "t": "SHN"
}, {
  "c": "圣基茨和尼维斯",
  "e": "St Kitts & Nevis",
  "k": "KN",
  "n": "1869",
  "o": "St. Kitts & Nevis",
  "t": "KNA"
}, {
  "c": "圣卢西亚",
  "e": "St Lucia",
  "k": "LC",
  "n": "1758",
  "o": "St. Lucia",
  "t": "LCA"
}, {
  "c": "法属圣马丁",
  "e": "St Martin",
  "k": "MF",
  "n": "590",
  "o": "Saint-Martin",
  "t": "MAF"
}, {
  "c": "圣皮埃尔和密克隆群岛",
  "e": "St Pierre & Miquelon",
  "k": "PM",
  "n": "508",
  "o": "Saint-Pierre-et-Miquelon",
  "t": "SPM"
}, {
  "c": "圣文森特和格林纳丁斯",
  "e": "St Vincent & Grenadines",
  "k": "VC",
  "n": "1784",
  "o": "St. Vincent & Grenadines",
  "t": "VCT"
}, {
  "c": "苏丹",
  "e": "Sudan",
  "k": "SD",
  "n": "249",
  "o": "‫السودان‬‎",
  "t": "SDN"
}, {
  "c": "苏里南",
  "e": "Suriname",
  "k": "SR",
  "n": "597",
  "o": "Suriname",
  "t": "SUR"
}, {
  "c": "斯瓦尔巴和扬马延",
  "e": "Svalbard & Jan Mayen",
  "k": "SJ",
  "n": "47",
  "o": "Svalbard og Jan Mayen",
  "u": 1,
  "t": "SJM"
}, {
  "c": "斯威士兰",
  "e": "Swaziland",
  "k": "SZ",
  "n": "268",
  "o": "Swaziland",
  "t": "SWZ"
}, {
  "c": "瑞典",
  "e": "Sweden",
  "k": "SE",
  "n": "46",
  "o": "Sverige",
  "t": "SWE"
}, {
  "c": "瑞士",
  "e": "Switzerland",
  "k": "CH",
  "n": "41",
  "o": "Schweiz",
  "t": "CHE"
}, {
  "c": "叙利亚",
  "e": "Syria",
  "k": "SY",
  "n": "963",
  "o": "‫سوريا‬‎",
  "t": "SYR"
}, {
  "c": "圣多美和普林西比",
  "e": "São Tomé & Príncipe",
  "k": "ST",
  "n": "239",
  "o": "São Tomé e Príncipe",
  "t": "STP"
}, {
  "c": "塔吉克斯坦",
  "e": "Tajikistan",
  "k": "TJ",
  "n": "992",
  "o": "Тоҷикистон",
  "t": "TJK"
}, {
  "c": "坦桑尼亚",
  "e": "Tanzania",
  "k": "TZ",
  "n": "255",
  "o": "Tanzania",
  "t": "TZA"
}, {
  "c": "泰国",
  "e": "Thailand",
  "k": "TH",
  "n": "66",
  "o": "ไทย",
  "t": "THA"
}, {
  "c": "东帝汶",
  "e": "Timor-Leste",
  "k": "TL",
  "n": "670",
  "o": "Timor-Leste",
  "t": "TLS"
}, {
  "c": "多哥",
  "e": "Togo",
  "k": "TG",
  "n": "228",
  "o": "Togo",
  "t": "TGO"
}, {
  "c": "托克劳",
  "e": "Tokelau",
  "k": "TK",
  "n": "690",
  "o": "Tokelau",
  "t": "TKL"
}, {
  "c": "汤加",
  "e": "Tonga",
  "k": "TO",
  "n": "676",
  "o": "Tonga",
  "t": "TON"
}, {
  "c": "特立尼达和多巴哥",
  "e": "Trinidad & Tobago",
  "k": "TT",
  "n": "1868",
  "o": "Trinidad & Tobago",
  "t": "TTO"
}, {
  "c": "突尼斯",
  "e": "Tunisia",
  "k": "TN",
  "n": "216",
  "o": "Tunisia",
  "t": "TUN"
}, {
  "c": "土耳其",
  "e": "Turkey",
  "k": "TR",
  "n": "90",
  "o": "Türkiye",
  "t": "TUR"
}, {
  "c": "土库曼斯坦",
  "e": "Turkmenistan",
  "k": "TM",
  "n": "993",
  "o": "Turkmenistan",
  "t": "TKM"
}, {
  "c": "特克斯和凯科斯群岛",
  "e": "Turks & Caicos Islands",
  "k": "TC",
  "n": "1649",
  "o": "Turks & Caicos Islands",
  "t": "TCA"
}, {
  "c": "图瓦卢",
  "e": "Tuvalu",
  "k": "TV",
  "n": "688",
  "o": "Tuvalu",
  "t": "TUV"
}, {
  "c": "乌干达",
  "e": "Uganda",
  "k": "UG",
  "n": "256",
  "o": "Uganda",
  "t": "UGA"
}, {
  "c": "乌克兰",
  "e": "Ukraine",
  "k": "UA",
  "n": "380",
  "o": "Україна",
  "t": "UKR"
}, {
  "c": "阿拉伯联合酋长国",
  "e": "United Arab Emirates",
  "k": "AE",
  "n": "971",
  "o": "‫الإمارات العربية المتحدة‬‎",
  "t": "ARE"
}, {
  "c": "乌拉圭",
  "e": "Uruguay",
  "k": "UY",
  "n": "598",
  "o": "Uruguay",
  "t": "URY"
}, {
  "c": "美属维尔京群岛",
  "e": "US Virgin Islands",
  "k": "VI",
  "n": "1340",
  "o": "U.S. Virgin Islands",
  "t": "VIR"
}, {
  "c": "乌兹别克斯坦",
  "e": "Uzbekistan",
  "k": "UZ",
  "n": "998",
  "o": "Oʻzbekiston",
  "t": "UZB"
}, {
  "c": "瓦努阿图",
  "e": "Vanuatu",
  "k": "VU",
  "n": "678",
  "o": "Vanuatu",
  "t": "VUT"
}, {
  "c": "梵蒂冈",
  "e": "Vatican City",
  "k": "VA",
  "n": "379",
  "o": "Città del Vaticano",
  "t": "VAT"
}, {
  "c": "委内瑞拉",
  "e": "Venezuela",
  "k": "VE",
  "n": "58",
  "o": "Venezuela",
  "t": "VEN"
}, {
  "c": "越南",
  "e": "Vietnam",
  "k": "VN",
  "n": "84",
  "o": "Việt Nam",
  "t": "VNM"
}, {
  "c": "瓦利斯和富图纳",
  "e": "Wallis & Futuna",
  "k": "WF",
  "n": "681",
  "o": "Wallis & Futuna",
  "t": "WLF"
}, {
  "c": "西撒哈拉",
  "e": "Western Sahara",
  "k": "EH",
  "n": "212",
  "o": "‫الصحراء الغربية‬‎",
  "u": 1,
  "t": "ESH"
}, {
  "c": "也门",
  "e": "Yemen",
  "k": "YE",
  "n": "967",
  "o": "‫اليمن‬‎",
  "t": "YEM"
}, {
  "c": "赞比亚",
  "e": "Zambia",
  "k": "ZM",
  "n": "260",
  "o": "Zambia",
  "t": "ZMB"
}, {
  "c": "津巴布韦",
  "e": "Zimbabwe",
  "k": "ZW",
  "n": "263",
  "o": "Zimbabwe",
  "t": "ZWE"
}];
export default COUNTRIES;
// const _CC_MAP = {};
// const _PH_MAP = {};
// COUNTRIES.forEach(ccObject => {
//   _CC_MAP[ccObject.t] = ccObject;
//   _PH_MAP[ccObject.n] = ccObject.t;
// });
// export const CC_MAP = _CC_MAP;
// export const PH_MAP = _PH_MAP;
// [{"mode":"full","isActive":false}]