import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import { Download } from 'react-bootstrap-icons';
// import User from "../assets/user_placeholder.png"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import Range from 'react-range-progress'
import { makeRequest,makeFileUploadRequest,handleKeyUp,gameList,timestampTime } from '../../helpers'
const DEFAULT_VALUE = 62.5;
const DEFAULT_VALUE1 = 80;
const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
};
class SeniorDetails extends React.Component {
  constructor(props) {
    super(props);
      this.showDiv = this.showDiv.bind(this)
      this.statusModal = this.statusModal.bind(this)
		this.makeRequest = makeRequest
		this.gameList = gameList
  }
  state= {
    showGameControl:false,
    value: DEFAULT_VALUE,
    value1: DEFAULT_VALUE1,
    showStatusModal:false,
	  gameData:[],
	  selectedGame:[],
    details:[],
  }
  gameProviders(e) {
	   this.state.selectedGame.push(e.target.value);
	}
  showDiv(){
      this.setState(state => ({showGameControl: !this.state.showGameControl}));
  }
  onRangeChanged = (value) => {
    this.setState(state => ({value: value}));
  }
  onRangeChanged1 = (value) => {
    this.setState(state => ({value1: value}));
  }
  statusModal(val){
    this.setState({showStatusModal: val})
  }
	componentDidMount() {
		this.gameList(this);
    const senior_id=window.location.hash.substr(1);
		(async () => {
		  var result1=await this.makeRequest('user/'+senior_id, 'get','',localStorage.getItem('role_token'));
		  if(result1.status===200){
			 this.setState({details: result1.data})
		  }
		})();
	}

  render() {
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px 30px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Senior management</span> / 
                    <span>Senior details</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Senior Details</h5><br/>
                    <div className="row" style={{margin:0,}}>
                        <div className="img_div" style={{width:"100px"}}>
                            <img src={'../assets/user_placeholder.png'}/>
                        </div>
                        <div style={{width:"300px"}} className="senior_det_left">
                            <h5>{this.state.details.username}</h5>
                            {/*<h6>#002</h6>*/}
                            <p>Full Name: {this.state.details.fullname}</p>
                            <p>Email: {this.state.details.email}</p>
                            <p>Phone: {this.state.details.phone}
                            
                            {this.state.details.phone !==null ? (<span>({this.state.details.isPhoneNumberVerified ? 'Verified' : 'Not verified'})</span>):('')}
                            </p>
                        </div>
                        <div style={{width:"300px"}} className="senior_det_right">
                            <button className="btn btn-primary add_btn" style={{width:'180px'}} onClick={() => this.statusModal(true)}>View Account Details</button><br/><br/>
                            <p>Referral code: {this.state.details.referralCode}</p>
                            <p>Country/Region: </p>
                            <p>Address: </p>

                            <Modal show={this.state.showStatusModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal">
                                <Modal.Header style={{background:'none',fontWeight:"700"}} closeButton onClick={() => this.statusModal(false)}>Turnover Commission of your Direct Referrals</Modal.Header>
                                <Modal.Body>

                                    <table className="senior_modal">
                                      <tbody>
                                        <tr>
                                          <td>Table Games</td>
                                          <td className="sen_right_td">0.7%</td>
                                        </tr>
                                        <tr>
                                          <td>Slots</td>
                                          <td className="sen_right_td">0.1%</td>
                                        </tr>
                                        <tr>
                                          <td>Sports</td>
                                          <td className="sen_right_td">0.5%</td>
                                        </tr>
                                        <tr>
                                          <td>Lottery</td>
                                          <td className="sen_right_td">0.5%</td>
                                        </tr>
                                        <tr>
                                          <td>Cock Fighting</td>
                                          <td className="sen_right_td">0.7%</td>
                                        </tr>
                                      </tbody>
                                    </table><br/>
                                    <button className="btn btn-primary add_btn cancel_btn pdf_btn" style={{width:'auto'}}><span><Download size={14} color="#1890FF"/></span> Commission Plan PDF</button>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>               
                </div>
              </div>
            </div>
            <div className="col-md-12 divbody main_setting">
                <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                    <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Maximum percentage of company GGR shared to you: <span className="del_span">80%</span></h5>
                    <div className="sen_det_p"><p>Turnover Commission of your Direct Referrals</p></div>

                </div>
            </div>

            <div className="col-md-12 divbody main_setting">
                <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                    <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Agent level allow</h5>
                </div>
                <div className="row" className="comm_setting" style={{margin:0,padding:'16px 30px 36px 80px',background:'white',width:"100%"}}>
                <div className="row ms-progressbar" style={{borderBottom:"0"}}>

                    <div className="col-xs-3 ms-progressbar-step complete">
                        <div className="progress">
                        <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Ower</div>
                        
                    </div>

                    <div className="col-xs-3 ms-progressbar-step complete">
                        <div className="progress">
                        <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Senior</div>
                        
                    </div>
                    <div className="col-xs-3 ms-progressbar-step disabled">
                        <div className="progress">
                        <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot current">
                        </a>
                        <div className="text-center ms-progressbar-step-number">Master</div>
                        
                    </div>
                    <div className="col-xs-3 ms-progressbar-step disabled">
                        <div className="progress">
                        <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Agent1</div>
                        
                    </div>
                    <div className="col-xs-3 ms-progressbar-step disabled">
                        <div className="progress">
                        <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Agent 2</div>
                        
                    </div>
                    <div className="col-xs-3 ms-progressbar-step disabled">
                        <div className="progress">
                        <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Agent 3</div>
                        
                    </div>
                    <div className="col-xs-3 ms-progressbar-step">
                        <div className="progress" style={{zIndex:"-1"}}>
                        <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Player</div>
                        
                    </div>
                </div>

                </div>
            </div>

            <div className="col-md-12 divbody main_setting">
                <div className="subbdvv1 progress_bar" style={{padding: '16px 0px 20px'}}>
                    <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Percentage of Your GGR Commission shared to This Senior:</h5>
                    <div style={{padding:"26px",width:"500px"}} className="bar_width">
                      <h6 className="bar_width">{this.state.value} %</h6>
                      <Range
                        value={this.state.value}
                        min={0}
                        max={100}
                        fillColor={{
                          r: 20,
                          g: 150,
                          b: 100,
                          a: 0.75,
                        }}
                        trackColor={{
                          r: 10,
                          g: 10,
                          b: 0,
                          a: 0.5,
                        }}
                        height={16}
                        width="100%"
                        onChange={this.onRangeChanged}
                      /><br/><br/>
                      <p>For GGR calculation specific to GBO, please see the Commission Plan document.</p>
                    </div>
                </div>
            </div>

            <div className="col-md-12 divbody main_setting">
                <div className="subbdvv1 progress_bar" style={{padding: '16px 0px 20px'}}>
                    <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Percentage of Your Allocated GGR retained:</h5>
                    <div className="bar bar_width" style={{padding:"26px",width:"500px"}}>
                      <h6 className="bar_width">{this.state.value1} %</h6>
                      <Range
                        value={this.state.value1}
                        min={0}
                        max={100}
                        fillColor={{
                          r: 20,
                          g: 150,
                          b: 100,
                          a: 0.75,
                        }}
                        trackColor={{
                          r: 10,
                          g: 10,
                          b: 0,
                          a: 0.5,
                        }}
                        height={16}
                        width="100%"
                        onChange={this.onRangeChanged1}
                      />
                    </div>
                </div>
            </div>

                <div className="col-md-12 divbody main_setting">
                  <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                      <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Game Control</h5>
                  </div>
                  <div className="row" style={{margin:0,padding:'12px 14px',background:'white'}}>
                  
                      <div className="col-md-6">
                      <ul className="comm_div">
                            <li className="li_head">Game Name <span>No/Yes</span></li>
                            {this.state.gameData.map(data => (
								<li className="game_provider_checkbox" key={data.id}>{data.name} 
								<span>
									<label className="switch">
									  <input type="checkbox" onClick={() => this.gameProviders}/>
									  <span className="slider round"></span>
									</label>
								</span>
								</li>
							))}
                          </ul>
                      </div>
                      {/*<div className="col-md-6">
                          <ul className="comm_div">
                            <li className="li_head">Game Name <span>No/Yes</span></li>
                            <li>Poker A <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                            <li>Poker B <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                            <li>Poker C <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                            <li>Poker D <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                            <li>Poker E <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                          </ul>
                      </div>*/}
                  </div><br/>
                </div>
                <div style={{background: 'white'}}>
                    <div style={{padding: '10px 20px 20px'}}>
                        <div style={{marginTop:"10px",paddingLeft:"0"}}>
                            <button className="btn btn-primary add_btn" style={{width:'auto',marginLeft:"20px"}}>Save</button>
                            <button className="btn btn-primary add_btn reset_black_btn" style={{width:'auto',marginLeft:"20px"}}>Reset</button>
                        </div>
                    </div>
                </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SeniorDetails;