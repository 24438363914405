import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { makeRequest,makeProviderRequest } from '../../helpers'
//import { Lock,Phone,CheckSquare,CaretDownFill,StopwatchFill } from 'react-bootstrap-icons';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.makeProviderRequest = makeProviderRequest
    this.update = this.update.bind(this)
    this.changeOldPwd = this.changeOldPwd.bind(this)
    this.changeNewPwd = this.changeNewPwd.bind(this)
    this.changeConfirmPwd = this.changeConfirmPwd.bind(this)
  }
  
  state= {
    oldPwd:'',
    newPwd:'',
    ConfirmPwd:'',
    submitDisabled:false,
    error_msg:'',
    success_msg:'',
  }
  changeOldPwd(e) {
    let oldPwdValid = e.target.value ? true : false;
    this.setState({
      oldPwd: (e.target.value).toLowerCase(),
      oldPwdValid: oldPwdValid,
    })
  }
  changeNewPwd(e) {
    let newPwdValid = e.target.value ? true : false;
    this.setState({
      newPwd: e.target.value,
      newPwdValid: newPwdValid,
    })
  }
  changeConfirmPwd(e) {
    let ConfirmPwdValid = e.target.value ? true : false;
    this.setState({
      ConfirmPwd: e.target.value,
      ConfirmPwdValid: ConfirmPwdValid,
    })
  }
  async update(){
    if(this.state.oldPwd===''){
      this.setState(state => ({error_msg:'Enter old password.'}));
    }else if(this.state.newPwd===''){
      this.setState(state => ({error_msg:'Enter new password.'}));
    }else if(this.state.ConfirmPwd===''){
      this.setState(state => ({error_msg:'Enter confirm password.'}));
    }else if(this.state.ConfirmPwd!==this.state.newPwd){
      this.setState(state => ({error_msg:'Password and confirm password does not match'}));
    }else{
      this.setState(state => ({submitDisabled:true}));
      let data = JSON.stringify({oldPassword: this.state.oldPwd,newPassword: this.state.newPwd});
      var result1;
      if(localStorage.getItem('role')=='owner'){
        result1=await this.makeRequest('owner/me/update-password', 'post', data,localStorage.getItem('role_token'));
      }else{
        result1=await this.makeProviderRequest('password', 'put', data,localStorage.getItem('role_token'));
      }
      
      if(result1.status===200){
        this.setState({
          success_msg:result1.data.message,error_msg:'',submitDisabled:false
        })
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false}));
    }
  }
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Settings</span> / 
                    <span>Change Password</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Change Password</h5>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              {this.state.success_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>):('')
              }
              {this.state.error_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.error_msg}</p>):('')
              }
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-5">
                    <div className="add_form_div persnl_setngs_div chg_pwd_div">
                      <span>Old Password：</span>
                      <input placeholder="Old Password" type="password" className="form-control" onChange={this.changeOldPwd}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div chg_pwd_div">
                      <span>New Password：</span>
                      <input placeholder="New Password" type="password" className="form-control" onChange={this.changeNewPwd}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div chg_pwd_div">
                      <span>Confirm Password：</span>
                      <input placeholder="Confirm Password" type="password" className="form-control" onChange={this.changeConfirmPwd}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <button className="btn btn-primary add_btn" onClick={this.update} disabled={this.state.submitDisabled} style={{position:"relative"}}>Update
                      </button>
                    </div><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ChangePassword;
