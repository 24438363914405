import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
const data = [
  {id: 1,username: "company",fname:"testmex01",agent_code: "ag1",total:"0.00"},{id: 2,username: "ag1",fname:"testmex01",agent_code: "ag1",total:"0.00"},{id: 3,username: "ag1",fname:"testmex01",agent_code: "ag1",total:"0.00"},{id: 4,username: "ag1",fname:"testmex01",agent_code: "ag1",total:"0.00"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class AgentCreditSettlement extends React.Component {
  render() {
    const columns = [
      {name: 'No.',width:'70px',cell: row => <div data-tag="allowRowEvents"><input type="checkbox" style={{width:"auto"}}/> <span>{row.id}</span></div>},
      {name: 'Username',selector: 'username'},
      {name: 'Full Name',selector: 'fname'},
      {name: 'Agent Code',selector: 'agent_code'},
      {name: 'Credit Limit',selector: 'total'},
      {name: 'Balance',selector: 'total',width:"80px"},
      {name: 'Amount',cell: row => <div data-tag="allowRowEvents"><input className="form-control" defaultValue="1"/></div>,width:"100px"},
      {name: 'Remark',cell: row => <div data-tag="allowRowEvents"><input className="form-control" defaultValue="1"/></div>,width:"100px"},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Agent management</span> / 
                    <span>Agent Credit Settlement</span>
                  </div>
                  <h5 className="pg_head">Agent Credit Settlement</h5>
                  <button className="btn btn-primary addbtn">Settlement</button>
                </div>
              </div><br/>
              
              <div className="divbody">
                <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AgentCreditSettlement;