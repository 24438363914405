import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import AboutSidebar from './AboutSidebar';
import { makeRequest } from '../helpers'
import { CaretUpFill,CaretDownFill,PlayFill,Fullscreen,DashSquare,GraphUp,ArrowLeftRight,ArrowLeft } from 'react-bootstrap-icons';

class PickNewCurrency extends React.Component {
    constructor(props) {
        super(props);
        this.makeRequest = makeRequest
        this.ppModal = this.ppModal.bind(this)
        this.gameInfo = this.gameInfo.bind(this)
        this.gameInfoModal = this.gameInfoModal.bind(this)
        this.toggleCurrencyFilter = this.toggleCurrencyFilter.bind(this)
        this.switch_currency = this.switch_currency.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.deposit_amount = this.deposit_amount.bind(this)
        this.changeShowDiv = this.changeShowDiv.bind(this)
        this.convertCurrency = this.convertCurrency.bind(this)
        this.changeCurrency = this.changeCurrency.bind(this)
        this.getCurrentEvolplayBalance = this.getCurrentEvolplayBalance.bind(this)
        this.getCurrentVigobetBalance = this.getCurrentVigobetBalance.bind(this)
        this.viewConvertCurrency = this.viewConvertCurrency.bind(this)
      }
      state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 4,
            },
            1000: {
                items: 6,
            },
        },
        show_ppModal:false,
        show_gameinfoModal:false,
        showCurrencyFilter:false,
        submitDisabled:false,
        content:'',
        chosen_currency:'USDT',
        games_data:[],
        game_info:{},
        fiatCurrencies:[],
        success_msg:'',
        error_msg:'',
        amount:0,
        game_title:'',
        availableWalletBalance:0,
        wallet_currency:'',
        showDiv:'start_playing',
        currentCurrency:'',
        currentAmount:'',
        currentVigobetCurrency:'',
        currentVigobetAmount:'',
        gameId:'',
        cryptoCurrencies:[],
        exchangeValue:'',
        currentSelectedCurrency:'USDT',
        convert_amount:'',
      }
      changeShowDiv(val){
        this.setState({showDiv: val})
        this.setState({amount:''})
        this.setState({convert_amount:''})
        this.setState({exchangeValue:''})
      }
      ppModal(val,content){
        this.setState({show_ppModal: val,content:content})
      }
      async gameInfo(val){
        let result1=await this.makeRequest('evoplay/game/'+val, 'get', '','');
        localStorage.setItem('game_id',val);
        if(result1.status===200){
          this.setState({game_info:result1.data.data});
          this.setState({game_title:result1.data.data.title});
          this.gameInfoModal(true);
          if(localStorage.getItem('token')!=='' && localStorage.getItem('token')!==null && localStorage.getItem('token')!=='null' && localStorage.getItem('token')!=='undefined' && localStorage.getItem('token')!==undefined){
            this.getCurrentEvolplayBalance();
          }
        }
      }
      gameInfoModal(val){
        this.setState({success_msg:'',error_msg:'',show_gameinfoModal: val})
      }
      
      async toggleCurrencyFilter(val,val2){
        this.setState({showCurrencyFilter: val})
        if(val2!==''){
          let data = JSON.stringify({currency: val2});
          this.setState({currentSelectedCurrency: val2})
          var result1=await this.makeRequest('wallet?currency='+val2, 'get','',localStorage.getItem('token'));
            if(result1.status===200){
                this.setState({currentVigobetCurrency: result1.data.data[0].currency})
                this.setState({currentVigobetAmount: (result1.data.data[0].balance).toFixed(2)})
                this.switch_currency(data);
            }
        }
      }
      changeCurrency(val,val2){
        this.setState({showCurrencyFilter: val})
        if(val2!==''){
          let data = JSON.stringify({currency: val2});
          this.setState({chosen_currency: val2})
        }
      }
      async switch_currency(data){
        if(localStorage.getItem('token')==='' || localStorage.getItem('token')===null || localStorage.getItem('token')==='null' || localStorage.getItem('token')==='undefined' || localStorage.getItem('token')===undefined){
          this.setState({error_msg: 'You need to login first to access the following features.'})
        }else{
          let result1=await this.makeRequest('evoplay/user/switch-currency', 'post', data,localStorage.getItem('token'));
          if(result1.status===200){
            let amount=(parseFloat(result1.data.balance).toFixed(2));
            this.setState({currentCurrency: result1.data.currency})
            this.setState({currentAmount: amount})
            //console.log(result1.data);
            //let bal=(result1.data.balance);
            //this.setState({success_msg: 'Balance: '+(bal)+(result1.data.data.currency)})
          }else if(result1.status===400){
            this.setState(state => ({error_msg:result1.data.message}));
          }else if(result1.status===500){
            this.setState(state => ({error_msg:'Some problem occured. Please try again.'}));
          }
        }
      }
      handleChange(e) {
        //let fnameValid = e.target.value ? true : false;
        this.setState({amount: e.target.value})
      }
      async getCurrentEvolplayBalance(){
        var result1=await this.makeRequest('evoplay/wallet', 'get','',localStorage.getItem('token'));
        if(result1.status===200){
            let amount=(parseFloat(result1.data.balance).toFixed(2));
            this.setState({currentCurrency: result1.data.currency})
            this.setState({currentSelectedCurrency: result1.data.currency})
            this.setState({currentAmount: amount})
            this.getCurrentVigobetBalance();
        }
      }
      async getCurrentVigobetBalance(){
        var result1=await this.makeRequest('wallet?currency='+this.state.currentSelectedCurrency, 'get','',localStorage.getItem('token'));
        if(result1.status===200){
            this.setState({currentVigobetCurrency: result1.data.data[0].currency})
            this.setState({currentVigobetAmount: (result1.data.data[0].balance).toFixed(2)})
        }
      }
      async deposit_amount(){
        if(localStorage.getItem('token')==='' || localStorage.getItem('token')===null || localStorage.getItem('token')==='null' || localStorage.getItem('token')==='undefined' || localStorage.getItem('token')===undefined){
          this.setState({error_msg: 'You need to login first to access the following features.'})
        }else{
            if(this.state.amount===0){
                this.setState({error_msg: 'Enter Amount.'})
              }
            else if(parseFloat(this.state.amount)>parseFloat(this.state.getCurrentVigobetBalance)){
                this.setState({error_msg: 'Insufficient balance in wallet.'})
            }else{
                this.setState({submitDisabled: true})
                let data = JSON.stringify({amount:parseFloat(this.state.amount)});
                let result1=await this.makeRequest('evoplay/user/deposit', 'post', data,localStorage.getItem('token'));
                if(result1.status===200){
                this.setState({submitDisabled: false,success_msg: 'Amount deposited successfully.',error_msg:''})
                setTimeout(
                    function() {
                    if(result1.status===200){
                        this.setState({submitDisabled: false,success_msg: '',error_msg:'',showDiv:'current_currency'})
                        this.getCurrentEvolplayBalance();
                        //this.props.history.push('/play_game#'+this.state.game_title);
                    }
                    }.bind(this),3000
                );
                }else if(result1.status===400){
                this.setState(state => ({error_msg:result1.data.message,submitDisabled: false,}));
                }else if(result1.status===500){
                this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled: false,}));
                }
            }
        }
      }
      viewConvertCurrency(val,val2){
        this.setState({showCurrencyFilter: val})
        this.setState({chosen_currency: val2})
      }
      async changeConvertCurrency(val,val2){
        this.setState({showCurrencyFilter: val})
        this.setState({chosen_currency: val2})
        if(this.state.convert_amount!==''){
            if(this.state.currentCurrency===val2){
                this.setState({error_msg: 'From and To currencies are not same.'})
                this.setState({exchangeValue: ''})
            }else{
                this.setState({submitDisabled: true})
                let data = JSON.stringify({from:this.state.currentCurrency,to:val2,value:parseFloat(this.state.convert_amount)});
                let result1=await this.makeRequest('convert', 'post', data,localStorage.getItem('token'));
                if(result1.status===200){
                    this.setState({submitDisabled: false,error_msg:'',exchangeValue:result1.data.value})
                    this.getCurrentEvolplayBalance();
                }else if(result1.status===400){
                    this.setState(state => ({error_msg:result1.data.message,submitDisabled: false,}));
                    this.setState({exchangeValue: ''})
                }else if(result1.status===500){
                    this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled: false,}));
                    this.setState({exchangeValue: ''})
                }
            }
        }
      }
      async convertCurrency(e){
        this.setState({convert_amount: e.target.value})
        if(this.state.currentCurrency===this.state.chosen_currency){
            this.setState({error_msg: 'From and To currencies are not same.'})
            this.setState({exchangeValue: ''})
        }else{
            this.setState({submitDisabled: true})
            let data = JSON.stringify({from:this.state.currentCurrency,to:this.state.chosen_currency,value:parseFloat(e.target.value)});
            let result1=await this.makeRequest('convert', 'post', data,localStorage.getItem('token'));
            if(result1.status===200){
            //let amount=(result1.data.value).toFixed(2);
            this.setState({submitDisabled: false,error_msg:'',exchangeValue:result1.data.value})
            this.getCurrentEvolplayBalance();
            // setTimeout(
            //     function() {
            //     if(result1.status===200){
            //         this.setState({submitDisabled: false,success_msg: '',error_msg:'',showDiv:'convert_success'})
            //         this.getCurrentEvolplayBalance();
            //         //this.props.history.push('/play_game#'+this.state.game_title);
            //     }
            //     }.bind(this),3000
            // );
            }else if(result1.status===400){
            this.setState(state => ({error_msg:result1.data.message,submitDisabled: false,}));
            this.setState({exchangeValue: ''})
            }else if(result1.status===500){
            this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled: false,}));
            this.setState({exchangeValue: ''})
            }
        }
      }
      componentDidMount(){
        let game_id=window.location.hash.substr(1);
        this.setState({gameId:game_id});
        this.gameInfo(game_id);
        this.getCurrentEvolplayBalance();
        (async () => {
            var result1=await this.makeRequest('wallet/currency', 'get', '',localStorage.getItem('token'));
            if(result1.status===200){
                this.setState({
                    cryptoCurrencies:result1.data.crypto
                })
                this.setState({
                    fiatCurrencies:result1.data.fiat
                })
            }
        })();
        (async () => {
            let result1=await this.makeRequest('game', 'get', '','');
            if(result1.status===200){
              this.setState({
                games_data:result1.data.data
              })
            }
        })();
      }
  render() {
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{backgroundImage:'linear-gradient(#000407, #011c31)',color:'white'}}>
            <br/><br/><br/>
            <div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12 col-sm-12">

                    {this.state.showDiv==='pick_currency' &&
                    <div className="pick_new_currency_div">
                        <h4>Please pick a new currency you<br/> would like to play with</h4>
                        <div>
                            <span>Balance in</span>
                            <div className="wallet_deposit_filter" style={{display: 'inline-block'}}>
                              <p className="wallet_select" style={{marginBottom:"0",lineHeight:"32px",textTransform:"capitalize",cursor: 'pointer',paddingLeft:'20px'}} onClick={() => this.toggleCurrencyFilter(!this.state.showCurrencyFilter,'')}>{this.state.currentCurrency}
                                {this.state.showCurrencyFilter ?
                                (<span style={{right:"-20px"}}><CaretDownFill color="white" size={14} /></span>):
                                (<span style={{right:"-20px"}}><CaretUpFill color="white" size={14} /></span>)}
                              </p>
                              {this.state.showCurrencyFilter ?
                              (<div className="under_wallet_deposit_filter">
                                <ul style={{padding: 0,listStyle: 'none'}}>
                                {this.state.cryptoCurrencies.map((currency,index) => (
                                    <li key={index} onClick={() => this.toggleCurrencyFilter(false,currency)}style={this.state.currentCurrency===currency ? {background:"#E5E5E5"} : {background:"white"}} style={{color:"#4f4e4e"}}><span>{currency}</span></li>
                                  ))}
                                  {this.state.fiatCurrencies.map((currency,index) => (
                                    <li key={index} onClick={() => this.toggleCurrencyFilter(false,currency)}style={this.state.currentCurrency===currency ? {background:"#E5E5E5"} : {background:"white"}}><span>{currency}</span></li>
                                  ))}
                                  {/* <li onClick={() => this.closeDepositCryptoModal(true)}>Crypto</li>
                                  <li onClick={() => this.fiatDiv(true)}>Fiat</li> */}
                                </ul>
                              </div>):('')}
                            </div>
                          </div>
                        <h6>Current {this.state.currentCurrency} Balance: {this.state.currentAmount}</h6>
                        <button className="btn login_btn play_btn" onClick={()=>this.changeShowDiv('current_currency')}><span><PlayFill color="white" size={18} /></span> Real Play</button>
                    </div>}
                    
                    {this.state.showDiv==='current_currency' &&
                    <div className="current_currency">
                        <h4>Current selected currency and wallet balance</h4>
                        <h3 style={{fontSize:"22px",paddingTop:"16px"}}>{this.state.currentVigobetCurrency+' '+this.state.currentVigobetAmount}</h3>
                        <button className="btn login_btn play_btnn" onClick={()=>this.props.history.push('/play_game#'+this.state.game_title)}>Start Playing</button>
                        <button className="btn login_btn play_btnn" onClick={()=>this.changeShowDiv('convert_currency')}>Convert</button>
                    </div>}

                    {this.state.showDiv==='start_playing' &&
                    <div className="change_currency_div">
                        {this.state.success_msg!=='' ?
                            (<p style={{fontSize: '16px',color: 'green',textAlign:'center'}}>{this.state.success_msg}</p>):('')
                        }
                        {this.state.error_msg!=='' ?
                            (<p style={{fontSize: '16px',color: 'red',textAlign:'center'}}>{this.state.error_msg}</p>):('')
                        }
                        <h5>Please pick a new currency you<br/> would like to play with</h5><br/>
                        <div className="under_change_currency">
                            <span><input type="number" onChange={this.handleChange} className="form-control" placeholder="Enter amount" /></span>
                            <div className="wallet_deposit_filter" style={{display: 'inline-block'}}>
                              <p className="wallet_select" onClick={() => this.toggleCurrencyFilter(!this.state.showCurrencyFilter,'')}>{this.state.currentSelectedCurrency}
                                {this.state.showCurrencyFilter ?
                                (<span style={{right:"4px"}}><CaretDownFill color="white" size={14} /></span>):
                                (<span style={{right:"4px"}}><CaretUpFill color="white" size={14} /></span>)}
                              </p>
                              {this.state.showCurrencyFilter ?
                              (<div className="under_wallet_deposit_filter">
                                <ul style={{padding: 0,listStyle: 'none'}}>
                                {this.state.cryptoCurrencies.map((currency,index) => (
                                    <li key={index} onClick={() => this.toggleCurrencyFilter(false,currency)}style={this.state.currentSelectedCurrency===currency ? {background:"#E5E5E5"} : {background:"white"}} ><span style={{color:"#4f4e4e"}}>{currency}</span></li>
                                  ))}
                                  {this.state.fiatCurrencies.map((currency,index) => (
                                    <li key={index} onClick={() => this.toggleCurrencyFilter(false,currency)}style={this.state.currentSelectedCurrency===currency ? {background:"#E5E5E5"} : {background:"white"}}><span style={{color:"#4f4e4e"}}>{currency}</span></li>
                                  ))}
                                  {/* <li onClick={() => this.closeDepositCryptoModal(true)}>Crypto</li>
                                  <li onClick={() => this.fiatDiv(true)}>Fiat</li> */}
                                </ul>
                              </div>):('')}
                            </div>
                        </div><br/>
                          <span className="change_currency_span">Current {this.state.currentVigobetCurrency} Balance in Vigobet: {this.state.currentVigobetAmount}</span><br/>
                          <button className="btn login_btn play_btn real_play" disabled={this.state.submitDisabled} onClick={this.deposit_amount}><span><PlayFill color="white" size={18} /></span> Real Play</button>
                    </div>}

                    {this.state.showDiv==='change_currency' &&
                    <div className="change_currency_div">
                        <span className="back_to_bln" onClick={()=>this.changeShowDiv('current_currency')}><i><ArrowLeft color="#FF7D00" size={16} /></i> Back to balance</span>
                        {this.state.success_msg!=='' ?
                            (<p style={{fontSize: '16px',color: 'green',textAlign:'center'}}>{this.state.success_msg}</p>):('')
                        }
                        {this.state.error_msg!=='' ?
                            (<p style={{fontSize: '16px',color: 'red',textAlign:'center'}}>{this.state.error_msg}</p>):('')
                        }
                        <h4>Change Currency</h4>
                        <p>Please pick a new currency you would like to play with.</p>
                        <p>By changing the game wallet currency,we will transfer<br/>the previous balance back to vigobet wallet.</p><br/><br/>
                        <div className="under_change_currency">
                            <span><input type="text" className="form-control" placeholder="Enter amount" onChange={this.handleChange} /></span>
                            <div className="wallet_deposit_filter" style={{display: 'inline-block'}}>
                              <p className="wallet_select" onClick={() => this.toggleCurrencyFilter(!this.state.showCurrencyFilter,'')}>{this.state.currentCurrency}
                                {this.state.showCurrencyFilter ?
                                (<span style={{right:"4px"}}><CaretDownFill color="white" size={14} /></span>):
                                (<span style={{right:"4px"}}><CaretUpFill color="white" size={14} /></span>)}
                              </p>
                              {this.state.showCurrencyFilter ?
                              (<div className="under_wallet_deposit_filter">
                                <ul style={{padding: 0,listStyle: 'none'}}>
                                  {this.state.cryptoCurrencies.map((currency,index) => (
                                    <li key={index} onClick={() => this.toggleCurrencyFilter(false,currency)}style={this.state.currentCurrency===currency ? {background:"#E5E5E5"} : {background:"white"}}><span>{currency}</span></li>
                                  ))}
                                  {this.state.fiatCurrencies.map((currency,index) => (
                                    <li key={index} onClick={() => this.toggleCurrencyFilter(false,currency)}style={this.state.currentCurrency===currency ? {background:"#E5E5E5"} : {background:"white"}}><span>{currency}</span></li>
                                  ))}
                                  
                                  {/* <li onClick={() => this.closeDepositCryptoModal(true)}>Crypto</li>
                                  <li onClick={() => this.fiatDiv(true)}>Fiat</li> */}
                                </ul>
                              </div>):('')}
                            </div>
                        </div><br/>
                          <span className="change_currency_span">Currenct {this.state.currentCurrency} Balance: {this.state.currentAmount}</span><br/>
                          <button className="btn login_btn play_btn" disabled={this.state.submitDisabled} onClick={this.deposit_amount}>Change and Play</button>
                          <button className="btn play_btn cancel_play_btn"  onClick={()=>this.changeShowDiv('current_currency')}>Cancel</button>
                    </div>}

                    {this.state.showDiv==='convert_currency' &&
                    <div className="convert_currency">
                        <span className="back_to_bln" onClick={()=>this.changeShowDiv('current_currency')}><i><ArrowLeft color="#FF7D00" size={16} /></i> Back to balance</span>
                        {this.state.success_msg!=='' ?
                            (<p style={{fontSize: '16px',color: 'green',textAlign:'center'}}>{this.state.success_msg}</p>):('')
                        }
                        {this.state.error_msg!=='' ?
                            (<p style={{fontSize: '16px',color: 'red',textAlign:'center'}}>{this.state.error_msg}</p>):('')
                        }
                        <h4>Convert Currency</h4><br/>
                        <div className="under_convert_currency">
                            <div className="row" style={{margin:"0",width:"100%"}}>
                                <div className="col-xl-5 col-lg-5" style={{marginTop:"32px"}}>
                                    <h3 style={{fontSize:"22px",paddingTop:"16px"}}>{this.state.currentAmount+' '+this.state.currentCurrency}</h3>
                                </div>
                                <div className="col-xl-2 col-lg-2" style={{marginTop:"32px"}}>
                                    <span style={{right:"4px"}}><ArrowLeftRight color="#909BA1" size={30} /></span>
                                </div>
                                <div className="col-xl-5 col-lg-5">
                                <div className="under_convert_currency_div">
                                        <span>1 BTC = 99999 USD</span>
                                        <div className="wallet_deposit_filter" style={{display: 'inline-block'}}>
                                        <p className="wallet_select" onClick={() => this.viewConvertCurrency(!this.state.showCurrencyFilter,this.state.chosen_currency)} style={{cursor:"pointer"}}>{this.state.chosen_currency}
                                            {this.state.showCurrencyFilter ?
                                            (<span style={{right:"-20px"}}><CaretDownFill color="white" size={14} /></span>):
                                            (<span style={{right:"-20px"}}><CaretUpFill color="white" size={14} /></span>)}
                                        </p>
                                        {this.state.showCurrencyFilter ?
                                        (<div className="under_wallet_deposit_filter under_convert_wallet_deposit_filter">
                                            <ul style={{padding: 0,listStyle: 'none'}}>
                                            {this.state.cryptoCurrencies.map((currency,index) => (
                                                <li key={index} onClick={() => this.changeConvertCurrency(false,currency)}style={this.state.chosen_currency===currency ? {background:"#E5E5E5"} : {background:"white"}}><span style={{color:"#4f4e4e"}}>{currency}</span></li>
                                            ))}
                                            {this.state.fiatCurrencies.map((currency,index) => (
                                                <li key={index} onClick={() => this.changeConvertCurrency(false,currency)}style={this.state.chosen_currency===currency ? {background:"#E5E5E5"} : {background:"white"}}><span style={{color:"#4f4e4e"}}>{currency}</span></li>
                                            ))}
                                            {/* <li onClick={() => this.closeDepositCryptoModal(true)}>Crypto</li>
                                            <li onClick={() => this.fiatDiv(true)}>Fiat</li> */}
                                            </ul>
                                        </div>):('')}
                                        </div>
                                    </div><br/>
                                    <div>
                                        <input type="text" className="form-control convert_amount" onChange={this.convertCurrency} placeholder="Enter Amount"/>  
                                    </div>
                                    <div className="convert_currency_left">
                                        <p className="exchange_rate">Exchange Rate:- <span style={{color:"#0ec70e",fontWeight:"700",sontSize:"16px"}}>{this.state.exchangeValue}</span></p>
                                    </div>
                                    <button className="btn login_btn play_btn" onClick={()=>this.changeShowDiv('convert_success')}>Change and Play</button>
                                </div>
                            </div>
                        </div>
                    </div>}

                    {this.state.showDiv==='convert_success' &&
                    <div className="convert_currency_success">
                        <h4>Convert Success!</h4>
                        <h4>Current selected currency and wallet balance</h4>
                        <h3 style={{fontSize:"22px",paddingTop:"16px"}}>{this.state.currentVigobetCurrency+' '+this.state.currentVigobetAmount}</h3>
                        <button className="btn login_btn play_btnn" onClick={()=>this.props.history.push('/play_game#'+this.state.game_title)}>Start Playing</button>
                        <button className="btn login_btn play_btnn"  onClick={()=>this.changeShowDiv('convert_currency')}>Convert</button>
                    </div>}

                    {/*<div className="game_controls">
                        <div className="row" style={{width:"100%",margin:"0"}}>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <ul className="game_control_left">
                                    <li><span><Fullscreen color="white" size={14} /><br/></span>Full screen</li>
                                    <li><span><DashSquare color="white" size={14} /><br/></span>Minimum</li>
                                    <li><span><GraphUp color="white" size={14} /><br/></span>Live slots</li>
                                </ul>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <ul className="game_control_right">
                                    <li>Fun play</li>
                                    <li>
                                    <label className="switch">
                                        <input type="checkbox"/>
                                            <span className="slider round"></span>
                                        </label>
                                    </li>
                                    <li>Real play</li>
                                </ul>
                            </div>
                        </div>
                        </div><br/><br/>*/}
                        <br/><br/>


                    {/* games */}
                    <div>
                        <div className="container" style={{position:"relative",padding:"0"}}>
                            <div className="row justify-content-center" style={{margin:"0",width:"100%"}}>
                            <div className="col-md-12" style={{padding:"0"}}>
                                <p style={{color:'#DEE2E6',textAlign:"left",marginBottom:"0"}}>Recommended Games</p><br/>
                            </div>
                            </div>
                            <div className="row justify-content-center">
                            <div className="col-md-12">
                                <OwlCarousel className='owl-theme' loop margin={10} nav responsive={this.state.responsive}>
                                {this.state.games_data.map((games) => (
                                <div className='text-center' key={games.id}>
                                    <img alt={games.name} className="w-100" src={games.image} style={{borderRadius:"12px"}}/>   
                                </div>
                                ))}
                                </OwlCarousel>
                            </div>
                            </div>              
                        </div>
                    </div>
                    {/* games */}




                  </div>
                </div>
              </div>
            </div>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
        </div>
    );
  }
}

export default PickNewCurrency;
