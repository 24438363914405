import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import {Search} from 'react-bootstrap-icons'
const data = [
  {id: 1,bank:"Cbank",acc_name:"SUNAMI",acc_no:"7485444444",deposit:"2.00",withdr:"0.00",cost:"0.00",balance:"36.00"},
  {id: 2,bank:"Cbank",acc_name:"SUNAMI",acc_no:"7485444444",deposit:"2.00",withdr:"0.00",cost:"0.00",balance:"36.00"},
  {id: 3,bank:"Cbank",acc_name:"SUNAMI",acc_no:"7485444444",deposit:"2.00",withdr:"0.00",cost:"0.00",balance:"36.00"},
  {id: 4,bank:"Cbank",acc_name:"SUNAMI",acc_no:"7485444444",deposit:"2.00",withdr:"0.00",cost:"0.00",balance:"36.00"},
  {id: 5,bank:"Cbank",acc_name:"SUNAMI",acc_no:"7485444444",deposit:"2.00",withdr:"0.00",cost:"0.00",balance:"36.00"},
  {id: "Total",bank_name: "",acc_name:"",acc_no:"",deposit:"",withdr:"216.00",cost:"0.00",balance:"0.00"},
  {id: "Total Records: 22",bank_name: "",acc_name:"",acc_no:"",deposit:"",withdr:"216.00",cost:"0.00",balance:"0.00"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class BankStatement extends React.Component {
  render() {
    const columns = [
        {name: 'No.',selector: 'id',width:'150px'},
        {name: 'Bank',selector: 'bank',width:'100px'},
        {name: 'Bank Acc. Name',selector: 'acc_name',cell: row => <div data-tag="allowRowEvents" className="dntshow" style={{color: '#1890ff',cursor:'pointer'}}>{row.acc_name}</div>},
        {name: 'Bank Acc. No.',selector: 'acc_no'},
        {name: 'Total Deposit',selector: 'deposit'},
        {name: 'Total Withdrawal',selector: 'withdr'},
        {name: 'Total Cost',selector: 'cost'},
        {name: 'Balance',selector: 'balance'},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Bank management</span> / 
                    <span>Bank Statement</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Bank Statement</h5>
                </div>
              </div><br/>
              
                <div className="divbody">
                    <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                        <h6 style={{fontWeight:600,margin:0}}>Search Criteria</h6>
                    </div>
                    <div className="row member_listing" style={{margin:0,padding:'20px 14px',background:'white'}}>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                            <label className="labls"><span>Bank Account Name:</span> <input type="text" className="form-control input_width_100"/></label>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">    
                            <label className="labls"><span>Bank Account No.:</span> 
                                <input type="text" className="form-control input_width_100"/>
                            </label>
                        </div>    
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">    
                            <label className="labls"><input style={{marginTop: '3px',marginRight: '8px',width:"auto"}} type="checkbox"/> <span style={{padding:0}}>Show Transfer In Out</span></label>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">    
                            <label className="labls"><button className="btn btn-primary add_btn">Search</button>
                            <button className="btn btn-primary add_btn cancel_btn">Reset</button></label>
                        </div>
                    </div>
                </div>
                <div className="divbody">
                  <div className="trans_tabl bank_stmt_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <span className="subdvv1_1_1">
                        <input type="text" className="form-control search" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Please enter"/>
                        <Search color="#00000040" style={{top:'4px'}} size={15}/>
                    </span><br/><br/>
                    <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BankStatement;