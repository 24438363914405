import axios from 'axios';
export function makeRequest(request_url,request_method,data,auth) {
    var config = {
        method: request_method,
        //url: 'https://p33331740-u740-c3b195f8.api.tiancai.run/api/v1/'+request_url,
        //url: 'https://p33333740-u740-9a6b0972.api.tiancai.run/api/v1/'+request_url,
        url: 'https://p4010118-u740-baa6823e.api.tiancai.run/api/v1/'+request_url,
        headers: {
            'Content-Type': 'application/json',
            'auth':auth,
			'Access-Control-Allow-Origin':'*'
        },
        responseType: 'json',
        data : data
    };
    return new Promise(function (resolve, reject) {
        axios(config).then(
            function (response) {
                resolve(response)
            }
        ).catch(
            function (error) {
                console.log('Show error notification!',error.response)
                resolve(error.response);
            }
        )
    });
}
export function makeProviderRequest(request_url,request_method,data,auth) {
    var config = {
        method: request_method,
        url: 'https://p33332740-u740-e79d882d.api.tiancai.run/api/v1/'+request_url,
        headers: {
            'Content-Type': 'application/json',
            'auth':auth,
			'Access-Control-Allow-Origin':'*'
        },
        responseType: 'json',
        data : data
    };
    return new Promise(function (resolve, reject) {
        axios(config).then(
            function (response) {
                resolve(response)
            }
        ).catch(
            function (error) {
                console.log('Show error notification!',error.response)
                resolve(error.response);
            }
        )
    });
}
export function makeFileUploadRequest(request_url,request_method,data,auth) {
    var config = {
        method: request_method,
        //url: 'https://p33331740-u740-c3b195f8.api.tiancai.run/api/v1/'+request_url,
        url: 'https://p33333740-u740-9a6b0972.api.tiancai.run/api/v1/'+request_url,
        headers: { 
            'Content-Type': 'multipart/form-data',
            'auth':auth,
			'Access-Control-Allow-Origin':'*'
        },
        responseType: 'json',
        data : data
    };
    return new Promise(function (resolve, reject) {
        axios(config).then(
            (response) => {
                console.log('Processing Request',response);
                resolve(response);
            },
            (error) => {
                reject(error);
            }
        );
    });
}
export function handleKeyUp(e){
    var target = e.target;
    var maxLength = parseInt(target.attributes["maxlength"].value, 10);
    var myLength = target.value.length;
    if (myLength >= maxLength) {
        var next = target;
        while (next = next.nextElementSibling) {
            if (next === null)
                break;
            if (next.tagName.toLowerCase() === "input") {
                next.focus();
                break;
            }
        }
    }
}
export async function user_mailbox(that){
    let result1=await makeRequest('mailbox', 'get', '',localStorage.getItem('token'));
    if(result1.status===200){
      let data_arr = [];
      let jsonString=result1.data.data;
      for(let i = 0 ; i < (jsonString.length) ; i++) {
        data_arr.push({
        'id': jsonString[i].id,
        'subject': jsonString[i].subject,
        'date_time': timestampDate(jsonString[i].updatedAt),
        'isRead': jsonString[i].isRead,
        'detail': jsonString[i].detail,
        'recipient': jsonString[i].recipient,
        });
      }
      that.setState(state => ({mailData:data_arr}));
    }
}
export async function gameList(that){
    let result1=await makeRequest('game', 'get', '','');
    if(result1.status===200){
      that.setState(state => ({gameData:result1.data.data}));
      //console.log('gamesss',result1.data);
    }
}
export async function read_mail(id){
    await makeRequest('mailbox/'+id, 'get', '',localStorage.getItem('token'));
    let that=this
    user_mailbox(that);
}
export function currentDate(){
    const date = new Date();
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()]+', '+date.toLocaleString('default', { month: 'long' })+' '+date.toLocaleString('default',{day: 'numeric'})+', '+' '+date.getFullYear();
}
export function timestampDate(unixTimestamp){
    const date = new Date(unixTimestamp);
    return (date.toLocaleString('default',{day: 'numeric'})+' '+date.toLocaleString('default', { month: 'long' })+' '+date.getFullYear()+' - '+addZero(date.getHours())+date.getHours()+"."+date.getMinutes()+((date.getHours() >= 12) ? " PM" : " AM"))
}
export function timestampOnlyDate(unixTimestamp){
    const date = new Date(unixTimestamp);
    return (date.toLocaleString('default',{day: 'numeric'})+' '+date.toLocaleString('default', { month: 'long' })+' '+date.getFullYear())
}
export function timestampTime(unixTimestamp){
    const date = new Date(unixTimestamp);
    return (addZero(date.getHours())+date.getHours()+"."+date.getMinutes()+((date.getHours() >= 12) ? " PM" : " AM"))
}
export function timestampDate1(unixTimestamp){
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(unixTimestamp);
    return (days[date.getDay()]+' - '+addZero(date.getHours())+date.getHours()+"."+date.getMinutes()+((date.getHours() >= 12) ? " PM" : " AM"))
}
export function addZero(i) {
    if (i < 10) {
      i = "0";
    }else{
      i="";
    }
    return i;
}
/*export function api(request_url,request_method,data){
    var config = {
        method: request_method,
        url: request_url,
        headers: { 
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        data : data
    };
    let that=this;
    (async () => {
        await axios(config)
            .then(function (response) {
            that.setState({register_result: response.data});
            console.log('axios',that.state.register_result)
            return response.data
        })
        .catch(function (error) {
            console.log(error);
        });
        console.log('inner',this.state.register_result)
    })();
}*/