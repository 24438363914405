import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
const data = [
  {id: 1,member_id: "dclient03",member_group:"VIP1A",bet:"32.5",payout:"43.8",win_lose:"131.00",last_cash_back:"-",calculated_cash_back:"0.00",adjusted_cash_back:"",remark:""},
  {id: 2,member_id: "dclient03",member_group:"VIP1A",bet:"32.5",payout:"43.8",win_lose:"131.00",last_cash_back:"-",calculated_cash_back:"0.00",adjusted_cash_back:"",remark:""},
  {id: 3,member_id: "dclient03",member_group:"VIP1A",bet:"32.5",payout:"43.8",win_lose:"131.00",last_cash_back:"-",calculated_cash_back:"0.00",adjusted_cash_back:"",remark:""},
  {id: 4,member_id: "dclient03",member_group:"VIP1A",bet:"32.5",payout:"43.8",win_lose:"131.00",last_cash_back:"-",calculated_cash_back:"0.00",adjusted_cash_back:"",remark:""},
  {id: 5,member_id: "dclient03",member_group:"VIP1A",bet:"32.5",payout:"43.8",win_lose:"131.00",last_cash_back:"-",calculated_cash_back:"0.00",adjusted_cash_back:"",remark:""},
  {id: 6,member_id: "dclient03",member_group:"VIP1A",bet:"32.5",payout:"43.8",win_lose:"131.00",last_cash_back:"-",calculated_cash_back:"0.00",adjusted_cash_back:"",remark:""},
  {id: 7,member_id: "dclient03",member_group:"VIP1A",bet:"32.5",payout:"43.8",win_lose:"131.00",last_cash_back:"-",calculated_cash_back:"0.00",adjusted_cash_back:"",remark:""},
  {id: 8,member_id: "dclient03",member_group:"VIP1A",bet:"32.5",payout:"43.8",win_lose:"131.00",last_cash_back:"-",calculated_cash_back:"0.00",adjusted_cash_back:"",remark:""},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class MemberCashBack extends React.Component {
  render() {
    const columns = [
      {name: 'No.',selector: 'id',width:'50px',cell: row => <div data-tag="allowRowEvents"><input type="checkbox" style={{width:"auto"}}/> {row.id}</div>},
      {name: 'Member ID',selector: 'member_id',width:'80px',cell: row => <div data-tag="allowRowEvents">dclient03</div>},
      {name: 'Member Group',selector: 'member_group',width:'110px',cell: row => <div data-tag="allowRowEvents">VIP1A</div>},
      {name: 'Bet',selector: 'bet',width:'60px',cell: row => <div data-tag="allowRowEvents">32.5</div>},
      {name: 'Payout',selector: 'payout',width:'70px',cell: row => <div data-tag="allowRowEvents">43.84</div>},
      {name: 'Win/Lose',selector: 'win_lose',width:'70px',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>131.00</div>},
      {name: 'Last Cash Back End Time',width:'170px',selector: 'last_cash_back',cell: row => <div data-tag="allowRowEvents">-</div>},
      {name: 'Calculated Cash Back (SGD)',width:'180px',selector: 'calculated_cash_back',cell: row => <div data-tag="allowRowEvents">0.00</div>},
      {name: 'Adjusted Cash Back (SGD)',width:'180px',selector: 'adjusted_cash_back',cell: row => <div data-tag="allowRowEvents"><input className="form-control" style={{height:"30px"}}/></div>},
      {name: 'Remark',selector: 'remark',cell: row => <div data-tag="allowRowEvents"><input className="form-control" style={{height:"30px"}}/></div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Cash back</span> / 
                    <span>Member Cash Back</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Member Cash Back</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-12">
                    <div className="top_second_div">
                        <label className="labls"><span style={{minWidth:"88px"}}>Date Range:</span> 
                            <input type="date" className="form-control"/>
                        </label>
                        <label className="labls"><span style={{minWidth:"82px"}}>Account ID:</span> <input type="text" className="form-control"/></label>
                        <label className="labls"><span style={{minWidth:"102px"}}>Member Group:</span> 
                            <select className="form-control" style={{width:"148px"}}>
                                <option>All</option>
                            </select>
                        </label>
                    </div><br/>
                    <div className="top_second_div_member">
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Calculate Rebate</button>
                        <button className="btn btn-primary add_btn cancel_btn">Reset</button>
                        <button className="btn btn-primary add_btn cancel_btn third_btn" style={{width:'auto'}}>Submit Member Rebate</button>
                        <span style={{verticalAlign: 'middle',fontSize: '14px'}}><input type="checkbox" style={{marginLeft:'12px',width:"auto"}}/> <span style={{verticalAlign:'text-bottom'}}>Auto Approve</span></span>
                    </div>
                </div>
              </div><br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <span className="subdvv1_1_1">
                    <input type="text" className="form-control" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Please enter"/>
                    <Search color="#00000040" style={{top:'4px'}} size={15}/>
                </span>
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MemberCashBack;