import React from "react";
import { makeRequest, timestampOnlyDate } from '../helpers'

class LevelDets extends React.Component {
  constructor(props) {
    super(props);
    this.viewdets = this.viewdets.bind(this)
	  this.makeRequest = makeRequest
  }
  state= {
    showDetsModal:false,
	  totalWalletBalance:'',
  }
  viewdets(val){
    this.setState(state => ({
        showDetsModal: val,
    }));
  }
  render() {
    const wallet_balance=this.props.balance;
    return (
        <div className="walletdiv">
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3 myaccountdiv1">
                    <img alt="crown_icon" src={'assets/goldcrown.png'}/>
                    <div className="divv1">
                        <span>VIP Level</span><br/>
                        <span>NORMAL</span>
                    </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-9 myaccountdiv1">
                    <div>                                    
                        <div className="divv1">
                            <span>Deposit Required For Upgrade</span><br/>
                            <span>USD {wallet_balance}</span>
                        </div>
                        <div style={{float:'right',paddingRight:'16px'}}>
                            <div className="divv1">
                                <span>Turnover Requirement for Upgrade</span><br/>
                                <span>USD {wallet_balance}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-12">
                    <div className="divv1">
                        <span>Reg. Date</span><br/>
                        <span>{localStorage.getItem('userInfo')!==undefined && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!=='' ?timestampOnlyDate((JSON.parse(localStorage.getItem('userInfo'))).createdAt):''}</span>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default LevelDets;
