import React from "react";
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.ppModal = this.ppModal.bind(this)
  }
  state= {
    show_ppModal:false,
    content:''
  }
  ppModal(val,content){
    this.setState({show_ppModal: val,content:content})
  }
  render() {
    return (
        <div>
          <div style={{background:'#001A2C',padding:'16px 0'}}>
            <nav className="navbar-expand-lg container thrd_nav footthrdnav text-white footer_nav1">
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex footer_ul1" style={{marginLeft:'auto',justifyContent: 'space-between'}}>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    <img alt="nav game1" src={'assets/Group3.png'}/>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    <img alt="nav game2" src={'assets/dribbble.png'}/>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    <img alt="nav game3" src={'assets/Group4.png'}/>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    <img alt="nav game4" src={'assets/Group8.png'}/>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    <img alt="nav game5" src={'assets/Group5.png'}/>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    <img alt="nav game6" src={'assets/Group6.png'}/>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div style={{background:'#000000',padding:'26px 0',color:'#8c8c8c'}}>
            {/* <nav className="navbar-expand-lg container footer_nav2">
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex footer_ul" style={{marginLeft:'auto',justifyContent: 'space-around'}}>
                <li className="nav-item">Slot Club</li>
                <li className="nav-item">4D Lottery</li>
                <li className="nav-item">Live Casino</li>
                <li className="nav-item">Sportsbook</li>
                <li className="nav-item">How to Deposit</li>
                <li className="nav-item">How to Transfer</li>
                <li className="nav-item">How to Withdrawl</li>
              </ul>
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex footer_ul2" style={{marginLeft:'auto',justifyContent: 'center',marginTop:'24px'}}>
                <li className="nav-item">About Us</li>
                <li className="nav-item">Ranking</li>
                <li className="nav-item">Prediction</li>
                <li className="nav-item">Privacy Policy</li>
                <li className="nav-item">Contact Us</li>
              </ul>
            </nav> */}

            <ul className="footer_ul_new justify-content-center">
              <li className=""><Link to="/slots">Slot Club</Link></li>
              <li onClick={() => this.ppModal(true,'4D Lottery')}>4D Lottery</li>
              <li className=""><Link to="/live_casino">Live Casino</Link></li>
              <li onClick={() => this.ppModal(true,'Sportsbook')}>Sportsbook</li>
              <li onClick={() => this.ppModal(true,'How to Deposit')}>How to Deposit</li>
              <li onClick={() => this.ppModal(true,'How to Transfer')}>How to Transfer</li>
              <li onClick={() => this.ppModal(true,'How to Withdrawl')}>How to Withdrawl</li>
              <br/>
              <li className=""><Link to="/about_us">About Us</Link></li>
              <li onClick={() => this.ppModal(true,'Ranking')}>Ranking</li>
              <li onClick={() => this.ppModal(true,'Prediction')}>Prediction</li>
              <li onClick={() => this.ppModal(true,'Privacy Policy')}>Privacy Policy</li>
              <li className="">Contact Us</li>
            </ul><br/>
            <Modal size="lg" show={this.state.show_ppModal} className="pwd_changed_modal bank_account_modal wallet_modal">
              <Modal.Header style={{background:'none'}} closeButton onClick={() => this.ppModal(false,'')}></Modal.Header>
              <Modal.Body>
                <div className="">
                  <h2 style={{fontSize: '30px',fontWeight: 600,margin: 0}} className="text-white text-center">{this.state.content}</h2>
                  <div className="text-center"><img alt="line" src={'assets/aboutus.png'}/></div><br/>
                  <p>GboBet is today one of the leading suppliers of online gaming products worldwide, offering a wide range of first-class online gambling products and games in a safe and user-friendly gaming environment. GboBet has been able establish themselves as one of the most trusted and reliable gaming companies in the market nowadays.</p>
                  <p>GboBet offers its customers a wide selection of innovative products such as Live Casino, Sports Betting and slots games. In our aim to provide the best service online we also offer the unique and exclusive support guarantee, which applies 24 hours a day, 7 days a week. Our support team is available through e-mail, live chat and telephone. Please visit the support section for further details.</p>
                  <p>With a solid product range and unique customer support, GboBet becomes the obvious choice for gaming enthusiasts all over the world!</p>
                  <p>Thank you for choosing Gbobet.com as your preferred choice of online gaming. We hope you enjoy playing with us, and if there is anything we can help you with please do not hesitate to contact us.</p>
                </div>
              </Modal.Body>
            </Modal>
            <div className="text-center footer_logo_div" style={{position:'relative'}}>
              <img alt="footer_logo" className="foot_logo lg_foot_logo" src={'assets/vigobet-lion.png'}/>
              <img alt="footer_logo" className="foot_logo sm_foot_logo" src={'assets/footer_logo_sm.png'}/>
            </div><br/>
            <div className="text-center">
              <p style={{fontSize:'12px'}}>© 2021 VIGO All Rights Reserved</p>
            </div>
          </div>
        </div>
    );
  }
}

export default Footer;
