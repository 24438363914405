import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
//import { Search } from 'react-bootstrap-icons';
const data = [
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},
  {product_name: "GAMEPLAY DIRECT",product_type:"Card & Board Games",cash_back:""},

];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '13px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'800'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'700!important'
    },
  },
};
class EditCashBack extends React.Component {
  render() {
    const columns = [
      {name: 'Product Name',selector: 'id',width:"300px",cell: row => <div data-tag="allowRowEvents"> GAMEPLAY DIRECT </div>},
      {name: 'Product Type',selector: 'member_name',width:"400px",cell: row => <div data-tag="allowRowEvents">Card & Board Games</div>},
      {name: 'Cash Back (%)',selector: 'status',cell: row => <div data-tag="allowRowEvents"><input type="text" className="form-control" style={{height:"30px"}}/></div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 24px 0 0'}}>
              <div style={{background: 'white',marginBottom:"12px"}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Cash back</span> / 
                    <span> Cash Back Setting</span> /
                    <span>Edit Cash Back</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Edit Cash Back</h5><br></br>
                    <div>
                    <Link to="/admin/cash_back_setting"><button className="btn btn-primary add_btn" style={{width:'auto'}}>Back To Cash Back Setting</button></Link>
                    </div>
                </div>
              </div>
            </div>
            <div className="divbody">
              <div className="trans_tabl" style={{margin:0,padding:'4px 14px',background:'white'}}>
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EditCashBack;