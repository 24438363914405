import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import { List,Search,XCircleFill } from 'react-bootstrap-icons';
import { makeProviderRequest,timestampDate } from '../../helpers';

class DepositChannelDets extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.makeProviderRequest = makeProviderRequest
  }
  state={
    channelDetail:[],
    owner_id:'',
  }
	componentDidMount() {
    const url_params=window.location.hash.split('#');
    const owner_id=url_params[2];
    const channel_id=url_params[1];
    (async () => {
		  var result1=await this.makeProviderRequest('owner/'+owner_id+'/deposit-channel/'+channel_id, 'get','',localStorage.getItem('role_token'));
		  if(result1.status===200){
			  this.setState({channelDetail: result1.data})
        this.setState({owner_id: owner_id})
		  }
		})();
	} 
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0,height:"100vh"}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 24px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Deposit Channel</span> / 
                    <span>Deposit channel detail</span>
                  </div>
                  <h5 className="pg_head">Deposit channel detail</h5>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              {/* Informations */}
              <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:700,margin:0}}>Informations
                  <div style={{float:'right',marginTop:'-6px'}}>
                    <button className="btn btn-primary add_btn" style={{width:'auto'}} onClick={()=>this.props.history.push('/admin/platform_owner_detail#'+this.state.owner_id)}>Back</button>
                  </div>
                </h6>
              </div>
              <div className="row" style={{margin:0,background:'white',width:"100%"}}>
                <div className={this.state.channelDetail.type ==='wire' ? "col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" : "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"} style={{padding:0}}>
                  <table className="table infotable" style={{margin:0}}>
                    <tbody>
                      <tr>
                        <td>Created At</td><td>{timestampDate(this.state.channelDetail.createdAt)}</td>
                      </tr>
                      <tr>
                        <td>Bank Name</td><td>{this.state.channelDetail.bankName}</td>
                      </tr>
                      <tr>
                        <td>Account Name</td><td>{this.state.channelDetail.accountName}</td>
                      </tr>
                      <tr>
                        <td>Account Number</td><td>{this.state.channelDetail.accountNumber}</td>
                      </tr>
					            <tr>
                        <td>Type</td><td>{this.state.channelDetail.type}</td>
                      </tr>
                      <tr>
                        <td>Minimum Deposit</td><td>{this.state.channelDetail.minimumDeposite}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" style={{padding:0}}>
                    {this.state.channelDetail.type ==='wire' ?
                    (<table className="table infotable" style={{margin:0}}>
                        <tbody>
                        <tr>
                            <td>Bank address</td><td>{this.state.channelDetail.bankAddress}</td>
                        </tr>
                        <tr>
                            <td>Account address</td><td>{this.state.channelDetail.accountAddress}</td>
                        </tr>
                        <tr>
                            <td>Channel name</td><td>{this.state.channelDetail.channelName}</td>
                        </tr>
                        <tr>
                            <td>Swift</td><td>{this.state.channelDetail.swift}</td>
                        </tr>
                        <tr style={{height:"41px"}}>
                            <td className="td_remove_after"></td><td></td>
                        </tr>
                        </tbody>
                    </table>):('')}
                </div>
              </div>
              <br/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DepositChannelDets;
