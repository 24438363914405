import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import {ChevronUp,Search,Dot} from 'react-bootstrap-icons';
import { makeRequest,timestampDate } from '../../helpers';
const data = [
  {id: 1,product: "Total Balance",bal:"75.00"},{id: 2,product: "POKER 8",bal:"0.00"},{id: 3,product: "IA SPORT",bal:"5.00"},{id: 4,product: "PRETTY GAMING",bal:"2.00"},{id: 5,product: "BOSPORT",bal:"2.00"},{id: 6,product: "VR LOTTO",bal:"2.00"},{id: 7,product: "JOKER",bal:"2.00"},
];
const data2 = [
  {id: 1,trans_dt: "2021-03-13 12:03:07",trans_type:"Deposit",turn_mult:"1",total:"0.00",fees:"0.00",dr:"-",cr:"25.50",remark:"1234"},
  {id: 2,trans_dt: "2021-03-13 12:03:07",trans_type:"Deposit",turn_mult:"1",total:"0.00",fees:"0.00",dr:"-",cr:"25.50",remark:"1234"},
  {id: "Subtotal",trans_dt: "",trans_type:"",turn_mult:"",total:"",fees:"0.00",dr:"-",cr:"25.50",remark:""},
  {id: "Total Records: 2",trans_dt: "",trans_type:"",turn_mult:"",total:"",fees:"0.00",dr:"-",cr:"25.50",remark:""},
];
const data3 = [
  {id: 1,trans_dt: "2021-03-13 12:03:07",trans_type:"Deposit",trans_id:"11699",total:"10.00",amount:"25.50",remark:"1234"},
  {id: 2,trans_dt: "2021-03-13 12:03:07",trans_type:"Deposit",trans_id:"11699",total:"10.00",amount:"25.50",remark:"1234"},
  {id: 3,trans_dt: "2021-03-13 12:03:07",trans_type:"Deposit",trans_id:"11699",total:"10.00",amount:"25.50",remark:"1234"},
  {id: 4,trans_dt: "2021-03-13 12:03:07",trans_type:"Deposit",trans_id:"11699",total:"10.00",amount:"25.50",remark:"1234"},
];
const data4 = [
  {id: 1,trans_dt: "2021-03-13 12:03:07",from:"Main",bal_bef:"21.30",bal_af:"0.00",amount:"25.50",status:"Success"},
  {id: 2,trans_dt: "2021-03-13 12:03:07",from:"Main",bal_bef:"21.30",bal_af:"0.00",amount:"25.50",status:"Success"},
  {id: 3,trans_dt: "2021-03-13 12:03:07",from:"Main",bal_bef:"21.30",bal_af:"0.00",amount:"25.50",status:"Success"},
  {id: 4,trans_dt: "2021-03-13 12:03:07",from:"Main",bal_bef:"21.30",bal_af:"0.00",amount:"25.50",status:"Success"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class MemberDetails extends React.Component {  
  constructor(props) {
    super(props);
	this.makeRequest = makeRequest
    this.changeTab = this.changeTab.bind(this)
  }
  state= {
    currentTab:'account',
	member_details:[],
  }
  changeTab(val){
    this.setState(state => ({currentTab: val}));
  }
	componentDidMount() {
		let id=window.location.hash.substr(1);
		(async () => {
		  var result1=await this.makeRequest('user/'+id, 'get','',localStorage.getItem('role_token'));
		  if(result1.status===200){
			  console.log(result1.data);
			this.setState({member_details: result1.data});
		  }
		})();
	} 
  render() {
    const columns = [
      {name: 'Product',cell: row => <div data-tag="allowRowEvents"><input type="checkbox" style={{width:"auto"}}/> <span>{row.product}</span></div>},
      {name: 'Balance (SGD)',selector: 'bal'},
      {name: 'Username',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}><span className="actionn" style={{cursor:'pointer'}}>Click To View</span></div>},
    ];
    const columns2 = [
        {name: 'No.',selector: 'id',width:'130px'},
        {name: 'Trans. Dt.',selector: 'trans_dt'},
        {name: 'Trans. ID',cell: row => <div data-tag="allowRowEvents" className="dntshow" style={{color: '#1890ff',cursor:'pointer'}}>11669</div>},
        {name: 'Trans. Type',selector: 'trans_type'},
        {name: 'Status',cell: row => <div data-tag="allowRowEvents" className="dntshow" style={{marginLeft: '-14px'}}><Dot color="#1890FF" size={28}/>Processing</div>,width:'108px'},
        {name: 'Turnover Multiply',selector: 'turn_mult'},
        {name: 'Current Rollover',selector: 'total'},
        {name: 'Bal. Before',selector: 'total'},
        {name: 'Bal. After',selector: 'total'},
        {name: 'Dr.',selector: 'dr',width:'50px'},
        {name: 'Cr.',selector: 'cr',width:'64px'},
        {name: 'Fees',selector: 'fees',width:'50px'},
        {name: 'Remark',selector: 'remark'},
    ];
    const columns3 = [
        {name: 'No.',selector: 'id',width:'50px'},
        {name: 'Transaction Date',selector: 'trans_dt'},
        {name: 'Trans. ID',selector:'trans_id'},
        {name: 'Trans. Type',selector: 'trans_type'},
        {name: 'Balance Before',selector: 'total'},
        {name: 'Balance After',selector: 'total'},
        {name: 'Amount',selector: 'amount'},
        {name: 'Remark',selector: 'remark'},
    ];
    const columns4 = [
        {name: 'No.',selector: 'id',width:'50px'},
        {name: 'Trans. Dt.',selector: 'trans_dt'},
        {name: 'Trans. ID',cell: row => <div data-tag="allowRowEvents" className="dntshow" style={{color: '#1890ff',cursor:'pointer'}}>11669</div>,width:"86px"},
        {name: 'From',selector: 'from',width:"58px"},
        {name: 'Bal. Before(From)',selector: 'bal_bef'},
        {name: 'Bal. After(From)',selector: 'bal_af'},
        {name: 'To',selector: 'from',width:"58px"},
        {name: 'Bal. Before(To)',selector: 'bal_bef'},
        {name: 'Bal. After(To)',selector: 'bal_af'},
        {name: 'Transfer Amount',selector: 'amount'},
        {name: 'Status',selector: 'status',width:"80px"},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Member management</span> / 
                    <span>Member Details</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Member Details</h5>
                </div>
              </div>
            </div>
            <div className="divbody" style={{background:'white'}}>
              <div className="row membdets">
                <div className="col-xl-1 col-lg-2 col-md-3 col-sm-3">
                    <img alt="placeholder" src={'../assets/user_placeholder.png'}/>
                </div>
                <div className="col-xl-11 col-lg-10 col-md-9 col-sm-9">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <span className="namm">{this.state.member_details.username}</span>
                                <button className="btn btn-primary addbtn" style={{background: '#272829',marginRight: '12px',boxShadow: 'none',outline: 0}} onClick={()=>this.props.history.push('/admin/member_listing')}>Back</button>
                                {/*<button className="btn btn-primary addbtn">Edit Account Details</button>*/}
                            </div>
                            <span className="idd">#002</span>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <span>Full Name: {this.state.member_details.fullname}</span>
                            <span>Email: {this.state.member_details.email}</span>
                            <span>Phone: {this.state.member_details.phone} 
							{this.state.member_details.phone !==null ?
							(<span>{this.state.member_details.isPhoneNumberVerified === false ? '(Not verified)' : '(Verified)'}</span>):(<span></span>)
							}</span>
							<br/>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <span>Date of Birth: {this.state.member_details.dateOfBirth!==null? timestampDate(this.state.member_details.dateOfBirth) :''}</span>
                            <span>Gender: </span>
                            <span>Country/Region: </span>
                            <span>Address: </span><br/>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <span>Create date: {timestampDate(this.state.member_details.createdAt)}</span>
                            <span>Last online: {this.state.member_details.lastLogin!==null ? timestampDate(this.state.member_details.lastLogin):''}</span>
                            <span>Last online IP: {this.state.member_details.lastLoginIp}</span>
                            <span>Register IP: </span><br/>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <span>Remark:</span>
                            <span> </span><br/>
                        </div>
                    </div>
                </div>
              </div>
            </div><br/>
            <div className="divbody" style={{paddingLeft: 0,paddingRight: 0}}>
                <div className="subbdvv1 sd1">
                    <ul className="tabbss">
                        <li className={this.state.currentTab==='account' ? 'active' : ''} onClick={()=>this.changeTab('account')}>Account overview</li>
                        <li className={this.state.currentTab==='wallet_transfer' ? 'active' : ''} onClick={()=>this.changeTab('wallet_transfer')}>Wallet/Transfer</li>
                        <li className={this.state.currentTab==='deposit_withdr' ? 'active' : ''} onClick={()=>this.changeTab('deposit_withdr')}>Deposit/Withdrawal</li>
                        <li className={this.state.currentTab==='adjustment' ? 'active' : ''} onClick={()=>this.changeTab('adjustment')}>Adjustment</li>
                        <li className={this.state.currentTab==='reset_pwd' ? 'active' : ''}>Reset Password</li>
                        <li className={this.state.currentTab==='off_record' ? 'active' : ''} onClick={()=>this.changeTab('off_record')}>Off record Adjustment</li>
                        <li className={this.state.currentTab==='bonus_adj' ? 'active' : ''} onClick={()=>this.changeTab('bonus_adj')}>Bonus Adjustment</li>
                        <li className={this.state.currentTab==='trans_hist' ? 'active' : ''} onClick={()=>this.changeTab('trans_hist')}>Transaction History</li>
                        <li className={this.state.currentTab==='trans_report' ? 'active' : ''} onClick={()=>this.changeTab('trans_report')}>Transfer Report</li>
                        <li className={this.state.currentTab==='stmt' ? 'active' : ''} onClick={()=>this.changeTab('stmt')}>Statement</li>
                    </ul>
                </div>
            </div>
            {this.state.currentTab==='account' &&
                <div className="divbody">
                    <div className="row">
                        <div className="colss col-md-3">
                            <div className="maindivv">
                                <div className="md1">
                                    <h6>Status</h6>
                                </div>
                                <div className="md2">
                                    <label>Status:
                                        <select className="form-control" value={this.state.member_details.status}>
                                            <option value="">All</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                            <option value="blocked">Blocked</option>
                                        </select>
                                    </label>
                                    <label>Pause Bet:
                                        <select className="form-control" value={this.state.member_details.betStatus}>
                                            <option>All</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                            <option value="blocked">Blocked</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="colss col-md-3">
                            <div className="maindivv">
                                <div className="md1">
                                    <h6>VIP Level</h6>
                                </div>
                                <div className="md2">
                                    <label>Level: {this.state.member_details.memberGroup!==null ? this.state.member_details.memberGroup : ''}</label>
                                    <label><button className="btn btn-primary addbtn comnbluecolor">View Detail</button></label>
                                </div>
                            </div>
                        </div>
                        <div className="colss col-md-3">
                            <div className="maindivv">
                                <div className="md1">
                                    <h6>Referral</h6>
                                </div>
                                <div className="md2">
                                    <span>Referred By: </span><br/>
                                    <span>Referral slug: {this.state.member_details.referralUsername}</span><br/><br/>
                                    <label><button className="btn btn-primary addbtn comnbluecolor">View Detail</button></label>
                                </div>
                            </div>
                        </div>
                        <div className="colss col-md-3">
                            <div className="maindivv">
                                <div className="md1">
                                    <h6>Agent</h6>
                                </div>
                                <div className="md2">
                                    <span>Agent Upline: -</span><br/>
                                    <span>Agent code: -</span><br/>
                                    <span>Agent type: -</span><br/><br/>
                                    <label><button className="btn btn-primary addbtn comnbluecolor">View Detail</button></label>
                                </div>
                            </div>
                        </div>
                        <div className="colss col-md-3">
                            <div className="maindivv">
                                <div className="md1">
                                    <h6>Bank Details</h6>
                                </div>
                                <div className="md2">
                                    <span>Bank: -</span><br/>
                                    <span>Accoune name: -</span><br/>
                                    <span>Account no.: -</span><br/>
                                    <span>Bank Branch: -</span><br/><br/>
                                    <label><button className="btn btn-primary addbtn comnbluecolor">View Detail</button></label>
                                </div>
                            </div>
                        </div>
                        <div className="colss col-md-3">
                            <div className="maindivv">
                                <div className="md1">
                                    <h6>Member transaction details</h6>
                                </div>
                                <div className="md2">
                                    <span>Total deposit: -</span><br/>
                                    <span>Total withdrawal: -</span><br/>
                                    <span>Total promotion: -</span><br/>
                                    <span>Last transaction date: -</span><br/>
                                    <span>Rebate turnover requirement: -</span><br/>
                                    <span>Turnover requirement: -</span><br/>
                                    <span>Current rollover: -</span><br/><br/>
                                    <label><button className="btn btn-primary addbtn comnbluecolor">View Detail</button></label>
                                </div>
                            </div>
                        </div>
                        <div className="colss col-md-3">
                            <div className="maindivv">
                                <div className="md1">
                                    <h6>Member KYC</h6>
                                </div>
                                <div className="md2">
                                    <span>ID verification: -</span><br/><br/>
                                    <label><button className="btn btn-primary addbtn comnbluecolor">View Detail</button></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {this.state.currentTab==='wallet_transfer' &&
                <div>
                    <div className="divbody">
                        <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                            <h6 style={{fontWeight:600,margin:0}}>Make Transfer</h6>
                        </div>
                        <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                            <div className="col-md-12">
                                <div>
                                    <label className="labls transf_labls"><span>From:</span> 
                                        <select className="form-control">
                                            <option>Select</option>
                                        </select>
                                    </label>
                                    <label className="labls transf_labls"><span>To:</span> 
                                        <select className="form-control">
                                            <option>Bank Account Name</option>
                                        </select>
                                    </label>
                                    <label className="labls transf_labls"><span>Amount:</span> 
                                        <input className="form-control" placeholder="Amount"/>
                                    </label>
                                    <label className="labls"><button className="btn btn-primary add_btn">Make Transfer</button></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divbody">
                        <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                            <h6 style={{fontWeight:600,margin:0}}>Member Token</h6>
                        </div>
                        <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                            <div className="col-md-4">
                                <div className="add_form_div wallettr_div">
                                    <span>Available Token: </span>
                                    <input type="text" placeholder="0" readonly className="form-control"/>
                                </div><br/>
                                <div className="add_form_div wallettr_div">
                                    <span>Token Adjustment：</span>
                                    <input type="text" placeholder="0" className="form-control"/>
                                </div><br/>
                            </div>
                            <div className="col-md-6">
                                <div className="add_form_div wallettr_div">
                                    <span>Remark: </span>
                                    <textarea placeholder="Remark" rows="3" className="form-control" style={{marginRight:'32px'}}/>
                                    <button className="btn btn-primary add_btn">Submit</button>
                                </div><br/>
                            </div>
                        </div>
                    </div>
                    <div className="divbody">
                        <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                            <h6 style={{fontWeight:600,margin:0}}>Member Wallet</h6>
                        </div>
                        <div className="row" style={{margin:0,padding:'0 8px',background:'white'}}>
                            <div style={{marginTop:'20px'}}>
                                <button className="btn btn-primary add_btn cancel_btn">Refresh</button>
                                <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}}>Transfer All</button>
                                <button className="btn btn-primary add_btn cancel_btn">Blocked</button>
                            </div>
                            <div className="trans_tabl wallet_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {this.state.currentTab==='deposit_withdr' &&
                <div className="row" style={{margin:0}}>
                    <div className="col-md-6">
                        <div className="divbody">
                            <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                                <h6 style={{fontWeight:600,margin:0}}>Deposit</h6>
                            </div>
                            <div style={{padding:'20px 20px',background:'white'}}>
                                <div className="add_form_div create_agent_div deposit_withdr_div">
                                    <span>Main Wallet Balance (SGD) </span>
                                    <input type="text" placeholder="0.00" className="form-control"/>
                                </div><br/>
                                <div className="add_form_div create_agent_div deposit_withdr_div">
                                    <span>Bank</span>
                                    <select className="form-control">
                                        <option>Select</option>
                                    </select>
                                </div><br/>
                                <div className="add_form_div create_agent_div deposit_withdr_div">
                                    <span>Product</span>
                                    <select className="form-control">
                                        <option>Main Wallet</option>
                                    </select>
                                </div><br/>
                                <div className="add_form_div create_agent_div deposit_withdr_div">
                                    <span>Amount (SGD) </span>
                                    <input type="text" placeholder="Amount" className="form-control"/>
                                </div><br/>
                                <div className="add_form_div create_agent_div deposit_withdr_div">
                                    <span>Promotion</span>
                                    <select className="form-control">
                                        <option>Promotion</option>
                                    </select>
                                </div><br/>
                                <div className="add_form_div create_agent_div deposit_withdr_div">
                                    <span>Remark</span>
                                    <textarea placeholder="Remark" className="form-control"/>
                                </div><br/>
                                <div className="add_form_div create_agent_div deposit_withdr_div">
                                    <span>Auto Approve</span>
                                    <input style={{marginTop:'11px',width:"auto"}} type="checkbox"/>
                                </div><br/>
                                <div style={{textAlign:'right'}}>
                                    <button className="btn btn-primary add_btn">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="divbody" style={{height:'100%'}}>
                            <div style={{height:'100%',background:'white'}}>
                                <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                                    <h6 style={{fontWeight:600,margin:0}}>Withdrawal</h6>
                                </div>
                                <div style={{padding:'20px 20px',background:'white'}}>
                                    <div className="add_form_div create_agent_div deposit_withdr_div">
                                        <span>Main Wallet Balance (SGD) </span>
                                        <input type="text" placeholder="0.00" className="form-control"/>
                                    </div><br/>
                                    <div className="add_form_div create_agent_div deposit_withdr_div">
                                        <span>Bank</span>
                                        <select className="form-control">
                                            <option>Select</option>
                                        </select>
                                    </div><br/>
                                    <div className="add_form_div create_agent_div deposit_withdr_div">
                                        <span>Bank Account Name</span>
                                        <input type="text" placeholder="" className="form-control"/>
                                    </div><br/>
                                    <div className="add_form_div create_agent_div deposit_withdr_div">
                                        <span>Bank Account No.</span>
                                        <input type="text" placeholder="" className="form-control"/>
                                    </div><br/>
                                    <div className="add_form_div create_agent_div deposit_withdr_div">
                                        <span>Amount </span>
                                        <input type="text" placeholder="Amount" className="form-control"/>
                                    </div><br/>
                                    <div style={{textAlign:'right'}}>
                                        <button className="btn btn-primary add_btn">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {this.state.currentTab==='adjustment' &&
                <div className="row" style={{margin:0}}>
                    <div className="col-md-12">
                        <div className="divbody">
                            <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                                <h6 style={{fontWeight:600,margin:0}}>Adjustment</h6>
                            </div>
                            <div className="row" style={{background: 'white',margin: 0}}>
                                <div className="col-md-6">
                                    <div style={{padding:'20px 20px',background:'white'}}>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Balance (SGD) </span>
                                            <input type="text" placeholder="75.00" readonly className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Record Type</span>
                                            <select className="form-control">
                                                <option>Credit</option>
                                            </select>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Amount (SGD) </span>
                                            <input type="text" placeholder="Amount" className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Remark</span>
                                            <textarea placeholder="Remark" className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Auto Approve</span>
                                            <input style={{marginTop:'11px',width:"auto"}} type="checkbox"/>
                                        </div><br/>
                                        <div>
                                            <button className="btn btn-primary add_btn">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {this.state.currentTab==='bonus_adj' &&
                <div className="row" style={{margin:0}}>
                    <div className="col-md-12">
                        <div className="divbody">
                            <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                                <h6 style={{fontWeight:600,margin:0}}>Bonus Adjustment</h6>
                            </div>
                            <div className="row" style={{background: 'white',margin: 0}}>
                                <div className="col-md-6">
                                    <div style={{padding:'20px 20px',background:'white'}}>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Deposit Amount </span>
                                            <input type="text" placeholder="75.00" disabled className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Promotion</span>
                                            <select className="form-control">
                                                <option>Credit</option>
                                            </select>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Promo Amount </span>
                                            <input type="text" placeholder="Amount" className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Turnover </span>
                                            <input type="text" placeholder="75.00" disabled className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Turnover Multiply</span>
                                            <input type="text" placeholder="75.00" disabled className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Current Accumulated Turnover</span>
                                            <input type="text" placeholder="75.00" disabled className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Minimum Deposit</span>
                                            <input type="text" placeholder="75.00" disabled className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Max. Promo Amount</span>
                                            <input type="text" placeholder="75.00" disabled className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Max. Promo Amount</span>
                                            <input name="promo_amount" defaultValue="yes" type="radio" style={{marginTop: '5px',marginRight: '4px',width:"auto"}}/>Yes
                                            <input name="promo_amount" defaultValue="no" type="radio" style={{marginLeft: '28px',marginTop: '5px',marginRight: '4px',width:"auto"}}/>No
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Accumulated Turnover</span>
                                            <input style={{marginTop:'11px',width:"auto"}} type="checkbox"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span style={{width: '112px'}}>Reset Rebate Turnover Requirement</span>
                                            <input style={{marginTop:'11px',width:"auto"}} type="checkbox"/>
                                        </div><br/>
                                        <div className="add_form_div create_agent_div deposit_withdr_div">
                                            <span>Remark</span>
                                            <textarea placeholder="Remark" className="form-control"/>
                                        </div><br/>
                                        <div>
                                            <button className="btn btn-primary add_btn">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {this.state.currentTab==='off_record' &&
                <div className="row" style={{margin:0}}>
                    <div className="col-md-12">
                        <div className="divbody">
                            <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                                <h6 style={{fontWeight:600,margin:0}}>Off Record Adjustment </h6>
                            </div>
                            <div className="row" style={{background: 'white',margin: 0}}>
                                <div className="col-md-6">
                                    <div style={{padding:'20px 20px',background:'white'}}>
                                        <div className="add_form_div">
                                            <span>Balance (SGD): </span>
                                            <input type="text" placeholder="75.00" readonly className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div">
                                            <span>Record Type:</span>
                                            <select className="form-control">
                                                <option>Credit</option>
                                            </select>
                                        </div><br/>
                                        <div className="add_form_div">
                                            <span>Amount (SGD): </span>
                                            <input type="text" placeholder="Amount" className="form-control"/>
                                        </div><br/>
                                        <div className="add_form_div">
                                            <span>Remark:</span>
                                            <textarea placeholder="Remark" className="form-control"/>
                                        </div><br/>
                                        <div>
                                            <button className="btn btn-primary add_btn">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {this.state.currentTab==='trans_hist' &&
                <div className="row" style={{margin:0}}>
                    <div className="col-md-12">
                        <div className="divbody">
                            <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                                <h6 style={{fontWeight:600,margin:0}}>Transaction History</h6>
                            </div>
                            <div className="row" style={{background: 'white',padding:'20px 14px',margin: 0}}>
                                <div className="col-md-2">
                                    <span className="checkspan"><span>Transaction Type: </span></span>
                                </div>
                                <div className="col-md-10">
                                    <div>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Deselect All</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Deposit</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Withdraw</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Promotion</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Adj. In</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Adj. Out</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Rebate</span>
                                        </span>
                                    </div><br/>
                                    <div>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Deposit</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Cash back</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Voucher</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Referrer Rebate</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="dashdbordr"></div>
                            <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                                <div className="col-md-2">
                                    <span className="checkspan"><span>Status: </span></span>
                                </div>
                                <div className="col-md-10">
                                    <div>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Deselect All</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>New Request</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Processing</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Confirmed</span>
                                        </span>
                                        <span className="checkspan twos">
                                            <input type="checkbox"/> <span>Rejected</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="dashdbordr"></div>
                            <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                                <div className="col-md-2">
                                    <span className="checkspan twos"><span>Date Range:</span></span>
                                </div>
                                <div className="col-md-4">
                                    <input type="date" style={{height: '31px',fontSize: '14px',border: '1px solid #d9d9d9',borderRadius: '2px'}} className="form-control"/>
                                </div>
                                <div className="col-md-12" style={{textAlign:'right'}}>
                                    <button className="btn btn-primary add_btn" style={{width:'auto'}}>Submit</button>
                                    <span style={{color: '#1890ff',marginLeft:'12px'}}>Pack up <ChevronUp color="#1890ff" size="14"/></span>
                                </div>
                            </div><br/>
                            <div className="trans_tabl trans_hist_tbl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                                <span className="subdvv1_1_1">
                                    <input type="text" className="form-control" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Search"/>
                                    <Search color="#00000040" style={{top:'4px'}} size={15}/>
                                </span>
                                <div style={{float:'right',marginBottom:'10px'}}>
                                    <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}}>Export to Excel</button>
                                    <button className="btn btn-primary add_btn cancel_btn">Print</button>
                                </div>
                                <DataTable columns={columns2} pagination data={data2} customStyles={customStyles}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {this.state.currentTab==='stmt' &&
                <div className="row" style={{margin:0}}>
                    <div className="col-md-12">
                        <div className="divbody">
                            <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                                <h6 style={{fontWeight:600,margin:0}}>Statement</h6>
                            </div>
                            <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                                <div className="col-xl-1 col-lg-2 col-md-2">
                                    <span className="checkspan twos"><span>Date Range:</span></span>
                                </div>
                                <div className="col-md-4">
                                    <input type="date" style={{height: '31px',fontSize: '14px',border: '1px solid #d9d9d9',borderRadius: '2px'}} className="form-control"/>
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-primary add_btn" style={{width:'auto'}}>Submit</button>
                                </div>
                            </div><br/>
                            <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                                <span className="subdvv1_1_1">
                                    <input type="text" className="form-control" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Search"/>
                                    <Search color="#00000040" style={{top:'4px'}} size={15}/>
                                </span>
                                <div style={{float:'right',marginBottom:'10px'}}>
                                    <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}}>Export to Excel</button>
                                    <button className="btn btn-primary add_btn cancel_btn">Print</button>
                                </div>
                                <DataTable columns={columns3} pagination data={data3} customStyles={customStyles}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {this.state.currentTab==='trans_report' &&
                <div className="row" style={{margin:0}}>
                    <div className="col-md-12">
                        <div className="divbody">
                            <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                                <h6 style={{fontWeight:600,margin:0}}>Transfer Report</h6>
                            </div>
                            <div className="row searchdivs" style={{margin:0,padding:'20px 14px',background:'white'}}>
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                    <label>Date Range:</label>
                                    <input type="date" className="form-control"/>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                    <label>From:</label>
                                    <select className="form-control">
                                        <option>Select</option>
                                    </select>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                    <label>To:</label>
                                    <select className="form-control">
                                        <option>Select</option>
                                    </select>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                    <label>Status:</label>
                                    <select className="form-control">
                                        <option>Select</option>
                                    </select>
                                </div>
                            </div><br/>
                            <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                                <span className="subdvv1_1_1" style={{fontSize:'14px'}}>Show 
                                    <select className="form-control" style={{display: 'inline-block',width: '60px',height: '31px',fontSize: '14px',margin: '0 10px'}}>
                                        <option>50</option>
                                    </select> Entries
                                </span>
                                <div style={{float:'right',marginBottom:'10px'}}>
                                    <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}}>Export to Excel</button>
                                    <button className="btn btn-primary add_btn cancel_btn">Print</button>
                                </div>
                                <DataTable columns={columns4} pagination data={data4} customStyles={customStyles}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
export default MemberDetails;