import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { makeRequest,makeProviderRequest } from '../../helpers'

class AddPlatformOwner extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.makeProviderRequest = makeProviderRequest
    this.add = this.add.bind(this)
    this.changeUsername = this.changeUsername.bind(this)
    this.changeEmail = this.changeEmail.bind(this)
    this.changePwd = this.changePwd.bind(this)
    this.changeFullName = this.changeFullName.bind(this)
    this.changePhone = this.changePhone.bind(this)
    this.changeAddress = this.changeAddress.bind(this)
  }
  state= {
    username:'',
    email:'',
    pwd:'',
    full_name:'',
    phone:'',
    submitDisabled:false,
    error_msg:'',
    success_msg:'',
    address:'',
  }
  changeUsername(e) {
    let usernameValid = e.target.value ? true : false;
    this.setState({
      username: (e.target.value).toLowerCase(),
      usernameValid: usernameValid,
    })
  }
  changeEmail(e) {
    this.setState({
      email: e.target.value,
    })
  }
  changePwd(e) {
    this.setState({
      pwd: e.target.value,
    })
  }
  changeFullName(e) {
    this.setState({
      full_name: e.target.value,
    })
  }
  changePhone(e) {
    this.setState({
      phone: e.target.value,
    })
  }
  changeAddress(e) {
    this.setState({
      address: e.target.value,
    })
  }
  async add(){
    if(this.state.username===''){
      this.setState(state => ({error_msg:'Enter username.'}));
    }else if(this.state.full_name===''){
      this.setState(state => ({error_msg:'Enter full name.'}));
    }else if(this.state.phone===''){
      this.setState(state => ({error_msg:'Enter phone number.'}));
    }else if(this.state.email===''){
      this.setState(state => ({error_msg:'Enter email address.'}));
    }else if((this.state.email).indexOf("@") === -1){
      this.setState(state => ({error_msg:'Invalid email address.'}));
    }else if(this.state.pwd===''){
      this.setState(state => ({error_msg:'Enter password.'}));
    }else if((this.state.pwd).length<8){
      this.setState(state => ({error_msg:'Password must be atleast 8 Characters.'}));
    }else{
      this.setState(state => ({submitDisabled:true}));
      let data = JSON.stringify({username: this.state.username,password: this.state.pwd,email: this.state.email,fullname: this.state.full_name,phone: this.state.phone,address: this.state.address});
      var result1=await this.makeProviderRequest('owner', 'post', data,localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState({
          success_msg:'Owner added successfully.',error_msg:'',submitDisabled:false
        })
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled:false,success_msg:''}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false,success_msg:''}));
    }
  }
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0,height:"100vh"}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Platform Owners</span> / 
                    <span>Add Platform owners</span>
                  </div>
                  <h5 className="pg_head">Add Platform owners</h5>
                </div>
              </div><br/>
            </div>
            <div style={{padding:'0 20px'}}>
              {this.state.success_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>):('')
              }
              {this.state.error_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.error_msg}</p>):('')
              }
            </div>
            <div className="divbody">
              <div className="subbdvv1 add_owner_subdiv" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:600,margin:0}}>Informations
                  <div className="add_owner_btns" style={{float:'right',marginTop:'-6px'}}>
                    <button className="btn btn-primary add_btn" onClick={this.add} disabled={this.state.submitDisabled}>Add</button>
                    <button className="btn btn-primary add_btn cancel_btn" onClick={()=>this.props.history.push('/admin/platform_owners')}>Cancel</button>
                  </div>
                </h6>
              </div>
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white',width:"100%"}}>
                <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Username: </span>
                      <input type="text" className="form-control" onChange={this.changeUsername}/>
                    </div><br/>
                    <div className="add_form_div">
                      <span>Full Name：</span>
                      <input type="text" className="form-control" onChange={this.changeFullName}/>
                    </div><br/>
                    <div className="add_form_div">
                      <span>Phone Number：</span>
                      <input type="text" className="form-control" onChange={this.changePhone}/>
                    </div><br/>
                    <div className="add_form_div" style={{marginBottom:"10px"}}>
                      <span>Email Address：</span>
                      <input type="text" className="form-control" onChange={this.changeEmail}/>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12">
                  <div className="add_form_div">
                    <span>Address: </span>
                    <textarea style={{height: '144px'}} className="form-control" onChange={this.changeAddress}/>
                  </div><br/>
                  <div className="add_form_div">
                    <span>Password：</span>
                    <input type="password" className="form-control" onChange={this.changePwd}/>
                  </div><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPlatformOwner;
