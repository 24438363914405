import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
import { CSVLink } from "react-csv";
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,username: "User1234",name:"Name ABCDE",contact_no:"0123456789",email:"email@email.com",frequency:"3"},
  {id: 2,username: "User1234",name:"Name ABCDE",contact_no:"0123456789",email:"email@email.com",frequency:"3"},
  {id: 3,username: "User1234",name:"Name ABCDE",contact_no:"0123456789",email:"email@email.com",frequency:"3"},
  {id: 4,username: "User1234",name:"Name ABCDE",contact_no:"0123456789",email:"email@email.com",frequency:"3"},
  {id: 5,username: "User1234",name:"Name ABCDE",contact_no:"0123456789",email:"email@email.com",frequency:"3"},
  {id: 6,username: "User1234",name:"Name ABCDE",contact_no:"0123456789",email:"email@email.com",frequency:"3"},
  {id: 7,username: "User1234",name:"Name ABCDE",contact_no:"0123456789",email:"email@email.com",frequency:"3"},
  {id: 8,username: "User1234",name:"Name ABCDE",contact_no:"0123456789",email:"email@email.com",frequency:"3"},
  
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const excelData = [
  {
    "Username": "User1234",
    "Name": "Name ABCDE",
    "Contact No.": '0123456789',
    "Email": 'email@email.com',
    "Frequency": '3',
  },
  {
    "Username": "User1234",
    "Name": "Name ABCDE",
    "Contact No.": '0123456789',
    "Email": 'email@email.com',
    "Frequency": '3',
  },
  {
    "Username": "User1234",
    "Name": "Name ABCDE",
    "Contact No.": '0123456789',
    "Email": 'email@email.com',
    "Frequency": '3',
  },
  {
    "Username": "User1234",
    "Name": "Name ABCDE",
    "Contact No.": '0123456789',
    "Email": 'email@email.com',
    "Frequency": '3',
  }
]
const headers = [
  { label: "Username", key: "username" },
  { label: "Name", key: "name" },
  { label: "Contact No.", key: "contact_no" },
  { label: "Email", key: "email" },
  { label: "Frequency", key: "frequency" },
];
class PlayerFreqReport extends React.Component {
  constructor(props) {
  super(props);
  this.tableRef = React.createRef();
  this.printData = this.printData.bind(this)
  this.exportData = this.exportData.bind(this)
  }
  printData(){
    window.print();
  }
  exportData(){
    exportFromJSON({ data: excelData, fileName: 'Player frequency report', exportType: exportFromJSON.types.xls})
  }
  render() {
    const columns = [
      {name: 'No.',selector: 'id',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Username',selector: 'username',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>User1234</div>},
      {name: 'Name',selector: 'name',cell: row => <div data-tag="allowRowEvents">Name ABCDE</div>},
      {name: 'Contact No.',width:'150px',selector: 'contact_no',cell: row => <div data-tag="allowRowEvents">0123456789</div>},
      {name: 'Email',width:'150px',selector: 'email',cell: row => <div data-tag="allowRowEvents">email@email.com</div>},
      {name: 'Frequency',width:'120px',selector: 'frequency',cell: row => <div data-tag="allowRowEvents">3</div>},
    ];
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}} className="print_hide">
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Statistics</span> / 
                    <span>Player Frequency Report</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Player Frequency Report</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="subbdvv1 print_hide" style={{padding: '18px 24px 0px'}}>
                    <h6 style={{fontWeight:700,margin:0}}>Search Criteria</h6>
                </div>
                <div className="row player_freq print_hide" style={{margin:0,padding:'12px 14px',background:'white'}}>
                
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"82px"}}>Date Range:</span></label>
                        <input type="date" className="form-control" style={{height:"32px",fontSize:"14px"}} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"82px"}}>Record Type:</span></label>
                        <input type="text" className="form-control" style={{height:"32px",fontSize:"14px"}} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"82px"}}>Amount Criteria:</span></label>
                        <input type="text" className="form-control" style={{height:"32px",fontSize:"14px"}} />
                    </div>
                    <br/>
                    <div style={{marginTop:"0px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Search</button>
                        <button className="btn btn-primary add_btn reset_black_btn">Reset</button>
                    </div>
                
                </div><br/>
              <div className="trans_tabl player_trans_table" style={{margin:0,padding:'20px 14px 56px',background:'white'}}>
                  <div className="row print_hide">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <span className="subdvv1_1_1 mem_stat_left">
                      <label className="labls labls1"><span style={{minWidth:"48px"}}>Show:</span>
                          <select className="form-control" style={{width:"70px"}}>
                            <option>50</option>
                          </select></label>
                      </span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                      <div className="mem_stat_btns" style={{float:'right',marginBottom:'10px'}}>
                          <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                          <CSVLink data={data} headers={headers} className="btn btn-primary add_btn cancel_btn">CSV</CSVLink>;
                          <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                      </div>
                    </div>
                  </div>  
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
                <div style={{marginTop:"0px",float:"right"}} className="print_hide">
                    <button className="btn btn-primary add_btn reset_black_btn1">Total Player Frequency : 35</button>
                    <button className="btn btn-primary add_btn reset_black_btn1">Total Player : 35</button>
                    <button className="btn btn-primary add_btn reset_black_btn1">Avg. Player Frequency : 35</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PlayerFreqReport;