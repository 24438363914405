import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
const data = [
  {id: 1,role: "Role Name",role_level:"20",action:""},
  {id: 2,role: "Role Name",role_level:"20",action:""},
  {id: 3,role: "Role Name",role_level:"20",action:""},
  {id: 4,role: "Role Name",role_level:"20",action:""},
  {id: 5,role: "Role Name",role_level:"20",action:""},
  {id: 6,role: "Role Name",role_level:"20",action:""},
  {id: 7,role: "Role Name",role_level:"20",action:""},
  {id: 8,role: "Role Name",role_level:"20",action:""},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class RoleManagement extends React.Component {
  render() {
    const columns = [
      {name: 'No.',selector: 'id',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Role',selector: 'role',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>Role Name</div>},
      {name: 'Role Level',selector: 'role_level',cell: row => <div data-tag="allowRowEvents">20</div>},
      {name: 'Action',width:"200px",selector: 'action',cell: row => <div data-tag="allowRowEvents"><button className="btn btn-primary add_btn admin_btns" style={{width:'auto'}}>Edit Role</button> <button className="btn btn-primary add_btn admin_btns" style={{width:'auto',marginLeft:"8px"}}><Link to="/admin/assign_module" style={{textDecoration:"none",color:"white"}}>Assign Module</Link></button> </div>}
    ];
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Admin Management</span> / 
                    <span>Role Management</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Role Management</h5>
                    <div style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Add New Role</button>
                    </div>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
              <div className="row">
                <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12"> 
                <span className="subdvv1_1_1 acc_man_left">
                      <label className="labls labls1"><span style={{minWidth:"48px"}}>Show:</span>
                          <select className="form-control" style={{width:"70px"}}>
                              <option>50</option>
                          </select></label>
                  </span>
                  <span className="subdvv1_1_1 acc_man_left">
                      <label className="labls labls1"><span style={{minWidth:"48px"}}>Status:</span>
                          <select className="form-control" style={{width:"70px"}}>
                              <option>All</option>
                          </select></label>
                  </span>
                  <span className="subdvv1_1_1 acc_man_left">
                      <label className="labls labls1"><span style={{minWidth:"48px"}}>Role:</span>
                          <select className="form-control" style={{width:"70px"}}>
                              <option>All</option>
                          </select></label>
                  </span>
                </div> 
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12"> 
                  <div className="acc_man_btns" style={{float:'right',marginBottom:'10px',width:"100%"}}>
                  <label className="labls labls1"><span style={{minWidth:"56px"}}>Search:</span><input type="text" className="form-control" style={{display: 'inline-block',height: '31px',fontSize: '14px'}}/></label>
                  </div>
                </div>
              </div>    
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
                
                <div className="col-md-8" style={{marginTop:"30px"}}>
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Edit Role</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 48px',background:'white'}}>
                    
                        <div className="col-md-12 top_div2">
                            <label className="labls"><span style={{fontWeight:"700"}}>Role Name:</span>
                            <input type="text" className="form-control sms_blast"/></label>
                        </div>
                        <div className="col-md-12 top_div2">
                            <label className="labls"><span style={{fontWeight:"700"}}>Role Level:</span>
                            <select className="form-control sms_blast" style={{width:"100%"}}>
                                <option>18</option>
                            </select></label>
                        </div>
                        <br/>
                        <div style={{marginTop:"12px",paddingLeft:"0"}}>
                            <button className="btn btn-primary add_btn reset_black_btn">Cancel</button>
                            <button className="btn btn-primary add_btn" style={{width:'auto',marginLeft:"20px"}}>Save</button>
                        </div>
                    </div><br/>
                </div>

            </div>           
          </div>
        </div>
      </div>
    );
  }
}
export default RoleManagement;