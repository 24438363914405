import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { makeRequest,makeFileUploadRequest,handleKeyUp,gameList } from '../../helpers'
class CommSetting extends React.Component {
	constructor(props) {
		super(props);
		this.makeRequest = makeRequest
		this.gameList = gameList
	  }
	  state= {
	  gameData:[],
	  selectedGame:[],
	  }
	  gameProviders(e) {
	   this.state.selectedGame.push(e.target.value);
		}
	  componentDidMount() {
		this.gameList(this);
	  }
  render() {
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Commission</span> / 
                    <span>Commission</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Overall Commission Setting</h5>
                    <div style={{marginTop:"20px"}}>
                    </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 divbody main_setting">
                <div className="subbdvv1" style={{padding: '16px 0px 0px'}}>
                    <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Default Agent Level Allow</h5>
                </div>
                <div className="row" className="comm_setting" style={{margin:0,padding:'16px 30px 36px 80px',background:'white',width:"100%"}}>
                <div className="row ms-progressbar" style={{borderBottom:"0"}}>

                      <div className="col-xs-3 ms-progressbar-step complete">
                        <div className="progress">
                          <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Ower <p>You</p></div>
                        
                      </div>

                      <div className="col-xs-3 ms-progressbar-step complete">
                        <div className="progress">
                          <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Senior<p>Level 2</p></div>
                        
                      </div>
                      <div className="col-xs-3 ms-progressbar-step disabled">
                        <div className="progress">
                          <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot current">
                        </a>
                        <div className="text-center ms-progressbar-step-number">Master <p>Level 3</p></div>
                        
                      </div>
                      <div className="col-xs-3 ms-progressbar-step disabled">
                        <div className="progress">
                          <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Agent1 <p>Level 4</p></div>
                        
                      </div>
                      <div className="col-xs-3 ms-progressbar-step disabled">
                        <div className="progress">
                          <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Agent 2 <p>Level 5</p></div>
                        
                      </div>
                      <div className="col-xs-3 ms-progressbar-step disabled dashed">
                        <div className="progress">
                          <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Agent 3 <p>Level 6</p></div>
                        
                      </div>
                      <div className="col-xs-3 ms-progressbar-step">
                        <div className="progress" style={{zIndex:"-1"}}>
                          <div className="progress-bar"></div>
                        </div>
                        <a href="#" className="ms-progressbar-dot"></a>
                        <div className="text-center ms-progressbar-step-number">Player</div>
                        
                      </div>
                </div>

                </div>
            </div>

            <div className="col-md-12 divbody main_setting">
                <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                    <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Default Game Control Open to New Senior</h5>
                </div>
                <div className="row" style={{margin:0,padding:'12px 14px',background:'white'}}>
                
                    <div className="col-md-6 top_div3">
                    <ul className="comm_div">
                           <li className="li_head">Game Name <span>No/Yes</span></li>
                            {this.state.gameData.map(data => (
								<li className="game_provider_checkbox" key={data.id}>{data.name} 
								<span>
									<label className="switch">
									  <input type="checkbox" onClick={() => this.gameProviders}/>
									  <span className="slider round"></span>
									</label>
								</span>
								</li>
							))}
                        </ul>
                    </div>
                    {/*<div className="col-md-6 top_div3">
                        <ul className="comm_div">
                           <li className="li_head">Game Name <span>No/Yes</span></li>
                           <li>Poker A <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                           <li>Poker B <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                           <li>Poker C <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                           <li>Poker D <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                           <li>Poker E <span><ToggleOn size={30} color="#BFBFBF"/></span></li>
                        </ul>
                    </div>*/}
                </div><br/>
            </div>

            <div className="col-md-12 divbody main_setting" style={{padding:"0px 23px"}}>
              <div className="subbdvv1" style={{padding:"16px"}}>
                  <button className="btn btn-primary add_btn" style={{width:'auto'}}>Save</button>
                  <button className="btn btn-primary add_btn reset_black_btn"  style={{width:'auto',marginLeft:"20px"}}>Reset</button>
              </div>
            </div><br/>

          </div>
        </div>
      </div>
    );
  }
}
export default CommSetting;