import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import WalletBalance from './WalletBalance';
import LevelDets from './LevelDets';
import LevelDetsSM from './LevelDetsSM';
import { Accordion, Card,Modal,Dropdown } from "react-bootstrap";
import { Eye,CloudArrowUpFill } from 'react-bootstrap-icons';
import "../assets/Modal.css"
//import Modal from './Modal'
import DataTable from 'react-data-table-component';
import data2 from './Data2'
import COUNTRIES from '../countries'
import { makeRequest,makeFileUploadRequest,handleKeyUp } from '../helpers'

const customStyles = {
  rows: {
    style: {
      minHeight: '50px',
      fontSize: '13px',
      background:'#0d171f',
      color:'white',
      textTransform:'capitalize'
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',
      paddingRight: '8px',
      fontSize: '14px',
      fontWeight: 'bold',
      width:'50px',
      background:'#363F47',
      color:'white',
      border:'0'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',
      paddingRight: '8px',
      width:'50px',
      color: '#ffffff80',
      fontSize: '14px',
      borderBottom:'1px solid #283138'
    },
  },
};
const columns = [
  {
    name: 'USERNAME',
    selector: 'username',
    width:'150px'
  },
  {
    name: 'VIP LEVEL',
    selector: 'vip_level',
  },
  {
    name: 'USER ROLE',
    selector: 'user_role',
  },
  {
    name: 'COMMISSION PASS TO DOWNLINE',
    selector: 'vip_level',
    cell: row => <div data-tag="allowRowEvents" className="commiss_pass"><input className="form-control" placeholder="0"/></div>,
  },
  {
    name: 'JOIN DATE',
    selector: 'datetime',
    width:'200px'
  },
];
// const columns2 = [
//   {
//     name: 'USERNAME',
//     selector: 'username',
//     width:'150px'
//   },
//   {
//     name: 'VIP LEVEL',
//     selector: 'vip_level',
//   },
//   {
//     name: 'JOIN DATE',
//     selector: 'datetime',
//     width:'200px'
//   },
// ];

class MyAccount1 extends React.Component {
  constructor(props) {
    super(props);
    this.changetab = this.changetab.bind(this)
    this.changeToggleState = this.changeToggleState.bind(this)
    this.changeToggleState2 = this.changeToggleState2.bind(this)
    this.setShow = this.setShow.bind(this)
    this.setVerifyMobile = this.setVerifyMobile.bind(this)
    this.goto_nextStep = this.goto_nextStep.bind(this)
    this.addBankModal = this.addBankModal.bind(this)
    this.addBank = this.addBank.bind(this)
    this.changeCurrPwd = this.changeCurrPwd.bind(this)
    this.changeNewPwd = this.changeNewPwd.bind(this)
    this.changeretypPwd = this.changeretypPwd.bind(this)
    this.modify_pwd = this.modify_pwd.bind(this)
    this.avatarUpload = this.avatarUpload.bind(this)
    this.changeAvatar = this.changeAvatar.bind(this)
    this.changeFname = this.changeFname.bind(this)
    this.changeDob = this.changeDob.bind(this)
    this.send_verif_code = this.send_verif_code.bind(this)
    this.changePhone = this.changePhone.bind(this)
    this.verify_phone = this.verify_phone.bind(this)
    this.changeReferral = this.changeReferral.bind(this)
    this.become_agent = this.become_agent.bind(this)
    this.update_profile = this.update_profile.bind(this)
    this.user_profile = this.user_profile.bind(this)
    this.referrer_info = this.referrer_info.bind(this)
    this.removeAvatar = this.removeAvatar.bind(this)
    this.selectedCountry = this.selectedCountry.bind(this)
    this.walletDetail = this.walletDetail.bind(this)
    this.becom_agnt = this.becom_agnt.bind(this)
    this.DetailModal = this.DetailModal.bind(this)
    this.changeAmount = this.changeAmount.bind(this)
    this.depositAmount = this.depositAmount.bind(this)
    this.transferMoney = this.transferMoney.bind(this)
    this.changeTransferAmount = this.changeTransferAmount.bind(this)
    this.changeSourceWallet = this.changeSourceWallet.bind(this)
    this.changeDestinationWallet = this.changeDestinationWallet.bind(this)
    this.c1 = this.c1.bind(this)
    this.c2 = this.c2.bind(this)
    this.c3 = this.c3.bind(this)
    this.c4 = this.c4.bind(this)
    this.c5 = this.c5.bind(this)
    this.c6 = this.c6.bind(this)
    this.makeRequest = makeRequest
    this.makeFileUploadRequest = makeFileUploadRequest
    this.handleKeyUp = handleKeyUp
  }
  state= {
    tab:'accountdetails',
    tog1_state:true,
    tog2_state:false,
    show:false,
    showVerifyMobile:false,
    currentStep:1,
    showBankModal:false,
    reflink:false,
    refstep:1,
    oldp:'',newp:'',currValid:'',newValid:'',retypeValid:'',
    success_msg:'',
    error_msg:'',
    error_msg2:'',
    error_msg3:'',
    submitDisabled:true,
    submitDisabled2:false,
    filepath:'',
    dobValid:'',
    dob:'',
    fnameValid:'',
    fname:'',
    phone:'',
    phoneValid:'',
    code:'',
    code1:'',
    code2:'',
    code3:'',
    code4:'',
    code5:'',
    code6:'',
    referral:'',
    referralValid:'',
    userAvatar:'',
    referrerInfo:'',
    referral_count:{},
    walletDetails:'',
    selected_country:{
        "k": "","n": ""
    },
    showDetailModal:'',
    showDepositModal:false,
    showTransferModal:false,
    amount:'',
	tranfer_amount:'',
	source_wallet:'',
	destination_wallet:'',
	allWallets:[],
	
  }

  componentDidMount() {
    if(localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!==''){
        let resultt = COUNTRIES.filter(obj => {
            return obj.k === localStorage.getItem('country')
        })
        this.setState({userAvatar:(JSON.parse(localStorage.getItem('userInfo'))).avatar,fname:(JSON.parse(localStorage.getItem('userInfo'))).fullname,dob:(JSON.parse(localStorage.getItem('userInfo'))).dateOfBirth,selected_country:{"k": localStorage.getItem('country'),"n": resultt[0].n}});
        this.referrer_info();
    }
    this.walletDetail();
    this.walletData();
  }
  async walletDetail(){
    var result1=await this.makeRequest('wallet', 'get', '',localStorage.getItem('token'));
    if(result1.status===200){
        console.log('detail',result1.data[0]);
        this.setState({walletDetails:result1.data[0]});
    }
  }
  changeAmount(e) {
    this.setState(state => ({
      amount: e.target.value,
    }));
  }
    changeTransferAmount(e) {
    this.setState(state => ({
      tranfer_amount: e.target.value,
    }));
  }
    changeSourceWallet(e) {
    this.setState(state => ({
      source_wallet: e.target.value,
    }));
  }
    changeDestinationWallet(e) {
    this.setState(state => ({
      destination_wallet: e.target.value,
    }));
  }
  async walletData(){
	var result1=await this.makeRequest('wallet', 'get', '',localStorage.getItem('token'));
	if(result1.status===200){
		console.log('all wallet',result1.data);
		this.setState({allWallets:result1.data});
	}
  }
  async depositAmount(){
    if(this.state.amount===''){
      this.setState(state => ({error_msg3: 'Enter amount'}));
    }else{
      this.setState(state => ({submitDisabled2: true,error_msg3:''}));
      var data = JSON.stringify({value: this.state.amount});
      var result1=await this.makeRequest('wallet/017aa0a9-5f47-46c1-9a45-aacf0e4334bb/deposit', 'put', data,localStorage.getItem('token'));
      if(result1.status===200){
        //console.log('deposit_data',result1);
        this.setState({
          success_msg:result1.data.message
        })
      }else if(result1.status===400){
       this.setState(state => ({error_msg3:result1.data.message,submitDisabled2:false}));
      }
    }
    
  }
    async transferMoney(){
    if(this.state.source_wallet===''){
		this.setState(state => ({error_msg3: 'Please choose source wallet'}));
	}else if(this.state.destination_wallet===''){
		this.setState(state => ({error_msg3: 'Please choose destination wallet'}));
	}else if(this.state.destination_wallet===this.state.source_wallet){
		this.setState(state => ({error_msg3: 'Source and destination wallets could not be same.'}));
	}else if(this.state.tranfer_amount===''){
	  this.setState(state => ({error_msg3: 'Please enter amount'}));
	}else {
	  this.setState(state => ({submitDisabled2: true,error_msg3:''}));
	  var data = JSON.stringify({source: this.state.source_wallet,destination: this.state.destination_wallet,value:parseInt(this.state.tranfer_amount)});
	  console.log('data',data);
	  var result1=await this.makeRequest('wallet/transfer', 'post', data,localStorage.getItem('token'));
	  if(result1.status===200){
		console.log('transfer_data',result1);
		this.setState({
		  success_msg:result1.data.message
		})
	  }else if(result1.status===400){
	   this.setState(state => ({error_msg3:result1.data.message,submitDisabled2:false}));
	  }
	}
		
	}
  changetab(val){
    this.setState(state => ({
        tab: val
    }));
  }
  changeToggleState(val){
    this.setState(state => ({
        tog1_state: val,
        tog2_state:false
    }));
  }
  changeToggleState2(val){
    this.setState(state => ({
        tog2_state: val,
        tog1_state:false
    }));
  }
  c1(e) {this.setState({code1: e.target.value})}
  c2(e) {this.setState({code2: e.target.value})}
  c3(e) {this.setState({code3: e.target.value})}
  c4(e) {this.setState({code4: e.target.value})}
  c5(e) {this.setState({code5: e.target.value})}
  c6(e) {this.setState({code6: e.target.value})}
  setShow(val){
    this.setState(state => ({
        show: val,
    }));
  }
  setVerifyMobile(val){
    this.setState(state => ({
        showVerifyMobile: val,
        currentStep:1
    }));
  }
  addBankModal(val){
    this.setState(state => ({
        showBankModal: val,
    }));
  }
  goto_nextStep(val){
    this.setState(state => ({
        currentStep: val,
    }));
  }
  enablRefLink(val){
    this.setState(state => ({
        reflink: val,
    }));
  }
  addBank(){
    this.setState(state => ({
        showBankModal: false,
        bankAdded:true
    }));
  }
  RefStep(val){
    this.setState(state => ({
        refstep: val,
    }));
  }
  changeCurrPwd(e) {
    let currValid = e.target.value ? true : false;
    this.setState(state => ({oldp: e.target.value,currValid: currValid}))
  }
  changeNewPwd(e) {
    let newValid = e.target.value ? true : false;
    this.setState(state => ({newp: e.target.value,newValid: newValid}))
  }
  changeDob(e) {
    let dobValid = e.target.value ? true : false;
    this.setState(state => ({dob: e.target.value,dobValid: dobValid,submitDisabled:false}))
  }
  changeFname(e) {
    let fnameValid = e.target.value ? true : false;
    this.setState(state => ({fname: e.target.value,fnameValid: fnameValid}))
  }
  changeAvatar(e) {
    // console.log('uploaded',e.target.files[0]);
    // let file=new File(["goldcrown"], "goldcrown.png", {type: "image/png"});
    // console.log('saved',file)
    this.setState(state => ({filepath: e.target.files[0]}));
    this.avatarUpload(e.target.files[0]);
  }
  changeretypPwd(e) {
    let retypeValid = e.target.value ? true : false;
    if(this.state.newp!==e.target.value){
      this.setState(state => ({
        error_msg:'Passwords do not match'
      }));
    }else{
        this.setState(state => ({error_msg:'',retypeValid: retypeValid}));
        if(this.state.currValid && this.state.newValid){
            this.setState(state => ({submitDisabled: false,}));
        }
    }
  }
  changePhone(e) {
    let phoneValid = e.target.value ? true : false;
    this.setState(state => ({phone: e.target.value,phoneValid: phoneValid,submitDisabled:false}))
  }
  changeReferral(e) {
    let referralValid = e.target.value ? true : false;
    this.setState({referral: e.target.value,referralValid: referralValid})
    if(e.target.value!=='')
        this.setState({submitDisabled: false})
    else if(e.target.value==='')
        this.setState({submitDisabled: false})
  }
  DetailModal(val){
    this.setState(state => ({
      showDetailModal: val,
    }));
  }
  closeDepositModal(val){
    this.setState(state => ({
      showDepositModal: val,
    }));
  }
  closeTransferModal(val){
    this.setState(state => ({
      showTransferModal: val,
    }));
  }

  async modify_pwd(){  
    if(this.state.oldPassword==='' || this.state.newPassword==='' || !this.state.newValid){
      this.setState(state => ({error_msg: 'Please enter all three fields'}));
    }else{
        this.setState(state => ({submitDisabled: true,error_msg:''}));   
        var data = JSON.stringify({oldPassword: this.state.oldp,newPassword: this.state.newp});
        var result1=await this.makeRequest('user/update-password', 'post', data,localStorage.getItem('token'));
        if(result1.status===200){
            this.setState({success_msg:result1.data.message,submitDisabled: false});
            this.setShow(true)
        }else if(result1.status===400){
            this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
        }else if(result1.status===500){
            this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false}));
        }
    }
  }
  async update_profile(){
    if(this.state.fname===''){
      this.setState(state => ({error_msg:'Enter your name'}));
    }
    else if(this.state.dob===''){
      this.setState(state => ({error_msg:'Enter date of birth'}));
    }else{
        this.setState(state => ({submitDisabled: true,error_msg:''}));   
        var data = JSON.stringify({fullname: this.state.fname,dateOfBirth: this.state.dob});
        var result1=await this.makeRequest('user/me', 'put', data,localStorage.getItem('token'));
        if(result1.status===200){
            this.setState({
                success_msg:result1.data.message,
                submitDisabled: false,
            });
            setTimeout(
                function() {
                    this.setState({success_msg:''});
                }.bind(this),3000
            );
            this.user_profile();
        }else if(result1.status===400){
            this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
        }
    }
  }
  async send_verif_code(){      
    this.goto_nextStep(2)
    // this.setState(state => ({submitDisabled: true}));
    // var data = JSON.stringify({phone: this.state.selected_country.n+this.state.phone});
    // var result1=await this.makeRequest('user/verify-phone', 'post', data,localStorage.getItem('token'));
    // this.setState({
    //   submitDisabled: false,
    // });
    // if(result1.status===200){
    //     this.goto_nextStep(2)
    // }
  }
  async verify_phone(){
    if(this.state.code1==='' || this.state.code2==='' || this.state.code3==='' || this.state.code4==='' || this.state.code5==='' || this.state.code6===''){
      this.setState(state => ({error_msg2: 'Please fill the code fields.'}));
    }else{
        this.setState(state => ({submitDisabled: true,error_msg:''}));
        var data = JSON.stringify({code: parseInt(this.state.code1+this.state.code2+this.state.code3+this.state.code4+this.state.code5+this.state.code6)});
        var result1=await this.makeRequest('user/confirm-phone', 'post', data,localStorage.getItem('token'));
        this.setState({submitDisabled: false});
        if(result1.status===200){
            this.goto_nextStep(3)
            this.user_profile();
        }else if(result1.status===400){
            this.setState(state => ({error_msg2:result1.data.message}));
        }
    }
  }
  async become_agent(){
    if(this.state.referral===''){
      this.setState(state => ({error_msg:'Enter referral code'}));
    }else{
        this.setState(state => ({submitDisabled:true}));
        let data = JSON.stringify({referral: this.state.referral});
        var result1=await this.makeRequest('become-agent', 'post', data,localStorage.getItem('token'));
        //console.log('Statement 1:',result1.data,'status:',result1.status);
        if(result1.status===200){
            this.setState({success_msg:'Request submitted successfully.',refstep:4})
            setTimeout(
                function() {
                    this.setState({success_msg:''});
                }.bind(this),2000
            );
        }else if(result1.status===400){
            this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
        }
    }
  }
  async avatarUpload(filepath){
    this.setState({submitDisabled2:true});
    var data = new FormData();
    data.append('image',(filepath));
    var result1=await this.makeFileUploadRequest('user/update-avatar', 'post', data,localStorage.getItem('token'));
    if(result1.status===200){
        this.setState({success_msg:result1.data.message,submitDisabled2:false});
        setTimeout(
            function() {
                this.setState({success_msg:''});
            }.bind(this),5000
        );
        this.user_profile();
    }    
  }
  async user_profile(){
    let result2=await this.makeRequest('user/me', 'get', '',localStorage.getItem('token'));
    if(result2.status===200){
        localStorage.setItem('userInfo',JSON.stringify(result2.data.user))
        this.setState({userAvatar:(JSON.parse(localStorage.getItem('userInfo'))).avatar});
    }
  }
  
  async referrer_info(){
    let result2=await this.makeRequest('user/me/referrer', 'get', '',localStorage.getItem('token'));
    let result3=await this.makeRequest('user/me/referral/count', 'get', '',localStorage.getItem('token'));
    if(result2.status===200){
        this.setState({referrerInfo:result2.data,referral_count:result3.data});
    }
  }
  removeAvatar(){
    (async () => {
        const response = await fetch('assets/goldcrown.png');
        const blob = await response.blob();
        const file = new File([blob], "profileImage.png", {
        type: blob.type,
        });
        this.setState(state => ({filepath: file}))
        this.avatarUpload(file);
    })();
  }
  selectedCountry(val){
    this.setState(state => ({selected_country: val}));
  }
  becom_agnt(){
    this.setState(state => ({tab: 'referral',refstep:3}));
    window.scrollTo(0, 0)
  }
  render() {
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{background:'#000C16',color:'white'}}>
            <br/><br/><br/>
            <div>
              <div className="container account_container">
                <div className="row justify-content-center">

                <div className="col-xl-3 col-lg-3 col-md-3 hide767scr">
                    {this.state.tab==='wallet_details'?
                        (   <div>                        
                                <div className="walletdiv">
                                    <h4 className="walletbal">
                                        <div><p className="bal">Total Balance</p><br/>
                                        {this.state.walletDetails.currency} {this.state.walletDetails.balance}</div>
                                    </h4><br/><br/>
                                    <button className="btn login_btn del_btn" onClick={() => this.DetailModal(true)}>View Wallet Details</button><br/>
                                    <Modal show={this.state.showDetailModal} className="pwd_changed_modal bank_account_modal wallet_modal">
                                        <Modal.Header style={{background:'none'}} closeButton onClick={() => this.DetailModal(false)}>
                                        <div>
                                            <span style={{fontSize: '25px',fontWeight: '600'}}>{this.state.walletDetails.name}</span>
                                        </div>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <div className="row" style={{height:'100%'}}>
                                            <div className="col-md-6 left" style={{marginBottom:"10px"}}>
                                                <span>Currency</span>
                                            </div>
                                            <div className="col-md-6 left">
                                                <span>{this.state.walletDetails.currency}</span>
                                            </div>
                                            <div className="col-md-6 left" style={{marginBottom:"10px"}}>
                                                <span>Balance</span>
                                            </div>
                                            <div className="col-md-6 left">
                                                <span>{this.state.walletDetails.balance}</span>
                                            </div>
                                        <br/>                                             
                                        </div>
                                        </Modal.Body>
                                    </Modal>
                                    <button className="btn login_btn del_btn" onClick={() => this.closeDepositModal(true)}>Deposit</button><br/>
                                    <Modal show={this.state.showDepositModal} className="pwd_changed_modal bank_account_modal wallet_modal">
                                        <Modal.Header style={{background:'none'}} closeButton onClick={() => this.closeDepositModal(false)}>
                                        <div>
                                            <span style={{fontSize: '25px',fontWeight: '600'}}>Deposit Amount</span>
                                        </div>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <div className="row" style={{height:'100%'}}>
                                            <div className="col-md-12 left" style={{marginBottom:"10px",padding:"0"}}>
                                                {this.state.error_msg3!=='' &&
                                                    <p style={{fontSize: '16px',color: '#ff2424',textAlign:"center"}}>{this.state.error_msg3}</p>
                                                }
                                                <label>Amount</label>
                                                <input type="text" className="form-control" onChange={this.changeAmount}></input>
                                            </div><br/>
                                            <button className="btn btn-primary login_btn w-100" disabled={this.state.submitDisabled2} onClick={this.depositAmount}>Submit</button><br/>
                                        <br/>                                             
                                        </div>
                                        </Modal.Body>
                                    </Modal>
                                    <button className="btn login_btn del_btn">Withdrawal</button><br/>
                                    <button className="btn login_btn del_btn" onClick={() => this.closeTransferModal(true)}>Transfer</button><br/>
									<Modal show={this.state.showTransferModal} className="pwd_changed_modal bank_account_modal wallet_modal">
                                        <Modal.Header style={{background:'none'}} closeButton onClick={() => this.closeTransferModal(false)}>
                                        <div>
                                            <span style={{fontSize: '25px',fontWeight: '600'}}>Transfer Amount</span>
                                        </div>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <div className="row" style={{height:'100%'}}>
                                            <div className="col-md-12 left" style={{marginBottom:"10px",padding:"0"}}>
                                                {this.state.error_msg3!=='' &&
                                                    <p style={{fontSize: '16px',color: '#ff2424',textAlign:"center"}}>{this.state.error_msg3}</p>
                                                }
                                                <label>Source</label>
                                                <select className="form-control" onChange={this.changeSourceWallet} style={{border:"3px solid #42474e",background:"transparent"}}>
													<option value="">Choose Source Wallet</option>
													{this.state.allWallets.map(wallet_data => (
														<option value={wallet_data.id}>{wallet_data.name}</option>
													))}
												</select>
												<label>Destination</label>
                                                <select className="form-control" onChange={this.changeDestinationWallet} style={{border:"3px solid #42474e",background:"transparent"}}>
													<option>Choose Destination Wallet</option>
													{this.state.allWallets.map(wallet_data => (
														<option value={wallet_data.id}>{wallet_data.name}</option>
													))}
												</select>
												<label>Amount</label>
                                                <input type="text" className="form-control" onChange={this.changeTransferAmount}></input>
                                            </div><br/>
                                            <button className="btn btn-primary login_btn w-100" disabled={this.state.submitDisabled2} onClick={this.transferMoney}>Submit</button><br/>
                                        <br/>                                             
                                        </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        ):
                        (<div><WalletBalance className="hide767scr"/></div>)
                    }
                </div>

                <div className="col-xl-9 col-lg-9 col-md-9">
                  <div className="hide767scr"><LevelDets/></div>
                  <div className="col-xl-6 col-lg-7 col-md-8 show767scr" style={{background:'#0d171f',padding:'20px'}}>
                    <LevelDetsSM/>
                  </div>
                    <br/>
                    <div>
                        <ul className="nav nav-tabs accounttabul" style={{padding: '8px 0px',justifyContent:'left'}} id="ex1" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className={ this.state.tab==='accountdetails' && (window.location.hash.substr(1)!=='ex1-tabs-1') && (window.location.hash.substr(1)!=='ex1-tabs-7') ? 'nav-link active' : 'nav-link' } id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true" onClick={() => this.changetab('accountdetails')}>Account Details</a>
                                <span></span>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={ this.state.tab==='promotions' || (window.location.hash.substr(1)==='ex1-tabs-2') ? 'nav-link active' : 'nav-link' } id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false" onClick={() => this.changetab('promotions')}>Promotions</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={ this.state.tab==='wallet_details' ? 'nav-link active' : 'nav-link' } id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false" onClick={() => this.changetab('wallet_details')}>Wallet</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={ this.state.tab==='referral' ? 'nav-link active' : 'nav-link' } id="ex1-tab-6" data-mdb-toggle="tab" href="#ex1-tabs-6" role="tab" aria-controls="ex1-tabs-6" aria-selected="false" onClick={() => this.changetab('referral')}>Referral</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={ this.state.tab==='agent' || (window.location.hash.substr(1)==='ex1-tabs-7') ? 'nav-link active' : 'nav-link' } id="ex1-tab-7" data-mdb-toggle="tab" href="#ex1-tabs-7" role="tab" aria-controls="ex1-tabs-7" aria-selected="false" onClick={() => this.changetab('agent')}>Agent</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={ this.state.tab==='security' ? 'nav-link active' : 'nav-link' } id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="false" onClick={() => this.changetab('security')}>Security</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={ this.state.tab==='reports' ? 'nav-link active' : 'nav-link' } id="ex1-tab-5" data-mdb-toggle="tab" href="#ex1-tabs-5" role="tab" aria-controls="ex1-tabs-5" aria-selected="false" onClick={() => this.changetab('reports')}>Reports</a>
                            </li>
                        </ul>

                        <div className="tab-content viptabcontent" id="ex1-content">
                            <div className={ (this.state.tab==='accountdetails' || this.state.tab==='wallet_details') && (window.location.hash.substr(1)!=='ex1-tabs-2') && (window.location.hash.substr(1)!=='ex1-tabs-7') ? 'active show tab-pane fade' : 'tab-pane fade' } id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                                <div className="row">
                                    <div className="col-md-12">
                                        <img alt="crown_img" style={{background: '#213953',padding: '12px 10px',borderRadius: '8px',verticalAlign:'top',height: '70px',objectFit: 'cover',width: '62px'}} src={this.state.userAvatar!==null ? this.state.userAvatar  : 'assets/goldcrown.png'}/>
                                        <div style={{display:'inline-block',paddingLeft: '12px'}}>
                                            <div style={{position:'relative'}}>
                                                {this.state.submitDisabled2 &&
                                                    <span className="spinner-border spinner-border-sm" style={{position: 'absolute',right: '-22px',top: '8px'}}></span>
                                                }
                                                <input type="file" className="custom_file" style={{width:'105px'}} onChange={this.changeAvatar}/>
                                            </div>
                                            {/* <button className="btn btn-primary signup_btn comngraybtn" style={{fontSize: '12px',padding: '6px 14px'}}>Upload Avatar</button><br/> */}
                                            <button className="btn btn-primary signup_btn comngraybtn" style={{marginTop:'8px',fontSize: '12px',padding: '6px 30px',background: 'transparent!important',border: '2px solid #7d7d7d!important',color: '#7d7d7d!important',fontWeight: '600'}} onClick={this.removeAvatar}>Remove</button>
                                        </div><br/><br/><br/>
                                    </div>
                                    <div className="col-md-4 col-sm-4 accdv1">
                                        <span>Username</span><br/><br/>
                                        {localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!=='' &&
                                            <span className="dtsss">{(JSON.parse(localStorage.getItem('userInfo'))).username}</span>
                                        }
                                        <br/><br/>
                                    </div>
                                    <div className="col-md-4 col-sm-4 accdv1">
                                        <span>Email</span> <span className="spn1">Verified!</span><br/><br/>
                                        {localStorage.getItem('userInfo')!=='undefined' && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!=='' &&
                                            <span className="dtsss">{(JSON.parse(localStorage.getItem('userInfo'))).email}</span>
                                        }
                                        <br/><br/>
                                    </div>
                                    <div className="col-md-4 col-sm-4 accdv1">
                                        <span>Phone number</span> 
                                        {(JSON.parse(localStorage.getItem('userInfo'))).isPhoneNumberVerified &&
                                            <span className="spn1">Verified!</span>
                                        }
                                        <br/>
                                        <span><button onClick={() => this.setVerifyMobile(true)} className="btn btn-primary signup_btn comngraybtn" style={{marginTop:'8px'}}>Verify now!</button></span><br/><br/>
                                        <Modal size="md" show={this.state.showVerifyMobile} className="pwd_changed_modal verifymobilemodal" strictmode="false">
                                            <Modal.Header closeButton onClick={() => this.setVerifyMobile(false)}></Modal.Header>
                                            <Modal.Body>
                                                <div className="row justify-content-center" style={{height:'100%'}}>
                                                    <div className="col-md-10" style={{margin:'auto 0'}}>
                                                        <div className="step1" style={this.state.currentStep===1 ? {} : { display: 'none' }}>
                                                            <div className="stepp">1</div>
                                                            <h3 style={{fontSize: '24px',fontWeight: 'bold'}}>Enter Mobile Number</h3><br/>
                                                            <p style={{color: 'white',fontSize: '12px',opacity:0.5}}>Please enter a correct phone number, we will send you a verification code once you click continue.</p><br/>
                                                            <div style={{position:'relative'}}>
                                                                <div className="dropdown flags">
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="secondary btn-sm" id="dropdown-basic"><img src={'https://flagcdn.com/32x24/'+(this.state.selected_country.k).toLowerCase()+'.png'} alt="Flag"/></Dropdown.Toggle>
                                                                        <Dropdown.Menu style={{backgroundColor:'#73a47'}}>
                                                                            {COUNTRIES.map(flag => (
                                                                                <Dropdown.Item key={flag.k} href="#"><img src={'https://flagcdn.com/32x24/'+(flag.k).toLowerCase()+'.png'} onClick={()=>this.selectedCountry(flag)} alt="Flag"/></Dropdown.Item>
                                                                            ))}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                <span className="countrycode">+{this.state.selected_country.n}</span>
                                                                <input className="form-control" type="number" placeholder="Mobile Number" onChange={this.changePhone}/>
                                                            </div><br/><br/>
                                                            <button style={{padding: '8px 8px',fontSize: '16px'}} className="btn btn-primary login_btn btn-block w-100" onClick={this.send_verif_code}>CONTINUE</button>
                                                        </div>
                                                        <div className="step2" style={this.state.currentStep===2 ? {} : { display: 'none' }}>
                                                            <div className="stepp">2</div>
                                                            <h3 style={{fontSize: '24px',fontWeight: 'bold'}}>Verify Mobile Number</h3><br/>
                                                            <p style={{color: 'white',fontSize: '12px',opacity:0.5}}>We have sent you an access code via SMS for registered mobile number verifications.</p><br/>
                                                            {this.state.error_msg2!=='' &&
                                                                <p style={{fontSize: '16px',color: '#ff2424'}}>{this.state.error_msg2}</p>
                                                            }
                                                            <div className="verifynumdiv" style={{position:'relative'}}>
                                                                <input className="form-control" maxLength="1" onChange={this.c1} onKeyUp={this.handleKeyUp}/>
                                                                <input className="form-control" maxLength="1" onChange={this.c2} onKeyUp={this.handleKeyUp}/>
                                                                <input className="form-control" maxLength="1" onChange={this.c3} onKeyUp={this.handleKeyUp}/>
                                                                <input className="form-control" maxLength="1" onChange={this.c4} onKeyUp={this.handleKeyUp}/>
                                                                <input className="form-control" maxLength="1" onChange={this.c5} onKeyUp={this.handleKeyUp}/>
                                                                <input className="form-control" maxLength="1" onChange={this.c6} onKeyUp={this.handleKeyUp}/>
                                                            </div><br/><br/>
                                                            <button style={{padding: '8px 8px',fontSize: '16px'}} className="btn btn-primary login_btn btn-block w-100" onClick={this.verify_phone}>CONTINUE</button><br/><br/>
                                                            <span className="resendcode">Resend Code</span>
                                                        </div>
                                                        <div className="step3" style={this.state.currentStep===3 ? {} : { display: 'none' }}>
                                                            <div className="stepp">&#10003;</div>
                                                            <h3 style={{fontSize: '24px',fontWeight: 'bold'}}>Verified!</h3><br/>
                                                            <p style={{color: 'white',fontSize: '12px',opacity:0.5}}>Congratulation! Your mobile number successfully verified</p><br/>
                                                            <button style={{padding: '8px 8px',fontSize: '16px'}} className="btn btn-primary login_btn btn-block w-100" onClick={() => this.setVerifyMobile(false)}>FINISH</button>
                                                        </div>
                                                        <br/><br/>
                                                    </div>                                                    
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                    <div className="col-md-12">
                                        {this.state.success_msg!=='' &&
                                            <p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>
                                        }
                                        {this.state.error_msg!=='' &&
                                            <p style={{fontSize: '16px',color: '#ff2424'}}>{this.state.error_msg}</p>
                                        }
                                    </div>
                                    <div className="col-md-4 col-sm-6 accdv1">
                                        <span>Full name</span><br/>
                                        <span><input className="form-control" value={this.state.fname} onChange={this.changeFname}/></span>
                                    </div>
                                    <div className="col-md-4 col-sm-6 accdv1">
                                        <span>Date of birth</span><br/>
                                        <span><input type="date" value={this.state.dob || ""} className="form-control dob" onChange={this.changeDob}/></span><br/><br/>
                                    </div>
                                    <div className="col-md-12">
                                        <button className="btn btn-primary login_btn" style={{padding: '8px 28px',fontSize: '14px'}} onClick={this.update_profile} disabled={this.state.submitDisabled}>Save</button>
                                        {!(JSON.parse(localStorage.getItem('userInfo'))).isPhoneNumberVerified &&
                                            <span className="notee" style={{color: '#FF2F28',textTransform: 'capitalize',paddingLeft: '23px',fontSize: '12px'}}><span style={{borderRadius: '100%',background: '#b30707',color: '#020000',padding: '1px 6px',display: 'inline-block',lineHeight: '1.2',fontSize: '12px'}}>!</span> Please note that phone verification is important. you cannot withdraw and add funds if you have not verified.</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={ this.state.tab==='promotions' || (window.location.hash.substr(1)==='ex1-tabs-2') ? 'active show tab-pane fade tab2content' : 'tab-pane fade' } id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                                <div>
                                    <h4 className="text-center">WELCOME PROMOTION</h4><br/>
                                    <div className="row justify-content-center">
                                        <div className="col-md-11">
                                            <div className="accordionn">
                                                <Accordion defaultActiveKey="0">
                                                    <Card>
                                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.changeToggleState(!this.state.tog1_state)}>
                                                            <span className="toggle text-center" style={!this.state.tog1_state ? {} : { display: 'none' }}>+</span><span className="toggle text-center" style={this.state.tog1_state ? {} : { display: 'none' }}>-</span> 5% Cash Back For All Games Am I Qualify?
                                                            <span className="hide565scr" style={{float:'right'}}>
                                                                <button className="btn btn-primary login_btn" style={{padding: '8px 20px', fontSize: '12px'}}>CLAIM</button>
                                                            </span>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="0">
                                                            <Card.Body>
                                                                <ul>
                                                                    <li className="green">Members are entitled to claim this bonus once per day.</li>
                                                                    <li className="red">Members latest deposit must proceed without any promotions.</li>
                                                                    <li className="red">Members only can claim one time 5% cash back based on his last deposit.</li>
                                                                    <li className="green">Members' wallet balance needs to be less than MYR10 to claim this bonus.</li>
                                                                    <li className="red">Members must claim within 24 hours according to latest deposit time.</li>
                                                                    <li className="red">Latest transaction is not a withdrawal.</li>
                                                                    <li className="yellow">Your claim will undergo a final checking to make sure all matches have settle down. Please make sure there is no pending bet or on-going bet, otherwise it will be rejected.</li>
                                                                </ul>
                                                                <div className="show565scr">
                                                                    <br/>
                                                                    <button className="btn btn-primary login_btn w-100" style={{padding: '8px 20px', fontSize: '14px',height: '42px'}}>CLAIM</button>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                    <br/>
                                                    <Card>
                                                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => this.changeToggleState2(!this.state.tog2_state)}>
                                                            <span className="toggle text-center" style={!this.state.tog2_state ? {} : { display: 'none' }}>+</span><span className="toggle text-center" style={this.state.tog2_state ? {} : { display: 'none' }}>-</span> Birthday Bonus Am I Qualify?
                                                            <span className="hide565scr" style={{float:'right'}}>
                                                                <button className="btn btn-primary login_btn" style={{padding: '8px 20px', fontSize: '12px'}}>CLAIM</button>
                                                            </span>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="1">
                                                            <Card.Body>
                                                                <ul>
                                                                    <li className="green">Members are entitled to claim this bonus once per day.</li>
                                                                    <li className="red">Members latest deposit must proceed without any promotions.</li>
                                                                    <li className="red">Members only can claim one time 5% cash back based on his last deposit.</li>
                                                                    <li className="green">Members' wallet balance needs to be less than MYR10 to claim this bonus.</li>
                                                                    <li className="red">Members must claim within 24 hours according to latest deposit time.</li>
                                                                    <li className="red">Latest transaction is not a withdrawal.</li>
                                                                    <li className="yellow">Your claim will undergo a final checking to make sure all matches have settle down. Please make sure there is no pending bet or on-going bet, otherwise it will be rejected.</li>
                                                                </ul>
                                                                <div className="show565scr">
                                                                    <br/>
                                                                    <button className="btn btn-primary login_btn w-100" style={{padding: '8px 20px', fontSize: '14px',height: '42px'}}>CLAIM</button>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div  id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                                <div className="row justify-content-center">
                                    <div className="col-md-5 text-center" style={this.state.bankAdded ? {display: 'none'} : { }}>
                                        <div>
                                            <img alt="creditcard" src={'assets/credit-card.png'}/>
                                        </div><br/><br/>
                                        <p style={{textTransform: 'capitalize',fontSize: '12px',color: 'white',opacity:0.5}}>Please take note that bank verification is optional. You may still able to withdraw your fund anytime.</p><br/>
                                        <button className="btn btn-primary login_btn" style={{fontSize: '12px',padding: '7px 28px',fontWeight:600}} onClick={() => this.addBankModal(true)}>ADD BANK ACC</button>
                                        <Modal size="lg" show={this.state.showBankModal} className="pwd_changed_modal bank_account_modal" strictmode="false">
                                            <Modal.Header closeButton onClick={() => this.addBankModal(false)}>Add Bank Account</Modal.Header>
                                            <Modal.Body>
                                                <div className="row justify-content-center" style={{height:'100%'}}>
                                                    <div className="col-md-11" style={{margin:'auto 0',textAlign:'left'}}>
                                                        <p style={{color: 'white',fontSize: '12px',opacity:0.5}}>Please upload a photo of your bank account with your full name. It can be a bank statement, e-letter statement and phone banking apps.</p>
                                                        <p style={{color: 'white',fontSize: '12px',opacity:0.5}}>Please sensor or cover up other sensitive information.</p><br/>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Account Name</label>
                                                                <input className="form-control" placeholder="Enter your name"/><br/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Bank Name</label>
                                                                <input className="form-control" placeholder="Select Bank"/><br/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Account Number</label>
                                                                <input className="form-control" placeholder="Enter account number"/><br/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Upload statement (Optional)</label>
                                                                <div style={{position:'relative',border: '3px solid #42474e',color: '#7c7e7f',borderRadius: '4px',height: '40px'}}>
                                                                    <input type="file" class="photo_upload"/>
                                                                    <div className="uploadiconn"><CloudArrowUpFill size="20" fill="#C4C6C8"/></div>
                                                                </div><br/><br/>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <button className="btn btn-primary login_btn show370scr" style={{padding: '10px 38px',fontSize: '14px'}} onClick={() => this.addBank()}>ADD BANK</button>
                                                                <span style={{color: '#FF2F28',textTransform: 'capitalize',fontWeight: '600',fontSize: '12px'}}><span style={{borderRadius: '100%',background: '#d22a25',color: '#060202',padding: '1px 6px',display: 'inline-block',lineHeight: '1.2',fontSize: '12px'}}>!</span> Please contact Live Chat if your verification status is not updated after 30 minutes.</span>
                                                                <button className="btn btn-primary login_btn hide370scr" style={{padding: '10px 38px',fontSize: '14px',float:'right'}} onClick={() => this.addBank()}>ADD BANK</button>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                    <div className="col-md-10 justify-content-center addedbanks" style={!this.state.bankAdded ? {display:'none'} : {}}>
                                        <div>
                                            <h5>Bank Accounts
                                                <button className="btn btn-primary login_btn" style={{float:'right',fontSize:'12px'}}>+ ADD BANK</button>
                                            </h5>
                                        </div><br/>
                                        <div className="row">
                                            <div className="col-md-6" style={{marginBottom:'10px'}}>
                                                <div>
                                                    <span><b>Bank of Thailand</b> <span className="spn1">Verified!</span></span><br/>
                                                    <span>Apple Chan</span><br/>
                                                    <span>***** 4905</span><br/>
                                                    <button className="btn btn-primary">Remove Account</button>
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{marginBottom:'10px'}}>
                                                <div>
                                                    <b>Krungthai Bank</b> <br/>
                                                    <span>Chan Ping Guo</span><br/>
                                                    <span>***** 3456</span><br/>
                                                    <button className="btn btn-primary signup_btn text-white" style={{borderColor:'#213953',marginRight:'10px'}}>Verify Account</button>
                                                    <button className="btn btn-primary">Remove Account</button>
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{marginBottom:'10px'}}>
                                                <div>
                                                    <span><b>Bank of Thailand</b> <span className="verifyingspn">Verifying...</span></span><br/>
                                                    <span>Apple Chan</span><br/>
                                                    <span>***** 4905</span><br/>
                                                    <button className="btn btn-primary">Remove Account</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{padding:0}} className={ this.state.tab==='referral' ? 'active show tab-pane fade tab6content' : 'tab-pane fade' } id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
                                {this.state.refstep===1 &&
                                    <div style={{position:'relative'}} className="signinref">
                                        <img alt="poker_game" style={{maxHeight:'400px'}} className="w-100" src={'assets/banner1.png'}/>
                                        <div className="activate">
                                            <h4>Sign in successful</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-5 col-md-6">
                                                    <span className="signedmsg">You have successfully sign in, press the button below to view the code.</span>
                                                    <div>
                                                        <button className="btn btn-primary login_btn" onClick={() => this.RefStep(2)}>VIEW REFERRAL</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.refstep===2 &&
                                    <div style={{position:'relative'}} className="signinref2">
                                        <img alt="poker_game" style={{maxHeight:'400px'}} className="w-100 hide654scr" src={'assets/reff.png'}/>
                                        <img alt="poker_game" style={{maxHeight:'400px'}} className="w-100 show654scr" src={'assets/banner1.png'}/>
                                        <div className="activate" style={{top: '8%',left: '0',textAlign: 'left',padding: '0 30px'}}>
                                            <h4 style={{textAlign:'left'}}>GBO Professional Referral Program</h4>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6">
                                                    <span className="signedmsg">Get paid every week, forever! Partner with GBO and receive lifetime rewards for inviting new players. Be the player and be the house, all at the same time.</span>
                                                    <div style={{border: '1px solid #FF8600',borderRadius: '2px 0 0 2px',height: '36px',display: 'flex',justifyContent: 'space-between',padding: '0px 0 0 6px'}}>
                                                        <span style={{color:'#D8A62C',paddingTop:'6px'}}>QW1234</span>
                                                        <button className="btn btn-primary login_btn" onClick={() => this.RefStep(2)}>COPY TO SHARE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{background:'#ffffff0d',padding:'24px 0',textTransform: 'initial'}}>
                                            <div className="row justify-content-center" style={{margin:0}}>
                                                <div className="col-xl-11 col-lg-11">
                                                    <h2 className="text-center" style={{fontWeight:'bold'}}>Hi, friend!</h2>
                                                    <span className="text-center" style={{display:'block'}}>This is how much referrral you've earned.</span>
                                                    <br/>
                                                    <div>
                                                        <div className="commissdiv">
                                                            <span>Earned from referrals</span><br/>
                                                            <div className="divvv1">
                                                                <h3>THB 10,000</h3>
                                                                <button className="btn btn-primary login_btn">Withdraw Commission</button>
                                                            </div><br/>
                                                            <div className="divvvborder"></div><br/>
                                                            <div className="row divvv2">
                                                                <div className="col-md-6 col-sm-6">
                                                                    <span>Commission this week</span><br/>
                                                                    <h5>THB 12,000</h5>
                                                                </div>
                                                                <div className="col-md-6 col-sm-6">
                                                                    <span>Commission last 90 days</span><br/>
                                                                    <h5>THB 50,000</h5>
                                                                </div>
                                                            </div><br/>
                                                        </div><br/><br/>
                                                        <div className="commissdiv">
                                                            <h4 className="text-center" style={{fontWeight:'bold'}}>Get THB 500 per referral to start*</h4>
                                                            <span className="text-center" style={{display:'block'}}>Then get up to THB 1000 per referral after that.</span>
                                                            <br/><br/><br/>

                                                            <div className="text-center">
                                                                <button className="btn btn-primary login_btn" onClick={() => this.RefStep(3)}>Become Agent</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.refstep===3 && 
                                    <div style={{position:'relative'}} className="signinref3">
                                        <img alt="poker_game" style={{maxHeight:'400px'}} className="w-100" src={'assets/banner1.png'}/>
                                        <div className="activate">
                                            <h4>Become our agent now!</h4>
                                            {this.state.error_msg!=='' &&
                                                <p style={{fontSize: '16px',color: '#ff2424'}}>{this.state.error_msg}</p>
                                            }
                                            <div className="row justify-content-center">
                                                <div className="col-lg-5 col-md-6">
                                                    <div className="uplinediv">
                                                        <input className="form-control" onChange={this.changeReferral} placeholder="Upline’s agent code"/>
                                                        <button className="btn btn-primary login_btn" disabled={this.state.submitDisabled} onClick={this.become_agent}>Be our Agent</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.refstep===4 && 
                                    <div style={{position:'relative'}} className="signinref3">
                                        <img alt="poker_game" style={{maxHeight:'400px'}} className="w-100" src={'assets/banner1.png'}/>
                                        <div className="activate">
                                            <h4>Become our agent now!</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-5 col-md-6" style={{textAlign:'left'}}>
                                                    <div className="uplinediv">
                                                        <span style={{fontSize: '14px',textTransform: 'initial',verticalAlign: 'sub'}}>Your referrer is {this.state.referral}</span>
                                                        <button className="btn btn-primary login_btn" onClick={() => this.enablRefLink(true)}>Contact Us</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* {!this.state.reflink ? (
                                    <div style={{position:'relative'}}>
                                        <img alt="poker_game" style={{maxHeight:'400px'}} className="w-100" src={'assets/banner1.png'}/>
                                        <div className="activate">
                                            <h4>Become our agent now!</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-5 col-md-6">
                                                    <div className="uplinediv">
                                                        <input className="form-control" placeholder="Upline’s agent code"/>
                                                        <button className="btn btn-primary login_btn" onClick={() => this.enablRefLink(true)}>Be our Agent</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ) : (
                                    <div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-9 col-md-11">
                                                <br/><br/>
                                                <h5 className="text-center" style={{paddingLeft:'0',fontWeight:'bold'}}>Referral summary</h5>
                                                <div className="reflinkdiv">
                                                    <input className="form-control"/>
                                                    <div><button className="btn btn-primary login_btn">COPY</button>
                                                    <button className="btn btn-primary">EDIT</button></div>
                                                </div>
                                                <div style={{textAlign: 'right',textTransform: 'capitalize',opacity: '0.75',fontSize: '15px'}}>* You can only edit your referral code once </div><br/><br/>
                                            </div>
                                            <div className="col-lg-11 col-md-12">
                                                <div className="commissdiv">
                                                    <span>Commission available for withdrawal</span><br/>
                                                    <div className="divvv1">
                                                        <h3>4,200฿</h3>
                                                        <button className="btn btn-primary login_btn">Withdraw Commission</button>
                                                    </div><br/>
                                                    <div className="divvvborder"></div><br/>
                                                    <div className="row divvv2">
                                                        <div className="col-md-4">
                                                            <span>Commission this week</span><br/>
                                                            <h5>4,200฿</h5>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <span>Commission last 90 days</span><br/>
                                                            <h5>THB 50,000</h5>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <span>Default downline commission</span><br/>
                                                            <h5>0%</h5>
                                                        </div>
                                                    </div><br/>
                                                    <div className="divvvborder"></div><br/>
                                                    <div className="row divvv2">
                                                        <div className="col-md-4">
                                                            <span>Your referrer username</span><br/>
                                                            <h5>ghx12093</h5>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <span>Number of agent referral</span><br/>
                                                            <h5>0</h5>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <span>Range of agent level downwards</span><br/>
                                                            <h5>2</h5>
                                                        </div>
                                                    </div><br/>
                                                    <div className="divvvborder"></div><br/>
                                                    <div className="row divvv2">
                                                        <div className="col-md-12">
                                                            <span>Your % of commission out of total profit</span><br/>
                                                            <h5>30%</h5>
                                                        </div>
                                                    </div>
                                                </div><br/>
                                            </div>
                                        </div><br/>
                                        <div>
                                            <div className="row justify-content-center" style={{margin:0}}>
                                                <div className="col-md-11">
                                                    <div className="commdetss">
                                                        <h4>Commission details</h4>
                                                        <button className="btn btn-primary login_btn">Save Commission</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable title="" columns={columns2} pagination data={data2} customStyles={customStyles}/>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                            <div style={{padding:0}} className={ this.state.tab==='agent' || (window.location.hash.substr(1)==='ex1-tabs-7') ? 'active show tab-pane fade tab7content' : 'tab-pane fade' } id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
                                <div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-9 col-md-11">
                                            <br/><br/>
                                            <h5 className="text-center" style={{paddingLeft:'0',fontWeight:'bold'}}>Agent summary</h5>
                                            <div className="reflinkdiv">
                                                <input className="form-control"/>
                                                <div><button className="btn btn-primary login_btn">COPY</button>
                                                <button className="btn btn-primary">EDIT</button></div>
                                            </div>
                                            <div className="canonlyedit" style={{textAlign: 'right',textTransform: 'capitalize',opacity: '0.75',fontSize: '15px'}}>* You can only edit your referral code once </div><br/><br/>
                                        </div>
                                        <div className="col-lg-11 col-md-12">
                                            <div className="commissdiv">
                                                <span>Commission available for withdrawal</span><br/>
                                                <div className="divvv1">
                                                    <h3>THB 10,000</h3>
                                                    <button className="btn btn-primary login_btn">Withdraw Commission</button>
                                                </div><br/>
                                                <div className="divvvborder"></div><br/>
                                                <div className="row divvv2">
                                                    <div className="col-sm-4">
                                                        <span>Commission this week</span><br/>
                                                        <h5>THB 12,000</h5><br/>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <span>Commission last 90 days</span><br/>
                                                        <h5>THB 50,000</h5><br/>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <span>Default downline commission</span><br/>
                                                        <h5>0%</h5>
                                                    </div>
                                                </div><br/>
                                                <div className="divvvborder"></div><br/>
                                                <div className="row divvv2">
                                                    <div className="col-sm-4">
                                                        <span>Your referrer username</span><br/>
                                                        <h5>Orange.123</h5><br/>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <span>Number of agent referral</span><br/>
                                                        <h5>21</h5><br/>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <span>Range of agent level downwards</span><br/>
                                                        <h5>2</h5>
                                                    </div>
                                                </div><br/>
                                                <div className="divvvborder"></div><br/>
                                                <div className="row divvv2">
                                                    <div className="col-md-12">
                                                        <span>Your % of commission out of total profit</span><br/>
                                                        <h5>30%</h5>
                                                    </div>
                                                </div>
                                            </div><br/>
                                        </div>
                                    </div><br/>
                                    <div>
                                        <div className="row justify-content-center" style={{margin:0}}>
                                            <div className="col-md-11">
                                                <div className="commdetss">
                                                    <h4>Commission details</h4>
                                                    <div>
                                                        <button className="btn btn-primary" style={{border: '1px solid #ffffffbf',borderRadius: '3px',width: '100px',background: 'transparent',marginLeft: '12px'}}>Export Excel</button>
                                                        <button className="btn btn-primary login_btn">Save Commission</button>
                                                    </div>
                                                </div>
                                                <DataTable title="" columns={columns} pagination data={data2} customStyles={customStyles}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={ this.state.tab==='security' ? 'active show tab-pane fade tab4content' : 'tab-pane fade' } id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                                <div className="row justify-content-center security_tab">
                                    <div className="col-lg-8 col-md-10">
                                        <h3 className="text-center security_headng" style={{fontSize:'24px',fontWeight:'bold'}}>MODIFY PASSWORD</h3><br/>
                                        <div style={{textTransform:'initial'}}>
                                            <p className="text-center" style={{fontSize: '12px',color: 'white',opacity:0.5}}>Changing your password will sign you out of all devices, including your phone. You will need to enter your new password on all devices.</p><br/>
                                            {this.state.success_msg!=='' &&
                                                <p className="text-center" style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>
                                            }
                                            {this.state.error_msg!=='' &&
                                                <p className="text-center" style={{fontSize: '16px',color: '#ff2424'}}>{this.state.error_msg}</p>
                                            }
                                            <div className="row">
                                                <div className="col-md-4"><label>Current Password</label></div>
                                                <div className="col-md-8" style={{position:'relative'}}><input className="form-control" type="password" placeholder="Type your current password" onChange={this.changeCurrPwd}/>
                                                    <Eye style={{position: 'absolute',right: '24px',top: '12px'}} fill="white" size="16"/>
                                                </div>
                                            </div><br/>
                                            <div className="row">
                                                <div className="col-md-4"><label>New Password</label></div>
                                                <div className="col-md-8" style={{position:'relative'}}><input className="form-control" type="password" placeholder="Type your new password" onChange={this.changeNewPwd}/>
                                                    <Eye style={{position: 'absolute',right: '24px',top: '12px'}} fill="white" size="16"/>
                                                </div>
                                            </div><br/>
                                            <div className="row">
                                                <div className="col-md-4"><label>Confirm Password</label></div>
                                                <div className="col-md-8" style={{position:'relative'}}>
                                                    <input className="form-control" type="password" placeholder="Re-type your new password" onChange={this.changeretypPwd}/>
                                                    <Eye style={{position: 'absolute',right: '24px',top: '12px'}} fill="white" size="16"/>
                                                </div>
                                            </div><br/>
                                            <div className="row">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-8">
                                                    <button className="btn btn-primary login_btn chgpwdbtn" style={{fontSize:'12px',width: '154px',height: '32px'}} onClick={this.modify_pwd} disabled={this.state.submitDisabled}>CHANGE PASSWORD</button>
                                                </div>
                                            </div><br/>
                                            <Modal size="md" show={this.state.show} className="pwd_changed_modal" strictmode="false">
                                                <Modal.Header closeButton onClick={() => this.setShow(false)}></Modal.Header>
                                                <Modal.Body style={{padding:'20px 30px'}}>
                                                    <div className="row justify-content-center" style={{height:'100%'}}>
                                                        <div className="col-md-9" style={{margin:'auto 0'}}>
                                                            <img alt="checkmark" height="104px" src={'assets/checkmark.png'}/><br/><br/><br/>
                                                            <h1 style={{fontSize:'24px',fontWeight:'bold'}}>Password Changed!</h1><br/>
                                                            <h4>The password has been successfully changed!</h4><br/><br/>
                                                            <button style={{padding: '10px 5px',fontSize: '14px'}} className="btn btn-primary login_btn btn-block w-100" onClick={() => this.setShow(false)}>Back to Profile</button><br/><br/><br/>
                                                        </div>                                                    
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={ this.state.tab==='reports' ? 'active show tab-pane fade tab5content' : 'tab-pane fade' } id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
                                
                            </div>
                        </div>
                        
                        {(this.state.tab==='accountdetails' || this.state.tab==='wallet_details') &&
                        <div>
                            <br/><br/>
                            <div className="referralbonus">
                                <div className="row justify-content-center">
                                    <div className="col-md-11">
                                        <div className="subdivv1">
                                            <h4>Referral Bonus</h4><br/>
                                            <div className="subdivv2">
                                                <span>Your referreral link</span>
                                                <div className="subdivv22">
                                                    <input placeholder={window.location.hostname+'?='+this.state.referrerInfo.referralCode} readOnly className="form-control"/>
                                                    <button className="btn btn-primary login_btn">COPY</button>
                                                </div><br/><br/>
                                                <div className="row">
                                                    <div className="col-md-4 col-sm-6">
                                                        <span>Your referrer username</span><br/>
                                                        <span className="answr">{this.state.referrerInfo.referralUsername}</span>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <span>Number of referral</span><br/>
                                                        <span className="answr">{this.state.referral_count.referral}</span>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                        <span>Total referral bonus</span><br/>
                                                        <span className="answr">3200฿</span>
                                                        <button className="btn btn-primary login_btn claimm">CLAIM</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><br/>
                                        {(JSON.parse(localStorage.getItem('userInfo'))).role==='user' &&
                                            <div className="text-center">
                                                <span style={{fontSize:'14px'}}>Claim your referral commission? <span style={{color:'#6d8daf',cursor:'pointer'}} onClick={this.becom_agnt}>Become our agent now.</span></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
          
        </div>
    );
  }
}

export default MyAccount1;