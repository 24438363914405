import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Search,Dot,Trash,PersonFill,BookmarkFill,ClipboardCheck } from 'react-bootstrap-icons';
import { makeRequest,timestampDate } from '../../helpers';
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'800'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'700!important'
    },
  },
};
class SeniorManagement extends React.Component {
	constructor(props) {
		super(props);
		this.makeRequest = makeRequest
		this.searchSenior = this.searchSenior.bind(this)
    this.printData = this.printData.bind(this)
    this.viewDeleteModal = this.viewDeleteModal.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.getUser = this.getUser.bind(this)
    this.viewUserStatusModal = this.viewUserStatusModal.bind(this)
    this.changeUserStatus = this.changeUserStatus.bind(this)
    this.updateUserStatus = this.updateUserStatus.bind(this)
    this.changeUserRole = this.changeUserRole.bind(this)
    this.changeAssignMemberGroup = this.changeAssignMemberGroup.bind(this)
    this.updateMemberGroup = this.updateMemberGroup.bind(this)
    this.updateUserRole = this.updateUserRole.bind(this)
	  }
	  state={
		senior_details:[],
    memberGroup:[],
    showDeleteModal:false,
    delGameId:'',
    submitDisabled1:false,
    error_msg1:'',
    success_msg1:'',
    error_msg2:'',
    success_msg2:'',
    error_msg3:'',
    success_msg3:'',
    error_msg4:'',
    success_msg4:'',
    userStatusId:'',
    roleId:'',
    assignId:'',
    showRoleModal:false,
    showMemberModal:false,
    showStatusModal:false,
    userStatus:'',
    userRole:'',
    assignMemberGroup:'',
	  }
    printData(){
      window.print();
    }
    viewDeleteModal(val,modal_value){
      this.setState({delGameId: val})
      this.setState({showDeleteModal: modal_value})
    }
    viewUserStatusModal(val,modal_value){
      this.setState({
        success_msg2:'',error_msg2:'',submitDisabled1:false
      })
      this.setState({userStatusId: val})
      this.setState({showStatusModal: modal_value})
    }
    viewAssignMemberModal(val,modal_value){
      this.setState({
        success_msg4:'',error_msg4:'',submitDisabled1:false
      })
      this.setState({assignId: val})
      this.setState({showMemberModal: modal_value})
    }
    viewUserRoleModal(val,modal_value){
      this.setState({
        success_msg3:'',error_msg3:'',submitDisabled1:false
      })
      this.setState({roleId: val})
      this.setState({showRoleModal: modal_value})
    }
    changeUserStatus(e){
      this.setState({userStatus: e.target.value})
    }
    changeUserRole(e){
      this.setState({userRole: e.target.value})
    }
    changeAssignMemberGroup(e){
      this.setState({assignMemberGroup: e.target.value})
    }
    async deleteUser(){
    this.setState(state => ({submitDisabled1:true}));
    let result1=await this.makeRequest('user/'+this.state.delGameId, 'delete', '',localStorage.getItem('role_token'));
    
    if(result1.status===200){
      this.setState({
        success_msg1:'Member deleted successfully.',error_msg1:'',submitDisabled1:false
      })
      this.setState({showDeleteModal: false})
      this.getUser();
    }
    else if(result1.status===400){
      this.setState(state => ({error_msg1:result1.data.message,submitDisabled1:false}));
    }
    else if(result1.status===500)
      this.setState(state => ({error_msg1:'Some problem occured. Please try again.',submitDisabled1:false}));
   
  }
  async updateUserStatus(){
    if(this.state.userStatus===''){
      this.setState(state => ({error_msg2:'Please choose status.'}));
    }else{
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({status: this.state.userStatus});
      let result1=await this.makeRequest('user/'+this.state.userStatusId+'/status', 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg2:'Status updated successfully.',error_msg2:'',submitDisabled1:false
        })
        this.getUser();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg2:result1.data.message,submitDisabled1:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg2:'Some problem occured. Please try again.',submitDisabled1:false}));
    }
  }
  async updateMemberGroup(){
    if(this.state.assignMemberGroup===''){
      this.setState(state => ({error_msg4:'Please choose member group.'}));
    }else{
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({groupId: this.state.assignMemberGroup});
      let result1=await this.makeRequest('user/'+this.state.assignId+'/member-group', 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg4:'Member group assigned successfully.',error_msg4:'',submitDisabled1:false
        })
        this.getUser();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg4:result1.data.message,submitDisabled1:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg4:'Some problem occured. Please try again.',submitDisabled1:false}));
    }
  }
  async updateUserRole(){
    if(this.state.userRole===''){
      this.setState(state => ({error_msg3:'Please choose role.'}));
    }else{
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({role: this.state.userRole});
      let result1=await this.makeRequest('user/'+this.state.roleId+'/role', 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg3:'Status updated successfully.',error_msg3:'',submitDisabled1:false
        })
        this.getUser();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg3:result1.data.message,submitDisabled1:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg3:'Some problem occured. Please try again.',submitDisabled1:false}));
    }
  }
  async getUser(){
    var result1=await this.makeRequest('user?role=senior', 'get','',localStorage.getItem('role_token'));
    if(result1.status===200){
      this.setState({senior_details: result1.data.data});
    }
  }
    async searchSenior(e){
        if(e.target.value!==''){
        let result1=await this.makeRequest('user?role=senior&username='+e.target.value, 'get','',localStorage.getItem('role_token'));
        if(result1.status===200){
          this.setState(state => ({senior_details:result1.data.data}));
        }
      }else{
        this.getUser();
      }
    }
		componentDidMount() {
			(async () => {
			  var result1=await this.makeRequest('user?role=senior', 'get','',localStorage.getItem('role_token'));
			  if(result1.status===200){
				this.setState({senior_details: result1.data.data});
			  }
			})();
			(async () => {
        var result1=await this.makeRequest('member-group', 'get','','');
        if(result1.status===200){
          this.setState({memberGroup: result1.data.data});
        }
      })();
		} 
  render() {
	var data = this.state.senior_details.map(function(item) {
		return {
			key: item.id,
			name:item.fullname,
			phone: item.phone,
			email: item.email,
			status: item.status,
			pause_bet: item.betStatus,
			amount: (JSON.parse(item.balance)).value,
			currency: (JSON.parse(item.balance)).currency,
			reg_details: timestampDate(item.createdAt),
			last_login: timestampDate(item.lastLogin),
			last_login_ip: item.lastLoginIp,
			agent_code: item.referralCode,
			memberGroup: item.memberGroup
		};
	});
    const columns = [
      {name: 'Member Name',selector: 'id',width:"120px",cell: row => <div data-tag="allowRowEvents" className="member_head" onClick={()=>this.props.history.push('/admin/senior_details#'+row.key)} style={{color:'#1890ff',cursor:"pointer"}}>{row.name}</div>},
      {name: 'Contact No.',selector: 'misc',width:"120px",cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>{row.phone}</div>},
      {name: 'Email',selector: 'total_records',width:"150px",cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>{row.email}</div>},
      {name: 'VIP level',width:"80px",selector: 'total_claims',cell: row => <div data-tag="allowRowEvents">{row.memberGroup!==null ? (JSON.parse(row.memberGroup)).name:''}</div>},
      {name: 'Agent Code',width:"100px",selector: 'total_claims',cell: row => <div data-tag="allowRowEvents">{row.agent_code}</div>},
      {name: 'Status',selector: 'total_records',width:"100px",cell: row => <div data-tag="allowRowEvents" style={{marginLeft:'-16px'}}><Dot color="green" size={30}/> {row.status}</div>},
      {name: 'Pause Bet',selector: 'total_records',width:"100px",cell: row => <div data-tag="allowRowEvents" style={{marginLeft:'-16px'}}><Dot color="green" size={30}/> {row.pause_bet}</div>},
      {name: 'Register Details',selector: 'total_records',width:"150px",cell: row => <div data-tag="allowRowEvents">{row.reg_details} <p className="p_text"></p><p className="reg_det_btn">test</p></div>},
      {name: 'Last Login Details',selector: 'total_records',width:"170px",cell: row => <div data-tag="allowRowEvents">{row.last_login} <p className="p_text">{row.last_login_ip}</p><p className="log_det_btn">test</p></div>},
      {name: 'Total Balance',selector: 'total_records',cell: row => <div data-tag="allowRowEvents">{row.currency+' '+(row.amount).toFixed(2)}</div>},
      {name: 'Action',selector: 'total_balance',cell: row => 
        <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}} className="table_action_icons">
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Update user's role" onClick={() => this.viewUserRoleModal(row.key,true)}><PersonFill color="#0a58ca" size={20}/></span>
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Assign member group for user" onClick={() => this.viewAssignMemberModal(row.key,true)}><ClipboardCheck color="#FFAC2A" size={18}/></span>
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Update user status" onClick={() => this.viewUserStatusModal(row.key,true)}><BookmarkFill color="purple" size={18}/></span>
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Delete user" onClick={() => this.viewDeleteModal(row.key,true)}><Trash color="red" size={18}/></span>
        </div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9 plo_right" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}} className="print_hide">
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; xx</span> / 
                    <span>xx</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Senior Management
                  <button className="btn btn-primary add_btn" style={{width:'auto',marginLeft:"16px"}}><Link to="/admin/create_member">Create</Link></button>
                  </h5>
                </div>
              </div>
            </div>
            <div className="divbody">
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="row print_hide">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                    <span className="subdvv1_1_1 mem_stat_left">
                        <input type="text" className="form-control" style={{display: 'inline-block',height: '31px',fontSize: '14px'}} placeholder="Search by username" onChange={this.searchSenior}/>
                        <Search color="#00000040" style={{top:'4px'}} size={15}/>
                    </span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">   
                    <div className="mem_stat_btns" style={{float:'right',marginBottom:'10px'}}>
                        <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}}>Export to Excel</button>
                        <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                    </div>
                  </div>
                </div> 
                {this.state.success_msg1!=='' ?
                  (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg1}</p>):('')
                }
                {this.state.error_msg1!=='' ?
                  (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg1}</p>):('')
                }
                <div id="divcontents">
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} id="print_area"/>
                </div> 
              </div>
            </div>
          </div>
        </div>
        {/*delete modal*/}
        <Modal size="md" show={this.state.showDeleteModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewDeleteModal('',false)}>Delete member</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              <h4>Are you sure</h4>
              <p>You want to delete this member ?</p>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewDeleteModal('',false)} className="del_no_btn">No</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.deleteUser} disabled={this.state.submitDisabled}>Yes</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
        {/*status modal*/}
        <Modal size="md" show={this.state.showStatusModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewUserStatusModal('',false)}>Update status</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              {this.state.success_msg2!=='' ?
                (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg2}</p>):('')
              }
              {this.state.error_msg2!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg2}</p>):('')
              }
              <select className="form-control" onChange={this.changeUserStatus} value={this.state.userStatus}>
                  <option value="">Choose status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="blocked">Block</option>
              </select>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewUserStatusModal('',false)} className="del_no_btn">Cancel</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateUserStatus} disabled={this.state.submitDisabled}>Update</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
        {/*role modal*/}
        <Modal size="md" show={this.state.showRoleModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewUserRoleModal('',false)}>Update role</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              {this.state.success_msg3!=='' ?
                (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg3}</p>):('')
              }
              {this.state.error_msg3!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg3}</p>):('')
              }
              <select className="form-control" onChange={this.changeUserRole} value={this.state.userRole}>
                  <option value="">Choose role</option>
                  <option value="user">User</option>
                  <option value="senior">Senior</option>
                  <option value="master">Master</option>
                  <option value="agent">Agent</option>
              </select>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewUserRoleModal('',false)} className="del_no_btn">Cancel</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateUserRole} disabled={this.state.submitDisabled}>Update</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
        {/*member modal*/}
        <Modal size="md" show={this.state.showMemberModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewAssignMemberModal('',false)}>Assign member group</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
            {this.state.success_msg4!=='' ?
              (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg4}</p>):('')
            }
            {this.state.error_msg4!=='' ?
              (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg4}</p>):('')
            }
            <select className="form-control" onChange={this.changeAssignMemberGroup} value={this.state.assignMemberGroup}>
                <option value="">Choose member group</option>
                {this.state.memberGroup && this.state.memberGroup.map(data => (
                  <option value={data.id} key={data.id}>{data.name}</option>
                ))}
            </select>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewAssignMemberModal('',false)} className="del_no_btn">Cancel</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateMemberGroup} disabled={this.state.submitDisabled}>Assign</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
      </div>
    );
  }
}
export default SeniorManagement;