import React from "react";
import { Globe,Envelope,Dot,ChevronDown,Person,BoxArrowRight,Justify } from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
import { makeRequest,currentDate } from '../../helpers';
import eventBus from "../../eventBus";
class AdminNav extends React.Component {
  constructor(props) {
      super(props);
      this.makeRequest = makeRequest
      this.date_time = currentDate
      this.langPopupDiv = this.langPopupDiv.bind(this)
      this.userPopupDiv = this.userPopupDiv.bind(this)
      this.logout = this.logout.bind(this)
      this.changeSidebar = this.changeSidebar.bind(this)
      this.state = {
      showLangPopupDiv:false,
      showUserPopupDiv:false,
      userInfo:[],
      viewSidebar:'',
      windowWidth:window.innerWidth,
    }
  }
  componentDidMount() {
    if(this.state.windowWidth<='767'){
      this.setState({ viewSidebar:false})
    }else{
      this.setState({ viewSidebar:true})
    }
    if(localStorage.getItem('role_token')==='undefined' || localStorage.getItem('role_token')===null || localStorage.getItem('role_token')===''){
      this.props.prop.history.push('/admin/admin_login');
    }else{
      if(localStorage.getItem('role')==='owner'){
        (async () => {
          var result1=await this.makeRequest('manager/me', 'get','',localStorage.getItem('role_token'));
          if(result1.status===200){
            this.setState(state => ({userInfo: result1.data.user}));
          }
        })();
      }
    }
	} 
  langPopupDiv(val){
    this.setState(state => ({showLangPopupDiv: val}));
  }
  changeSidebar(){
      this.setState(state => ({viewSidebar: true}));
      eventBus.dispatch("viewSidebar", { message: true});
  }
  userPopupDiv(val){
    this.setState(state => ({showUserPopupDiv: val}));
  }
  logout(val){
    if(val){
      let role=localStorage.getItem('role');
      localStorage.removeItem("role");
      localStorage.removeItem("role_token");
      if(role==='provider'){
        this.props.prop.history.push('/admin/admin_login');
      }else{
        this.props.prop.history.push('/admin/owner_login');
      }
    }
  }
  render() {
    return (
      <div className="toppnavv">
        <ul>
          <li className="navbar_toggle" style={{display:"none"}} onClick={this.changeSidebar}><span><Justify color="#000000" size={30}/></span></li>
          <li>
            <span style={{position:'relative'}}><Dot className="notific" color="#FF711D" size={60}/><Envelope color="black" size={18} style={{verticalAlign:'text-bottom',marginRight:'18px'}}/></span>
            <span className="langspan">
              {this.state.showLangPopupDiv &&
                <div className="text-center langss">
                  <div>
                    <img alt="th" src={'../assets/th.png'}/><br/>
                    <span>Thai</span>
                  </div>
                  <div>
                    <img alt="en" src={'../assets/gb.png'}/><br/>
                    <span>EN</span>
                  </div>
                  <div>
                    <img alt="cn" src={'../assets/cn.png'}/><br/>
                    <span>CN</span>
                  </div>
                </div>
              }
              <Globe onClick={()=>this.langPopupDiv(!this.state.showLangPopupDiv)} color="black" size={17} style={{verticalAlign:'sub',cursor:'pointer'}}/>
            </span>
          </li>
          <li className="date_time">{this.date_time(this)}</li>
          <li style={{position:'relative',cursor:'pointer'}}>
            <span onClick={()=>this.userPopupDiv(!this.state.showUserPopupDiv)}>{localStorage.getItem('role')==='owner' ? this.state.userInfo.fullname : 'Provider'} <ChevronDown style={{marginLeft:'10px'}} color="black" size={16}/></span>
            {this.state.showUserPopupDiv &&
              <div className="user_dropdown">
                {localStorage.getItem('role')==='owner' ?
                (<div className="text-center sdv1">
                  <h6>{this.state.userInfo.fullname}</h6>
                  <span>{this.state.userInfo.email}</span>
                </div>):
                (
                  <div className="text-center sdv1">
                    <h6>Provider</h6>
                    <span>provider@gmail.com</span>
                  </div>
                )}
                <hr/>
                <div className="sdv2">
                {localStorage.getItem('role')==='owner' ?
                  (<Link className="a" to="/admin/personal_settings"><Person color="black" size={16}/> Personal Settings</Link>):('')}
                  <Link className="a" to="/admin/change_password"><Person color="black" size={16}/> Change Password</Link>
                  <span className="a" onClick={()=>this.logout(true)} style={{cursor:'pointer'}}><BoxArrowRight style={{marginLeft:'2px'}} color="black" size={14}/> Logout</span>
                </div>
              </div>
            }
          </li>
        </ul>
      </div>
    );
  }
}

export default AdminNav;