import React from "react";
import {Link} from 'react-router-dom';

class ReferTo extends React.Component { 
  render() {
    return (
      <div style={{ background: '#000001', color: '#D6A42B',height:'100vh' }}>
        <div className="container" style={{height:'100%'}}>
            <div className="row justify-content-center" style={{height:'100%'}}>
                <div className="col-md-12" style={{margin:'auto',textAlign:'center'}}>
                    <Link to={'/create_account#referral' } style={{textDecoration:'none', color: '#D6A42B'}}><h1>LOGIN / SIGN UP</h1></Link>
                    {/* <Link to={'/create_account/origin/referral' } style={{textDecoration:'none', color: '#D6A42B'}}><h1>LOGIN / SIGN UP</h1></Link> */}
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default ReferTo;