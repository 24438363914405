import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import {Search} from 'react-bootstrap-icons'
import { CSVLink } from "react-csv";
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,bank_name: "OCBC",acc_name:"CCC",acc_no:"7485444444",deposit:"2.00",withdr:"0.00",cost:"0.00"},
  {id: 2,bank_name: "OCBC",acc_name:"CCC",acc_no:"7485444444",deposit:"2.00",withdr:"0.00",cost:"0.00"},
  {id: 3,bank_name: "OCBC",acc_name:"CCC",acc_no:"7485444444",deposit:"2.00",withdr:"0.00",cost:"0.00"},
  {id: 4,bank_name: "OCBC",acc_name:"CCC",acc_no:"7485444444",deposit:"2.00",withdr:"0.00",cost:"0.00"},
  {id: 5,bank_name: "OCBC",acc_name:"CCC",acc_no:"7485444444",deposit:"2.00",withdr:"0.00",cost:"0.00"},
  {id: "Total",bank_name: "",acc_name:"",acc_no:"",deposit:"216",withdr:"0.00",cost:"0.00"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const excelData = [
  {
    "Bank Name": "OCBC",
    "Bank Account Name": "CCC",
    "Bank Account No.": '7485444444',
    "Total Deposit": '2.00',
    "Total Withdrawal": '0.00',
    "Total Cost": 0.00,
  },
  {
    "Bank Name": "OCBC",
    "Bank Account Name": "CCC",
    "Bank Account No.": '7485444444',
    "Total Deposit": '2.00',
    "Total Withdrawal": '0.00',
    "Total Cost": 0.00,
  },
  {
    "Bank Name": "OCBC",
    "Bank Account Name": "CCC",
    "Bank Account No.": '7485444444',
    "Total Deposit": '2.00',
    "Total Withdrawal": '0.00',
    "Total Cost": 0.00,
  },
  {
    "Bank Name": "OCBC",
    "Bank Account Name": "CCC",
    "Bank Account No.": '7485444444',
    "Total Deposit": '2.00',
    "Total Withdrawal": '0.00',
    "Total Cost": 0.00,
  }
]
const headers = [
  { label: "Bank Name", key: "bank_name" },
  { label: "Bank Account Name", key: "acc_name" },
  { label: "Bank Account No.", key: "acc_no" },
  { label: "Total Deposit", key: "deposit" },
  { label: "Total Withdrawal", key: "withdr" },
  { label: "Total Cost", key: "cost" },
];
class DailyBankReport extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.printData = this.printData.bind(this)
    this.exportData = this.exportData.bind(this)
    }
    printData(){
      window.print();
    }
    exportData(){
      exportFromJSON({ data: excelData, fileName: 'Daily bank report', exportType: exportFromJSON.types.xls})
    }
  render() {
    const columns = [
        {name: 'No.',selector: 'id',width:'60px'},
        {name: 'Bank Name',selector: 'bank_name'},
        {name: 'Bank Account Name',selector: 'acc_name'},
        {name: 'Bank Account No.',selector: 'acc_no'},
        {name: 'Total Deposit',selector: 'deposit'},
        {name: 'Total Withdrawal',selector: 'withdr'},
        {name: 'Total Cost',selector: 'cost'},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}} className="print_hide">
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Bank management</span> / 
                    <span>Daily Bank Report</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Daily Bank Report</h5>
                </div>
              </div><br/>
              
                <div className="divbody print_hide">
                    <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                        <h6 style={{fontWeight:600,margin:0}}>Search Criteria</h6>
                    </div>
                    <div className="row credit_hist member_listing" style={{margin:0,padding:'20px 14px',background:'white'}}>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1"> 
                          <label className="labls"><span style={{minWidth:"84px"}}>Date Range:</span> 
                              <input type="date" className="form-control input_width_100"/>
                          </label>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">     
                          <label className="labls"><span>Bank Account Name:</span> <input type="text" className="form-control input_width_100"/></label>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1"> 
                          <label className="labls"><span>Bank Account No.:</span> 
                              <input type="text" className="form-control input_width_100"/>
                          </label>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">     
                          <label className="labls"><button className="btn btn-primary add_btn">Search</button>
                          <button className="btn btn-primary add_btn cancel_btn">Reset</button></label>
                      </div>
                    </div>
                </div><br/>
                <div className="divbody">
                  <div className="trans_tabl daily_report_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                      <span className="subdvv1_1_1 print_hide">
                          <input type="text" className="form-control search" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Search"/>
                          <Search color="#00000040" style={{top:'4px'}} size={15}/>
                      </span>
                      <div className="top_btns print_hide" style={{float:'right',marginBottom:'10px'}}>
                          <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                          <CSVLink data={data} headers={headers} className="btn btn-primary add_btn cancel_btn">CSV</CSVLink>;
                          <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                      </div>
                      <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DailyBankReport;