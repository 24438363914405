const data = [
  {
    id: 1,
    datetime: "13/05/2021 12:45",
    trans_type: "Deposit",
    amount: "THB 50.000",
    status: "Success",
  },
  {
    id: 2,
    datetime: "13/05/2021 12:45",
    trans_type: "Deposit",
    amount: "THB 50.000",
    status: "Success",
  },
  {
    id: 3,
    datetime: "13/05/2021 12:45",
    trans_type: "Deposit",
    amount: "THB 50.000",
    status: "Success",
  },
  {
    id: 4,
    datetime: "13/05/2021 12:45",
    trans_type: "Deposit",
    amount: "THB 50.000",
    status: "Success",
  },
  {
    id: 5,
    datetime: "13/05/2021 12:45",
    trans_type: "Deposit",
    amount: "THB 50.000",
    status: "Success",
  },
  {
    id: 6,
    datetime: "13/05/2021 12:45",
    trans_type: "Deposit",
    amount: "THB 50.000",
    status: "Success",
  },
  {
    id: 7,
    datetime: "13/05/2021 12:45",
    trans_type: "Deposit",
    amount: "THB 50.000",
    status: "Success",
  },
  {
    id: 8,
    datetime: "13/05/2021 12:45",
    trans_type: "Deposit",
    amount: "THB 50.000",
    status: "Success",
  },
  {
    id: 9,
    datetime: "13/05/2021 12:45",
    trans_type: "Deposit",
    amount: "THB 50.000",
    status: "Success",
  },
  {
    id: 10,
    datetime: "13/05/2021 12:45",
    trans_type: "Deposit",
    amount: "THB 50.000",
    status: "Success",
  },
];
export default data;
