import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import {Link} from 'react-router-dom';

class CockFighting extends React.Component {
  render() {
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{background:'#000C16',color:'white',paddingTop: '140px'}} className="cockfightngdiv">
            <div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 text-center">
                    <h1 className="text-white text-center homeheadng">COCK FIGHTING</h1>
                    <div className="text-center">
                      <img alt="all games here" src={'assets/Group44.png'}/>
                    </div><br/><br/>
                    <img alt="bullhorn" className="bullhorn" src={'assets/bullhorn.png'}/><br/><br/>
                    <p style={{fontSize: '18px',color: '#DEE2E6',lineHeight:'22px',fontWeight:400}}>We look forward to bringing you this innovative new gaming experience soon.</p>
                    <Link to="/" className="bck2home">Back to Home</Link>
                  </div>
                </div>
              </div>
            </div>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
        </div>
    );
  }
}

export default CockFighting;
