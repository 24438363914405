import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search,ChevronUp,Dot } from 'react-bootstrap-icons';
const data = [
  {id: 1,trans_date: "2021-02-26 14:53:05",trans_id:"11656",account_id:"dclient02",account_name:"dclient dmt",group:"VIP1A",Email:"democlient02@mailnator.com",trans_type:"Deposit",details:"MYPJ",transfer_to:"-",status:"Confirmed",debit:"-",credit:"25.00",fees:"0.00",approved_by:"cdemo001",remark:""},
  {id: 2,trans_date: "2021-02-26 14:53:05",trans_id:"11656",account_id:"dclient02",account_name:"dclient dmt",group:"VIP1A",Email:"democlient02@mailnator.com",trans_type:"Deposit",details:"MYPJ",transfer_to:"-",status:"Confirmed",debit:"-",credit:"25.00",fees:"0.00",approved_by:"cdemo001",remark:""},
  {id: 3,trans_date: "2021-02-26 14:53:05",trans_id:"11656",account_id:"dclient02",account_name:"dclient dmt",group:"VIP1A",Email:"democlient02@mailnator.com",trans_type:"Deposit",details:"MYPJ",transfer_to:"-",status:"Confirmed",debit:"-",credit:"25.00",fees:"0.00",approved_by:"cdemo001",remark:""},
  {id: 4,trans_date: "2021-02-26 14:53:05",trans_id:"11656",account_id:"dclient02",account_name:"dclient dmt",group:"VIP1A",Email:"democlient02@mailnator.com",trans_type:"Deposit",details:"MYPJ",transfer_to:"-",status:"Confirmed",debit:"-",credit:"25.00",fees:"0.00",approved_by:"cdemo001",remark:""},
  {id: 5,trans_date: "2021-02-26 14:53:05",trans_id:"11656",account_id:"dclient02",account_name:"dclient dmt",group:"VIP1A",Email:"democlient02@mailnator.com",trans_type:"Deposit",details:"MYPJ",transfer_to:"-",status:"Confirmed",debit:"-",credit:"25.00",fees:"0.00",approved_by:"cdemo001",remark:""},
  {id: 6,trans_date: "2021-02-26 14:53:05",trans_id:"11656",account_id:"dclient02",account_name:"dclient dmt",group:"VIP1A",Email:"democlient02@mailnator.com",trans_type:"Deposit",details:"MYPJ",transfer_to:"-",status:"Confirmed",debit:"-",credit:"25.00",fees:"0.00",approved_by:"cdemo001",remark:""},
  
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class TransactionHistoryy extends React.Component {
  render() {
    const columns = [
      {name: 'No.',width:'40px',selector: 'id',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Transaction Date',width:'150px',selector: 'trans_date',cell: row => <div data-tag="allowRowEvents">2021-02-26 14:53:06</div>},
      {name: 'Trans. ID',width:'80px',selector: 'trans_id',cell: row => <div data-tag="allowRowEvents" style={{fontWeight:"800",cursor:"pointer"}} className="trans_id"><Link to="/admin/transaction_detail">11655</Link></div>},
      {name: 'Account ID',width:'80px',selector: 'account_id',cell: row => <div data-tag="allowRowEvents">dclient02</div>},
      {name: 'Account Name',width:'110px',selector: 'account_name',cell: row => <div data-tag="allowRowEvents">dclient dmt</div>},
      {name: 'Group',width:'60px',selector: 'group',cell: row => <div data-tag="allowRowEvents">VIP1A</div>},
      {name: 'Email',width:'180px',selector: 'email',cell: row => <div data-tag="allowRowEvents">democlient02@mailnator.com</div>},
      {name: 'Transaction Type',width:'120px',selector: 'trans_type',cell: row => <div data-tag="allowRowEvents">Deposit</div>},
      {name: 'Details',width:'60px',selector: 'details',cell: row => <div data-tag="allowRowEvents">MYPJ</div>},
      {name: 'Transfer (To)',width:'110px',selector: 'transfer_to',cell: row => <div data-tag="allowRowEvents">-</div>},
      {name: 'Status',width:'110px',selector: 'status',cell: row => <div data-tag="allowRowEvents" style={{marginLeft:"-16px"}}><Dot color="green" size={30}/> Confirmed</div>},
      {name: 'Debit',width:'60px',selector: 'debit',cell: row => <div data-tag="allowRowEvents">-</div>},
      {name: 'Credit',width:'60px',selector: 'credit',cell: row => <div data-tag="allowRowEvents">25.00</div>},
      {name: 'Fees',width:'60px',selector: 'fees',cell: row => <div data-tag="allowRowEvents">0.00</div>},
      {name: 'Approved By',width:'100px',selector: 'approved_by',cell: row => <div data-tag="allowRowEvents">cdemo001</div>},
      {name: 'Remark',selector: 'remark',cell: row => <div data-tag="allowRowEvents"></div>},
    ];
    return (
      <div>  
          <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
            <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
              <AdminSidebar prop={this.props}/>
            </div>
            <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
              <div style={{padding:'0px'}}>
                <div style={{background: 'white'}}>
                  <AdminNav prop={this.props}/>
                  <div style={{padding: '18px 20px'}}>
                    <div className="routeinfo">
                      <span>Home</span> / 
                      <span>&nbsp; Transactions</span> / 
                      <span>All transaction history</span>
                    </div>
                    <h5 className="pg_head" style={{marginBottom:'0'}}>All Transaction History</h5>
                  </div>
                </div>
              </div>

                <div className="divbody" style={{background:'white',margin:"16px"}}>
                  <div className="row history_row">
                    <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <span className="checkspan">
                          <input type="checkbox"/> <span>Transaction Type: </span>
                      </span>                    
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <div className="trans_historyy">
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Deposit</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Withdrawal</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Promotion</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Adjustment In.</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Adjustment Out</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Rebate</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Product Promotion</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Cash Back</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Referrer Rebate</span>
                            </span>
                        </div>               
                    </div>
                  </div>
                  <div className="row history_row"> 
                    <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <span className="checkspan">
                          <input type="checkbox"/> <span>Record Type: </span>
                      </span>                    
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <div className="trans_historyy">
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Credit</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Debit</span>
                            </span>
                        </div>             
                    </div>
                  </div>
                  <div className="row history_row">  
                    <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <span className="checkspan">
                          <input type="checkbox"/> <span>Status: </span>
                      </span>                    
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <div className="trans_historyy">
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>New Request</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Processing</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Confirmed</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Rejected</span>
                            </span>
                        </div>              
                    </div>
                    </div>
                    <div className="row history_row">
                      <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <span className="checkspan">
                            <input type="checkbox"/> <span>Member Group: </span>
                        </span>                    
                      </div>
                      <div className="col-xl-10 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                          <div className="trans_historyy">
                              <span className="checkspan twos">
                                  <input type="checkbox"/> <span>VIP1A</span>
                              </span>
                              <span className="checkspan twos">
                                  <input type="checkbox"/> <span>High Risk</span>
                              </span>
                              <span className="checkspan twos">
                                  <input type="checkbox"/> <span>VIP Member</span>
                              </span>
                              <span className="checkspan twos">
                                  <input type="checkbox"/> <span>Silver Member</span>
                              </span>
                              <span className="checkspan twos">
                                  <input type="checkbox"/> <span>Rejected</span>
                              </span>
                              <span className="checkspan twos">
                                  <input type="checkbox"/> <span>Bronze Member</span>
                              </span>
                              <span className="checkspan twos">
                                  <input type="checkbox"/> <span>Diamond Member</span>
                              </span>
                              <span className="checkspan twos">
                                  <input type="checkbox"/> <span>New</span>
                              </span>
                              <span className="checkspan twos">
                                  <input type="checkbox"/> <span>Monitor new</span>
                              </span>
                          </div>           
                      </div>
                    </div>

                    <div className="row trans_historyy" style={{margin:0,padding:'30px 0px 0px',background:'white'}}>
                  
                      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"82px"}}>Date Range:</span>
                          <input type="date" className="form-control"/></label>
                      </div>
                      <div className="col-lg-3 col-md-5 col-sm-5 col-xs-11 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"92px"}}>Transaction ID:</span>
                          <input type="text" className="form-control"/></label>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1" style={{paddingLeft:"0px"}}>
                          <label className="labls labls2"><span style={{minWidth:"18px"}}>To</span>
                          <input type="text" className="form-control"/></label>
                      </div>
                      <div className="col-lg-3 col-md-5 col-sm-5 col-xs-11 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"102px"}}>Amount Criteria:</span>
                          <input type="text" className="form-control"/></label>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 top_div1" style={{paddingLeft:"0px"}}>
                          <label className="labls labls2"><span style={{minWidth:"18px"}}>To</span>
                          <input type="text" className="form-control"/></label>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"128px"}}>Bank Account Name:</span>
                          <select className="form-control">
                              <option>Select</option>
                          </select></label>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"92px"}}>Account ID:</span>
                          <input type="text" className="form-control"/></label>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"40px"}}>Email:</span>
                          <input type="text" className="form-control"/></label>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"84px"}}>Approved By:</span>
                          <input type="text" className="form-control"/></label>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"128px"}}>Transaction Channel:</span>
                          <select className="form-control">
                              <option>All</option>
                          </select></label>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                          <label className="labls labls2"><span style={{minWidth:"78px"}}>Agent Code:</span>
                          <input type="text" className="form-control"/></label>
                      </div>
                      <br/>
                      <div style={{marginTop:"20px",paddingLeft:"0"}}>
                          <button className="btn btn-primary add_btn cancel_btn">Reset</button>
                          <button className="btn btn-primary add_btn" style={{width:'auto',marginLeft:"20px"}}>Search</button>
                      </div>
                  
                    </div><br/>
                </div>
                <div className="trans_tabl" style={{margin:"16px",padding:'20px 14px',background:'white'}}>
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <span className="subdvv1_1_1 mem_stat_left">
                          <input type="text" className="form-control" style={{display: 'inline-block',height: '31px',fontSize: '14px'}} placeholder="Search"/>
                          <Search color="#00000040" style={{top:'4px'}} size={15}/>
                      </span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                      <div className="mem_stat_btns" style={{float:'right',marginBottom:'10px'}}>
                          <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}}>Export to Excel</button>
                          <button className="btn btn-primary add_btn cancel_btn">CSV</button>
                          <button className="btn btn-primary add_btn cancel_btn">Print</button>
                      </div>
                    </div> 
                  </div>   
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>


            </div>
            </div>
      </div>
    );
  }
}
export default TransactionHistoryy;