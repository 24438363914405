import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
import { CSVLink } from "react-csv";
import exportFromJSON from 'export-from-json'

const data = [
  {id: 1,trans_id: "50012345",trans_date:"2021-03-08 12:23:45",account_id:"NameID",from:"Main",from_bal_before:"50,000.00",from_bal_after:"45,000.00",to:"Playtech",to_bal_before:"0.00",to_bal_after:"5000.00",transfer_amount:"5000.00",status:"success"},
  {id: 2,trans_id: "50012345",trans_date:"2021-03-08 12:23:45",account_id:"NameID",from:"Main",from_bal_before:"50,000.00",from_bal_after:"45,000.00",to:"Playtech",to_bal_before:"0.00",to_bal_after:"5000.00",transfer_amount:"5000.00",status:"success"},
  {id: 3,trans_id: "50012345",trans_date:"2021-03-08 12:23:45",account_id:"NameID",from:"Main",from_bal_before:"50,000.00",from_bal_after:"45,000.00",to:"Playtech",to_bal_before:"0.00",to_bal_after:"5000.00",transfer_amount:"5000.00",status:"success"},
  {id: 4,trans_id: "50012345",trans_date:"2021-03-08 12:23:45",account_id:"NameID",from:"Main",from_bal_before:"50,000.00",from_bal_after:"45,000.00",to:"Playtech",to_bal_before:"0.00",to_bal_after:"5000.00",transfer_amount:"5000.00",status:"success"},
  {id: 5,trans_id: "50012345",trans_date:"2021-03-08 12:23:45",account_id:"NameID",from:"Main",from_bal_before:"50,000.00",from_bal_after:"45,000.00",to:"Playtech",to_bal_before:"0.00",to_bal_after:"5000.00",transfer_amount:"5000.00",status:"success"},
  {id: 6,trans_id: "50012345",trans_date:"2021-03-08 12:23:45",account_id:"NameID",from:"Main",from_bal_before:"50,000.00",from_bal_after:"45,000.00",to:"Playtech",to_bal_before:"0.00",to_bal_after:"5000.00",transfer_amount:"5000.00",status:"success"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const excelData = [
  {
    "Transaction ID": "50012345",
    "Transaction Date": "2021-03-08 12:23:45",
    "Account ID": 'Name ID',
    "From": 'Main',
    "Bal. Before (From)": '50,000.00',
    "Bal. After (From)": '45,000.00',
    "To": 'Playtech',
    "Bal. Before (To)": 0.00,
    "Bal. After (To)": 5000.00,
    "Transfer Amount": '5000.00',
    "Status": 'Success'
  },
  {
    "Transaction ID": "50012345",
    "Transaction Date": "2021-03-08 12:23:45",
    "Account ID": 'Name ID',
    "From": 'Main',
    "Bal. Before (From)": '50,000.00',
    "Bal. After (From)": '45,000.00',
    "To": 'Playtech',
    "Bal. Before (To)": 0.00,
    "Bal. After (To)": 5000.00,
    "Transfer Amount": '5000.00',
    "Status": 'Success'
  },
  {
    "Transaction ID": "50012345",
    "Transaction Date": "2021-03-08 12:23:45",
    "Account ID": 'Name ID',
    "From": 'Main',
    "Bal. Before (From)": '50,000.00',
    "Bal. After (From)": '45,000.00',
    "To": 'Playtech',
    "Bal. Before (To)": 0.00,
    "Bal. After (To)": 5000.00,
    "Transfer Amount": '5000.00',
    "Status": 'Success'
  },
  {
    "Transaction ID": "50012345",
    "Transaction Date": "2021-03-08 12:23:45",
    "Account ID": 'Name ID',
    "From": 'Main',
    "Bal. Before (From)": '50,000.00',
    "Bal. After (From)": '45,000.00',
    "To": 'Playtech',
    "Bal. Before (To)": 0.00,
    "Bal. After (To)": 5000.00,
    "Transfer Amount": '5000.00',
    "Status": 'Success'
  }
]
const headers = [
  { label: "Trans. ID", key: "trans_id" },
  { label: "Trans. Date", key: "trans_date" },
  { label: "Account ID", key: "account_id" },
  { label: "From", key: "from" },
  { label: "Bal. Before (From)", key: "from_bal_before" },
  { label: "Bal. After (From)", key: "from_bal_after" },
  { label: "To", key: "to" },
  { label: "Bal. Before (To)", key: "to_bal_before" },
  { label: "Bal. After (To)", key: "to_bal_after" },
  { label: "Transfer Amount", key: "tranfer_amount" },
  { label: "Status", key: "status" },
];
class TransferReport extends React.Component {
  constructor(props) {
  super(props);
  this.printData = this.printData.bind(this)
  this.exportData = this.exportData.bind(this)
  }
  printData(){
    window.print();
  }
  exportData(){
    exportFromJSON({ data: excelData, fileName: 'Transfer report', exportType: exportFromJSON.types.xls})
  }
  render() {
    const columns = [
      {name: 'No.',selector: 'id',width:'50px',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Trans. ID',selector: 'trans_id',width:'90px',cell: row => <div data-tag="allowRowEvents" style={{fontWeight:"900"}}>50012345</div>},
      {name: 'Trans. Date',selector: 'trans_date',width:'100px',cell: row => <div data-tag="allowRowEvents">2021-03-08 12:23:45</div>},
      {name: 'Account ID',selector: 'account_id',width:'80px',cell: row => <div data-tag="allowRowEvents">Name ID</div>},
      {name: 'From',selector: 'from',width:'60px',cell: row => <div data-tag="allowRowEvents">Main</div>},
      {name: 'Bal. Before (From)',selector: 'from_bal_before',width:'90px',cell: row => <div data-tag="allowRowEvents">50,000.00</div>},
      {name: 'Bal. After (From)',selector: 'from_bal_after',width:'90px',cell: row => <div data-tag="allowRowEvents">45,000.00</div>},
      {name: 'To',width:'120px',selector: 'to',cell: row => <div data-tag="allowRowEvents">Playtech</div>},
      {name: 'Bal. Before (To)',width:'80px',selector: 'to_bal_before',cell: row => <div data-tag="allowRowEvents">0.00</div>},
      {name: 'Bal. After (To)',width:'90px',selector: 'to_bal_after',cell: row => <div data-tag="allowRowEvents">5000.00</div>},
      {name: 'Transfer Amount',width:'90px',selector: 'tranfer_amount',cell: row => <div data-tag="allowRowEvents">5000.00</div>},
      {name: 'Status',selector: 'status',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>Success</div>},
    ];
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}} className="print_hide">
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Report</span> / 
                    <span>Transfer Report</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Transfer Report</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="subbdvv1 print_hide" style={{padding: '14px 24px 0px 24px'}}>
                    <h6 style={{fontWeight:700,margin:0}}>Search Criteria</h6>
                  </div>
                <div className="row print_hide" style={{margin:0,padding:'12px 14px',background:'white'}}>
                
                    <div className="col-md-6 top_div">
                        <label className="labls labls1"><span>Date Range:</span></label>
                        <input type="date" className="form-control"/>
                    </div>
                    <div className="col-md-6 top_div">    
                        <label className="labls labls1"><span>Product:</span></label>
                        <select className="form-control select_size">
                                <option className="select_size">Select</option>
                        </select>
                    </div> 
                    <div className="col-md-6 top_div">   
                        <label className="labls labls1"><span>Product Type:</span></label>
                        <select className="form-control select_size">
                                <option className="select_size">All</option>
                        </select>
                    </div>
                    <div className="col-md-6 top_div">
                        <label className="labls labls1"><span>Agent Code:</span></label>
                        <input type="text" className="form-control"/>
                    </div>
                    <div className="col-md-6 top_div">   
                        <label className="labls labls1"><span>Status:</span></label>
                        <select className="form-control select_size">
                                <option className="select_size">All</option>
                        </select>
                    </div>
                    <div className="col-md-6 top_div">
                        <label className="labls labls1"><span>Account Id:</span></label>
                        <input type="text" className="form-control"/>
                    </div>
                    <br/>
                    <div className="top_second_div report_btns" style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Member</button>
                        <button className="btn btn-primary add_btn cancel_btn">Agent</button>
                        <button className="btn btn-primary add_btn cancel_btn">Product</button>
                        <button className="btn btn-primary add_btn cancel_btn">Type</button>
                        <button className="btn btn-primary add_btn cancel_btn">Reset</button>
                    </div>
                
                </div><br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <span className="subdvv1_1_1 win_report_btns print_hide">
                <label className="labls labls1"><span style={{minWidth:"48px"}}>Show:</span>
                    <select className="form-control" style={{width:"70px"}}>
                            <option>50</option>
                    </select><span style={{minWidth:"10px",marginLeft:"12px"}}>Entries:</span></label>
                    <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                    <CSVLink data={data} headers={headers} className="btn btn-primary add_btn cancel_btn">CSV</CSVLink>;
                    <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                </span>
                <div className="search_report print_hide" style={{float:'right',marginBottom:'10px'}}>
                <label className="labls labls1"><span style={{minWidth:"56px"}}>Search:</span><input type="text" className="form-control" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}}/></label>
                </div>
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TransferReport;