import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
const data = [
  {id: 1,vip_level: "VIP1A",walletbal: "75.00",member_group:"VIP1A",platform:"WINDOW",browser:"Chrome-89.0"},{id: 2,vip_level: "VIP1A",walletbal: "75.00",member_group:"VIP1A",platform:"WINDOW",browser:"Chrome-89.0"},{id: 3,vip_level: "VIP1A",walletbal: "75.00",member_group:"VIP1A",platform:"WINDOW",browser:"Chrome-89.0"},{id: 4,vip_level: "VIP1A",walletbal: "75.00",member_group:"VIP1A",platform:"WINDOW",browser:"Chrome-89.0"},{id: 5,vip_level: "VIP1A",walletbal: "75.00",member_group:"VIP1A",platform:"WINDOW",browser:"Chrome-89.0"},{id: 6,vip_level: "VIP1A",walletbal: "75.00",member_group:"VIP1A",platform:"WINDOW",browser:"Chrome-89.0"},{id: 7,vip_level: "VIP1A",walletbal: "75.00",member_group:"VIP1A",platform:"WINDOW",browser:"Chrome-89.0"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class OnlineMember extends React.Component {  
  render() {
    const columns = [
      {name: 'No.',selector:'id',width:'50px'},
      {name: 'Username',cell: row => <div data-tag="allowRowEvents" onClick={()=>this.props.history.push('/admin/member_details')} style={{color: '#1890ff',cursor:'pointer'}}>dclient02</div>,},
      {name: 'Agent Code',selector: 'vip_level'},
      {name: 'Member Group',selector: 'member_group'},
      {name: 'Platform',selector: 'platform'},
      {name: 'Browser',selector: 'browser'},
      {name: 'Login Time/Login IP',cell: row => <div data-tag="allowRowEvents"><span>2021-03-13 12:01:01</span><br/><span style={{color: '#1890ff'}}>223.24.185.07</span></div>,width:'148px'},
      {name: 'Registered Date/Register IP',cell: row => <div data-tag="allowRowEvents"><span>2021-03-13 12:01:01</span><br/><span style={{color: '#1890ff'}}>223.24.185.07</span></div>,width:'148px'},
      {name: 'Main Wallet',selector: 'walletbal',width:'108px'},
      {name: 'Actions',cell: row => <div data-tag="allowRowEvents" style={{color: '#1890ff'}}>Log Off</div>,width:'70px'},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Member management</span> / 
                    <span>Online Member</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Online Member</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              <div className="trans_tabl" style={{margin:0,background:'white'}}>
                <div className="subbdvv1">
                    <div className="subdvv1_1">
                        <div className="subdvv1_1_1">
                            <input type="text" className="form-control" placeholder="Search"/>
                            <Search color="#00000040" size={15}/>
                        </div>
                    </div><br/>
                    <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OnlineMember;