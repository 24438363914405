import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import WalletBalance from './WalletBalance';
import LevelDets from './LevelDets';
import LevelDetsSM from './LevelDetsSM';
import { PlayFill,ArrowLeft,XCircleFill,PlusSquare } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import data from './Data'
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
      fontSize: '14px',
      background:'#0d171f',
      color:'white',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',
      paddingRight: '8px',
      fontSize: '14px',
      fontWeight: '500',
      width:'50px',
      background:'#363F47',
      color:'white',
      border:'0'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',
      paddingRight: '8px',
      width:'50px',
      color: '#ffffff80',
      fontSize: '14px',
      borderBottom:'1px solid #283138'
    },
  },
};


class History extends React.Component {
  constructor(props) {
    super(props);
    this.submitHistory = this.submitHistory.bind(this)
    this.showDets = this.showDets.bind(this)
  }
  state = {
    historyTable:false,
    showDetss:false,
  }

  componentDidMount() {
    //window.addEventListener("resize", this.forceUpdate.bind(this))
  }

  submitHistory(val){
    this.setState(state => ({
      historyTable: val,
    }));
  }
  showDets(val){
    this.setState(state => ({
      showDetss: val,
      historyTable:!(this.state.historyTable)
    }));
  }

  render() {
    const columns = [
    {
      name: 'TIME & DATE',
      selector: 'datetime',
    },
    {
      name: 'TRANSACTION TYPE',
      selector: 'trans_type',
      cell: row => <div data-tag="allowRowEvents" style={{cursor:'pointer'}} onClick={() => this.showDets(true)}>{row.trans_type}</div>,
    },
    {
      name: 'AMOUNT',
      selector: 'amount',
    },
    {
      name: 'STATUS',
      selector: 'status',
      cell: row => <div data-tag="allowRowEvents" style={{background: '#449d4d40',padding: '3px 7px',borderRadius: '3px',width:'60px',fontSize:'12px',color:'#449D4D',textAlign:'center'}}>{row.status}</div>,
    }
  ];
    return (
      <div>
        <Navbar prop={this.props}/>
        <div style={{ background: '#000C16', color: 'white' }}>
          <br /><br /><br />
          <div>
            <div className="container account_container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-3 col-md-3 hide767scr">
                  <WalletBalance />
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9">
                  <div className="hide767scr"><LevelDets/></div>
                  <div className="col-xl-6 col-lg-7 col-md-8 show767scr" style={{background:'#0d171f',padding:'20px'}}>
                    <LevelDetsSM/>
                  </div>
                  <br /><br/>
                  <div>
                    <ul className="nav nav-tabs accounttabul transferul historyul">
                      <li className="nav-item w-100" style={{display:'block'}}>
                        <span>History</span>
                        <span><b><PlayFill style={{verticalAlign:'bottom'}} size={16}/></b>Watch Tutorial</span>
                      </li>
                    </ul>
                    <div className="tab-pane transferdiv" style={{background:'#0D171F'}}>
                      <br/><br/><br/>
                      <div className="row justify-content-center security_tab" style={{margin:0}}>
                        <div className="col-xl-9 col-lg-10 col-md-12">
                          <div style={{textTransform:'capitalize'}}>
                            <div className="row">
                              <div className="col-lg-4 col-md-3"><label style={{fontSize:'14px'}}>Transaction Type</label></div>
                              <div className="col-lg-8 col-md-9"><input className="form-control" placeholder="Deposit"/></div>
                            </div><br/>
                            <div className="row">
                              <div className="col-lg-4 col-md-3" style={{margin:0}}><label style={{fontSize:'14px'}}>Transaction Date</label></div>
                              <div className="col-lg-8 col-md-9">
                                {!this.state.historyTable &&
                                  <input style={{marginBottom:'22px'}} className="form-control" placeholder="Today"/>
                                }
                                <div className="otransdt" style={{position:'relative'}}>
                                    <span>to</span>
                                  <div className="transdt">
                                    <input className="form-control" type="date" />
                                    <div className="iconn">
                                      {/* <Calendar2Plus color="white" size={20} /> */}
                                    </div>
                                  </div>
                                  <div className="transdt">
                                    <input className="form-control" type="date" />
                                    <div className="iconn">
                                      {/* <Calendar2Plus color="white" size={20} /> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div><br/>
                            <div className="row">
                              <div className="col-lg-4 col-md-3"></div>
                              <div className="col-lg-8 col-md-9">
                                <button className="btn btn-primary login_btn" style={{fontSize: '12px',width: '127px',height: '32px'}} onClick={() => this.submitHistory(true)}>SUBMIT</button>
                                {this.state.historyTable &&
                                  <button className="btn btn-primary" style={{fontSize: '12px',width: '127px',height: '32px',marginLeft: '12px',background: 'transparent',border: '3px solid #676d72',paddingTop:'4px'}} onClick={() => this.submitHistory(false)}>CLEAR</button>
                                }
                              </div>
                            </div>
                            <br/><br/><br/><br/>
                          </div>
                        </div>
                      </div>
                      {this.state.historyTable &&
                        <div className="row">
                          <div className="col-md-12">
                            <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  {
                    this.state.showDetss &&                  
                    <div>
                      <ul className="nav nav-tabs accounttabul transferul historyul">
                        <li className="nav-item w-100" style={{display:'block',textTransform:'capitalize'}}>Transaction Information
                        </li>
                      </ul>
                      <div className="tab-pane transferdiv mailbox_modal" style={{background:'#0D0D0D'}}>
                        <div className="divvv1" style={{padding:'12px 16px'}}>
                          <ArrowLeft color="#425A70" style={{cursor:'pointer'}} onClick={() => this.showDets(false)} size={26} />
                          <span style={{marginTop:'5px'}}>Today, 16th May 2021, 12:45</span>
                        </div>
                        <div className="divvvborder"></div>
                        <div style={{padding:'0 20px 20px 20px'}}>
                          <h4>Transaction Detail</h4>
                          <span style={{fontSize: '13px',opacity: '0.75'}}>Status: Submitted or Cancelled</span>
                          <br/><br/><br/>
                          <div className="row" style={{fontSize: '13px',opacity: '0.5'}}>
                            <div className="col-md-6">
                              <div>
                                <span>Bank: Local Bank A</span><br/>
                                <span>Bank Account Name: Marry Lam</span><br/>
                                <span>Bank Account Number: 123123123123</span><br/>
                                <span>Minimum Deposit: THB 50.00</span>
                              </div><br/>
                              <div>
                                <span>Bank Reference unique code:</span><br/>
                                QWERTY12345
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <span>Sender's Name: Apple Chan Ping Guo</span><br/>
                                <span>Amount to Deposit (THB): 10,000 THB</span><br/>
                                <span>Promotion: Proceed without any of the promotions above.</span>
                              </div><br/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="tab-pane transferdiv mailbox_modal" style={{background:'#0D0D0D'}}>
                        <div className="divvv1" style={{padding:'12px 16px'}}>
                          <ArrowLeft style={{cursor:'pointer'}} onClick={() => this.showDets(false)} color="#425A70" size={26} />
                          <span style={{marginTop:'5px'}}>Today, 16th May 2021, 12:45</span>
                        </div>
                        <div className="divvvborder"></div>
                        <div style={{padding:'0 20px 20px 20px'}}>
                          <h4>Transaction Detail</h4>
                          <span style={{fontSize: '13px',opacity: '0.75'}}>Status: Approved</span>
                          <br/><br/>
                          
                          <div className="transimgs">
                            <img width="100px" alt="img1" height="120px" style={{objectFit:'cover'}} src={'assets/placeholder.jpg'}/>
                            <img width="100px" alt="img2" height="120px" style={{objectFit:'cover',margin:'0 12px'}} src={'assets/placeholder.jpg'}/>
                            <img width="100px" alt="img3" height="120px" style={{objectFit:'cover'}} src={'assets/placeholder.jpg'}/>
                          </div>
                          
                          <br/>
                          <div className="row" style={{fontSize: '13px',opacity: '0.5'}}>
                            <div className="col-md-6">
                              <div>
                                <span>Bank: Local Bank A</span><br/>
                                <span>Bank Account Name: Marry Lam</span><br/>
                                <span>Bank Account Number: 123123123123</span><br/>
                                <span>Minimum Deposit: THB 50.00</span>
                              </div><br/>
                              <div>
                                <span>Bank Reference unique code:</span><br/>
                                QWERTY12345
                              </div><br/>
                              <div>
                                <span>Approved Date and time: xxxx</span><br/>
                                <span>Approved amount: xxxxxxxx</span><br/><br/>
                                <span>Remark: xxx</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <span>Sender's Name: Apple Chan Ping Guo</span><br/>
                                <span>Amount to Deposit (THB): 10,000 THB</span><br/>
                                <span>Promotion: Proceed without any of the promotions above.</span>
                              </div><br/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="tab-pane transferdiv mailbox_modal" style={{background:'#0D0D0D'}}>
                        <div className="divvv1" style={{padding:'12px 16px'}}>
                          <ArrowLeft color="#425A70" style={{cursor:'pointer'}} onClick={() => this.showDets(false)} size={26} />
                          <span style={{marginTop:'5px'}}>Today, 16th May 2021, 12:45</span>
                        </div>
                        <div className="divvvborder"></div>
                        <div style={{padding:'0 20px 20px 20px'}}>
                          <h4>Transaction Detail</h4>
                          <span style={{fontSize: '13px',opacity: '0.75'}}>Status: Pending</span>
                          <br/><br/>
                          
                          <div className="transimgs">
                            <div style={{display:'inline-block',position:'relative'}}><img width="100px" height="120px" style={{objectFit:'cover'}} alt="img1" src={'assets/placeholder.jpg'}/>
                              <XCircleFill color="#FF2F28" className="deletefile" size={20}/>
                            </div>
                            <div style={{display:'inline-block',position:'relative'}}><img width="100px" alt="img2" height="120px" style={{objectFit:'cover',margin:'0 12px'}} src={'assets/placeholder.jpg'}/>
                            <XCircleFill color="#FF2F28" className="deletefile" style={{right:'8px'}} size={20}/></div>
                            <div style={{display:'inline-block'}}>
                              <PlusSquare color="white" size="38"/>
                            </div>
                          </div>
                          
                          <br/>
                          <div className="row" style={{fontSize: '13px',opacity: '0.5'}}>
                            <div className="col-md-6">
                              <div>
                                <span>Bank: Local Bank A</span><br/>
                                <span>Bank Account Name: Marry Lam</span><br/>
                                <span>Bank Account Number: 123123123123</span><br/>
                                <span>Minimum Deposit: THB 50.00</span>
                              </div><br/>
                              <div>
                                <span>Bank Reference unique code:</span><br/>
                                QWERTY12345
                              </div><br/>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <span>Sender's Name: Apple Chan Ping Guo</span><br/>
                                <span>Amount to Deposit (THB): 10,000 THB</span><br/>
                                <span>Promotion: Proceed without any of the promotions above.</span>
                              </div><br/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="tab-pane transferdiv mailbox_modal" style={{background:'#0D0D0D'}}>
                        <div className="divvv1" style={{padding:'12px 16px'}}>
                          <ArrowLeft color="#425A70" style={{cursor:'pointer'}} onClick={() => this.showDets(false)} size={26} />
                          <span style={{marginTop:'5px'}}>Today, 16th May 2021, 12:45</span>
                        </div>
                        <div className="divvvborder"></div>
                        <div style={{padding:'0 20px 20px 20px'}}>
                          <h4>Transaction Detail</h4>
                          <span style={{fontSize: '13px',opacity: '0.75'}}>Status: Declined</span>
                          <br/><br/>
                          
                          <div className="transimgs">
                            <img width="100px" alt="img1" height="120px" style={{objectFit:'cover'}} src={'assets/placeholder.jpg'}/>
                            <img width="100px" alt="img2" height="120px" style={{objectFit:'cover',margin:'0 12px'}} src={'assets/placeholder.jpg'}/>
                            <img width="100px" alt="img3" height="120px" style={{objectFit:'cover'}} src={'assets/placeholder.jpg'}/>
                          </div>
                          
                          <br/>
                          <div className="row" style={{fontSize: '13px',opacity: '0.5'}}>
                            <div className="col-md-6">
                              <div>
                                <span>Bank: Local Bank A</span><br/>
                                <span>Bank Account Name: Marry Lam</span><br/>
                                <span>Bank Account Number: 123123123123</span><br/>
                                <span>Minimum Deposit: THB 50.00</span>
                              </div><br/>
                              <div>
                                <span>Bank Reference unique code:</span><br/>
                                QWERTY12345
                              </div><br/>
                              <div>
                                <span>Remark: xxxx</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <span>Sender's Name: Apple Chan Ping Guo</span><br/>
                                <span>Amount to Deposit (THB): 10,000 THB</span><br/>
                                <span>Promotion: Proceed without any of the promotions above.</span>
                              </div><br/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          <br /><br /><br /><br /><br /><br />
          <Footer />
        </div>

      </div>
    );
  }
}

export default History;