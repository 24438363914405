import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import {Search} from 'react-bootstrap-icons'
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,agent_code: "ag1",total:"0.00"},{id: 2,agent_code: "ag1",total:"0.00"},{id: 3,agent_code: "ag1",total:"0.00"},{id: 4,agent_code: "ag1",total:"0.00"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const excelData = [
  {
    "Agent Code": "ag1",
    "Total Deposit": "0.00",
    "Total Withdrawal": 0.00,
    "Total Promotion": 0.00,
    "Rebate": '0.00',
    "Turnover": 0.00,
    "Bet": 0.00,
    "Payment": 0.00,
    "Win/Lose": 0.00,
  },
  {
    "Agent Code": "ag1",
    "Total Deposit": "0.00",
    "Total Withdrawal": 0.00,
    "Total Promotion": 0.00,
    "Rebate": '0.00',
    "Turnover": 0.00,
    "Bet": 0.00,
    "Payment": 0.00,
    "Win/Lose": 0.00,
  },
  {
    "Agent Code": "ag1",
    "Total Deposit": "0.00",
    "Total Withdrawal": 0.00,
    "Total Promotion": 0.00,
    "Rebate": '0.00',
    "Turnover": 0.00,
    "Bet": 0.00,
    "Payment": 0.00,
    "Win/Lose": 0.00,
  },
  {
    "Agent Code": "ag1",
    "Total Deposit": "0.00",
    "Total Withdrawal": 0.00,
    "Total Promotion": 0.00,
    "Rebate": '0.00',
    "Turnover": 0.00,
    "Bet": 0.00,
    "Payment": 0.00,
    "Win/Lose": 0.00,
  }
]
class AgentPerformance extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.printData = this.printData.bind(this)
    this.exportData = this.exportData.bind(this)
    }
    printData(){
      window.print();
    }
    exportData(){
      exportFromJSON({ data: excelData, fileName: 'Agent performance', exportType: exportFromJSON.types.xls})
    }
  render() {
    const columns = [
        {name: 'No.',selector: 'id',width:'50px'},
        {name: 'Agent Code',selector: 'agent_code'},
        {name: 'Total Deposit',selector: 'total'},
        {name: 'Total Withdrawal',selector: 'total'},
        {name: 'Total Promotion',selector: 'total'},
        {name: 'Rebate',selector: 'total'},
        {name: 'Turnover',selector: 'total'},
        {name: 'Bet',selector: 'total'},
        {name: 'Payment',selector: 'total'},
        {name: 'Win/Lose',selector: 'total'},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}} className="print_hide">
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Agent management</span> / 
                    <span>Agent Performance</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Agent Performance</h5>
                </div>
              </div><br/>
              <div className="divbody print_hide">
                  <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                    <h6 style={{fontWeight:600,margin:0}}>Search Criteria</h6>
                  </div>
                  <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <div className="col-md-12">
                      <div className="agent_perform">
                        <label className="labls"><span>Date Range:</span> 
                            <input type="date" className="form-control"/>
                        </label>
                        <label className="labls"><button className="btn btn-primary add_btn">Search</button>
                        <button className="btn btn-primary add_btn cancel_btn">Reset</button></label>
                      </div>
                    </div>
                  </div>
              </div><br/>
              <div className="divbody">
                <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                  <span className="subdvv1_1_1 print_hide">
                    <input type="text" className="form-control search" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Please enter"/>
                    <Search color="#00000040" style={{top:'4px'}} size={15}/>
                  </span>
                  <div className="top_btns print_hide" style={{float:'right',marginBottom:'10px'}}>
                    <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                    <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                  </div>
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AgentPerformance;