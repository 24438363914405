import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import AboutSidebar from './AboutSidebar';

class AboutUs extends React.Component {
  render() {
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{backgroundImage:'linear-gradient(#000407, #011c31)',color:'white'}}>
            <br/><br/><br/>
            <div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-2 col-md-3 col-sm-4 text-center hidesidebar575">
                    <AboutSidebar/>
                  </div>
                  <div className="showdropdown575 col-sm-12">
                    <select className="form-control">
                      <option>About Us</option>
                    </select><br/><br/>
                  </div>
                  <div className="col-lg-10 col-md-9 col-sm-8">
                    <div style={{padding: '0 20px'}}>
                        <div className="aboutdiv">
                          <h2 style={{fontSize: '30px',fontWeight: 600,margin: 0}} className="text-white text-center">ABOUT US</h2>
                          <div className="text-center"><img alt="line" src={'assets/aboutus.png'}/></div><br/>
                          <p>GboBet is today one of the leading suppliers of online gaming products worldwide, offering a wide range of first-class online gambling products and games in a safe and user-friendly gaming environment. GboBet has been able establish themselves as one of the most trusted and reliable gaming companies in the market nowadays.</p>
                          <p>GboBet offers its customers a wide selection of innovative products such as Live Casino, Sports Betting and slots games. In our aim to provide the best service online we also offer the unique and exclusive support guarantee, which applies 24 hours a day, 7 days a week. Our support team is available through e-mail, live chat and telephone. Please visit the support section for further details.</p>
                          <p>With a solid product range and unique customer support, GboBet becomes the obvious choice for gaming enthusiasts all over the world!</p>
                          <p>Thank you for choosing Gbobet.com as your preferred choice of online gaming. We hope you enjoy playing with us, and if there is anything we can help you with please do not hesitate to contact us.</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
        </div>
    );
  }
}

export default AboutUs;
