import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import ReactApexChart from "react-apexcharts";
import { ChevronDown,ArrowUpCircleFill,ArrowDownCircleFill,ThreeDots,CaretUpFill } from 'react-bootstrap-icons';

class WebsiteStatistic extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        
          series: [{
              name: "Total Login",
              data: [45, 52, 38, 24, 33, 26, 21]
            },
            {
              name: "Login Per Month",
              data: [35, 41, 62, 42, 13, 18, 29]
            }
          ],
          options: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: [2, 2],
              curve: 'straight',
              dashArray: [0, 0]
            },
            title: {
              text: 'Popular Time (Total Login: 206)',
              align: 'left',
              style: {
                fontSize:  '13px',
                fontWeight:  'bold',
                color:  '#263238'
              },
            },
            legend: {
              tooltipHoverFormatter: function(val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
              }
            },
            markers: {
              size: 0,
              hover: {
                sizeOffset: 4
              }
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
            },
            yaxis: {
              labels: {
                  style: {
                      colors: ['#1890ff'],
                  },
              },
            },
            tooltip: {
              y: [
                {
                  title: {
                    formatter: function (val) {
                      return val
                    }
                  }
                },
                {
                  title: {
                    formatter: function (val) {
                      return val
                    }
                  }
                },
                {
                  title: {
                    formatter: function (val) {
                      return val;
                    }
                  }
                }
              ]
            },
            grid: {
              borderColor: '#f1f1f1',
            },
            plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    position: 'yop'
                  }
                }
              },
          },
        
        
        };
      }
  
    render() {
  
      return (

        <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Statistic</span> / 
                    <span>Website Statistic</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Website Statistic</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="subbdvv1" style={{padding: '14px 24px 0px 24px'}}>
                    <h6 style={{fontWeight:700,margin:0}}>Search Criteria</h6>
                  </div>
                <div className="row" style={{margin:0,padding:'12px 14px',background:'white'}}>               
                    <div className="col-md-6 top_div">
                        <label className="labls labls1"><span>Date Range:</span></label>
                        <input type="date" className="form-control"/>
                    </div>
                    <br/>
                    <div style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Search</button>
                        <button className="btn btn-primary add_btn reset_black_btn">Reset</button>
                    </div>
                
                </div><br/>

                <div className="row main_stat_div" style={{width:"100%",margin:"0",marginBottom:"24px"}}>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" style={{padding:"8px 12px 4px 0px"}}>
                        <div className="stat_div">
                            <h6>Member</h6>
                            <table className="stat_table">
                                <thead>
                                    <th style={{width:"20px"}}>#</th>
                                    <th style={{width:"320px"}}>Description</th>
                                    <th>Total</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Total Member</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Total Active Member</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Avg Member Register Per Day</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Total Unique Member Login</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Avg. Unique Member Login Per Day</td>
                                        <td>25</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12"  style={{padding:"8px 12px 4px 0px"}}>
                    <div className="stat_div">
                            <h6>Deposit</h6>
                            <table className="stat_table">
                                <thead>
                                    <th style={{width:"20px"}}>#</th>
                                    <th style={{width:"320px"}}>Description</th>
                                    <th>Total</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Total Deposit</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Total Deposit Trans</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Total First Deposit Trans</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Avg. Deposit</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Avg. Deposit Per Day</td>
                                        <td>25</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12"  style={{padding:"8px 12px 4px 0px"}}>
                    <div className="stat_div">
                            <h6>Withdrawal</h6>
                            <table className="stat_table">
                                <thead>
                                    <th style={{width:"20px"}}>#</th>
                                    <th style={{width:"320px"}}>Description</th>
                                    <th>Total</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Total Withdrawal</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Total Withdraw Trans</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Total First Withdraw Trans</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Avg. Withdraw</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Avg. Withdraw Per Day</td>
                                        <td>25</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12"  style={{padding:"8px 0px 4px 0px"}}>
                    <div className="stat_div">
                            <h6>Promotion</h6>
                            <table className="stat_table">
                                <thead>
                                    <th style={{width:"20px"}}>#</th>
                                    <th style={{width:"320px"}}>Description</th>
                                    <th>Total</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Total Promotion</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Total Promotion Trans</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Total First Promotion Trans</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Avg. Promotion</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Avg. Promotion Per Day</td>
                                        <td>25</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row main_stat_div" style={{width:"100%",margin:"0"}}>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12" style={{padding:"8px 12px 4px 0px"}}>
                        <div id="chart" className="line_chart">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12" style={{padding:"8px 12px 4px 0px"}}>
                        <div id="chart" className="line_chart">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12"  style={{padding:"8px 0px 4px 0px"}}>
                        <div id="chart" className="line_chart">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
                        </div>
                    </div>
                </div>
                
            </div>
          </div>
        </div>
      </div>
      );
    }
  }
  
  export default WebsiteStatistic;
