import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import {Modal} from 'react-bootstrap';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { makeRequest } from '../helpers'

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.register = this.register.bind(this)
    this.changeUsername = this.changeUsername.bind(this)
    this.changefname = this.changefname.bind(this)
    this.changeEmail = this.changeEmail.bind(this)
    this.changePwd = this.changePwd.bind(this)
    this.changeCode = this.changeCode.bind(this)
    this.capcha = this.capcha.bind(this)
    this.tcModal = this.tcModal.bind(this)
    this.rgpModal = this.rgpModal.bind(this)
    this.changeReferral = this.changeReferral.bind(this)
  }
  state= {
    username:'',
    fname:'',
    email:'',
    pwd:'',
    code:'',
    register_result:'',
    error_msg:'',
    success_msg:'',
    submitDisabled:false,
    showTCModal:false,
    showrgpModal:false,
    referral:'',
  }
  
  componentDidMount() {
    this.capcha();
  }

  changeUsername(e) {
    let usernameValid = e.target.value ? true : false;
    this.setState({
      username: (e.target.value).toLowerCase(),
      usernameValid: usernameValid,
    })
  }
  changefname(e) {
    let fnameValid = e.target.value ? true : false;
    this.setState({
      fname: e.target.value,
      fnameValid: fnameValid,
    })
  }
  changeEmail(e) {
    let emailValid = e.target.value ? true : false;
    this.setState({
      email: e.target.value,
      emailValid: emailValid,
    })
  }
  changePwd(e) {
    let pwdValid = e.target.value ? true : false;
    this.setState({
      pwd: e.target.value,
      pwdValid: pwdValid,
    })
  }
  changeCode(e) {
    let codeValid = e.target.value ? true : false;
    this.setState({
      code: e.target.value,
      codeValid: codeValid,
    })
  }
  changeReferral(e) {
    this.setState({referral: e.target.value})
  }
  capcha(){
    this.setState(state => ({capcha_txt:Math.round((Math.pow(36, 6 + 1) - Math.random() * Math.pow(36, 6))).toString(36).slice(1)}));
  }
  sendTotop(){
    window.scrollTo(0, 0)
    //document.getElementById("usernamme").focus();
  }
  tcModal(val){
    this.setState({showTCModal: val})
  }
  rgpModal(val){
    this.setState({showrgpModal: val})
  }
  async register(){
    var ref_name;
    if(this.state.username===''){
      this.setState(state => ({error_msg:'Enter username.'}));
    }else if(this.state.fname===''){
      this.setState(state => ({error_msg:'Enter your full name.'}));
    }else if(this.state.email===''){
      this.setState(state => ({error_msg:'Enter email address.'}));
    }else if((this.state.email).indexOf("@") === -1){
      this.setState(state => ({error_msg:'Invalid email address.'}));
    }else if(this.state.pwd===''){
      this.setState(state => ({error_msg:'Enter password.'}));
    }else if((this.state.pwd).length<8){
      this.setState(state => ({error_msg:'Password must be atleast 8 Characters.'}));
    }else if(this.state.code===''){
      this.setState(state => ({error_msg:'Enter code.'}));
    }else if(this.state.code!==this.state.capcha_txt){
      this.setState(state => ({error_msg:'Code is incorrect.'}));
    }else if(!document.getElementById('agree').checked){
      this.setState(state => ({error_msg:'Accept Terms & Conditions to continue.'}));
    }else{
      if(this.state.referral===''){
        ref_name=null;
      }else{
        ref_name=this.state.referral;
      }
      this.setState(state => ({submitDisabled:true}));
      let data = JSON.stringify({username: this.state.username,password: this.state.pwd,fullname: this.state.fname,email: this.state.email,referrerUsername:ref_name});
      var result1=await this.makeRequest('auth/register', 'post', data,'');
      if(result1.status===200){
        this.setState({
          success_msg:result1.data.message,error_msg:'',submitDisabled:false
        })
        localStorage.setItem('email',this.state.email)
        this.props.history.push('/verify_email');
        // this.setState({
        //   success_msg:result1.data.message,error_msg:'',submitDisabled:false
        // })
        // setTimeout(
        //   function() {
        //     if(result1.status===200){
        //       localStorage.setItem('email',this.state.email)
        //       this.props.history.push('/verify_email');
        //     }
        //   }.bind(this),3000
        // );
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message}));
        this.setState(state => ({submitDisabled:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false}));
      //console.log('Statement 1:',result1.data,'status:',result1.status);
    }
  }
  
  render() {
    //const hash=this.props.location.hash;
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{background:'#000C16',color:'white',paddingTop: '92px'}} className="cockfightngdiv">
            <div>
              <div className="container createaccdiv">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-6 col-md-7 text-center">
                    <h1 className="text-white text-center homeheadng">CREATE AN ACCOUNT</h1>
                    <img alt="line" src={'assets/aboutus.png'}/>
                    <br/><br/>
                    {this.state.error_msg}
                    <p style={{fontSize: '14px',color: 'white',padding: '0 28px',opacity:'0.75'}}>Let's get you all set up so you can verify your personal account and begin setting up your profile.</p>
                    {this.state.success_msg!=='' ?
                      (<p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>):('')
                    }
                    {this.state.error_msg!=='' ?
                      (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.error_msg}</p>):('')
                    }
                  </div>
                </div><br/>
                <div className="row justify-content-center form_div">
                  <div className="col-lg-6 col-md-10">
                    <div className="row">
                        <div className="col-md-6">
                          <input className="form-control" placeholder="Username*" onChange={this.changeUsername}/><br/>
                        </div>
                        <div className="col-md-6">
                          <input className="form-control" placeholder="Full Name*" onChange={this.changefname}/><br/>
                        </div>
                        <div className="col-md-6">
                          <input className="form-control" type="email" placeholder="Email Address*" onChange={this.changeEmail}/><br/>
                        </div>
                        <div className="col-md-6">
                          <input className="form-control" type="password" placeholder="Password*" onChange={this.changePwd}/><br/>
                        </div>
                        <div className="col-md-6">
                          <input className="form-control" placeholder="Code in the box*" onChange={this.changeCode}/><br/>
                        </div>
                        <div className="col-md-6">
                          <span className="capchatext">{this.state.capcha_txt}</span>
                          {/* <span className="capchatext">5Ji8a7</span> */}
                          <span className="reloadcapcha" onClick={this.capcha}><ArrowRepeat size={15} /> Reload</span>
                          <br/><br/><br/>
                        </div>
                        <div className="col-md-12">
                          <div className="referrall">
                            <div className="row">
                              <div className="col-md-11">
                                <p>Referred by (Please enter the refereral code/username of your referrer) </p>
                                <input className="form-control" placeholder="Referral code / username" onChange={this.changeReferral}/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 already">
                          <input style={{height: '16px',width: '16px',verticalAlign: 'middle'}} id="agree" type="checkbox"/>
                          <span style={{paddingLeft: '10px',color: '#a2a2a2',fontSize: '14px'}}>By clicking you agree to accept our <span className="yellowcolor" style={{cursor:'pointer'}} onClick={() => this.tcModal(true)}>Terms & Conditions,</span> that you are over 18 & you are aware our <span className="yellowcolor" style={{cursor:'pointer'}} onClick={() => this.rgpModal(true)}>Responsible gambling policy!</span></span>
                          <br/><br/>
                          <Modal size="lg" show={this.state.showTCModal} className="pwd_changed_modal bank_account_modal wallet_modal">
                            <Modal.Header style={{background:'none'}} closeButton onClick={() => this.tcModal(false)}></Modal.Header>
                            <Modal.Body>
                              <div className="">
                                <h2 style={{fontSize: '30px',fontWeight: 600,margin: 0}} className="text-white text-center">Terms & Conditions</h2>
                                <div className="text-center"><img alt="line" src={'assets/aboutus.png'}/></div><br/>
                                <p>GboBet is today one of the leading suppliers of online gaming products worldwide, offering a wide range of first-class online gambling products and games in a safe and user-friendly gaming environment. GboBet has been able establish themselves as one of the most trusted and reliable gaming companies in the market nowadays.</p>
                                <p>GboBet offers its customers a wide selection of innovative products such as Live Casino, Sports Betting and slots games. In our aim to provide the best service online we also offer the unique and exclusive support guarantee, which applies 24 hours a day, 7 days a week. Our support team is available through e-mail, live chat and telephone. Please visit the support section for further details.</p>
                                <p>With a solid product range and unique customer support, GboBet becomes the obvious choice for gaming enthusiasts all over the world!</p>
                                <p>Thank you for choosing Gbobet.com as your preferred choice of online gaming. We hope you enjoy playing with us, and if there is anything we can help you with please do not hesitate to contact us.</p>
                              </div>
                            </Modal.Body>
                          </Modal>
                          <Modal size="lg" show={this.state.showrgpModal} className="pwd_changed_modal bank_account_modal wallet_modal">
                            <Modal.Header style={{background:'none'}} closeButton onClick={() => this.rgpModal(false)}></Modal.Header>
                            <Modal.Body>
                              <div className="">
                                <h2 style={{fontSize: '30px',fontWeight: 600,margin: 0}} className="text-white text-center">Responsible gambling policy</h2>
                                <div className="text-center"><img alt="line" src={'assets/aboutus.png'}/></div><br/>
                                <p>GboBet is today one of the leading suppliers of online gaming products worldwide, offering a wide range of first-class online gambling products and games in a safe and user-friendly gaming environment. GboBet has been able establish themselves as one of the most trusted and reliable gaming companies in the market nowadays.</p>
                                <p>GboBet offers its customers a wide selection of innovative products such as Live Casino, Sports Betting and slots games. In our aim to provide the best service online we also offer the unique and exclusive support guarantee, which applies 24 hours a day, 7 days a week. Our support team is available through e-mail, live chat and telephone. Please visit the support section for further details.</p>
                                <p>With a solid product range and unique customer support, GboBet becomes the obvious choice for gaming enthusiasts all over the world!</p>
                                <p>Thank you for choosing Gbobet.com as your preferred choice of online gaming. We hope you enjoy playing with us, and if there is anything we can help you with please do not hesitate to contact us.</p>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div className="col-md-12">
                          <span onClick={this.register} className="createlinkk"><button className="btn btn-primary comn_btn login_btn" disabled={this.state.submitDisabled} style={{fontSize:'15px',float:'right'}}>CREATE ACCOUNT</button></span>
                          {/* <Link to="/verify_email" onClick={() => this.register} className="createlinkk"><button className="btn btn-primary comn_btn login_btn" style={{fontSize:'15px',float:'right'}}>CREATE ACCOUNT</button></Link> */}
                          <span className="alreaspan" style={{color: '#a2a2a2',fontSize: '14px',verticalAlign:'middle'}}>Already have an account? <span className="yellowcolor" onClick={this.sendTotop} style={{cursor:'pointer'}}>Sign in</span></span>
                        </div>
                    </div>
                  </div>
                </div><br/><br/>
              </div>
            </div>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
        </div>
    );
  }
}

export default CreateAccount;