import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';

class LiveCasino extends React.Component {  
  render() {
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{background:'#000C16',color:'white'}}>
            {/* games */}
            <br/><br/>
            <div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <h1 className="text-white text-center homeheadng">LIVE CASINO</h1>
                    <div className="text-center">
                      <img alt="all games here" src={'assets/Group44.png'}/>
                    </div><br/><br/>
                  </div>
                </div>
                <div className="row casidv casidvv">
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="casinodiv">
                      <img alt="live casino image1" src={'assets/casino.png'}/>
                    </div>
                  </div>  
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="casinodiv">
                      <img alt="live casino image1" src={'assets/casinoking.png'}/>
                    </div>
                  </div>  
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="casinodiv">
                      <img alt="live casino image1" src={'assets/pokerking.png'}/>
                    </div>
                  </div>  
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="casinodiv">
                      <img alt="live casino image1" src={'assets/casinobonus.png'}/>
                    </div>
                  </div>  
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="casinodiv">
                      <img alt="live casino image1" src={'assets/casinopartry.png'}/>
                    </div>
                  </div>  
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="casinodiv">
                      <img alt="live casino image1" src={'assets/casinoscree.png'}/>
                    </div>
                  </div>  
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="casinodiv">
                      <img alt="live casino image1" src={'assets/casino-01.png'}/>
                    </div>
                  </div>  
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 text-center">
                    <div className="casinodiv">
                      <img alt="live casino image1" src={'assets/lastchance.png'}/>
                    </div>
                  </div>  
                </div>              
              </div>
            </div>
            {/* games */}

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
        </div>
    );
  }
}

export default LiveCasino;
