import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { CaretRightFill } from 'react-bootstrap-icons';
class AssignModule extends React.Component {
    constructor(){
        super();
        this.state = {
          showInnerFirst: true,
          showUnderInnerFirst: true,
          showInnerSecond: true,
          showUnderInnerSecond: true,
          
        }
    }
    showInnerFirst = () => {
        this.setState({showInnerFirst: !this.state.showInnerFirst });
    };
    showUnderInnerFirst = () => {
        this.setState({showUnderInnerFirst: !this.state.showUnderInnerFirst });
    };
    showInnerSecond = () => {
        this.setState({showInnerSecond: !this.state.showInnerSecond });
    };
    showUnderInnerSecond = () => {
        this.setState({showUnderInnerSecond: !this.state.showUnderInnerSecond });
    };
    stopProp = (e) =>{
        e.stopPropagation();
    };
  render() {
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Admin Management</span> / 
                    <span>Assign Module</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Assign Module</h5>
                    <div style={{marginTop:"20px"}}>
                    <button className="btn btn-primary add_btn" style={{width:'auto'}}><Link to="/admin/role_management" style={{textDecoration:"none",color:"white"}}>Back</Link></button>
                    </div>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <div className="assign_btns" style={{float:'right',marginBottom:'10px'}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Select All</button>
                        <button className="btn btn-primary add_btn" style={{width:'auto',marginLeft:"20px"}}>Deselect All</button>
                    </div>
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px"}}>Modules</h5>
                    </div>
                    <div className="row" style={{margin:0,width:"100%"}}>
                        <div className="col-md-12" style={{padding:"0px"}}>
                            <ul className="assign_module first">
                                <li>
                                    <p className="assign_dark_color" onClick={() => { this.showInnerFirst() }} ><input type="checkbox" onClick={this.stopProp}/><span className="down_arrow" ><CaretRightFill size="14" color="#000000"/></span>
                                    <span className="checkspan twos twos1">
                                        <span className="assign_module_text">Admin Management</span>
                                    </span></p>
                                    {this.state.showInnerFirst?
                                    (<ul className="assign_module inner_first">
                                        <li>
                                            <p className="assign_dark1_color" onClick={() => { this.showUnderInnerFirst() }} style={{cursor:"pointer"}}><input type="checkbox" onClick={this.stopProp}/>
                                            <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                            <span className="checkspan twos twos1">
                                                <span className="assign_module_text">Account Management</span>
                                            </span></p>
                                        </li>
                                        {this.state.showUnderInnerFirst ?
                                        (<ul className="assign_module under_inner_first">
                                            <li className="last_assign_module">
                                                <p className="assign_light_color"><input type="checkbox" onClick={this.stopProp}/>
                                                    <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                    <span className="checkspan twos twos1">
                                                        <span className="assign_module_text">Add Operator</span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li className="last_assign_module">
                                                <p className="assign_light_color"><input type="checkbox" onClick={this.stopProp}/>
                                                    <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                    <span className="checkspan twos twos1">
                                                        <span className="assign_module_text">Edit Operator Profile</span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li className="last_assign_module">
                                                <p className="assign_light_color"><input type="checkbox" onClick={this.stopProp}/>
                                                    <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                    <span className="checkspan twos twos1">
                                                        <span className="assign_module_text">Reset Operator Password</span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li className="last_assign_module">
                                                <p className="assign_light_color"><input type="checkbox" onClick={this.stopProp}/>
                                                    <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                    <span className="checkspan twos twos1">
                                                        <span className="assign_module_text">Update Operator Account</span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ul>):('')}
                                        <li>
                                            <p className="assign_dark1_color" onClick={() => { this.showInnerSecond() }} style={{cursor:"pointer"}}><input type="checkbox" onClick={this.stopProp}/>
                                                <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                <span className="checkspan twos twos1">
                                                    <span className="assign_module_text">Role Management</span>
                                                </span>
                                            </p>    
                                            {this.state.showInnerSecond ?
                                            (<ul className="assign_module">
                                                <li className="last_assign_module_ul">
                                                    <p className="assign_light_color"><input type="checkbox" onClick={this.stopProp}/>
                                                        <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                        <span className="checkspan twos twos1">
                                                        <span className="assign_module_text">Add New Role</span>
                                                        </span>
                                                    </p>
                                                </li>
                                                <li className="last_assign_module_ul">
                                                    <p className="assign_light_color"><input type="checkbox" onClick={this.stopProp}/>
                                                        <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                        <span className="checkspan twos twos1">
                                                            <span className="assign_module_text">Edit Role</span>
                                                        </span>
                                                    </p>
                                                </li>
                                                <li className="last_assign_module_ul">
                                                    <p className="assign_light_color"><input type="checkbox" onClick={this.stopProp}/>
                                                        <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                        <span className="checkspan twos twos1">
                                                            <span className="assign_module_text">Assign Module</span>
                                                        </span>
                                                    </p>
                                                </li>
                                            </ul>):('')}
                                        </li>
                                        
                                        <li>
                                            <p className="assign_dark1_color"><input type="checkbox" onClick={this.stopProp}/>
                                                <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                <span className="checkspan twos twos1">
                                                    <span className="assign_module_text">Dashboard</span>
                                                </span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="assign_dark1_color"><input type="checkbox" onClick={this.stopProp}/>
                                                <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                <span className="checkspan twos twos1">
                                                <span className="assign_module_text">Transaction</span>
                                                </span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="assign_dark1_color"><input type="checkbox" onClick={this.stopProp}/>
                                                <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                <span className="checkspan twos twos1">
                                                    <span className="assign_module_text">Member Management</span>
                                                </span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="assign_dark1_color"><input type="checkbox" onClick={this.stopProp}/>
                                                <span className="down_arrow"><CaretRightFill size="14" color="#000000"/></span>
                                                <span className="checkspan twos twos1">
                                                    <span className="assign_module_text">Affiliate Management</span>
                                                </span>
                                            </p>
                                        </li>

                                    </ul>):('')}
                                </li>
                            </ul>
                        </div>
                        <div className="module_btns" style={{marginTop:"20px",paddingLeft:"0",textAlign:'right'}}>
                            <button className="btn btn-primary add_btn reset_black_btn" style={{width:"150px"}}>Cancel</button>
                            <button className="btn btn-primary add_btn" style={{width:"150px",marginLeft:"20px"}}>Save</button>
                        </div>
                    </div>

                </div>

            </div>           
          </div>
        </div>
      </div>
    );
  }
}
export default AssignModule;