import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import WalletBalance from './WalletBalance';
import LevelDets from './LevelDets';
import LevelDetsSM from './LevelDetsSM';
import { Bank2,PlayFill,ExclamationCircleFill } from 'react-bootstrap-icons';
class Withdrawal extends React.Component {
  constructor(props) {
    super(props);
    this.submitHistory = this.submitHistory.bind(this)
  }
  state = {
    historyTable:false
  }

  submitHistory(val){
    this.setState(state => ({
        historyTable: val,
    }));
  }

  render() {
    return (
      <div>
        <Navbar prop={this.props}/>
        <div style={{ background: '#000C16', color: 'white' }}>
          <br /><br /><br />
          <div>
            <div className="container account_container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-3 col-md-3 hide767scr">
                  <WalletBalance />
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9">
                  <div className="hide767scr"><LevelDets/></div>
                  <div className="col-xl-6 col-lg-7 col-md-8 show767scr" style={{background:'#0d171f',padding:'20px'}}>
                    <LevelDetsSM/>
                  </div>
                  <br /><br/>
                  <div>
                    <ul className="nav nav-tabs accounttabul transferul historyul">
                      <li className="nav-item w-100" style={{display:'block'}}>
                        <span>Withdrawal</span>
                        <span><b><PlayFill style={{verticalAlign:'bottom'}} size={16}/></b>Watch Tutorial</span>
                      </li>
                    </ul>
                    <div className="tab-pane transferdiv withdrawaldiv" style={{background:'#0D171F'}}>
                      <br/><br/><br/>
                      <div className="row justify-content-center security_tab">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
                          <div className="depositfrom_div active">
                            <div className="iconn"><Bank2 color="white" size={35} /></div>
                            <span>Bank Transfer</span>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="depositfrom_div">
                            <div className="iconn" style={{margin:0}}>
                              <img alt="crypto" src={'assets/crypto.png'}/>
                            </div>
                            <span>Crypto Currency</span>
                            <div className="cmngsoon">Coming soon</div>
                          </div>
                        </div>
                      </div><br/>
                      <div className="row justify-content-center security_tab" style={{margin:0}}>
                        <div className="col-lg-11">
                          <div style={{textTransform:'capitalize'}}>
                            <div className="row" style={{justifyContent:'space-evenly'}}>
                                <div className="col-md-5">
                                    <label>Wallet</label>
                                    <input className="form-control" placeholder="Select wallet"/><br/>
                                </div>
                                <div className="col-md-5">
                                    <label>Bank</label>
                                    <input className="form-control" placeholder="Select Bank"/><br/>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div><br/>
                      <div className="row justify-content-center security_tab" style={{margin:0}}>
                        <div className="col-md-10">
                            <div style={{borderTop:'2px solid #ffffff40',textTransform:'capitalize',padding:'0 10px',fontSize: '14px',color: '#DEE2E6'}}>
                                <br/>
                                <div className="row" style={{justifyContent:'space-evenly'}}>
                                    <div className="col-md-6">
                                        <span>Withdrawal Availability: 0</span>
                                    </div>
                                    <div className="col-md-6">
                                        <span>Bank: -</span><br/>
                                        <span>Account name: -</span><br/>
                                        <span>Account number: ********</span><br/>
                                        <span>Status: -</span>
                                    </div>
                                </div>
                            </div><br/>
                            <div style={{borderTop:'2px solid #ffffff40',textTransform:'capitalize',padding:'0 10px',fontSize: '14px',color: '#bbbbbb'}}>
                                <br/>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div><label>Amount (THB)<b style={{color:'#FF2F28'}}>*</b></label><span style={{color:'#FF2F28',float:'right',fontSize:'12px'}}><ExclamationCircleFill style={{marginRight: '4px',verticalAlign: 'text-bottom'}} color="#FF2F28" size={13}/>Minimum THB 30.00</span></div>
                                        <input className="form-control" placeholder="Enter the amount"/><br/>
                                    </div>
                                    <div className="col-md-6" style={{margin: 'auto 0',textAlign: 'right'}}>
                                        <button style={{fontSize: '12px',padding: '6px 30px'}} className="btn btn-primary login_btn">PROCESS NOW</button>
                                    </div>
                                </div>
                            </div><br/>
                            <div style={{borderTop:'2px solid #ffffff40',textTransform:'capitalize',padding:'0 10px',fontSize: '14px',color: '#bbbbbb'}}>
                                <br/>
                                <div className="row">
                                    <div className="col-md-6">
                                      <h6 className="text-white">Promotion Rollover Status</h6>
                                    </div>
                                    <div className="col-md-6" style={{textAlign:'right',fontSize:'10px'}}>
                                      <span>Last Updated 2021-03-29 01:25:39</span>
                                    </div>
                                </div><br/>
                                <div className="row">
                                    <div className="col-md-6" style={{marginBottom:'30px'}}>
                                      <div className="rolloverstatus">
                                        Rollover Requirement
                                        <span>0.00</span>
                                      </div>
                                      <div className="rolloverdata">
                                        <div>
                                          <span>Unsettled Rollover</span>
                                          <span>THB 0.00</span>
                                        </div>
                                        <div>
                                          <span>Latest Deposit Rollover</span>
                                          <span>THB 0.00</span>
                                        </div>
                                        <div>
                                          <span>Total Rollover Requirement</span>
                                          <span>THB 0.00</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div>
                                        <div className="rolloverstatus">
                                          Accumulated Rollover
                                          <span>0.00</span>
                                        </div>
                                        <div className="rolloverdata">
                                          <div></div><br/>
                                          <div></div><br/>
                                          <div></div><br/>
                                        </div>
                                      </div>
                                    </div>
                                </div><br/><br/>
                                <div className="col-md-12">
                                  <div className="rolloverdata" style={{borderRadius:'5px'}}>
                                    <div className="row" style={{marginBottom:0}}>
                                      <div className="col-md-9" style={{marginBottom:0}}>
                                        <h6 className="text-white" style={{fontSize:'12px',fontWeight:'600'}}>Friendly Reminder</h6>
                                        <span>Member who take promotion please take note that ALL Live Casino turnover will take about 30 minutes to settle and update to our system.<br/>
                                        Hence, please with at least 30 minutes for your turnover to update in our system before submitting your withdrawal.</span>
                                        <span></span><br/><br/>
                                        <div style={{color:'#FF2F28',marginBottom:0}}><ExclamationCircleFill style={{marginRight: '4px',verticalAlign: 'text-bottom'}} color="#FF2F28" size={13}/> Minimum withdrawal MYR 30.00</div>
                                      </div>
                                    </div>
                                  </div><br/><br/><br/>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br /><br /><br /><br /><br /><br />
          <Footer />
        </div>

      </div>
    );
  }
}

export default Withdrawal;