import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { makeRequest,timestampDate,timestampOnlyDate,timestampTime } from '../../helpers'
// import { Search,Dot,ChevronUp } from 'react-bootstrap-icons';

class TransactionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
  }
  state={
    trans_status:'',
    submitDisabled:false,
    error_msg:'',
    success_msg:'',
    allTrans:'',
    userDetails:'',
  }
  componentDidMount(){
    const trans_id=window.location.hash.substr(1);
    (async () => {
    var result1=await this.makeRequest('transaction/'+trans_id, 'get','',localStorage.getItem('role_token'));
    if(result1.status===200){
        this.setState(state => ({allTrans: result1.data}));
        this.setState(state => ({trans_status:result1.data.userStatus}));
        this.setState(state => ({userDetails:JSON.parse(result1.data.user)}));
    }
    })();
  }
  render() {
    const hash=window.location.hash;
    let status_style;
    if(this.state.trans_status==='approved'){
        status_style={background: '#d0e6d2',color: '#7ebb84'}
    }else if(this.state.trans_status==='declined'){
        status_style={background: '#FFCBC9',color: '#FF443E'}
    }else if(this.state.trans_status==='pending'){
        status_style={background: '#F5E9CA',color: '#DEB652'}
    }else if(this.state.trans_status==='submitted'){
      status_style={background: '#DDF0F8',color: '#9AD2EB'}
    }else if(this.state.trans_status==='cancelled'){
      status_style={background: '#E5E5E5',color: '#BFBFBF'}
    }
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Transactions</span> / 
                    <span> &nbsp; Transaction</span> / 
                    <span>Transaction Details</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Transaction Details</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-12" style={{padding:"0"}}>
                   <div className="Transreq_div">
                       <span>Type: {this.state.allTrans.type}</span>
                       <span>Transaction ID: {this.state.allTrans.id}</span>
                       <span>Status: 
                        <span style={status_style}>{this.state.allTrans.userStatus}</span>
                       </span>
                       <span>Last update: {timestampDate(this.state.allTrans.updatedAt)}</span>
                   </div>
                </div>
              </div><br/>
              {(this.state.trans_status==='approved') &&
                <div>
                  <div className="row transac_det" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <div className="col-md-4">
                      <span>Transaction date: {timestampOnlyDate(this.state.allTrans.createdAt)}</span><br/>
                      <span>Approved deposit amount: {this.state.allTrans.approvedAmount}</span><br/>
                      <span>Currency: {this.state.allTrans.currency}</span><br/>
                      <span>Fee: {this.state.allTrans.fee}</span><br/>
                    </div>
                    <div className="col-md-4">
                      <span>Approved method: </span><br/>
                      <span>Approved by: {this.state.allTrans.updatedBy!==null ? (JSON.parse(this.state.allTrans.updatedBy)).username : ''}</span><br/><br/>
                      <span>Remark:  {this.state.allTrans.updatedBy!==null ? (JSON.parse(this.state.allTrans.updatedBy)).remark : ''}</span><br/>
                    </div>
                    {/*<div className="col-md-4">
                      <span>Approved method: Auto</span><br/>
                      <span>Approved By: Twilio</span><br/><br/>
                      <span>Remark:</span><br/>
                    </div>*/}
                  </div><br/>
                </div>
              }
              {this.state.trans_status==='declined' &&
                <div>
                    <div className="row transac_det" style={{margin:0,padding:'20px 14px',background:'white'}}>
                        <div className="col-md-4">
                            <span>Declined method: </span><br/>
                            <span>Declined by: {this.state.allTrans.updatedBy!==null ? (JSON.parse(this.state.allTrans.updatedBy)).username : ''}</span><br/><br/>
                            <span>Remark:  {this.state.allTrans.updatedBy!==null ? (JSON.parse(this.state.allTrans.updatedBy)).remark : ''}</span><br/>
                        </div>
                    </div><br/>
                </div>
              }
              <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:700,margin:0}}>Request Details</h6>
              </div>
              <div className="row transac_det" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-4">
                  <img alt="placeholder" src={this.state.userDetails!==null && this.state.userDetails.avatar!==''  ? this.state.userDetails.avatar : '../assets/user_placeholder.png'}/>
                  <div>
                    <span> {this.state.userDetails!==null ? this.state.userDetails.fullname : ''}</span><br/>
                    <span>Email: {this.state.userDetails!==null ? this.state.userDetails.email : ''}</span><br/>
                    <span>Phone: {this.state.userDetails!==null ? this.state.userDetails.phone : ''}</span><br/>
                  </div>
                </div>
				<div className="col-md-5">
                  <span>Username: {this.state.userDetails!==null ? this.state.userDetails.username : ''}</span><br/>
                  <span>Address: </span><br/>
                  <span>Coin: {this.state.allTrans.currency}</span><br/>
                  <span>Amount: {this.state.allTrans.amount}</span><br/>
                  <span>Fee: {this.state.allTrans.fee}</span><br/>
                  <span>Referral Username:  {this.state.userDetails!==null ? this.state.userDetails.username : ''}</span><br/>
                  <span>TransactionId:  {this.state.allTrans.id}</span><br/>
                  <span>txId: </span><br/><br/>
                  <span>Request time: {timestampTime(this.state.allTrans.createdAt)}</span><br/>
                </div>
              </div><br/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TransactionDetails;