import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Dot,Search } from 'react-bootstrap-icons';

const data = [
  {id: 1,name: "POKER8",product_code: "GD",datetime:"2019-07-11 00:00:00"},
  {id: 2,name: "POKER8",product_code: "GD",datetime:"2019-07-11 00:00:00"},
  {id: 3,name: "POKER8",product_code: "GD",datetime:"2019-07-11 00:00:00"},
  {id: 4,name: "POKER8",product_code: "GD",datetime:"2019-07-11 00:00:00"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '14px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class ProductMaintenance extends React.Component {
  render() {
    const columns = [
      {name: 'No.',selector: 'id',width:'50px'},
      {name: 'Product Name',selector: 'name',},
      {name: 'Product Code',selector: 'product_code',},
      {name: 'Maint. Status',selector: 'status',cell: row => <div data-tag="allowRowEvents"><Dot style={{marginLeft:'-8px'}} color="#53D816" size={30}/>Off</div>},
      {name: 'Maintenance Start Time',selector: 'datetime'},
      {name: 'Maintenance End Time',selector: 'datetime'},
      {name: 'Actions',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}><span style={{cursor:'pointer'}} onClick={()=>this.props.history.push('/admin/update_product_details')}>Edit</span></div>,width:'80px'},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Product</span> / 
                    <span>Product Maintenance</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Product Maintenance</h5>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              <div className="subbdvv1">
                <div className="subdvv1_1">
                  <div className="subdvv1_1_1">
                    <input type="text" className="form-control" placeholder="Please enter"/>
                    <Search color="#00000040" size={15}/>
                  </div>
                </div><br/>
                <div className="subdvv1_2">
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ProductMaintenance;