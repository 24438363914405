import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { makeRequest,makeProviderRequest } from '../../helpers'

class AddDepositChannel extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.makeProviderRequest = makeProviderRequest
    this.add = this.add.bind(this)
    this.changeBankName = this.changeBankName.bind(this)
    this.changeAccountName = this.changeAccountName.bind(this)
    this.changeType = this.changeType.bind(this)
    this.changeAccountNumber = this.changeAccountNumber.bind(this)
    this.changeMinDeposit = this.changeMinDeposit.bind(this)
    this.changeCurrency = this.changeCurrency.bind(this)
    this.changeChannelName = this.changeChannelName.bind(this)
    this.changeBankAddress = this.changeBankAddress.bind(this)
    this.changeAccAddress = this.changeAccAddress.bind(this)
    this.changeSwift = this.changeSwift.bind(this)
  }
  state= {
    type:'bank',
    bank_name:'',
    account_name:'',
    account_number:'',
    min_deposit:'',
    currency:'',
    channel_name:'',
    bank_address:'',
    acc_address:'',
    swift:'',
    submitDisabled:false,
    error_msg:'',
    success_msg:'',
    owner_id:'',
  }
  changeType(e) {
    this.setState({
      type: e.target.value,
    })
  }
  changeBankName(e) {
    this.setState({
      bank_name: e.target.value,
    })
  }
  changeAccountName(e) {
    this.setState({
      account_name: e.target.value,
    })
  }
  changeAccountNumber(e) {
    this.setState({
      account_number: e.target.value,
    })
  }
  changeMinDeposit(e) {
    this.setState({
      min_deposit: e.target.value,
    })
  }
  changeCurrency(e) {
    this.setState({
      currency: e.target.value,
    })
  }
  changeChannelName(e) {
    this.setState({
      channel_name: e.target.value,
    })
  }
  changeBankAddress(e) {
    this.setState({
      bank_address: e.target.value,
    })
  }
  changeAccAddress(e) {
    this.setState({
      acc_address: e.target.value,
    })
  }
  changeSwift(e) {
    this.setState({
      swift: e.target.value,
    })
  }
  componentDidMount() {
    const owner_id=window.location.hash.substr(1);;
    this.setState(state => ({owner_id:owner_id}));
  } 
  async add(){
    if(this.state.bank_name===''){
      this.setState(state => ({error_msg:'Enter bank name.'}));
    }else if(this.state.account_name===''){
      this.setState(state => ({error_msg:'Enter account name.'}));
    }else if(this.state.account_number===''){
        this.setState(state => ({error_msg:'Enter account number.'}));
    }else if(this.state.min_deposit===''){
        this.setState(state => ({error_msg:'Enter minimum deposit.'}));
    }else if(this.state.currency===''){
        this.setState(state => ({error_msg:'Enter currency.'}));
    }else if(this.state.type==='wire' && this.state.channel_name===''){
        this.setState(state => ({error_msg:'Enter channel name.'}));
    }else if(this.state.type==='wire' && this.state.bank_address===''){
        this.setState(state => ({error_msg:'Enter bank address.'}));
    }else if(this.state.type==='wire' && this.state.acc_address===''){
        this.setState(state => ({error_msg:'Enter account address.'}));
    }else if(this.state.type==='wire' && this.state.swift===''){
        this.setState(state => ({error_msg:'Enter swift.'}));
    }else{
      this.setState(state => ({submitDisabled:true}));
      var data;
      if(this.state.type==='bank'){
        data = JSON.stringify({bankName: this.state.bank_name,accountName: this.state.account_name,accountNumber: this.state.account_number,minimumDeposite: parseInt(this.state.min_deposit),currency: this.state.currency});
      }else{
        data = JSON.stringify({bankName: this.state.bank_name,accountName: this.state.account_name,accountNumber: this.state.account_number,minimumDeposite: parseInt(this.state.min_deposit),currency: this.state.currency,bankAddress: this.state.bank_address,accountAddress: this.state.acc_address,channelName: this.state.channel_name,swift: this.state.swift});
      }
      var result1=await this.makeProviderRequest('owner/'+this.state.owner_id+'/deposit-channel/'+this.state.type, 'post', data,localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState({
          success_msg:'Channel added successfully',error_msg:'',submitDisabled:false
        })
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled:false,success_msg:''}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false,success_msg:''}));
    }
  }
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0,height:"100vh"}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 24px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Deposit Channel</span> / 
                    <span>Add deposit channel</span>
                  </div>
                  <h5 className="pg_head">Add deposit channel</h5>
                </div>
              </div><br/>
            </div>
            <div style={{padding:'0 20px'}}>
              {this.state.success_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>):('')
              }
              {this.state.error_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.error_msg}</p>):('')
              }
            </div>
            <div className="divbody">
              <div className="subbdvv1 add_owner_subdiv" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:600,margin:0}}>Informations
                  <div className="add_owner_btns" style={{float:'right',marginTop:'-6px'}}>
                    <button className="btn btn-primary add_btn" onClick={this.add} disabled={this.state.submitDisabled}>Add</button>
                    <button className="btn btn-primary add_btn cancel_btn" onClick={()=>this.props.history.push('/admin/platform_owner_detail#'+this.state.owner_id)}>Cancel</button>
                  </div>
                </h6>
              </div>
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white',width:"100%"}}>
                <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div">
                      <span>Type: </span>
                      <select className="form-control" onChange={this.changeType}>
                            <option value="bank">Bank</option>
                            <option value="wire">Wire</option>
                      </select>
                    </div><br/>
                    <div className="add_form_div">
                      <span>Bank Name:</span>
                      <input type="text" className="form-control" onChange={this.changeBankName}/>
                    </div><br/>
                    <div className="add_form_div">
                      <span>Account Name：</span>
                      <input type="text" className="form-control" onChange={this.changeAccountName}/>
                    </div><br/>
                    <div className="add_form_div">
                      <span style={{minWidth:"144px"}}>Account Number：</span>
                      <input type="number" className="form-control" onChange={this.changeAccountNumber}/>
                    </div><br/>
                    <div className="add_form_div">
                      <span style={{minWidth:"144px"}}>Minimum deposit：</span>
                      <input type="number" className="form-control" onChange={this.changeMinDeposit}/>
                    </div><br/>
                    <div className="add_form_div" style={{marginBottom:"10px"}}>
                      <span>Currency：</span>
                      <select className="form-control" onChange={this.changeCurrency}>
                          <option>Choose currency</option>
                          <option value="EUR">EUR</option>
                          <option value="USD">USD</option>
                          <option value="THB">THB</option>
                      </select>
                    </div>
                </div>
                {this.state.type==='wire' ?
                (<div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12">
                  <div className="add_form_div">
                    <span>Channel name: </span>
                    <input type="text" className="form-control" onChange={this.changeChannelName}/>
                  </div><br/>
                  <div className="add_form_div">
                    <span>Bank Address: </span>
                    <input type="text" className="form-control" onChange={this.changeBankAddress}/>
                  </div><br/>
                  <div className="add_form_div">
                    <span style={{minWidth:"144px"}}>Account Address: </span>
                    <input type="text" className="form-control" onChange={this.changeAccAddress}/>
                  </div><br/>
                  <div className="add_form_div">
                    <span>Swift: </span>
                    <input type="text" className="form-control" onChange={this.changeSwift}/>
                  </div><br/>
                </div>):('')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddDepositChannel;
