import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import { makeRequest } from '../helpers'

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.changePcode = this.changePcode.bind(this)
    this.changeNewp = this.changeNewp.bind(this)
    this.changeRePwd = this.changeRePwd.bind(this)
    this.updatePassword = this.updatePassword.bind(this)
    this.timer = this.timer.bind(this)
    this.sendTotop = this.sendTotop.bind(this)
    this.closeMessage = this.closeMessage.bind(this)
    this.makeRequest = makeRequest
  }
  state= {
    code:'',
    pwd:'',
    newpwd:'',
    emailValid:'',
    pCodeValid:'',
    pwdValid:'',
    newpwdValid:'',
    success_msg:'',
    success_msg2:'',
    error_msg:'',
    submitDisabled:false,
    showTimer:false,
    submitDisabled2:false,
    time:60,
    forgotDiv:true,
    resetDiv:false,
  }
  componentDidMount() {
    const code=window.location.hash.substr(1);
    this.setState({code: code})
   //this.setState({code: this.props.match.params.code})
  }
  timer(){
    if(this.state.time>1){
      let i=parseInt(this.state.time)
      i=i-1;
      this.setState(state => ({time:i}));
    }else{
      this.setState({submitDisabled: false,showTimer:false})
    }
  }
  closeMessage(){
    this.setState({success_msg2: ''})
  }
  changePcode(e) {
    let pCodeValid = e.target.value ? true : false;
    this.setState(state => ({
      code: e.target.value,
      pCodeValid: pCodeValid,
    }));
  }
  changeNewp(e) {
    let pwdValid = e.target.value ? true : false;
    this.setState(state => ({
      pwd: e.target.value,
      pwdValid: pwdValid,
    }));
  }
  changeRePwd(e) {
    let newpwdValid = e.target.value ? true : false;
    if(this.state.pwd!==e.target.value){
      this.setState(state => ({
        error_msg:'Passwords do not match',
        newpwd: e.target.value,
      }));
    }
    else{
      this.setState(state => ({
        error_msg:'',
        newpwdValid: newpwdValid,
        newpwd: e.target.value,
      }));
      if(this.state.pCodeValid && this.state.pwdValid){
        this.setState(state => ({
          submitDisabled: true,
        }));
      }
    }
  }
  async updatePassword(){
    if(this.state.pwd===''){
      this.setState(state => ({error_msg:'Enter password fields.'}));
    }else if((this.state.pwd).length<8){
      this.setState(state => ({error_msg:'Password must be atleast 8 Characters.'}));
    }else{
      if(this.state.pwdValid && this.state.newpwdValid){
        this.setState(state => ({
          submitDisabled2: true,error_msg:'',success_msg:''
        }));
      }
      var data = JSON.stringify({token:this.state.code,password: this.state.pwd});
      console.log(data);
      var result1=await this.makeRequest('auth/update-password', 'post', data,'');
      if(result1.status===200){
          
        this.setState(state => ({
          success_msg2:result1.data.message,
          submitDisabled2: false,
        }))
        this.props.history.push('/home#password_verified');
      }
      else if(result1.status===400)
       this.setState(state => ({err_msg:result1.data.message,submitDisabled2:false}));
      else if(result1.status===500)
        this.setState(state => ({err_msg:'Some problem occured. Please try again.',submitDisabled2:false}));
    }
  }
  sendTotop(){
    window.scrollTo(0, 0)
    document.getElementById("usernamme").focus();
  }

  render() {
    return (
      <div>
        <Navbar prop={this.props}/>
        <div style={{background:'#000C16',color:'white',paddingTop: '140px'}} className="cockfightngdiv">
          <div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-5 col-md-7 text-center">
                <h1 className="text-white text-center homeheadng">Reset Password</h1>
                <img alt="line" src={'../assets/aboutus.png'}/><br/><br/>
                </div>
              </div><br/>
              <div className="row justify-content-center form_div" style={{position:"relative"}}>
                <div className="col-lg-4 col-md-6">
                  {this.state.success_msg2!=='' &&
                    <p className="text-center" style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg2}</p>
                  }
                  
                  {this.state.err_msg!=='' &&
                    <p className="text-center" style={{fontSize: '16px',color: '#ff2424'}}>{this.state.err_msg}</p>
                  }
                  <div className="row">
                      <div className="col-md-12" style={{position:'relative'}}>
                        {this.state.pwd!=='' &&
                          <span className="formfilled_txt">New Password</span>
                        }
                        <input className="form-control" type="password" placeholder="New Password" onChange={this.changeNewp}/><br/>
                      </div>
                      <div className="col-md-12" style={{position:'relative'}}>
                        {this.state.newpwd!=='' &&
                          <span className="formfilled_txt">Confirm Password</span>
                        }
                        <input className="form-control" type="password" placeholder="Re-enter password" onChange={this.changeRePwd}/><br/><br/>
                      </div>
                      <div className="col-md-12">
                        <div><button className="btn btn-primary w-100 signup_btn comn_btn" style={{border:"1px solid #ffffffb0"}} onClick={this.updatePassword}>RESET PASSWORD</button></div>
                        <br/><br/>
                      </div>
                      <div className="col-md-12 text-center" style={{fontSize:'14px'}}>
                          <span style={{color: 'white',opacity: 0.75}}>Do you remember your password ?</span><br/><br/>
                          <span className="yellowcolor">Try phone reset password</span>
                          <p style={{color:"black"}}>Cancel</p>
                      </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <br/><br/><br/><br/><br/>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default ResetPassword;