import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Dot,Search } from 'react-bootstrap-icons';
// import { Search,Dot,ChevronUp } from 'react-bootstrap-icons';
const data = [
  {id: 1,name: "TOPPAY33",swift_code: "-",gateway:"Yes"},
  {id: 2,name: "TOPPAY33",swift_code: "-",gateway:"No"},
  {id: 3,name: "TOPPAY33",swift_code: "-",gateway:"Yes"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '14px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class BankAccounts extends React.Component {
  render() {
    const columns = [
      {name: 'No.',selector: 'id'},
      {name: 'Bank Name',selector: 'name',
        
      },
      {name: 'Swift Code',selector: 'swift_code',},
      {name: 'Status',selector: 'status',cell: row => <div data-tag="allowRowEvents"><Dot color="#53D816" size={30}/>Active</div>},
      {name: 'Payment Gateway',selector: 'gateway'},
      {name: 'Actions',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}><span style={{cursor:'pointer'}} onClick={()=>this.props.history.push('/admin/platform_owner_detail')}>Edit</span></div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Bank management</span> / 
                    <span>Banks</span>
                  </div>
                  <h5 className="pg_head">Banks</h5>
                  <button className="btn btn-primary addbtn">Add Bank</button>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              <div className="subbdvv1">
                <div className="subdvv1_1 banks_div">
                  <div className="subdvv1_1_1">
                    <input type="text" className="form-control" placeholder="Please enter"/>
                    <Search color="#00000040" size={15}/>
                  </div>
                  <span>Status: </span>
                  <select className="form-control">
                    <option value="">Active</option>
                  </select>
                </div><br/>
                <div className="subdvv1_2">
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BankAccounts;