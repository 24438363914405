import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Dot,Search } from 'react-bootstrap-icons';
import { makeProviderRequest,timestampDate } from '../../helpers';
// const data = [
//   {
//     id: 1,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
//   {
//     id: 2,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
//   {
//     id: 3,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
// ];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '14px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class PlatformOwners extends React.Component {
  constructor(props) {
    super(props);
    this.makeProviderRequest = makeProviderRequest
		this.changeStatusFilter = this.changeStatusFilter.bind(this)
		this.search = this.search.bind(this)
		this.changeUsername = this.changeUsername.bind(this)
		this.clearSearchData = this.clearSearchData.bind(this)
  }
  state={
    ownerss:[],
    status:'',
    username:'',
  }
  changeStatusFilter(e) {
    this.setState({
      status: e.target.value,
    })
  }
  changeUsername(e) {
    this.setState({
      username: e.target.value,
    })
  }
  clearSearchData(){
    this.setState({
      username: '',
    })
    this.setState({
      status: '',
    })
    this.componentDidMount();
  }
  async search(){
    var result1;
      if((this.state.status!=='') && (this.state.username==='')){
        result1=await this.makeProviderRequest('owner?status='+this.state.status, 'get','',localStorage.getItem('role_token'));
      }else if((this.state.status==='') && (this.state.username!=='')){
        result1=await this.makeProviderRequest('owner?keyword='+this.state.username, 'get','',localStorage.getItem('role_token'));
      }else if((this.state.status!=='') && (this.state.username!=='')){
        result1=await this.makeProviderRequest('owner?status='+this.state.status+'&keyword='+this.state.username, 'get','',localStorage.getItem('role_token'));
      }else if((this.state.status==='') && (this.state.username==='')){
        result1=await this.makeProviderRequest('owner', 'get','',localStorage.getItem('role_token'));
      }
      if(result1.status===200){
        this.setState(state => ({ownerss:result1.data.data}));
      }
  }
  componentDidMount() {
    if(localStorage.getItem('role')!=='provider'){
      this.props.history.push('/admin/admin_login');
    }
    (async () => {
      var result1=await this.makeProviderRequest('owner', 'get','',localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState(state => ({ownerss:result1.data.data}));
      }
    })();
	} 
  render() {
    const columns = [
    {
      name: 'Username',width: '180px',selector: 'username',cell: row => <div data-tag="allowRowEvents">{row.username}</div>,
    },
    {
      name: 'Full Name',width: '180px',selector: 'fname',cell: row => <div data-tag="allowRowEvents">{row.fullname}</div>,
    },
    {
      name: 'Status',width: '180px',selector: 'status',cell: row => <div data-tag="allowRowEvents"><Dot color="#53D816" size={30}/>{row.status}</div>,
    },
    {
      name: 'Last Login Time/Last Login IP',width: '250px',selector: 'time_ip',cell: row => <div data-tag="allowRowEvents" style={{height: '40px',whiteSpace: 'initial'}}>{row.last_login!==null ?timestampDate(row.last_login) : ''}<br/>{row.lastLoginIp!==null ? row.lastLoginIp : ''}</div>,
    },
    {
      name: 'Actions',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}><span style={{cursor:'pointer'}} onClick={()=>this.props.history.push('/admin/platform_owner_detail#'+row.key)}>View</span> | <span style={{cursor:'pointer'}} onClick={()=>this.props.history.push('/admin/edit_platform_owner#'+row.key)}>Edit</span></div>,right:true
    },
  ];
  var data = this.state.ownerss.map(function(item) {
    return {
      key: item.id,
      username:item.username,
      fullname: item.fullname,
      status: item.status,
      last_login: item.lastLogin,
      last_login_ip: item.lastLoginIp,
    };
  });
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4',width:"100%"}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>Platform Owners</span>
                  </div>
                  <h5 className="pg_head">Platform Owners</h5>
                  <button className="btn btn-primary addbtn" onClick={()=>this.props.history.push('/admin/add_platform_owner')}>+ Add platform owners</button>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              <div className="subbdvv1">
                <div className="subdvv1_1 subdvv1_1_block">
                  <div className="subdvv1_1_1">
                    <input type="text" className="form-control" placeholder="Username" onChange={this.changeUsername} value={this.state.username} />
                    <Search color="#00000040" size={15}/>
                  </div>
                  <span>Status: </span>
                  <select className="form-control" onChange={this.changeStatusFilter} value={this.state.status}>
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="blocked">Blocked</option>
                  </select>
                  <button className="btn btn-primary filter_btn text-white" style={{background: '#1890FF'}}onClick={this.search}>Search</button>
                  <button className="btn btn-primary filter_btn text-white" style={{background: '#BFBFBF'}} onClick={this.clearSearchData}>Clear</button>
                </div><br/>
                <div className="subdvv1_2">
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlatformOwners;
