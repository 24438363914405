import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search,ChevronUp } from 'react-bootstrap-icons';
import exportFromJSON from 'export-from-json'
const data = [
  {
    id: 1,name: "Cbank",acc_name: "CCC",acc_no: "123456789",trans_type: "Member Deposit",remark: "-",by: "dclient02",trans_dt: "2021-03-13 12:01:01",deposit: "0.00",withdr: "0.00",fees: "0.00",
  },
  {
    id: 2,name: "Cbank",acc_name: "CCC",acc_no: "123456789",trans_type: "Member Deposit",remark: "-",by: "dclient02",trans_dt: "2021-03-13 12:01:01",deposit: "0.00",withdr: "0.00",fees: "0.00",
  },
  {
    id: 3,name: "Cbank",acc_name: "CCC",acc_no: "123456789",trans_type: "Member Deposit",remark: "-",by: "dclient02",trans_dt: "2021-03-13 12:01:01",deposit: "0.00",withdr: "0.00",fees: "0.00",
  },
  {
    id: 4,name: "Cbank",acc_name: "CCC",acc_no: "123456789",trans_type: "Member Deposit",remark: "-",by: "dclient02",trans_dt: "2021-03-13 12:01:01",deposit: "0.00",withdr: "0.00",fees: "0.00",
  },
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '13px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '14px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const excelData = [
  {
    "Bank Name": "Cbank",
    "Bank Acc. Name": "CCC",
    "Bank Acc. No.": '123456789',
    "Transaction Type": 'Member Deposit',
    "Deposit": '0.00',
    "Withdrawal": 0.00,
    "Fees": 0.00,
    "Transfer By": 'dclient02',
    "Trans. Date": '2021-03-13 12:01:01',
    "Remark": '-'
  },
  {
    "Bank Name": "Cbank",
    "Bank Acc. Name": "CCC",
    "Bank Acc. No.": '123456789',
    "Transaction Type": 'Member Deposit',
    "Deposit": '0.00',
    "Withdrawal": 0.00,
    "Fees": 0.00,
    "Transfer By": 'dclient02',
    "Trans. Date": '2021-03-13 12:01:01',
    "Remark": '-'
  },
  {
    "Bank Name": "Cbank",
    "Bank Acc. Name": "CCC",
    "Bank Acc. No.": '123456789',
    "Transaction Type": 'Member Deposit',
    "Deposit": '0.00',
    "Withdrawal": 0.00,
    "Fees": 0.00,
    "Transfer By": 'dclient02',
    "Trans. Date": '2021-03-13 12:01:01',
    "Remark": '-'
  },
  {
    "Bank Name": "Cbank",
    "Bank Acc. Name": "CCC",
    "Bank Acc. No.": '123456789',
    "Transaction Type": 'Member Deposit',
    "Deposit": '0.00',
    "Withdrawal": 0.00,
    "Fees": 0.00,
    "Transfer By": 'dclient02',
    "Trans. Date": '2021-03-13 12:01:01',
    "Remark": '-'
  }
]
class DetailedBankReport extends React.Component {
  constructor(props) {
  super(props);
  this.tableRef = React.createRef();
  this.printData = this.printData.bind(this)
  this.exportData = this.exportData.bind(this)
  }
  printData(){
    window.print();
  }
  exportData(){
    exportFromJSON({ data: excelData, fileName: 'Detailed bank report', exportType: exportFromJSON.types.xls})
  }
  render() {
    const columns = [
        {name: 'No.',selector: 'id',width:'50px'},
        {name: 'Bank Name',selector: 'name'},
        {name: 'Bank Acc. Name',selector: 'acc_name'},
        {name: 'Bank Acc. No.',selector: 'acc_no'},
        {name: 'Trans. Type',selector: 'trans_type'},
        {name: 'Deposit',selector: 'deposit',width:'68px'},
        {name: 'Withdrawal',selector: 'withdr',width:'92px'},
        {name: 'Fees',selector: 'fees',width:'60px'},
        {name: 'Transfer By',selector: 'by'},
        {name: 'Trans. Date',selector: 'trans_dt'},
        {name: 'Remark',selector: 'remark',width:'70px'},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}} className="print_hide">
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Bank management</span> / 
                    <span>Bank Statement</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Detailed Bank Report</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody print_hide">
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-2">
                   <span className="checkspan"><span>Bank: </span></span>
                </div>
                <div className="col-md-10">
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>Deselect All</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                    </div><br/>
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                    </div><br/>
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                    </div>
                </div>
              </div>
              <div className="dashdbordr"></div>
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white',fontSize:'14px'}}>
                <div className="col-md-2">
                    <span>Bank Accounts: </span>
                </div>
                <div className="col-md-10">
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>Deselect All</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                    </div>
                </div>
              </div>
              <div className="dashdbordr"></div>
              <div className="row member_listing" style={{margin:0,padding:'20px 14px',background:'white',fontSize:'14px'}}>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                   <span>Date Range: </span>
                   <input type="date" className="diselect form-control input_width_100"/>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                   <span>Transaction Type: </span>
                   <select className="form-control diselect input_width_100">
                       <option>All</option>
                   </select>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 top_div1">
                    <button className="btn btn-primary add_btn" style={{width:'auto'}}>Search</button>
                    <span style={{float:'right',color: '#1890ff'}}>Pack up <ChevronUp color="#1890ff" size="14"/></span>
                </div>
              </div>
            </div>
            
            <div className="divbody">
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-12 print_hide">
                    <span className="subdvv1_1_1">
                        <input type="text" className="form-control search" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Search"/>
                        <Search color="#00000040" style={{top:'4px'}} size={15}/>
                    </span>
                    <div className="top_btns" style={{float:'right',marginBottom:'10px'}}>
                        <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                        <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                    </div>
                </div>
                <div className="col-md-12 trans_tabl">
                  <br/>
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DetailedBankReport;