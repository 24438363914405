import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { List,Search,XCircleFill } from 'react-bootstrap-icons';
import { makeRequest,makeProviderRequest,gameList,timestampTime } from '../../helpers';
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '14px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class PlatformOwnerDets extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.makeRequest = makeRequest
    this.gameList = gameList
    this.makeProviderRequest = makeProviderRequest
    this.admPwdModal = this.admPwdModal.bind(this)
    this.ownerPwdModal = this.ownerPwdModal.bind(this)
    this.ShowOwnerPwdModal = this.ShowOwnerPwdModal.bind(this)
    this.bankModal = this.bankModal.bind(this)
    this.editBankModal = this.editBankModal.bind(this)
    this.currntOption = this.currntOption.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.changePwd = this.changePwd.bind(this)
    this.timer = this.timer.bind(this)
    this.updateGame = this.updateGame.bind(this)
    this.updateAccountStatus = this.updateAccountStatus.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
    this.updateStatus = this.updateStatus.bind(this)
    this.changeOwnerPassword = this.changeOwnerPassword.bind(this)
    this.changeStatusFilter = this.changeStatusFilter.bind(this)
    this.changeTypeFilter = this.changeTypeFilter.bind(this)
    this.changeCurrencyFilter = this.changeCurrencyFilter.bind(this)
    this.changeChannelFilter = this.changeChannelFilter.bind(this)
    this.search = this.search.bind(this)
    this.clearSearchData = this.clearSearchData.bind(this)
  }
  state={
    showAdmPwdModal:false,
    showOwnerPwdModal:false,
    showBankModal:false,
    showEditBankModal:false,
    bank_type:'local_bank',
    getOwnerDetail:[],
    gameData:[],
    selectedGame:[],
    checkGames:[],
    pwd:'',
    owner_id:'',
    channels:[],
    accountStatus:'',
    error_msg2:'',
    success_msg2:'',
    error_msg3:'',
    success_msg3:'',
    deposit_success_msg:'',
    deposit_error_msg:'',
    deposit_success_msg1:'',
    deposit_error_msg1:'',
    submitDisabled1:false,
    submitDisabled2:false,
    submitDisabled3:false,
    owner_status:'',
    ownerPwd:'',
    type:'',
    status:'',
    currency:'',
    channel:'',
  }
  admPwdModal(val){
    this.setState({showAdmPwdModal: val})
  }
  ownerPwdModal(val){
    this.setState({showOwnerPwdModal: val})
  }
  bankModal(val){
    this.setState({showBankModal: val})
  }
  editBankModal(val){
    this.setState({showEditBankModal: val})
  }
  changeStatus(e){
    this.setState({accountStatus: e.target.value})
  }
  currntOption(e){
    this.setState({bank_type: e.target.value})
  }
  changeOwnerPassword(e){
    this.setState({ownerPwd: e.target.value})
  }
  changePwd(e) {
    let pwdValid = e.target.value ? true : false;
    this.setState({
      pwd: e.target.value,
      pwdValid: pwdValid,
    })
  }
  changeStatusFilter(e) {
    this.setState({
      status: e.target.value,
    })
  }
  changeTypeFilter(e) {
    this.setState({
      type: e.target.value,
    })
  }
  changeCurrencyFilter(e) {
    this.setState({
      currency: e.target.value,
    })
  }
  changeChannelFilter(e) {
    this.setState({
      channel: e.target.value,
    })
  }
  async search(){
    var search_data='owner/'+this.state.owner_id+'/deposit-channel?';
      if(this.state.status!==''){
        search_data=search_data+'&status='+this.state.status;
      }if(this.state.type!==''){
        search_data=search_data+'&type='+this.state.type;
      }if(this.state.currency!==''){
        search_data=search_data+'&currency='+this.state.currency;
      }
      var result1=await this.makeProviderRequest(search_data, 'get','',localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState(state => ({channels:result1.data.data}));
      }
  }
  async clearSearchData(){
    this.setState({
      type: '',
    })
    this.setState({
      status: '',
    })
    this.setState({
      currency: '',
    })
    var result1=await this.makeProviderRequest('owner/'+this.state.owner_id+'/deposit-channel', 'get','',localStorage.getItem('role_token'));
    if(result1.status===200){
      this.setState(state => ({channels:result1.data.data}));
    }
  }
  // checkItems() {
  //   console.log(this.state.checkGames);
  //   Object.keys(this.myRef.current.children).map((index)=>{
  //     var game_name=this.myRef.current.children[index].children[0].children[0].children[0].value;
  //     var current_checkbox=this.myRef.current.children[index].children[0].children[0].children[0];
  //     this.state.checkGames.some(function (elem) {
  //       console.log(elem);
  //         if(elem.name === game_name){
  //           current_checkbox.defaultChecked=true;
  //         }
  //     });
  //   })
  // }
  timer(){
     this.setState(state => ({error_msg:'',success_msg:'',submitDisabled:false}));
  }
  async ShowOwnerPwdModal(val){
    if(this.state.ownerPwd===''){
      this.setState(state => ({error_msg3:'Enter password.'}));
    }else if((this.state.ownerPwd).length<8){
      this.setState(state => ({error_msg3:'Password must be atleast 8 Characters.'}));
    }else{
      this.setState(state => ({submitDisabled3:true}));
      let data = JSON.stringify({username:'provider',password: this.state.ownerPwd});
      var result1=await this.makeProviderRequest('auth/login', 'post', data,localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState({showOwnerPwdModal: val})
        this.setState(state => ({error_msg3:'',submitDisabled3:false}));
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg3:result1.data.message,submitDisabled3:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg3:'Some problem occured. Please try again.',submitDisabled3:false}));
    }
  }
  async updateGame(e){
      var id=e.target.getAttribute("attr");
      let data = JSON.stringify({gameId: id,status: e.target.checked});
      var result1=await this.makeProviderRequest('owner/'+this.state.owner_id+'/game', 'put', data,localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState({
          success_msg1:result1.data.message,error_msg1:''
        })
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg1:result1.data.message}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg1:'Some problem occured. Please try again.'}));
   }
  async changePassword(){
	 if(this.state.pwd===''){
      this.setState(state => ({error_msg:'Enter password.'}));
    }else if((this.state.pwd).length<8){
      this.setState(state => ({error_msg:'Password must be atleast 8 Characters.'}));
    }else{
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({password: this.state.pwd});
      var result1=await this.makeProviderRequest('owner/'+this.state.owner_id+'/password', 'put', data,localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState({
          success_msg:result1.data.message,error_msg:'',submitDisabled1:false
        })
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled1:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled1:false}));
    }
  }
  async updateAccountStatus(){
    if(this.state.accountStatus===''){
       this.setState(state => ({error_msg2:'Choose status.'}));
     }else{
       this.setState(state => ({submitDisabled2:true}));
       let data = JSON.stringify({status: this.state.accountStatus});
       var result1=await this.makeProviderRequest('owner/'+this.state.owner_id+'/status', 'put', data,localStorage.getItem('role_token'));
       if(result1.status===200){
         this.setState({
           success_msg2:result1.data.message,error_msg2:'',submitDisabled2:false
         })
         this.setState(state => ({error_msg2:''}));
       }
       else if(result1.status===400){
         this.setState(state => ({error_msg2:result1.data.message}));
         this.setState(state => ({submitDisabled2:false}));
       }
       else if(result1.status===500)
         this.setState(state => ({error_msg2:'Some problem occured. Please try again.'}));
         this.setState(state => ({submitDisabled2:false}));
     }
   }
   async deleteChannel(id){
      var result1=await this.makeProviderRequest('owner/'+this.state.owner_id+'/deposit-channel/'+id, 'delete','',localStorage.getItem('role_token'));
      if(result1.status===200){
          this.componentDidMount();
          this.setState({
          deposit_success_msg:result1.data.message,deposit_error_msg:''
          })
      }
      else if(result1.status===400){
          this.setState(state => ({deposit_error_msg:result1.data.message}));
      }
      else if(result1.status===500){
          this.setState(state => ({deposit_error_msg:'Some problem occured. Please try again.'}));
      }
  }
  async updateStatus(e){
      var id=e.target.getAttribute("attr");
      let data = JSON.stringify({status: e.target.checked});
      var result1=await this.makeProviderRequest('owner/'+this.state.owner_id+'/deposit-channel/'+id+'/status', 'put', data,localStorage.getItem('role_token'));
      if(result1.status===200){
          this.setState({
            deposit_success_msg1:result1.data.message,deposit_error_msg1:''
          })
          this.componentDidMount();
      }
      else if(result1.status===400){
          this.setState(state => ({deposit_error_msg1:result1.data.message}));
      }
      else if(result1.status===500)
          this.setState(state => ({deposit_error_msg1:'Some problem occured. Please try again.'}));
  }
	componentDidMount() {
		this.gameList(this);
		const owner_id=window.location.hash.substr(1);
		this.setState({owner_id: window.location.hash.substr(1)});
		(async () => {
		  var result1=await this.makeProviderRequest('owner/'+owner_id, 'get','',localStorage.getItem('role_token'));
		  if(result1.status===200){
			  this.setState({checkGames: result1.data.gameControl})
        this.setState({
          getOwnerDetail: result1.data
        })
        if(result1.data.gameControl!='' && result1.data.gameControl!=undefined && result1.data.gameControl!=null){
          //this.checkItems();
        }
        this.setState({accountStatus: result1.data.status})
		  }
		})();
    (async () => {
		  var result1=await this.makeProviderRequest('owner/'+owner_id+'/deposit-channel?', 'get','',localStorage.getItem('role_token'));
		  if(result1.status===200){
			  this.setState({channels: result1.data.data})
		  }
		})();
		
	} 
  render() {
    const columns = [
      {
        name: 'Bank deposit channel',width: '180px',selector: 'name',cell: row => <div data-tag="allowRowEvents">{row.channelName}</div>,
      },
      {
        name: 'Currency',width: '150px',selector: 'type',cell: row => <div data-tag="allowRowEvents">{row.currency}</div>,
      },
      {
        name: 'Type',width: '150px',selector: 'organization',cell: row => <div data-tag="allowRowEvents">{row.type}</div>,
      },
      {
        name: 'Name',width: '150px',selector: 'organization',cell: row => <div data-tag="allowRowEvents">{row.bankName}</div>,
      },
      {
        name: 'Close/Open',width: '150px',selector: 'time_ip',cell: row => <div data-tag="allowRowEvents"> <span>
        <label className="switch" style={{marginRight:"30px"}}>
          <input type="checkbox" defaultChecked={row.status===true ? true : false} attr={row.id} onClick={this.updateStatus}/>
          <span className="slider round"></span>
        </label>
      </span></div>,
      },
      {
        name: 'Action',width: '236px',selector: 'action',cell: row => <div data-tag="allowRowEvents" > <span onClick={() => this.deleteChannel(row.id)} style={{color:"rgb(24, 144, 255)",cursor:"pointer"}} onClick={()=>this.props.history.push('/admin/deposit_channel_details#'+row.id+'#'+this.state.owner_id)}>View | </span>
        <span onClick={()=>this.props.history.push('/admin/edit_deposit_channel#'+row.id+'#'+row.type+'#'+this.state.owner_id)} style={{color:"rgb(24, 144, 255)",cursor:"pointer"}}>Edit | </span>
        <span onClick={() => this.deleteChannel(row.id)} style={{color:"rgb(24, 144, 255)",cursor:"pointer"}}>Delete</span></div>,right:true
      },
    ];
    var data = this.state.channels.map(function(item) {
      return {
        id: item.id,
        channelName:item.channelName,
        currency: item.currency,
        bankName: item.bankName,
        status: item.status,
        type: item.type,
      };
    });
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Platform Owners</span> / 
                    <span>Platform owner detail</span>
                  </div>
                  <h5 className="pg_head">Platform owner detail</h5>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              {/* Informations */}
              <div className="subbdvv1 add_owner_subdiv" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:700,margin:0}}>Informations
                  <div className="add_owner_btns" style={{float:'right',marginTop:'-6px'}}>
                    <button className="btn btn-primary add_btn" style={{width:'auto'}} onClick={()=>this.props.history.push('/admin/edit_platform_owner#'+this.state.owner_id)}>Edit account details</button>
                  </div>
                </h6>
              </div>
              <div className="row" style={{margin:0,background:'white'}}>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" style={{padding:0}}>
                  <table className="table infotable" style={{margin:0}}>
                    <tbody>
                      <tr>
                        <td>User ID</td><td>{this.state.getOwnerDetail.id}</td>
                      </tr>
                      <tr>
                        <td>Username</td><td>{this.state.getOwnerDetail.username}</td>
                      </tr>
                      <tr>
                        <td>Full Name</td><td>{this.state.getOwnerDetail.fullname}</td>
                      </tr>
                      <tr>
                        <td>Phone Number</td><td>{this.state.getOwnerDetail.phone}</td>
                      </tr>
                      <tr>
                        <td>Permission</td><td>{this.state.getOwnerDetail.permission}</td>
                      </tr>
					            {/*<tr>
                        <td>D.O.B</td><td>{this.state.getOwnerDetail.dateOfBirth}</td>
                      </tr>
					            <tr>
                        <td>WeChat</td><td>{this.state.getOwnerDetail.wechat}</td>
                      </tr>
					            <tr>
                        <td>Telegram</td><td>{this.state.getOwnerDetail.telegram}</td>
                      </tr>
					            <tr>
                        <td>State</td><td>{this.state.getOwnerDetail.state}</td>
                      </tr>*/}
                    </tbody>
                  </table>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" style={{padding:0}}>
                  <table className="table infotable" style={{margin:0}}>
                    <tbody>
                      <tr>
                        <td>Email Address</td><td>{this.state.getOwnerDetail.email}</td>
                      </tr>
                      <tr>
                        <td>Status</td><td>{this.state.getOwnerDetail.status}</td>
                      </tr>
                      <tr>
                        <td>Address</td><td>{this.state.getOwnerDetail.address}</td>
                      </tr>
                      <tr>
                        <td>Date Created</td><td>{timestampTime(this.state.getOwnerDetail.createdAt)}</td>
                      </tr>
                      <tr style={{height:"41px"}}>
                        <td className="td_remove_after"></td><td></td>
                      </tr>
                      {/*<tr>
                        <td>Last Login</td><td></td>
                      </tr>
                      <tr>
                        <td>Last Login IP</td><td></td>
                      </tr>
                      <tr>
                        <td>Remark</td><td>{this.state.getOwnerDetail.remark}</td>
                      </tr>
					            <tr>
                        <td>Whatsapp</td><td>{this.state.getOwnerDetail.whatsapp}</td>
                      </tr>
					            <tr>
                        <td>Line</td><td>{this.state.getOwnerDetail.line}</td>
                      </tr>
					            <tr>
                        <td>Zalo</td><td>{this.state.getOwnerDetail.zalo}</td>
                      </tr>
					            <tr>
                        <td>Permission</td><td>{this.state.getOwnerDetail.permission}</td>
                      </tr>*/}
                    </tbody>
                  </table>
                </div>
              </div>
              <br/>
              <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:700,margin:0}}>Account detail</h6>
                {this.state.success_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'green',marginBottom:"0"}}>{this.state.success_msg}</p>):('')
                }
                {this.state.error_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'red',marginBottom:"0"}}>{this.state.error_msg}</p>):('')
                }
              </div>
              <div className="row subdvv1_1 sdvv1 new_class" style={{width:"100%",margin:"0"}}>
              <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-xs-12 new_class">
                <span style={{marginLeft:"0"}}>Username</span>
                <div className="subdvv1_1_1 new_class">
                  <input type="text" className="form-control" readOnly defaultValue={this.state.getOwnerDetail.username} style={{width:"100%"}}/>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-xs-12 new_class"> 
                <span>Password</span>
                <div className="subdvv1_1_1 new_class">
                  <input type="password" className="form-control" placeholder="**********" onChange={this.changePwd} style={{width:"100%"}}/>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 platform_det_btns new_class"> 
                  <button className="btn btn-primary filter_btn chgpwdd" disabled={this.state.submitDisabled1} onClick={this.changePassword}>Change password</button>
                  <button className="btn btn-primary filter_btn text-white" onClick={()=>this.admPwdModal(true)} style={{background: '#1890FF',marginRight:"0"}}>View</button>
                  <Modal size="md" show={this.state.showAdmPwdModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal">
                    <Modal.Header style={{background:'none'}} closeButton onClick={() => this.admPwdModal(false)}>Re-enter your admin password to view password</Modal.Header>
                    <Modal.Body>
                      {this.state.error_msg3!=='' ?
                        (<p style={{fontSize: '16px',color: 'red',marginBottom:"0",textAlign:"center"}}>{this.state.error_msg3}</p>):('')
                        }
                      <div style={{display:'flex'}}>
                        <label>Password</label>
                        <input type="password" className="form-control" placeholder="Current password" onChange={this.changeOwnerPassword}/>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div>
                        <span onClick={()=>this.admPwdModal(false)}>Cancel</span>
                        <button className="btn btn-primary" onClick={() => this.ShowOwnerPwdModal(true)} disabled={this.state.submitDisabled3}>View password</button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  <Modal size="md" show={this.state.showOwnerPwdModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal">
                    <Modal.Header style={{background:'none'}} closeButton onClick={() => this.ownerPwdModal(false)}>View password</Modal.Header>
                    <Modal.Body>
                      <div style={{display:'flex'}}>
                        <label>Password</label>
                        <input type="text" className="form-control" placeholder="Current password" readOnly defaultValue={this.state.getOwnerDetail.viewPassword}/>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div>
                        <span onClick={()=>this.ownerPwdModal(false)}>Cancel</span><br/>
                        {/*<button className="btn btn-primary">View password</button>*/}
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div><br/>
              </div>
              {/* Informations */}

              {/* Deactivate user permission */}
              <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:700,margin:0}}>Deactivate user permission</h6>
                {this.state.success_msg2!=='' ?
                (<p style={{fontSize: '16px',color: 'green',marginBottom:"0"}}>{this.state.success_msg2}</p>):('')
                }
                {this.state.error_msg2!=='' ?
                (<p style={{fontSize: '16px',color: 'red',marginBottom:"0"}}>{this.state.error_msg2}</p>):('')
                }
              </div>
              <div className="subdvv1_1 sdvv1">
                <span className="platform_status">Status：</span>
                <div className="row platform_data_status" style={{width:"100%",margin:"0"}}>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-5">
                        <select className="form-control input_width_100" value={this.state.accountStatus} onChange={this.changeStatus}>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                          <option value="blocked">Blocked</option>
                        </select>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-6">
                        <button className="btn btn-primary filter_btn text-white" style={{background: '#1890FF',marginRight:"0"}} onClick={this.updateAccountStatus} disabled={this.state.submitDisabled2}>Update</button>
                    </div>
                </div>
              </div><br/>
              {/* Deactivate user permission */}

              {/* Game provider control */}
              <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:700,margin:0}}>Game provider control</h6>
                {this.state.success_msg1!=='' ?
                (<p style={{fontSize: '16px',color: 'green',marginBottom:"0"}}>{this.state.success_msg1}</p>):('')
                }
                {this.state.error_msg1!=='' ?
                (<p style={{fontSize: '16px',color: 'red',marginBottom:"0"}}>{this.state.error_msg1}</p>):('')
                }
              </div>
              <div className="row" style={{margin:0,background:'white',padding: '20px 12px 0 12px'}}>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div3">
                <ul className="comm_div" style={{marginBottom:"0"}}>
                    <li className="li_head">Game Name <span>No/Yes</span></li>
                </ul>
                <ul className="comm_div" ref={this.myRef}>
                  {this.state.checkGames.map(data => (
                    <li className="game_provider_checkbox" key={data.id}>{data.name} 
                    <span>{data.status}
                      <label className="switch">
                      <input type="checkbox" defaultValue={data.name} onClick={this.updateGame} defaultChecked={data.status ? true : false} attr={data.id}/>
                        <span className="slider round"></span>
                      </label>
                    </span>
                    </li>
                  ))}
                </ul>
                </div>
                <div className="col-md-6">
                  
                </div>
              </div><br/>
              {/* Game provider control */}

              {/* Bank deposit channel */}
              <div className="subbdvv1 add_owner_subdiv" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:700,margin:0}}>Bank deposit channel
                  <div className="add_owner_btns" style={{float:'right',marginTop:'-6px'}}>
                    <button className="btn btn-primary add_btn" style={{width:'auto'}}  onClick={()=>this.props.history.push('/admin/add_deposit_channel#'+this.state.owner_id)}>+ Add new channel</button>
                  </div>
                </h6>
                <Modal size="lg" show={this.state.showBankModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal">
                  <Modal.Header style={{background:'none'}} closeButton onClick={() => this.bankModal(false)}>Add deposit channel</Modal.Header>
                  <Modal.Body>
                    <div className="formdiv">
                      <label>Currency</label>
                      <select className="form-control">
                        <option>THB</option>
                      </select>
                    </div><br/>
                    <div className="formdiv">
                      <label>Deposit type</label>
                      <select className="form-control" onChange={this.currntOption}>
                        <option value="local_bank">Local Bank</option>
                        <option value="international_wire">International Wire</option>
                      </select>
                    </div><br/>
                    {this.state.bank_type==='local_bank' &&                    
                      <div className="formdiv" style={{marginBottom:'24px'}}>
                        <label>Upload logo</label>
                        <button className="btn btn-primary up_btn" style={{width:'auto'}}>Upload</button>
                      </div>
                    }
                    <div className="formdiv">
                      <label>Bank name </label>
                      <input className="form-control" placeholder="Bank name"/>
                    </div><br/>
                    <div className="formdiv">
                      <label>Account name</label>
                      <input className="form-control" placeholder="Account name"/>
                    </div><br/>
                    {this.state.bank_type==='local_bank' &&  
                      <div>
                        <div className="formdiv">
                          <label>Bank account number</label>
                          <input className="form-control" placeholder="Bank account number"/>
                        </div><br/>
                      </div>
                    }

                    {this.state.bank_type==='international_wire' &&
                      <div>
                        <div className="formdiv">
                          <label>Channel name</label>
                          <input className="form-control" placeholder="Channel name"/>
                        </div><br/>
                        <div className="formdiv">
                          <label>Account address</label>
                          <input className="form-control" placeholder="Account address"/>
                        </div><br/>
                        <div className="formdiv">
                          <label>Account number/IBAN</label>
                          <input className="form-control" placeholder="Account number/IBAN"/>
                        </div><br/>
                        <div className="formdiv">
                          <label>Bank Address</label>
                          <input className="form-control" placeholder="Bank Address"/>
                        </div><br/>
                        <div className="formdiv">
                          <label>SWIFT</label>
                          <input className="form-control" placeholder="SWIFT"/>
                        </div><br/>
                      </div>
                    }
                    <div className="formdiv">
                      <label>Minimum deposit(THB)</label>
                      <input className="form-control" placeholder="Minimum deposit"/>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div>
                      <span onClick={()=>this.bankModal(false)}>Cancel</span>
                      <button className="btn btn-primary" style={{width:'auto'}}>Create</button>
                    </div>
                  </Modal.Footer>
                </Modal>
                
              </div>
              <div className="row subdvv1_1 sdvv1" style={{justifyContent:'center',width:"100%",margin:"0"}}>
                <div className="subdvv1_1_1 sd22">
                  {/*<input type="text" className="form-control" placeholder="Search"  onChange={this.changeChannelFilter} value={this.state.channel}/>
                  <Search color="#00000040" size={15}/>*/}
                </div>
                <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-xs-12 new_class"> 
                  <div className="subdvv1_1_1 sd22">
                    <select className="form-control input_width_100" onChange={this.changeTypeFilter} value={this.state.type}>
                        <option value="">All</option>
                        <option value="bank">Local Bank</option>
                        <option value="wire">International Wire</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-xs-12 new_class"> 
                  <div className="subdvv1_1_1 sd22">
                    <select className="form-control input_width_100" onChange={this.changeCurrencyFilter} value={this.state.currency}>
                      <option value="">Currency</option>
                      <option value="EUR">EUR</option>
                      <option value="USD">USD</option>
                      <option value="THB">THB</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-xs-12 new_class">   
                  <div className="subdvv1_1_1 sd22">
                    <select className="form-control input_width_100" onChange={this.changeStatusFilter} value={this.state.status}>
                      <option value="">Status</option>
                      <option value="on">Open</option>
                      <option value="off">Close</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 new_class">   
                  <button className="btn btn-primary filter_btn text-white" style={{background: '#1890FF'}}onClick={this.search}>Search</button>
                  <button className="btn btn-primary filter_btn chgpwdd" onClick={this.clearSearchData}>Clear</button>
                </div>
              </div>
              <div style={{background:'white',padding: '20px 24px 0 24px'}}>
                {this.state.deposit_success_msg!=='' ?
                    (<p style={{fontSize: '16px',color: 'green'}}>{this.state.deposit_success_msg}</p>):('')
                }
                {this.state.deposit_error_msg!=='' ?
                    (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.deposit_error_msg}</p>):('')
                }
                {this.state.deposit_success_msg1!=='' ?
                    (<p style={{fontSize: '16px',color: 'green'}}>{this.state.deposit_success_msg1}</p>):('')
                }
                {this.state.deposit_error_msg1!=='' ?
                    (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.deposit_error_msg1}</p>):('')
                }
                {/*<table className="table gameprovider_tbl">
                  <thead>
                    <tr>
                      <th>Bank deposit channel</th><th>Currency</th><th>Type</th><th>Name</th><th className="text-center" style={{width:'140px'}}>Close/Open</th><th className="text-center">Action</th>
                    </tr>
                  </thead>
                  {(this.state.channels).length>0 ?
                  (<tbody>
                  {this.state.channels.map(data => (
                    <tr key={data.id} attr={data.id}>
                      <td className="bank_name_td"><List color="#9D9D9D" size={20}/> {data.channelName}</td>
                      <td>{data.currency}</td><td>{data.type}</td><td>{data.bankName}</td>
                      <td className="text-center">
                        <span>
                          <label className="switch" style={{marginRight:"30px"}}>
                            <input type="checkbox" defaultChecked={data.status===true ? true : false} attr={data.id} onClick={this.updateStatus}/>
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </td>
                      <td className="text-center">
                        <span onClick={() => this.deleteChannel(data.id)} style={{color:"rgb(24, 144, 255)",cursor:"pointer"}} onClick={()=>this.props.history.push('/admin/deposit_channel_details#'+data.id+'#'+this.state.owner_id)}>View | </span>
                        <span onClick={()=>this.props.history.push('/admin/edit_deposit_channel#'+data.id+'#'+data.type+'#'+this.state.owner_id)} style={{color:"rgb(24, 144, 255)",cursor:"pointer"}}>Edit | </span>
                        <span onClick={() => this.deleteChannel(data.id)} style={{color:"rgb(24, 144, 255)",cursor:"pointer"}}>Delete</span>
                      </td>
                    </tr>
                  ))} 
                  </tbody>):(
                  <tbody>
                    <tr>
                      <td colSpan="6" style={{textAlign:"center"}}>No records found.</td>
                    </tr>
                  </tbody>
                  )}
                  </table>*/}
                <DataTable title="" columns={columns} data={data} customStyles={customStyles}/>
                <Modal size="lg" show={this.state.showEditBankModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal">
                  <Modal.Header style={{background:'none'}} closeButton onClick={() => this.editBankModal(false)}>Edit deposit channel</Modal.Header>
                  <Modal.Body>
                    <div className="formdiv">
                      <label>Currency</label>
                      <select className="form-control">
                        <option>THB</option>
                      </select>
                    </div><br/>
                    <div className="formdiv">
                      <label>Deposit type</label>
                      <select className="form-control" onChange={this.currntOption}>
                        <option value="local_bank">Local Bank</option>
                        <option value="international_wire">International Wire</option>
                      </select>
                    </div><br/>
                    {this.state.bank_type==='local_bank' &&                    
                      <div className="formdiv" style={{marginBottom:'24px'}}>
                        <label>Upload logo</label>
                        <div className="logodivv">
                          <img alt="logo" className="w-100" src={'../assets/hsbc_logo.png'}/>
                          <XCircleFill color="#5095D8" size="18"/>
                        </div>
                      </div>
                    }
                    <div className="formdiv">
                      <label>Bank name </label>
                      <input className="form-control" defaultValue="HSBC"/>
                    </div><br/>
                    <div className="formdiv">
                      <label>Account name</label>
                      <input className="form-control" defaultValue="Marry Lam"/>
                    </div><br/>
                    {this.state.bank_type==='local_bank' &&  
                      <div>
                        <div className="formdiv">
                          <label>Bank account number</label>
                          <input className="form-control" defaultValue="12312312312312"/>
                        </div><br/>
                      </div>
                    }

                    {this.state.bank_type==='international_wire' &&
                      <div>
                        <div className="formdiv">
                          <label>Channel name</label>
                          <input className="form-control" defaultValue="International Wire"/>
                        </div><br/>
                        <div className="formdiv">
                          <label>Account address</label>
                          <input className="form-control" defaultValue="XXXXYYYYZZZZ"/>
                        </div><br/>
                        <div className="formdiv">
                          <label>Account number/IBAN</label>
                          <input className="form-control" defaultValue="123123123123"/>
                        </div><br/>
                        <div className="formdiv">
                          <label>Bank Address</label>
                          <input className="form-control" defaultValue="asdasd13123"/>
                        </div><br/>
                        <div className="formdiv">
                          <label>SWIFT</label>
                          <input className="form-control" defaultValue="1312312312"/>
                        </div><br/>
                      </div>
                    }
                    <div className="formdiv">
                      <label>Minimum deposit(THB)</label>
                      <input className="form-control" defaultValue="50"/>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div>
                      <span onClick={()=>this.editBankModal(false)}>Cancel</span>
                      <span className="del_platform_owner">Delete</span>
                      <button className="btn btn-primary" style={{width:'auto'}}>Update</button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </div>
              {/* Bank deposit channel */}

              <br/>
              {/* Cryptocurrencies/chains  */}
              <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:700,margin:0}}>Cryptocurrencies/chains
                  <button className="btn btn-primary add_btn" style={{width:'auto',background: '#BFBFBF',marginLeft: '20px'}}>Coming Soon</button>
                </h6>
              </div>
              {/* Cryptocurrencies/chains  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlatformOwnerDets;
