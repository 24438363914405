import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import ReactApexChart from "react-apexcharts";
import { ChevronDown,ArrowUpCircleFill,ArrowDownCircleFill,ThreeDots,CaretUpFill } from 'react-bootstrap-icons';

class GameStatistic extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      series: [8, 15, 37, 20, 20],
      options: {
        chart: {
          type: 'donut',
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: true,
          style: {
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'normal',
          },
        },
        stroke: {
          width: 2,
          colors : ['#F44335', '#673AB7','#CDDC39', '#2096F3','#FF9801'],
          opacity: 1,
          },
          fill:{
          colors : ['#F44335', '#673AB7','#CDDC39', '#2096F3','#FF9801'],
          opacity: 1,
          },
        responsive: [{
          breakpoint: 300,
        }]
      },
    
    };
  }
  
    render() {
  
      return (

        <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Statistic</span> / 
                    <span>Game Statistic</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Game Statistic</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="subbdvv1" style={{padding: '14px 24px 0px 24px'}}>
                    <h6 style={{fontWeight:700,margin:0}}>Search Criteria</h6>
                  </div>
                <div className="row" style={{margin:0,padding:'12px 14px',background:'white'}}>               
                    <div className="col-md-6 top_div">
                        <label className="labls labls1"><span>Date Range:</span></label>
                        <input type="date" className="form-control"/>
                    </div>
                    <br/>
                    <div style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Search</button>
                        <button className="btn btn-primary add_btn reset_black_btn">Reset</button>
                    </div>
                </div><br/>
                <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <div className="accordian">
                        <p><span className="bold_span">Product Type Bet Statistic</span> <span className="normal_span">(Total Records : 6, Bet : 29.00. Payout : 20:00, Company P/L : 9.00)</span> <span className="icon"><ChevronDown color="gray" size="16"/></span></p>
                        <p><span className="bold_span">Product Bet Statistic</span> <span className="normal_span">(Total Records : 6, Bet : 29.00. Payout : 20:00, Company P/L : 9.00)</span><span className="icon"><ChevronDown color="gray" size="16"/></span> <span className="icon"><ChevronDown color="gray" size="16"/></span></p>
                        <p><span className="bold_span">Popular Product</span> <span className="icon"><ChevronDown color="gray" size="16"/></span></p>
                        <p><span className="bold_span">Popular Product Type</span>  <span className="normal_span">(Total Member Access : 19)</span><span className="icon"><ChevronDown color="gray" size="16"/></span></p>
                        <p><span className="bold_span">Popular Product</span> <span className="normal_span">(Total Member Access : 19)</span> <span className="icon"><ChevronDown color="gray" size="16"/></span></p>
                    </div>
                

                    <div id="chart" className="donut">
                      <ReactApexChart options={this.state.options} series={this.state.series} type="donut" style={{width:"100%"}} />
                    </div>

                </div>
            </div>
          </div>
        </div>
      </div>
      );
    }
  }
  
  export default GameStatistic;
