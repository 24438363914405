import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import { makeRequest,handleKeyUp } from '../helpers'

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.handleKeyUp = handleKeyUp
    this.verify = this.verify.bind(this)
    this.resendCode = this.resendCode.bind(this)
    this.timer = this.timer.bind(this)
    this.sendTotop = this.sendTotop.bind(this)
    this.c1 = this.c1.bind(this)
    this.c2 = this.c2.bind(this)
    this.c3 = this.c3.bind(this)
    this.c4 = this.c4.bind(this)
    this.c5 = this.c5.bind(this)
    this.c6 = this.c6.bind(this)
    this.pasteCode = this.pasteCode.bind(this)
    this.closeMessage = this.closeMessage.bind(this)
  }
  state= {
    email:'',
    code:'',
    code1:'',
    code2:'',
    code3:'',
    code4:'',
    code5:'',
    code6:'',
    error_msg:'',
    success_msg:'',
    submitDisabled:false,
    showTimer:true,
    time:60,
  }
  componentDidMount(){
    let intervalId="";
    if(this.state.time>1){
      intervalId = setInterval(this.timer, 1000);
    }else{
      clearInterval(intervalId)
      this.setState({submitDisabled: false})
    }
    if(localStorage.getItem('email')!==undefined && localStorage.getItem('email')!==null && localStorage.getItem('email')!==''){
      this.setState({email: localStorage.getItem('email')})
    }
  }
  closeMessage(){
    this.setState({success_msg: ''})
  }
  c1(e) {
    //let usernameValid = e.target.value ? true : false;
    this.setState({code1: e.target.value,
      //usernameValid: usernameValid,
    })
  }
  c2(e) {
    this.setState({code2: e.target.value})
  }
  c3(e) {
    this.setState({code3: e.target.value})
  }
  c4(e) {
    this.setState({code4: e.target.value})
  }
  c5(e) {
    this.setState({code5: e.target.value})
  }
  c6(e) {
    this.setState({submitDisabled: true})
    this.setState({code6: e.target.value})
  }
	pasteCode(e){
	    let paste = (e.clipboardData || window.clipboardData).getData('text');
	    let paste_array=Array.from(paste);
		this.setState({code1: paste_array[0]});
		this.setState({code2: paste_array[1]});
		this.setState({code3: paste_array[2]});
		this.setState({code4: paste_array[3]});
		this.setState({code5: paste_array[4]});
		this.setState({code6: paste_array[5]});
		this.setState({submitDisabled: true});
	}
  timer(){
    if(this.state.time>1){
      let i=parseInt(this.state.time)
      i=i-1;
      this.setState(state => ({time:i}));
    }else{
      this.setState({submitDisabled: false,showTimer:false})
    }
  }
  async verify(){
    if(this.state.code1==='' || this.state.code2==='' || this.state.code3==='' || this.state.code4==='' || this.state.code5==='' || this.state.code6===''){
      this.setState(state => ({error_msg: 'Please enter the code sent to your entered email.'}));
    }else{
      this.setState(state => ({submitDisabled: true,error_msg:''}));
      var data = JSON.stringify({email: this.state.email,code: parseInt(this.state.code1+this.state.code2+this.state.code3+this.state.code4+this.state.code5+this.state.code6)});
      var result1=await this.makeRequest('auth/verify-email', 'post', data,'');
      if(result1.status===200){
        this.setState({
          success_msg:result1.data.message+'. Please login to continue.',submitDisabled:false
        })
        this.props.history.push('/home#email_verified');
        setTimeout(
          function() {
            window.scrollTo(0, 0)
            document.getElementById("usernamme").focus();
          },1000
        );
      }else if(result1.status===400){
       this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
      }
    }
  }
  async resendCode(){
    var data = JSON.stringify({email: localStorage.getItem('email')});
    var result1=await this.makeRequest('auth/resend-code', 'post', data,'');
    this.setState({
      success_msg:result1.data.message
    })
  }
  sendTotop(){
    window.scrollTo(0, 0)
    document.getElementById("usernamme").focus();
  }

  render() {
    return (
        <div>
          <Navbar prop={this.props}/>
          <div style={{background:'#000C16',color:'white'}}>
            <br/><br/><br/>
            <div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-4 col-lg-5 col-md-7 text-center">
                    <h1 className="text-white text-center homeheadng">VERIFY YOUR EMAIL</h1>
                    <img alt="line" src={'assets/aboutus.png'}/><br/><br/>
                    <p style={{fontSize: '14px',color: '#a2a2a2',padding: '0 28px'}}>Please enter 6 digit code sent to <br/><span className="text-white">{localStorage.getItem('email')}</span></p>
                    {this.state.error_msg!=='' &&
                      <p style={{fontSize: '16px',color: 'red'}}>{this.state.error_msg}</p>
                    }
                    {this.state.success_msg!=='' &&
                      <p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>
                    }
                  </div>
                </div><br/>
                <div className="row justify-content-center form_div verify_div">
                  <div className="col-xl-5 col-lg-6 col-md-10 text-center">
                    <div>
                      <input className="form-control" maxLength="1" onChange={this.c1} onKeyUp={this.handleKeyUp} onPaste={this.pasteCode} value={this.state.code1}/>
                      <input className="form-control" maxLength="1" onChange={this.c2} onKeyUp={this.handleKeyUp} value={this.state.code2}/>
                      <input className="form-control" maxLength="1" onChange={this.c3} onKeyUp={this.handleKeyUp} value={this.state.code3}/>
                      <input className="form-control" maxLength="1" onChange={this.c4} onKeyUp={this.handleKeyUp} value={this.state.code4}/>
                      <input className="form-control" maxLength="1" onChange={this.c5} onKeyUp={this.handleKeyUp} value={this.state.code5}/>
                      <input className="form-control" maxLength="1" onChange={this.c6} onKeyUp={this.handleKeyUp} value={this.state.code6}/>
                    </div>
                    <br/><br/>
                    <div>
                      {!this.state.showTimer &&
                        <span>
                          <span style={{color: '#a2a2a2',fontSize: '14px'}}>Didn't receive code? <span className="yellowcolor" style={{cursor:'pointer'}} onClick={this.resendCode}>Resend code</span></span><br/><br/>
                        </span>
                      }                      
                      {this.state.showTimer &&
                        <span style={{color: 'white',fontSize: '12px',opacity:'0.75'}}>Resend({this.state.time}s)</span>
                      }
                      <br/><br/>
                      {!this.state.submitDisabled ?
                      (<div style={{padding: '0 72px',paddingLeft: '53px'}}><button className="btn btn-primary w-100 signup_btn comn_btn" style={{fontSize: '14px',fontWeight: '600'}} disabled={this.state.submitDisabled}>VERIFY NOW</button></div>):

                      (<div style={{padding: '0 72px',paddingLeft: '53px'}}><button className="btn btn-primary w-100 signup_btn comn_btn" style={{fontSize: '14px',fontWeight: '600',border:"1px solid #ffffffb0"}} onClick={this.verify}>VERIFY NOW</button></div>)
                      }
                    </div>
                  </div>                  
                </div><br/><br/>
              </div>
            </div>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
          </div>
        </div>
    );
  }
}

export default VerifyEmail;