import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { makeRequest } from '../../helpers';
//import { Lock,Phone,CheckSquare,CaretDownFill,StopwatchFill } from 'react-bootstrap-icons';

class PersonalSettings extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.changeLine = this.changeLine.bind(this)
    this.changeAddress = this.changeAddress.bind(this)
    this.changeCountry = this.changeCountry.bind(this)
    this.changeDob = this.changeDob.bind(this)
    this.changeEmail = this.changeEmail.bind(this)
    this.changeFullname = this.changeFullname.bind(this)
    this.changePhone = this.changePhone.bind(this)
    this.changeRemark = this.changeRemark.bind(this)
    this.changeRole = this.changeRole.bind(this)
    this.changeState = this.changeState.bind(this)
    this.changeTelegram = this.changeTelegram.bind(this)
    this.changeWechat = this.changeWechat.bind(this)
    this.changeWhatsapp = this.changeWhatsapp.bind(this)
    this.changeZalo = this.changeZalo.bind(this)
    this.update = this.update.bind(this)


    this.state = {
      userInfo:[],
      address:'',
      country:'',
      dob:'',
      email:'',
      fullname:'',
      phone:'',
      remark:'',
      role:'',
      state:'',
      telegram:'',
      wechat:'',
      whatsapp:'',
      zalo:'',
      line:'',
      submitDisabled:false,
      error_msg:'',
      success_msg:'',
    }
  }
  changeLine(e){
    this.setState(state => ({line: e.target.value}));
  }
  changeAddress(e){
    this.setState(state => ({address: e.target.value}));
  }
  changeCountry(e){
    this.setState(state => ({country: e.target.value}));
  }
  changeDob(e){
    this.setState(state => ({dob: e.target.value}));
  }
  changeEmail(e){
    this.setState(state => ({email: e.target.value}));
  }
  changeFullname(e){
    this.setState(state => ({fullname: e.target.value}));
  }
  changePhone(e){
    this.setState(state => ({phone: e.target.value}));
  }
  changeRemark(e){
    this.setState(state => ({remark: e.target.value}));
  }
  changeRole(e){
    this.setState(state => ({role: e.target.value}));
  }
  changeState(e){
    this.setState(state => ({state: e.target.value}));
  }
  changeTelegram(e){
    this.setState(state => ({telegram: e.target.value}));
  }
  changeWechat(e){
    this.setState(state => ({wechat: e.target.value}));
  }
  changeWhatsapp(e){
    this.setState(state => ({whatsapp: e.target.value}));
  }
  changeZalo(e){
    this.setState(state => ({zalo: e.target.value}));
  }
  async update(){
    if(this.state.fullname===''){
      this.setState(state => ({error_msg:'Enter fullname.'}));
    }else if(this.state.phone===''){
      this.setState(state => ({error_msg:'Enter phone.'}));
    }else if(this.state.state===''){
      this.setState(state => ({error_msg:'Enter state.'}));
    }else if(this.state.country===''){
      this.setState(state => ({error_msg:'Enter country.'}));
    }else if(this.state.dob===''){
      this.setState(state => ({error_msg:'Enter D.O.B.'}));
    }else if(this.state.wechat===''){
      this.setState(state => ({error_msg:'Enter wechat.'}));
    }else if(this.state.telegram===''){
      this.setState(state => ({error_msg:'Enter telegram.'}));
    }else if(this.state.zalo===''){
      this.setState(state => ({error_msg:'Enter zalo.'}));
    }else if(this.state.whatsapp===''){
      this.setState(state => ({error_msg:'Enter whatsapp.'}));
    }else if(this.state.line===''){
      this.setState(state => ({error_msg:'Enter line.'}));
    }else if(this.state.address===''){
      this.setState(state => ({error_msg:'Enter address.'}));
    }else{
      this.setState(state => ({submitDisabled:true}));
      let data = JSON.stringify({fullname: this.state.fullname,dateOfBirth: this.state.dob,phone: this.state.phone,whatsapp: this.state.whatsapp,wechat: this.state.wechat,line: this.state.line,telegram: this.state.telegram,zalo: this.state.zalo,address: this.state.address,state: this.state.state,remark: this.state.remark,country: this.state.country});
      var result1=await this.makeRequest('manager/me', 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg:result1.data.message,error_msg:'',submitDisabled:false
        })
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false}));
    }
  }
  componentDidMount() {
    (async () => {
		  var result1=await this.makeRequest('manager/me', 'get','',localStorage.getItem('role_token'));
		  if(result1.status===200){
        if(result1.data.user!==null){
			    this.setState({userInfo: result1.data.user})
        }
        if(result1.data.user.fullname!==null){
          this.setState({fullname: result1.data.user.fullname})
        }
        if(result1.data.user.dateOfBirth!==null){
          this.setState({dob: result1.data.user.dateOfBirth})
        }
        if(result1.data.user.phone!==null){
          this.setState({phone: result1.data.user.phone})
        }
        if(result1.data.user.whatsapp){
          this.setState({whatsapp: result1.data.user.whatsapp})
        }
        if(result1.data.user.wechat!==null){
          this.setState({wechat: result1.data.user.wechat})
        }
        if(result1.data.user.line!==null){
          this.setState({line: result1.data.user.line})
        }
        if(result1.data.user.telegram!==null){
          this.setState({telegram: result1.data.user.telegram})
        }
        if(result1.data.user.zalo!==null){
          this.setState({zalo: result1.data.user.zalo})
        }
        if(result1.data.user.address!==null){
          this.setState({address: result1.data.user.address})
        }
        if(result1.data.user.state!==null){
          this.setState({state: result1.data.user.state})
        }
        if(result1.data.user.country!==null){
          this.setState({country: result1.data.user.country})
        }
        if(result1.data.user.remark!==null){
          this.setState({remark: result1.data.user.remark})
        }
       
		  }
		})();
		
	} 
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>Personal Settings</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Personal Settings</h5>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              {this.state.success_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>):('')
              }
              {this.state.error_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.error_msg}</p>):('')
              }
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-5">
                    <div className="add_form_div persnl_setngs_div">
                      <span>Username: </span>
                      <span>{this.state.userInfo.username}</span>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>Full Name：</span>
                      <input type="text" className="form-control" onChange={this.changeFullname} value={this.state.fullname}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>Phone Number：</span>
                      <input type="text" className="form-control" onChange={this.changePhone} value={this.state.phone}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>Country：</span>
                      <input  type="text" className="form-control" onChange={this.changeCountry} value={this.state.country}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>Telegram:</span>
                      <input type="text" className="form-control" onChange={this.changeTelegram} value={this.state.telegram}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>Whatsapp:</span>
                      <input type="text" className="form-control" onChange={this.changeWhatsapp} value={this.state.whatsapp}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>Address: </span>
                      <textarea rows="3" className="form-control"  onChange={this.changeAddress} value={this.state.address}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <button className="btn btn-primary add_btn" onClick={this.update} disabled={this.state.submitDisabled}>Update</button>
                      <button className="btn btn-primary add_btn cancel_btn">Cancel</button>
                    </div><br/>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5">
                    <div className="add_form_div persnl_setngs_div" style={{marginBottom:"24px"}}></div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>State：</span>
                      <input type="text" className="form-control" onChange={this.changeState} value={this.state.state}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>D.O.B：</span>
                      <input type="date" className="form-control"  onChange={this.changeDob} value={this.state.dob}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>Wechat:</span>
                      <input type="text" className="form-control" onChange={this.changeWechat} value={this.state.wechat}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>Zalo:</span>
                      <input type="text" className="form-control"  onChange={this.changeZalo} value={this.state.zalo}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>Line:</span>
                      <input type="text" className="form-control" onChange={this.changeLine} value={this.state.line}/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div">
                      <span>Remark: </span>
                      <textarea rows="3" className="form-control" onChange={this.changeRemark} value={this.state.remark}></textarea>
                    </div><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PersonalSettings;
