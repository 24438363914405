import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { makeRequest,makeProviderRequest,gameList } from '../../helpers'
//import { Lock,Phone,CheckSquare,CaretDownFill,StopwatchFill } from 'react-bootstrap-icons';

class EditPlatformOwner extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		this.makeRequest = makeRequest
		this.gameList = gameList
		this.makeProviderRequest = makeProviderRequest
		this.update = this.update.bind(this)
		this.changeFullName = this.changeFullName.bind(this)
		this.changePhone = this.changePhone.bind(this)
		this.changeAddress = this.changeAddress.bind(this)
		this.changeRemark = this.changeRemark.bind(this)
		this.updateGame = this.updateGame.bind(this)
	  }
	  state= {
		fullname:'',
		phone:'',
		address:'',
		remark:'',
		status:'',
		permission:'',
		submitDisabled:false,
		error_msg:'',
		success_msg:'',
		error_msg1:'',
		success_msg1:'',
		getOwnerDetail:[],
		gameData:[],
		checkGames: [],
		owner_id:'',
	  }
	  changeFullName(e) {
		let fullnameValid = e.target.value ? true : false;
		this.setState({
		  fullname: (e.target.value).toLowerCase(),
		  fullnameValid: fullnameValid,
		})
	  }
	  changePhone(e) {
		let phoneValid = e.target.value ? true : false;
		this.setState({
		  phone: (e.target.value).toLowerCase(),
		  phoneValid: phoneValid,
		})
	  }
	  changeAddress(e) {
		let addressValid = e.target.value ? true : false;
		this.setState({
		  address: (e.target.value).toLowerCase(),
		  addressValid: addressValid,
		})
	  }
	  changeRemark(e) {
		let remarkValid = e.target.value ? true : false;
		this.setState({
		  remark: (e.target.value).toLowerCase(),
		  remarkValid: remarkValid,
		})
	  }
	  async updateGame(e){
		var id=e.target.getAttribute("attr");
		let data = JSON.stringify({gameId: id,status: e.target.checked});
		var result1=await this.makeProviderRequest('owner/'+this.state.owner_id+'/game', 'put', data,localStorage.getItem('role_token'));
		if(result1.status===200){
		  this.setState({
			success_msg1:result1.data.message,error_msg1:''
		  })
		}
		else if(result1.status===400){
		  this.setState(state => ({error_msg1:result1.data.message}));
		}
		else if(result1.status===500)
		  this.setState(state => ({error_msg1:'Some problem occured. Please try again.'}));
	 }
	  async update(){ 
		if(this.state.fullname===''){
		  this.setState(state => ({error_msg:'Full name should not be empty!!'}));
		}else if(this.state.phone===''){
		  this.setState(state => ({error_msg:'Phone number should not be empty!!'}));
		}else{
		  this.setState(state => ({submitDisabled:true}));
		  //let data = JSON.stringify({game: this.state.checkGames,fullname: this.state.fullname,dateOfBirth: this.state.dob,phone: this.state.phone,whatsapp: this.state.whatsapp,wechat: this.state.wechat,line: this.state.line,telegram: this.state.telegram,zalo: this.state.zalo,address: this.state.address,state: this.state.state,remark: this.state.remark,country: this.state.country,status: this.state.status,permission: this.state.permission});
		  let data = JSON.stringify({fullname: this.state.fullname,phone: this.state.phone,address: this.state.address,remark: this.state.remark});
		  var result1=await this.makeProviderRequest('owner/'+this.state.owner_id, 'put', data,localStorage.getItem('role_token'));
		  if(result1.status===200){
			this.setState({
			  success_msg:result1.data.message,error_msg:'',submitDisabled:false
			})
			this.gameList(this);
		  }
		  else if(result1.status===400){
			this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
		  }
		  else if(result1.status===500)
			this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false}));
		}
	  }
	  componentDidMount() {
		this.gameList(this);
		const owner_id=window.location.hash.substr(1);
		 this.setState(state => ({owner_id:owner_id}));
		(async () => {
		  var result1=await this.makeProviderRequest('owner/'+owner_id, 'get','',localStorage.getItem('role_token'));
		  if(result1.status===200){
			  this.setState({checkGames: result1.data.gameControl});
			  if(result1.data.fullname!==null){
				this.setState({fullname: result1.data.fullname});
			  }
			  if(result1.data.phone!==null){
				this.setState({phone: result1.data.phone});
			  }
			  if(result1.data.address!==null){
				this.setState({address: result1.data.address});
			  }
			  if(result1.data.remark!==null){
				this.setState({remark: result1.data.remark});
			  }
			  if(result1.data.status!==null){
				this.setState({status: result1.data.status});
			  }
			  if(result1.data.permission!==null){
				this.setState({permission: result1.data.permission});
			  }
			 this.setState({
			  getOwnerDetail: result1.data
			})
		  }
		})();
		
	} 
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Platform Owners</span> / 
                    <span>Platform owner detail</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Edit user info</h5>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              <div className="subbdvv1 add_owner_subdiv" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:700,margin:0}}>Platform owner profile
                  <div className="add_owner_btns" style={{float:'right',marginTop:'-6px'}}>
                    <button className="btn btn-primary add_btn" onClick={this.update} disabled={this.state.submitDisabled} >Save</button>
                    <button className="btn btn-primary add_btn cancel_btn" onClick={()=>this.props.history.push('/admin/platform_owners')}>Cancel</button>
                  </div>
                </h6>
              </div>
			  {this.state.success_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg}</p>):('')
              }
              {this.state.error_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg}</p>):('')
              }
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white',width:"100%"}}>
                <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12">
                    <div className="add_form_div edit_form_div">
                      <span>Full Name：</span>
                      <input type="text" className="form-control" onChange={this.changeFullName} value={this.state.fullname}/>
                    </div><br/>
                    <div className="add_form_div edit_form_div">
                      <span>Phone Number：</span>
                      <input type="text" className="form-control" onChange={this.changePhone} value={this.state.phone}/>
                    </div><br/>
                   {/*<div className="add_form_div edit_form_div">
                      <span>Telegram：</span>
                      <input type="text" className="form-control" onChange={this.changeTelegram} value={this.state.telegram}/>
                    </div><br/>
					<div className="add_form_div edit_form_div">
                      <span>Zalo：</span>
                      <input type="text" className="form-control" onChange={this.changeZalo} value={this.state.zalo}/>
					</div><br/>*/}
					{/*<div className="add_form_div edit_form_div">
                      <span>State：</span>
                      <input type="text" className="form-control" onChange={this.changeState} value={this.state.state}/>
					</div><br/>	*/}				 
					<div className="add_form_div edit_form_div">
						<span>Address: </span>
						<textarea style={{height: '144px'}} className="form-control" onChange={this.changeAddress} value={this.state.address}/>
					</div><br/>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12">
					{/*<div className="add_form_div edit_form_div">
                      <span>D.O.B：</span>
                      <input type="date" className="form-control" onChange={this.changeDob} value={this.state.dob}/>
                    </div><br/>
					<div className="add_form_div edit_form_div">
                      <span>Whatsapp</span>
                      <input type="text" className="form-control" onChange={this.changeWhatsapp} value={this.state.whatsapp}/>
                    </div><br/>
					<div className="add_form_div edit_form_div">
                      <span>Wechat：</span>
                      <input type="text" className="form-control" onChange={this.changeWechat} value={this.state.wechat}/>
                    </div><br/>
					<div className="add_form_div edit_form_div">
                      <span>Line：</span>
                      <input type="text" className="form-control" onChange={this.changeLine} value={this.state.line}/>
                    </div><br/>
					<div className="add_form_div edit_form_div">
                      <span>Country：</span>
                      <input type="text" className="form-control" onChange={this.changeCountry} value={this.state.country}/>
					</div><br/>*/}
					<div className="add_form_div edit_form_div">
                      <span>Permission：</span>
                      <input type="text" className="form-control" readOnly defaultValue={this.state.permission}/>
                    </div><br/>
					<div className="add_form_div edit_form_div">
                      <span>Status：</span>
                      <input type="text" className="form-control" readOnly defaultValue={this.state.status}/>
                    </div><br/>
                  <div className="add_form_div edit_form_div">
                    <span>Remark：</span>
                    <textarea style={{height: '144px'}} className="form-control"  onChange={this.changeRemark}  value={this.state.remark}/>
                  </div><br/>
                </div>
				<div className="col-md-12">
					{/* Game provider control */}
					  <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
						<h6 style={{fontWeight:700,margin:0}}>Game provider control</h6>
						{this.state.success_msg1!=='' ?
						(<p style={{fontSize: '16px',color: 'green',marginBottom:"0"}}>{this.state.success_msg1}</p>):('')
						}
						{this.state.error_msg1!=='' ?
						(<p style={{fontSize: '16px',color: 'red',marginBottom:"0"}}>{this.state.error_msg1}</p>):('')
						}
					  </div>
					  <div className="row" style={{margin:0,background:'white',padding: '20px 12px 0 12px'}}>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div3 game_padding">
							<ul className="comm_div" style={{marginBottom:"0"}}>
								<li className="li_head">Game Name <span>No/Yes</span></li>
							</ul>
							<ul className="comm_div" ref={this.myRef}>
								{this.state.checkGames.map(data => (
									<li className="game_provider_checkbox" key={data.id}>{data.name} 
									<span>{data.status}
									<label className="switch">
									<input type="checkbox" defaultValue={data.name} onClick={this.updateGame} defaultChecked={data.status ? true : false} attr={data.id}/>
										<span className="slider round"></span>
									</label>
									</span>
									</li>
								))}
							</ul>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
						  
						</div>
					  </div><br/>
					  {/* Game provider control */}
				</div>
				
              </div>
            </div>
			
			
			
			
          </div>
        </div>
      </div>
    );
  }
}

export default EditPlatformOwner;
