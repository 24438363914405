import React from "react";
import {Link} from 'react-router-dom';
import { Lock,Phone,CheckSquare,CaretDownFill,StopwatchFill,PersonFill } from 'react-bootstrap-icons';
import { makeRequest,makeProviderRequest } from '../../helpers'

class OwnerLogin extends React.Component {
  constructor(props) {
    super(props);
    this.showPop = this.showPop.bind(this)
    this.makeRequest = makeRequest
    this.makeProviderRequest = makeProviderRequest
    this.login = this.login.bind(this)
    this.changeUsername = this.changeUsername.bind(this)
    this.changePwd = this.changePwd.bind(this)
    this.changeCapchaaa = this.changeCapchaaa.bind(this)
    this.enterPressed = this.enterPressed.bind(this)
    this.reloadCaptcha = this.reloadCaptcha.bind(this)
  }
  state= {
    popStatus:false,    
    username:'',
    pwd:'',
    submitDisabled:false,
    error_msg:'',
    success_msg:'',
    IP:'',
    capchaaa:'',
    capchaaaText:'STOA'
  }
  showPop(val1){
    this.setState(state => ({popStatus: val1}));
  }
  changeUsername(e) {
    let usernameValid = e.target.value ? true : false;
    this.setState({
      username: (e.target.value).toLowerCase(),
      usernameValid: usernameValid,
    })
  }
  enterPressed(event){
    var code = event.keyCode || event.which;
		if(code === 13) { //13 is the enter keycode
			this.login();
		}
  }
  reloadCaptcha(){
    var result= '';
    var characters= 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersLength = characters.length;
    for ( var i = 0; i < 4; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.setState(state => ({capchaaaText:result}));
  }
  changePwd(e) {
    let pwdValid = e.target.value ? true : false;
    this.setState({
      pwd: e.target.value,
      pwdValid: pwdValid,
    })
  }
  changeCapchaaa(e) {
    this.setState({
      capchaaa: e.target.value,
    })
  }
  async login(){
    if(this.state.username===''){
      this.setState(state => ({error_msg:'Enter username.'}));
    }else if(this.state.pwd===''){
      this.setState(state => ({error_msg:'Enter password.'}));
    }else if(this.state.capchaaa===''){
      this.setState(state => ({error_msg:'Enter verification code.'}));
    }else if(this.state.capchaaa!==this.state.capchaaaText){
      this.setState(state => ({error_msg:'Wrong verification code.'}));
    }else{
      this.setState(state => ({submitDisabled:true}));
      this.setState(state => ({error_msg:''}));
		let data = JSON.stringify({username: this.state.username,password: this.state.pwd,ip:this.state.IP});
		let result1=await this.makeRequest('manager/auth/login', 'post', data,'');
      if(result1.status===200){
        this.setState({
          success_msg:result1.data.message,error_msg:'',submitDisabled:false
        })
        localStorage.setItem('role_token',result1.data.token);
        localStorage.setItem('role','owner')
        this.props.history.push('/admin/dashboard');
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false}));
    }
  }
  componentDidMount(){
    (async () => {
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        this.setState(state => ({IP: data.IPv4}));
    })();
  }
  render() {
    return (
        <div style={{background:'#f4f7ff',height:"100vh"}}>
            <div className="container" style={{height:'100%'}}>
                <div className="row justify-content-center" style={{height:'100%'}}>
                    <div className="col-md-10" style={{margin:'auto',background:'white',borderRadius:'16px'}}>
                        <div className="row" style={{boxShadow:'0 40px 60px 0 rgb(56 122 254 / 8%)'}}>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-xs-3 bckgrundd" style={{backgroundImage:'url(../assets/login_map.png)'}}>
                                <div className="login_welcome_text">
                                    <h3 style={{textAlign:"center"}}>Welcome to owner platform</h3>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-xs-9">
                              <form onKeyPress={this.enterPressed}>
                                <div className="row justify-content-center" style={{padding:'82px 0'}}>
                                    <div className="col-xl-7 col-lg-10 col-md-12 col-sm-12 col-xs-12">
                                        <div className="logindivv">
                                            <h6>Platform owner Login</h6>
                                            {this.state.success_msg!=='' ?
                                                (<p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>):('')
                                            }
                                            {this.state.error_msg!=='' ?
                                                (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.error_msg}</p>):('')
                                            }
                                            <div className="frmdv">
                                                <Phone color="#979797" size={16} />
                                                <input type="text" className="form-control" placeholder="Username" onChange={this.changeUsername}/>
                                            </div><br/>
                                            <div className="frmdv">
                                                <Lock color="#979797" size={16} />
                                                <input type="password" className="form-control" placeholder="Password" onChange={this.changePwd}/>
                                            </div><br/>
                                            <div>
                                                <div className="frmdv" style={{display:'inline-block'}}>
                                                    <CheckSquare color="#979797" size={15} />
                                                    <input type="text" className="form-control" placeholder="Verification Code" onChange={this.changeCapchaaa}/>
                                                </div>
                                                <span className="capchaaa">{this.state.capchaaaText}</span>
                                                <p className="reload_captcha" onClick={this.reloadCaptcha}>Change verification code</p>
                                            </div>
                                            <br/>
                                            <div>
                                                <Link href="" className="btn btn-primary w-100" onClick={this.login} disabled={this.state.submitDisabled}>Login</Link>
                                            </div><br/>
                                            <hr/><br/>
                                            <div className="text-center langss">
                                                <h6>Languages</h6>
                                                <div style={{position:'relative'}}>
                                                    <img alt="th" style={{opacity:0.5}} onClick={()=>this.showPop('th')} src={'../assets/th.png'}/><br/>
                                                    <span style={{opacity:0.5}}>THAI</span>
                                                    {this.state.popStatus==='th' &&
                                                        <div className="popp">
                                                            <div className="sdv1">
                                                                <StopwatchFill color="#FAAD15" size={12} /> 
                                                                <span>Coming Soon</span>
                                                            </div>
                                                            <hr/>
                                                            <div className="sdv2">
                                                            <img alt="th" src={'../assets/th.png'}/> 
                                                            <span>THAI</span>
                                                            <span onClick={()=>this.showPop(false)}>OK</span>
                                                            <CaretDownFill color="white" size={20}/>
                                                            </div>
                                                        </div>  
                                                    }
                                                </div>
                                                <div>
                                                    <img alt="gb" src={'../assets/gb.png'}/><br/>
                                                    <span>EN</span>
                                                </div>
                                                <div style={{position:'relative'}}>
                                                    <img alt="cn" style={{opacity:0.5}} src={'../assets/cn.png'} onClick={()=>this.showPop('cn')}/><br/>
                                                    <span style={{opacity:0.5}}>CN</span>
                                                    {this.state.popStatus==='cn' &&
                                                        <div className="popp">
                                                            <div className="sdv1">
                                                                <StopwatchFill color="#FAAD15" size={12} /> 
                                                                <span>Coming Soon</span>
                                                            </div>
                                                            <hr/>
                                                            <div className="sdv2">
                                                            <img alt="cn" src={'../assets/cn.png'}/> 
                                                            <span>CHINA</span>
                                                            <span onClick={()=>this.showPop(false)}>OK</span>
                                                            <CaretDownFill color="white" size={20}/>
                                                            </div>
                                                        </div>  
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default OwnerLogin;
