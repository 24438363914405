import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
//import { Lock,Phone,CheckSquare,CaretDownFill,StopwatchFill } from 'react-bootstrap-icons';
import { makeRequest,timestampDate1,timestampTime } from '../../helpers';

class SendHistory extends React.Component {
	constructor(props) {
    super(props);
	this.makeRequest = makeRequest
    this.getDetail = this.getDetail.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.changeKeyword = this.changeKeyword.bind(this)
    this.search = this.search.bind(this)
    this.clearSearchData = this.clearSearchData.bind(this)
  }
  state={
	Msgs:[],
	details:[],
  submitDisable:false,
  date:'',
  keyword:'',
  activeBox:'',
  }
	async getDetail(id){
    this.setState({activeBox: id});
    this.setState({submitDisable: true});
		var result1=await this.makeRequest('owner/mailbox/'+id, 'get', '',localStorage.getItem('role_token'));
		if(result1.status===200){
      this.setState({submitDisable: false});
			this.setState({details: result1.data});
		}
	}
  changeDate(e) {
    this.setState({
      date: e.target.value,
    })
  }
  changeKeyword(e) {
    this.setState({
      keyword: e.target.value,
    })
  }
  clearSearchData(){
    this.setState({
      keyword: '',
    })
    this.setState({
      date: '',
    })
    this.componentDidMount();
  }
  async search(){
    var result1;
      if((this.state.date!=='')){
        result1=await this.makeRequest('owner/mailbox?startDate='+this.state.date, 'get','',localStorage.getItem('role_token'));
      }
      // else if((this.state.date==='') && (this.state.keyword!=='')){
      //   result1=await this.makeRequest('owner/mailbox?keyword='+this.state.keyword, 'get','',localStorage.getItem('role_token'));
      // }
      // else if((this.state.date!=='') && (this.state.keyword!=='')){
      //   result1=await this.makeRequest('owner/mailbox?startDate='+this.state.date+'&keyword='+this.state.type, 'keyword','',localStorage.getItem('role_token'));
      // }
      if(result1.status===200){
        this.setState(state => ({channels:result1.data.data}));
      }
  }
	componentDidMount() {
		(async () => {
		  var result1=await this.makeRequest('owner/mailbox', 'get','',localStorage.getItem('role_token'));
		  if(result1.status===200){
			this.setState({Msgs: result1.data.data});
			this.setState({details: result1.data.data[0]});
			this.setState({activeBox: result1.data.data[0].id});
		  }
		})();
		
	} 
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Message center</span> / 
                    <span>Send history</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Send history</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              <div className="subbdvv1">
                <div className="subdvv1_1">
                  {/*<span style={{fontSize:'15px'}}>Search: </span>
                  <div className="subdvv1_1_1">
                    <input type="text" className="form-control" placeholder="Search" onChange={this.changeKeyword} value={this.state.keyword}/>
                  </div>*/}
                  <span style={{fontSize:'15px'}}>Date Range: </span>
                  <input type="date" className="form-control" onChange={this.changeDate}  value={this.state.date}/>
                  <button className="btn btn-primary filter_btn text-white" style={{background: '#1890FF'}}onClick={this.search}>Search</button>
                  <button className="btn btn-primary add_btn cancel_btn" onClick={this.clearSearchData}>Reset</button>
                </div>
              </div><br/>
              <div className="outrr">
                  <div className="senders_list">
				  
                   {this.state.Msgs.map(msg_data => (
                     <div key={msg_data.id} className="sender_list_box">
                      <div className="sdiv1" style={{position:"relative",cursor:"pointer",display:"inline-flex",padding:"6px",width:"100%"}} onClick={()=>this.getDetail(msg_data.id)}>
                        <img alt="msg1" src={'../assets/msg1.png'}/>
                        <div className={this.state.activeBox===msg_data.id ? 'active_msg_box' : ''}>
                          <h6 style={msg_data.isRead ? {fontWeight:600} : {fontWeight:800}}>{msg_data.subject}</h6>
                          <span className="send_history_date" style={{position:"absolute",top:"0px",right:"0"}}>
                            <div>{timestampTime(msg_data.createdAt)}</div>
                          </span>
                          <span>{msg_data.detail}</span>
                        </div>
                      </div><hr></hr></div>
                      
                    ))}
				   
                  </div>
                  <div className="message_detail" style={this.state.submitDisable ? "opacity:'0.4'" : "opacity:'1'"} style={ this.state.submitDisable ? { opacity:'0.4'} : {opacity:'1'}}>
                    {this.state.submitDisable ?
                    (<div className="spinner-border" role="status" style={{position:"absolute",top:"30%",left:"46%"}}>
                      <span className="sr-only">Loading...</span>
                    </div>):('')}
                    <div className="sdiv1">
                        <img alt="msg1" src={'../assets/msg1.png'}/>
                        <div>
                            <h5 style={{fontSize:"16px",marginBottom:"0",fontWeight:"700"}}>{this.state.details.subject}</h5>
                            <span className="sp1"><b>From:</b> abominus</span>
                            <span className="sp2"><b>To:</b> demo123</span><br/>
                            <span className="sp3">{timestampDate1(this.state.details.createdAt)}</span>
                        </div>
                        <p>
						            {this.state.details.detail}
                        </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SendHistory;
