import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import { makeRequest } from '../helpers'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.changeEmail = this.changeEmail.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
    this.timer = this.timer.bind(this)
    this.sendTotop = this.sendTotop.bind(this)
    this.closeMessage = this.closeMessage.bind(this)
    this.makeRequest = makeRequest
  }
  state= {
    email:'',
    success_msg:'',
    success_msg2:'',
    error_msg:'',
    submitDisabled:false,
    showTimer:false,
    submitDisabled2:true,
    time:60,
    forgotDiv:true,
    resetDiv:false,
  }

  componentDidMount() {
    // if (window.location.href.indexOf("forgot_password") > -1) {
    //   console.log('forgot');
    // }else if(window.location.href.indexOf("reset-password") > -1){
    //   console.log('reset');
    // }
    // if(window.location.hash.substr(1)===''){
    //   this.setState(state => ({resetDiv:false}));
    //   this.setState(state => ({forgotDiv:true}));
    // }else{
    //   this.setState(state => ({forgotDiv:false}));
    //   this.setState(state => ({resetDiv:true}));
    // }
    //window.addEventListener("resize", this.forceUpdate.bind(this))
  }
  // componentDidUpdate() {
  //   console.log('status1:',this.state.pCodeValid,'status2:',this.state.pwdValid,'status3:',this.state.newpwdValid)    
  // }
  timer(){
    if(this.state.time>1){
      let i=parseInt(this.state.time)
      i=i-1;
      this.setState(state => ({time:i}));
    }else{
      this.setState({submitDisabled: false,showTimer:false})
    }
  }
  closeMessage(){
    this.setState({success_msg2: ''})
  }
  changeEmail(e) {
    let emailValid = e.target.value ? true : false;
    this.setState({
      email: e.target.value,
      emailValid: emailValid,
    })
  }
  async resetPassword(){
    if(this.state.email===''){
      this.setState(state => ({error_msg: 'Enter email address to receive the code'}));
    }else if((this.state.email).indexOf("@") === -1){
      this.setState(state => ({error_msg:'Invalid email address.'}));
    }else{
      if(this.state.emailValid){
        this.setState(state => ({submitDisabled: true,error_msg:''}));
      }
      var data = JSON.stringify({email: this.state.email});
      var result1=await this.makeRequest('auth/reset-password', 'post', data,'');
      if(result1.status===200){
        this.setState({
          success_msg:result1.data.message,showTimer:true
        })
        let intervalId="";
        if(this.state.time>1){
          intervalId = setInterval(this.timer, 1000);
        }else{
          clearInterval(intervalId)
          this.setState({submitDisabled: false})
        }
        // setTimeout(
        //   function() {
        //     this.setState({submitDisabled: false})
        //   }.bind(this),60000
        // );
      }else if(result1.status===400){
       this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
      }
    }
    
  }
  sendTotop(){
    window.scrollTo(0, 0)
    document.getElementById("usernamme").focus();
  }

  render() {
    return (
      <div>
        <Navbar prop={this.props}/>
        <div style={{background:'#000C16',color:'white',paddingTop: '140px'}} className="cockfightngdiv">
          <div>
            <div className="container">
              <div className="row justify-content-center">
                {this.state.forgotDiv ?
                (<div className="col-xl-4 col-lg-5 col-md-7 text-center">
                  <h1 className="text-white text-center homeheadng">FORGOT PASSWORD</h1>
                  <img alt="line" src={'assets/aboutus.png'}/><br/><br/>
                  <p style={{fontSize: '14px',color: 'white',padding: '0 28px',opacity:'0.75'}}>Don't worry! Resetting your password is easy. Just type in the email you registered to vigobet.</p>
                </div>) :
                (
                  <div className="col-xl-4 col-lg-5 col-md-7 text-center">
                    <h1 className="text-white text-center homeheadng">Reset PASSWORD</h1>
                    <img alt="line" src={'assets/aboutus.png'}/><br/><br/>
                  </div>
                )
                }
              </div><br/>
              <div className="row justify-content-center form_div" style={{position:"relative"}}>
                <div className="col-lg-4 col-md-6">
                  {this.state.success_msg!=='' &&
                    <p className="text-center" style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>
                  }
                  
                  {this.state.error_msg!=='' &&
                    <p className="text-center" style={{fontSize: '16px',color: '#ff2424'}}>{this.state.error_msg}</p>
                  }
                  {this.state.forgotDiv &&
                  <div className="row">
                      <div className="col-md-12" style={{position:'relative'}}>
                        {this.state.email!=='' &&
                          <span className="formfilled_txt">Email address</span>
                        }
                        <input className="form-control" type="email" placeholder="Enter your email address" onChange={this.changeEmail}/><br/>
                      </div>
                      <div className="col-md-12 text-center">
                        {this.state.showTimer &&
                          <span style={{color: 'white',fontSize: '12px',opacity:'0.75'}}>Resend({this.state.time}s)</span>
                        }
                        <div><button className="btn btn-primary w-100 login_btn comn_btn" disabled={this.state.submitDisabled} onClick={this.resetPassword}>SEND RESET PASSWORD CODE</button></div>
                        <br/>
                      </div>
                    </div>}
                    {this.state.resetDiv &&
                    (<div className="row">
                      <div className="col-md-12" style={{position:'relative'}}>
                        {this.state.code!=='' &&
                          <span className="formfilled_txt">Reset password code</span>
                        }
                        <input className="form-control" type="text" placeholder="Reset password code" onChange={this.changePcode}/><br/>
                      </div>
                      <div className="col-md-12" style={{position:'relative'}}>
                        {this.state.pwd!=='' &&
                          <span className="formfilled_txt">New Password</span>
                        }
                        <input className="form-control" type="password" placeholder="New Password" onChange={this.changeNewp}/><br/>
                      </div>
                      <div className="col-md-12" style={{position:'relative'}}>
                        {this.state.newpwd!=='' &&
                          <span className="formfilled_txt">Confirm Password</span>
                        }
                        <input className="form-control" type="password" placeholder="Re-enter password" onChange={this.changeRePwd}/><br/><br/>
                      </div>
                      <div className="col-md-12">
                        {!this.state.submitDisabled ?
                        (<div><button className="btn btn-primary w-100 signup_btn comn_btn" disabled={this.state.submitDisabled}>RESET PASSWORD</button></div>):

                        (<div><button className="btn btn-primary w-100 signup_btn comn_btn" style={{border:"1px solid #ffffffb0"}} onClick={this.updatePassword}>RESET PASSWORD</button></div>)
                        }
                        <br/><br/>
                      </div>
                      <div className="col-md-12 text-center" style={{fontSize:'14px'}}>
                          <span style={{color: 'white',opacity: 0.75}}>Do you remember your password ?</span><br/><br/>
                          <span className="yellowcolor">Try phone reset password</span>
                          <p style={{color:"black"}}>Cancel</p>
                      </div>
                  </div>)}

                </div>
              </div>
            </div>
          </div>

          <br/><br/><br/><br/><br/>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;