import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import Chart from "react-apexcharts";
import { makeRequest } from '../../helpers';
import { ArrowUpCircleFill,ArrowDownCircleFill,ThreeDots,CaretUpFill } from 'react-bootstrap-icons';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
	this.makeRequest = makeRequest
    this.popupDiv = this.popupDiv.bind(this)
    this.state = {
        showPopupDiv:false,
        total_players:'',
        popup:0,
        series: [{
            name: 'Network',
            data: [
                {x: 'Jan',y: 260},
                {x: 'Feb',y: 320},
                {x: 'Mar',y: 150},
                {x: 'Apr',y: 350},
                // {x: 'Jan 03 2018',y: null},
            ],
        }],
        options: {
            chart: {
                type: 'area',height: 350,animations: {enabled: false},zoom: {enabled: false},
            },
            colors:["#3B8BFF"],
            dataLabels: {enabled: false},
            stroke: {curve: 'smooth',width: 1.7},
            fill: {opacity: 0.8,},
            markers: {size: 5,hover: {size: 9}},
            // title: {text: 'Win/Lose Report',},
            tooltip: {intersect: true,shared: false},
            theme: {palette: 'palette1'},
            xaxis: {type: 'month',lines: {show: false},padding: {left: 0,right: 0},axisBorder: {show: false}},
            yaxis: {show: false},
            grid: {show: false,padding: {left: 10,right: 0}}
        },
    }
  }
  popupDiv(val,count){
    this.setState(state => ({showPopupDiv: val,popup:count}));
  }
    componentDidMount() {
        (async () => {
            var result1=await this.makeRequest('user/count', 'get','',localStorage.getItem('role_token'));
            if(result1.status===200){
                this.setState({total_players: result1.data.users});
            }
        })();
    } 
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4',position:"relative"}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>Dashboard</span>
                  </div>
                  <h5 className="pg_head">Dashboard</h5>
                </div>
              </div><br/>
            </div>
            
            <div>
              <div className="subbdvv1" style={{padding: '18px 10px'}}>
                <div className="row" style={{margin:0}}>
                    <div className="col-md-3 col-sm-6" style={{marginBottom:'40px'}}>
                        <div className="dash_fig_div">
                            <h2>13,000</h2>
                            <span>Kiosk Balance</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6" style={{marginBottom:'40px'}}>
                        <div className="dash_fig_div">
                            <h2>40 M $</h2>
                            <span>Total Deposit</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6" style={{marginBottom:'40px'}}>
                        <div className="dash_fig_div">
                            <h2>1 M $</h2>
                            <span>Today Withdrawal</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6" style={{marginBottom:'40px'}}>
                        <div className="dash_fig_div">
                            <h2>{this.state.total_players}</h2>
                            <span>Total Players</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6" style={{marginBottom:'40px'}}>
                        <div className="dash_fig_div">
                            <h2>10,234</h2>
                            <span>New Players (Today)</span>
                        </div>
                    </div>
                </div><br/><br/>

                {/* chart */}
                <div className="row" style={{margin:0}}>
                    <div className="col-md-4 col-sm-12 comn_mb">
                        <div className="chartdivv">
                            <h5>Win/Lose Report
                                <div className="dots" onClick={()=>this.popupDiv(!this.state.showPopupDiv,1)}><ThreeDots size="30" color="#9CA3BD"/>
                                    {(this.state.showPopupDiv && this.state.popup===1) &&
                                        <CaretUpFill className="togg" size="20" color="#e2e2e29c"/>
                                    }
                                    {(this.state.showPopupDiv && this.state.popup===1) &&
                                        <div className="dot_popup">
                                            <ul>
                                                <li>Daily</li>
                                                <li>Weekly</li>
                                                <li className="active">Monthly</li>
                                                <li>Yearly</li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </h5>
                            <span><ArrowUpCircleFill color="#8FD683" size="13"/> +32% Growth</span>
                            <Chart options={this.state.options} series={this.state.series} type="area" height="170" width="100%"/>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 comn_mb">
                        <div className="chartdivv">
                            <h5>Transaction Report
                                <div className="dots" onClick={()=>this.popupDiv(!this.state.showPopupDiv,2)}><ThreeDots size="30" color="#9CA3BD"/>
                                    {(this.state.showPopupDiv && this.state.popup===2) &&
                                        <CaretUpFill className="togg" size="20" color="#e2e2e29c"/>
                                    }
                                    {(this.state.showPopupDiv && this.state.popup===2) &&
                                        <div className="dot_popup">
                                            <ul>
                                                <li>Daily</li>
                                                <li>Weekly</li>
                                                <li className="active">Monthly</li>
                                                <li>Yearly</li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </h5>
                            <span><ArrowUpCircleFill color="#8FD683" size="13"/> +40% Growth</span>
                            <Chart options={this.state.options} series={this.state.series} type="area" height="170" width="100%"/>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 comn_mb">
                        <div className="chartdivv">
                            <h5>New Subscribers
                                <div className="dots" onClick={()=>this.popupDiv(!this.state.showPopupDiv,3)}><ThreeDots size="30" color="#9CA3BD"/>
                                    {(this.state.showPopupDiv && this.state.popup===3) &&
                                        <CaretUpFill className="togg" size="20" color="#e2e2e29c"/>
                                    }
                                    {(this.state.showPopupDiv && this.state.popup===3) &&
                                        <div className="dot_popup">
                                            <ul>
                                                <li>Daily</li>
                                                <li>Weekly</li>
                                                <li className="active">Monthly</li>
                                                <li>Yearly</li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </h5>
                            <span style={{color:'#F69791'}}><ArrowDownCircleFill color="#F69791" size="13"/> -20% Decline</span>
                            <Chart options={this.state.options} series={this.state.series} type="area" height="170" width="100%"/>
                        </div>
                    </div>
                </div>
                {/* chart */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
