import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import { makeRequest } from '../../helpers'
// import { Search,Dot,ChevronUp } from 'react-bootstrap-icons';

class TransactionRequest extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.currntOption = this.currntOption.bind(this)
    this.statusModal = this.statusModal.bind(this)
    this.changeAmount = this.changeAmount.bind(this)
    this.changeRemark = this.changeRemark.bind(this)
    this.update = this.update.bind(this)
  }
  state={
    status_type:'approve',
    showStatusModal:false,
    trans_id:'',
    trans_type:'',
    trans_status:'',
    amount:'',
    remark:'',
    submitDisabled:false,
    error_msg:'',
    success_msg:'',
    trans_currency:'',
    trans_date:'',
  }
  statusModal(val){
    this.setState({showStatusModal: val})
  }
  currntOption(e){
    this.setState({status_type: e.target.value})
  }
  changeAmount(e){
    this.setState({amount: e.target.value})
  }
  changeRemark(e){
    this.setState({remark: e.target.value})
  }
  componentDidMount(){
    const url_params=window.location.hash.split('#');
    const trans_id=url_params[1];
    const type=url_params[2];
    const status=url_params[3];
    const currency=url_params[4];
    const date=url_params[5];
    this.setState(state => ({trans_id:trans_id}));
    this.setState(state => ({trans_type:type}));
    this.setState(state => ({trans_status:status}));
    this.setState(state => ({trans_currency:currency}));
    this.setState(state => ({trans_date:date}));
  }
  async update(){
    if(this.state.amount===''){
      this.setState(state => ({error_msg:'Enter Amount.'}));
    }else{
      this.setState(state => ({submitDisabled:true}));
      var data;
      data = JSON.stringify({amount: parseInt(this.state.amount),remark: this.state.remark});
      var result1=await this.makeRequest('btsepay/transaction/'+this.state.trans_id+'/'+this.state.trans_type, 'put', data,localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState({
          success_msg:result1.data.message,error_msg:'',submitDisabled:false
        })
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled:false,success_msg:''}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false,success_msg:''}));
    }
  }
  render() {
    const hash=window.location.hash;
    let status_style;
    if(this.state.trans_status==='approved'){
        status_style={background: '#d0e6d2',color: '#7ebb84'}
    }else if(this.state.trans_status==='declined'){
        status_style={background: '#FFCBC9',color: '#FF443E'}
    }else if(this.state.trans_status==='pending'){
        status_style={background: '#F5E9CA',color: '#DEB652'}
    }else if(this.state.trans_status==='submit'){
      status_style={background: '#DDF0F8',color: '#9AD2EB'}
    }else if(this.state.trans_status==='cancelled'){
      status_style={background: '#E5E5E5',color: '#BFBFBF'}
    }
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Transactions</span> / 
                    <span> &nbsp; All transaction history</span> / 
                    <span>Transaction Details - Deposit</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Transaction Details</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-12">
                   <div className="Transreq_div">
                       <span>Type: {this.state.trans_type}</span>
                       <span>Transaction ID: {this.state.trans_id}</span>
                       <span>Status: 
                        {hash!=='' &&
                          <span style={status_style}>{this.state.trans_status}</span>
                        }
                        {hash==='' &&
                          <span style={status_style}>Approved</span>
                        }
                        </span>
                       <span>Last update: 30-12-2021 09:13:12</span>
                   </div>
                </div>
              </div><br/>
              {(this.state.trans_status==='approved' || this.state.trans_status==='declined' || this.state.trans_status==='') &&
                <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                    <h6 style={{fontWeight:700,margin:0}}>Transaction detail</h6>
                </div>
              }{(this.state.trans_status==='pending' || this.state.trans_status==='submit') &&
                <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                    <h6 style={{fontWeight:700,margin:0}}>Update Transaction status</h6>
                </div>
              }
              {(this.state.trans_status==='approved' || hash==='') &&
                <div>
                  <div className="row transac_det" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <div className="col-md-4">
                      <span>Transaction date: 30-12-2021 09:13:12</span><br/>
                      <span>Approved deposit amount: 100</span><br/>
                      <span>Currency: THB</span><br/>
                      <span>Fee: 0</span><br/>
                    </div>
                    <div className="col-md-4">
                      <span>Approved method: Manual</span><br/>
                      <span>Approved by: Orange_man</span><br/><br/>
                      <span>Remark:</span><br/>
                    </div>
                    <div className="col-md-4">
                      <span>Approved method: Auto</span><br/>
                      <span>Approved By: Twilio</span><br/><br/>
                      <span>Remark:</span><br/>
                    </div>
                  </div><br/>
                </div>
              }
              {this.state.trans_status==='declined' &&
                <div>
                    <div className="row transac_det" style={{margin:0,padding:'20px 14px',background:'white'}}>
                        <div className="col-md-4">
                            <span>Declined method: Manual</span><br/>
                            <span>Declined by: Orange_man</span><br/><br/>
                            <span>Remark: Transfer unsuccess</span><br/>
                        </div>
                    </div><br/>
                </div>
              }
              {(this.state.trans_status==='pending' || this.state.trans_status==='submit') &&
                <div>
                    <div className="row transac_det" style={{margin:0,padding:'20px 14px',background:'white'}}>
                        <div className="col-md-12">
                            <button className="btn btn-primary add_btn" onClick={() => this.statusModal(true)}>Update</button>
                            <Modal size="lg" show={this.state.showStatusModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal">
                                <Modal.Header style={{background:'none'}} closeButton onClick={() => this.statusModal(false)}>Update transaction status</Modal.Header>
                                <Modal.Body>
                                {this.state.success_msg!=='' ?
                                  (<p style={{fontSize: '16px',color: 'green'}}>{this.state.success_msg}</p>):('')
                                }
                                {this.state.error_msg!=='' ?
                                  (<p style={{fontSize: '16px',color: 'red',margin:0}}>{this.state.error_msg}</p>):('')
                                }
                                    <div className="formdiv">
                                        <label>Status</label>
                                        <select className="form-control" onChange={this.currntOption}>
                                            <option value="approve">Approve</option>
                                            {/*<option value="declined">Declined</option>*/}
                                        </select>
                                    </div><br/>                   
                                    {/*<div className="formdiv" style={{marginBottom:'24px'}}>
                                        <label>Upload transfer slip</label>
                                        <button className="btn btn-primary up_btn" style={{width:'auto'}}>Upload</button>
                                    </div>*/}
                                    <div>
                                        <div className="formdiv">
                                            <label>Transaction date*</label>
                                            <input type="date" className="form-control" placeholder="Transaction date" defaultValue={this.state.trans_date}readOnly />
                                        </div><br/>
                                        <div className="formdiv">
                                            <label>Currency</label>
                                            <input className="form-control" placeholder="Curency" defaultValue={this.state.trans_currency} readOnly/>
                                        </div><br/>
                                    </div>
                                    <div className="formdiv">
                                        <label>Approved amount</label>
                                        <input className="form-control" placeholder="Amount" onChange={this.changeAmount}/>
                                    </div><br/>
                                    <div className="formdiv">
                                        <label>Remark</label>
                                        <textarea className="form-control" placeholder="Remark" onChange={this.changeRemark}/>
                                    </div><br/>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div>
                                        <span onClick={()=>this.statusModal(false)}>Cancel</span>
                                        <button className="btn btn-primary" style={{width:'auto'}} onClick={this.update} disabled={this.state.submitDisabled}>Update</button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div><br/>
                </div>
              }
              {this.state.trans_status!=='cancelled' &&
                <div>
                    <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                        <h6 style={{fontWeight:700,margin:0}}>Transfer slip</h6>
                    </div>
                    <div style={{margin:0,padding:'28px',background:'white'}}>
                        <div className="uplodss">
                            <div></div>
                            <span>Upload at:</span><br/>
                            <span>30-12-2021 09:13:32</span>
                        </div>
                        <div className="uplodss">
                            <div></div>
                            <span>Upload at:</span><br/>
                            <span>30-12-2021 09:13:32</span>
                        </div>
                        <div className="uplodss">
                            <div></div>
                            <span>Upload at:</span><br/>
                            <span>30-12-2021 09:13:32</span>
                        </div>
                    </div><br/>
                </div>
              }
              <div className="subbdvv1" style={{padding: '18px 24px',borderBottom: '1px solid #E9E9E9'}}>
                <h6 style={{fontWeight:700,margin:0}}>Request Details</h6>
              </div>
              <div className="row transac_det" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-4">
                  <img alt="placeholder" src={'../assets/user_placeholder.png'}/>
                  <div>
                    <span>apple_chan</span><br/>
                    <span>#002</span><br/><br/>
                    <span>Email: kayly@gmail.com</span><br/>
                    <span>Phone: 8521254785</span><br/>
                    <button className="btn btn-primary add_btn" style={{marginTop:'12px',width:'auto'}}>View user detail</button>
                  </div>
                </div>
				<div className="col-md-4">
                  <span>Username: apple_chan</span><br/>
                  <span>Address: 00dvfhsanhfhnjdhdndhewd</span><br/>
                  <span>Coin: BTC</span><br/>
                  <span>Amount: 100</span><br/>
                  <span>Fee: </span><br/>
                  <span>ReferenceId: </span><br/>
                  <span>TransactionId: </span><br/>
                  <span>txId: </span><br/><br/>
                  <span>Request time: 12:12:21 09:13:32</span><br/>
                </div>
                {/*<div className="col-md-4">
                  <span>Sender's name: Chen Ping Gwo</span><br/>
                  <span>Deposit amount: 100</span><br/>
                  <span>Currency: THB</span><br/>
                  <span>Reference code: banana</span><br/><br/>
                  <span>Request time: 12-12-21 09:13:12</span>
                  <span>Turnover requirement: 500</span>
                </div>
                <div className="col-md-4">
                  <span>Deposit type: Local Bank</span><br/>
                  <span>Bank Name: Apple Bank</span><br/>
                  <span>Account name: Chan si si</span><br/>
                  <span>Bank account number: 123123123</span><br/><br/>
                  <span>OR</span><br/><br/>
                  <span>Deposit type: International wire</span><br/>
                  <span>Channel name: SWIFT-123</span><br/>
                  <span>Account name: Chan si si</span><br/>
                  <span>Account address: Thai, sss street, 12D Room</span><br/>
                  <span>Account number/IBAN: 12123</span><br/>
                  <span>Bank name: 123 Bank</span><br/>
                  <span>Bank address: 123 Room, Building</span><br/>
                  <span>SWIFT: 123</span><br/>
                </div>*/}
              </div><br/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TransactionRequest;