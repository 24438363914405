import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
class SmsBlast extends React.Component {
  render() {
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Marketing</span> / 
                    <span>SMS Blast</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>SMS Blast</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="subbdvv1" style={{padding: '36px 24px 0px 24px'}}>
                    <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px"}}>SMS Blast</h5>
                  </div>
                <div className="row" style={{margin:0,padding:'12px 14px 48px',background:'white'}}>
                
                    <div className="col-md-12 top_div">
                        <label className="labls"><span style={{fontWeight:"700"}}>Contact Number:</span></label>
                        <input type="text" className="form-control sms_blast"/>
                        <span style={{color:"rgb(255 32 32)",fontSize:"11px",fontWeight:"600"}}>add multiple contact number seperate by (,) eg. 0123456789, 0124545894.</span>
                    </div>
                    <div className="col-md-12 top_div" style={{marginTop:"10px"}}>
                        <label className="labls"><span style={{fontWeight:"700"}}>SMS Content:</span></label>
                        <textarea className="form-control sms_blast" style={{height:"100px"}}></textarea>
                    </div>
                    <br/>
                    <div style={{marginTop:"28px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Send Message</button>
                    </div>
                </div><br/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SmsBlast;