import React from "react";

class AboutSidebar extends React.Component {
  render() {
    return (
        <div>
          <ul className="sidebarul">
            <li className="active">About Us</li>
            <li>FAQ</li>
            <li>Betting Rules and Regulations</li>
            <li>Banking Information</li>
            <li>Contact Us</li>
            <li>How to Join</li>
            <li>How to Deposit</li>
            <li>How to Withdrawal</li>
            <li>How to Transfer</li>
            <li>Terms & Conditions</li>
          </ul>
        </div>
    );
  }
}

export default AboutSidebar;
