import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"

class UpdateProductDetails extends React.Component {
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 24px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Product</span> / 
                    <span>&nbsp; Product Maintenance</span> / 
                    <span>Update Product Details</span>
                  </div>
                  <h5 className="pg_head">Update Product Details</h5>
                  <button className="btn btn-primary add_btn" style={{width:'auto'}} onClick={()=>this.props.history.push('/admin/product_maintenance')}>Back To Product Maintenance</button>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
                <div className="row" style={{background: 'white',margin: 0}}>
                    <div className="col-md-6">
                        <div style={{padding:'20px 20px',background:'white'}}>
                            <div className="add_form_div create_agent_div deposit_withdr_div">
                                <span>Product Name: </span>
                                <input type="text" defaultValue="POKER8" className="form-control"/>
                            </div><br/>
                            <div className="add_form_div create_agent_div deposit_withdr_div">
                                <span>Sequence</span>
                                <input type="text" defaultValue="0" className="form-control"/>
                            </div><br/>
                            <div className="add_form_div create_agent_div deposit_withdr_div">
                                <span>Maintenance Start Time: </span>
                                <input type="date" className="form-control"/>
                            </div><br/>
                            <div className="add_form_div create_agent_div deposit_withdr_div">
                                <span>Maintenance End Time: </span>
                                <input type="date" className="form-control"/>
                            </div><br/>
                            <div>
                                <button className="btn btn-primary add_btn" style={{width:'auto'}}>Update Product Details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UpdateProductDetails;