import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { CaretDownFill } from 'react-bootstrap-icons';

class AffiliateBO extends React.Component {
    constructor(){
        super();
        this.state = {
          showInnerFirst: true,
          showUnderInnerFirst: true,
          showInnerThird: true,
          showInnerFourth: true,
          showInnerSecond: true,
          showUnderInnerSecond: true,
          showUnderInnerSecond2: true,
          
        }
    }
    showInnerFirst = () => {
        this.setState({showInnerFirst: !this.state.showInnerFirst });
    };
    showUnderInnerFirst = () => {
        this.setState({showUnderInnerFirst: !this.state.showUnderInnerFirst });
    };
    showInnerThird = () => {
        this.setState({showInnerThird: !this.state.showInnerThird });
    };
    showInnerFourth = () => {
        this.setState({showInnerFourth: !this.state.showInnerFourth });
    };
    showInnerSecond = () => {
        this.setState({showInnerSecond: !this.state.showInnerSecond });
    };
    showUnderInnerSecond = () => {
        this.setState({showUnderInnerSecond: !this.state.showUnderInnerSecond });
    };showUnderInnerSecond2 = () => {
        this.setState({showUnderInnerSecond2: !this.state.showUnderInnerSecond2 });
    };
  render() {
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Agent Management</span> / 
                    <span>Affiliate BO Module Control</span> 
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Affiliate BO Module Control</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="row" style={{margin:0,width:"100%",background:'white',padding:"16px 16px"}}>
                <div className="col-md-12">
                    <h6 style={{fontWeight:"800",color:"#434344",borderBottom:"1px solid #80808047",paddingBottom:"8px"}}>Assign Module</h6>
                    <ul className="BO_ul first">
                        <li>
                            <span className="down_arrow" onClick={() => { this.showInnerFirst() }} ><CaretDownFill size="18" color="#CECECE"/></span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Affiliate</span>
                            </span>
                            {this.state.showInnerFirst?
                            (<ul className="BO_ul1 inner_first">
                                <li>
                                    <span className="down_arrow" onClick={() => { this.showUnderInnerFirst() }} ><CaretDownFill size="18" color="#CECECE"/></span>
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Agent Management</span>
                                    </span>
                                </li>
                                {this.state.showUnderInnerFirst ?
                                (<ul className="BO_ul2 under_inner_first">
                                    <li className="last_BO_ul">
                                        <span className="checkspan twos twos1">
                                            <input type="checkbox"/> <span className="BO_text">View QR Code</span>
                                        </span>
                                    </li>
                                    <li className="last_BO_ul">
                                        <span className="checkspan twos twos1">
                                            <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Product Percentages Settings</span>
                                        </span>
                                    </li>
                                </ul>):('')}
                            </ul>):('')}
                        </li>
                    </ul>
                    <ul className="BO_ul second">
                        <li>
                            <span className="down_arrow" onClick={() => { this.showInnerSecond() }}><CaretDownFill size="18" color="#CECECE"/></span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Member</span>
                            </span>
                            {this.state.showInnerSecond ?
                            (<ul className="BO_ul1">
                                <li className="last_BO_ul">
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Add Member</span>
                                    </span>
                                </li>
                                <li className="last_BO_ul">
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Member Listing</span>
                                    </span>
                                </li>
                                <li>
                                    <span className="down_arrow" onClick={() => { this.showUnderInnerSecond() }}><CaretDownFill size="18" color="#CECECE"/></span>
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Member Search</span>
                                    </span>
                                </li>
                                {this.state.showUnderInnerSecond ?
                                (<ul className="BO_ul2">
                                    <li className="last_BO_ul">
                                        <span className="checkspan twos twos1">
                                            <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Update Member Account Status</span>
                                        </span>
                                    </li>
                                    <li className="last_BO_ul">
                                        <span className="checkspan twos twos1">
                                            <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">UpdateMemberPauseBet</span>
                                        </span>
                                    </li>
                                </ul>):('')}
                                <li>
                                    <span className="down_arrow" onClick={() => { this.showUnderInnerSecond2() }}><CaretDownFill size="18" color="#CECECE"/></span>
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Member Details</span>
                                    </span>
                                </li>
                                {this.state.showUnderInnerSecond2 ?
                                (<ul className="BO_ul2">
                                    <li className="last_BO_ul">
                                        <span className="checkspan twos twos1">
                                            <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">View Sensitive Data</span>
                                        </span>
                                    </li>
                                    <li className="last_BO_ul">
                                        <span className="checkspan twos twos1">
                                            <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Update Member Details (For Credit Agent Only)</span>
                                        </span>
                                    </li>
                                </ul>):('')}
                                <li>
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Online Member</span>
                                    </span>
                                </li>
                            </ul>):('')}
                        </li>
                    </ul>
                    <ul className="BO_ul third">
                        <li>
                            <span className="down_arrow" onClick={() => { this.showInnerThird() }}><CaretDownFill size="18" color="#CECECE"/></span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Transaction</span>
                            </span>
                            {this.state.showInnerThird ?
                            (<ul className="BO_ul1">
                                <li className="last_BO_ul">
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">New Transaction</span>
                                    </span>
                                </li>
                                <li className="last_BO_ul">
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Transaction History</span>
                                    </span>
                                </li>
                                <li className="last_BO_ul">
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Transaction Details</span>
                                    </span>
                                </li>
                            </ul>):('')}
                            
                        </li>
                    </ul>
                    <ul className="BO_ul fourth">
                        <li>
                            <span className="down_arrow" onClick={() => { this.showInnerFourth() }}><CaretDownFill size="18" color="#CECECE"/></span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Report</span>
                            </span>
                            {this.state.showInnerFourth ?
                            (<ul className="BO_ul1">
                                <li className="last_BO_ul">
                                    
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Member Transaction Report</span>
                                    </span>
                                </li>
                                <li className="last_BO_ul">
                                    
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Win/Lose Report</span>
                                    </span>
                                </li>
                                <li className="last_BO_ul">
                                   
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">PT Report</span>
                                    </span>
                                </li>
                                <li className="last_BO_ul">
                                    
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Promo Report</span>
                                    </span>
                                </li>
                                <li className="last_BO_ul">
                                   
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Rebate Report</span>
                                    </span>
                                </li>
                                <li className="last_BO_ul">
                                    
                                    <span className="checkspan twos twos1">
                                        <input type="checkbox" defaultChecked='checked'/> <span className="BO_text">Details PT Report</span>
                                    </span>
                                </li>
                            </ul>):('')}
                            
                        </li>
                    </ul>
                </div>
                
                </div><br/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AffiliateBO;