import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
import { CSVLink } from "react-csv";
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,member_name: "TestName",total_records:"12",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 2,member_name: "TestName",total_records:"12",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 3,member_name: "TestName",total_records:"12",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 4,member_name: "TestName",total_records:"12",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 5,member_name: "TestName",total_records:"12",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 6,member_name: "TestName",total_records:"12",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 7,member_name: "TestName",total_records:"12",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 8,member_name: "TestName",total_records:"12",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 9,member_name: "TestName",total_records:"12",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 10,member_name: "TestName",total_records:"12",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const excelData = [
  {
    "Member Name": "TestName",
    "Total Records": "4",
    "Turnover": 2,
    "Bet": 70.00,
    "Payout": 1041.00,
    "Win/Lose": 1041.00,
    "Prog. Share": 'cdemo005',
    "Commission": '-',
    "Company P/L": '-',
    "%": '-'
  },
  {
    "Member Name": "TestName",
    "Total Records": "4",
    "Turnover": 2,
    "Bet": 70.00,
    "Payout": 1041.00,
    "Win/Lose": 1041.00,
    "Prog. Share": 'cdemo005',
    "Commission": '-',
    "Company P/L": '-',
    "%": '-'
  },
  {
    "Member Name": "TestName",
    "Total Records": "4",
    "Turnover": 2,
    "Bet": 70.00,
    "Payout": 1041.00,
    "Win/Lose": 1041.00,
    "Prog. Share": 'cdemo005',
    "Commission": '-',
    "Company P/L": '-',
    "%": '-'
  },
  {
    "Member Name": "TestName",
    "Total Records": "4",
    "Turnover": 2,
    "Bet": 70.00,
    "Payout": 1041.00,
    "Win/Lose": 1041.00,
    "Prog. Share": 'cdemo005',
    "Commission": '-',
    "Company P/L": '-',
    "%": '-'
  }
]
const headers = [
  { label: "Member Name", key: "member_name" },
  { label: "Total Records", key: "total_records" },
  { label: "Turnover", key: "123.00" },
  { label: "Bet", key: "123.00" },
  { label: "Payout", key: "3.00" },
  { label: "Win/Lose", key: "-30.00" },
  { label: "Prog. Share", key: "0.00" },
  { label: "Commission", key: "0.00" },
  { label: "Company P/L", key: "0.50" },
  { label: "%", key: "80.00" },
];
class WinLossReport extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.printData = this.printData.bind(this)
    this.exportData = this.exportData.bind(this)
    }
    printData(){
      window.print();
    }
    exportData(){
      exportFromJSON({ data: excelData, fileName: 'Win loss report', exportType: exportFromJSON.types.xls})
    }
  render() {
    const columns = [
      {name: 'No.',selector: 'id',width:'50px',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Member Name',selector: 'member_name',width:'100px',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>TestName</div>},
      {name: 'Total Records',selector: 'total_records',width:'110px',cell: row => <div data-tag="allowRowEvents">12</div>},
      {name: 'Turnover',selector: 'turnover',width:'80px',cell: row => <div data-tag="allowRowEvents">123.00</div>},
      {name: 'Bet',selector: 'bet',width:'80px',cell: row => <div data-tag="allowRowEvents">123.00</div>},
      {name: 'Payout',selector: 'payout',width:'70px',cell: row => <div data-tag="allowRowEvents">3.00</div>},
      {name: 'Win/Lose',selector: 'win_lose',width:'70px',cell: row => <div data-tag="allowRowEvents">-30.00</div>},
      {name: 'Prog. Share',width:'120px',selector: 'prog_share',cell: row => <div data-tag="allowRowEvents">0.00</div>},
      {name: 'Commission',width:'150px',selector: 'commission',cell: row => <div data-tag="allowRowEvents">0.00</div>},
      {name: 'Company P/L',width:'100px',selector: 'company_pl',cell: row => <div data-tag="allowRowEvents">0.50</div>},
      {name: '%',selector: 'percent',cell: row => <div data-tag="allowRowEvents">80.00</div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}} className="print_hide">
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Report</span> / 
                    <span>Win/Lose Report</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Win / Lose Report</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="subbdvv1 print_hide" style={{padding: '14px 24px 0px 24px'}}>
                    <h6 style={{fontWeight:700,margin:0}}>Search Criteria</h6>
                  </div>
                <div className="row print_hide" style={{margin:0,padding:'12px 14px',background:'white'}}>
                
                    <div className="col-md-6 col-sm-6 top_div">
                        <label className="labls labls1"><span>Date Range:</span></label>
                        <input type="date" className="form-control"/>
                    </div>
                    <div className="col-md-6 col-sm-6 top_div">    
                        <label className="labls labls1"><span>Product:</span></label>
                        <select className="form-control select_size">
                                <option className="select_size">Select</option>
                        </select>
                    </div> 
                    <div className="col-md-6 col-sm-6 top_div">   
                        <label className="labls labls1"><span>Product Type:</span></label>
                        <select className="form-control select_size">
                                <option className="select_size">All</option>
                        </select>
                    </div>
                    <div className="col-md-6 col-sm-6 top_div">
                        <label className="labls labls1"><span>Agent Code:</span></label>
                        <input type="text" className="form-control"/>
                    </div>
                    <div className="col-md-6 col-sm-6 top_div">   
                        <label className="labls labls1"><span>Status:</span></label>
                        <select className="form-control select_size">
                                <option className="select_size">All</option>
                        </select>
                    </div>
                    <div className="col-md-6 col-sm-6 top_div">
                        <label className="labls labls1"><span>Account Id:</span></label>
                        <input type="text" className="form-control"/>
                    </div>
                    <br/>
                    <div className="top_second_div report_btns" style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Member</button>
                        <button className="btn btn-primary add_btn cancel_btn">Agent</button>
                        <button className="btn btn-primary add_btn cancel_btn">Product</button>
                        <button className="btn btn-primary add_btn cancel_btn">Type</button>
                        <button className="btn btn-primary add_btn cancel_btn">Reset</button>
                    </div>
                
                </div><br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <span className="subdvv1_1_1 win_report_btns print_hide">
                <label className="labls labls1"><span style={{minWidth:"48px"}}>Show:</span>
                    <select className="form-control" style={{width:"70px"}}>
                            <option>50</option>
                    </select><span style={{minWidth:"10px",marginLeft:"12px"}}>Entries:</span></label>
                    <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                    <CSVLink data={data} headers={headers} className="btn btn-primary add_btn cancel_btn">CSV</CSVLink>;
                    <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                </span>
                <div className="search_report print_hide" style={{float:'right',marginBottom:'10px'}}>
                <label className="labls labls1"><span style={{minWidth:"56px"}}>Search:</span><input type="text" className="form-control" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}}/></label>
                </div>
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WinLossReport;