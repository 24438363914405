import React from "react";
import { makeRequest,timestampOnlyDate } from '../helpers'
import WalletBalance from './WalletBalance';
import { Modal } from "react-bootstrap";
import { XCircleFill } from 'react-bootstrap-icons';

class LevelDetsSM extends React.Component {
  constructor(props) {
    super(props);
    this.viewdets = this.viewdets.bind(this)
	this.makeRequest = makeRequest
  }
  state= {
    showDetsModal:false,
	totalWalletBalance:'',
  }
  viewdets(val){
    this.setState(state => ({
        showDetsModal: val,
    }));
  }
  render() {
    const wallet_balance=this.props.balance;
    return (
        <div>
            <div>
                <h6 style={{fontSize:'12px',opacity:0.75}}>Wallet Balance</h6>
                <h4 style={{margin:0}}>USD {wallet_balance}
                {/*<span style={{float: 'right',fontSize: '13px',color: '#D8A62C',border: '1px solid',width: '60px',height: '28px',textAlign: 'center',padding: '6px 0',borderRadius: '3px',marginTop: '-3px'}} onClick={() => this.viewdets(true)}>View</span>*/}
                <Modal size="sm" show={this.state.showDetsModal} className="pwd_changed_modal bank_account_modal leveldets_modal" strictmode="false">
                    <Modal.Body style={{padding:0}}>
                        <WalletBalance/>
                    </Modal.Body>
                    <div className="text-center" style={{background: '#0d181f',padding: '10px 0',cursor:'pointer'}}>
                        <XCircleFill onClick={() => this.viewdets(false)} fill="#D8A62C" size="18"/><br/>
                        {/*<span style={{color: '#D8A62C'}}>Close</span>*/}
                    </div>
                </Modal>
                </h4><br/>
            </div>
            <div className="divvvborder"></div><br/>
            <div className="text-center">
                <img alt="silvercrown" src={'assets/silvercrown.png'}/>
            </div><br/><br/>
            <div className="viptxtdiv transfertxtdiv">
                <span>VIP LEVEL </span>
                <span>Silver</span>
            </div>
            <div className="viptxtdiv transfertxtdiv">
                <span>Deposit Required For Upgrade </span>
                <span>USD {wallet_balance}</span>
            </div>
            <div className="viptxtdiv transfertxtdiv">
                <span>Turnover Requirement for Upgrade </span>
                <span>USD {wallet_balance}</span>
            </div>
            <div className="viptxtdiv transfertxtdiv">
                <span>Registration Date </span>
                <span>{localStorage.getItem('userInfo')!==undefined && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!=='' ? timestampOnlyDate((JSON.parse(localStorage.getItem('userInfo'))).createdAt):''}</span>
            </div>
        </div>
    );
  }
}

export default LevelDetsSM;
