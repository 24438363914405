import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import {Search} from 'react-bootstrap-icons'
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,timestamp: "2021-03-14 04:07:50",member_id:"dclient02",adj_qty:"20",amount_before:"0",amount_after:"0",adjusted_by:"cdemo05",remark:"Batch token adjustment"},{id: 2,timestamp: "2021-03-14 04:07:50",member_id:"dclient02",adj_qty:"20",amount_before:"0",amount_after:"0",adjusted_by:"cdemo05",remark:"Batch token adjustment"},{id: 3,timestamp: "2021-03-14 04:07:50",member_id:"dclient02",adj_qty:"20",amount_before:"0",amount_after:"0",adjusted_by:"cdemo05",remark:"Batch token adjustment"},{id: 4,timestamp: "2021-03-14 04:07:50",member_id:"dclient02",adj_qty:"20",amount_before:"0",amount_after:"0",adjusted_by:"cdemo05",remark:"Batch token adjustment"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const excelData = [
  {
    "Timestamp": "2021-02-26 14:53:06",
    "Member ID": "dclient02",
    "Adjustment qty.": 30.00,
    "Amount Before": 5.00,
    "Amount After": '10.00',
    "Adjusted By": 'cdemo05',
    "Remark": '-'
  },
  {
    "Timestamp": "2021-02-26 14:53:06",
    "Member ID": "dclient02",
    "Adjustment qty.": 30.00,
    "Amount Before": 5.00,
    "Amount After": '10.00',
    "Adjusted By": 'cdemo05',
    "Remark": '-'
  },
  {
    "Timestamp": "2021-02-26 14:53:06",
    "Member ID": "dclient02",
    "Adjustment qty.": 30.00,
    "Amount Before": 5.00,
    "Amount After": '10.00',
    "Adjusted By": 'cdemo05',
    "Remark": '-'
  },
  {
    "Timestamp": "2021-02-26 14:53:06",
    "Member ID": "dclient02",
    "Adjustment qty.": 30.00,
    "Amount Before": 5.00,
    "Amount After": '10.00',
    "Adjusted By": 'cdemo05',
    "Remark": '-'
  }
]
class AdjustmentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.printData = this.printData.bind(this)
    this.exportData = this.exportData.bind(this)
    }
    printData(){
      window.print();
    }
    exportData(){
      exportFromJSON({ data: excelData, fileName: 'Adjustment history', exportType: exportFromJSON.types.xls})
    }
  render() {
    const columns = [
        {name: 'No.',selector: 'id',width:'50px'},
        {name: 'Timestamp',selector: 'timestamp'},
        {name: 'Member ID',selector: 'member_id'},
        {name: 'Adjustment qty.',selector: 'adj_qty'},
        {name: 'Amount Before',selector: 'amount_before'},
        {name: 'Amount After',selector: 'amount_after'},
        {name: 'Adjusted By',selector: 'adjusted_by'},
        {name: 'Remark',selector: 'remark'},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}} className="print_hide">
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Member management</span> / 
                    <span>Adjustment History</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Adjustment History</h5>
                </div>
              </div><br/>
                <div className="divbody">
                  <div className="row member_listing adj_hist print_hide" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1"> 
                      <label className="labls"><span>Record Type:</span> 
                          <select className="form-control input_width_100" style={{width: '120px'}}>
                              <option>Token</option>
                          </select>
                      </label>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">     
                        <label className="labls"><span>Member ID:</span> <input type="text" className="form-control input_width_100"/></label>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">     
                        <label className="labls"><span>Date Range:</span> 
                            <input type="date" className="form-control input_width_100"/>
                        </label>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1"> 
                      <label className="labls"><span>Adjusted By:</span> 
                          <input type="text" className="form-control input_width_100"/>
                      </label>
                    </div>  
                    <div>    
                        <label className="labls"><button className="btn btn-primary add_btn">Search</button>
                        <button className="btn btn-primary add_btn cancel_btn">Reset</button></label>
                    </div>
                  </div>
                </div><br/>
                <div className="divbody">
                  <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                      <span className="subdvv1_1_1 print_hide">
                          <input type="text" className="form-control search" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Search"/>
                          <Search color="#00000040" style={{top:'4px'}} size={15}/>
                      </span>
                      <div className="top_btns print_hide" style={{float:'right',marginBottom:'10px'}}>
                          <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                          <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                      </div>
                      <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdjustmentHistory;