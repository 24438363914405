import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';

class ComingSoon extends React.Component { 
  render() {
    return (
      <div style={{height:'100vh' }}>
        <Navbar prop={this.props}/>
        <div className="comingsoon_div" style={{background:'url(assets/comingsoon.png)',backgroundPosition: 'center',backgroundSize: 'cover',height:'100%'}}>
          <div className="container" style={{height:'100%'}}>
              <div className="row justify-content-center" style={{height:'100%'}}>
                  <div className="col-md-12" style={{margin:'auto',textAlign:'center'}}>
                      <div>
                        <h1 className="text-white">This Feature is Coming soon!</h1>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ComingSoon;