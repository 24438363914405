import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
import { CSVLink } from "react-csv";
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,timestamp: "2021-02-28 14:53:06",member:"dclient02",adj_type:"Add credit",amount:"5.00",balance_before:"2.00",balance_after:"70.00",operator:"cdemo005",remark:"Testing"},
  {id: 2,timestamp: "2021-02-28 14:53:06",member:"dclient02",adj_type:"Add credit",amount:"5.00",balance_before:"2.00",balance_after:"70.00",operator:"cdemo005",remark:"Testing"},
  {id: 3,timestamp: "2021-02-28 14:53:06",member:"dclient02",adj_type:"Add credit",amount:"5.00",balance_before:"2.00",balance_after:"70.00",operator:"cdemo005",remark:"Testing"},
  {id: 4,timestamp: "2021-02-28 14:53:06",member:"dclient02",adj_type:"Add credit",amount:"5.00",balance_before:"2.00",balance_after:"70.00",operator:"cdemo005",remark:"Testing"},
  {id: 5,timestamp: "2021-02-28 14:53:06",member:"dclient02",adj_type:"Add credit",amount:"5.00",balance_before:"2.00",balance_after:"70.00",operator:"cdemo005",remark:"Testing"},
  {id: 6,timestamp: "2021-02-28 14:53:06",member:"dclient02",adj_type:"Add credit",amount:"5.00",balance_before:"2.00",balance_after:"70.00",operator:"cdemo005",remark:"Testing"}, 
];
const excelData = [
  {
    "Timestamp": "2021-02-26 14:53:06",
    "Member": "dclient02",
    "Adjustment type": 'Add credit',
    "Amount": 5.00,
    "Balance Before": 1041.00,
    "Balance After": 1041.00,
    "Operator": 'cdemo005',
    "Remark": 'Testing'
  },
  {
    "Timestamp": "2021-02-26 14:53:06",
    "Member": "dclient02",
    "Adjustment type": 'Add credit',
    "Amount": 5.00,
    "Balance Before": 1041.00,
    "Balance After": 1041.00,
    "Operator": 'cdemo005',
    "Remark": 'Testing'
  },
  {
    "Timestamp": "2021-02-26 14:53:06",
    "Member": "dclient02",
    "Adjustment type": 'Add credit',
    "Amount": 5.00,
    "Balance Before": 1041.00,
    "Balance After": 1041.00,
    "Operator": 'cdemo005',
    "Remark": 'Testing'
  },
  {
    "Timestamp": "2021-02-26 14:53:06",
    "Member": "dclient02",
    "Adjustment type": 'Add credit',
    "Amount": 5.00,
    "Balance Before": 1041.00,
    "Balance After": 1041.00,
    "Operator": 'cdemo005',
    "Remark": 'Testing'
  }
]
const headers = [
  { label: "Timestamp", key: "timestamp" },
  { label: "Member", key: "member" },
  { label: "Adjustment type", key: "adj_type" },
  { label: "Amount", key: "amount" },
  { label: "Balance Before", key: "balance_before" },
  { label: "Balance After", key: "balance_after" },
  { label: "Operator", key: "operator" },
  { label: "Remark", key: "remark" },
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class OffRecord extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.printData = this.printData.bind(this)
    this.exportData = this.exportData.bind(this)
    }
    printData(){
      window.print();
    }
    exportData(){
      exportFromJSON({ data: excelData, fileName: 'Off record', exportType: exportFromJSON.types.xls})
    }
  render() {
    const columns = [
      {name: 'No.',width:'50px',selector: 'id',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Timestamp',width:'150px',selector: 'timestamp',cell: row => <div data-tag="allowRowEvents">2021-02-26 14:53:06</div>},
      {name: 'Member',selector: 'member',cell: row => <div data-tag="allowRowEvents">dclient02</div>},
      {name: 'Adj. Type',selector: 'adj_type',cell: row => <div data-tag="allowRowEvents">Add credit</div>},
      {name: 'Amount',selector: 'amount',cell: row => <div data-tag="allowRowEvents">5.00</div>},
      {name: 'Balance Before',selector: 'balance_before',cell: row => <div data-tag="allowRowEvents">2.00</div>},
      {name: 'Balance After',selector: 'balance_after',cell: row => <div data-tag="allowRowEvents">70.00</div>},
      {name: 'Operator',selector: 'operator',cell: row => <div data-tag="allowRowEvents">cdemo005</div>},
      {name: 'Remark',selector: 'remark',cell: row => <div data-tag="allowRowEvents">Testing</div>},
    ];
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}} className="print_hide">
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Transactions</span> / 
                    <span>Off record adjustment history</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Off record adjustment history</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="row bonus_adj print_hide" style={{margin:0,padding:'12px 14px',background:'white'}}>
                
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"82px"}}>Date Range:</span>
                        <input type="date" className="form-control" style={{height:"32px",fontSize:"14px"}} /></label>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"64px"}}>Adj. Type:</span>
                        <select className="form-control" style={{height:"32px",fontSize:"14px"}}>
                            <option>All</option>
                        </select></label>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"72px"}}>Member ID:</span>
                        <input type="text" className="form-control" style={{height:"32px",fontSize:"14px"}} /></label>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"60px"}}>Operator:</span>
                        <input type="text" className="form-control" style={{height:"32px",fontSize:"14px"}} /></label>
                    </div>
                    <br/>
                    <div>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Search</button>
                    </div>
                </div><br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px 56px',background:'white'}}>
                  <div className="row print_hide">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <span className="subdvv1_1_1 mem_stat_left">
                          <input type="text" className="form-control" style={{display: 'inline-block',height: '31px',fontSize: '14px'}} placeholder="Search"/>
                          <Search color="#00000040" style={{top:'4px'}} size={15}/>
                      </span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12"> 
                      <div className="mem_stat_btns" style={{float:'right',marginBottom:'10px'}}>
                        <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                          <CSVLink data={data} headers={headers} className="btn btn-primary add_btn cancel_btn">CSV</CSVLink>;
                          <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                      </div>
                    </div> 
                  </div>       
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OffRecord;