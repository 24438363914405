import React from "react";
import {Link} from 'react-router-dom';
import { ChevronDown,BarChartFill,PersonFill,NutFill,PencilFill,Stack,MegaphoneFill,ChatSquareDotsFill,Bank2,AwardFill,Speedometer2,GenderTrans,Gem,Coin,GridFill,FileEarmarkTextFill,XSquare,XDiamondFill } from 'react-bootstrap-icons';
import eventBus from "../../eventBus";

class AdminSidebar extends React.Component {
  constructor(props) {
    super(props);
    //this.showPop = this.showPop.bind(this)
    //this.eventBus = eventBus
    this.changeSidebar = this.changeSidebar.bind(this)
  }
  state= {
    path:'false',
    windowWidth:window.innerWidth,
    viewSidebar:'',
  }
//   showPop(val1){
//     this.setState(state => ({popStatus: val1}));
//   }
    changeSidebar(){
      this.setState({ viewSidebar: false })
      eventBus.dispatch("viewSidebar", { message: false});
    }
  componentDidMount(){
    eventBus.on("viewSidebar", (data) =>
      this.setState({ viewSidebar: data.message })
    );
    let path=(this.props.prop.location.pathname)
    path=path.split("/");
    this.setState(state => ({path: path[2]}));
    window.addEventListener('resize', (event) => {
      if(window.innerWidth<='767'){
        this.setState({ viewSidebar:false})
      }else{
        this.setState({ viewSidebar:true})
      }
    });
    if(this.state.windowWidth<='767'){
      this.setState({ viewSidebar:false})
    }else{
      this.setState({ viewSidebar:true})
    }
  }
  componentWillUnmount() {
    eventBus.remove("viewSidebar");
  }
  render() {
    return (
          <div className="sdvvv" style={this.state.viewSidebar ? {background:'#323232',height:"100%",display:'block'} : {background:'#323232',height:"100%",display:'none'}}>
            <div className="text-white gbo_dash">
              <div style={{float:"left"}}>Vigobet Dashboard</div>
              <div className="navbar_toggle" style={{display:"none",float:"right"}} onClick={this.changeSidebar}><XSquare size="16" style={{marginBottom:"4px"}}/></div>
            </div>
            <div className="menus">
                {/* <ul>
                  <li><Link to="platform_owners" className="text-white">Platform Owner</Link></li>
                  <li><Link to="dashboard" className="text-white">Dashboard</Link></li>
                </ul> */}
                {localStorage.getItem('role')==='provider' ?
                (<ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item">
                    <Link style={(this.state.path==='platform_owners' || this.state.path==='add_platform_owner' || this.state.path==='platform_owner_detail' || this.state.path==='edit_platform_owner') ? {color:'white'} : {}} to="/admin/platform_owners" className="nav-link"><PersonFill size="16"/> <span className="sidebar_text">Platform Owner</span></Link>
                  </li>
                  {/*<li className="nav-item">
                    <Link style={(this.state.path==='deposit_channel' || this.state.path==='add_deposit_channel' || this.state.path==='edit_deposit_channel' || this.state.path==='deposit_channel_details') ? {color:'white'} : {}} to="/admin/deposit_channel" className="nav-link"><LayerBackward size="16"/> <span className="sidebar_text">Deposit channel</span></Link>
                  </li>*/}
                  <li className="nav-item">
                    <Link style={(this.state.path==='game_list') ? {color:'white'} : {}} to="/admin/game_list" className="nav-link"><XDiamondFill size="16"/> <span className="sidebar_text">Games</span></Link>
                  </li>
                </ul>):('')}
                {localStorage.getItem('role')==='owner'?
                (<ul className="nav flex-column" id="nav_accordion">  
                  <li className="nav-item">
                    <Link to="/admin/dashboard" style={this.state.path==='dashboard' ? {color:'white'} : {}} className="nav-link"><Speedometer2 fill={this.state.path==='dashboard' ? 'white' : ''} size="16"/> <span className="sidebar_text">Dashboard</span></Link>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item1" href="#"><GenderTrans size="16"/> <span className="sidebar_text">Transactions</span> <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item1" className={(this.state.path==='transaction_request' || this.state.path==='transaction_history'|| this.state.path==='transaction' || this.state.path==='bonus_adjustment' || this.state.path==='off_record' || this.state.path==='batch_adjust' || this.state.path==='batch_wallet' || this.state.path==='transaction_report' || this.state.path==='transaction_details') ? 'submenu collapse show' : 'submenu collapse'} data-bs-parent="#nav_accordion">
                      <li style={this.state.path==='transaction_request' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/transaction_request"><span className="sidebar_text">Transaction request</span> </Link></li>
                      <li style={this.state.path==='transaction' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/transaction"><span className="sidebar_text">Transaction </span> </Link></li>
                      <li style={this.state.path==='transaction_history' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/transaction_history"><span className="sidebar_text">All transaction history </span></Link></li>
                      <li style={this.state.path==='bonus_adjustment' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/bonus_adjustment"><span className="sidebar_text">Bonus adjustment history</span> </Link></li>
                      <li style={this.state.path==='off_record' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/off_record"><span className="sidebar_text">Off record adjustment history</span> </Link></li>
                      <li style={this.state.path==='batch_adjust' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/batch_adjust"><span className="sidebar_text">Batch adjustment</span> </Link></li>
                      <li style={this.state.path==='batch_wallet' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/batch_wallet"><span className="sidebar_text">Batch wallet adjustment</span> </Link></li>
                      <li style={this.state.path==='transaction_report' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/transaction_report"><span className="sidebar_text">Transaction report</span></Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item2" href="#"><Gem size="16"/> <span className="sidebar_text">Member management</span> <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item2" className={(this.state.path==='member_listing' || this.state.path==='online_member' || this.state.path==='member_group' || this.state.path==='batch_adjustment' || this.state.path==='adjustment_history' || this.state.path==='member_details') ? 'submenu collapse show' : 'submenu collapse'} data-bs-parent="#nav_accordion">
                      <li style={this.state.path==='member_listing' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/member_listing"><span className="sidebar_text">Member</span></Link></li>
                      <li style={this.state.path==='online_member' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/online_member"><span className="sidebar_text">Online Member</span></Link></li>
                      <li style={this.state.path==='member_group' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/member_group"><span className="sidebar_text">Member Group</span></Link></li>
                      <li><a className="nav-link" href="#"><span className="sidebar_text">Detailed Member Report</span></a></li>
                      <li style={this.state.path==='batch_adjustment' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/batch_adjustment"><span className="sidebar_text">Batch Token/Point Adjustment</span></Link></li>
                      <li style={this.state.path==='adjustment_history' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/adjustment_history"><span className="sidebar_text">Adjustment History</span></Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item3" href="#"><AwardFill size="16"/> <span className="sidebar_text">Agent management</span> <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item3" className={(this.state.path==='agent_mgmt' || this.state.path==='credit_history' || this.state.path==='agent_performance' || this.state.path==='create_agent' || this.state.path==='agent_credit_settlement' || this.state.path==='affiliate_bo') ? 'submenu collapse show' : 'submenu collapse'} data-bs-parent="#nav_accordion">
                      <li style={this.state.path==='agent_mgmt' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/agent_mgmt"><span className="sidebar_text">Agent Management</span></Link></li>
                      <li style={this.state.path==='affiliate_bo' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/affiliate_bo"><span className="sidebar_text">Affiliate BO Module Control</span></Link></li>
                      <li><a className="nav-link" href="#"><span className="sidebar_text">PT Report</span></a></li>
                      <li style={this.state.path==='agent_performance' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/agent_performance"><span className="sidebar_text">Agent Performance</span></Link></li>
                      <li style={this.state.path==='credit_history' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/credit_history"><span className="sidebar_text">Credit History</span></Link></li>
                      <li style={this.state.path==='agent_credit_settlement' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/agent_credit_settlement"><span className="sidebar_text">Agent Credit Settlement</span></Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item4" href="#"><Bank2 size="16"/> <span className="sidebar_text">Bank management</span> <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item4" className={(this.state.path==='banks' || this.state.path==='bank_stmt' || this.state.path==='daily_bank_report' || this.state.path==='detailed_bank_report' || this.state.path==='bank_accounts') ? 'submenu collapse show' : 'submenu collapse'} data-bs-parent="#nav_accordion">
                      <li style={this.state.path==='banks' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/banks"><span className="sidebar_text">Bank</span></Link></li>
                      <li style={this.state.path==='bank_accounts' ? {background:'#575757'} : {}}><Link className="nav-link" href="/#"><span className="sidebar_text">Bank Accounts</span></Link></li>
                      <li><a className="nav-link" href="#"><span className="sidebar_text">Bank Accounts Threshold</span></a></li>
                      <li style={this.state.path==='daily_bank_report' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/daily_bank_report"><span className="sidebar_text">Daily Bank Report</span></Link></li>
                      <li style={this.state.path==='bank_stmt' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/bank_stmt"><span className="sidebar_text">Bank Statement</span></Link></li>
                      <li style={this.state.path==='detailed_bank_report' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/detailed_bank_report"><span className="sidebar_text">Detailed Bank Report</span></Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item5" href="#"><ChatSquareDotsFill style={{verticalAlign:'middle'}} size="16"/> <span className="sidebar_text">Message center</span> <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item5" className={(this.state.path==='send_message' || this.state.path==='send_history') ? 'submenu collapse show' : 'submenu collapse'} data-bs-parent="#nav_accordion">
                      <li style={this.state.path==='send_message' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/send_message">Send message</Link></li>
                      <li><a className="nav-link" href="#">Inbox</a></li>
                      <li><a className="nav-link" href="#">Archive message</a></li>
                      <li style={this.state.path==='send_history' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/send_history">Send history</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item6" href="#"><GridFill size="16"/> Product <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item6" className={(this.state.path==='product_maintenance' || this.state.path==='update_product_details') ? 'submenu collapse show' : 'submenu collapse'} data-bs-parent="#nav_accordion">
                      <li style={this.state.path==='product_maintenance' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/product_maintenance">Product Maintenance</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item7" href="#"><Coin size="16"/> Rebate <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item7" className={(this.state.path==='rebate_setting' || this.state.path==='calculate_member_rebate' || this.state.path==='rebate_report' || this.state.path==='update_rebate_setting') ? 'submenu collapse show' : 'submenu collapse'} data-bs-parent="#nav_accordion">
                      <li style={this.state.path==='rebate_setting' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/rebate_setting">Rebate Setting</Link></li>
                      <li style={this.state.path==='calculate_member_rebate' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/calculate_member_rebate">Calculate Member Rebate</Link></li>
                      <li><a className="nav-link" href="#">Rebate History</a></li>
                      <li style={this.state.path==='rebate_report' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/rebate_report">Rebate Report</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item8" href="#"><PersonFill size="16"/> Cash back <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item8" data-bs-parent="#nav_accordion" className={(this.state.path==='cash_back_setting' || this.state.path==='member_cash_back' || this.state.path==='member_cash_back_history') ? 'submenu collapse show' : 'submenu collapse'}>
                      <li  style={this.state.path==='cash_back_setting' ? {background:'#575757'} : {}}><Link className="nav-link"  to="/admin/cash_back_setting">Cash Back Setting</Link></li>
                      <li  style={this.state.path==='member_cash_back' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/member_cash_back">Member Cash Back</Link></li>
                      <li  style={this.state.path==='member_cash_back_history' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/member_cash_back_history">Member Cash Back History</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item9" href="#"><FileEarmarkTextFill size="16"/> Report <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item9" data-bs-parent="#nav_accordion"  className={(this.state.path==='win_loss_report' || this.state.path==='detailed_win_loss_report' || this.state.path==='transfer_report') ? 'submenu collapse show' : 'submenu collapse'} >
                      <li  style={this.state.path==='win_loss_report' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/win_loss_report">Win/Lose report</Link></li>
                      <li  style={this.state.path==='detailed_win_loss_report' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/detailed_win_loss_report">Detailed Win/Lose report</Link></li>
                      <li style={this.state.path==='transfer_report' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/transfer_report">Transfer report</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item10" href="#"><BarChartFill size="16"/> Statistics <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item10" data-bs-parent="#nav_accordion" className={(this.state.path==='game_statistic' || this.state.path==='website_statistic' || this.state.path==='member_statistic' || this.state.path==='member_statistic_advanced' || this.state.path==='transaction_statistic' || this.state.path==='player_freq_report' || this.state.path==='inactive_player') ? 'submenu collapse show' : 'submenu collapse'}>
                      <li style={this.state.path==='game_statistic' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/game_statistic">Game Statistic</Link></li>
                      <li style={this.state.path==='website_statistic' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/website_statistic">Website Statistic</Link></li>
                      <li style={this.state.path==='member_statistic' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/member_statistic">Member Statistic</Link></li>
                      <li style={this.state.path==='member_statistic_advanced' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/member_statistic_advanced">Member Statistic Advanced</Link></li>
                      <li style={this.state.path==='transaction_statistic' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/transaction_statistic">Transaction Statistic</Link></li>
                      <li style={this.state.path==='player_freq_report' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/player_freq_report">Player Freq. Report</Link></li>
                      <li style={this.state.path==='inactive_player' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/inactive_player">Inactive Customer (Deposit)</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item11" href="#"><MegaphoneFill size="16"/> Marketing <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item11" data-bs-parent="#nav_accordion" className={(this.state.path==='sms_blast' || this.state.path==='email_blast') ? 'submenu collapse show' : 'submenu collapse'}>
                      <li style={this.state.path==='email_blast' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/email_blast">Email blast</Link></li>
                      <li style={this.state.path==='sms_blast' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/sms_blast">SMS blast</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item12" href="#"><Stack size="16"/> CMS <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item12" className={(this.state.path==='member_groups' || this.state.path==='add_member_group' || this.state.path==='edit_member_group') ? 'submenu collapse show' : 'submenu collapse'}  data-bs-parent="#nav_accordion">
                      <li style={this.state.path==='member_groups' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/member_groups">Member Groups</Link></li>
                      <li><a className="nav-link" href="#">Popup Announcement</a></li>
                      <li><a className="nav-link" href="#">Banner Management</a></li>
                      <li><a className="nav-link" href="#">Info Management</a></li>
                      <li><a className="nav-link" href="#">Promotion Management</a></li>
                      <li><a className="nav-link" href="#">Promotion Category</a></li>
                      <li><a className="nav-link" href="#">Layout Management</a></li>
                      <li><a className="nav-link" href="#">Content Management</a></li>
                      <li><a className="nav-link" href="#">Custom Page Management</a></li>
                      <li><a className="nav-link" href="#">Preferences</a></li>
                      <li><a className="nav-link" href="#">Configuration</a></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item13" href="#"><PencilFill size="16"/> Tools <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item13" className="submenu collapse" data-bs-parent="#nav_accordion">
                      <li><a className="nav-link" href="#">Announcement</a></li>
                      <li><a className="nav-link" href="#">IP Check</a></li>
                      <li><a className="nav-link" href="#">Duplicate Bank Account Name</a></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item14" href="#"><NutFill size="16"/> Settings <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item14" className="submenu collapse" data-bs-parent="#nav_accordion">
                      <li><a className="nav-link" href="#">System settings</a></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item15" href="#"><PersonFill size="16"/> Admin management <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item15" data-bs-parent="#nav_accordion" className={(this.state.path==='account_management' || this.state.path==='role_management') ? 'submenu collapse show' : 'submenu collapse'}>
                      <li style={this.state.path==='account_management' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/account_management">Account management</Link></li>
                      <li style={this.state.path==='role_management' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/role_management">Role management</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item16" href="#"><AwardFill size="16"/> <span className="sidebar_text">Senior management</span> <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item16" className={(this.state.path==='senior_management' || this.state.path==='comm_setting') ? 'submenu collapse show' : 'submenu collapse'} data-bs-parent="#nav_accordion">
                      <li style={this.state.path==='senior_management' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/senior_management"><span className="sidebar_text">Senior Management</span></Link></li>
                      <li style={this.state.path==='comm_setting' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/comm_setting"><span className="sidebar_text">Commission Setting</span></Link></li>
                    </ul>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item17" href="#"><AwardFill size="16"/> <span className="sidebar_text">Master</span> <span style={{float:'right'}}><ChevronDown color="white" size="16"/></span></a>
                    <ul id="menu_item17" className={(this.state.path==='master_management') ? 'submenu collapse show' : 'submenu collapse'} data-bs-parent="#nav_accordion">
                      <li style={this.state.path==='master_management' ? {background:'#575757'} : {}}><Link className="nav-link" to="/admin/master_management"><span className="sidebar_text">Master</span></Link></li>
                    </ul>
                  </li>
                </ul>):('')}
            </div>
        </div>
    );
  }
}

export default AdminSidebar;
