import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Dot,Search } from 'react-bootstrap-icons';
import { makeRequest,timestampDate } from '../../helpers';
// const data = [
//   {
//     id: 1,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
//   {
//     id: 2,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
//   {
//     id: 3,username: "dclient02",fname: "testmex01",status: "Active",time_ip: "2021-03-13 12:01:01 223.24.185.07",
//   },
// ];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '14px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class MemberGroups extends React.Component {
  constructor(props) {
    super(props);
    this.makeRequest = makeRequest
    this.changeDeleteModal = this.changeDeleteModal.bind(this)
    this.deleteMember = this.deleteMember.bind(this)
  }
  state={
    memberGroups:[],
    showDeleteModal:false,
    delGameId:'',
    success_msg1:'',
    error_msg1:'',
    submitDisabled1:false,
  }
  changeDeleteModal(val,modal_value){
    this.setState({delGameId: val})
    this.setState({showDeleteModal: modal_value})
  }
  async deleteMember(){
    this.setState(state => ({submitDisabled1:true}));
    let result1=await this.makeRequest('member-group/'+this.state.delGameId, 'delete', '',localStorage.getItem('role_token'));
    
    if(result1.status===200){
      this.setState({
        success_msg1:'Member group deleted successfully.',error_msg1:'',submitDisabled1:false
      })
      this.setState({showDeleteModal: false})
      this.componentDidMount();
    }
    else if(result1.status===400){
      this.setState(state => ({error_msg1:result1.data.message,submitDisabled1:false}));
    }
    else if(result1.status===500)
      this.setState(state => ({error_msg1:'Some problem occured. Please try again.',submitDisabled1:false}));
   
  }
  componentDidMount() {
    (async () => {
      var result1=await this.makeRequest('member-group', 'get','','');
      if(result1.status===200){
        this.setState(state => ({memberGroups:result1.data.data}));
      }
    })();
	} 
  render() {
    const columns = [
    {
      name: 'Name',width:"100px",selector: 'username',cell: row => <div data-tag="allowRowEvents">{row.name}</div>,
    },
    {
      name: 'Birthday Bonus',width:"150px",selector: 'fname',cell: row => <div data-tag="allowRowEvents">{row.birthdayBonusValue +' '+row.birthdayBonusUnit}</div>,
    },
    {
      name: 'Deposit Bonus',width:"150px",selector: 'status',cell: row => <div data-tag="allowRowEvents">{row.depositBonusValue +' '+row.depositBonusUnit}</div>,
    },
    {
      name: 'Holiday Bonus',width:"150px",selector: 'status',cell: row => <div data-tag="allowRowEvents">{row.holidayBonusValue +' '+row.holidayBonusUnit}</div>,
    },
    {
      name: 'Loss Return',width:"150px",selector: 'status',cell: row => <div data-tag="allowRowEvents">{row.lossReturnValue +' '+row.lossReturnUnit}</div>,
    },
    {
      name: 'Turnover Requirement',width:"180px",selector: 'status',cell: row => <div data-tag="allowRowEvents">{row.turnoverRequirementValue +' '+row.turnoverRequirementUnit}</div>,
    },
    {
      name: 'Friend Invite Bonus',width:"180px",selector: 'status',cell: row => <div data-tag="allowRowEvents">{row.friendInviteBonusValue +' '+row.friendInviteBonusUnit}</div>,
    },
    {
      name: 'Created On',width:"250px",selector: 'created_on',cell: row => <div data-tag="allowRowEvents">{row.date}</div>,
    },
    {
      name: 'Action',width:"150px",selector: 'created_on',cell: row => <div data-tag="allowRowEvents"><span style={{color:"rgb(24, 144, 255)",cursor:"pointer"}}  onClick={()=>this.props.history.push('/admin/edit_member_group#'+row.key)}>Edit</span> | <span style={{color:"rgb(24, 144, 255)",cursor:"pointer"}} onClick={() => this.changeDeleteModal(row.key,true)}>Delete</span></div>,
    },
  ];
  var data = this.state.memberGroups.map(function(item) {
    return {
      key: item.id,
      name:item.name,
      birthdayBonusValue: item.birthdayBonus.value,
      birthdayBonusUnit: item.birthdayBonus.unit,
      depositBonusValue: item.depositBonus.value,
      depositBonusUnit: item.depositBonus.unit,
      holidayBonusValue: item.holidayBonus.value,
      holidayBonusUnit: item.holidayBonus.unit,
      lossReturnValue: item.lossReturn.value,
      lossReturnUnit: item.lossReturn.unit,
      turnoverRequirementValue: item.turnoverRequirement.value,
      turnoverRequirementUnit: item.turnoverRequirement.unit,
      friendInviteBonusValue: item.friendInviteBonus.value,
      friendInviteBonusUnit: item.friendInviteBonus.unit,
      date: timestampDate(item.createdAt),
    };
  });
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4',width:"100%"}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>CMS</span> / 
                    <span>Member Groups</span>
                  </div>
                  <h5 className="pg_head">Member Groups</h5>
                  <button className="btn btn-primary addbtn" onClick={()=>this.props.history.push('/admin/add_member_group')}>+ Add new member group</button>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              <div className="subbdvv1">
                {this.state.success_msg1!=='' ?
                  (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg1}</p>):('')
                }
                {this.state.error_msg1!=='' ?
                  (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg1}</p>):('')
                }
                <div className="subdvv1_2">
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*delete modal*/}
        <Modal size="md" show={this.state.showDeleteModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.changeDeleteModal('',false)}>Delete member Groups</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              <h4>Are you sure</h4>
              <p>You want to delete this member group ?</p>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.changeDeleteModal('',false)} className="del_no_btn">No</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.deleteMember} disabled={this.state.submitDisabled1}>Yes</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
      </div>
    );
  }
}

export default MemberGroups;
