import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {Dot,Search,BookmarkFill} from 'react-bootstrap-icons'
import { makeRequest,timestampDate } from '../../helpers';
const data = [
  {id: 1,username: "dclient02",fname:"testmex01",agent_type:"Cash Mode"},{id: 2,username: "dclient02",fname:"testmex01",agent_type:"Cash Mode"},{id: 3,username: "dclient02",fname:"testmex01",agent_type:"Cash Mode"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class AgentManagement extends React.Component {
	constructor(props) {
		super(props);
		this.makeRequest = makeRequest
    this.changeTab = this.changeTab.bind(this)
    this.viewUpdateStatusModal = this.viewUpdateStatusModal.bind(this)
    this.updateStatus = this.updateStatus.bind(this)
    this.getAgentRequests = this.getAgentRequests.bind(this)
	  }
	  state={
		agent_details:[],
    currentTab:'agent_mgmt',
    becomeAgentRequests:[],
    updateStatusId:'',
    showStatusModal:false,
    success_msg:'',
    error_msg:'',
    submitDisabled:false,
	  }
    changeTab(val){
        this.setState(state => ({currentTab: val}));
    }
    viewUpdateStatusModal(val,modal_value){
      this.setState({updateStatusId: val});
      this.setState({showStatusModal: modal_value});
      this.setState({success_msg: ''});
      this.setState({error_msg: ''});
    }
    async updateStatus(){
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({status: 'approved'});
      let result1=await this.makeRequest('become-agent/'+this.state.updateStatusId, 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg:'Status updated successfully.',error_msg:'',submitDisabled:false
        })
        this.getAgentRequests();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg:result1.data.message,submitDisabled:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg:'Some problem occured. Please try again.',submitDisabled:false}));
    }
    async getAgentRequests(){
      var result1=await this.makeRequest('become-agent','get','',localStorage.getItem('role_token'));
      if(result1.status===200){
        this.setState({becomeAgentRequests: result1.data.data});
      }
    }
		componentDidMount() {
			(async () => {
			  var result1=await this.makeRequest('user?role=agent','get','',localStorage.getItem('role_token'));
			  if(result1.status===200){
				this.setState({agent_details: result1.data});
			  }
			})();
      this.getAgentRequests();
		} 
  render() {
    const columns = [
        {name: 'No.',selector: 'id',width:'40px'},
        {name: 'Username',selector: 'username',width:'90px'},
        {name: 'Full Name',selector: 'fname',width:'100px'},
        {name: 'Agent Code',selector: 'agent_type',width:'110px'},
        {name: 'Agent Type',selector: 'agent_type',width:'110px'},
        {name: 'Status',cell: row => <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}}><Dot color="#FFAC2A" size={28}/>Active</div>,width:'84px'},
        {name: 'Login Permission',cell: row => <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}}><Dot color="#FFAC2A" size={28}/>Granted</div>,width:'118px'},
        {name: 'Last Login Time/Last Login IP',width:"200px",cell: row => <div data-tag="allowRowEvents"><span>2021-03-13 12:01:01</span><br/><span style={{color: '#1890ff'}}>223.24.185.07</span></div>},
        {name: 'Actions',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}><span style={{cursor:'pointer'}}>Domain</span> | <span style={{cursor:'pointer'}}>Edit Profile</span> | <span style={{cursor:'pointer'}}>Reset Password</span></div>},
    ];
    const request_data = this.state.becomeAgentRequests.map(function(item,index) {
      return {
        no:parseInt(index+1),
        key: item.id,
        request_date:timestampDate(item.createdAt),
        request_by:  item.userId,
        status: item.status,
      }
    });
    const request_columns = [
      {name: 'No.',width:"200px",selector: 'id',cell: row => <div data-tag="allowRowEvents">{row.no}</div>},
      {name: 'Request date',selector: 'username',cell: row => <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}}>{row.request_date}</div>},
      {name: 'Request by',width:"400px",selector: 'fname',cell: row => <div data-tag="allowRowEvents" style={{marginLeft: '-14px',color: '#1890ff',cursor:"pointer"}}>{row.request_by}</div>},
      {name: 'Status',selector: 'agent_type',cell: row => <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}}>{row.status==='approved' ? <Dot color="#0ee30e" size={28}/> : <Dot color="#FFAC2A" size={28}/>} {row.status}</div>},
      {name: 'Action',selector: 'action',cell: row => <div data-tag="allowRowEvents" style={{cursor:"pointer"}}>{row.status==='new' ? (<span title="Approve request" onClick={()=>this.viewUpdateStatusModal(row.key,true)}><BookmarkFill color="purple" size={18}/></span>):('')}</div>},
  ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px 0px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Agent management</span> / 
                    <span>Agent Management</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Agent Management</h5>
                  <br></br>
                  <div className="subbdvv1 sd1">
                      <ul className="tabbss transaction_tabs">
                          <li className={this.state.currentTab==='agent_mgmt' ? 'active' : ''} onClick={()=>this.changeTab('agent_mgmt')}>Agent Management</li>
                          <li className={this.state.currentTab==='agent_requests' ? 'active' : ''} onClick={()=>this.changeTab('agent_requests')}>Become agent requests</li>
                      </ul>
                  </div>
                </div>
              </div><br/>
              
              {this.state.currentTab==='agent_mgmt' &&
              <div className="divbody">
                <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <div className="row subdvv1_1 agent_management" style={{width:"100%",margin:"0"}}>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 top_div1" style={{marginTop:"24px"}}>
                          <div className="subdvv1_1_1">
                              <input type="text" className="form-control input_width_100" placeholder="Search"/>
                              <Search color="#00000040" size={15}/>
                          </div>
                      </div> 
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 top_div1">  
                        <span>Status: </span>
                        <select className="form-control input_width_100">
                            <option value="">Active</option>
                        </select>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <span>Agent Type: </span>
                        <select className="form-control input_width_100">
                            <option value="">All</option>
                        </select>
                      </div> 
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 top_div1" style={{marginTop:"24px"}}>
                        <button className="btn btn-primary filter_btn text-white" style={{background: '#1890FF'}} onClick={()=>this.props.history.push('/admin/create_agent')}>Create</button>
                      </div>  
                    </div><br/>
                    <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>}

              {this.state.currentTab==='agent_requests' &&
              <div className="divbody">
                <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <DataTable title="" columns={request_columns} pagination data={request_data} customStyles={customStyles}/>
                </div>
              </div>}

            </div>
          </div>
        </div>
        {/*status modal*/}
        <Modal size="md" show={this.state.showStatusModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewUpdateStatusModal('',false)}>Update status</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              {this.state.success_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg}</p>):('')
              }
              {this.state.error_msg!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg}</p>):('')
              }
              <select className="form-control">
                  <option value="approved">approved</option>
              </select>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewUpdateStatusModal('',false)} className="del_no_btn">Cancel</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateStatus} disabled={this.state.submitDisabled}>Update</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
      </div>
    );
  }
}
export default AgentManagement;