import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
class UpdateOperatorProfile extends React.Component {
  render() {
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Admin Management</span> / 
                    <span>Update Operator Profile</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Update Operator Profile</h5>
                    <div style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}><Link to="/admin/account_management" style={{textDecoration:"none",color:"white"}}>Back to Account Management</Link></button>
                    </div>
                </div>
              </div><br/>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12 col-xs-12 divbody">
                <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                    <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Operator Profile</h5>
                  </div>
                <div className="row" style={{margin:0,padding:'12px 14px 48px',background:'white'}}>
                
                    <div className="col-md-12 top_div2">
                        <label className="labls"><span style={{fontWeight:"700"}}>Username:</span>
                        <input type="text" className="form-control sms_blast"/></label>
                    </div>
                    <div className="col-md-12 top_div2">
                        <label className="labls"><span style={{fontWeight:"700"}}>Full Name:</span>
                        <input type="text" className="form-control sms_blast"/></label>
                    </div>
                    <div className="col-md-12 top_div2">
                        <label className="labls"><span style={{fontWeight:"700"}}>Email:</span>
                        <input type="text" className="form-control sms_blast"/></label>
                    </div>
                    <div className="col-md-12 top_div2">
                        <label className="labls"><span style={{fontWeight:"700"}}>Role:</span>
                        <select className="form-control sms_blast" style={{width:"100%"}}>
                            <option>Amount</option>
                        </select></label>
                    </div>
                    <br/>
                    <div style={{marginTop:"28px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Update Profile</button>
                    </div>
                </div><br/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UpdateOperatorProfile;