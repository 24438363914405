import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {Search,Trash,PersonFill,BookmarkFill,ClipboardCheck} from 'react-bootstrap-icons'
import { makeRequest,timestampDate } from '../../helpers';
// const data = [
//   {id: 1,account_id: "ID0001CL001",account_name:"Master Name 01",senior:"Senior01",senior_comm:"10%",contact:"1234567890",email:"democlient02@gmail.com",registeration:"Lorem ipsum",url:"https://gbobet88.com"},
//   {id: 2,account_id: "ID0001CL001",account_name:"Master Name 01",senior:"Senior01",senior_comm:"10%",contact:"1234567890",email:"democlient02@gmail.com",registeration:"Lorem ipsum",url:"https://gbobet88.com"},{id: 3,account_id: "ID0001CL001",account_name:"Master Name 01",senior:"Senior01",senior_comm:"10%",contact:"1234567890",email:"democlient02@gmail.com",registeration:"Lorem ipsum",url:"https://gbobet88.com"},{id: 4,account_id: "ID0001CL001",account_name:"Master Name 01",senior:"Senior01",senior_comm:"10%",contact:"1234567890",email:"democlient02@gmail.com",registeration:"Lorem ipsum",url:"https://gbobet88.com"},{id: 5,account_id: "ID0001CL001",account_name:"Master Name 01",senior:"Senior01",senior_comm:"10%",contact:"1234567890",email:"democlient02@gmail.com",registeration:"Lorem ipsum",url:"https://gbobet88.com"},{id: 6,account_id: "ID0001CL001",account_name:"Master Name 01",senior:"Senior01",senior_comm:"10%",contact:"1234567890",email:"democlient02@gmail.com",registeration:"Lorem ipsum",url:"https://gbobet88.com"},
// ];
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '13px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class MasterManagement extends React.Component {
	constructor(props) {
		super(props);
		this.makeRequest = makeRequest
		this.searchMaster = this.searchMaster.bind(this)
    this.viewDeleteModal = this.viewDeleteModal.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.getUser = this.getUser.bind(this)
    this.viewUserStatusModal = this.viewUserStatusModal.bind(this)
    this.changeUserStatus = this.changeUserStatus.bind(this)
    this.updateUserStatus = this.updateUserStatus.bind(this)
    this.changeUserRole = this.changeUserRole.bind(this)
    this.changeAssignMemberGroup = this.changeAssignMemberGroup.bind(this)
    this.updateMemberGroup = this.updateMemberGroup.bind(this)
    this.updateUserRole = this.updateUserRole.bind(this)
	  }
	  state={
		master_details:[],
    memberGroup:[],
    showDeleteModal:false,
    delGameId:'',
    submitDisabled1:false,
    error_msg1:'',
    success_msg1:'',
    error_msg2:'',
    success_msg2:'',
    error_msg3:'',
    success_msg3:'',
    error_msg4:'',
    success_msg4:'',
    userStatusId:'',
    roleId:'',
    assignId:'',
    showRoleModal:false,
    showMemberModal:false,
    showStatusModal:false,
    userStatus:'',
    userRole:'',
    assignMemberGroup:'',
	  }
    viewDeleteModal(val,modal_value){
      this.setState({delGameId: val})
      this.setState({showDeleteModal: modal_value})
    }
    viewUserStatusModal(val,modal_value){
      this.setState({
        success_msg2:'',error_msg2:'',submitDisabled1:false
      })
      this.setState({userStatusId: val})
      this.setState({showStatusModal: modal_value})
    }
    viewAssignMemberModal(val,modal_value){
      this.setState({
        success_msg4:'',error_msg4:'',submitDisabled1:false
      })
      this.setState({assignId: val})
      this.setState({showMemberModal: modal_value})
    }
    viewUserRoleModal(val,modal_value){
      this.setState({
        success_msg3:'',error_msg3:'',submitDisabled1:false
      })
      this.setState({roleId: val})
      this.setState({showRoleModal: modal_value})
    }
    changeUserStatus(e){
      this.setState({userStatus: e.target.value})
    }
    changeUserRole(e){
      this.setState({userRole: e.target.value})
    }
    changeAssignMemberGroup(e){
      this.setState({assignMemberGroup: e.target.value})
    }
    async deleteUser(){
    this.setState(state => ({submitDisabled1:true}));
    let result1=await this.makeRequest('user/'+this.state.delGameId, 'delete', '',localStorage.getItem('role_token'));
    
    if(result1.status===200){
      this.setState({
        success_msg1:'Member deleted successfully.',error_msg1:'',submitDisabled1:false
      })
      this.setState({showDeleteModal: false})
      this.getUser();
    }
    else if(result1.status===400){
      this.setState(state => ({error_msg1:result1.data.message,submitDisabled1:false}));
    }
    else if(result1.status===500)
      this.setState(state => ({error_msg1:'Some problem occured. Please try again.',submitDisabled1:false}));
   
  }
  async updateUserStatus(){
    if(this.state.userStatus===''){
      this.setState(state => ({error_msg2:'Please choose status.'}));
    }else{
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({status: this.state.userStatus});
      let result1=await this.makeRequest('user/'+this.state.userStatusId+'/status', 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg2:'Status updated successfully.',error_msg2:'',submitDisabled1:false
        })
        this.getUser();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg2:result1.data.message,submitDisabled1:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg2:'Some problem occured. Please try again.',submitDisabled1:false}));
    }
  }
  async updateMemberGroup(){
    if(this.state.assignMemberGroup===''){
      this.setState(state => ({error_msg4:'Please choose member group.'}));
    }else{
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({groupId: this.state.assignMemberGroup});
      let result1=await this.makeRequest('user/'+this.state.assignId+'/member-group', 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg4:'Member group assigned successfully.',error_msg4:'',submitDisabled1:false
        })
        this.getUser();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg4:result1.data.message,submitDisabled1:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg4:'Some problem occured. Please try again.',submitDisabled1:false}));
    }
  }
  async updateUserRole(){
    if(this.state.userRole===''){
      this.setState(state => ({error_msg3:'Please choose role.'}));
    }else{
      this.setState(state => ({submitDisabled1:true}));
      let data = JSON.stringify({role: this.state.userRole});
      let result1=await this.makeRequest('user/'+this.state.roleId+'/role', 'put', data,localStorage.getItem('role_token'));
      
      if(result1.status===200){
        this.setState({
          success_msg3:'Status updated successfully.',error_msg3:'',submitDisabled1:false
        })
        this.getUser();
      }
      else if(result1.status===400){
        this.setState(state => ({error_msg3:result1.data.message,submitDisabled1:false}));
      }
      else if(result1.status===500)
        this.setState(state => ({error_msg3:'Some problem occured. Please try again.',submitDisabled1:false}));
    }
  }
  async getUser(){
    var result1=await this.makeRequest('user?role=master','get','',localStorage.getItem('role_token'));
    if(result1.status===200){
      this.setState({master_details: result1.data.data});
    }
  }
    async searchMaster(e){
        if(e.target.value!==''){
        let result1=await this.makeRequest('user?role=master&referrer='+e.target.value, 'get','',localStorage.getItem('role_token'));
        if(result1.status===200){
          this.setState(state => ({master_details:result1.data}));
        }
      }else{
        this.getUser();
      }
    }
		componentDidMount() {
			(async () => {
			  var result1=await this.makeRequest('user?role=master','get','',localStorage.getItem('role_token'));
			  if(result1.status===200){
				this.setState({master_details: result1.data.data});
			  }
			})();
      (async () => {
        var result1=await this.makeRequest('member-group', 'get','','');
        if(result1.status===200){
          this.setState({memberGroup: result1.data.data});
        }
      })();
		} 
  render() {
	var data = this.state.master_details.map(function(item,index) {
		return {
      no:parseInt(index)+1,
			key: item.id,
			phone: item.phone,
			email: item.email,
			senior_name: (JSON.parse(item.referrer)).fullname,
			reg: timestampDate(item.createdAt),
		};
	});
    const columns = [
        {name: 'No.',width:"40px",selector: 'id',width:'50px',cell: row => <div data-tag="allowRowEvents" >{row.no}</div>},
        {name: 'Account ID',width:"180px",selector: 'account_id',cell: row => <div data-tag="allowRowEvents">{row.key}</div>},
        {name: 'Account Name',width:"130px",selector: 'account_name'},
        {name: 'Senior',width:"120px",selector: 'senior',cell: row => <div data-tag="allowRowEvents">{row.senior_name}</div>},
        {name: 'Senior Commission',width:"160px",selector: 'senior_comm'},
        {name: 'Contact',width:"120px",selector: 'contact',cell: row => <div data-tag="allowRowEvents">{row.phone}</div>},
        {name: 'Email',width:"220px",selector: 'email',cell: row => <div data-tag="allowRowEvents" >{row.email}</div>},
        {name: 'Registeration',width:"190px",selector: 'registeration',cell: row => <div data-tag="allowRowEvents">{row.reg}</div>},
        {name: 'Url',width:"200px",selector: 'url',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>https://gbobet88.com</div>},
        {name: 'Action',width:"130px",selector: 'total_balance',cell: row => 
        <div data-tag="allowRowEvents" style={{marginLeft: '-14px'}} className="table_action_icons">
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Update user's role" onClick={() => this.viewUserRoleModal(row.key,true)}><PersonFill color="#0a58ca" size={20}/></span>
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Assign member group for user" onClick={() => this.viewAssignMemberModal(row.key,true)}><ClipboardCheck color="#FFAC2A" size={18}/></span>
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Update user status" onClick={() => this.viewUserStatusModal(row.key,true)}><BookmarkFill color="purple" size={18}/></span>
          <span style={{cursor:"pointer",color: '#1890ff'}} title="Delete user" onClick={() => this.viewDeleteModal(row.key,true)}><Trash color="red" size={18}/></span>
        </div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; xx</span> / 
                    <span>xx</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Master Management</h5>
                </div>
              </div>

                <div className="divbody">
                  <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                    <div className="row master">
                      <div className="col-lg-10 col-md-8 col-sm-6 col-xs-6">
                        <span className="subdvv1_1_1 search">
                            <input type="text" className="form-control" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}} placeholder="Search name or id"/>
                            <Search color="#00000040" style={{top:'4px'}} size={15}/>
                        </span> 
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6">
                        <div style={{float:'right',marginBottom:'10px'}}>
                            <button className="btn btn-primary add_btn" style={{width:'auto'}}><Link to="/admin/create_master" style={{textDecoration:"none",color:"white"}}>Create</Link></button>
                        </div>
                      </div>
                    </div>
                    {this.state.success_msg1!=='' ?
                      (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg1}</p>):('')
                    }
                    {this.state.error_msg1!=='' ?
                      (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg1}</p>):('')
                    }
                    <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                  </div>
                </div>
            </div>
          </div>
        </div>
        {/*delete modal*/}
        <Modal size="md" show={this.state.showDeleteModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewDeleteModal('',false)}>Delete member</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              <h4>Are you sure</h4>
              <p>You want to delete this member ?</p>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewDeleteModal('',false)} className="del_no_btn">No</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.deleteUser} disabled={this.state.submitDisabled}>Yes</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
        {/*status modal*/}
        <Modal size="md" show={this.state.showStatusModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewUserStatusModal('',false)}>Update status</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              {this.state.success_msg2!=='' ?
                (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg2}</p>):('')
              }
              {this.state.error_msg2!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg2}</p>):('')
              }
              <select className="form-control" onChange={this.changeUserStatus} value={this.state.userStatus}>
                  <option value="">Choose status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="blocked">Block</option>
              </select>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewUserStatusModal('',false)} className="del_no_btn">Cancel</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateUserStatus} disabled={this.state.submitDisabled}>Update</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
        {/*role modal*/}
        <Modal size="md" show={this.state.showRoleModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewUserRoleModal('',false)}>Update role</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
              {this.state.success_msg3!=='' ?
                (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg3}</p>):('')
              }
              {this.state.error_msg3!=='' ?
                (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg3}</p>):('')
              }
              <select className="form-control" onChange={this.changeUserRole} value={this.state.userRole}>
                  <option value="">Choose role</option>
                  <option value="user">User</option>
                  <option value="senior">Senior</option>
                  <option value="master">Master</option>
                  <option value="agent">Agent</option>
              </select>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewUserRoleModal('',false)} className="del_no_btn">Cancel</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateUserRole} disabled={this.state.submitDisabled}>Update</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
        {/*member modal*/}
        <Modal size="md" show={this.state.showMemberModal} className="pwd_changed_modal bank_account_modal wallet_modal admin_pwd_modal delete_modal">
          <Modal.Header style={{background:'none'}} closeButton onClick={() => this.viewAssignMemberModal('',false)}>Assign member group</Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
            {this.state.success_msg4!=='' ?
              (<p style={{fontSize: '16px',color: 'green',textAlign:"center"}}>{this.state.success_msg4}</p>):('')
            }
            {this.state.error_msg4!=='' ?
              (<p style={{fontSize: '16px',color: 'red',margin:0,textAlign:"center"}}>{this.state.error_msg4}</p>):('')
            }
            <select className="form-control" onChange={this.changeAssignMemberGroup} value={this.state.assignMemberGroup}>
                <option value="">Choose member group</option>
                {this.state.memberGroup && this.state.memberGroup.map(data => (
                  <option value={data.id} key={data.id}>{data.name}</option>
                ))}
            </select>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button onClick={() => this.viewAssignMemberModal('',false)} className="del_no_btn">Cancel</button>
              <button className="btn btn-primary" style={{width:'auto',background:"#1890ff"}} onClick={this.updateMemberGroup} disabled={this.state.submitDisabled}>Assign</button>
            </div>
          </Modal.Footer><br/>
        </Modal>
      </div>
    );
  }
}
export default MasterManagement;