import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
import { CSVLink } from "react-csv";
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,trans_id: "99123",date:"2021-03-08 12:23:15",account_id:"Name ID",details:"Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 2,trans_id: "99123",date:"2021-03-08 12:23:15",account_id:"Name ID",details:"Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 3,trans_id: "99123",date:"2021-03-08 12:23:15",account_id:"Name ID",details:"Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 4,trans_id: "99123",date:"2021-03-08 12:23:15",account_id:"Name ID",details:"Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 5,trans_id: "99123",date:"2021-03-08 12:23:15",account_id:"Name ID",details:"Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 6,trans_id: "99123",date:"2021-03-08 12:23:15",account_id:"Name ID",details:"Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 7,trans_id: "99123",date:"2021-03-08 12:23:15",account_id:"Name ID",details:"Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 8,trans_id: "99123",date:"2021-03-08 12:23:15",account_id:"Name ID",details:"Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 9,trans_id: "99123",date:"2021-03-08 12:23:15",account_id:"Name ID",details:"Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
  {id: 10,trans_id: "99123",date:"2021-03-08 12:23:15",account_id:"Name ID",details:"Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414",turnover:"123.00",bet:"123.00",payout:"3.00",win_lose:"-30.00",prog_share:"0.00",commission:"0.00",company_pl:"0.50",percent:"80.00"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const excelData = [
  {
    "Trans. ID": "99123",
    "Date": "2021-03-08 12:23:15",
    "Account ID": 'NameID',
    "Details": 'Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414',
    "Turnover": '123.00',
    "Bet": '123.00',
    "Payout": 3.00,
    "Win/Lose": '-30.00',
    "Prog. Share": 0.00,
    "Commission": '0.00',
    "Company P/L": '0.50',
    "%": '80.00'
  },
  {
    "Trans. ID": "99123",
    "Date": "2021-03-08 12:23:15",
    "Account ID": 'NameID',
    "Details": 'Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414',
    "Turnover": '123.00',
    "Bet": '123.00',
    "Payout": 3.00,
    "Win/Lose": '-30.00',
    "Prog. Share": 0.00,
    "Commission": '0.00',
    "Company P/L": '0.50',
    "%": '80.00'
  },
  {
    "Trans. ID": "99123",
    "Date": "2021-03-08 12:23:15",
    "Account ID": 'NameID',
    "Details": 'Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414',
    "Turnover": '123.00',
    "Bet": '123.00',
    "Payout": 3.00,
    "Win/Lose": '-30.00',
    "Prog. Share": 0.00,
    "Commission": '0.00',
    "Company P/L": '0.50',
    "%": '80.00'
  },
  {
    "Trans. ID": "99123",
    "Date": "2021-03-08 12:23:15",
    "Account ID": 'NameID',
    "Details": 'Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414',
    "Turnover": '123.00',
    "Bet": '123.00',
    "Payout": 3.00,
    "Win/Lose": '-30.00',
    "Prog. Share": 0.00,
    "Commission": '0.00',
    "Company P/L": '0.50',
    "%": '80.00'
  }
]
const headers = [
  { label: "Trans. ID", key: "trans_id" },
  { label: "Date", key: "date" },
  { label: "Account ID", key: "account_id" },
  { label: "Details", key: "details" },
  { label: "Turnover", key: "turnover" },
  { label: "Bet", key: "bet" },
  { label: "Payout", key: "payout" },
  { label: "Win/Lose", key: "win_lose" },
  { label: "Prog. Share", key: "prog_share" },
  { label: "Commission", key: "commission" },
  { label: "Company P/L", key: "company_pl" },
  { label: "%", key: "percent" },
];
class DetailedWinLossReport extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.printData = this.printData.bind(this)
    this.exportData = this.exportData.bind(this)
    }
    printData(){
      window.print();
    }
    exportData(){
      exportFromJSON({ data: excelData, fileName: 'Bonus Adjustment', exportType: exportFromJSON.types.xls})
    }
  render() {
    const columns = [
      {name: 'No.',selector: 'id',width:'50px',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Trans. ID',selector: 'trans_id',width:'70px',cell: row => <div data-tag="allowRowEvents" style={{fontWeight:"900"}}>99123</div>},
      {name: 'Date',selector: 'date',width:'100px',cell: row => <div data-tag="allowRowEvents">2021-03-08 12:23:15</div>},
      {name: 'Account ID',selector: 'account_id',width:'80px',cell: row => <div data-tag="allowRowEvents">NameID</div>},
      {name: 'Details',selector: 'details',width:'192px',cell: row => <div data-tag="allowRowEvents">Details Here - Esport - 1234567 : 122434 - Name code 123243245452523 213213434134132421434414</div>},
      {name: 'Turnover',selector: 'turnover',width:'90px',cell: row => <div data-tag="allowRowEvents">123.00</div>},
      {name: 'Bet',selector: 'bet',width:'90px',cell: row => <div data-tag="allowRowEvents">123.00</div>},
      {name: 'Payout',selector: 'payout',width:'70px',cell: row => <div data-tag="allowRowEvents">3.00</div>},
      {name: 'Win/Lose',selector: 'win_lose',width:'70px',cell: row => <div data-tag="allowRowEvents">-30.00</div>},
      {name: 'Prog. Share',width:'100px',selector: 'prog_share',cell: row => <div data-tag="allowRowEvents">0.00</div>},
      {name: 'Commission',width:'100px',selector: 'commission',cell: row => <div data-tag="allowRowEvents">0.00</div>},
      {name: 'Company P/L',width:'120px',selector: 'company_pl',cell: row => <div data-tag="allowRowEvents">0.50</div>},
      {name: '%',selector: 'percent',cell: row => <div data-tag="allowRowEvents">80.00</div>},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}} className="print_hide">
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Report</span> / 
                    <span>Detailed Win/Lose Report</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Detailed Win / Lose Report</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="subbdvv1 print_hide" style={{padding: '14px 24px 0px 24px'}}>
                    <h6 style={{fontWeight:700,margin:0}}>Search Criteria <span style={{fontSize:"10px",fontWeight:"normal",marginLeft:"12px",color:"#6c757d"}}>* Maximum search data range without account ID is 1 day, with account ID is 7 days.</span></h6>
                  </div>
                <div className="row print_hide" style={{margin:0,padding:'12px 14px',background:'white'}}>
                
                    <div className="col-md-6 top_div">
                        <label className="labls labls1"><span>Date Range:</span></label>
                        <input type="date" className="form-control"/>
                    </div>
                    <div className="col-md-6 top_div">    
                        <label className="labls labls1"><span>Product:</span></label>
                        <select className="form-control select_size">
                                <option className="select_size">Select</option>
                        </select>
                    </div> 
                    <div className="col-md-6 top_div">   
                        <label className="labls labls1"><span>Product Type:</span></label>
                        <select className="form-control select_size">
                                <option className="select_size">All</option>
                        </select>
                    </div>
                    <div className="col-md-6 top_div">
                        <label className="labls labls1"><span>Agent Code:</span></label>
                        <input type="text" className="form-control"/>
                    </div>
                    <div className="col-md-6 top_div">   
                        <label className="labls labls1"><span>Status:</span></label>
                        <select className="form-control select_size">
                                <option className="select_size">All</option>
                        </select>
                    </div>
                    <div className="col-md-6 top_div">
                        <label className="labls labls1"><span>Account Id:</span></label>
                        <input type="text" className="form-control"/>
                    </div>
                    <br/>
                    <div className="top_second_div report_btns" style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Member</button>
                        <button className="btn btn-primary add_btn cancel_btn">Agent</button>
                        <button className="btn btn-primary add_btn cancel_btn">Product</button>
                        <button className="btn btn-primary add_btn cancel_btn">Type</button>
                        <button className="btn btn-primary add_btn cancel_btn">Reset</button>
                    </div>
                
                </div><br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <span className="subdvv1_1_1 win_report_btns print_hide">
                <label className="labls labls1"><span style={{minWidth:"48px"}}>Show:</span>
                    <select className="form-control" style={{width:"70px"}}>
                            <option>50</option>
                    </select><span style={{minWidth:"10px",marginLeft:"12px"}}>Entries:</span></label>
                    <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                    <CSVLink data={data} headers={headers} className="btn btn-primary add_btn cancel_btn">CSV</CSVLink>;
                    <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                </span>
                <div className="search_report print_hide" style={{float:'right',marginBottom:'10px'}}>
                <label className="labls labls1"><span style={{minWidth:"56px"}}>Search:</span><input type="text" className="form-control" style={{display: 'inline-block',width: '200px',height: '31px',fontSize: '14px'}}/></label>
                </div>
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DetailedWinLossReport;