import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
import { CSVLink } from "react-csv";
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,username: "ag1",adjust_in:"50.00",adjust_out:"0.00",total_deposit:"1210.00",total_withdrawal:"630.00",total_promotion:"70.00",total_rebate:"0.50"},
  {id: 2,username: "ag1",adjust_in:"50.00",adjust_out:"0.00",total_deposit:"1210.00",total_withdrawal:"630.00",total_promotion:"70.00",total_rebate:"0.50"},
  {id: 3,username: "ag1",adjust_in:"50.00",adjust_out:"0.00",total_deposit:"1210.00",total_withdrawal:"630.00",total_promotion:"70.00",total_rebate:"0.50"},
  {id: 4,username: "ag1",adjust_in:"50.00",adjust_out:"0.00",total_deposit:"1210.00",total_withdrawal:"630.00",total_promotion:"70.00",total_rebate:"0.50"},
  {id: 5,username: "ag1",adjust_in:"50.00",adjust_out:"0.00",total_deposit:"1210.00",total_withdrawal:"630.00",total_promotion:"70.00",total_rebate:"0.50"},
  {id: 6,username: "ag1",adjust_in:"50.00",adjust_out:"0.00",total_deposit:"1210.00",total_withdrawal:"630.00",total_promotion:"70.00",total_rebate:"0.50"},
];
const headers = [
  { label: "Username", key: "username" },
  { label: "Total Adjustment (IN)", key: "adjust_in" },
  { label: "Total Adjustment (OUT)", key: "adjust_out" },
  { label: "Total Deposit", key: "total_deposit" },
  { label: "Total Withdrawal", key: "total_withdrawal" },
  { label: "Total Promotion", key: "total_promotion" },
  { label: "Total Rebate", key: "total_rebate" },
];
const excelData = [
  {
      "Username": "2021-02-26 14:53:06",
      "Total Adjustment (IN)": "50.00",
      "Total Adjustment (OUT)": 0.00,
      "Total Deposit": 1210.00,
      "Total Withdrawal": '630.00',
      "Total Promotion": 70.00,
      "Total Rebate": 0.50,
  },
  {
    "Username": "2021-02-26 14:53:06",
      "Total Adjustment (IN)": "50.00",
      "Total Adjustment (OUT)": 0.00,
      "Total Deposit": 1210.00,
      "Total Withdrawal": '630.00',
      "Total Promotion": 70.00,
      "Total Rebate": 0.50,
  },
  {
    "Username": "2021-02-26 14:53:06",
      "Total Adjustment (IN)": "50.00",
      "Total Adjustment (OUT)": 0.00,
      "Total Deposit": 1210.00,
      "Total Withdrawal": '630.00',
      "Total Promotion": 70.00,
      "Total Rebate": 0.50,
  },
  {
    "Username": "2021-02-26 14:53:06",
      "Total Adjustment (IN)": "50.00",
      "Total Adjustment (OUT)": 0.00,
      "Total Deposit": 1210.00,
      "Total Withdrawal": '630.00',
      "Total Promotion": 70.00,
      "Total Rebate": 0.50,
  }
]
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class TransactionReport extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.printData = this.printData.bind(this)
    this.exportData = this.exportData.bind(this)
    }
    printData(){
      window.print();
    }
    exportData(){
      exportFromJSON({ data: excelData, fileName: 'Transaction report', exportType: exportFromJSON.types.xls})
    }
  render() {
    const columns = [
      {name: 'No.',width:'80px',selector: 'id',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Username',width:'120px',selector: 'username',cell: row => <div data-tag="allowRowEvents">ag1</div>},
      {name: 'Total Adjustment (IN)',width:'150px',selector: 'adjust_in',cell: row => <div data-tag="allowRowEvents">50.00</div>},
      {name: 'Total Adjustment (OUT)',width:'180px',selector: 'adjust_out',cell: row => <div data-tag="allowRowEvents">0.00</div>},
      {name: 'Total Deposit',width:'110px',selector: 'total_deposit',cell: row => <div data-tag="allowRowEvents">1210.00</div>},
      {name: 'Total Withdrawal',width:'150px',selector: 'total_withdrawal',cell: row => <div data-tag="allowRowEvents">630.00</div>},
      {name: 'Total Promotion',width:'150px',selector: 'total_promotion',cell: row => <div data-tag="allowRowEvents">70.00</div>},
      {name: 'Total Rebate',selector: 'total_rebate',cell: row => <div data-tag="allowRowEvents">0.50</div>}
    ];
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}} className="print_hide">
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Transactions</span> / 
                    <span>Transaction Report</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Transaction Report</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="row bonus_adj print_hide" style={{margin:0,padding:'12px 14px',background:'white'}}>
                
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"82px"}}>Date Range:</span>
                        <input type="date" className="form-control" style={{height:"32px",fontSize:"14px"}} /></label>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"80px"}}>Agent Code:</span>
                        <input type="text" className="form-control" style={{height:"32px",fontSize:"14px"}} /></label>
                    </div>
                    <div className="col-lg-3 col-md-9 col-sm-10 col-xs-12 top_div1">
                        <label className="labls labls2"><span style={{minWidth:"74px"}}>Account ID:</span>
                        <input type="text" className="form-control" style={{height:"32px",fontSize:"14px"}} /></label>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-2 col-xs-12 top_div1">
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Search</button>
                    </div>
                
                </div><br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px 56px',background:'white'}}>
                  <div className="row print_hide">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <span className="subdvv1_1_1 mem_stat_left">
                          <input type="text" className="form-control" style={{display: 'inline-block',height: '31px',fontSize: '14px'}} placeholder="Search"/>
                          <Search color="#00000040" style={{top:'4px'}} size={15}/>
                      </span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                      <div className="mem_stat_btns" style={{float:'right',marginBottom:'10px'}}>
                          <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                          <CSVLink data={data} headers={headers} className="btn btn-primary add_btn cancel_btn">CSV</CSVLink>;
                        <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                      </div>
                    </div>
                  </div>
                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TransactionReport;