import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
//import { Lock,Phone,CheckSquare,CaretDownFill,StopwatchFill } from 'react-bootstrap-icons';

class SendMessage extends React.Component {
  render() {
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Message center</span> / 
                    <span>Send Message</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Send Message</h5>
                </div>
              </div><br/>
            </div>


            <div className="divbody">
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-2">
                   <span className="checkspan">
                       <input type="checkbox"/> <span>Users: </span>
                   </span>
                </div>
                <div className="col-md-10">
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                    </div>
                </div>
              </div>
            </div>


            {/*<div className="divbody">
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-2">
                   <span className="checkspan">
                       <input type="checkbox"/> <span>AgentGroup: </span>
                   </span>
                </div>
                <div className="col-md-10">
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                    </div><br/>
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                    </div><br/>
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                    </div>
                </div>
              </div>
              <div className="dashdbordr"></div>
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-2">
                   <span className="checkspan">
                       <input type="checkbox"/> <span>Member Group: </span>
                   </span>
                </div>
                <div className="col-md-10">
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                    </div>
                </div>
              </div>
              <div className="dashdbordr"></div>
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-2">
                   <span className="checkspan">
                       <input type="checkbox"/> <span>Member ID: </span>
                   </span>
                </div>
                <div className="col-md-10">
                    <div className="persnl_setngs_div chg_pwd_div">
                        <input type="text" className="form-control"/>
                    </div>
                </div>
                <div className="col-md-6">
                    <br/>
                    <div className="informm">
                        <span>- 100 maximum username can be keyed in</span><br/>
                        <span>- add multiple username separate by comma (,) eg. michael, michelle</span>
                    </div>
                </div>
              </div>
            </div>*/}
            
            <div className="divbody">
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-12">
                    <div className="add_form_div persnl_setngs_div chg_pwd_div">
                      <span>Title：</span>
                      <input defaultValue="cdemo02" type="text" className="form-control"/>
                    </div><br/>
                    <div className="add_form_div persnl_setngs_div chg_pwd_div">
                      <span>Content: </span>
                      <textarea rows="3" defaultValue="cdemo02" className="form-control"/>
                    </div><br/>
                </div>
              </div>
            </div>

            <div style={{background: 'white',padding: '12px 24px'}}>
                <button className="btn btn-primary add_btn" style={{width:'auto'}}>Send message</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SendMessage;
