import React from "react";
import {Link} from 'react-router-dom';
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import { Dot } from 'react-bootstrap-icons';
class TransactionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.changeTab = this.changeTab.bind(this)
    }
    state= {
        currentTab:'withdrawal'
    }
    changeTab(event){
        this.setState(state => ({currentTab: event.target.value}));
    }
  render() {
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Transactions</span> / 
                    <span>Transaction Details - 
                        {this.state.currentTab=='withdrawal' && ('Withdrawal')} 
                        {this.state.currentTab=='deposit' && ('Deposit')}
                        {this.state.currentTab=='adjustment' && 'Deposit (Adjustment in)'}
                        {this.state.currentTab=='rebate' && 'Deposit (Rebate)'}
                    </span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Transaction Details - {this.state.currentTab=='withdrawal' && ('Withdrawal')} 
                  {this.state.currentTab=='deposit' && ('Deposit')}
                  {this.state.currentTab=='adjustment' && 'Deposit (Adjustment in)'}
                  {this.state.currentTab=='rebate' && 'Deposit (Rebate)'}
                  </h5>
                    <div style={{marginTop:"20px",paddingLeft:"0"}}>
                        <button className="btn btn-primary add_btn reset_black_btn" style={{width:'auto'}}><Link to="/admin/transaction_historyy" style={{textDecoration:"none",color:"white"}}>Back</Link></button>
                        <button className="btn btn-primary add_btn" style={{width:'auto',marginLeft:"20px"}}>Super 168</button>
                    </div>
                    <div style={{float:"right",marginTop:"-54px"}}>
                        <select className="form-control sms_blast" onChange={this.changeTab}>
                            <option value="withdrawal">Withdrawal</option>
                            <option value="deposit">Deposit</option>
                            <option value="adjustment">Adjustment In.</option>
                            <option value="rebate">Rebate</option>
                        </select>
                    </div>
                </div>
              </div><br/>
            </div>
            {this.state.currentTab=='withdrawal' &&
            <div>
                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Transaction Details</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>W. Transaction ID:</span>
                            <input type="text" className="form-control sms_blast" placeholder="11666"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Refrence Code:</span>
                            <input type="text" className="form-control sms_blast"  placeholder="--"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>W. Transaction Date:</span>
                            <input type="date" className="form-control sms_blast"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Turnover Requirement:</span>
                            <input type="text" className="form-control sms_blast"  placeholder="SGD 100.00"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>W. Fund Method:</span>
                            <input type="date" className="form-control sms_blast" placeholder="SGD 110.00"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Current Rollover:</span>
                            <p><Dot color="red" size={30}/> SGD 0.00</p></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>W. Fund Method:</span>
                            <input type="date" className="form-control sms_blast" placeholder="Withdrawal"/></label><br/><br/>
                            <label className="labls"><span style={{fontWeight:"700"}}>Deposit Account:</span>
                            <p><Dot color="orange" size={30}/> New Request</p></label>
                        </div>
                    </div>
                </div>

                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Member Bank Details</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Bank Name:</span>
                            <input type="text" className="form-control sms_blast" placeholder="MAYBANK"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Bank Account Name:</span>
                            <input type="text" className="form-control sms_blast" placeholder="fsdfdsf"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Bank Account No.:</span>
                            <input type="text" className="form-control sms_blast" placeholder="1234567890"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Bank Account Name:</span>
                            <input type="text" className="form-control sms_blast" placeholder="bsn bank"/></label>
                        </div>
                    </div>
                </div>

                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Withdrawal Details</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Bank Name:</span>
                            <select className="form-control sms_blast">
                                <option>Select</option>
                            </select></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Withdrawal Channel:</span>
                            <input type="text" className="form-control sms_blast" placeholder="sp_support"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Bank Account Name:</span>
                            <select className="form-control sms_blast">
                                <option>Select</option>
                            </select></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Bank Account No.:</span>
                            <input type="text" className="form-control sms_blast" placeholder="sp_support"/></label>
                        </div>
                    </div>
                </div>

                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Others</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Fees (SGD):</span>
                            <input type="text" className="form-control sms_blast" placeholder="0.00"/></label><br/><br/>
                            <label className="labls"><span>Remark:</span>
                            <textarea className="form-control sms_blast" placeholder="asd"/></label>
                        </div>
                    </div>
                </div>

            
                <div style={{background: 'white'}}>
                    <div style={{padding: '10px 20px 20px'}}>
                        <div style={{marginTop:"10px",paddingLeft:"0"}}>
                            <button className="btn btn-primary add_btn" style={{width:'auto',marginLeft:"20px"}}>Acknowledge</button>
                            <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto',marginLeft:"20px",color:"red"}}>Reject</button>
                        </div>
                    </div>
                </div>
            </div>}

            {this.state.currentTab=='deposit' &&
            <div>
                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Transaction Details</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Transaction ID:</span>
                            <input type="text" className="form-control sms_blast" placeholder="11666"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Deposit Date:</span>
                            <input type="date" className="form-control sms_blast"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Transaction Date:</span>
                            <input type="date" className="form-control sms_blast"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Deposit Channel:</span>
                            <select className="form-control sms_blast">
                                <option>--</option>
                            </select></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Deposit Amount:</span>
                            <input type="text" className="form-control sms_blast" placeholder="SGD 110.00"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Approved Amount:</span>
                            <input type="text" className="form-control sms_blast" placeholder="SGD 110.00"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Fund Method:</span>
                            <input type="text" className="form-control sms_blast" placeholder="Deposit"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Reference Code:</span>
                            <input type="text" className="form-control sms_blast" placeholder="Deposit"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span style={{fontWeight:"700"}}>Status:</span>
                            <p style={{color:"green"}}>Confirmed</p></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Turnover Requirement:</span>
                            <input type="text" className="form-control sms_blast" placeholder="SGD 110"/></label>
                        </div>
                    </div>
                </div>

                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Representative Bank Details</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Bank Name:</span>
                            <select className="form-control sms_blast">
                                <option>cbank</option>
                            </select></label><br/><br/>
                            <label className="labls"><span>Bank Account Name:</span>
                            <input type="text" className="form-control sms_blast" placeholder="fsdfdsf"/></label><br/><br/>
                            <label className="labls"><span>Bank Account No.:</span>
                            <input type="text" className="form-control sms_blast" placeholder="1234567890"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Receipt:</span>
                            <input type="text" className="form-control sms_blast" placeholder="Not Available"/></label>
                        </div>
                    </div>
                </div>

                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Audit Details</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Confirmed on:</span>
                            <input type="date" className="form-control sms_blast"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Confirmed by:</span>
                            <input type="text" className="form-control sms_blast" placeholder="sp_support"/></label>
                        </div>
                    </div>
                </div>

                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Others</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Fees (SGD):</span>
                            <input type="text" className="form-control sms_blast" placeholder="0.00"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Remark:</span>
                            <textarea className="form-control sms_blast" placeholder="Remark"/></label>
                        </div>
                    </div>
                </div>

            
                <div style={{background: 'white'}}>
                    <div style={{padding: '10px 20px 20px'}}>
                        <div style={{marginTop:"10px",paddingLeft:"0"}}>
                            <button className="btn btn-primary add_btn" style={{width:'auto',marginLeft:"20px"}}>Save</button>
                            <button className="btn btn-primary add_btn reset_black_btn" style={{width:'auto',marginLeft:"20px"}}>Revoke</button>
                        </div>
                    </div>
                </div>
            </div>}

            {this.state.currentTab=='adjustment'  || this.state.currentTab=='rebate'?
            (<div>
                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Transaction Details</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Transaction ID:</span>
                            <input type="text" className="form-control sms_blast" placeholder="11666"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Deposit Date:</span>
                            <input type="text" className="form-control sms_blast"  placeholder="--"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Transaction Date:</span>
                            <input type="date" className="form-control sms_blast"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Deposit Channel:</span>
                            <input type="text" className="form-control sms_blast"  placeholder="--"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Deposit Amount:</span>
                            <input type="text" className="form-control sms_blast" placeholder="SGD 110.00"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Refrence Code:</span>
                            <input type="text" className="form-control sms_blast"  placeholder="--"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Fund Method:</span>
                            <input type="text" className="form-control sms_blast" placeholder={this.state.currentTab=='rebate' ? 'Rebate' : 'Adjustment in'} /></label><br/><br/>
                            <label className="labls"><span style={{fontWeight:"700"}}>Status:</span>
                            <p style={{color:"green"}}>Confirmed</p></label>
                        </div>
                        {this.state.currentTab=='rebate' &&
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Refrence Turnover Requirement:</span>
                            <input type="text" className="form-control sms_blast"  placeholder="0.00"/></label>
                        </div>}
                    </div>
                </div>

                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Audit Details</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Confirmed on:</span>
                            <input type="date" className="form-control sms_blast"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Confirmed by:</span>
                            <input type="text" className="form-control sms_blast" placeholder="sp_support"/></label>
                        </div>
                    </div>
                </div>
                <div className="transaction_detailss divbody">
                    <div className="subbdvv1" style={{padding: '16px 0px 20px'}}>
                        <h5 style={{fontWeight:"700",margin:"0",fontSize:"17px",borderBottom:"1px solid #8080805e",paddingBottom:"10px",paddingLeft:"24px"}}>Others</h5>
                    </div>
                    <div className="row" style={{margin:0,padding:'12px 14px 0px',background:'white'}}>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Fees (SGD):</span>
                            <input type="text" className="form-control sms_blast" placeholder="0.00"/></label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 top_div2">
                            <label className="labls"><span>Remark:</span>
                            <textarea className="form-control sms_blast" placeholder="Remark"/></label>
                        </div>
                    </div>
                </div>
                <div style={{background: 'white'}}>
                    <div style={{padding: '10px 20px 20px'}}>
                        <div style={{marginTop:"10px",paddingLeft:"0"}}>
                            <button className="btn btn-primary add_btn" style={{width:'auto',marginLeft:"20px"}}>Save</button>
                        </div>
                    </div>
                </div>
            </div>):('')}

          </div>
        </div>
      </div>
    );
  }
}
export default TransactionDetail;