import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';
import { CSVLink } from "react-csv";
import exportFromJSON from 'export-from-json'
const data = [
  {id: 1,username: "User1234",total_records:"5",turnover:"130.00",bet:"200.00",payout:"130.00",win_lose:"70.00"},
  {id: 2,username: "User1234",total_records:"5",turnover:"130.00",bet:"200.00",payout:"130.00",win_lose:"70.00"},
  {id: 3,username: "User1234",total_records:"5",turnover:"130.00",bet:"200.00",payout:"130.00",win_lose:"70.00"},
  {id: 4,username: "User1234",total_records:"5",turnover:"130.00",bet:"200.00",payout:"130.00",win_lose:"70.00"},
  {id: 5,username: "User1234",total_records:"5",turnover:"130.00",bet:"200.00",payout:"130.00",win_lose:"70.00"},
  {id: 6,username: "User1234",total_records:"5",turnover:"130.00",bet:"200.00",payout:"130.00",win_lose:"70.00"},
  {id: 7,username: "User1234",total_records:"5",turnover:"130.00",bet:"200.00",payout:"130.00",win_lose:"70.00"},
  {id: 8,username: "User1234",total_records:"5",turnover:"130.00",bet:"200.00",payout:"130.00",win_lose:"70.00"},
  {id: 9,username: "User1234",total_records:"5",turnover:"130.00",bet:"200.00",payout:"130.00",win_lose:"70.00"},
  {id: 10,username: "User1234",total_records:"5",turnover:"130.00",bet:"200.00",payout:"130.00",win_lose:"70.00"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',fontSize: '12px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
const excelData = [
  {
    "Username": "User1234",
    "Total Records": "5",
    "Turnover": 130.00,
    "Bet": 5.00,
    "Payout": '130.00',
    "Win/Lose": 70.00,
  },
  {
    "Username": "User1234",
    "Total Records": "5",
    "Turnover": 130.00,
    "Bet": 5.00,
    "Payout": '130.00',
    "Win/Lose": 70.00,
  },
  {
    "Username": "User1234",
    "Total Records": "5",
    "Turnover": 130.00,
    "Bet": 5.00,
    "Payout": '130.00',
    "Win/Lose": 70.00,
  },
  {
    "Username": "User1234",
    "Total Records": "5",
    "Turnover": 130.00,
    "Bet": 5.00,
    "Payout": '130.00',
    "Win/Lose": 70.00,
  }
]
const headers = [
  { label: "Username", key: "username" },
  { label: "Total Records", key: "total_records" },
  { label: "Turnover", key: "turnover" },
  { label: "Bet", key: "bet" },
  { label: "Payout", key: "payout" },
  { label: "Win/Lose", key: "win_lose" },
];
class MemberStatistic extends React.Component {
  constructor(props) {
  super(props);
  this.tableRef = React.createRef();
  this.printData = this.printData.bind(this)
  this.exportData = this.exportData.bind(this)
  }
  printData(){
    window.print();
  }
  exportData(){
    exportFromJSON({ data: excelData, fileName: 'Member statistic', exportType: exportFromJSON.types.xls})
  }
  render() {
    const columns = [
      {name: 'No.',selector: 'id',cell: row => <div data-tag="allowRowEvents">{row.id}</div>},
      {name: 'Username',selector: 'username',cell: row => <div data-tag="allowRowEvents" style={{color:'#1890ff'}}>User1234</div>},
      {name: 'Total Records',selector: 'total_records',cell: row => <div data-tag="allowRowEvents">5</div>},
      {name: 'Turnover',selector: 'turnover',cell: row => <div data-tag="allowRowEvents">130.00</div>},
      {name: 'Bet',selector: 'bet',cell: row => <div data-tag="allowRowEvents">200.00</div>},
      {name: 'Payout',selector: 'payout',cell: row => <div data-tag="allowRowEvents">130.00</div>},
      {name: 'Win/Lose',selector: 'win_lose',cell: row => <div data-tag="allowRowEvents">70.00</div>},
    ];
    return (
      <div>  
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo print_hide" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px'}} className="print_hide">
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Statistics</span> / 
                    <span>Member Statistic</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:'0'}}>Member Statistic</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
                <div className="row print_hide" style={{margin:0,padding:'12px 14px',background:'white'}}>
                
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 top_div top_second_div">
                        <label className="labls"><span style={{minWidth:"82px"}}>Date Range:</span>
                        <input type="date" className="form-control"/></label>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-10 col-xs-12" style={{marginTop:"20px",width:"100%"}}>
                        <div className="mem_statistic">
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>Top 30 Winners List</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>Top 30 Losers List</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>No Deposit User</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>No Login User</span>
                            </span>
                        </div>
                        <div  className="mem_statistic" style={{marginTop:"10px"}}>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>Top 30 Deposit List</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>Top 30 Withdraw List</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>Top 30 Promotion List</span>
                            </span>
                            <span className="checkspan twos twos1">
                                <input type="checkbox"/> <span>Top 30 Rebate List</span>
                            </span>
                        </div>
                        <div  className="mem_statistic" style={{marginTop:"20px"}}>
                            <span className="checkspan twos">
                                <input type="checkbox" defaultChecked="checked"/> <span>Select All</span>
                            </span>
                            <span className="checkspan twos">
                                <input type="checkbox"/> <span>Deselect All</span>
                            </span>
                        </div>
                    </div>
                    <br/>
                    <div  className="mem_statistic" style={{marginTop:"20px"}}>
                        <button className="btn btn-primary add_btn" style={{width:'auto'}}>Search</button>
                        <button className="btn btn-primary add_btn reset_black_btn">Reset</button>
                    </div>
                
                </div><br/>
              <div className="trans_tabl" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="row print_hide">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <span className="subdvv1_1_1 mem_stat_left">
                        <label className="labls labls1"><span style={{minWidth:"48px"}}>Show:</span>
                            <select className="form-control" style={{width:"70px"}}>
                                    <option>50</option>
                            </select></label>
                      </span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                      <div className="mem_stat_btns" style={{float:'right',marginBottom:'10px'}}>
                          <button className="btn btn-primary add_btn cancel_btn" style={{width:'auto'}} onClick={this.exportData}>Export to Excel</button>
                          <CSVLink data={data} headers={headers} className="btn btn-primary add_btn cancel_btn">CSV</CSVLink>;
                          <button className="btn btn-primary add_btn cancel_btn" onClick={this.printData}>Print</button>
                      </div>
                    </div>
                </div>
                

                <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles} style={{marginTop:"20px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MemberStatistic;