import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import {Modal} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Search,Dot,ChevronUp } from 'react-bootstrap-icons';
import { makeRequest,timestampOnlyDate } from '../../helpers'
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '13px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '12px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class TransactionRequest extends React.Component {
	 constructor(props) {
		super(props);
		this.makeRequest = makeRequest
		this.approve_money = this.approve_money.bind(this)
		this.closeConfirmDiv = this.closeConfirmDiv.bind(this)
    this.changeStatusFilter = this.changeStatusFilter.bind(this)
    this.changeTypeFilter = this.changeTypeFilter.bind(this)
    this.search = this.search.bind(this)
    this.clearSearchData = this.clearSearchData.bind(this)
    this.set_color = this.set_color.bind(this)
	  }
	  state= {
		transData:[],
		confirmDiv:false,
    status:'',
    type:'',
	  }
    changeStatusFilter(e) {
      this.setState({
        status: e.target.value,
      })
    }
    changeTypeFilter(e) {
      this.setState({
        type: e.target.value,
      })
    }
    clearSearchData(){
      this.setState({
        type: '',
      })
      this.setState({
        status: '',
      })
    }
    set_color(val){
      if(val==='approved'){
           return '#12d612';
      }else if(val==='declined'){
          return'#FF443E';
      }else if(val==='pending'){
        return 'orange';
      }else if(val==='submit'){
        return '#9AD2EB';
      }else if(val==='cancelled'){
        return '#BFBFBF';
      }else if(val==='created'){
        return'rgb(24, 144, 255)';
      }
    }
    async search(){
      var result1;
        if((this.state.status!=='') && (this.state.type==='')){
          result1=await this.makeRequest('btsepay/transaction?status='+this.state.status, 'get','',localStorage.getItem('role_token'));
        }else if((this.state.status==='') && (this.state.type!=='')){
          result1=await this.makeRequest('btsepay/transaction?type='+this.state.type, 'get','',localStorage.getItem('role_token'));
        }else if((this.state.status!=='') && (this.state.type!=='')){
          result1=await this.makeRequest('btsepay/transaction?status='+this.state.status+'&type='+this.state.type, 'get','',localStorage.getItem('role_token'));
        }
        else if((this.state.status==='') && (this.state.type==='')){
          result1=await this.makeRequest('btsepay/transaction', 'get','',localStorage.getItem('role_token'));
        }
        if(result1.status===200){
          this.setState(state => ({transData:result1.data.data}));
        }
    }
	  componentDidMount(){
		(async () => {
		  var result1=await this.makeRequest('btsepay/transaction', 'get','',localStorage.getItem('role_token'));
		  if(result1.status===200){
			  console.log(result1.data)
			this.setState(state => ({transData: result1.data.data}));
		  }
		})();
	  }
	  closeConfirmDiv(){
		this.setState(state => ({confirmDiv: false}));
	  }
	  async approve_money(id){
		localStorage.setItem(id,id)
		var result1=await this.makeRequest('btsepay/transaction/'+id, 'post','',localStorage.getItem('role_token'));
		  if(result1.status===200){
			this.setState(state => ({confirmDiv: true}));
		  }
	  }
  render() {
	var data = this.state.transData.map(function(item) {
		return {
			key: item.id,
			trans_dt:item.createdAt,
			trans_id: item.transactionId,
			trans_address: item.address,
			trans_type: item.type,
			status: item.status,
			amount: item.amount,
			currency: item.coin,
			fees: item.fee
		};
	});
	// const data = [
		// {this.state.transData.map(data => (
		  // {
			// id: 1,trans_id: "11655",trans_type: "Promotion",status: "New Request",trans_dt: "2021-03-13 12:01:01",debit: "-",credit: "25.00",fees: "0.00",currency:"25"
		  // },
		// ))}
	// ];
    const columns = [
    {name: 'Trans. Date',selector: 'trans_dt',width:'200px',cell: row => <div data-tag="allowRowEvents">{timestampOnlyDate(row.trans_dt)}</div>,
	},
    {name: 'Trans. Type',selector: 'trans_type'},
	{name: 'Trans. Address',selector: 'trans_address',width:'200px',cell: row => <div data-tag="allowRowEvents" style={{color: '#1890ff',fontWeight:"700",cursor:"pointer"}} onClick={()=>this.props.history.push('/admin/transaction_history#'+row.key+'#'+row.trans_type+'#'+row.status+'#'+row.currency+'#'+((row.trans_dt.split('T'))[0]))} >{row.trans_address}</div>,
	},
    {
      name: 'Status',selector: 'status',cell: row => <div data-tag="allowRowEvents"><Dot color={this.set_color(row.status)} size={28}/>{row.status}</div>,
    },
    {name: 'Currency',selector: 'currency',width:'90px'},
    {name: 'Debit',selector: 'debit',width:'60px',cell: row => <div data-tag="allowRowEvents">{row.trans_type==='withdraw' ? row.amount : '-'}</div>,
	},
    {name: 'Credit',selector: 'credit',width:'60px',cell: row => <div data-tag="allowRowEvents">{row.trans_type==='deposit' ? row.amount : '-'}</div>,
	},
    {name: 'Fees',selector: 'fees',width:'60px'},
	  {/*name: 'Action',selector: 'action',cell: row => <div data-tag="allowRowEvents">
		 {localStorage.getItem(row.key)!=='undefined' && localStorage.getItem(row.key)!==null && localStorage.getItem(row.key)!=='' ?
		 (<p style={{color:"green",marginBottom:"0",fontSize:"13px",fontWeight:"600"}}>Request Sent</p>):(<button className="trans_approve_btn" onClick={() => this.approve_money(row.key)}>Approve</button>)}
	  </div>,
*/},
  ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3 plo" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 0px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span> &nbsp; Transactions</span> / 
                    <span>New transaction request</span>
                  </div>
                  <h5 className="pg_head" style={{marginBottom:0}}>Transaction request</h5>
                </div>
              </div><br/>
            </div>
            <div className="divbody">
              {/*<div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                <div className="col-md-2">
                   <span className="checkspan">
                       <input type="checkbox"/> <span>Member Group: </span>
                   </span>
                </div>
                <div className="col-md-10">
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                    </div><br/>
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>DAFF001</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>ag1</span>
                        </span>
                    </div><br/>
                    <div>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>demo12</span>
                        </span>
                        <span className="checkspan twos">
                            <input type="checkbox"/> <span>testmex01</span>
                        </span>
                    </div>
                </div>
              </div>
              <div className="dashdbordr"></div>
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white',fontSize:'14px'}}>
                <div className="col-md-12 trdv">
                    <input type="checkbox"/> <span>Record type: </span>
                    <input type="checkbox"/> <span style={{marginRight:'36px'}}>Credit: </span>
                    <input type="checkbox"/> <span>Debit: </span>
                </div>
              </div>*/}
              <div className="dashdbordr"></div>
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                {/*<div className="col-md-4" style={{marginBottom:'24px'}}>
                   <span>Refresh rate: </span>
                   <select className="form-control diselect">
                       <option>5 sec</option>
                   </select>
                </div>*/}
                <div className="col-md-4" style={{marginBottom:'24px'}}>
                   <span>Transaction Type: </span>
                   <select className="form-control diselect" onChange={this.changeTypeFilter} value={this.state.type}>
                       <option value="">All</option>
                       <option value="deposit">Deposit</option>
                       <option value="withdraw">Withdraw</option>
                   </select>
                </div>
                <div className="col-md-4" style={{marginBottom:'24px'}}>
                   <span>Transaction Status: </span>
                   <select className="form-control diselect" onChange={this.changeStatusFilter} value={this.state.status}>
                       <option value="">All</option>
                       <option value="created">Created</option>
                       <option value="pending">Pending</option>
                       <option value="done">Approved</option>
                   </select>
                </div>
                {/*<div className="col-md-4" style={{marginBottom:'24px'}}>
                   <span>Transaction Channel: </span>
                   <select className="form-control diselect">
                       <option>Local Bank</option>
                       <option>International wire</option>
                   </select>
                </div>*/}
                <div className="col-md-12">
                    <button className="btn btn-primary add_btn" style={{width:'auto'}} onClick={this.search}>Search</button>
                   {/*<span style={{color: '#929292',fontSize: '14px',marginLeft: '14px'}}>Last update on: March 13, 2021 6:15:05 PM
                        <span style={{float:'right',color: '#1890ff'}}>Pack up <ChevronUp color="#1890ff" size="14"/></span>
                    </span>*/}
                </div>
              </div>
            </div>
            
            <div className="divbody">
              <div className="row" style={{margin:0,padding:'20px 14px',background:'white'}}>
                {/*<div className="col-md-12">
                    <div className="subdvv1_1_1" style={{width:'230px'}}>
                        <input style={{height: '31px',fontSize: '14px',border: '1px solid #d9d9d9',borderRadius: '2px'}} type="text" className="form-control" placeholder="Search"/><Search color="#00000040" size={15}/>
                    </div>
                  </div>*/}
                <div className="col-md-12 trans_tabl">
                  <br/>
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
					  <div className="nav_notify_div">
						<Modal show={this.state.confirmDiv} size="sm" className="pwd_changed_modal bank_account_modal notif_modal">
						  <Modal.Header style={{background:'none'}} closeButton onClick={() => this.closeConfirmDiv()}></Modal.Header>
						  <Modal.Body>
							<div className=""><br/>
							  <p style={{fontSize: '18px',color: '#ffffff',marginBottom:"0px",fontWeight:"700"}}>
							  Request sent to BTSE!</p>
							  <p style={{fontSize: '14px',color: '#ffffff',marginBottom:"0px",fontWeight:"500"}}>
							  When BTSE gives response, status will be updated.</p><br/><br/>
							</div>
						  </Modal.Body>
						</Modal>  
					  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TransactionRequest;