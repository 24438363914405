import React from "react";
import Navbar from './Navbar_new';
import Footer from './Footer';
import LevelDets from './LevelDets';
import LevelDetsSM from './LevelDetsSM';
import { Accordion, Card, Modal, Dropdown } from "react-bootstrap";
import { Eye, CloudArrowUpFill, Clock, Trash, CaretUpFill, CaretDownFill, Front, ExclamationCircleFill, ArrowLeft, XCircleFill, PlusSquare, EnvelopeFill, TelephoneFill, XSquare,BoxArrowUp } from 'react-bootstrap-icons';
import "../assets/Modal.css"
//import Modal from './Modal'
import DataTable from 'react-data-table-component';
import data2 from './Data2'
import COUNTRIES from '../countries'
import { makeRequest, makeFileUploadRequest, handleKeyUp, user_mailbox, read_mail, addZero, timestampDate, timestampTime } from '../helpers'
var proof_files;
var proof_length=0;
const customStyles = {
  rows: {
    style: {
      minHeight: '50px',
      fontSize: '13px',
      background: '#0d171f',
      color: 'white',
      textTransform: 'capitalize'
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',
      paddingRight: '8px',
      fontSize: '12px',
      fontWeight: 'bold',
      width: '50px',
      background: '#363F47',
      color: 'white',
      border: '0'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',
      paddingRight: '8px',
      width: '50px',
      color: '#ffffff80',
      fontSize: '12px',
      borderBottom: '1px solid #283138'
    },
  },
};
// const columns2 = [
//   {
//     name: 'USERNAME',
//     selector: 'username',
//     width:'150px'
//   },
//   {
//     name: 'VIP LEVEL',
//     selector: 'vip_level',
//   },
//   {
//     name: 'JOIN DATE',
//     selector: 'datetime',
//     width:'200px'
//   },
// ];

class Wallets extends React.Component {
  constructor(props) {
    super(props);
    this.changetab = this.changetab.bind(this)
    this.changeToggleState = this.changeToggleState.bind(this)
    this.changeToggleState2 = this.changeToggleState2.bind(this)
    this.setShow = this.setShow.bind(this)
    this.setVerifyMobile = this.setVerifyMobile.bind(this)
    this.goto_nextStep = this.goto_nextStep.bind(this)
    this.addBank = this.addBank.bind(this)
    this.changeCurrPwd = this.changeCurrPwd.bind(this)
    this.changeNewPwd = this.changeNewPwd.bind(this)
    this.changeretypPwd = this.changeretypPwd.bind(this)
    this.modify_pwd = this.modify_pwd.bind(this)
    this.avatarUpload = this.avatarUpload.bind(this)
    this.changeAvatar = this.changeAvatar.bind(this)
    this.changeFname = this.changeFname.bind(this)
    this.changeDob = this.changeDob.bind(this)
    this.send_verif_code = this.send_verif_code.bind(this)
    this.changePhone = this.changePhone.bind(this)
    this.verify_phone = this.verify_phone.bind(this)
    this.changeReferral = this.changeReferral.bind(this)
    this.become_agent = this.become_agent.bind(this)
    this.update_profile = this.update_profile.bind(this)
    this.user_profile = this.user_profile.bind(this)
    this.referrer_info = this.referrer_info.bind(this)
    this.removeAvatar = this.removeAvatar.bind(this)
    this.selectedCountry = this.selectedCountry.bind(this)
    this.walletDetail = this.walletDetail.bind(this)
    this.becom_agnt = this.becom_agnt.bind(this)
    this.DetailModal = this.DetailModal.bind(this)
    this.changeAmount = this.changeAmount.bind(this)
    this.depositAmount = this.depositAmount.bind(this)
    this.closeWithdrawModal = this.closeWithdrawModal.bind(this)
    this.transferMoney = this.transferMoney.bind(this)
    this.addBankModal = this.addBankModal.bind(this)
    this.changeTransferAmount = this.changeTransferAmount.bind(this)
    this.changeSourceWallet = this.changeSourceWallet.bind(this)
    this.closeDepositCryptoModal = this.closeDepositCryptoModal.bind(this)
    this.changeDestinationWallet = this.changeDestinationWallet.bind(this)
    this.toggleWalletFilter = this.toggleWalletFilter.bind(this)
    this.toggleWalletWithdrawFilter = this.toggleWalletWithdrawFilter.bind(this)
    this.changeCurrency = this.changeCurrency.bind(this)
    this.changeWithdrawCurrency = this.changeWithdrawCurrency.bind(this)
    this.showCryptoList = this.showCryptoList.bind(this)
    this.showCryptoWithdrawList = this.showCryptoWithdrawList.bind(this)
    this.depositCrypto = this.depositCrypto.bind(this)
    this.withdrawMoney = this.withdrawMoney.bind(this)
    this.closeWithdrawMoneyModal = this.closeWithdrawMoneyModal.bind(this)
    this.changeWithdrawAmount = this.changeWithdrawAmount.bind(this)
    this.copyCryptoAddress = this.copyCryptoAddress.bind(this)
    this.changeWithdrawAddress = this.changeWithdrawAddress.bind(this)
    this.showTransactionTable = this.showTransactionTable.bind(this)
    this.delete_mail = this.delete_mail.bind(this)
    this.fiatDiv = this.fiatDiv.bind(this)
    this.read_mail = read_mail
    this.addZero = addZero
    this.timestampDate = timestampDate
    this.c1 = this.c1.bind(this)
    this.c2 = this.c2.bind(this)
    this.c3 = this.c3.bind(this)
    this.c4 = this.c4.bind(this)
    this.c5 = this.c5.bind(this)
    this.c6 = this.c6.bind(this)
    this.makeRequest = makeRequest
    this.user_mailbox = user_mailbox
    this.makeFileUploadRequest = makeFileUploadRequest
    this.submitRequest = this.submitRequest.bind(this)
    this.uploadProof = this.uploadProof.bind(this)
    this.showTransactionDetail = this.showTransactionDetail.bind(this)
    this.handleKeyUp = handleKeyUp
    this.mailDetsModal = this.mailDetsModal.bind(this)
    this.showDepositFromTable = this.showDepositFromTable.bind(this)
    this.showWithdrawFromTable = this.showWithdrawFromTable.bind(this)
    this.changeConvertedAmount = this.changeConvertedAmount.bind(this)
    this.showFromCurrency = this.showFromCurrency.bind(this)
    this.showToCurrency = this.showToCurrency.bind(this)
    this.changeConvertCurrencyModal = this.changeConvertCurrencyModal.bind(this)
    this.changeFromCurrency = this.changeFromCurrency.bind(this)
    this.changeToCurrency = this.changeToCurrency.bind(this)
    this.convertValue = this.convertValue.bind(this)
    this.acceptValue = this.acceptValue.bind(this)
    this.newConvertRequest = this.newConvertRequest.bind(this)
    this.viewTransactionDetailDiv = this.viewTransactionDetailDiv.bind(this)
    this.searchTransactions = this.searchTransactions.bind(this)
    this.resetTransactions = this.resetTransactions.bind(this)
    this.changeTransactionType = this.changeTransactionType.bind(this)
    this.changeTransactionCurrency = this.changeTransactionCurrency.bind(this)
    this.changeTransactionStartDate = this.changeTransactionStartDate.bind(this)
    this.changeTransactionEndDate = this.changeTransactionEndDate.bind(this)
    this.closeWithdrawFiatModal = this.closeWithdrawFiatModal.bind(this)
    this.withdrawFiat = this.withdrawFiat.bind(this)
    this.changeFiatWithdrawAmount = this.changeFiatWithdrawAmount.bind(this)
    this.changeFiatWithdrawChannel = this.changeFiatWithdrawChannel.bind(this)
    this.changeFiatWithdrawCurrency = this.changeFiatWithdrawCurrency.bind(this)
    this.ChangefiatDepositChannelId = this.ChangefiatDepositChannelId.bind(this)
    this.ChangeFiatDepositCurrency = this.ChangeFiatDepositCurrency.bind(this)
    this.changeFiatDepositAmount = this.changeFiatDepositAmount.bind(this)
    this.getfiatChannelDetail = this.getfiatChannelDetail.bind(this)
    this.changeProofFiles = this.changeProofFiles.bind(this)
    this.uploadProofFiles = this.uploadProofFiles.bind(this)
    this.cancelFiatRequest = this.cancelFiatRequest.bind(this)
    this.getDepositChannels = this.getDepositChannels.bind(this)
    this.getGameWallets = this.getGameWallets.bind(this)
    this.changeEvoplayAmount = this.changeEvoplayAmount.bind(this)
    this.transferToVigobet = this.transferToVigobet.bind(this)
    this.clearErrorMsgs = this.clearErrorMsgs.bind(this)
    this.changeAccountName = this.changeAccountName.bind(this)
    this.changeAccountNumber = this.changeAccountNumber.bind(this)
    this.changeBankName = this.changeBankName.bind(this)
    this.getBankList = this.getBankList.bind(this)
    this.changeBankStatements = this.changeBankStatements.bind(this)
    this.deleteBankAccount = this.deleteBankAccount.bind(this)
    this.changeUploadStatements = this.changeUploadStatements.bind(this)
    //this.uploadStatements = this.uploadStatements.bind(this)
  }
  state = {
    tab: 'account',
    tog1_state: true,
    tog2_state: false,
    show: false,
    showVerifyMobile: false,
    currentStep: 1,
    reflink: false,
    refstep: 1,
    oldp: '', newp: '', currValid: '', newValid: '', retypeValid: '',
    success_msg: '',
    success_msg4: '',
    success_msg5: '',
    success_msg6: '',
    success_msg7: '',
    success_msg8: '',
    success_msg9: '',
    success_msg10: '',
    success_msg11: '',
    success_msg12: '',
    success_msg13: '',
    success_msg14: '',
    error_msg: '',
    error_msg2: '',
    error_msg3: '',
    error_msg4: '',
    error_msg5: '',
    error_msg6: '',
    error_msg7: '',
    error_msg8: '',
    error_msg9: '',
    error_msg10: '',
    error_msg11: '',
    error_msg12: '',
    error_msg13: '',
    error_msg14: '',
    submitDisabled: true,
    submitDisabled2: false,
    submitDisabled4: false,
    submitDisabled5: false,
    submitDisabled7: false,
    submitDisabled8: false,
    submitDisabled9: false,
    submitDisabled10: false,
    submitDisabled11: false,
    submitDisabled12: false,
    submitDisabled13: false,
    submitDisabled14: false,
    filepath: '',
    dobValid: '',
    dob: '',
    fnameValid: '',
    fname: '',
    phone: '',
    phoneValid: '',
    code: '',
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
    referral: '',
    referralValid: '',
    userAvatar: '',
    referrerInfo: '',
    referral_count: {},
    walletDetails: '',
    selected_country: {
      "k": "", "n": ""
    },
    showDetailModal: '',
    showDepositModal: false,
    showTransferModal: false,
    amount: '',
    tranfer_amount: '',
    source_wallet: '',
    destination_wallet: '',
    allWallets: [],
    showDepositCryptoModal: false,
    showBankModal: false,
    showWithdrawModal: false,
    showWalletFilter: false,
    currentCurrency: 'ETH',
    currentWithdrawCurrency: 'ETH',
    cryptoList: false,
    cryptoWithdrawList: false,
    showWithdrawMoneyModal: false,
    withdrawAmount: '',
    cryptoAddress: '',
    copiedCryptoAddress: '',
    copy_text_msg: '',
    totalWalletBalance: '',
    allCurrencies: [],
    transactionTable: true,
    showModal: false,
    mailData: [],
    mailDets: {},
    showFiatDiv: false,
    showDets: 1,
    transactionDetail: false,
    walletDetail: [],
    fiatCurrencies: [],
    transactionList: [],
    converted_amount: '',
    show_from_currency: false,
    showConvertCurrencyModal: false,
    from_currency: 'USD',
    to_currency: 'USD',
    availableWalletBalance: '',
    quoteId: '',
    convertedValue: '',
    convertFirstDiv: true,
    acceptButton: true,
    viewTransactionDetail: [],
    transactionType: '',
    showWalletWithdrawFilter: false,
    showWithdrawFiatModal: false,
    depositChannel: [],
    fiatWithdrawAmount: '',
    fiatWithdrawCurrency: '',
    fiatWithdrawChannel: '',
    transactionEndDate: '',
    transactionStartDate: '',
    transactionCurrency: '',
    fiatDepositChannelId: '',
    fiatDepositCurrency: 'USD',
    fiatDepositAmount: '',
    //fiatDepositTransId: '',
    fiatDepositTransId: '017c3627-69df-4ba9-98ba-32ddd5bccd16',
    showFiatChannelDetails: [],
    showUploadModal: false,
    currencyDepositChannels: [],
    proof_length: 0,
    referral_bonus_total: '',
    referral_bonus_currency: '',
    currentEvoplayCurrency:'',
    currentEvoplayAmount:'',
    transferEvoplayAmount:'',
    crypto_currencies:[],
    fiat_currencies:[],
    agentCommissionData:[],
    accountName:'',
    accountNumber:'',
    bankName:'',
    bankListLength:'',
    showBankStatements:false,
    bankStatements:[],
    statementFiles:'',

  }
  clearErrorMsgs(){
    this.setState(state => ({ success_msg:'',success_msg4:'',success_msg5:'',success_msg6:'',success_msg7:'',success_msg8:'',success_msg9:'',success_msg10:'',success_msg11:'',success_msg12:'',success_msg13:'',error_msg:'',error_msg2:'',error_msg3:'',error_msg4:'',error_msg5:'',error_msg6:'',error_msg7:'',error_msg8:'',error_msg9:'',error_msg10:'',error_msg11:'',error_msg12:'',error_msg13:'' }));
  }
  componentDidMount() {
    this.getBankList();
    // if(localStorage.getItem('userInfo')==='undefined' || localStorage.getItem('userInfo')===null || localStorage.getItem('userInfo')===''){
    //   window.location.href='/';
    // }
    if (localStorage.getItem('userInfo') !== 'undefined' && localStorage.getItem('userInfo') !== null && localStorage.getItem('userInfo') !== '') {
      let resultt = COUNTRIES.filter(obj => {
        return obj.k === localStorage.getItem('country')
      })
      this.setState({ userAvatar: (JSON.parse(localStorage.getItem('userInfo'))).avatar, fname: (JSON.parse(localStorage.getItem('userInfo'))).fullname, dob: (JSON.parse(localStorage.getItem('userInfo'))).dateOfBirth, selected_country: { "k": localStorage.getItem('country'), "n": resultt[0].n } });
      this.referrer_info();
    }
    (async () => {
      var result1 = await this.makeRequest('wallet/balance', 'get', '', localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({
          totalWalletBalance: (result1.data.value).toFixed(2)
        })
      }
    })();
    (async () => {
      var result1 = await this.makeRequest('wallet/available-balance', 'get', '', localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({
          availableWalletBalance: (result1.data.value).toFixed(2)
        })
      }
    })();
    (async () => {
      var result1 = await this.makeRequest('user/transaction', 'get', '', localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({
          transactionList: result1.data.data
        })
      }
    })();
    (async () => {
      var result1 = await this.makeRequest('deposit-channel/currency', 'get', '', localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({
          fiatCurrencies: result1.data.data
        })
      }
    })();
    (async () => {
      var result1 = await this.makeRequest('deposit-channel', 'get', '', localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({
          depositChannel: result1.data.data
        })
      }
    })();
    (async () => {
      var result1 = await this.makeRequest('btsepay/currency', 'get');
      if (result1.status === 200) {
        this.setState(state => ({ allCurrencies: result1.data.data }));
        this.setState(state => ({ currentCurrency: result1.data.data[0]}));
        this.setState(state => ({ currentWithdrawCurrency: result1.data.data[0]}));
      }
    })();
    this.getGameWallets();
    (async () => {
      var result1=await this.makeRequest('wallet/currency', 'get','',localStorage.getItem('token'));
      if(result1.status===200){
    	this.setState(state => ({fiat_currencies: result1.data.fiat,crypto_currencies:result1.data.crypto}));
      }
    })();
    (async () => {
      var result1=await this.makeRequest('user/me/referral', 'get','',localStorage.getItem('token'));
      if(result1.status===200){
    	  this.setState(state => ({agentCommissionData: result1.data.data}));
      }
    })();
    this.user_mailbox(this);
    this.walletDetail();
    this.walletData();
    this.setState(state => ({
      tab: window.location.hash.substr(1),
    }));
    this.getDepositChannels(this.state.fiatDepositCurrency);
  }
  async getGameWallets(){
    var result1=await this.makeRequest('evoplay/wallet', 'get','',localStorage.getItem('token'));
    if(result1.status===200){
        let amount=(parseFloat(result1.data.balance).toFixed(2));
        this.setState({currentEvoplayCurrency: result1.data.currency})
        this.setState({currentEvoplayAmount: amount})
    }
  }
  async getDepositChannels(currency) {
    var result1 = await this.makeRequest('deposit-channel?currency=' + currency, 'get', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      this.setState({
        currencyDepositChannels: result1.data.data
      })
    }
  }
  
  async walletDetail() {
    var result1 = await this.makeRequest('wallet', 'get', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      this.setState({ walletDetails: result1.data[0] });
    }
  }
  submitRequest(val) {
    this.setState(state => ({
      showDets: val,
      showUploadModal: false
    }));
  }
  cancelFiatRequest(val) {
    this.setState(state => ({ fiatDepositCurrency: 'USD', fiatDepositChannelId: '', fiatDepositAmount: '', fiatDepositTransId: '', }));
    this.setState({ showDets: val });
  }
  async getfiatChannelDetail(trans_id, val) {
    var result1 = await this.makeRequest('user/transaction/' + trans_id, 'get', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      this.setState(state => ({ showFiatChannelDetails: result1.data }));
      this.setState({ showDets: val });
    }
  }
  async getBankList() {
    var result1 = await this.makeRequest('bank-account', 'get', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      this.setState(state => ({ bankList: result1.data.data,bankListLength: (result1.data.data).length}));
    }
  }
  async getUniqueCode(val) {
    if (this.state.fiatDepositCurrency === '') {
      this.setState(state => ({ error_msg10: 'Please choose currency' }));
    } else if (this.state.fiatDepositChannelId === '') {
      this.setState(state => ({ error_msg10: 'Please select deposit channel' }));
    } else if (this.state.fiatDepositAmount === '') {
      this.setState(state => ({ error_msg10: 'Please enter amount' }));
    } else {
      this.setState(state => ({ submitDisabled10: true, error_msg10: '' }));
      var data = JSON.stringify({ amount: parseInt(this.state.fiatDepositAmount), currency: this.state.fiatDepositCurrency, type: 'deposit', depositChannelId: this.state.fiatDepositChannelId });
      var result1 = await this.makeRequest('transaction', 'post', data, localStorage.getItem('token'));
      if (result1.status === 200) {
        //this.setState({showDets:val});
        this.setState({ showUploadModal: false });
        this.setState({ fiatDepositTransId: result1.data.id });
        this.setState({ submitDisabled10: false });
        this.getfiatChannelDetail(result1.data.id, val);
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg10: result1.data.message, submitDisabled10: false }));
      } else if (result1.status === 401) {
        this.setState(state => ({ error_msg10: result1.data.error, submitDisabled10: false }));
      }
    }
  }
  async uploadProofFiles() {
    console.log(proof_length);
    if (proof_length === 0) {
      this.setState(state => ({ error_msg11: 'Please choose images.'}));
    }else {
      this.setState(state => ({ error_msg11: '', submitDisabled11: true }));
      var data = new FormData();
      data.append('image', (this.state.proof_files));
      var result1 = await this.makeFileUploadRequest('transaction/' + this.state.fiatDepositTransId + '/proof', 'post', data, localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState(state => ({submitDisabled11: false }));
        this.getfiatChannelDetail(this.state.fiatDepositTransId, 3);
      }
    }
  }
  changeUploadStatements(e){
    // const statements = e.target.files;
    // this.setState(state => ({statementFiles: statements }));
  }
  changeProofFiles(e) {
    const files = e.target.files;
    proof_files='';
    proof_length='';
    this.setState(state => ({ error_msg11: '', submitDisabled11: false }));
    if (files.length > 3) {
      this.setState(state => ({ error_msg11: 'You are only allowed to upload a maximum of 3 images at a time'}));
      var output = document.getElementById("image_result");
      output.innerHTML = '';
    } else {
      //this.setState(state => ({ filepath: e.target.files }));
      proof_files=e.target.files;
      proof_length=files.length;
      this.setState(state => ({ error_msg11: ''}));
      var output = document.getElementById("image_result");
      output.innerHTML = '';
      for (var i = 0; i < files.length; i++) {
        var index=-1;
        var file = files[i];
        //Only pics
        if (!file.type.match('image'))
          continue;
        var picReader = new FileReader();
        picReader.fileName = file.name;
        picReader.addEventListener("load", function (event) {
          var picFile = event.target;
          var filename = event.target.fileName;
          var div = document.createElement("span");
          index=parseInt(index)+1;
          div.innerHTML = "<div id='del_proof_div"+index+"' style='position:relative;'><img class='thumbnail' style='height:50px;object-fit:contain;margin-right: 32px;' src='" + picFile.result + "'" +
            "attr='" + filename + "'/><span class='proof_del_icon' id='del_proof"+index+"'>✖</span></div>";
          output.insertBefore(div, null);
          var myspan = document.getElementById("del_proof"+index);
          var del_div = document.getElementById("del_proof_div"+index);
          var del_index=index;
          var file_name=filename;
          myspan.onclick = function() { 
            del_div.remove();
            var newFileList = Array.from(proof_files);
            var indexx = newFileList.findIndex(obj => obj.name === file_name);
            newFileList.splice(indexx,1);
            var new_object=Object.assign(newFileList);
            proof_files=new_object;
            proof_length=new_object.length;
          };
        });
        picReader.readAsDataURL(file);
      }
    }
  }

  async searchTransactions() {
    var search = 'user/transaction?';
    if (this.state.transactionType !== '') {
      search = search + '&type=' + this.state.transactionType;
    }
    if (this.state.transactionCurrency !== '') {
      search = search + '&currency=' + this.state.transactionCurrency;
    }
    if (this.state.transactionStartDate !== '' && this.state.transactionEndDate !== '') {
      search = search + '&startDate=' + this.state.transactionStartDate + '&endDate=' + this.state.transactionEndDate;
    }
    var result1 = await this.makeRequest(search, 'get', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      this.setState({
        transactionList: result1.data.data
      })
    }
  }
  async resetTransactions() {
    this.setState(state => ({ transactionType: '', transactionCurrency: '', transactionStartDate: '', transactionEndDate: '' }));
    var result1 = await this.makeRequest('user/transaction', 'get', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      this.setState({
        transactionList: result1.data.data
      })
    }
  }
  showFromCurrency(val) {
    this.setState(state => ({
      show_to_currency: false
    }));
    this.setState(state => ({
      show_from_currency: val
    }));
  }
  showToCurrency(val) {
    this.setState(state => ({
      show_from_currency: false
    }));
    this.setState(state => ({
      show_to_currency: val
    }));
  }
  changeToCurrency(val) {
    this.setState(state => ({
      show_to_currency: false
    }));
    this.setState(state => ({
      to_currency: val
    }));
  }
  changeBankStatements(statements,modal_val) {
    this.setState(state => ({showBankStatements: modal_val,bankStatements:statements}));
  }
  changeFromCurrency(val) {
    this.setState(state => ({
      show_from_currency: false
    }));
    this.setState({
      from_currency: val
    })
  }
  changeEvoplayAmount(e) {
    this.setState({
      transferEvoplayAmount: e.target.value
    })
  }
  changeConvertCurrencyModal(val) {
    this.setState(state => ({
      showConvertCurrencyModal: val,error_msg8: '', submitDisabled8: false,error_msg7: '',submitDisabled7: false
    }));
    this.setState({ error_msg8: '' })
    this.setState({ success_msg8: '' })
    this.setState({ submitDisabled8: false })
    this.setState({ acceptButton: true })
    this.setState({ convertFirstDiv: true })
  }
  newConvertRequest() {
    this.setState({ error_msg8: '' })
    this.setState({ success_msg8: '' })
    this.setState({ submitDisabled8: false })
    this.setState({ acceptButton: true })
    this.setState({ convertFirstDiv: true })
  }
  uploadProof(val) {
    this.setState(state => ({
      showUploadModal: val,
    }));
  }
  changeAmount(e) {
    this.setState(state => ({
      amount: e.target.value,
    }));
  }
  changeConvertedAmount(e) {
    this.setState(state => ({
      converted_amount: e.target.value,
    }));
  }
  changeFiatWithdrawCurrency(e) {
    this.setState(state => ({
      fiatWithdrawCurrency: e.target.value,
    }));
  }
  changeFiatWithdrawChannel(e) {
    this.setState(state => ({
      fiatWithdrawChannel: e.target.value,
    }));
  }
  changeFiatWithdrawAmount(e) {
    this.setState(state => ({
      fiatWithdrawAmount: e.target.value,
    }));
  }
  ChangeFiatDepositCurrency(e) {
    this.setState(state => ({
      fiatDepositCurrency: e.target.value,
    }));
    this.getDepositChannels(e.target.value);
  }
  ChangefiatDepositChannelId(val) {
    this.setState(state => ({
      fiatDepositChannelId: val,
    }));
  }
  changeFiatDepositAmount(e) {
    this.setState(state => ({
      fiatDepositAmount: e.target.value,
    }));
  }
  changeBankName(e) {
    this.setState(state => ({
      bankName: e.target.value,
    }));
  }
  changeAccountName(e) {
    this.setState(state => ({
      accountName: e.target.value,
    }));
  }
  changeAccountNumber(e) {
    this.setState(state => ({
      accountNumber: e.target.value,
    }));
  }
  mailDetsModal(val, row) {
    this.setState(state => ({
      showModal: val,
      mailDets: row
    }));
    if (val === true) {
      this.read_mail(row.id)
    }
  }
  async delete_mail(id) {
    let result1 = await this.makeRequest('mailbox/' + id, 'delete', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      this.setState(state => ({
        success_msg6: 'Record deleted successfully',
      }));
    } else {
      this.setState(state => ({
        error_msg6: 'Some problem occured',
      }));
    }
  }
  changeTransferAmount(e) {
    this.setState(state => ({
      tranfer_amount: e.target.value,
    }));
  }
  changeWithdrawAmount(e) {
    this.setState(state => ({
      withdrawAmount: e.target.value,
    }));
  }
  changeTransactionType(e) {
    this.setState(state => ({
      transactionType: e.target.value,
    }));
  }
  changeTransactionCurrency(e) {
    this.setState(state => ({
      transactionCurrency: e.target.value,
    }));
  }
  changeTransactionStartDate(e) {
    this.setState(state => ({
      transactionStartDate: e.target.value,
    }));
  }
  changeTransactionEndDate(e) {
    this.setState(state => ({
      transactionEndDate: e.target.value,
    }));
  }
  changeSourceWallet(e) {
    this.setState(state => ({
      source_wallet: e.target.value,
    }));
  }
  changeDestinationWallet(e) {
    this.setState(state => ({
      destination_wallet: e.target.value,
    }));
  }
  showDepositFromTable(val) {
    this.state.allCurrencies.splice(6);
    const fetch_values = this.state.allCurrencies;
    var element = fetch_values.indexOf(val);
    if (element === -1) {
      this.state.allCurrencies.push(val);
    }
    this.setState(state => ({
      currentCurrency: val,
    }));
    this.setState(state => ({
      showDepositCryptoModal: true,
    }));
  }
  showWithdrawFromTable(val) {
    this.state.allCurrencies.splice(6);
    const fetch_values = this.state.allCurrencies;
    var element = fetch_values.indexOf(val);
    if (element === -1) {
      this.state.allCurrencies.push(val);
    }
    this.setState(state => ({
      currentWithdrawCurrency: val,
    }));
    this.setState(state => ({
      showWithdrawMoneyModal: true,
    }));
  }
  copyCryptoAddress(e) {
    if (localStorage.getItem('wallet_address') !== 'undefined' && localStorage.getItem('wallet_address') !== null && localStorage.getItem('wallet_address') !== '') {
      navigator.clipboard.writeText(localStorage.getItem('wallet_address'))
    } else {
      navigator.clipboard.writeText(this.state.cryptoAddress)
    }
    this.setState(state => ({
      copy_text_msg: 'Text copied',
    }));
  }
  changeWithdrawAddress(e) {
    let paste = (e.clipboardData || window.clipboardData).getData('text');
    this.setState(state => ({
      copiedCryptoAddress: paste,
    }));
  }
  async walletData() {
    var result1 = await this.makeRequest('wallet', 'get', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      this.setState({ allWallets: result1.data.data });
    }
  }
  async depositAmount() {
    if (this.state.amount === '') {
      this.setState(state => ({ error_msg3: 'Enter amount' }));
    } else {
      this.setState(state => ({ submitDisabled2: true, error_msg3: '' }));
      var data = JSON.stringify({ value: parseInt(this.state.amount) });
      var result1 = await this.makeRequest('wallet/017aa0a9-5f47-46c1-9a45-aacf0e4334bb/deposit', 'put', data, localStorage.getItem('token'));
      if (result1.status === 200) {
        //console.log('deposit_data',result1);
        this.setState({
          success_msg: result1.data.message
        })
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg3: result1.data.message, submitDisabled2: false }));
      } else if (result1.status === 401) {
        this.setState(state => ({ error_msg3: result1.data.error, submitDisabled2: false }));
      }
    }

  }

  async depositCrypto() {
    this.setState(state => ({ submitDisabled4: true, error_msg4: '' }));
    var data = JSON.stringify({ currency: this.state.currentCurrency });
    var result1 = await this.makeRequest('btsepay/deposit', 'post', data, localStorage.getItem('token'));
    if (result1.status === 200) {
      this.setState({
        success_msg4: 'Deposited successfully'
      })
      localStorage.setItem('wallet_address', result1.data.address)
      this.setState({
        error_msg4: ''
      })
      this.setState({
        submitDisabled4: false
      })
      this.setState({
        cryptoAddress: result1.data.address
      })
    } else if (result1.status === 400) {
      this.setState(state => ({ error_msg4: result1.data.message, submitDisabled4: false }));
    } else if (result1.status === 401) {
      this.setState(state => ({ error_msg4: result1.data.error, submitDisabled4: false }));
    }
  }

  async withdrawFiat() {
    if (this.state.fiatWithdrawChannel === '') {
      this.setState(state => ({ error_msg9: 'Please choose deposit channel!!', success_msg9: '' }));
    } else if (this.state.fiatWithdrawCurrency === '') {
      this.setState(state => ({ error_msg9: 'Please choose currency!!', success_msg9: '' }));
    } else if (this.state.fiatWithdrawAmount === '') {
      this.setState(state => ({ error_msg9: 'Please enter amount!!', success_msg9: '' }));
    } else {
      this.setState(state => ({ submitDisabled9: true, error_msg9: '' }));
      var data = JSON.stringify({ depositChannelId: this.state.fiatWithdrawChannel, currency: this.state.fiatWithdrawCurrency, amount: parseInt(this.state.fiatWithdrawAmount), type: 'withdraw' });
      var result1 = await this.makeRequest('transaction', 'post', data, localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({ success_msg9: result1.data.message })
        this.setState({ error_msg9: '' })
        this.setState({ submitDisabled9: false })
        this.setState(state => ({ fiatWithdrawAmount: '', fiatWithdrawChannel: '', fiatWithdrawCurrency: '' }));
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg9: result1.data.message, submitDisabled9: false }));
      } else if (result1.status === 401) {
        this.setState(state => ({ error_msg9: result1.data.error, submitDisabled9: false }));
      }
    }
  }

  async withdrawMoney() {
    if (this.state.copiedCryptoAddress === '') {
      this.setState(state => ({ error_msg5: 'Address could not be empty!!' }));
    } else if (this.state.withdrawAmount === '') {
      this.setState(state => ({ error_msg5: 'Amount could not be empty!!', }));
    } else if (this.state.withdrawAmount > this.state.totalWalletBalance) {
      this.setState(state => ({ error_msg5: 'Wallet balance is not enough to withdraw!!' }));
    } else {
      this.setState(state => ({ submitDisabled5: true, error_msg5: '' }));
      var data = JSON.stringify({ address: this.state.copiedCryptoAddress, currency: this.state.currentWithdrawCurrency, amount: parseInt(this.state.withdrawAmount) });
      var result1 = await this.makeRequest('btsepay/withdraw', 'post', data, localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({
          success_msg5: result1.data.message
        })
        this.setState({
          error_msg5: ''
        })
        this.setState({
          submitDisabled5: false
        })
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg5: result1.data.message, submitDisabled5: false }));
      } else if (result1.status === 401) {
        this.setState(state => ({ error_msg5: result1.data.error, submitDisabled5: false }));
      }
    }
  }

  async convertValue() {
    if (this.state.converted_amount === '') {
      this.setState(state => ({ error_msg7: 'Amount could not be empty!!' }));
    }else if (this.state.from_currency === '') {
      this.setState(state => ({ error_msg7: 'From currency could not be empty!!' }));
    } else if (this.state.to_currency === '') {
      this.setState(state => ({ error_msg7: 'To currency could not be empty!!', }));
    } else if (this.state.from_currency === this.state.to_currency) {
      this.setState(state => ({ error_msg7: 'From & To currency could not be same!!', }));
    }else {
      this.setState(state => ({ submitDisabled7: true, error_msg7: '' }));
      var data = JSON.stringify({ from: this.state.from_currency, to: this.state.to_currency, value: parseFloat(this.state.converted_amount) });
      console.log(data);
      var result1 = await this.makeRequest('convert-transaction', 'post', data, localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({
          convertedValue: (result1.data.value).toFixed(8)
        })
        this.setState({
          quoteId: result1.data.id
        })
        this.setState({
          error_msg7: ''
        })
        this.setState({
          submitDisabled7: false
        })
        this.setState({ convertFirstDiv: false })
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg7: result1.data.message, submitDisabled7: false }));
      } else if (result1.status === 401) {
        this.setState(state => ({ error_msg7: result1.data.error, submitDisabled7: false }));
      }
    }
  }

  async acceptValue() {
    this.setState(state => ({ submitDisabled8: true, error_msg8: '' }));
    //var data = JSON.stringify({ quoteId: this.state.quoteId });
    var result1 = await this.makeRequest('convert-transaction/'+this.state.quoteId, 'post', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      this.walletData();
      this.setState({ error_msg8: '' })
      this.setState(state => ({ submitDisabled8: false, error_msg7: '',convertedValue:'',showConvertCurrencyModal:false }));
      this.setState({ success_msg8: '' })
      this.setState({ submitDisabled8: false })
      this.setState({ acceptButton: false })
    } else if (result1.status === 400) {
      this.setState(state => ({ error_msg8: result1.data.message, submitDisabled8: false }));
      this.setState(state => ({ submitDisabled7: true, error_msg7: '',convertedValue:'' }));
    } else if (result1.status === 401) {
      this.setState(state => ({ error_msg8: result1.data.error, submitDisabled8: false }));
      this.setState(state => ({ submitDisabled7: true, error_msg7: '',convertedValue:'' }));
    }
  }

  async transferMoney() {
    if (this.state.source_wallet === '') {
      this.setState(state => ({ error_msg3: 'Please choose source wallet' }));
    } else if (this.state.destination_wallet === '') {
      this.setState(state => ({ error_msg3: 'Please choose destination wallet' }));
    } else if (this.state.destination_wallet === this.state.source_wallet) {
      this.setState(state => ({ error_msg3: 'Source and destination wallets could not be same.' }));
    } else if (this.state.tranfer_amount === '') {
      this.setState(state => ({ error_msg3: 'Please enter amount' }));
    } else {
      this.setState(state => ({ submitDisabled2: true, error_msg3: '' }));
      var data = JSON.stringify({ source: this.state.source_wallet, destination: this.state.destination_wallet, value: parseInt(this.state.tranfer_amount) });
      var result1 = await this.makeRequest('wallet/transfer', 'post', data, localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({
          success_msg: result1.data.message
        })
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg3: result1.data.message, submitDisabled2: false }));
      }
    }

  }
  fiatDiv(val) {
    this.setState(showFiatDiv => ({
      showFiatDiv: val
    }));
  }
  changetab(val) {
    this.setState(state => ({
      tab: val,
      bankAdded: false,
    }));
    window.location.hash = val;
    if (val === 'transactions') {
      this.setState(state => ({
        transactionTable: true,
      }));
      this.setState(state => ({
        transactionDetail: false,
      }));
    }
  }
  changeCurrency(val) {
    this.setState(state => ({
      currentCurrency: val
    }));
    this.setState(state => ({
      cryptoList: false
    }));
  }
  changeWithdrawCurrency(val) {
    this.setState(state => ({
      currentWithdrawCurrency: val
    }));
    this.setState(state => ({
      cryptoWithdrawList: false
    }));
  }
  changeToggleState(val) {
    this.setState(state => ({
      tog1_state: val,
      tog2_state: false
    }));
  }
  showTransactionTable(val) {
    this.setState(state => ({
      transactionTable: val,
    }));
    this.setState(state => ({
      transactionDetail: false,
    }));
  }
  showCryptoList(val) {
    this.setState(state => ({
      cryptoList: val
    }));
  }
  showCryptoWithdrawList(val) {
    this.setState(state => ({
      cryptoWithdrawList: val
    }));
  }
  closeWithdrawMoneyModal(val) {
    this.state.allCurrencies.splice(6);
    this.setState({
      currentCurrency: this.state.allCurrencies[0]
    })
    this.setState({
      success_msg5: ''
    })
    this.setState({
      error_msg5: ''
    })
    this.setState(state => ({
      showWithdrawMoneyModal: val
    }));
    this.setState({
      showWalletFilter: false
    })
  }
  closeWithdrawFiatModal(val) {
    this.setState({
      success_msg9: ''
    })
    this.setState({
      error_msg9: ''
    })
    this.setState(state => ({
      showWithdrawFiatModal: val
    }));
    this.setState({
      showWalletWithdrawFilter: false
    })
  }
  toggleWalletFilter(val) {
    this.setState(state => ({
      showWalletFilter: val,
    }));
  }
  toggleWalletWithdrawFilter(val) {
    this.setState(state => ({
      showWalletWithdrawFilter: val,
    }));
  }
  changeToggleState2(val) {
    this.setState(state => ({
      tog2_state: val,
      tog1_state: false
    }));
  }
  showTransactionDetail(val) {
    if (val === true) {
      this.setState(state => ({
        transactionTable: false,
      }));
    } else {
      this.setState(state => ({
        transactionTable: true,
      }));
    }
    this.setState(state => ({
      transactionDetail: val,
    }));
  }
  async viewTransactionDetailDiv(trans_id) {
    var result1 = await this.makeRequest('user/transaction/' + trans_id, 'get', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      if (result1.data !== null && result1.data !== '' && result1.data !== undefined) {
        this.setState({ viewTransactionDetail: result1.data });
        this.setState(state => ({ transactionTable: false }));
        this.setState(state => ({ transactionDetail: true }));
      }
    }
  }
  c1(e) { this.setState({ code1: e.target.value }) }
  c2(e) { this.setState({ code2: e.target.value }) }
  c3(e) { this.setState({ code3: e.target.value }) }
  c4(e) { this.setState({ code4: e.target.value }) }
  c5(e) { this.setState({ code5: e.target.value }) }
  c6(e) { this.setState({ code6: e.target.value }) }
  setShow(val) {
    this.setState(state => ({
      show: val,
    }));
  }
  setVerifyMobile(val) {
    this.setState(state => ({
      showVerifyMobile: val,
      currentStep: 1
    }));
  }
  addBankModal(val) {
    this.setState(state => ({
      showBankModal: val,
    }));
  }
  closeWithdrawModal(val) {
    this.setState(state => ({
      showWithdrawModal: val,
    }));
  }
  goto_nextStep(val) {
    this.setState(state => ({
      currentStep: val,
    }));
  }
  enablRefLink(val) {
    this.setState(state => ({
      reflink: val,
    }));
  }
  async addBank() {
    if (this.state.accountName === '') {
      this.setState(state => ({ error_msg13: 'Please enter account name' }));
    } else if (this.state.bankName === '') {
      this.setState(state => ({ error_msg13: 'Please enter bank name' }));
    } else if (this.state.accountNumber === '') {
      this.setState(state => ({ error_msg13: 'Please enter account number' }));
    } else {
      this.setState(state => ({ submitDisabled13: true, error_msg13: '' }));
      var data = JSON.stringify({ accountName: this.state.accountName, accountNumber: this.state.accountNumber, bankName: this.state.bankName });
      var result1 = await this.makeRequest('bank-account', 'post', data, localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState(state => ({
          showBankModal: false,
          bankAdded: true,
          submitDisabled13: false
        }));
        this.getBankList();
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg13: result1.data.message, submitDisabled13: false }));
      } else if (result1.status === 401) {
        this.setState(state => ({ error_msg13: result1.data.error, submitDisabled13: false }));
      }
    }
  }
  RefStep(val) {
    this.setState(state => ({
      refstep: val,
    }));
  }
  changeCurrPwd(e) {
    let currValid = e.target.value ? true : false;
    this.setState(state => ({ oldp: e.target.value, currValid: currValid }))
  }
  changeNewPwd(e) {
    let newValid = e.target.value ? true : false;
    this.setState(state => ({ newp: e.target.value, newValid: newValid }))
  }
  changeDob(e) {
    let dobValid = e.target.value ? true : false;
    this.setState(state => ({ dob: e.target.value, dobValid: dobValid, submitDisabled: false }))
  }
  changeFname(e) {
    let fnameValid = e.target.value ? true : false;
    this.setState(state => ({ fname: e.target.value, fnameValid: fnameValid, submitDisabled: false }))
  }
  changeAvatar(e) {
    // console.log('uploaded',e.target.files[0]);
    // let file=new File(["goldcrown"], "goldcrown.png", {type: "image/png"});
    // console.log('saved',file)
    this.setState(state => ({ filepath: e.target.files[0] }));
    this.avatarUpload(e.target.files[0]);
  }
  changeretypPwd(e) {
    let retypeValid = e.target.value ? true : false;
    if (this.state.newp !== e.target.value) {
      this.setState(state => ({
        error_msg: 'Passwords do not match'
      }));
    } else {
      this.setState(state => ({ error_msg: '', retypeValid: retypeValid }));
      if (this.state.currValid && this.state.newValid) {
        this.setState(state => ({ submitDisabled: false, }));
      }
    }
  }
  changePhone(e) {
    let phoneValid = e.target.value ? true : false;
    this.setState(state => ({ phone: e.target.value, phoneValid: phoneValid, submitDisabled: false }))
  }
  changeReferral(e) {
    let referralValid = e.target.value ? true : false;
    this.setState({ referral: e.target.value, referralValid: referralValid })
    if (e.target.value !== '')
      this.setState({ submitDisabled: false })
    else if (e.target.value === '')
      this.setState({ submitDisabled: false })
  }
  DetailModal(val) {
    this.setState(state => ({
      showDetailModal: val,
    }));
  }
  closeDepositModal(val) {
    this.setState(state => ({
      showDepositModal: val,
    }));
  }
  closeTransferModal(val) {
    this.setState(state => ({
      showTransferModal: val,
    }));
  }
  closeDepositCryptoModal(val) {
    this.state.allCurrencies.splice(6);
    this.setState({
      currentCurrency: this.state.allCurrencies[0]
    })
    this.setState({
      success_msg4: ''
    })
    this.setState({
      error_msg4: ''
    })
    this.setState(state => ({
      showDepositCryptoModal: val,
    }));
    this.setState({
      showWalletFilter: false
    })
    this.setState({
      copy_text_msg: ''
    })
  }

  async modify_pwd() {
    if (this.state.oldPassword === '' || this.state.newPassword === '' || !this.state.newValid) {
      this.setState(state => ({ error_msg: 'Please enter all three fields' }));
    }else if (this.state.oldPassword === '' || this.state.newPassword === '' || !this.state.newValid) {
      this.setState(state => ({ error_msg: 'Please enter all three fields' }));
    } else {
      this.setState(state => ({ submitDisabled: true, error_msg: '' }));
      var data = JSON.stringify({ oldPassword: this.state.oldp, newPassword: this.state.newp });
      var result1 = await this.makeRequest('user/update-password', 'post', data, localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({ success_msg: result1.data.message, submitDisabled: false });
        this.setShow(true)
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg: result1.data.message, submitDisabled: false }));
      } else if (result1.status === 500) {
        this.setState(state => ({ error_msg: 'Some problem occured. Please try again.', submitDisabled: false }));
      }
    }
  }
  async update_profile() {
    if (this.state.fname === '') {
      this.setState(state => ({ error_msg: 'Enter your name' }));
    }
    else if (this.state.dob === '') {
      this.setState(state => ({ error_msg: 'Enter date of birth' }));
    } else {
      this.setState(state => ({ submitDisabled: true, error_msg: '' }));
      var data = JSON.stringify({ fullname: this.state.fname, dateOfBirth: this.state.dob });
      var result1 = await this.makeRequest('user/me', 'put', data, localStorage.getItem('token'));
      if (result1.status === 200) {
        this.setState({
          success_msg: result1.data.message,
          submitDisabled: false,
        });
        setTimeout(
          function () {
            this.clearErrorMsgs();
            //this.setState({ success_msg: '' });
          }.bind(this), 3000
        );
        this.user_profile();
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg: result1.data.message, submitDisabled: false }));
      }
    }
  }
  async send_verif_code() {
    this.goto_nextStep(2)
    // this.setState(state => ({submitDisabled: true}));
    // var data = JSON.stringify({phone: this.state.selected_country.n+this.state.phone});
    // var result1=await this.makeRequest('user/verify-phone', 'post', data,localStorage.getItem('token'));
    // this.setState({
    //   submitDisabled: false,
    // });
    // if(result1.status===200){
    //     this.goto_nextStep(2)
    // }
  }
  async verify_phone() {
    if (this.state.code1 === '' || this.state.code2 === '' || this.state.code3 === '' || this.state.code4 === '' || this.state.code5 === '' || this.state.code6 === '') {
      this.setState(state => ({ error_msg2: 'Please fill the code fields.' }));
    } else {
      this.setState(state => ({ submitDisabled: true, error_msg: '' }));
      var data = JSON.stringify({ code: parseInt(this.state.code1 + this.state.code2 + this.state.code3 + this.state.code4 + this.state.code5 + this.state.code6) });
      var result1 = await this.makeRequest('user/confirm-phone', 'post', data, localStorage.getItem('token'));
      this.setState({ submitDisabled: false });
      if (result1.status === 200) {
        this.goto_nextStep(3)
        this.user_profile();
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg2: result1.data.message }));
      }
    }
  }
  async transferToVigobet() {
    if (this.state.transferEvoplayAmount === '') {
      this.setState(state => ({ error_msg12: 'Enter amount' }));
    } else {
      this.setState(state => ({ submitDisabled12: true }));
      let data = JSON.stringify({ amount: parseFloat(this.state.transferEvoplayAmount) });
      var result1 = await this.makeRequest('evoplay/user/withdraw', 'post', data, localStorage.getItem('token'));
      //console.log('Statement 1:',result1.data,'status:',result1.status);
      if (result1.status === 200) {
        this.setState({ success_msg12: result1.data.message,submitDisabled12: false,error_msg12:'' })
        setTimeout(
          function () {
            this.getGameWallets();
            this.walletData();
            this.setState({ showTransferModal: false});
            this.setState({ success_msg12: '' });
          }.bind(this), 2000
        );
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg12: result1.data.message, submitDisabled12: false,success_msg12: ''  }));
      }
    }
  }
  async deleteBankAccount(del_id) {
    this.setState(state => ({ submitDisabled14: true }));
    var result1 = await this.makeRequest('bank-account/'+del_id, 'delete', '', localStorage.getItem('token'));
    if (result1.status === 200) {
      this.getBankList();
      this.setState({ success_msg14: 'Bank Account deleted successfully.',submitDisabled14: false,error_msg14:'' })
      setTimeout(
        function () {
          this.setState({ success_msg14: '' });
        }.bind(this), 2000
      );
    } else if (result1.status === 400) {
      this.setState(state => ({ error_msg14: result1.data.message, submitDisabled14: false,success_msg14: ''  }));
    }
  }
  async become_agent() {
    if (this.state.referral === '') {
      this.setState(state => ({ error_msg: 'Enter referral code' }));
    } else {
      this.setState(state => ({ submitDisabled: true }));
      let data = JSON.stringify({ referral: this.state.referral });
      var result1 = await this.makeRequest('become-agent', 'post', data, localStorage.getItem('token'));
      //console.log('Statement 1:',result1.data,'status:',result1.status);
      if (result1.status === 200) {
        this.setState({ success_msg: 'Request submitted successfully.', refstep: 4 })
        setTimeout(
          function () {
            this.setState({ success_msg: '' });
          }.bind(this), 2000
        );
      } else if (result1.status === 400) {
        this.setState(state => ({ error_msg: result1.data.message, submitDisabled: false }));
      }
    }
  }
  async avatarUpload(filepath) {
    this.setState({ submitDisabled2: true });
    var data = new FormData();
    data.append('image', (filepath));
    var result1 = await this.makeFileUploadRequest('user/update-avatar', 'post', data, localStorage.getItem('token'));
    if (result1.status === 200) {
      this.setState({ success_msg: result1.data.message, submitDisabled2: false });
      setTimeout(
        function () {
          this.setState({ success_msg: '' });
        }.bind(this), 5000
      );
      this.user_profile();
    }
  }
  async user_profile() {
    let result2 = await this.makeRequest('user/me', 'get', '', localStorage.getItem('token'));
    if (result2.status === 200) {
      localStorage.setItem('userInfo', JSON.stringify(result2.data.user))
      this.setState({ userAvatar: (JSON.parse(localStorage.getItem('userInfo'))).avatar });
    }
  }

  async referrer_info() {
    let result2 = await this.makeRequest('user/me/referrer', 'get', '', localStorage.getItem('token'));
    let result3 = await this.makeRequest('user/me/referral/count', 'get', '', localStorage.getItem('token'));
    let result4 = await this.makeRequest('user/me/referral/total-bonus', 'get', '', localStorage.getItem('token'));
    if (result2.status === 200) {
      this.setState({ referrerInfo: result2.data, referral_count: result3.data, referral_bonus_currency: result4.data.currency, referral_bonus_total: result4.data.total });
    }
  }
  removeAvatar() {
    (async () => {
      const response = await fetch('assets/goldcrown.png');
      const blob = await response.blob();
      const file = new File([blob], "profileImage.png", {
        type: blob.type,
      });
      this.setState(state => ({ filepath: file }))
      this.avatarUpload(file);
    })();
  }
  selectedCountry(val) {
    this.setState(state => ({ selected_country: val }));
  }
  becom_agnt() {
    this.setState(state => ({ tab: 'referral', refstep: 3 }));
    window.scrollTo(0, 0)
  }
  render() {
    var data1 = this.state.allWallets.map(function (item) {
      return {
        key: item.id,
        name: item.name,
        balance: item.balance,
        currency: item.currency
      };
    });
    const data3 = this.state.transactionList.map(function (item) {
      return {
        key: item.id,
        date: timestampDate(item.createdAt),
        amount: item.amount,
        currency: item.currency,
        status: item.userStatus,
        type: item.type
      };
    });
    const columns3 = [
      {
        name: 'Request At',
        selector: 'request_at',
        width: '220px',
        cell: row => <div data-tag="allowRowEvents" className="transaction_list_btn">{row.date}</div>,
      },
      {
        name: 'Type',
        selector: 'type',
        width: '180px',
        cell: row => <div data-tag="allowRowEvents" className="transaction_list_btn" style={{ cursor: "pointer" }} onClick={() => this.viewTransactionDetailDiv(row.key)}>{row.type}</div>,
      },
      {
        name: 'From',
        selector: 'from',
        width: '200px',
        cell: row => <div data-tag="allowRowEvents" className="transaction_list_btn">{localStorage.getItem('userInfo') !== undefined && localStorage.getItem('userInfo') !== null && localStorage.getItem('userInfo') !== '' ? (JSON.parse(localStorage.getItem('userInfo'))).username : ''}</div>,
      },
      {
        name: 'To',
        selector: 'to',
        width: '200px',
        cell: row => <div data-tag="allowRowEvents" className="transaction_list_btn">Vigobet</div>,
      },
      {
        name: 'Amount',
        selector: 'amount',
        width: '200px',
        cell: row => <div data-tag="allowRowEvents" className="transaction_list_btn">{row.currency + ' ' + row.amount}</div>,
      },
      {
        name: 'Status',
        selector: 'status',
        cell: row => <div data-tag="allowRowEvents" className="transaction_list_btn">{row.status}</div>,
      },
    ];
    const columns1 = [
      {
        name: 'Wallet',
        selector: 'wallet',
        cell: row => <div data-tag="allowRowEvents" className="wallet_list_button">{row.name}</div>

      },
      {
        name: 'Available Balance',
        selector: 'available_balance',
        cell: row => <div data-tag="allowRowEvents" className="wallet_list_button">{(row.balance).toFixed(2)}</div>

      },
      {
        name: 'Total Balance',
        selector: 'total_balance', cell: row => <div data-tag="allowRowEvents" className="wallet_list_button">{(row.balance).toFixed(2)}</div>

      },
      {
        name: 'Action',
        selector: 'action',
        cell: row => <div data-tag="allowRowEvents" className="wallet_list_button wallet_list_button11"><button onClick={() => this.showDepositFromTable(row.currency)}>Deposit</button><button onClick={() => this.showWithdrawFromTable(row.currency)}>Withdraw</button><button>More</button></div>,
      },
    ];
    const game_wallet_data = [
      {game_wallet: 'Evoplay',selected_currency: this.state.currentEvoplayCurrency,Balance: this.state.currentEvoplayAmount,action:""},
    ];
    const game_wallet_columns = [
      {
        name: 'Game Wallet',
        selector: 'wallet',
        cell: row => <div data-tag="allowRowEvents" className="wallet_list_button">Evoplay</div>

      },
      {
        name: 'Selected Currency',
        selector: 'available_balance',
        cell: row => <div data-tag="allowRowEvents" className="wallet_list_button">{this.state.currentEvoplayCurrency}</div>

      },
      {
        name: 'Balance',
        selector: 'total_balance', cell: row => <div data-tag="allowRowEvents" className="wallet_list_button">{this.state.currentEvoplayAmount+' '+this.state.currentEvoplayCurrency}</div>

      },
      {
        name: '',
        selector: 'action',
        cell: row => <div data-tag="allowRowEvents" className="wallet_list_button wallet_list_button11"><button onClick={() => this.closeTransferModal(true)}>Transfer to vigobet</button><button>Convert</button></div>,
      },
    ];
    const columns4 = [
      {
        name: '',
        selector: 'id',
        cell: row => <div data-tag="allowRowEvents"><input className="checkrow" style={{ height: '18px', width: '18px', verticalAlign: 'bottom' }} type="checkbox" /></div>,
        width: '40px'
      },
      {
        name: 'DATE & TIME',
        selector: 'date_time',
        cell: row => <div data-tag="allowRowEvents" style={row.isRead === false ? { fontWeight: 800 } : {}}>{row.date_time}</div>,
        width: '220px',
      },
      {
        name: 'SUBJECT',
        selector: 'subject',
        cell: row => <div data-tag="allowRowEvents" style={row.isRead === false ? { fontWeight: 800, cursor: 'pointer' } : { cursor: 'pointer' }} onClick={() => this.mailDetsModal(true, row)}>{row.subject}</div>,
      },
      {
        name: 'DELETE',
        selector: 'id',
        cell: row => <div data-tag="allowRowEvents" className="deletediv" style={{ cursor: 'pointer', position: 'relative' }}><Trash color="#425A70" onClick={() => this.delete_mail(row.id)} size={18} /><span className="spinner-border spinner-border-sm d-none" style={{ position: 'absolute', right: '-20px', top: '3px' }}></span></div>,
        width: '90px'
      },
    ];
    const agentCommissionDataa = this.state.agentCommissionData.map(function (item) {
      return {
        key: item.id,
         user:item.user,
         vip:(JSON.parse(item.user.memberGroup)),
         commission:item.commission,
         date:item.createdAt
      };
    });
    const agentCommissionColumns = [
      {
        name: 'USERNAME',
        selector: 'username',
        width: '150px',
        cell: row => <div data-tag="allowRowEvents" className="commiss_pass">{row.user!==null ? row.user.username : ''}</div>,
      },
      {
        name: 'VIP',
        selector: 'vip_level',
        cell: row => <div data-tag="allowRowEvents" className="commiss_pass">{row.user!==null && row.vip!==null ? row.vip.name : ''}</div>,
      },
      {
        name: 'ROLE',
        selector: 'user_role',
        cell: row => <div data-tag="allowRowEvents" className="commiss_pass">{row.user!==null ? row.user.role : ''}</div>,
      },
      {
        name: 'ACTIVATE DATE',
        selector: 'activate_date',
        cell: row => <div data-tag="allowRowEvents" className="commiss_pass">{timestampDate(row.date)}</div>,
      },
      {
        name: 'COMMISSION PASSDOWN',
        selector: 'vip_level',
        cell: row => <div data-tag="allowRowEvents" className="commiss_pass"><input className="form-control" defaultValue={row.commission+'%'} style={{fontSize:"13px"}} /></div>,
      },
      {
        name: 'PROMOTION SETTINGS',
        selector: 'action',
        width: '200px',
        cell: row => <div data-tag="allowRowEvents" className="deletediv" style={{color:"#B28E2F"}}><span style={{cursor: 'pointer'}}>View</span> | <span style={{cursor: 'pointer'}}>Edit</span></div>,
      },
    ];
    return (
      <div>
        <Navbar prop={this.props} />
        <div style={{ background: '#000C16', color: 'white' }}>
          <br /><br /><br />
          <div>
            <div className="container account_container">
              <div className="row justify-content-center">
                <div className="col-xl-12 col-lg-9 col-md-9 col-sm-9">
                  <div className="hide767scr"><LevelDets balance={this.state.totalWalletBalance} /></div>
                  <div className="col-xl-6 col-lg-7 col-md-8 show767scr" style={{ background: '#0d171f', padding: '20px' }}>
                    <LevelDetsSM balance={this.state.totalWalletBalance} />
                  </div>
                  <br />
                  <div>
                    {/* Top bar */}
                    <ul className="nav nav-tabs accounttabul" style={{ padding: '8px 0px', justifyContent: 'left' }} id="ex1" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a className={window.location.hash.substr(1) === 'account' ? 'nav-link active' : 'nav-link'} id="ex1-tab-1" data-mdb-toggle="tab" href="#account" role="tab" aria-controls="ex1-tabs-1" aria-selected="false" onClick={() => this.changetab('account')}>Account</a>
                        {window.location.hash.substr(1) === 'account' ? (<span className="user_top_bar"><CaretUpFill size="30" color="#000C16" /></span>) : ('')}
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className={window.location.hash.substr(1) === 'security' ? 'nav-link active' : 'nav-link'} id="ex1-tab-4" data-mdb-toggle="tab" href="#security" role="tab" aria-controls="ex1-tabs-4" aria-selected="false" onClick={() => this.changetab('security')}>Security</a>
                        {window.location.hash.substr(1) === 'security' ? (<span className="user_top_bar"><CaretUpFill size="30" color="#000C16" /></span>) : ('')}
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className={window.location.hash.substr(1) === 'promotions' ? 'nav-link active' : 'nav-link'} id="ex1-tab-2" data-mdb-toggle="tab" href="#promotions" role="tab" aria-controls="ex1-tabs-2" aria-selected="false" onClick={() => this.changetab('promotions')}>Promotions</a>
                        {window.location.hash.substr(1) === 'promotions' ? (<span className="user_top_bar"><CaretUpFill size="30" color="#000C16" /></span>) : ('')}
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className={window.location.hash.substr(1) === 'wallet' ? 'nav-link active' : 'nav-link'} id="ex1-tab-2" data-mdb-toggle="tab" href="#wallet" role="tab" aria-controls="ex1-tabs-2" aria-selected="false" onClick={() => this.changetab('wallet')}>Wallet</a>
                        {window.location.hash.substr(1) === 'wallet' ? (<span className="user_top_bar"><CaretUpFill size="30" color="#000C16" /></span>) : ('')}
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className={window.location.hash.substr(1) === 'finance' ? 'nav-link active' : 'nav-link'} id="ex1-tab-2" data-mdb-toggle="tab" href="#finance" role="tab" aria-controls="ex1-tabs-2" aria-selected="false" onClick={() => this.changetab('finance')}>Finance</a>
                        {window.location.hash.substr(1) === 'finance' ? (<span className="user_top_bar"><CaretUpFill size="30" color="#000C16" /></span>) : ('')}
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className={window.location.hash.substr(1) === 'transactions' ? 'nav-link active' : 'nav-link'} id="ex1-tab-2" data-mdb-toggle="tab" href="#transactions" role="tab" aria-controls="ex1-tabs-2" aria-selected="false" onClick={() => this.changetab('transactions')}>Transactions</a>
                        {window.location.hash.substr(1) === 'transactions' ? (<span className="user_top_bar"><CaretUpFill size="30" color="#000C16" /></span>) : ('')}
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className={window.location.hash.substr(1) === 'agent' ? 'nav-link active' : 'nav-link'} id="ex1-tab-7" data-mdb-toggle="tab" href="#agent" role="tab" aria-controls="ex1-tabs-7" aria-selected="false" onClick={() => this.changetab('agent')}>Agent center</a>
                        {window.location.hash.substr(1) === 'agent' ? (<span className="user_top_bar"><CaretUpFill size="30" color="#000C16" /></span>) : ('')}
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className={window.location.hash.substr(1) === 'notifications' ? 'nav-link active' : 'nav-link'} id="ex1-tab-5" data-mdb-toggle="tab" href="#notifications" role="tab" aria-controls="ex1-tabs-5" aria-selected="false" onClick={() => this.changetab('notifications')}>Notifications</a>
                        {window.location.hash.substr(1) === 'notifications' ? (<span className="user_top_bar"><CaretUpFill size="30" color="#000C16" /></span>) : ('')}
                      </li>
                    </ul>
                    {/* End Top bar */}
                    {/* Accounts div */}
                    <div className="tab-content viptabcontent" id="ex1-content">
                      <div className={window.location.hash.substr(1) === 'account' ? 'active show tab-pane fade' : 'tab-pane fade'} id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                        <div className="row">
                          <div className="col-md-12">
                            <img alt="crown_img" style={{ background: '#213953', padding: '12px 10px', borderRadius: '8px', verticalAlign: 'top', height: '70px', objectFit: 'cover', width: '62px' }} src={this.state.userAvatar !== null ? this.state.userAvatar : 'assets/goldcrown.png'} />
                            <div style={{ display: 'inline-block', paddingLeft: '12px' }}>
                              <div style={{ position: 'relative' }}>
                                {this.state.submitDisabled2 &&
                                  <span className="spinner-border spinner-border-sm" style={{ position: 'absolute', right: '-22px', top: '8px' }}></span>
                                }
                                <input type="file" className="custom_file" style={{ width: '105px' }} onChange={this.changeAvatar} />
                              </div>
                              {/* <button className="btn btn-primary signup_btn comngraybtn" style={{fontSize: '12px',padding: '6px 14px'}}>Upload Avatar</button><br/> */}
                              <button className="btn btn-primary signup_btn comngraybtn" style={{ marginTop: '8px', fontSize: '12px', padding: '6px 30px', background: 'transparent!important', border: '2px solid #7d7d7d!important', color: '#7d7d7d!important', fontWeight: '600' }} onClick={this.removeAvatar}>Remove</button>
                            </div><br /><br /><br />
                          </div>
                          <div className="col-md-4 col-sm-4 accdv1">
                            <span>Username</span><br /><br />
                            {localStorage.getItem('userInfo') !== 'undefined' && localStorage.getItem('userInfo') !== null && localStorage.getItem('userInfo') !== '' &&
                              <span className="dtsss">{(JSON.parse(localStorage.getItem('userInfo'))).username}</span>
                            }
                            <br /><br />
                          </div>
                          <div className="col-md-4 col-sm-4 accdv1">
                            <span>Email</span> <span className="spn1">Verified!</span><br /><br />
                            {localStorage.getItem('userInfo') !== 'undefined' && localStorage.getItem('userInfo') !== null && localStorage.getItem('userInfo') !== '' &&
                              <span className="dtsss">{(JSON.parse(localStorage.getItem('userInfo'))).email}</span>
                            }
                            <br /><br />
                          </div>
                          <div className="col-md-4 col-sm-4 accdv1">
                            <span>Phone number</span>
                            {(localStorage.getItem('userInfo') !== undefined && localStorage.getItem('userInfo') !== null && localStorage.getItem('userInfo') !== '' && JSON.parse(localStorage.getItem('userInfo'))).isPhoneNumberVerified ?
                              (<span className="spn1">Verified!</span>) : ('')
                            }
                            <br />
                            <span><button onClick={() => this.setVerifyMobile(true)} className="btn btn-primary signup_btn comngraybtn" style={{ marginTop: '8px' }}>Verify now!</button></span><br /><br />
                            <Modal size="md" show={this.state.showVerifyMobile} className="pwd_changed_modal verifymobilemodal" strictmode="false">
                              <Modal.Header closeButton onClick={() => this.setVerifyMobile(false)}></Modal.Header>
                              <Modal.Body>
                                <div className="row justify-content-center" style={{ height: '100%' }}>
                                  <div className="col-md-10" style={{ margin: 'auto 0' }}>
                                    <div className="step1" style={this.state.currentStep === 1 ? {} : { display: 'none' }}>
                                      <div className="stepp">1</div>
                                      <h3 style={{ fontSize: '24px', fontWeight: 'bold' }}>Enter Mobile Number</h3><br />
                                      <p style={{ color: 'white', fontSize: '12px', opacity: 0.5 }}>Please enter a correct phone number, we will send you a verification code once you click continue.</p><br />
                                      <div style={{ position: 'relative' }}>
                                        <div className="dropdown flags">
                                          <Dropdown>
                                            <Dropdown.Toggle variant="secondary btn-sm" id="dropdown-basic"><img src={'https://flagcdn.com/32x24/' + (this.state.selected_country.k).toLowerCase() + '.png'} alt="Flag" /></Dropdown.Toggle>
                                            <Dropdown.Menu style={{ backgroundColor: '#73a47' }}>
                                              {COUNTRIES.map(flag => (
                                                <Dropdown.Item key={flag.k} href="#"><img src={'https://flagcdn.com/32x24/' + (flag.k).toLowerCase() + '.png'} onClick={() => this.selectedCountry(flag)} alt="Flag" /></Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                        <span className="countrycode">+{this.state.selected_country.n}</span>
                                        <input className="form-control" type="number" placeholder="Mobile Number" onChange={this.changePhone} />
                                      </div><br /><br />
                                      <button style={{ padding: '8px 8px', fontSize: '16px' }} className="btn btn-primary login_btn btn-block w-100" onClick={this.send_verif_code}>CONTINUE</button>
                                    </div>
                                    <div className="step2" style={this.state.currentStep === 2 ? {} : { display: 'none' }}>
                                      <div className="stepp">2</div>
                                      <h3 style={{ fontSize: '24px', fontWeight: 'bold' }}>Verify Mobile Number</h3><br />
                                      <p style={{ color: 'white', fontSize: '12px', opacity: 0.5 }}>We have sent you an access code via SMS for registered mobile number verifications.</p><br />
                                      {this.state.error_msg2 !== '' &&
                                        <p style={{ fontSize: '16px', color: '#ff2424' }}>{this.state.error_msg2}</p>
                                      }
                                      <div className="verifynumdiv" style={{ position: 'relative' }}>
                                        <input className="form-control" maxLength="1" onChange={this.c1} onKeyUp={this.handleKeyUp} />
                                        <input className="form-control" maxLength="1" onChange={this.c2} onKeyUp={this.handleKeyUp} />
                                        <input className="form-control" maxLength="1" onChange={this.c3} onKeyUp={this.handleKeyUp} />
                                        <input className="form-control" maxLength="1" onChange={this.c4} onKeyUp={this.handleKeyUp} />
                                        <input className="form-control" maxLength="1" onChange={this.c5} onKeyUp={this.handleKeyUp} />
                                        <input className="form-control" maxLength="1" onChange={this.c6} onKeyUp={this.handleKeyUp} />
                                      </div><br /><br />
                                      <button style={{ padding: '8px 8px', fontSize: '16px' }} className="btn btn-primary login_btn btn-block w-100" onClick={this.verify_phone}>CONTINUE</button><br /><br />
                                      <span className="resendcode">Resend Code</span>
                                    </div>
                                    <div className="step3" style={this.state.currentStep === 3 ? {} : { display: 'none' }}>
                                      <div className="stepp">&#10003;</div>
                                      <h3 style={{ fontSize: '24px', fontWeight: 'bold' }}>Verified!</h3><br />
                                      <p style={{ color: 'white', fontSize: '12px', opacity: 0.5 }}>Congratulation! Your mobile number successfully verified</p><br />
                                      <button style={{ padding: '8px 8px', fontSize: '16px' }} className="btn btn-primary login_btn btn-block w-100" onClick={() => this.setVerifyMobile(false)}>FINISH</button>
                                    </div>
                                    <br /><br />
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div className="col-md-12">
                            {this.state.success_msg !== '' &&
                              <p style={{ fontSize: '16px', color: 'green' }}>{this.state.success_msg}</p>
                            }
                            {this.state.error_msg !== '' &&
                              <p style={{ fontSize: '16px', color: '#ff2424' }}>{this.state.error_msg}</p>
                            }
                          </div>
                          <div className="col-md-4 col-sm-6 accdv1">
                            <span>Full name</span><br />
                            <span><input className="form-control" value={this.state.fname} onChange={this.changeFname} /></span>
                          </div>
                          <div className="col-md-4 col-sm-6 accdv1">
                            <span>Date of birth</span><br />
                            <span><input type="date" value={this.state.dob || ''} className="form-control dob" onChange={this.changeDob} /></span><br /><br />
                          </div>
                          <div className="col-md-12">
                            <button className="btn btn-primary login_btn" style={{ padding: '8px 28px', fontSize: '14px' }} onClick={this.update_profile} disabled={this.state.submitDisabled}>Save</button>
                            {localStorage.getItem('userInfo') !== undefined && localStorage.getItem('userInfo') !== null && localStorage.getItem('userInfo') !== '' && !(JSON.parse(localStorage.getItem('userInfo'))).isPhoneNumberVerified ?
                              (<span className="notee" style={{ color: '#FF2F28', textTransform: 'capitalize', paddingLeft: '23px', fontSize: '12px' }}><span style={{ borderRadius: '100%', background: '#b30707', color: '#020000', padding: '1px 6px', display: 'inline-block', lineHeight: '1.2', fontSize: '12px' }}>!</span> Please note that phone verification is important. you cannot withdraw and add funds if you have not verified.</span>) : ('')}

                          </div>
                        </div><br />

                        <div className="referralbonus">
                          <div className="row justify-content-center">
                            <div className="col-md-11">
                              <div className="subdivv1">
                                <h4>Referral Bonus</h4><br />
                                <div className="subdivv2">
                                  <span>Your referreral link</span>
                                  <div className="subdivv22">
                                    <input placeholder={window.location.hostname + '?=' + this.state.referrerInfo.referralCode} readOnly className="form-control" />
                                    <button className="btn btn-primary login_btn">COPY</button>
                                  </div><br /><br />
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                      <span>Your referrer username</span><br />
                                      <span className="answr">{this.state.referrerInfo.referralUsername}</span>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <span>Number of referral</span><br />
                                      <span className="answr">{this.state.referral_count.referral}</span>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                      <span>Total referral bonus</span><br />
                                      <span className="answr">{this.state.referral_bonus_total + ' ' + this.state.referral_bonus_currency}</span>
                                      <button className="btn btn-primary login_btn claimm">CLAIM</button>
                                    </div>
                                  </div>
                                </div>
                              </div><br />
                              {(JSON.parse(localStorage.getItem('userInfo'))).role === 'user' &&
                                <div className="text-center">
                                  <span style={{ fontSize: '14px' }}>Claim your referral commission? <span style={{ color: '#6d8daf', cursor: 'pointer' }} onClick={this.becom_agnt}>Become our agent now.</span></span>
                                </div>
                              }
                            </div>
                          </div>
                        </div>


                      </div>
                      {/* End Accounts Div */}

                      <div className={window.location.hash.substr(1) === 'promotions' ? 'active show tab-pane fade tab2content' : 'tab-pane fade'} id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                        <div>
                          <h4 className="text-center">WELCOME PROMOTION</h4><br />
                          <div className="row justify-content-center">
                            <div className="col-md-11">
                              <div className="accordionn">
                                <Accordion defaultActiveKey="0">
                                  <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.changeToggleState(!this.state.tog1_state)}>
                                      <span className="toggle text-center" style={!this.state.tog1_state ? {} : { display: 'none' }}>+</span><span className="toggle text-center" style={this.state.tog1_state ? {} : { display: 'none' }}>-</span> 5% Cash Back For All Games Am I Qualify?
                                      <span className="hide565scr" style={{ float: 'right' }}>
                                        <button className="btn btn-primary login_btn" style={{ padding: '8px 20px', fontSize: '12px' }}>CLAIM</button>
                                      </span>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                      <Card.Body>
                                        <ul>
                                          <li className="green">Members are entitled to claim this bonus once per day.</li>
                                          <li className="red">Members latest deposit must proceed without any promotions.</li>
                                          <li className="red">Members only can claim one time 5% cash back based on his last deposit.</li>
                                          <li className="green">Members' wallet balance needs to be less than MYR10 to claim this bonus.</li>
                                          <li className="red">Members must claim within 24 hours according to latest deposit time.</li>
                                          <li className="red">Latest transaction is not a withdrawal.</li>
                                          <li className="yellow">Your claim will undergo a final checking to make sure all matches have settle down. Please make sure there is no pending bet or on-going bet, otherwise it will be rejected.</li>
                                        </ul>
                                        <div className="show565scr">
                                          <br />
                                          <button className="btn btn-primary login_btn w-100" style={{ padding: '8px 20px', fontSize: '14px', height: '42px' }}>CLAIM</button>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                  <br />
                                  <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => this.changeToggleState2(!this.state.tog2_state)}>
                                      <span className="toggle text-center" style={!this.state.tog2_state ? {} : { display: 'none' }}>+</span><span className="toggle text-center" style={this.state.tog2_state ? {} : { display: 'none' }}>-</span> Birthday Bonus Am I Qualify?
                                      <span className="hide565scr" style={{ float: 'right' }}>
                                        <button className="btn btn-primary login_btn" style={{ padding: '8px 20px', fontSize: '12px' }}>CLAIM</button>
                                      </span>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                      <Card.Body>
                                        <ul>
                                          <li className="green">Members are entitled to claim this bonus once per day.</li>
                                          <li className="red">Members latest deposit must proceed without any promotions.</li>
                                          <li className="red">Members only can claim one time 5% cash back based on his last deposit.</li>
                                          <li className="green">Members' wallet balance needs to be less than MYR10 to claim this bonus.</li>
                                          <li className="red">Members must claim within 24 hours according to latest deposit time.</li>
                                          <li className="red">Latest transaction is not a withdrawal.</li>
                                          <li className="yellow">Your claim will undergo a final checking to make sure all matches have settle down. Please make sure there is no pending bet or on-going bet, otherwise it will be rejected.</li>
                                        </ul>
                                        <div className="show565scr">
                                          <br />
                                          <button className="btn btn-primary login_btn w-100" style={{ padding: '8px 20px', fontSize: '14px', height: '42px' }}>CLAIM</button>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={this.state.tab === 'finance' ? 'active show tab-pane fade      tab3content' : 'tab-pane fade'} id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                        <div className="row justify-content-center">
                          <div className="col-md-5 text-center" style={this.state.bankListLength!==0 ? { display: 'none' } : {}}>
                            <div>
                              <img alt="creditcard" src={'assets/credit-card.png'} />
                            </div><br /><br />
                            <p style={{ textTransform: 'capitalize', fontSize: '12px', color: 'white', opacity: 0.5 }}>Please take note that bank verification is optional. You may still able to withdraw your fund anytime.</p><br />
                            <button className="btn btn-primary login_btn" style={{ fontSize: '12px', padding: '7px 28px', fontWeight: 600 }} onClick={() => this.addBankModal(true)}>ADD BANK ACC</button>
                            <Modal size="lg" show={this.state.showBankModal} className="pwd_changed_modal bank_account_modal" strictmode="false">
                              <Modal.Header closeButton onClick={() => this.addBankModal(false)}>Add Bank Account</Modal.Header>
                              <Modal.Body>
                                <div className="row justify-content-center" style={{ height: '100%' }}>
                                  <div className="col-md-11" style={{ margin: 'auto 0', textAlign: 'left' }}>
                                    <p style={{ color: 'white', fontSize: '12px', opacity: 0.5 }}>Please upload a photo of your bank account with your full name. It can be a bank statement, e-letter statement and phone banking apps.</p>
                                    <p style={{ color: 'white', fontSize: '12px', opacity: 0.5 }}>Please sensor or cover up other sensitive information.</p><br />
                                    {this.state.success_msg13 !== '' &&
                                      <p style={{ fontSize: '16px', color: 'green' }}>{this.state.success_msg13}</p>
                                    }
                                    {this.state.error_msg13 !== '' &&
                                      <p style={{ fontSize: '16px', color: '#ff2424' }}>{this.state.error_msg13}</p>
                                    }
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>Account Name</label>
                                        <input className="form-control" placeholder="Enter your name" onChange={this.changeAccountName} /><br />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Bank Name</label>
                                        <input className="form-control" placeholder="Select Bank" onChange={this.changeBankName}/><br />
                                      </div>
                                      <div className="col-md-12">
                                        <label>Account Number</label>
                                        <input className="form-control" placeholder="Enter account number" onChange={this.changeAccountNumber} /><br />
                                      </div>
                                      {/*<div className="col-md-6">
                                        <label>Upload statement (Optional)</label>
                                        <div style={{ position: 'relative', border: '3px solid #42474e', color: '#7c7e7f', borderRadius: '4px', height: '40px' }}>
                                          <input type="file" class="photo_upload" />
                                          <div className="uploadiconn"><CloudArrowUpFill size="20" fill="#C4C6C8" /></div>
                                        </div><br /><br />
                                      </div>*/}
                                      <div className="col-md-12">
                                        <button className="btn btn-primary login_btn show370scr" style={{ padding: '10px 38px', fontSize: '14px' }} onClick={() => this.addBank()}>ADD BANK</button>
                                        <span style={{ color: '#FF2F28', textTransform: 'capitalize', fontWeight: '600', fontSize: '12px' }}><span style={{ borderRadius: '100%', background: '#d22a25', color: '#060202', padding: '1px 6px', display: 'inline-block', lineHeight: '1.2', fontSize: '12px' }}>!</span> Please contact Live Chat if your verification status is not updated after 30 minutes.</span>
                                        <button className="btn btn-primary login_btn hide370scr" style={{ padding: '10px 38px', fontSize: '14px', float: 'right' }} disabled={this.state.submitDisabled13} onClick={() => this.addBank()}>ADD</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div className="col-md-10 justify-content-center addedbanks" style={this.state.bankListLength===0 ? { display: 'none' } : {}}>
                            <div>
                              <h5>Bank Accounts
                                <button className="btn btn-primary login_btn" style={{ float: 'right', fontSize: '12px' }}  onClick={() => this.addBankModal(true)}>+ ADD BANK</button>
                              </h5>
                            </div><br />
                            {this.state.success_msg14 !== '' &&
                              <p style={{ fontSize: '16px', color: 'green',textAlign:'center' }}>{this.state.success_msg14}</p>
                            }
                            {this.state.error_msg14 !== '' &&
                              <p style={{ fontSize: '16px', color: '#ff2424',textAlign:'center' }}>{this.state.error_msg14}</p>
                            }
                            <div className="row">
                              {this.state.bankList && this.state.bankList.map(data => (
                                <div className="col-md-6" style={{ marginBottom: '10px' }} key={data.id}>
                                  <div style={{position:"relative"}}>
                                    <span className="bank_upload_icon" onClick={() => this.statement!==null ? this.changeBankStatements(JSON.parse(data.statement),true) : this.changeBankStatements('',true)}><BoxArrowUp color="white" size={16} /></span>
                                    <span><b>{data.bankName}</b> <span className="verifyingspn">{data.status}</span></span><br />
                                    <span>{data.accountName}</span><br />
                                    <span>{((data.accountNumber).slice((data.accountNumber).length - 4))}</span><br />
                                    <button className="btn btn-primary" onClick={() => this.deleteBankAccount(data.id)}>Remove Account</button>
                                  </div>
                                </div>
                              ))}
                              <Modal size="md" show={this.state.showBankStatements} className="pwd_changed_modal bank_account_modal" strictmode="false">
                                <Modal.Header closeButton onClick={() => this.changeBankStatements('',false)}>Upload Statements</Modal.Header>
                                <Modal.Body>
                                  <div className="row justify-content-center" style={{ height: '100%' }}>
                                    <div className="col-md-11" style={{ margin: 'auto 0', textAlign: 'left' }}>
                                      {this.state.success_msg13 !== '' &&
                                        <p style={{ fontSize: '16px', color: 'green' }}>{this.state.success_msg13}</p>
                                      }
                                      {this.state.error_msg13 !== '' &&
                                        <p style={{ fontSize: '16px', color: '#ff2424' }}>{this.state.error_msg13}</p>
                                      }
                                      <div>
                                        <div className="row justify-content-center">
                                          <label htmlFor="statements" className="custom-file-input">
                                          </label>
                                          <input id="statements" type="file" className="" multiple onChange={this.changeUploadStatements} style={{ display: "none" }} />
                                        </div>
                                        <button onClick={this.uploadStatements}>Upload</button>
                                      </div>
                                      <div className="row">
                                      {this.state.bankStatements!==null && this.state.bankStatements!=='' && this.state.bankStatements.map(data => (
                                        <div className="col-md-4" key={data.id}>
                                          <img src={data.link} className="statement_image"/>
                                        </div>
                                      ))}
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                              </Modal>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ padding: 0 }} className={window.location.hash.substr(1) === 'agent' ? 'active show tab-pane fade tab6content' : 'tab-pane fade'} id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
                        { ((JSON.parse(localStorage.getItem('userInfo'))).role).includes('agent') ?
                        (
                          <div>
                          <div className="row justify-content-center">
                            <div className="col-lg-9 col-md-11">
                              <br /><br />
                              <h5 className="text-center" style={{ paddingLeft: '0', fontWeight: 'bold' }}>Agent summary</h5>
                              <div className="reflinkdiv">
                                <input className="form-control" placeholder={window.location.hostname + '?=' + this.state.referrerInfo.referralCode}/>
                                <div><button className="btn btn-primary login_btn">COPY</button>
                                  <button className="btn btn-primary">EDIT</button></div>
                              </div>
                              <div className="canonlyedit" style={{ textAlign: 'right', textTransform: 'capitalize', opacity: '0.75', fontSize: '15px' }}>* You can only edit your referral code once </div><br /><br />
                            </div>
                            <div className="col-lg-11 col-md-12">
                              <div className="commissdiv">
                                <span>Commission available for withdrawal</span><br />
                                <div className="divvv1">
                                  <h3>THB 10,000</h3>
                                  <button className="btn btn-primary login_btn">Withdraw Commission</button>
                                </div><br />
                                <div className="divvvborder"></div><br />
                                <div className="row divvv2">
                                  <div className="col-sm-4">
                                    <span>Commission this week</span><br />
                                    <h5>THB 12,000</h5><br />
                                  </div>
                                  <div className="col-sm-4">
                                    <span>Commission last 90 days</span><br />
                                    <h5>THB 50,000</h5><br />
                                  </div>
                                  <div className="col-sm-4">
                                    <span>Default downline commission</span><br />
                                    <h5>0%</h5>
                                  </div>
                                </div><br />
                                <div className="divvvborder"></div><br />
                                <div className="row divvv2">
                                  <div className="col-sm-4">
                                    <span>Your referrer username</span><br />
                                    <h5>Orange.123</h5><br />
                                  </div>
                                  <div className="col-sm-4">
                                    <span>Number of agent referral</span><br />
                                    <h5>21</h5><br />
                                  </div>
                                  <div className="col-sm-4">
                                    <span>Range of agent level downwards</span><br />
                                    <h5>2</h5>
                                  </div>
                                </div><br />
                                <div className="divvvborder"></div><br />
                                <div className="row divvv2">
                                  <div className="col-md-12">
                                    <span>Your % of commission out of total profit</span><br />
                                    <h5>30%</h5>
                                  </div>
                                </div>
                              </div><br />
                            </div>
                          </div><br />
                          <div>
                            <div className="row justify-content-center" style={{ margin: 0 }}>
                              <div className="col-md-11">
                                <div className="commdetss">
                                  <h4>Commission details</h4>
                                  <div>
                                    <button className="btn btn-primary" style={{ border: '1px solid #ffffffbf', borderRadius: '3px', width: '100px', background: 'transparent', marginLeft: '12px' }}>Export Excel</button>
                                    <button className="btn btn-primary login_btn">Save Commission</button>
                                  </div>
                                </div>
                                <DataTable title="" columns={agentCommissionColumns} pagination data={agentCommissionDataa} customStyles={customStyles} />
                              </div>
                            </div>
                          </div>
                        </div>
                        ):
                        (<div>
                          {this.state.refstep === 1 &&
                            <div style={{ position: 'relative' }} className="signinref3">
                              <img alt="poker_game" style={{ maxHeight: '400px' }} className="w-100" src={'assets/banner1.png'} />
                              <div className="activate">
                                <h4>Become our agent now!</h4>
                                {this.state.error_msg !== '' &&
                                  <p style={{ fontSize: '16px', color: '#ff2424' }}>{this.state.error_msg}</p>
                                }
                                <div className="row justify-content-center">
                                  <div className="col-lg-5 col-md-6">
                                    <div className="uplinediv">
                                      <input className="form-control" onChange={this.changeReferral} placeholder="Upline’s agent code" />
                                      <button className="btn btn-primary login_btn" disabled={this.state.submitDisabled} onClick={this.become_agent} style={{ opacity: "1" }}>Be our Agent</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          {this.state.refstep === 4 &&
                            <div style={{ position: 'relative' }} className="signinref3">
                              <img alt="poker_game" style={{ maxHeight: '400px' }} className="w-100" src={'assets/banner1.png'} />
                              <div className="activate">
                                <h4>Become our agent now!</h4>
                                <div className="row justify-content-center">
                                  <div className="col-lg-5 col-md-6" style={{ textAlign: 'left' }}>
                                    <div className="uplinediv">
                                      <span style={{ fontSize: '14px', textTransform: 'initial', verticalAlign: 'sub' }}>Your referrer is {this.state.referral}</span>
                                      <button className="btn btn-primary login_btn" onClick={() => this.enablRefLink(true)}>Contact Us</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>)
                        }

                      </div>
                      <div style={{ padding: 0 }} className={this.state.tab === 'referral' ? 'active show tab-pane fade tab6content' : 'tab-pane fade'} id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
                        {this.state.refstep === 1 &&
                          <div style={{ position: 'relative' }} className="signinref">
                            <img alt="poker_game" style={{ maxHeight: '400px' }} className="w-100" src={'assets/banner1.png'} />
                            <div className="activate">
                              <h4>Sign in successful</h4>
                              <div className="row justify-content-center">
                                <div className="col-lg-5 col-md-6">
                                  <span className="signedmsg">You have successfully sign in, press the button below to view the code.</span>
                                  <div>
                                    <button className="btn btn-primary login_btn" onClick={() => this.RefStep(2)}>VIEW REFERRAL</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {this.state.refstep === 2 &&
                          <div style={{ position: 'relative' }} className="signinref2">
                            <img alt="poker_game" style={{ maxHeight: '400px' }} className="w-100 hide654scr" src={'assets/reff.png'} />
                            <img alt="poker_game" style={{ maxHeight: '400px' }} className="w-100 show654scr" src={'assets/banner1.png'} />
                            <div className="activate" style={{ top: '8%', left: '0', textAlign: 'left', padding: '0 30px' }}>
                              <h4 style={{ textAlign: 'left' }}>GBO Professional Referral Program</h4>
                              <div className="row">
                                <div className="col-lg-4 col-md-6">
                                  <span className="signedmsg">Get paid every week, forever! Partner with GBO and receive lifetime rewards for inviting new players. Be the player and be the house, all at the same time.</span>
                                  <div style={{ border: '1px solid #FF8600', borderRadius: '2px 0 0 2px', height: '36px', display: 'flex', justifyContent: 'space-between', padding: '0px 0 0 6px' }}>
                                    <span style={{ color: '#D8A62C', paddingTop: '6px' }}>QW1234</span>
                                    <button className="btn btn-primary login_btn" onClick={() => this.RefStep(2)}>COPY TO SHARE</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ background: '#ffffff0d', padding: '24px 0', textTransform: 'initial' }}>
                              <div className="row justify-content-center" style={{ margin: 0 }}>
                                <div className="col-xl-11 col-lg-11">
                                  <h2 className="text-center" style={{ fontWeight: 'bold' }}>Hi, friend!</h2>
                                  <span className="text-center" style={{ display: 'block' }}>This is how much referrral you've earned.</span>
                                  <br />
                                  <div>
                                    <div className="commissdiv">
                                      <span>Earned from referrals</span><br />
                                      <div className="divvv1">
                                        <h3>THB 10,000</h3>
                                        <button className="btn btn-primary login_btn">Withdraw Commission</button>
                                      </div><br />
                                      <div className="divvvborder"></div><br />
                                      <div className="row divvv2">
                                        <div className="col-md-6 col-sm-6">
                                          <span>Commission this week</span><br />
                                          <h5>THB 12,000</h5>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <span>Commission last 90 days</span><br />
                                          <h5>THB 50,000</h5>
                                        </div>
                                      </div><br />
                                    </div><br /><br />
                                    <div className="commissdiv">
                                      <h4 className="text-center" style={{ fontWeight: 'bold' }}>Get THB 500 per referral to start*</h4>
                                      <span className="text-center" style={{ display: 'block' }}>Then get up to THB 1000 per referral after that.</span>
                                      <br /><br /><br />

                                      <div className="text-center">
                                        <button className="btn btn-primary login_btn" onClick={() => this.RefStep(3)}>Become Agent</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {this.state.refstep === 3 &&
                          <div style={{ position: 'relative' }} className="signinref3">
                            <img alt="poker_game" style={{ maxHeight: '400px' }} className="w-100" src={'assets/banner1.png'} />
                            <div className="activate">
                              <h4>Become our agent now!</h4>
                              {this.state.error_msg !== '' &&
                                <p style={{ fontSize: '16px', color: '#ff2424' }}>{this.state.error_msg}</p>
                              }
                              <div className="row justify-content-center">
                                <div className="col-lg-5 col-md-6">
                                  <div className="uplinediv">
                                    <input className="form-control" onChange={this.changeReferral} placeholder="Upline’s agent code" />
                                    <button className="btn btn-primary login_btn" disabled={this.state.submitDisabled} onClick={this.become_agent}>Be our Agent</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {this.state.refstep === 4 &&
                          <div style={{ position: 'relative' }} className="signinref3">
                            <img alt="poker_game" style={{ maxHeight: '400px' }} className="w-100" src={'assets/banner1.png'} />
                            <div className="activate">
                              <h4>Become our agent now!</h4>
                              <div className="row justify-content-center">
                                <div className="col-lg-5 col-md-6" style={{ textAlign: 'left' }}>
                                  <div className="uplinediv">
                                    <span style={{ fontSize: '14px', textTransform: 'initial', verticalAlign: 'sub' }}>Your referrer is {this.state.referral}</span>
                                    <button className="btn btn-primary login_btn" onClick={() => this.enablRefLink(true)}>Contact Us</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      <div style={{ padding: 0 }} className={this.state.tab === 'agent1' ? 'active show tab-pane fade tab7content' : 'tab-pane fade'} id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
                        
                      </div>
                      <div className={window.location.hash.substr(1) === 'security' ? 'active show tab-pane fade tab4content' : 'tab-pane fade'} id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                        <div className="row justify-content-center security_tab">
                          <div className="col-lg-8 col-md-10">
                            <h3 className="text-center security_headng" style={{ fontSize: '24px', fontWeight: 'bold' }}>MODIFY PASSWORD</h3><br />
                            <div style={{ textTransform: 'initial' }}>
                              <p className="text-center" style={{ fontSize: '12px', color: 'white', opacity: 0.5 }}>Changing your password will sign you out of all devices, including your phone. You will need to enter your new password on all devices.</p><br />
                              {this.state.success_msg !== '' &&
                                <p className="text-center" style={{ fontSize: '16px', color: 'green' }}>{this.state.success_msg}</p>
                              }
                              {this.state.error_msg !== '' &&
                                <p className="text-center" style={{ fontSize: '16px', color: '#ff2424' }}>{this.state.error_msg}</p>
                              }
                              <div className="row">
                                <div className="col-md-4"><label>Current Password</label></div>
                                <div className="col-md-8" style={{ position: 'relative' }}><input className="form-control" type="password" placeholder="Type your current password" onChange={this.changeCurrPwd} />
                                  <Eye style={{ position: 'absolute', right: '24px', top: '12px' }} fill="white" size="16" />
                                </div>
                              </div><br />
                              <div className="row">
                                <div className="col-md-4"><label>New Password</label></div>
                                <div className="col-md-8" style={{ position: 'relative' }}><input className="form-control" type="password" placeholder="Type your new password" onChange={this.changeNewPwd} />
                                  <Eye style={{ position: 'absolute', right: '24px', top: '12px' }} fill="white" size="16" />
                                </div>
                              </div><br />
                              <div className="row">
                                <div className="col-md-4"><label>Confirm Password</label></div>
                                <div className="col-md-8" style={{ position: 'relative' }}>
                                  <input className="form-control" type="password" placeholder="Re-type your new password" onChange={this.changeretypPwd} />
                                  <Eye style={{ position: 'absolute', right: '24px', top: '12px' }} fill="white" size="16" />
                                </div>
                              </div><br />
                              <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-8">
                                  <button className="btn btn-primary login_btn chgpwdbtn" style={{ fontSize: '12px', width: '154px', height: '32px' }} onClick={this.modify_pwd} disabled={this.state.submitDisabled}>CHANGE PASSWORD</button>
                                </div>
                              </div><br />
                              <Modal size="md" show={this.state.show} className="pwd_changed_modal" strictmode="false">
                                <Modal.Header closeButton onClick={() => this.setShow(false)}></Modal.Header>
                                <Modal.Body style={{ padding: '20px 30px' }}>
                                  <div className="row justify-content-center" style={{ height: '100%' }}>
                                    <div className="col-md-9" style={{ margin: 'auto 0' }}>
                                      <img alt="checkmark" height="104px" src={'assets/checkmark.png'} /><br /><br /><br />
                                      <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>Password Changed!</h1><br />
                                      <h4>The password has been successfully changed!</h4><br /><br />
                                      <button style={{ padding: '10px 5px', fontSize: '14px' }} className="btn btn-primary login_btn btn-block w-100" onClick={() => this.setShow(false)}>Back to Profile</button><br /><br /><br />
                                    </div>
                                  </div>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={window.location.hash.substr(1) === 'transactions' ? 'active show tab-pane fade tab4content' : 'tab-pane fade'} id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4" style={{ padding: "40px 0px" }}>
                        <div className="row justify-content-center security_tab" style={{ width: "100%", margin: "0" }}>
                          <div className="col-lg-8 col-md-10">
                            <div style={{ textTransform: 'initial' }}>
                              <div className="row">
                                <div className="col-md-3"><label>Transaction Type</label></div>
                                <div className="col-md-9">
                                  <select className="form-control fiat_currency" onChange={this.changeTransactionType} value={this.state.transactionType}>
                                    <option value="">All</option>
                                    <option value="deposit">Deposit</option>
                                    <option value="withdraw">Withdraw</option>
                                  </select>
                                </div>
                              </div><br />
                              <div className="row">
                                <div className="col-md-3"><label>Currency Type</label></div>
                                <div className="col-md-9">
                                  <select className="form-control fiat_currency" onChange={this.changeTransactionCurrency} value={this.state.transactionCurrency}>
                                    <option value="">All</option>
                                    {this.state.fiatCurrencies.map(currency_data => (
                                      <option key={currency_data}>{currency_data}</option>
                                    ))}
                                  </select>
                                </div>
                              </div><br />
                              <div className="row">
                                <div className="col-md-3"><label>Transaction Date</label></div>
                                <div className="col-md-4">
                                  <input type="date" className="form-control" onChange={this.changeTransactionStartDate}></input>
                                </div>
                                <div className="col-md-1">
                                  <label>To</label>
                                </div>
                                <div className="col-md-4">
                                  <input type="date" className="form-control" onChange={this.changeTransactionEndDate}></input>
                                </div>
                              </div><br />
                              <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-4 col-xs-6 trans_btn">
                                  <button className="btn btn-primary login_btn chgpwdbtn" style={{ fontSize: '14px', width: '154px', height: '32px' }} onClick={this.searchTransactions}>Submit</button>
                                </div>
                                <div className="col-md-4 col-xs-6 trans_btn">
                                  <button className="btn btn-primary chgpwdbtn" style={{ fontSize: '14px', width: '154px', height: '32px', background: "transparent", border: "2px solid #676d72" }} onClick={this.resetTransactions}>Clear</button>
                                </div>
                              </div><br />
                            </div>
                          </div>
                          {this.state.transactionTable ?
                            (<div className="wallet_top_bar3">
                              <DataTable title="" columns={columns3} pagination data={data3} customStyles={customStyles} />
                            </div>) : ('')}
                          {this.state.transactionDetail ?
                            (<div className="wallet_top_bar3">
                              <div style={{ textTransform: "capitalize" }}>
                                <ul className="nav nav-tabs accounttabul transferul historyul transaction_info_text" style={{ marginBottom: "0px" }}>
                                  <li className="nav-item w-100" style={{ display: 'block', textTransform: 'capitalize' }}>Transaction Information
                                  </li>
                                </ul>
                                <div className="tab-pane transferdiv mailbox_modal" style={{ background: '#0D0D0D', paddingTop: "0px" }}>
                                  <div className="divvv1" style={{ padding: '12px 16px' }}>
                                    <ArrowLeft color="#425A70" style={{ cursor: 'pointer' }} onClick={() => this.showTransactionDetail(false)} size={26} />
                                    <span style={{ marginTop: '5px' }}>{timestampDate(this.state.viewTransactionDetail.createdAt)}</span>
                                  </div>
                                  <div className="divvvborder"></div>
                                  <div style={{ padding: '0 20px 20px 20px' }}>
                                    <h4>Transaction Detail</h4>
                                    <span style={{ fontSize: '13px', opacity: '0.75' }}>Status: {this.state.viewTransactionDetail.userStatus}</span>
                                    <br /><br />

                                    {(this.state.viewTransactionDetail.userStatus === 'pending' || this.state.viewTransactionDetail.userStatus === 'declined' || this.state.viewTransactionDetail.userStatus === 'approved') &&
                                      <div className="transimgs">
                                        {this.state.viewTransactionDetail.proof && JSON.parse(this.state.viewTransactionDetail.proof).map(data => (
                                          <img width="100px" alt="img1" height="120px" style={{ objectFit: 'cover', marginRight: "12px" }} src={data.link} key={data.link} />
                                        ))}
                                      </div>}

                                    <br />
                                    <div className="row" style={{ fontSize: '13px', opacity: '0.5' }}>
                                      <div className="col-md-6">
                                        <div>
                                          <span>Bank: {this.state.viewTransactionDetail.depositChannel !== null ? (JSON.parse(this.state.viewTransactionDetail.depositChannel)).bankName : ''}</span><br />
                                          <span>Bank Account Name: {this.state.viewTransactionDetail.depositChannel !== null ? (JSON.parse(this.state.viewTransactionDetail.depositChannel)).accountName : ''}</span><br />
                                          <span>Bank Account Number: {this.state.viewTransactionDetail.depositChannel !== null ? (JSON.parse(this.state.viewTransactionDetail.depositChannel)).accountNumber : ''}</span><br />
                                          <span>Minimum Deposit: </span>
                                        </div><br />
                                        <div>
                                          <span>Bank Reference unique code:</span><br />
                                          {this.state.viewTransactionDetail.code}
                                        </div><br />
                                        {this.state.viewTransactionDetail.userStatus === 'approved' &&
                                          <div>
                                            <span>Approved Date and time: {timestampDate(this.state.viewTransactionDetail.updatedAt)}</span><br />
                                            <span>Approved amount: {this.state.viewTransactionDetail.approvedAmount}</span><br /><br />
                                          </div>}<br />
                                        {(this.state.viewTransactionDetail.userStatus === 'approved' || this.state.viewTransactionDetail.userStatus === 'declined') &&
                                          <div>
                                            <span>Remark: {this.state.viewTransactionDetail.remark}</span>
                                          </div>}
                                      </div>

                                      <div className="col-md-6">
                                        <div>
                                          <span>Sender's Name: {(JSON.parse(localStorage.getItem('userInfo'))).username}</span><br />
                                          <span>Amount to Deposit (THB): {this.state.viewTransactionDetail.amount + ' ' + this.state.viewTransactionDetail.currency}</span><br />
                                          <span>Promotion: </span>
                                        </div><br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>

                            </div>) : ('')}
                        </div>
                      </div>
                      <div className={window.location.hash.substr(1) === 'notifications' ? 'active show tab-pane fade tab4content' : 'tab-pane fade'} id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4" style={{ padding: "8px 0px" }}>
                        <div className="row justify-content-center security_tab" style={{ width: "100%", margin: "0" }}>
                          <div className="wallet_top_bar3" style={{ textTransform: "capitalize" }}>
                            {this.state.success_msg6 !== '' &&
                              <p style={{ fontSize: '16px', color: 'green', textAlign: "center" }}>{this.state.success_msg6}</p>
                            }
                            {this.state.error_msg6 !== '' &&
                              <p style={{ fontSize: '16px', color: '#ff2424', textAlign: "center" }}>{this.state.error_msg6}</p>
                            }
                            <DataTable title="" className="mailstable" columns={columns4} pagination data={this.state.mailData} customStyles={customStyles} />

                            <Modal size="lg" show={this.state.showModal} className="pwd_changed_modal bank_account_modal mailbox_modal" strictmode="false">
                              <Modal.Header closeButton onClick={() => this.mailDetsModal(false, this.state.mailDets)}>System Information</Modal.Header>
                              <Modal.Body>
                                <div className="row justify-content-center" style={{ height: '100%' }}>
                                  <div className="col-md-12" style={{ margin: 'auto 0', textAlign: 'left' }}>
                                    <div className="divvv1">
                                      <ArrowLeft color="#425A70" size={26} />
                                      <Trash color="#425A70" size={17} />
                                      <span>{this.state.mailDets.date_time}</span>
                                    </div>
                                    <div className="divvvborder"></div>
                                    <div className="divvv2">
                                      <h3>{this.state.mailDets.subject}</h3><br />
                                      <div>
                                        <span className="dp">TG</span>
                                        <span className="dpname">Team GBO Casino<br /><span>team@gbocasino.com</span></span>
                                      </div><br />
                                      <span className="spnn1">{this.state.mailDets.detail}</span><br />
                                      <span className="spnn1">Please sensor or cover up other sensitive information.</span><br /><br /><br />
                                      <span className="spnn2">
                                        <TelephoneFill color="#ffffff80" size={12} />
                                        <span>+ 603 9212 7434 (12pm 10pm)</span>
                                        <EnvelopeFill color="#ffffff80" size={12} />
                                        <span> support@gbocasino.com</span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>

                          </div>
                        </div>
                      </div>
                      <div className={this.state.tab === 'reports' ? 'active show tab-pane fade tab5content' : 'tab-pane fade'} id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">

                      </div>
                      <div className={window.location.hash.substr(1) === 'wallet' ? 'active show tab-pane fade' : 'tab-pane fade'} id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1" style={{ paddingTop: "24px" }}>
                        {!this.state.showFiatDiv ?
                          (<div className="row justify-content-center" style={{ width: "100%", margin: "0", background: "#081018" }}>
                            <div className="wallet_top_bar">
                              <div className="row" style={{ width: "100%", margin: "0" }}>
                                <div className="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-xs-12">
                                  <div className="row">
                                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-6 col-xs-6 myaccountdiv1">
                                      <div className="divv1">
                                        <span>Available Balance</span><br />
                                        <span className="bln">{this.state.availableWalletBalance} USD</span>
                                      </div>
                                    </div>
                                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-6 col-xs-6 myaccountdiv2">
                                      <div className="divv1">
                                        <span>Total Balance</span><br />
                                        <span className="bln">{this.state.totalWalletBalance} USD</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12 col-xs-12 wallet_top_btnn">
                                  <div className="row wallet_top_right">
                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                      {/*<select className="form-control wallet_select" onClick={() => this.changeConvertCurrencyModal(true)}>
                                              <option>Convert</option>
                                            </select>*/}
                                      <button className="form-control wallet_select" onClick={() => this.changeConvertCurrencyModal(true)}>Convert</button>
                                      <Modal size="lg" show={this.state.showConvertCurrencyModal} className="pwd_changed_modal bank_account_modal wallet_modal crypto_modal" style={{ zIndex: "99999" }}>
                                        <Modal.Header style={{ background: 'none' }} closeButton onClick={() => this.changeConvertCurrencyModal(false)}>
                                          <div>
                                            <span style={{ fontSize: '25px', fontWeight: '600' }}>Convert Currency</span>
                                          </div>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <div className="row" style={{ height: '100%', width: "100%", margin: "0" }}>
                                            {/*this.state.convertFirstDiv ?*/}
                                              <div className="col-md-12 left" style={{ marginBottom: "10px", padding: "0" }}>
                                                {this.state.success_msg7 !== '' &&
                                                  <p style={{ fontSize: '16px', color: 'green', textAlign: "center" }}>{this.state.success_msg7}</p>
                                                }
                                                {this.state.error_msg7 !== '' &&
                                                  <p style={{ fontSize: '16px', color: '#ff2424', textAlign: "center" }}>{this.state.error_msg7}</p>
                                                }
                                                 {this.state.success_msg8 !== '' &&
                                                    <p style={{ fontSize: '16px', color: 'green', textAlign: "center" }}>{this.state.success_msg8}</p>
                                                  }
                                                  {this.state.error_msg8 !== '' &&
                                                    <p style={{ fontSize: '16px', color: '#ff2424', textAlign: "center" }}>{this.state.error_msg8}</p>
                                                  }
                                                  {this.state.convertedValue !== '' &&
                                                    <p style={{ fontSize: '16px', color: '#0ab90a', textAlign: "center" }}>Converted Value is {this.state.convertedValue}</p>
                                                  }
                                                <div className="top_wallet_list">
                                                  <label style={{ width: "100%" }}>Amount</label>
                                                  <input type="text" className="form-control crypto_input" onChange={this.changeConvertedAmount} />
                                                  <div style={{ position: "relative" }}>
                                                    <label style={{ width: "100%" }}>From</label>
                                                    <p className="crypto_p" onClick={() => this.showFromCurrency(!this.state.show_from_currency)}> <img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {this.state.from_currency}
                                                      {this.state.show_from_currency ?
                                                        (<span style={{ float: "right" }}><CaretDownFill color="white" size={10} /></span>) :
                                                        (<span style={{ float: "right" }}><CaretUpFill color="white" size={10} /></span>)}
                                                    </p>

                                                    {this.state.show_from_currency ?
                                                      (<div className="top_wallet_list_ul crypto_list">
                                                        <ul>
                                                          {this.state.fiat_currencies.map(currency_data => (
                                                            //<li onClick={() => this.changeFromCurrency(currency_data)} key={currency_data} style={this.state.from_currency === currency_data ? { background: '#0a7feb', color: 'white', paddingTop: '4px', paddingBottom: "4px", marginBottom: "4px" } : { background: "transaparent" }}><img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {currency_data}</li>
                                                            <li onClick={() => this.changeFromCurrency(currency_data)} key={currency_data} style={this.state.from_currency === currency_data ? { background: '#0a7feb', color: 'white', paddingTop: '4px', paddingBottom: "4px", marginBottom: "4px" } : { background: "transaparent" }}><img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {currency_data}</li>
                                                          ))}
                                                          {this.state.crypto_currencies.map(currency_data => (
                                                            <li onClick={() => this.changeFromCurrency(currency_data)} key={currency_data} style={this.state.from_currency === currency_data ? { background: '#0a7feb', color: 'white', paddingTop: '4px', paddingBottom: "4px", marginBottom: "4px" } : { background: "transaparent" }}><img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {currency_data}</li>
                                                          ))}
                                                        </ul>
                                                      </div>) : ('')}
                                                  </div>
                                                  <div style={{ position: "relative" }}>
                                                    <label style={{ width: "100%" }}>To</label>
                                                    <p className="crypto_p" onClick={() => this.showToCurrency(!this.state.show_to_currency)}> <img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {this.state.to_currency}
                                                      {this.state.show_to_currency ?
                                                        (<span style={{ float: "right" }}><CaretDownFill color="white" size={10} /></span>) :
                                                        (<span style={{ float: "right" }}><CaretUpFill color="white" size={10} /></span>)}
                                                    </p>

                                                    {this.state.show_to_currency ?
                                                      (<div className="top_wallet_list_ul crypto_list">
                                                        <ul>
                                                          {this.state.fiat_currencies.map(currency_data => (
                                                            // <li onClick={() => this.changeToCurrency(currency_data)} key={currency_data} style={this.state.to_currency === currency_data ? { background: '#0a7feb', color: 'white', paddingTop: '4px', paddingBottom: "4px", marginBottom: "4px" } : { background: "transaparent" }}><img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {currency_data}</li>
                                                            <li onClick={() => this.changeToCurrency(currency_data)} key={currency_data} style={this.state.to_currency === currency_data ? { background: '#0a7feb', color: 'white', paddingTop: '4px', paddingBottom: "4px", marginBottom: "4px" } : { background: "transaparent" }}><img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {currency_data}</li>
                                                          ))}
                                                          {this.state.crypto_currencies.map(currency_data => (
                                                            <li onClick={() => this.changeToCurrency(currency_data)} key={currency_data} style={this.state.to_currency === currency_data ? { background: '#0a7feb', color: 'white', paddingTop: '4px', paddingBottom: "4px", marginBottom: "4px" } : { background: "transaparent" }}><img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {currency_data}</li>
                                                          ))}
                                                        </ul>
                                                      </div>) : ('')}
                                                  </div>
                                                  {this.state.convertFirstDiv &&
                                                  <button className="btn btn-primary login_btn w-100 deposit_crypto_btn" disabled={this.state.submitDisabled7} onClick={this.convertValue}>Get a quote</button>}<br />
                                                  {!this.state.convertFirstDiv &&
                                                  <div>
                                                    <button className="btn btn-primary login_btn w-100 deposit_crypto_btn" disabled={this.state.submitDisabled8} onClick={this.acceptValue}>Transfer Wallet</button>
                                                    
                                                  </div>
                                                  }
                                                  
                                                </div><br />
                                              </div>
                                            <br />
                                            <br />
                                          </div>
                                        </Modal.Body>
                                      </Modal>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                      <div className="wallet_deposit_filter">
                                        <p className="wallet_select" style={{ marginBottom: "0", lineHeight: "32px", textTransform: "capitalize" }} onClick={() => this.toggleWalletFilter(!this.state.showWalletFilter)}>Deposit
                                          {this.state.showWalletFilter ?
                                            (<span><CaretDownFill color="white" size={14} /></span>) :
                                            (<span><CaretUpFill color="white" size={14} /></span>)}
                                        </p>
                                        {this.state.showWalletFilter ?
                                          (<div className="under_wallet_deposit_filter">
                                            <ul>
                                              <li onClick={() => this.closeDepositCryptoModal(true)}>Crypto</li>
                                              <li onClick={() => this.fiatDiv(true)}>Fiat</li>
                                            </ul>
                                          </div>) : ('')}
                                      </div>
                                    </div>
                                    <Modal size="lg" show={this.state.showDepositCryptoModal} className="pwd_changed_modal bank_account_modal wallet_modal crypto_modal" style={{ zIndex: "99999" }}>
                                      <Modal.Header style={{ background: 'none' }} closeButton onClick={() => this.closeDepositCryptoModal(false)}>
                                        <div>
                                          <span style={{ fontSize: '25px', fontWeight: '600' }}>Deposit Crypto</span>
                                        </div>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <div className="row" style={{ height: '100%' }}>
                                          <div className="col-md-12 left" style={{ marginBottom: "10px", padding: "0" }}>
                                            {this.state.success_msg4 !== '' &&
                                              <p style={{ fontSize: '16px', color: 'green', textAlign: "center" }}>{this.state.success_msg4}</p>
                                            }
                                            {this.state.error_msg4 !== '' &&
                                              <p style={{ fontSize: '16px', color: '#ff2424', textAlign: "center" }}>{this.state.error_msg4}</p>
                                            }
                                            <div className="top_wallet_list">
                                              <label style={{ width: "100%", textAlign: "center" }}>Choose Currency</label>
                                              <p className="crypto_p" onClick={() => this.showCryptoList(!this.state.cryptoList)}> <img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {this.state.currentCurrency}
                                                {this.state.cryptoList ?
                                                  (<span style={{ float: "right" }}><CaretDownFill color="white" size={10} /></span>) :
                                                  (<span style={{ float: "right" }}><CaretUpFill color="white" size={10} /></span>)}
                                              </p>

                                              {this.state.cryptoList ?
                                                (<div className="top_wallet_list_ul crypto_list">
                                                  <ul id="currency_list">
                                                    {this.state.allCurrencies.map(currency_data => (
                                                      <li onClick={() => this.changeCurrency(currency_data)} key={currency_data} style={this.state.currentCurrency === currency_data ? { background: '#0a7feb', color: 'white', paddingTop: '4px', paddingBottom: "4px", marginBottom: "4px" } : { background: "transaparent" }}><img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {currency_data}</li>
                                                    ))}
                                                  </ul>
                                                </div>) : ('')}
                                              {localStorage.getItem('wallet_address') !== 'undefined' && localStorage.getItem('wallet_address') !== null && localStorage.getItem('wallet_address') !== '' ? ('') :
                                                (<button className="btn btn-primary login_btn w-100 deposit_crypto_btn" disabled={this.state.submitDisabled4} onClick={this.depositCrypto}>Submit</button>)}
                                              <br />
                                            </div><br />
                                            <div className="">
                                              {/*<label style={{width:"100%",textAlign:"center"}}>Blockchain to send on</label>*/}
                                              {/*<div style={{width:"120px",height:"24px",background:"#C4C4C4",marginTop:"20px"}}></div>*/}
                                              <div className="crypto_last_div">
                                                {/* <p>2021-08-18 103.36.50</p>
                                                    
                                                    <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNBePgHcctS-TcPog7tzN2hIX_mZS9AdcQqS2QLodN3tFUoUt6LH6qZeM7MiCBb5D4q-Y&usqp=CAU'} alt="wallet"/><br/><br/> */}

                                                <div className="row" style={{ width: "100%", margin: "0" }}>
                                                  {this.state.copy_text_msg !== '' &&
                                                    <div className="col-sm-12"><p style={{ fontSize: '16px', color: '#0e980e', textAlign: "center", marginBottom: "4px", fontWeight: "bold" }}>{this.state.copy_text_msg}</p></div>
                                                  }
                                                  {localStorage.getItem('wallet_address') !== 'undefined' && localStorage.getItem('wallet_address') !== null && localStorage.getItem('wallet_address') !== '' ?
                                                    (
                                                      <div className="col-sm-10 col-xs-10 address_10" style={{ background: "#8C9094", color: "white" }}>
                                                        <p style={{ marginBottom: "0", padding: "6px", textAlign: "left", color: "white" }}>{localStorage.getItem('wallet_address')}</p>
                                                      </div>
                                                    ) :
                                                    (
                                                      <div className="col-sm-10 col-xs-10 address_10" style={{ background: "#8C9094", color: "white" }}>
                                                        <p style={{ marginBottom: "0", padding: "6px", textAlign: "left", color: "white" }}>{this.state.cryptoAddress}</p>
                                                      </div>
                                                    )}
                                                  <div className="col-sm-2 col-xs-2 address_2" style={{ background: "rgb(187 190 194)", textAlign: "center" }}>
                                                    <span style={{ verticalAlign: "sub", cursor: "pointer" }} onClick={this.copyCryptoAddress}><Front color="white" size={20} /></span>
                                                  </div>
                                                </div>
                                              </div><br /><br />
                                            </div>
                                          </div><br />
                                          <br />
                                        </div>
                                      </Modal.Body>
                                    </Modal>                                  
                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                      {/*<button className="form-control wallet_select" onClick={() => this.closeWithdrawMoneyModal(true)}>Withdraw</button>*/}
                                      <div className="wallet_deposit_filter">
                                        <p className="wallet_select" style={{ marginBottom: "0", lineHeight: "32px", textTransform: "capitalize" }} onClick={() => this.toggleWalletWithdrawFilter(!this.state.showWalletWithdrawFilter)}>Withdraw
                                          {this.state.showWalletWithdrawFilter ?
                                            (<span><CaretDownFill color="white" size={14} /></span>) :
                                            (<span><CaretUpFill color="white" size={14} /></span>)}
                                        </p>
                                        {this.state.showWalletWithdrawFilter ?
                                          (<div className="under_wallet_deposit_filter">
                                            <ul>
                                              <li onClick={() => this.closeWithdrawMoneyModal(true)}>Crypto</li>
                                              <li onClick={() => this.closeWithdrawFiatModal(true)}>Fiat</li>
                                            </ul>
                                          </div>) : ('')}
                                      </div>
                                      <Modal size="lg" show={this.state.showWithdrawMoneyModal} className="pwd_changed_modal bank_account_modal wallet_modal crypto_modal" style={{ zIndex: "99999" }}>
                                        <Modal.Header style={{ background: 'none' }} closeButton onClick={() => this.closeWithdrawMoneyModal(false)}>
                                          <div>
                                            <span style={{ fontSize: '25px', fontWeight: '600' }}>Withdrawal</span>
                                          </div>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <div className="row" style={{ height: '100%' }}>
                                            <div className="col-md-12 left" style={{ marginBottom: "10px", padding: "0" }}>
                                              {this.state.success_msg5 !== '' &&
                                                <p style={{ fontSize: '16px', color: 'green', textAlign: "center" }}>{this.state.success_msg5}</p>
                                              }
                                              {this.state.error_msg5 !== '' &&
                                                <p style={{ fontSize: '16px', color: '#ff2424', textAlign: "center" }}>{this.state.error_msg5}</p>
                                              }
                                              <div className="top_wallet_list">
                                                <label style={{ width: "100%" }}>Address</label>
                                                <input type="text" className="form-control crypto_input" onPaste={this.changeWithdrawAddress} />
                                                <label style={{ width: "100%" }}>Choose Currency</label>
                                                <p className="crypto_p" onClick={() => this.showCryptoWithdrawList(!this.state.cryptoWithdrawList)}> <img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {this.state.currentWithdrawCurrency}
                                                  {this.state.cryptoWithdrawList ?
                                                    (<span style={{ float: "right" }}><CaretDownFill color="white" size={10} /></span>) :
                                                    (<span style={{ float: "right" }}><CaretUpFill color="white" size={10} /></span>)}
                                                </p>

                                                {this.state.cryptoWithdrawList ?
                                                  (<div className="top_wallet_list_ul crypto_list" style={{ top: "130px" }}>
                                                    <ul>
                                                      {this.state.allCurrencies.map(currency_data => (
                                                        <li onClick={() => this.changeWithdrawCurrency(currency_data)} key={currency_data} style={this.state.currentWithdrawCurrency === currency_data ? { background: '#0a7feb', color: 'white', paddingTop: '4px', paddingBottom: "4px", marginBottom: "4px" } : { background: "transaparent" }}><img src={'assets/wallet1.png'} style={{ height: "20px" }} alt="wallet" /> {currency_data}</li>
                                                      ))}
                                                    </ul>
                                                  </div>) : ('')}
                                                <label style={{ width: "100%" }}>Amount</label>
                                                <input type="text" className="form-control crypto_input" onChange={this.changeWithdrawAmount} />
                                                <button className="btn btn-primary login_btn w-100 deposit_crypto_btn" disabled={this.state.submitDisabled5} onClick={this.withdrawMoney}>withdraw</button><br />
                                              </div><br />
                                            </div><br />
                                            <br />
                                          </div>
                                        </Modal.Body>
                                      </Modal>
                                      <Modal size="lg" show={this.state.showWithdrawFiatModal} className="pwd_changed_modal bank_account_modal wallet_modal crypto_modal" style={{ zIndex: "99999" }}>
                                        <Modal.Header style={{ background: 'none' }} closeButton onClick={() => this.closeWithdrawFiatModal(false)}>
                                          <div>
                                            <span style={{ fontSize: '25px', fontWeight: '600' }}>Fiat Withdraw</span>
                                          </div>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <div className="row" style={{ height: '100%' }}>
                                            <div className="col-md-12 left" style={{ marginBottom: "10px", padding: "0" }}>
                                              {this.state.success_msg9 !== '' &&
                                                <p style={{ fontSize: '16px', color: 'green', textAlign: "center" }}>{this.state.success_msg9}</p>
                                              }
                                              {this.state.error_msg9 !== '' &&
                                                <p style={{ fontSize: '16px', color: '#ff2424', textAlign: "center" }}>{this.state.error_msg9}</p>
                                              }
                                              <div className="top_wallet_list">
                                                <div className="fiat_currency_div">
                                                  <label style={{ width: "100%" }}>Deposit Channel</label>
                                                  <select className="form-control fiat_currency" onChange={this.changeFiatWithdrawChannel} value={this.state.fiatWithdrawChannel}>
                                                    <option>Choose deposit channel</option>
                                                    {this.state.depositChannel.map(channel_data => (
                                                      <option key={channel_data.id}>{channel_data.id}</option>
                                                    ))}
                                                  </select>
                                                </div>
                                                <div className="fiat_currency_div">
                                                  <label style={{ width: "100%" }}>Choose Currency</label>
                                                  <select className="form-control fiat_currency" onChange={this.changeFiatWithdrawCurrency} value={this.state.fiatWithdrawCurrency}>
                                                    <option>Choose currency</option>
                                                    {this.state.fiatCurrencies.map(currency_data => (
                                                      <option key={currency_data}>{currency_data}</option>
                                                    ))}
                                                  </select>
                                                </div>
                                                <label style={{ width: "100%" }}>Amount</label>
                                                <input type="text" className="form-control crypto_input" onChange={this.changeFiatWithdrawAmount} />
                                                <button className="btn btn-primary login_btn w-100 deposit_crypto_btn" disabled={this.state.submitDisabled9} onClick={this.withdrawFiat}>withdraw</button><br />
                                              </div><br />
                                            </div><br />
                                            <br />
                                          </div>
                                        </Modal.Body>
                                      </Modal>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                      <button className="form-control wallet_select" onClick={() => this.closeTransferModal(true)}>Transfer</button>
                                    </div>
                                    <Modal size="lg" show={this.state.showTransferModal} className="pwd_changed_modal bank_account_modal wallet_modal crypto_modal" style={{ zIndex: "99999" }}>
                                      <Modal.Header style={{ background: 'none' }} closeButton onClick={() => this.closeTransferModal(false)}>
                                        <div>
                                          <span style={{ fontSize: '25px', fontWeight: '600' }}>Transfer</span>
                                        </div>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <div className="row" style={{ height: '100%' }}>
                                          <div className="col-md-12 left fiat_currency_div" style={{ marginBottom: "10px", padding: "0" }}>
                                            {this.state.error_msg12 !== '' &&
                                              <p style={{ fontSize: '16px', color: '#ff2424', textAlign: "center" }}>{this.state.error_msg12}</p>
                                            }
                                            {this.state.success_msg12 !== '' &&
                                              <p style={{ fontSize: '16px', color: 'green', textAlign: "center" }}>{this.state.success_msg12}</p>
                                            }
                                            <label>Transfer from</label>
                                            <select className="form-control" style={{ border: "3px solid #42474e", background: "transparent" }}>
                                              <option value="">Game wallet</option>
                                            </select>
                                            <label>Transfer to</label>
                                            <select className="form-control" style={{ border: "3px solid #42474e", background: "transparent" }}>
                                              <option>Vigobet wallet</option>
                                            </select>
                                            <label>Transfer amount</label>
                                            <input type="text" className="form-control" onChange={this.changeEvoplayAmount}></input>
                                          </div><br />
                                          {/*<button className="btn btn-primary login_btn w-100" disabled={this.state.submitDisabled2} onClick={this.transferMoney}>Submit</button><br/>*/}
                                          <div style={{ width: "100%", paddingRight: "0" }}>
                                            <button className="btn btn-primary login_btn deposit_crypto_btn" onClick={this.transferToVigobet}>Transfer Now</button><br />
                                          </div>
                                          <br />
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="wallet_top_bar2">
                              <div className="row" style={{ width: "100%", margin: "0" }}>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                  <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 wallet_after">
                                      <div className="divv1">
                                        <span className="bln">{this.state.totalWalletBalance} USD</span>
                                      </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 wallet_after1">
                                      <div className="divv1">
                                        <span className="bln">{this.state.totalWalletBalance} USD</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                  <div className="row wallet_top_right">
                                    <button style={{ width: "auto" }}><span><Clock size="14" color="#ffffff" /></span> Recent Notifications</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="wallet_top_bar3">
                              <DataTable title="" columns={columns1} data={data1} customStyles={customStyles} />
                            </div>
                            <div className="wallet_top_bar3" style={{marginTop:"30px"}}>
                              <DataTable title="" columns={game_wallet_columns} data={game_wallet_data} customStyles={customStyles} />
                            </div>
                          </div>) : ('')}
                        {this.state.showFiatDiv ?
                          (<div className="row justify-content-center" style={{ width: "100%", margin: "0", background: "#081018" }}>
                            <div className="tab-pane transferdiv depositdivv" style={{ background: '#0D171F', paddingTop: "0px" }}>
                              <div className="row justify-content-center">
                                <div className="col-md-12">
                                  <div className="depo_prom_div" style={{ padding: "20px 0px", marginBottom: "30px", marginTop: "16px" }}>
                                    <h5 style={{ fontWeight: "800", marginBottom: "0px", textTransform: "capitalize" }}> <span style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => this.fiatDiv(false)}><ArrowLeft color="white" size={28} style={{ fontWeight: "800" }} /></span>Fiat Deposit</h5>
                                  </div>
                                </div><br /><br />
                                <div className="col-md-10" style={{ textTransform: "capitalize" }}>
                                  <div className="row" style={{ margin: 0 }}>
                                    {this.state.success_msg10 !== '' &&
                                      <p style={{ fontSize: '16px', color: 'green', textAlign: "center" }}>{this.state.success_msg10}</p>
                                    }
                                    {this.state.error_msg10 !== '' &&
                                      <p style={{ fontSize: '16px', color: '#ff2424', textAlign: "center" }}>{this.state.error_msg10}</p>
                                    }
                                  </div>
                                  <div className="currency_select">
                                    <label>Select Currency: </label>
                                    <select className="form-control fiat_currency" onChange={this.ChangeFiatDepositCurrency} value={this.state.fiatDepositCurrency}>
                                      {this.state.fiatCurrencies.map(currency_data => (
                                        <option key={currency_data}>{currency_data}</option>
                                      ))}
                                    </select>
                                  </div>
                                  <br />
                                  <div className="row" style={{ margin: 0 }}>
                                    {this.state.currencyDepositChannels.map(channel_data => (
                                      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-4">
                                        <div className="bankname" key={channel_data.id} attr={channel_data.id} style={this.state.fiatDepositChannelId === channel_data.id ? { cursor: "pointer", borderColor: "#14d614", borderWidth: "3px" } : { cursor: "pointer" }} onClick={() => this.ChangefiatDepositChannelId(channel_data.id)}>
                                          <img alt="bank1" className="w-100" src={channel_data.logo !== null ? channel_data.logo : 'https://www.publicdomainpictures.net/pictures/30000/t2/plain-white-background.jpg'} style={{ height: "100%", objectFit: "cover" }} />
                                        </div>
                                      </div>
                                    ))}
                                    {/*<div className="col-lg-2 col-md-3 col-sm-4 col-xs-4">
                                                    <div className="bankname">
                                                      <img alt="bank2" className="w-100" src={'assets/chinese_logo.png'}/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-xs-4">
                                                    <div className="bankname" style={{color: '#323338',fontSize: '12px'}}>International Wire</div>
                                                </div>*/}
                                  </div><br />
                                  {this.state.showDets === 1 &&
                                    <div>
                                      <div className="row security_tab depositform_div" style={{ borderTop: '2px solid #ffffff40', margin: 0 }}>
                                        <div style={{ paddingTop: '10px' }}><h6 className="text-white">Deposit request</h6></div>
                                        <div className="col-md-6">
                                          <label>Sender's Name</label>
                                          <input type="text" className="form-control" placeholder="Enter sender's name" readOnly defaultValue={localStorage.getItem('userInfo') !== null && localStorage.getItem('userInfo') !== undefined && localStorage.getItem('userInfo') !== '' ? (JSON.parse(localStorage.getItem('userInfo'))).fullname : ''} />
                                        </div>
                                        <div className="col-md-6">
                                          <label>Amount to deposit<span>*</span></label>
                                          <input type="text" className="form-control" placeholder="Enter deposit amount" onChange={this.changeFiatDepositAmount} />
                                        </div>
                                      </div><br />
                                      <div className="row" style={{ margin: 0 }}>
                                        <div className="col-md-12">
                                          <div className="depo_prom_div">
                                            <h6>Promotion</h6>
                                            <div>
                                              <input type="radio" name="promotion_type" /> <span>120% Welcome Deposit Bonus (Rollover x22)</span>
                                            </div>
                                            <div>
                                              <input type="radio" name="promotion_type" /> <span>Proceed without any of the promotions above. ( Rollover x1 )</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div><br /><br />
                                      <div className="row" style={{ borderTop: '2px solid #ffffff40', margin: 0 }}>
                                        <div className="col-md-12">
                                          <br />
                                          <div className="depo_terms_div">
                                            <span><ExclamationCircleFill style={{ marginRight: '4px', verticalAlign: 'text-bottom' }} color="white" size={15} />Kindly read the terms & conditions before selecting. <span style={{ color: '#D8A62C' }}>Terms & Conditions</span></span>
                                            <span style={{ float: 'right' }} className="uniqcode">
                                              <button className="btn btn-primary login_btn" disabled={this.state.submitDisabled10} onClick={() => this.getUniqueCode(2)}>Get unique code</button>
                                            </span>
                                          </div>
                                        </div>
                                      </div><br />
                                    </div>
                                  }
                                  {this.state.showDets === 2 &&
                                    <div>
                                      <div className="row security_tab depositform_div" style={{ borderTop: '2px solid #ffffff40', color: '#A6AEB3', margin: 0 }}>
                                        <div style={{ paddingTop: '10px' }}><h6 className="text-white">Please transfer to the following:</h6></div>
                                        <div className="col-md-12 depositdivvv">
                                           {/* <span>Bank: {(JSON.parse(this.state.showFiatChannelDetails.depositChannel)).bankName}</span><br/>
                                                  <span>Bank Account Name: {(JSON.parse(this.state.showFiatChannelDetails.depositChannel)).accountName}</span><br/>
                                                  <span>Bank Account Number: {(JSON.parse(this.state.showFiatChannelDetails.depositChannel)).accountNumber}</span><br/>
                                                  <span>Minimum Deposit: {(JSON.parse(this.state.showFiatChannelDetails.depositChannel)).minimumDeposite}</span><br/><br/>
                                                  <span>Unique code:</span><br/>
                                                  <h4 style={{color:'#D8A62C',fontWeight:'bold'}}>{this.state.showFiatChannelDetails.code}</h4><br/>
                                                  <span>Please transfers the money and in "REFERENCE" of your internet banking, you must put the unique code provided by our platform. When the "Unique code". "Amount" and "Sender's name" matched the funds, we will be credited automatically to the players account almost instantaneously for them to use.</span><br/><br/><br/>
                                                  <div style={{borderTop:'2px solid #ffffff40'}}></div><br/>
                                                  <span>Sender's name: {localStorage.getItem('userInfo')!==undefined && localStorage.getItem('userInfo')!==null && localStorage.getItem('userInfo')!=='' ? (JSON.parse(localStorage.getItem('userInfo'))).fullname : ''}</span><br/>
                                                  <span>Amount to deposit: {this.state.showFiatChannelDetails.amount+' '+this.state.showFiatChannelDetails.currency}</span><br/>
                                                  <span>Promotion: Proceed without any of the promotions above.</span><br/><br/> */}
                                          <div style={{ borderTop: '2px solid #ffffff40' }}></div><br />
                                          <div style={{ textAlign: 'right' }} className="depo_terms_div">
                                            <button className="btn btn-primary cancelreq" style={{ width: 'auto' }} onClick={() => this.cancelFiatRequest(1)}>Cancel this deposit request</button>
                                            <button className="btn btn-primary uploadbtn" style={{ width: 'auto' }} onClick={() => this.uploadProof(true)}>Upload Transfer Slip</button>
                                            <Modal size="md" show={this.state.showUploadModal} className="pwd_changed_modal bank_account_modal upload_modal" strictmode="false">
                                              <Modal.Header closeButton onClick={() => this.uploadProof(false)}></Modal.Header>
                                              <Modal.Body>
                                                {this.state.success_msg11 !== '' &&
                                                  <p style={{ fontSize: '16px', color: 'green', textAlign: "center" }}>{this.state.success_msg11}</p>
                                                }
                                                {this.state.error_msg11 !== '' &&
                                                  <p style={{ fontSize: '16px', color: '#ff2424', textAlign: "center" }}>{this.state.error_msg11}</p>
                                                }
                                                <div className="row justify-content-center" style={{ height: '100%' }}>
                                                  <div className="col-md-11 text-center" style={{ margin: 'auto 0' }}>
                                                    <h5 style={{ fontSize: '24px', fontWeight: 'bold' }}>Upload Proof</h5>
                                                    <div className="row justify-content-center">
                                                      <label htmlFor="files" className="custom-file-input">
                                                      </label>
                                                      <input id="files" type="file" className="" multiple onChange={this.changeProofFiles} style={{ display: "none" }} />
                                                    </div>
                                                    <span style={{ fontSize: '12px' }}>jpg, png, jpeg.   Max 5MB. 3images Max</span><br />
                                                    <div id="image_result" style={{ display: 'flex', marginTop: "20px", justifyContent: "center" }}>

                                                    </div>
                                                    <button className="btn btn-primary complete_deposit" onClick={() => this.uploadProofFiles()} disabled={this.state.submitDisabled11}>Complete deposit</button><br />
                                                  </div>
                                                </div><br />
                                              </Modal.Body>
                                            </Modal>
                                          </div><br /><br />
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  {this.state.showDets === 3 &&
                                    <div>
                                      <div className="row security_tab depositform_div text-center" style={{ borderTop: '2px solid #ffffff40', color: '#DEE2E6', paddingTop: '32px', margin: 0 }}>
                                        <div className="col-md-12 depositdivvv">
                                          <span className="fc">Thank you for your application.</span><br />
                                          <span>We will credit you shortly when we receive your transfer!</span><br /><br /><br />
                                          <span>Transaction ID: {this.state.showFiatChannelDetails.number}</span><br /><br />
                                          <span>Bank: {(JSON.parse(this.state.showFiatChannelDetails.depositChannel)).bankName}</span><br />
                                          <span>Bank Account Name: {(JSON.parse(this.state.showFiatChannelDetails.depositChannel)).accountName}</span><br />
                                          <span>Bank Account Number: {(JSON.parse(this.state.showFiatChannelDetails.depositChannel)).accountNumber}</span><br />
                                          <span>Minimum Deposit: THB {(JSON.parse(this.state.showFiatChannelDetails.depositChannel)).minimumDeposite + ' ' + (JSON.parse(this.state.showFiatChannelDetails.depositChannel)).currency}</span><br />
                                          <span>Bank Reference unique code: {this.state.showFiatChannelDetails.depositChannel.code}</span><br /><br />
                                          <span>Sender’s name: {localStorage.getItem('userInfo') !== undefined && localStorage.getItem('userInfo') !== null && localStorage.getItem('userInfo') !== '' ? (JSON.parse(localStorage.getItem('userInfo'))).fullname : ''}</span><br /><br />
                                          <span>Promotion: Proceed without any of the promotions above. </span><br /><br />
                                          <div style={{ borderTop: '2px solid #ffffff40' }}></div><br />

                                          <div className="depo_terms_div">
                                            <button style={{ width: '153px' }} className="btn btn-primary login_btn" onClick={() => this.cancelFiatRequest(1)}>Make new request</button>
                                          </div><br /><br />
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>) : ('')}
                      </div>
                    </div>

                    {(this.state.tab === 'accountdetails' || this.state.tab === 'wallet_details') &&
                      <div>
                        <br /><br />
                        <div className="referralbonus">
                          <div className="row justify-content-center">
                            <div className="col-md-11">
                              <div className="subdivv1">
                                <h4>Referral Bonus</h4><br />
                                <div className="subdivv2">
                                  <span>Your referreral link</span>
                                  <div className="subdivv22">
                                    <input placeholder={window.location.hostname + '?=' + this.state.referrerInfo.referralCode} readOnly className="form-control" />
                                    <button className="btn btn-primary login_btn">COPY</button>
                                  </div><br /><br />
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                      <span>Your referrer username</span><br />
                                      <span className="answr">{this.state.referrerInfo.referralUsername}</span>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <span>Number of referral</span><br />
                                      <span className="answr">{this.state.referral_count.referral}</span>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                      <span>Total referral bonus</span><br />
                                      <span className="answr">3200฿</span>
                                      <button className="btn btn-primary login_btn claimm">CLAIM</button>
                                    </div>
                                  </div>
                                </div>
                              </div><br />
                              {(JSON.parse(localStorage.getItem('userInfo'))).role === 'user' &&
                                <div className="text-center">
                                  <span style={{ fontSize: '14px' }}>Claim your referral commission? <span style={{ color: '#6d8daf', cursor: 'pointer' }} onClick={this.becom_agnt}>Become our agent now.</span></span>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }



                  </div>
                </div>
              </div>
            </div>
          </div>

          <br /><br /><br /><br /><br /><br />
          <Footer />
        </div>

      </div>
    );
  }
}

export default Wallets;