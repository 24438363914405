import React from "react";
import AdminSidebar from "./AdminSidebar"
import AdminNav from "./AdminNav"
import DataTable from 'react-data-table-component';
const data = [
  {id: 1,name: "GAMEPLAY DIRECT",prod_type:"Card & Board Games"},
  {id: 2,name: "HAPPY GAMING",prod_type:"Card & Board Games"},
  {id: 3,name: "MP MICROGAMING",prod_type:"Card & Board Games"},
  {id: 4,name: "ESPORTKTIM",prod_type:"Card & Board Games"},
  {id: 5,name: "GAMEPLAY DIRECT",prod_type:"Card & Board Games"},
];
const customStyles = {
  rows: {
    style: {
      minHeight: '60px',fontSize: '14px',background:'#ffffff',color:'rgba(0, 0, 0, 0.65)',
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',fontSize: '14px',width:'50px',background:'#FAFAFA',color:'rgba(0, 0, 0, 0.85)',border:'0',fontWeight:'700'
    },
  },
  cells: {
    style: {
      paddingLeft: '20px',paddingRight: '8px',width:'50px',color: 'rgba(0, 0, 0, 0.65)',fontSize: '14px',borderBottom:'1px solid #E8E8E8',fontWeight:'600!important'
    },
  },
};
class UpdateRebateSetting extends React.Component {
  render() {
    const columns = [
      {name: 'Product Name',selector: 'name',},
      {name: 'Product Type',selector: 'prod_type'},
      {name: 'Rebate (%)',selector: 'action',cell: row => <div data-tag="allowRowEvents"><input style={{height: '32px'}} className="form-control"/></div>,width:'120px'},
    ];
    return (
      <div>
        <div className="row main_row" style={{margin:0,background:'#F4F4F4'}}>
          <div className="col-xl-2 col-lg-3 col-md-3" style={{padding: 0}}>
            <AdminSidebar prop={this.props}/>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9" style={{padding: 0}}>
            <div style={{padding:'0px 24px 0 0'}}>
              <div style={{background: 'white'}}>
                <AdminNav prop={this.props}/>
                <div style={{padding: '18px 20px'}}>
                  <div className="routeinfo">
                    <span>Home</span> / 
                    <span>&nbsp; Rebate</span> / 
                    <span>&nbsp; Rebate Setting</span> / 
                    <span>Update Rebate Setting</span>
                  </div>
                  <h5 className="pg_head">Update Rebate Setting</h5>
                  <button className="btn btn-primary addbtn" onClick={()=>this.props.history.push('/admin/rebate_setting')}>Back To Rebate Setting</button>
                </div>
              </div><br/>
            </div>
            
            <div className="divbody">
              <div className="subbdvv1">
                <div className="subdvv1_2">
                  <DataTable title="" columns={columns} pagination data={data} customStyles={customStyles}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UpdateRebateSetting;